import {DayDeviationVM} from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/day-deviation-view-model.class";
import {TimeIntervalVM} from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/time-interval-view-model.class";
import {Day} from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/day-view-model.class";
import {AsObservableObj, IAsObservable} from "../../../../../../../src/app/classes/as-observable/as-observable-obj";

export class EmployeeGraphGridCell implements IAsObservable<EmployeeGraphGridCell>{

  readonly asObservable: AsObservableObj<EmployeeGraphGridCell> = new AsObservableObj<EmployeeGraphGridCell>(() => this, true);

  constructor(public day: Day,
              public id: number,
              public timeInterval: TimeIntervalVM,
              public dayDeviation: DayDeviationVM,
              public customValue: number,
              public subtractLunch: boolean,
              public flexDinner: number | null,
              public covidHours: number,
              public hasCovid2: boolean,
              public vichHours: number,
              public tuberHours: number,) {
  }
}
