<kendo-grid [data]="gridData$ | async"
            (add)="addCovidCase($event)"
            (cancel)="cancelCovidCase($event)"
            (save)="saveRow($event)"
            (edit)="editRow($event)"
            (remove)="removeRow($event)"
            KendoGridExpanded columnsPadding="2px 4px"
            style="height: 100%; min-height: 200px"
>
  <ng-template kendoGridToolbarTemplate>
    <div style="width: 100%; display: flex; justify-content: space-between">
      <div>
        <button *ngIf="!isReadOnly"
                kendoGridAddCommand
                primary="true"
                [disabled]="!!formGroup"
        >
          <span class="k-icon k-i-plus k-font-icon"></span>
          Добавить
        </button>
      </div>
      <div *ngIf="info"
           style="display: flex; align-self: flex-end;"
      >
        <div>Дата: <strong>{{settings.date | kendoDate:'dd.MM.yyyy'}}</strong></div>
        <div class="info-item">Всего рабочего времени: <strong>{{info.available}}</strong> ч</div>
        <div class="info-item">Работа с COVID-19: <strong>{{info.busy}}</strong> ч</div>
        <div class="info-item">Доступно: <strong>{{(info.available - info.busy) | kendoNumber: '0.0#': 'en'}}</strong> ч</div>
      </div>
    </div>
  </ng-template>

  <ng-template *ngIf="settings?.showDetails"
               [kendoGridDetailTemplateShowIf]="detailShowIf"
               kendoGridDetailTemplate
               let-dataItem>
    <div *ngIf="dataItem && dataItem.settingItem.details" style="display: flex; justify-content: center">
      <kendo-grid
                  style="width: 80%;"
                  class="graph-grid"
                  [data]="[dataItem.settingItem.details]"
      >
        <kendo-grid-column title=""
                           [width]="40"
                           class="no-padding unselectable"
                           headerClass="graph-column-header"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <img *ngIf="dataItem.imageUrl"
                 [src]="dataItem.imageUrl"
                 style="width: 34px; height: 34px;"
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Подразделение" [field]="'subdivision'" headerClass="graph-column-header">
        </kendo-grid-column>
        <kendo-grid-column title="Должность" [field]="'occupation'" headerClass="graph-column-header">

        </kendo-grid-column>
        <kendo-grid-column title="Ставка" [width]="70" [field]="'rate'" headerClass="graph-column-header">

        </kendo-grid-column>
      </kendo-grid>
    </div>
  </ng-template>

  <kendo-grid-column title="Номер ИБ" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{(dataItem.covidLog.id ? dataItem.covidLog.diseaseNumber : '')}}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="formGroup.get('staffUnit').value?.id">
        <ng-container *ngIf="formGroup.get('diseaseNumber').value || !formGroup.get('researchCount').value;else unavailable">
          <input #diseaseNumber
                 kendoTextBox
                 [formControl]="formGroup.get('diseaseNumber')"
                 [maxLength]="50"
          />
          <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: diseaseNumber, control: 'diseaseNumber', noWrap: false}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="ФИО пациента" [width]="260">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.covidLog.id ? dataItem.covidLog.patientFio : ''}}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="formGroup.get('staffUnit').value?.id">
        <ng-container *ngIf="formGroup.get('patientFio').value || !formGroup.get('researchCount').value;else unavailable">
          <input #patientFio
                 kendoTextBox
                 [formControl]="formGroup.get('patientFio')"
                 [maxLength]="100"
          />
          <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: patientFio, control: 'patientFio', noWrap: true}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column>
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Режим работы</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.covidLog.id ? dataItem.mode.text : ''}}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <kendo-dropdownlist #mode
                          [valueField]="'id'"
                          [textField]="'text'"
                          [data]="modeSource"
                          style="width: 100%"
                          [formControl]="formGroup.get('mode')"
      >
      </kendo-dropdownlist>
      <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: mode, control: 'mode', noWrap: true}">
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column>
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Вид манипуляции</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.manipulation?.text}}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="formGroup.get('staffUnit').value?.id">
        <ng-container *ngIf="formGroup.get('manipulation').value || !formGroup.get('researchCount').value;else unavailable">
          <kendo-combobox #manipulation
                          [valueField]="'id'"
                          [textField]="'text'"
                          [data]="manipulationSource"
                          [formControl]="formGroup.get('manipulation')"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          style="width: 100%"
          >
          </kendo-combobox>
          <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: manipulation.container.element.nativeElement.parentElement, control: 'manipulation', noWrap: true}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="wrapped-column-header"
                     [width]="125">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Количество исследований</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.covidLog.id ? dataItem.covidLog.researchCount : ''}}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="formGroup.get('staffUnit').value?.id">
        <ng-container *ngIf="displayResearchCount;else unavailable2">
          <kendo-numerictextbox #researchCount [min]="0"
                                [step]="1"
                                [autoCorrect]="true"
                                [decimals]="0"
                                format="0"
                                [formControl]="formGroup.get('researchCount')"
          >
          </kendo-numerictextbox>
          <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: researchCount.numericInput, control: 'researchCount', noWrap: false}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Интервал" [width]="125" [hidden]="settings.items.length < 2">
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem.settingItem?.timeInterval">
        {{(dataItem.settingItem.timeInterval.start | kendoDate: 'HH:mm') + '-' + (dataItem.settingItem.timeInterval.end | kendoDate: 'HH:mm')}}
      </ng-container>
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <kendo-dropdownlist #timeInterval
                          [data]="staffUnitDropDownSource"
                          [defaultItem]="staffUnitDropDownDefaultItem$ | async"
                          [valueField]="'id'"
                          [textField]="'text'"
                          [formControl]="formGroup.get('staffUnit')"
      >
      </kendo-dropdownlist>
      <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: timeInterval.container.element.nativeElement.parentElement, control: 'staffUnit', noWrap: false}">
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="wrapped-column-header"
                     [width]="140">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Длительность (мин)</div>
    </ng-template>
    <ng-template kendoGridCellTemplate
                 let-dataItem="dataItem"
    >
      {{dataItem.covidLog.id ? dataItem.covidLog.operatingTime : ''}}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="formGroup.get('staffUnit').value?.id">
        <div style="display: flex; align-items: center">
          <div>
            <kendo-numerictextbox #length
                                  [min]="1"
                                  [step]="1"
                                  [autoCorrect]="true"
                                  [decimals]="0"
                                  format="0"
                                  [formControl]="formGroup.get('length')"
                                  style="width: 76px;"
            >
            </kendo-numerictextbox>
            <ng-container *ngTemplateOutlet="validationTooltip;context:{anchor: length.numericInput, control: 'length', noWrap: false}">
            </ng-container>
          </div>
          <strong style="margin-left: 15px"
               title="Доступно времени"
               [ngStyle]="{
               color: formGroup.get('lengthFree').value < 0 ? 'red' : 'green'
               }"
          >
            {{formGroup.get('lengthFree').value | kendoNumber:'0.##'}}
          </strong>
        </div>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="!isReadOnly"
                             title=""
                             [width]="112"
  >
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand
              icon="edit"
              primary="true"
              title="Редактировать"
              [disabled]="!!formGroup"
      >
      </button>
      <button *ngIf="dataItem.covidLog?.id"
              kendoGridRemoveCommand
              icon="delete"
              title="Удалить"
              [disabled]="!!formGroup"
      >
      </button>
      <button kendoGridSaveCommand
              primary="true"
              title="Сохранить"
              [disabled]="formGroup?.invalid || formGroup?.pristine">
        <span class="k-icon k-i-save k-font-icon"></span>
      </button>
      <button kendoGridCancelCommand
              title="Отмена">
        <span class="k-icon k-i-cancel k-font-icon"></span>
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<ng-template #validationTooltip let-anchor="anchor" let-control="control" let-noWrap="noWrap">
  <kendo-popup *ngIf="!formGroup.get(control.toString()).valid"
               [anchor]="anchor"
               popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg popup-correction-has-tooltip-class"
  >
    <div [ngStyle]="{
    whiteSpace: noWrap ? 'nowrap' : 'none'
    }"
         [innerHTML]="formGroup.get(control.toString()).errors.message">
    </div>
  </kendo-popup>
</ng-template>

<ng-template #unavailable>
  <ng-container [ngTemplateOutlet]="unavailableBase" [ngTemplateOutletContext]="{title: 'Причина: заполнено поле - Количество исследований'}"></ng-container>
</ng-template>

<ng-template #unavailable2>
  <ng-container [ngTemplateOutlet]="unavailableBase" [ngTemplateOutletContext]="{title: 'Причина: заполнены поля - ' + titleForUnavailable2}"></ng-container>
</ng-template>

<ng-template #unavailableBase let-title="title">
  <div kendoTooltip>
    <div style="color: gray; font-style: italic; height: 100%; width: 100%" [title]="title">Недоступно</div>
  </div>
</ng-template>
