import {AfterContentInit, Directive, ElementRef, Input, OnInit} from "@angular/core";
import {DropDownButtonComponent} from "@progress/kendo-angular-buttons";

/** Директива расширяет возможности KendoDropdownButton */
@Directive({
  selector: '[KendoDropDownButtonExpanded]'
})
export class KendoDropdownButtonExpandedDirective implements OnInit, AfterContentInit{

  /**
   * Снимать ли фокус с кнопки после выбора элемента
   *
   * Данный костыль необходим для решения бага в кендо NG100
   * Он происходит если результатом выбора элемента будет открытие диалогового окна.
   * Из ошибки следует, что он установил фокус на кнопку, но диалоговое окно тут же этот фокус снимает
   *
   * Возможно этот баг в будуйщем они закроют.
   * Для проверки: График, нажимаем на печать и выбираем долгий отчет, и тут же пытаемся загрузить еще один. Сервер возвращает ошибку и появляется диалоговое окно и в консоле появляется эта ошибка
   */
  @Input() unFocusAfterSelected : boolean = false;

  constructor(private el: ElementRef, private component: DropDownButtonComponent) {
  }

  ngOnInit(): void {
    if(this.unFocusAfterSelected){
      this.component.itemClick.subscribe(value => {
        this.component.disabled = true;
        this.component.disabled = false;
        setTimeout(() => this.component.toggle(false), 0);
      })
    }
  }

  ngAfterContentInit(): void {
  }
}
