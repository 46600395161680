<div class="wrap">
  <div class="staff-unit-type-section">
    <kendo-label class="k-checkbox-label"
                 style="margin-top: 10px; margin-right: 5px;"
                 text="Вид занятости:">
    </kendo-label>
    <kendo-multiselect [data]="dataSource"
                       textField="description"
                       valueField="id"
                       [checkboxes]="true"
                       [autoClose]="false"
                       style="width: auto; margin-left: 3px;"
                       (valueChange)="setCheckedStaffUnitTypes($event)">
    </kendo-multiselect>
  </div>

  <div class='k-form-buttons' style='justify-content: flex-end;'>
    <button kendoButton
            type='button'
            primary='true'
            [disabled] = 'disabled'
            (click)='onOk()'>
      Сформировать
    </button>

    <button kendoButton
            type='button'
            (click)='onCancel()'>
      Отменить
    </button>

  </div>

</div>
