<kendo-listview [data]="selectedReportSections"
                style=" border: none">
  <ng-template kendoListViewItemTemplate let-index="index" let-dataItem="dataItem" style="margin-bottom: 5px;">
    <div style="display: grid; grid-template-columns: 5fr 5fr 40px 40px; gap: 5px;">
      <label for="date" style="align-self: center">Начать раздел журнала с:</label>
      <kendo-datepicker id=date
                        [(ngModel)]="dataItem.SectionDate"
                        [readOnlyInput]="true"
                        [max]="maxDate"
                        [min]="minDate"
                        [disabledDates]="disabledDates"
                        style="align-self: center; width: auto; justify-self: end;"
      ></kendo-datepicker>
      <button kendoButton
              [primary]="true"
              style="width: 30px;
                  vertical-align: bottom;
                  margin-bottom: 2px;
                  margin-top: 2px;
                  justify-self: end;"
              (click)="DeleteReportSection(dataItem.SectionDate, index)"
      ><span class="k-icon k-i-delete  k-font-icon"></span></button>
      <button *ngIf="selectedReportSections.length==index+1" kendoButton
              style="width: 30px;
                  vertical-align: bottom;
                  margin-bottom: 2px;
                  margin-top: 2px;
                  background: lawngreen;
                  justify-self: end;"
              (click)="AddReportSection()"
      ><span class="k-icon k-i-plus  k-font-icon"></span></button>
    </div>
  </ng-template>
</kendo-listview>

<div style="margin-top: 10px; display: grid; grid-template-columns: 5fr 7fr; gap: 5px; margin-bottom: 90px;">
  <kendo-label class="k-checkbox-label"
               style="align-self: center; margin-right: 5px;"
               text="Вид занятости:">
  </kendo-label>
  <kendo-multiselect [data]="staffUnitTypes"
                     textField="description"
                     valueField="id"
                     [checkboxes]="true"
                     [autoClose]="false"
                     style="width: auto; margin-left: -3px;"
                     (valueChange)="setCheckedStaffUnitTypeIds($event)">
  </kendo-multiselect>
</div>

<div class='k-form-buttons' style='justify-content: flex-end;'>
  <button kendoButton
          type='button'
          primary='true'
          [disabled]="!checkedStaffUnitTypes.length"
          (click)='onOk()'>
    Сформировать
  </button>

  <button kendoButton
          type='button'
          (click)='onCancel()'>
    Отменить
  </button>
</div>

