import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AlertService } from "../../../../services/alert.service";
import { take, takeUntil } from "rxjs/operators";
import { Observable, ReplaySubject } from "rxjs";
import { LoadingIndicatorService } from "../../../../services/loading-indicator.service";
import { IResponsibleItem } from '../../entity/responsible-item.class';

@Component({
  selector: 'app-responsible-grid',
  templateUrl: './responsible-grid.component.html',
  styleUrls: ['./responsible-grid.component.css']
})
export class ResponsibleGridComponent implements OnInit, OnDestroy {

  @Input('responsibleSettings') responsibleSettings: IResponsibleGridSettings;

  @Input() public subdivisionId: number;

  /** Событие ошибки загрузки данных */
  @Output() public loadDataError: EventEmitter<any> = new EventEmitter<any>();

  public userDropDownListData: IResponsibleItem[] = [];
  public gridResponsiblesData: IResponsibleItem[] = [];


  private source: IResponsibleItem[];
  private unsubscribe$ = new ReplaySubject<any>(1);


  constructor(private alertService: AlertService,
    private loadingIndicatorService: LoadingIndicatorService,
  ) {
  }

  ngOnInit(): void {
    if (this.subdivisionId) {
      this.responsibleSettings.getResponsibles$Func(this.subdivisionId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(value => {
          this.gridResponsiblesData = value;
        });
    }

    this.responsibleSettings.getAllResponsibles$Func().pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.source = value;
      });
  }

  /* Handler поиска в списке пользователей, имеющих доступ к данной программе */
  userListFilter($event: any) {
    if(!$event.length) {
      this.userDropDownListData = this.source;
      return;
    }
    this.userDropDownListData = this.source.filter(
      (row) => (row.employeeName.toLowerCase().indexOf($event.toLowerCase()) !== - 1
          || row.employeeCode.toLowerCase().indexOf($event.toLowerCase()) !== -1) &&
        !this.gridResponsiblesData.some(r => r.responsibleId === row.responsibleId));
  }

  /* Handler нажатия кнопки добавления User-а в список ответственных */
  addBtnClick() {
    this.userDropDownListData = this.source
      .filter(row => !this.gridResponsiblesData.some(r => r.responsibleId === row.responsibleId));
    //если список свободных от назначения пользователей пуст, то выходим с показом предупреждения
    if (!this.userDropDownListData.length) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'Все пользователи, имеющие доступ к данному функционалу, уже назначены';
      }).showAlert();
      return;
    }
    //добавляем только если уже нет пустой строки
    if (!this.gridResponsiblesData.some(x => !x.responsibleId)) {
      this.gridResponsiblesData.push({ responsibleId: null, employeeName: '', employeeCode: '' });
    }
  }

  /* Handler выбора User-а в выпадающем списке */
  selectEmployee(selectedUser: IResponsibleItem) {
    let row: IResponsibleItem = this.gridResponsiblesData.find(x => !x.responsibleId);
    // Добавляем в базу запись
    this.loadingIndicatorService.addToObservable("Сохранение ответственного сотрудника",
      this.responsibleSettings.addResponsible$Func(selectedUser, this.subdivisionId).pipe(take(1))).subscribe({
        next: value => {
          row.responsibleId = selectedUser.responsibleId;
          row.employeeName = selectedUser.employeeName;
          row.employeeCode = selectedUser.employeeCode;
        }, error: error => {
          this.gridResponsiblesData = this.gridResponsiblesData.filter(x => x.responsibleId);
          this.alertService.defaultAlertOption.information()
            .mod(x => x.message = "Не удалось добавить запись в базу данных.\nПопробуйте еще раз.")
            .showAlert();
        }
      });
  }

  /* Handler нажатия кнопки удаления строки User-а из списка ответственных*/
  deleteBtnClick(user: IResponsibleItem) {
    if (!user.responsibleId) {
      this.gridResponsiblesData = this.gridResponsiblesData.filter(row => row.responsibleId);
      return;
    }

    this.loadingIndicatorService.addToObservable("Удаление ответственного сотрудника",
      this.responsibleSettings.deleteResponsible$Func(user, this.subdivisionId)).pipe(take(1)).subscribe({
        next: value => {
          this.gridResponsiblesData = this.gridResponsiblesData.filter(row => row.responsibleId !== user.responsibleId);
        }, error: error => {
          this.alertService.defaultAlertOption.information()
            .mod(x => x.message = "Не удалось удалить запись из базы данных.\nПопробуйте еще раз.")
            .showAlert();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

/** Интерфейс настроек для грида */
export interface IResponsibleGridSettings {
  /** Фукнция получение ответственных для подразделения. */
  getResponsibles$Func: (subdivisionId: number) => Observable<IResponsibleItem[]>;

  /** Фукнция получение всех, кто может быть ответственным. */
  getAllResponsibles$Func: () => Observable<IResponsibleItem[]>;

  /** Фукнция добавления ответственого для подразделения */
  addResponsible$Func: (rui: IResponsibleItem, subdivisionId: number) => Observable<any>;

  /** Фукнция удаления ответственого для подразделения */
  deleteResponsible$Func: (rui: IResponsibleItem, subdivisionId: number) => Observable<any>;
}
