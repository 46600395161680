import {Injectable, OnDestroy} from "@angular/core";
import {DataSource,
  DataSourceReadOnly
} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {
  combineLatest,
  concatMap, debounceTime,
  defer,
  Observable,
  ReplaySubject,
  switchMap,
  tap,
  throwError, timer
} from "rxjs";
import {GraphComponentStorageServiceBase} from "./graph-component-storage.service";
import {
  RedactionGridDataSourceSelectionBase
} from "../../../../shareds/redactions/redaction-grid2/services/redaction-grid-data-source.selection";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {exDistinctUntilChanged} from "../../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TraceParamEnum
} from "../../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {
  Api1RedactionGraphService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-graph.service";
import {AuthService} from "../../../../../../../../../src/app/modules/auth/services/auth.service";
import {ObservableEmittedType} from "../../../../../../../../../src/app/types/observable.type";

/** Тип для разрешений */
type PermissionType = 'editing' | 'canEditing' | 'canReediting' | 'lock';

@Injectable()
export abstract class GraphComponentEditServiceBase implements OnDestroy {

  protected readonly _permissionDataSource = new DataSource<PermissionType>();
  /** Источник данных для разрешений на редактирование */
  public get permissionDataSource(): DataSourceReadOnly<PermissionType>{
    return this._permissionDataSource;
  }

  /** Выборщик редакций */
  protected redactionSelection: RedactionGridDataSourceSelectionBase;

  protected constructor() {
    this._permissionDataSource.setData('lock'); //Устанавливаем по умолчанию
  }

  private _initialized = false;
  /** Инициализация сервиса */
  public onInit(redactionSelection: RedactionGridDataSourceSelectionBase): void{
    if(this._initialized){
      throw new Error('Не допускается повторная инициализация сервиса');
    }

    if(!redactionSelection){
      throw new Error('Выборщик редакций не передан');
    }

    this._initialized = true;
    this.redactionSelection = redactionSelection;
  }

  /** Начать редактирование */
  public abstract start$(): Observable<void>;

  /** Завершить редактирование */
  public abstract end$(): Observable<void>;

  /** Переначать редактирование. Сперва вызовет {@link end$} после {@link start$} */
  public reStart$(): Observable<void> {
    return defer(() => {
      if(this.permissionDataSource.data !== 'canReediting'){
        return throwError(() => new Error('Вы не можете продолжить редактирование'));
      }

        return this.end$()
          .pipe(
            concatMap(() => this.permissionDataSource.data$.pipe(
              filter(value => value === 'canEditing'),
              take(1),
            )),
            concatMap(() => this.start$()),
          )
    })
  }

  /** @inheritDoc */
  public ngOnDestroy(){
    this._permissionDataSource.onDestroy();
  };
}

/** Период обновления последней активности редактирования */
const lastUpdatePeriod = 2 * 60 * 1000;

@Injectable()
@traceClass('GraphComponentEditService')
export class GraphComponentEditService extends GraphComponentEditServiceBase {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Конструктор */
  constructor(private readonly storageService: GraphComponentStorageServiceBase,
              private readonly redactionService: Api1RedactionGraphService,
              private readonly authService: AuthService,

              private readonly traceService: TracerServiceBase) {
    super();

    //Обновление последне активности
    this.permissionDataSource.data$
      .pipe(
        filter(value => value === 'editing'),
        map(() => this.storageService.editStatusDataSource.data),
        switchMap(value => {
          const delayValue = (() => {
            let temp = !value.lastUpdate
              ? 0
              : value.lastUpdate + lastUpdatePeriod - Date.now();

            temp = Math.min(temp, lastUpdatePeriod);

            return temp < 0 ? 0 : temp;
          })();

          return timer(delayValue, lastUpdatePeriod)
            .pipe(
              map(() => this.redactionSelection.selectedItems2.data[0]),
              switchMap(value => this.redactionService.updateLastActivity$(value.redaction.id)),
              tap(value => {
                this.storageService.editStatusDataSource.setData({
                  id: value.id,
                  targetFkId: value.targetFkId,
                  lastUpdate: +value.lastActivity
                })
              }),
              takeUntil(this.permissionDataSource.beforeChange$) //До тех пор, пока не изменится состояние редактирования
            )
        }),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe();
  }

  /** @inheritDoc */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  public onInit(redactionSelection: RedactionGridDataSourceSelectionBase){
    super.onInit(redactionSelection);

    //Очистка хранилища от устаревших данных
    redactionSelection.selectedItems2.data$
      .pipe(
        map(value => {
          const fullData = value[0]?.version?.fullData;

          return {
            targetFkId: fullData?.graphTable?.id,
            editStatusServer: fullData?.editStatus,
            editStatusStorage: this.storageService.editStatusDataSource.data
          }
        }),
        filter(value => !!value.editStatusStorage), //Должно быть в хранилище
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
      if (value.targetFkId !== value.editStatusStorage.targetFkId) { //Если открыт другой график
        return;
      }

      if (value.editStatusServer?.id === value.editStatusStorage.id) {
        return;
      }

      this.storageService.editStatusDataSource.setData(undefined);
    })

    //Определение состояния
    const combineLatest$ = combineLatest([
      this.storageService.editStatusDataSource.data$,
      redactionSelection.selectedItems2.data$
    ]);

    combineLatest$
      .pipe(
        debounceTime(0),
        map<ObservableEmittedType<typeof combineLatest$>, PermissionType>(value => {
          const editStatus = value[0];
          const redaction = value[1][0];

          if (!redaction?.isActual || !redaction.version.fullData.inWork) {  //Если редакция НЕ выбрана || она НЕ актуальная || график уже находится на этапах согласования
            return 'lock';
          }

          if (typeof redaction.version.fullData.userEditId === 'number') { //Если в данный момент редактируется
            if (redaction.version.fullData.userEditId !== this.authService.user.Id) { //НЕ текущим пользователем
              return 'lock';
            }

            if (redaction.redaction.workTimeSheetId !== editStatus?.targetFkId) { //Если в хранилище другой идентификатор графика
              return 'canReediting';
            }

            return 'editing';
          }

          return 'canEditing';
        }),
        exDistinctUntilChanged(this.permissionDataSource.data),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
      this._permissionDataSource.setData(value);
    })
  }

  /** @inheritDoc */
  @traceFunc()
  public start$(): Observable<void> {
    return defer(() => {
      if(this.permissionDataSource.data !== 'canEditing'){
        return throwError(() => new Error('Вы не можете начать редактирование данной редакции'));
      }

      const redaction = this.redactionSelection.selectedItems2.data[0].redaction;

      return this.redactionService.startEdit$(redaction.id)
        .pipe(
          tap(value => this.storageService.editStatusDataSource.setData({
            id: value.id,
            targetFkId: value.targetFkId,
            lastUpdate: +value.lastActivity,
          })),
          map(() => {})
        );
    });
  }
  /** @inheritDoc */
  @traceFunc()
  public end$(): Observable<void> {
    return defer(() => {
      if(!(this.permissionDataSource.data === 'editing' || this.permissionDataSource.data === 'canReediting')){
        return throwError(() => new Error('Вы не можете закончить редактирование в данной редакции'));
      }

      const redaction = this.redactionSelection.selectedItems2.data[0].redaction;
      return this.redactionService.stopEdit$(redaction.id)
        .pipe(
          map(() => {})
        )
    })
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
