import {Directive, ElementRef, Input, Renderer2} from "@angular/core";
import {
  ICellSelectingDirective,
  ICellSelectingDirectiveModel,
  SelectedCellsEventObj
} from "./cell-selecting.directive.service";

/** Директива отслеживает выделенные ячейки в таблице */
@Directive({
  selector: '[appCellSelecting]'
})
export class CellSelectingDirective implements ICellSelectingDirective {
  /** Индекс строки ячейки */
  @Input() public rowIndex: number = null;
  /** Индекс колонки ячейки */
  @Input() public columnIndex: number = null;
  /** модель ячейки */
  @Input() public cellModel: null | ICellSelectingDirectiveModel = null;

  public get isSelected() {
    return this._borderClassEnum === BorderClassEnum.asSelectedCell;
  }

  private _borderClassEnum: BorderClassEnum = null;

  /** Расположение ячейки относительно выделенной области */
  private set borderClassEnum(value :BorderClassEnum){
    if(this._borderClassEnum != value){
      if(this._borderClassEnum){
        this.r.removeClass(this.elRef.nativeElement, this._borderClassEnum);
      }

      this._borderClassEnum = value;

      switch (value) {
        case BorderClassEnum.asNone:
          this.r.addClass(this.elRef.nativeElement, BorderClassEnum.asNone);
          break;
        case BorderClassEnum.asSelectedCell:
          this.r.addClass(this.elRef.nativeElement, BorderClassEnum.asSelectedCell);
          break;
        case BorderClassEnum.asRow:
          this.r.addClass(this.elRef.nativeElement, BorderClassEnum.asRow);
          break;
        case BorderClassEnum.asColumn:
          this.r.addClass(this.elRef.nativeElement, BorderClassEnum.asColumn);
          break;
      }
    }
  }

  /** Конструктор */
  constructor(
    public readonly elRef: ElementRef,
    private readonly r: Renderer2,
  ) {
    this.r.addClass(this.elRef.nativeElement, 'cell-wrap')
  }

  /** Обработать изменение выделения */
  public onSelectChange(value: SelectedCellsEventObj){
    if(value.isSelected){
      const isInRows = value.checkIsInRows(this.rowIndex);
      const isInColumns = value.checkIsInColumns(this.columnIndex);

      if(isInRows && isInColumns && value.checkIsSelected(this.rowIndex, this.columnIndex)){
        this.borderClassEnum = BorderClassEnum.asSelectedCell;
        return;
      }

      if(!value.rowRange.isMultiple && isInRows){
        this.borderClassEnum = BorderClassEnum.asRow;
        return;
      }
      if(!value.columnRang.isMultiple && isInColumns){
        this.borderClassEnum = BorderClassEnum.asColumn;
        return;
      }
    }

    this.borderClassEnum = BorderClassEnum.asNone;
  }
}

/** Перечисление бордюров */
enum BorderClassEnum{
  asNone = 'border-as-none',
  asSelectedCell = 'border-as-selected-cell',
  asRow = 'border-as-row',
  asColumn = 'border-as-column'
}
