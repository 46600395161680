import {ContentChildren, Directive, ElementRef, HostListener, OnDestroy, QueryList} from "@angular/core";
import {CellSelectingDirective} from "./cell-selecting.directive";
import {
  CellSelectingDirectiveService,
  SelectedCellsEventObj
} from "./cell-selecting.directive.service";
import {fromEvent, ReplaySubject, Subject, take, takeUntil} from "rxjs";

/** Директива для компонента График. Отвечает за выделение ячеек */
@Directive({
  selector: '[appGraphCellSelecting]'
})
export class GraphCellSelectingDirective implements OnDestroy{
  private unsubscribe$ = new ReplaySubject<any>(1);

  private mouse$ = {
    start: new Subject<MouseEvent>(),
    end: new Subject<MouseEvent>(),
  }

  private _cellSelectingDirectives: CellSelectingDirective[];
  @ContentChildren(CellSelectingDirective, {descendants: true}) public set cellSelectingDirectives(value: QueryList<CellSelectingDirective>){
    this._cellSelectingDirectives = value.toArray();
    (this.cellSelectingDirectiveService.clear$ as Subject<void>).next();
  }

  /** Объект свершенного выделения */
  private selectedEvent = new SelectedCellsEventObj([]);

  /** Объект временного выделения. Включает в себя {@link selectedEvent} и {@link selectedRange} */
  private tempSelectedEvent = new SelectedCellsEventObj([]);

  /** Выделенная область */
  private selectedRange: {start: CellSelectingDirective, end: CellSelectingDirective} = null;


  /** Происходит ли в данный момент выделение */
  private get isSelecting(){
    return !!this.selectedRange;
  }

  constructor(public readonly elRef: ElementRef,
              public readonly cellSelectingDirectiveService: CellSelectingDirectiveService) {
    cellSelectingDirectiveService.clear$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.clear();
    })
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent){
    this.mouse$.start.next(event);
    const directive = this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(event));

    if(!directive){ //Если клик произошел не на ячейке
      return;
    }

    if(this.selectedEvent.isSelected && !event.ctrlKey){ // Если нажали без клавиши ctrl и при этом были выделенные ячейки
      this.selectedEvent = new SelectedCellsEventObj([]);
      this.tempSelectedEvent = new SelectedCellsEventObj([]);
      this.selectedRange = undefined;
    }

    if(!this.isSelecting){ //Если нет временной области выделения
      this.selectedRange = {
        start: directive,
        end: directive,
      }
    } else { //Что-то пошло не по бизнес процессу
      (this.cellSelectingDirectiveService.clear$ as Subject<void>).next();
      return;
    }

    const tempSelectedDirectives = this.getDirectiveByRanges(
      this.selectedRange.start.rowIndex, this.selectedRange.end.rowIndex,
      this.selectedRange.start.columnIndex, this.selectedRange.end.columnIndex);

    this.tempSelectedEvent = this.selectedEvent
      .merge(tempSelectedDirectives.map(x => {
        return {cell: x, model: x.cellModel}
      }));

    fromEvent(this.elRef.nativeElement, 'mouseleave') //Подписываемся за выход за пределы основного компонента
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        takeUntil(this.mouse$.start),
        takeUntil(this.mouse$.end),
      ).subscribe((value: MouseEvent) => {
        this.mouse$.end.next(value);
        this.onEndSelecting();
    });

    fromEvent(this.elRef.nativeElement, 'mouseup') //Подписываемся на отпускание мыши
      .pipe(
        take(1),
        takeUntil(this.unsubscribe$),
        takeUntil(this.mouse$.start),
        takeUntil(this.mouse$.end),
      ).subscribe((value: MouseEvent) => {
        this.mouse$.end.next(value);
        this.onEndSelecting();
    })

    fromEvent(this.elRef.nativeElement, 'mouseover') //Подписываемся на перемещение мыши до отпуска мыши
      .pipe(
        takeUntil(this.unsubscribe$),
        takeUntil(this.mouse$.start),
        takeUntil(this.mouse$.end)
      ).subscribe((value: MouseEvent) => {
      this.onMouseover(value);
    })

    this.onChange();
  }

  @HostListener('dblclick', ['$event']) onMouseDoubleClick(event: MouseEvent){
    const directive = this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(event));
    if(!directive){ //Если клик произошел не на ячейке
      return;
    }
    (this.cellSelectingDirectiveService.dblclick$ as Subject<void>).next();
  }

  /** Обработка завершения выделения временной области */
  private onEndSelecting(){
    this.selectedEvent = new SelectedCellsEventObj(this.tempSelectedEvent.datas);
    this.tempSelectedEvent = new SelectedCellsEventObj([]);
    this.selectedRange = undefined;
  }


  private prevHtmlElementMouseMove: HTMLElement = undefined;
  /** Обработка события перемещения мышки между HtmlElement */
  private onMouseover(event: MouseEvent){
    if(!this.isSelecting){
      this.prevHtmlElementMouseMove = undefined;
      return;
    }

    const htmlElement = this.getHtmlElementFromMouseEvent(event);
    if(htmlElement == this.prevHtmlElementMouseMove){ //Если происходит перемещение мыши в ячейке которую уже проанализировали
      return;
    }

    this.prevHtmlElementMouseMove = htmlElement;

    const directive = this.findDirectiveByHtmlElement(htmlElement);
    if(!directive){ //Если переместили не на ячейку
      return;
    }

    if(directive.rowIndex === this.selectedRange.end.rowIndex && directive.columnIndex === this.selectedRange.end.columnIndex){ //Если перемещение внутри ячейки
      return;
    }

    this.selectedRange.end = directive; //запоминаем новое окончание

    const tempSelectedDirectives = this.getDirectiveByRanges(
      this.selectedRange.start.rowIndex, this.selectedRange.end.rowIndex,
      this.selectedRange.start.columnIndex, this.selectedRange.end.columnIndex);

    this.tempSelectedEvent = this.selectedEvent
      .merge(tempSelectedDirectives.map(x => {
        return {cell: x, model: x.cellModel}
      }));

    this.onChange();
  }

  /**
   * Получить директиву по событию мыши
   * @return (директиву + событие) или undefined, если событие мыши не затрагивает элемент помеченный {@link CellSelectingDirective}
   */
  private findDirectiveByHtmlElement(htmlElement: HTMLElement){
    return htmlElement ? this._cellSelectingDirectives.find(x => x.elRef.nativeElement == htmlElement) : undefined;
  }


  /** Найти html элемент имеющий директиву appCellSelecting*/
  private getHtmlElementFromMouseEvent(event: MouseEvent){
    let element = event.target as HTMLElement;

    while (true){
      if(element === this.elRef.nativeElement){
        return undefined;
      }

      if(element.hasAttribute && element.hasAttribute('appCellSelecting')){
        return element;
      }

      element = element.parentElement;

      if(!element){
        return undefined;
      }
    }
  }

  /** Получить директивы по диапазонам строк и колонок */
  private getDirectiveByRanges(rowIndex1: number, rowIndex2: number, columnIndex1: number, columnIndex2: number){
    const minRowIndex = Math.min(rowIndex1, rowIndex2);
    const maxRowIndex = Math.max(rowIndex1, rowIndex2);
    const minColumnIndex = Math.min(columnIndex1, columnIndex2);
    const maxColumnIndex = Math.max(columnIndex1, columnIndex2);

    return this._cellSelectingDirectives.filter(item =>
      item.rowIndex >= minRowIndex && item.rowIndex <= maxRowIndex && item.columnIndex >= minColumnIndex && item.columnIndex <= maxColumnIndex);
  }

  /** Очистить выделение и транслировать */
  private clear(){
    this.selectedEvent = new SelectedCellsEventObj([]);
    this.tempSelectedEvent = new SelectedCellsEventObj([]);
    this.selectedRange = undefined;

    this.onChange();
  }

  /** Сообщить об изменении выделения */
  private onChange(){
    this._cellSelectingDirectives.forEach(x => {
      x.onSelectChange(this.tempSelectedEvent);
    })

    this.cellSelectingDirectiveService.selecting2 = this.tempSelectedEvent;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();

    this.mouse$.start.complete();
    this.mouse$.end.complete();
  }
}
