import {HasEventTargetAddRemove} from "rxjs/internal/observable/fromEvent";
import {fromEvent, map, Observable, race, startWith, switchMap, take, timer} from "rxjs";

/**
 * Функция оборачивает событие mouseleave, и позволяет пользователю выйти на установленное время за пределы htmlElement без трансляции данного события.<br>
 * Использование: При открытии popup по нажатию на кнопку, нужно закрывать его, если пользователь покинул popup. В other передаем popup в target кнопку.<br>
 * @param target html элемент, уход с которого нужно слушать
 * @param other html элемент/элементы, наведение на которые останавливает событие ухода
 * @param delayMs задержка. Время в миллисекундах за которое пользователь должен вернуться для предотвращения события
 */
export function fromMouseleaveEvent(target: HasEventTargetAddRemove<Event>, other: HasEventTargetAddRemove<Event> | ArrayLike<HasEventTargetAddRemove<Event>>, delayMs = 250): Observable<Event> {
  if (!target) {
    throw new Error('Целевой html element не передан');
  }

  if (!other) {
    throw new Error('Объект/Объекты для прослушивания наведения не переданы')
  }

  return fromEvent(target, 'mouseleave')
    .pipe(
      take(1),
      switchMap(mouseleave => {
        return race(
          timer(delayMs).pipe(map(_ => ({isMouseEnter: false, event: mouseleave}))),
          fromEvent(other, 'mouseenter').pipe(take(1), map(event => ({isMouseEnter: true, event: event})))
        ).pipe(
          take(1),
          switchMap(value => {
            switch (value.isMouseEnter) {
              case true:
                return fromMouseleaveEvent(value.event.currentTarget, other);
              case false:
                return fromMouseleaveEvent(value.event.target, other).pipe(startWith(value.event));
              default:
                throw new Error('out of range');
            }
          })
        )
      })
    )
}
