<div *ngIf="form"
     [formGroup]="form"
     class="wrap">
  <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
    <label class="k-checkbox-label" style="align-self: center; margin-left: 10px;" for='dateFrom'>Дата: </label>
    <kendo-datepicker
      id="dateFrom"
      style="width: 70%"
      formControlName="date"
      [format]="datePickerViewParams.format"
      [activeView]="datePickerViewParams.activeView"
      [bottomView]="datePickerViewParams.deepBottomView"
      [topView]="datePickerViewParams.deepTopView"
    >
      <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
    </kendo-datepicker>
  </div>

  <div class="staff-unit-type-multiselect-section">
    <kendo-label text="Вид занятости:">
    </kendo-label>
    <kendo-multiselect [data]="staffUnitTypes"
                       textField="description"
                       valueField="id"
                       formControlName="staffUnitTypeIds"
                       [checkboxes]="true"
                       [autoClose]="false"
                       style="width: 70%;">
    </kendo-multiselect>
  </div>

  <app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                           [selection]="selection"
                                           [expandAll]="expandAllSubdivisions"
                                           [checkableSettings]="checkableSettings"
                                           [displayTextFn]="displayTextFn"
                                           (checkedSubdivisions$)="setCheckedSubdivisionsValue($event)"
                                           (allSubdivisions$)="setAllSubdivisionsFlagValue($event)">
  </app-subdivision-treeview-with-checkbox2>

  <div class="k-form-buttons" style="justify-content: flex-end;">

    <button kendoButton
            type="button"
            primary="true"
            [disabled]="form.invalid"
            (click)="onOk()"
    >
      Сформировать
    </button>

    <button kendoButton
            type="button"
            (click)="onCancel()"
    >
      Отменить
    </button>

  </div>
</div>

