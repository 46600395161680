<kendo-splitter orientation="horizontal" style="height: 100%">
  <kendo-splitter-pane size="600px" min="300px" max="50%">
    <app-subdivisions-treelist [dataSourceService]="subdivisionDataSourceService"
                                [selection]="deferSubdivisionSelectionService.tempSelection.data$ | async"
    >
    </app-subdivisions-treelist>
  </kendo-splitter-pane>
  <kendo-splitter-pane>
    <app-responsible-grid *ngIf="selectedSubdivisionId && !hideRight"
                          [subdivisionId]="selectedSubdivisionId"
                          [responsibleSettings]="responsibleSettings"
    ></app-responsible-grid>
  </kendo-splitter-pane>
</kendo-splitter>
