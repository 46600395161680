<kendo-grid *ngIf="dataSource"
            [data]="dataSource"
            [filterable]="true"
            [filter]="state.filter"
            [sortable]="{mode: 'multiple'}"
            [sort]="state.sort"
            (dataStateChange)="onDataStateChange($event)"
            style="max-height: 100%"
            [kendoGridExpandDetailsBy]="expandDetailsBy"
            [(expandedDetailKeys)]="expandedDetailKeys"
>
  <kendo-grid-column title="Подразделение"
                     field="graphOrTable.subdivisionName"
                     [filterable]="true"

  >
  </kendo-grid-column>

  <kendo-grid-column title="Статус"
                     [width]="180"
                     field="status.name"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div style="display: flex; flex-wrap: nowrap; flex-direction: column;"
           title="{{dataItem.whoEdits}}"
      >
        <div>{{dataItem.status.name}}</div>
        <div *ngIf="dataItem.whoEdits"
             style="font-size: 0.6vw; font-style: italic; color: #1e8796; display: flex; flex-wrap: nowrap"
        >
          <span class="k-icon k-i-edit k-font-icon"
                style="font-size: 0.8vw;">
          </span>
          <span class="k-text-ellipsis" style="overflow: hidden;">{{dataItem.whoEdits}}</span>
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Этап согласования"
                     field="stage"
  >

  </kendo-grid-column>

  <kendo-grid-column-group title="Последнее изменение статуса">
    <kendo-grid-column title="Дата"
                       [width]="140"
                       field="lastRoute.dateTime"
                       format="HH:mm dd.MM.yyyy"
                       [filterable]="false"
    >
    </kendo-grid-column>

    <kendo-grid-column title="Автор"
                       [width]="200"
                       field="lastRoute.author"
                       [filterable]="false"
    >
    </kendo-grid-column>

    <kendo-grid-column title="Комментарий"
                       field="lastRoute.comment"
                       [filterable]="false"
                       [sortable]="false"
                       [style]="{'white-space': 'pre-line'}"
    >
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column [width]="250">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button *ngIf="dataItem.managingApproval.canApproving"
              kendoButton
              primary="true"
              icon="play"
              (click)="onStartApproving(dataItem)"
      >
        Начать согласование
      </button>
      <button *ngIf="dataItem.managingApproval.isApproving"
              kendoButton
              primary="true"
              icon="redo"
              (click)="onRedoApproving(dataItem)"
      >
        Продолжить согласование
      </button>
      <button *ngIf="dataItem.managingApproval.canUnderApproving"
              kendoButton
              primary="true"
              icon="undo"
              (click)="onUnderApproving(dataItem)"
      >
        Отменить согласование
      </button>
      <button *ngIf="dataItem.managingApproval.canToUnderRevision"
              kendoButton
              primary="true"
              icon="edit"
              (click)="onUnderRevision(dataItem)"
      >
        Отправить на доработку
      </button>
    </ng-template>

  </kendo-grid-column>

  <div *kendoGridDetailTemplate="let dataItem" style="margin: 1% 4%;">
    <app-monitoring-grid-detail [entityId]="dataItem.graphOrTable.id"
                                [sourceGetFunc]="monitoringService.detailGet$(dataItem.graphOrTable.id)"
    >
    </app-monitoring-grid-detail>
  </div>

</kendo-grid>
