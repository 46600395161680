import {effect, EffectRef, InputSignal, untracked} from "@angular/core";

/**
 * Функция отслеживает изменения в переданном {@link input} при помощи {@link effect}, и при смене значения бросает ошибку.
 * Тем самым переданный {@link input} становится только инициализируемым.
 *
 * @example
 * class Component{
 *   public readonly value = input<boolean>(true); //Входной параметр компонента
 *
 *   constructor(){
 *     readonlyInput(this.value); //При первой установке в true больше не позволит изменить
 *     readonlyInput(this.value, x => true); //Любое первое значение НЕ позволит изменить
 *     readonlyInput(this.value, x => x === false); //Любое первое значение равное false не позволит изменить
 *   }
 * }
 *
 * @param input инпут
 * @param hasValueFn функция проверки - имеется ли значение в {@link input}. По умолчанию !!value
 */
export function readonlyInput<T>(input: InputSignal<T>, hasValueFn = (value: T) => !!value): EffectRef{

  let isFirst = true; //Для пропуска первого значения === null
  let hasValue = false; //Имеется ли значение в инпуте
  let prevValue: T = undefined; //Предыдущее значение в инпуте

  const effectRef = effect(() => {
    const value = input();

    untracked(() => {
      if (isFirst) { //Инпут всегда транслирует сперва null, даже если он имеет значение по умолчанию
        isFirst = false;
        return;
      }

      if(!hasValue){ //Если значение еще небыло установлено в инпуте
        if(hasValueFn(value)){ //Если значение имеется в инпуте
          hasValue = true;
          prevValue = value;
        }

        return;
      }

      if(prevValue !== value){
        prevValue = value;
        effectRef.destroy(); //Разрушаем эффект. Тем самым будет только одна ошибка при первой смене значения
        throw new Error('Изменение входного параметра НЕ поддерживается');
      }
    })
  });

  return effectRef;
}

