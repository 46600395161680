<kendo-grid #tableGrid
  *ngIf="view"
  [data]="view.source"
  style="max-height: 100% !important; min-width: 1500px; cursor: default;"
  (cellClick)="onCellClick($event)"
  sortable="true"
  [sort]="state.sort"
  [selectable]="selectableSettings"
  (selectionChange)="selectRow($event)"
            kendoGridSelectBy
            [selectedKeys]="mySelection"
  (sortChange)="sortChange($event)"
>

  <!-- ПАНЕЛЬ РЕДАКТИРОВАНИЯ И УПРАВЛЕНИЯ ТАБЕЛЕМ -->
  <ng-template kendoGridToolbarTemplate>
    <div style="display: flex; flex-direction: column; width: 100%;">
      <app-grid-toolbar-management *ngIf="settings.showToolbarManagementComponent"
                                   [disabled]="settings.readonly"
                                   [disableSaveButton]="!viewHasChange"
      >
      </app-grid-toolbar-management>

      <app-table-code-panel *ngIf="gridToolbarManagementService.isEditing$ | async"
                               class="grid-toolbar-element"
                              [disabled]="tableCodePanelComponentService.disable"
      >
      </app-table-code-panel>

    </div>
  </ng-template>

  <!--ТАБЛИЦА ТАБЕЛЯ-->
  <kendo-grid-column title=""
                     [width]="5"
                     class="no-padding"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : ''" style="height: 3em;">
        <div *ngIf="dataItem.isFirstRow && dataItem.staffUnit.isExternal"
             class="icon-staff-unit-external"
        ></div>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title=""
                     [width]="30"
                     class="no-padding "
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="content-center" style="height: 3em; justify-content: center;"
           [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : ''">
        <img *ngIf="dataItem.imageUrl"
             position="right"
             title=""
             kendoTooltip [tooltipTemplate]="tooltipTemplate"
             class="staff-unit-img"
             [src]="dataItem.imageUrl"
             (mouseover)="dataForTooltip = dataItem"
        />
      </div>

    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Должность"
                     field="occupationName"
                     sortable="true"
                     class="no-padding unselectable"
                     headerClass="graph-column-header"  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="text-hidden" style="height: 3em; padding-left: 2px"
           [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : ''"
      >
        <div appCutLongString
             [lineCount]="2"
             [title]="dataItem.isFirstRow ? dataItem.staffUnit.occupation.name: null"
        >
          {{dataItem.isFirstRow ? dataItem.staffUnit.occupation.name: null}}
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="ФИО"
                     field="employeeName"
                     sortable="true"
                     class="no-padding unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="text-hidden" style="height: 3em; padding-left: 2px"
           [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : ''"
      >
        <div appCutLongString
             [lineCount]="2"
             [title]="dataItem.isFirstRow ? dataItem.staffUnit.employee.fullName : null"
        >
          {{dataItem.isFirstRow ? dataItem.staffUnit.employee.fullName : null}}
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ставка"
                     field="rate"
                     [width]="65"
                     class="no-padding unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="content-center" style="height: 3em; justify-content: center;"
           [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : '1px solid silver'"
      >{{dataItem.isFirstRow && dataItem.staffUnit.staffUnitType.id !== staffUnitTypeEnum.Duty ? dataItem.staffUnit.rate : null}}</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Код"
                     [width]="35"
                     class="no-padding unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="content-center" style="height: 3em; justify-content: center;"
           [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : '1px solid silver'"
           [title]= "dataItem.code.name"
      >{{dataItem.code.key}}</div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Значение"
                     [width]="50"
                     class="no-padding unselectable"
                     headerClass="graph-column-header"
                     [editable]="gridToolbarManagementService.isEditing$ | async"
                     editor="numeric"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div style="display:flex; flex-direction: column; justify-content: space-between; height: 3em;">
        <div class="content-center" style="flex: 1 1 auto; justify-content: center;"
             [style.height]="dataItem.code.supportValue2 && !dataItem.code.isComputedFlag? 'auto' : '3em'"
             [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : '1px solid silver'"
        >{{dataItem.tableRowValue | kendoNumber : '#0.##'}}</div>
        <div *ngIf="dataItem.code.supportValue2"
             style="display:flex;justify-content: center;">
          {{dataItem.percent | kendoNumber : '#0.##\\%'}}
        </div>
      </div>

    </ng-template>
    <ng-template kendoGridEditTemplate let-rowIndex ="rowIndex" let-column="column" let-dataItem="dataItem">
      <div style="size: 3em;">
        <kendo-numerictextbox *ngIf="!dataItem.code.isEqualBaseRowValue"
          [spinners]="false"
                              format="#0.##\ч"
                              decimals="2"
                              min="0"
                              autoCorrect="true"
                              [(ngModel)]="dataItem.tableRowValue"
                              [style.height]="dataItem.code.supportValue2 && !dataItem.code.isComputedFlag ? '1.7em' : '3em'"
                              class="no-padding">
        </kendo-numerictextbox>
        <kendo-numerictextbox *ngIf="dataItem.code.supportValue2 && !dataItem.code.isComputedFlag"
                              [spinners]="false"
                              format="##.##\%"
                              min="0"
                              max="100"
                              autoCorrect="true"
                              decimals="2"
                              style="height: 1.7em;"
                              [(ngModel)]="dataItem.percent">
        </kendo-numerictextbox>
      </div>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let day of view.days; let dayIndex = index"
                     [title]="dayIndex + 1"
                     class="no-padding"
                     headerClass="graph-column-header"
                     [width]="36"
                     [style]="{background: day.color}"
  >
    <ng-template kendoGridCellTemplate let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div class="content-center" style="height: 3em; justify-content: center;"
           [style.backgroundColor]="view.source[rowIndex].cellModels[columnIndex - 7].dayWorked ?
                                    view.source[rowIndex].cellModels[columnIndex - 7].cellColor :
                                    'rgba(0,0,0,0.15)'"
           [style.borderTop] = "view.source[rowIndex].isFirstRow ? '1px solid grey' : '1px solid silver'"
           [title]="view.source[rowIndex].cellModels[columnIndex - 7].tooltipItem.dayDeviationName"
      >
        {{view.source[rowIndex].cellModels[columnIndex - 7].cellValue}}
      </div></ng-template>

  </kendo-grid-column>
</kendo-grid>

<kendo-contextmenu
  #gridMenu
  [items]="tableGridContextMenu"
  (select)="onSelectContextMenuOnRow($event)"
>
</kendo-contextmenu>

<ng-template #tooltipTemplate>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div style="text-align: center; font-size: 20px;">{{dataForTooltip.staffUnit.staffUnitType.description}}</div>
    <ng-container *ngTemplateOutlet="dataForTooltip.staffUnit.parentId ? tooltipTemplate1 : tooltipTemplate2"></ng-container>
  </div>
</ng-template>

<ng-template #tooltipTemplate1>
  <div style="margin-top: 10px; width: fit-content; display: grid; grid-template-rows: auto auto auto auto; grid-template-columns: auto auto; grid-gap: 10px 20px;">
    <div>За сотрудника:</div>
    <div>{{dataForTooltip.staffUnit.parentEpmloyeeFullName}}</div>
    <div>Дата начала:</div>
    <div>{{dataForTooltip.staffUnit.startDate | kendoDate:'dd.MM.yyy'}}</div>
    <div>Дата окончания:</div>
    <div>{{dataForTooltip.staffUnit.endDate | kendoDate:'dd.MM.yyy'}}</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">Размер выплаты:</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">{{dataForTooltip.staffUnit.percent}} %</div>
  </div>
</ng-template>

<ng-template #tooltipTemplate2>
  <div style="margin-top: 10px; width: fit-content; display: grid; grid-template-rows: auto auto auto auto; grid-template-columns: auto auto; grid-gap: 10px 20px;">
    <div>Дата начала:</div>
    <div>{{dataForTooltip.staffUnit.startDate | kendoDate:'dd.MM.yyy'}}</div>
    <div>Дата окончания:</div>
    <div>{{dataForTooltip.staffUnit.endDate | kendoDate:'dd.MM.yyy'}}</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">Размер выплаты:</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">{{dataForTooltip.staffUnit.percent}} %</div>
  </div>

</ng-template>

