import { Injectable, OnDestroy } from "@angular/core";
import {combineLatest, of, ReplaySubject} from "rxjs";
import { GraphDataSourceService } from "./graph-data-source.service";
import { map, switchMap, take, takeUntil } from "rxjs/operators";
import {
  GraphDayViewModel,
  GraphGridRowModel
} from "../classes/view-models/row-and-graph-day-view-model.class";
import { StaffUnitVM } from "../classes/view-models/staff-unit-view-model.class";
import { LoadingIndicatorService } from "../../../../../../../../src/app/services/loading-indicator.service";
import { GraphDataSourceHelperService } from "./graph-data-source-helper.service";
import { GraphNormFactCalculatorService } from "./graph-norm-fact-calculator.service";
import { AlertService } from "../../../../../../../../src/app/services/alert.service";
import { GridToolbarManagementService } from "../../services/grid-toolbar-management.service";
import { StaffUnit } from "../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { Api1GraphControlControllerService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import { KendoNotificationService } from "../../../../../../../../src/app/services/kendo-notification.service";
import { ResponseObjError } from "../../../../../../../../src/app/classes/requestResults/responseObjError";
import { EditMoonlighterService } from "../../services/edit-moonlighter.service";
import {
  EditProxyComponent_SaveEvent,
  EditProxyComponent_Settings,
  EditProxyComponent_ProxyStaffUnit, EditProxyComponent_ParentStaffUnit
} from "../../../edit-proxy/edit-proxy.component";
import { EditProxyService } from "../../services/edit-proxy.service";
import { LableAndTitle_Settings } from "../../../../Classes/LableAndTitle_Settings";
import { CellSelectingDirectiveService, SelectedCellsEventObj } from "../directives/cell-selecting.directive.service";
import { GraphEditService_SelectedEvent } from "./graph-cell-edit.service";
import { EditDutyService } from "../../services/edit-duty.service";
import {
  EditDutyComponent_SaveEvent,
  EditDutyComponent_Settings,
  EditDutyComponent_StaffUnit
} from "../../../edit-duty/edit-duty.component";
import {
  EditMoonlighterComponent_Settings,
  EditMoonlighterComponent_StaffUnit
} from "../../../edit-moonlighter/i-edit-moonlighter-component";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { traceClass } from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { trace } from "../../../../../../../../src/app/modules/trace/operators/trace";
import {
  StaffUnitTypeEnum,
  StaffUnitTypeEnumHelper
} from "../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {DayDeviationEnum} from "../../../../../../../../src/app/classes/domain/enums/day-deviation.enum";

/** Сервис для редактирования строк графика */
@Injectable()
@traceClass('GraphRowEditService')
export class GraphRowEditService implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(public graphDataSourceService: GraphDataSourceService,
    private loadingIndicatorService: LoadingIndicatorService,
    private graphDataSourceHelperService: GraphDataSourceHelperService,
    private graphNormFactCalculatorService: GraphNormFactCalculatorService,
    private alertService: AlertService,
    private gridToolbarManagementService: GridToolbarManagementService,
    private api1GraphControlControllerService: Api1GraphControlControllerService,
    private kendoNotificationService: KendoNotificationService,
    private editProxyService: EditProxyService,
    private editMoonlighterService: EditMoonlighterService,
    private cellSelectingDirectiveService: CellSelectingDirectiveService,
    private editDutyService: EditDutyService,
    private displayErrorsService: DisplayErrorsService,
    private readonly tracerService: TracerServiceBase
  ) {

    this.editMoonlighterService.save$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      switch (value.type) {
        case "edit":
          this.onEditedMoonlighter(value.staffUnit);
          break;
        case "add":
          this.addMoonlighter(value.staffUnit);
          break;
        default:
          throw new Error('out of range')
      }
    });

    this.editProxyService.save$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      switch (value.type) {
        case "add":
          this.onAddedProxy(value);
          break;
        case "edit":
          this.onEditedProxy(value);
      }
      //Перед изменением строки parent-а проверяем на наличие конфликтов отклонений, проставленных у parent-а с выбранной причиной замещения
      // и, в случае необходимости, вызываем окно с предупреждением
      this.warningConflictDayDeviationDialogShow(value);
    });

    this.editDutyService.save$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      switch (value.type) {
        case "add":
          this.onAddDuty(value);
          break;
        case "edit":
          this.onEditDuty(value);
          break;
        default: throw new Error('out of range');
      }
    });
  }

  /** Удаление строки по StaffUnitOwnerId */
  @traceFunc()
  public deleteRow(staffUnitOwnerId: number, timestamp: []) {
    this.loadingIndicatorService.addToObservable(
      'Происходит удаление',
      this.api1GraphControlControllerService.deleteStaffUnit$(this.gridToolbarManagementService.redaction.id, staffUnitOwnerId, timestamp)
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.kendoNotificationService.showSuccess({ content: 'Удалено' })
        this.graphDataSourceService.removeRow(staffUnitOwnerId);
      }, error: error => {
        this.kendoNotificationService.showError({ content: 'НЕ получилось удалить' })
        this.displayErrorsService.handleError(error)
      }
    })
  }

  /** Обработка события добавления Proxy */
  @traceFunc()
  public onAddedProxy($event: EditProxyComponent_SaveEvent) {
    this.loadingIndicatorService.addToObservable(
      'Добавление строки Замещения в график',
      combineLatest(
        [this.graphDataSourceHelperService.findOrGetStaffUnitType$(this.graphDataSourceService, $event.staffUnit.typeId),
        this.graphDataSourceHelperService
          .findOrGetEmployee$(this.graphDataSourceService, $event.staffUnit.employeeId, this.gridToolbarManagementService.data.graphTable.month)]
      )
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        const parentRow = this.graphDataSourceService.source.find(x => x.staffUnit.ownerId == $event.staffUnit.parentId);
        const row = (() => {
          const temp = new GraphGridRowModel(
            this.graphNormFactCalculatorService,
            null,
            parentRow,
            null
          );

          temp.staffUnit = StaffUnitVM.Create2(
            temp,
            $event.staffUnit,
            value[0],
            null,
            null,
            value[1],
            null,
            null,
            $event.staffUnit.comment,
            $event.staffUnit.timestamp
          )

          temp.graphDays = this.graphDataSourceService.days.map(day => GraphDayViewModel.CreateEmpty(temp, day, null, false, null, null));
          return temp;
        })();

        this.graphDataSourceService.addRow(row);
      }, error: error => {
        this.alertService.defaultAlertOption.error().mod(x => {
          x.message = 'При загрузке информации о Замещении произошла ошибка.<br>Сохраните данные и перезагрузите страницу';
        }).showAlert();
      }
    });
  }

  /** Обработка события редактирования Proxy */
  @traceFunc()
  public onEditedProxy($event: EditProxyComponent_SaveEvent) {
    const row = this.graphDataSourceService.source.find(x => x.staffUnit.ownerId == $event.staffUnit.ownerId);
    if (!row) { throw new Error('Редактируемая строка не найдена') }
    row.staffUnit.startDate = $event.staffUnit.startDate;
    row.staffUnit.endDate = $event.staffUnit.endDate;
    row.staffUnit.rate = $event.staffUnit.rate;
    row.staffUnit.comment = $event.staffUnit.comment;
    row.staffUnit.timestamp = $event.staffUnit.timestamp;
    row.staffUnit.percent = $event.staffUnit.percent;
    row.staffUnit.milk = $event.staffUnit.milk;

    row.staffUnit.asObservable.onChange();
    row.onChangedGraphDay(null);
  }

  /** Добавления Moonlighter */
  @traceFunc()
  public addMoonlighter(staffUnit: StaffUnit) {
    this.getForAddOrEditMoonlighterData$(staffUnit).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const row = (() => {
        const temp = new GraphGridRowModel(
          this.graphNormFactCalculatorService,
          null,
          null,
          null
        );

        temp.staffUnit = StaffUnitVM.Create2(
          temp,
          staffUnit,
          value.staffUnitType,
          value.position,
          value.occupation,
          value.employee,
          value.workMode,
          value.financingSource,
          staffUnit.comment,
          staffUnit.timestamp
        )

          temp.graphDays = this.graphDataSourceService.days.map(day => GraphDayViewModel.CreateEmpty(temp, day, null, false, null, null));
        return temp;
      })();

      this.graphDataSourceService.addRow(row);
    })
  }

  /** Событие добавления Дежурства */
  @traceFunc()
  public onAddDuty($event: EditDutyComponent_SaveEvent) {
    this.getForAddOrEditMoonlighterData$($event.staffUnit)
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe( { next: value => {
        const row = (() => {
        const temp = new GraphGridRowModel(
          this.graphNormFactCalculatorService,
          null,
          null,
          null
        );

        temp.staffUnit = StaffUnitVM.Create2(
          temp,
          $event.staffUnit,
          value.staffUnitType,
          value.position,
          value.occupation,
          value.employee,
          value.workMode,
          value.financingSource,
          $event.staffUnit.comment,
          $event.staffUnit.timestamp
        );

          temp.graphDays = this.graphDataSourceService.days.map(
            day => GraphDayViewModel.CreateEmpty(temp, day, null, false, null, null));

        return temp;
      })();

      this.graphDataSourceService.addRow(row);
      this.cellSelectingDirectiveService.clear();
    }});
  }

  /** Событие редактирования Дежурства */
  @traceFunc()
  public onEditDuty($event: EditDutyComponent_SaveEvent) {
    this.getForAddOrEditMoonlighterData$($event.staffUnit)
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe({
        next: value => {
          const row = this.graphDataSourceService.source
            .find(x => x.staffUnit.ownerId == $event.staffUnit.ownerId);

          if (!row) throw new Error('Редактируемая строка не найдена');

          row.staffUnit.startDate = $event.staffUnit.startDate;
          row.staffUnit.endDate = $event.staffUnit.endDate;
          row.staffUnit.position = value.position;
          row.staffUnit.occupation = value.occupation;
          row.staffUnit.workMode = value.workMode;
          row.staffUnit.financingSource = value.financingSource;
          row.staffUnit.milk = $event.staffUnit.milk;
          row.staffUnit.percent = $event.staffUnit.percent;
          row.staffUnit.comment = $event.staffUnit.comment;
          row.staffUnit.timestamp = $event.staffUnit.timestamp;

          row.staffUnit.asObservable.onChange();
          row.onChangedGraphDay(null);
        }, error: error => {
          this.displayErrorsService.handleError(error);
        }
      });
  }

  /** Открыть диалог редактирования Proxy */
  @traceFunc()
  public openEditProxyDialog(staffUnitOwnerId: number, combinationType: 'proxy' | 'combination' | 'uvor') {
    const proxyRow = this.graphDataSourceService.source.find(x => x.staffUnit.ownerId == staffUnitOwnerId);

    const staffUnitTypeId = proxyRow.staffUnit.staffUnitType.id;

    if (!proxyRow ||
      !proxyRow.staffUnit.isProxy ||
      (!StaffUnitTypeEnumHelper.isMoonlighter(staffUnitTypeId) && !StaffUnitTypeEnumHelper.isCombination(staffUnitTypeId) && staffUnitTypeId !== StaffUnitTypeEnum.Uvor)) {
      throw new Error('Передан НЕ валидный staffUnitOwnerId')
    }
    const graphDays = proxyRow.parent.graphDays.filter(x => x.day.date >= proxyRow.staffUnit.startDate && x.day.date <= proxyRow.staffUnit.endDate).sort();
    const selectedDayDeviation = this.editProxyService.changeGraphDaysAndReturnDayDeviation(graphDays);
    const parentStaffUnit = proxyRow.parent.staffUnit;

    this.editProxyService
      .open(new EditProxyComponent_Settings(
        'edit',
        this.gridToolbarManagementService.data.graphTable.month,
        this.gridToolbarManagementService.redaction.id,
        this.gridToolbarManagementService.data.graphTable.subdivisionOwnerId,
        proxyRow.parent.staffUnit.employee.ownerId,
        new EditProxyComponent_ProxyStaffUnit(
          proxyRow.staffUnit.ownerId,
          proxyRow.staffUnit.employee.fullName,
          proxyRow.staffUnit.startDate,
          proxyRow.staffUnit.endDate,
          proxyRow.staffUnit.rate,
          proxyRow.staffUnit.staffUnitType.id,
          StaffUnitTypeEnumHelper.isMoonlighter(proxyRow.staffUnit.staffUnitType.id) && proxyRow.staffUnit.milk,
          proxyRow.staffUnit.comment,
          proxyRow.staffUnit.timestamp,
          proxyRow.staffUnit.percent),
        new EditProxyComponent_ParentStaffUnit(
          parentStaffUnit.ownerId,
          parentStaffUnit.employee.fullName,
          parentStaffUnit.startDate,
          parentStaffUnit.endDate,
          parentStaffUnit.rate,
          parentStaffUnit.position.ownerId),
        new LableAndTitle_Settings(
          "Редактировать заместителя",
          "Замещаемый:",
          "Заместитель:",
          `Причина замещения:`),
        selectedDayDeviation,
        graphDays[0].day.date,
        graphDays[graphDays.length - 1].day.date,
        combinationType,
        proxyRow.parent.graphDays.filter(x => x.dayDeviation?.id == DayDeviationEnum.ИО).map(x => x.day.date)));
  }

  /** Открыть диалог редактирования Moonlighter */
  @traceFunc()
  public openEditMoonlighterDialog(staffUnitOwnerId: number, combinationType: 'moonlighter' | 'combination' | 'uvor') {
    const row = this.graphDataSourceService.source.find(x => x.staffUnit.ownerId == staffUnitOwnerId);

    const staffUnitTypeId = row.staffUnit.staffUnitType.id;

    if (!row || row.staffUnit.isProxy ||
      (!StaffUnitTypeEnumHelper.isMoonlighter(staffUnitTypeId) && ! StaffUnitTypeEnumHelper.isCombination(staffUnitTypeId) && staffUnitTypeId !== StaffUnitTypeEnum.Uvor))
    {
      throw new Error('Передан НЕ валидный staffUnitOwnerId');
    }

    this.editMoonlighterService
      .open(new EditMoonlighterComponent_Settings(
        'edit',
        this.gridToolbarManagementService.data.graphTable.month,
        this.gridToolbarManagementService.redaction.id,
        this.gridToolbarManagementService.data.graphTable.subdivisionOwnerId,
        row.staffUnit.employee.ownerId,
        new EditMoonlighterComponent_StaffUnit(
          row.staffUnit.ownerId,
          row.staffUnit.startDate,
          row.staffUnit.endDate,
          row.staffUnit.rate,
          row.staffUnit.financingSource.id,
          row.staffUnit.staffUnitType.id,
          row.staffUnit.position.ownerId,
          StaffUnitTypeEnumHelper.isMoonlighter(row.staffUnit.staffUnitType.id) && row.staffUnit.milk,
          row.staffUnit.comment,
          row.staffUnit.timestamp,
          row.staffUnit.percent),
        combinationType))
  }

  /** Обработка события свершившегося редактирования Moonlighter */
  @traceFunc()
  public onEditedMoonlighter(staffUnit: StaffUnit) {
    const row = this.graphDataSourceService.source.find(x => x.staffUnit.ownerId == staffUnit.ownerId);
    if (!row) {
      throw new Error('Редактируемая строка не найдена')
    }

    this.getForAddOrEditMoonlighterData$(staffUnit).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        row.staffUnit.position = value.position;
        row.staffUnit.occupation = value.occupation;
        row.staffUnit.workMode = value.workMode;
        row.staffUnit.financingSource = value.financingSource;
        row.staffUnit.startDate = staffUnit.startDate;
        row.staffUnit.endDate = staffUnit.endDate;
        row.staffUnit.rate = staffUnit.rate;
        row.staffUnit.milk = staffUnit.milk;
        row.staffUnit.comment = staffUnit.comment;
        row.staffUnit.timestamp = staffUnit.timestamp;
        row.staffUnit.percent = staffUnit.percent;

        row.staffUnit.asObservable.onChange();
        row.onChangedGraphDay(null);
      }, error: error => {
        this.displayErrorsService.handleError(error);
      }
    });
  }

  /** Открыть диалоговое окно редактирования дежурства */
  @traceFunc()
  public openEditDutyDialog(staffUnitOwnerId: number) {
    const row = this.graphDataSourceService.source.find(x => x.staffUnit.ownerId == staffUnitOwnerId);
    if (!row || !(row.staffUnit.staffUnitType.id == StaffUnitTypeEnum.Duty)) {
      throw new Error('Передан НЕвалидный staffUnitOwnerId');
    }
    this.editDutyService
      .open(new EditDutyComponent_Settings(
        'edit',
        this.gridToolbarManagementService.data.graphTable.month,
        this.gridToolbarManagementService.redaction.id,
        this.gridToolbarManagementService.data.graphTable.subdivisionOwnerId,
        new EditDutyComponent_StaffUnit(
          row.staffUnit.ownerId,
          row.staffUnit.employee.fullName,
          row.staffUnit.employee.ownerId,
          row.staffUnit.startDate,
          row.staffUnit.endDate,
          row.staffUnit.rate,
          row.staffUnit.position.ownerId,
          row.staffUnit.milk,
          row.staffUnit.comment,
          row.staffUnit.timestamp)));
  }


  /** Получить данные для обновления/добавления строки Moonlighter */
  @traceFunc()
  private getForAddOrEditMoonlighterData$(staffUnit: StaffUnit) {
    const firstPart = combineLatest(
      [this.graphDataSourceHelperService.findOrGetStaffUnitType$(this.graphDataSourceService, staffUnit.typeId),
      this.graphDataSourceHelperService
        .findOrGetEmployee$(this.graphDataSourceService, staffUnit.employeeId, this.gridToolbarManagementService.data.graphTable.month),
      this.graphDataSourceHelperService.findOrGetPosition$(this.graphDataSourceService, staffUnit.positionId, this.gridToolbarManagementService.data.graphTable.month),
      ]
    );

    return firstPart.pipe(switchMap(value => {
      const position = value[2];
      return combineLatest(
        [this.graphDataSourceHelperService.findOrGetOccupation$(this.graphDataSourceService, position.occupationId, this.gridToolbarManagementService.data.graphTable.month),
        this.graphDataSourceHelperService.findOrGetWorkMode$(this.graphDataSourceService, position.workModeId, this.gridToolbarManagementService.data.graphTable.month),
          !staffUnit.financingSourceId ? of(null) : this.graphDataSourceHelperService.findOrGetFinancingSource$(this.graphDataSourceService, staffUnit.financingSourceId)]
      ).pipe(map(value1 => {
        return {
          staffUnitType: value[0],
          employee: value[1],
          position: value[2],
          occupation: value1[0],
          workMode: value1[1],
          financingSource: value1[2]
        }
      }))
    }))
  }

  /** Вывести сообщение о возможном конфликте причины замещения/совмещения */
  @traceFunc()
  private warningConflictDayDeviationDialogShow(saveEv: EditProxyComponent_SaveEvent): void {
    this.cellSelectingDirectiveService.selecting2$.pipe(takeUntil(this.streams$.unsubscribe), take(1)).subscribe(value => {
      const parentRow = saveEv.type == 'add' ? GraphEditService_SelectedEvent.Create(value).row : GraphEditService_SelectedEvent.Create(value).row.parent;
      //Все GraphDay, относящиеся к выбранному диаппазону замещения/совмещения с учетом возможных изменений при редактировании периода замещения/совмещения
      const parentGraphDaysForProxy = parentRow.graphDays
        .filter(x => saveEv.staffUnit.startDate <= x.day.date && saveEv.staffUnit.endDate >= x.day.date);
      //Проставленная у parent-а причина замещения
      const parentDayDeviation = this.editProxyService.changeGraphDaysAndReturnDayDeviation(parentGraphDaysForProxy);
      // Если невозможно однозначно определить причину замещения, проставленную у parent-а
      // или имеющаяся у parent-а причина замещения/совмещения не совпадает с выбранной в окне добавления,
      // то вызываем окно с предупреждением о затирании данных proxy за выбранный период
      if (!parentDayDeviation || parentDayDeviation?.id !== saveEv.deviation?.id) {
        this.alertService.defaultAlertOption.confirmation().mod(x => {
          x.message = `<strong>При добавлении</strong> заместителя <strong>в выбранном интервале времени</strong> обнаружен один из следующих <strong>конфликтов:</strong>
•\tприсутствуют разные причины отклонений;
•\tприсутствуют временные интервалы;
•\tприсутствуют дни, в которых не проставлено отклонение.<br>
В случае нажатия кнопки <strong>"Подтвердить"</strong> у замещаемого в графике <strong>произойдет изменение причины отклонения</strong> на выбранный Вами в поле "Причина замещения/совмещения" (если поле было не заполнено, то данные останутся без изменения)<br>
В случае нажатия кнопки <strong>"Отмена"</strong> у замещаемого данные в графике <strong>останутся без изменений</strong>.`;
          x.buttons[0].text = 'Подтвердить';
          x.buttons[0].isPrimary = true;
          x.buttons[0].callBack = () => {
            if (saveEv.deviation) {
              parentRow.graphDays.forEach(graphDay => {
                if (saveEv.staffUnit.startDate <= graphDay.day.date && saveEv.staffUnit.endDate >= graphDay.day.date) {
                  graphDay.dayDeviation = saveEv.deviation;
                  if (graphDay.dayDeviation && !graphDay.dayDeviation.deviationWithInterval) {
                    graphDay.timeInterval = null;
                  }

                  graphDay.onChange();
                }
              });
            }
            //снимаем выделение с выделенного диаппазона ячеек
            this.cellSelectingDirectiveService.clear();
          };
          x.buttons[1].text = 'Отмена';
          x.buttons[1].isPrimary = false;
          x.buttons[1].callBack = () => {
            //снимаем выделение с выделенного диаппазона ячеек
            this.cellSelectingDirectiveService.clear();
          };
        }).showAlert();
      }
    })
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

}
