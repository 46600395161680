import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { ISelectWorkingEmployeesComponent } from "../i-select-working-employees.component";
import { SelectWorkingEmployeesComponentDataService, SelectWorkingEmployeesComponentGridItem } from "./select-working-employees-component-data.service";
import { process, State} from '@progress/kendo-data-query';

@Injectable()
export class SelectWorkingEmployeesComponentService extends ComponentServiceBase<ISelectWorkingEmployeesComponent> implements OnDestroy  {

  /** Загруженные данные */
  private _allData: SelectWorkingEmployeesComponentGridItem[];

  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private dataService: SelectWorkingEmployeesComponentDataService) {
    super();
  }

  /**
   * Функция фильтрации поиска, для грида
   */
   public filterData(inputValue: string): void {
    this.component.gridState.filter = {
      logic: "or",
      filters: [
        {
            field: 'fio',
            operator: 'contains',
            value: inputValue
        },
        {
          field: 'code',
          operator: 'contains',
          value: inputValue
        },
        {
          field: 'subdivisionsString',
          operator: 'contains',
          value: inputValue
        },
        {
          field: 'occupationsString',
          operator: 'contains',
          value: inputValue
        }
      ],
    };
    this.component.gridState.skip = 0;
    this._processGridData(this._allData);
  }

  /** Обновление данных */
  public reloadData(forDate?: Date, startDate?: Date, endDate?: Date) {
    this.dataService.getWorkingEmployees$(forDate,startDate,endDate).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this._allData = value;
      this.component.gridState.skip = 0;

      //Очищаем SelectedKeys, удаляя те, которых не существует в выборке
      this.component.selectedKeys = this.component.selectedKeys.filter(sk => value.some(dataItem=> dataItem.id === sk));

      this._processGridData(value);
    });
  }

  /** Событие происходит при изменении состояния грида (будь то изменениесортировки, пагинация и т.д) */
  public onDataStateChange(state:State){
    this.component.gridState = state;
    this._processGridData(this._allData);
  }

  /** Получить элементы по списку id */
  public getItemsByIds(ids: number[]) {
    return this._allData.filter(v=> ids.some(s=> s === v.id));
  }

  /** Событие происходит при изменении выбранных элеентов в гриде */
  public selectedKeysChanged(newSelectedKeys: number[]) {
    this.component.selectedKeys = newSelectedKeys;
    this.component.selectedItems$.next(this.getItemsByIds(newSelectedKeys));
  }

  /**Обработать данные грида */
  private _processGridData(dataItems: any) {
    this.component.gridView = process(dataItems, this.component.gridState);
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.dataService.ngOnDestroy();
  }

}


