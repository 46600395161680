<kendo-grid
  [data]="gridView"
  [sortable]="{mode: 'single'}"
  [sort]="state.sort"
  (sortChange)= sortChange($event)
  [selectable]="{mode: 'single'}"
  KendoGridExpanded (dbCellClick)="onDblClick($event)"
  (cellClick)="onCellClick($event)"
  style="max-height: 100%"
  [pageable]="true"
  [skip]="skip"
  [pageSize]="pageSize"
  (pageChange)="pageChange($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="grid-header-content">
      <kendo-textbox class="search-input" placeholder="Поиск" [(value)]="searchValue" (afterValueChanged)="filterSource()" [clearButton]="true" ></kendo-textbox>
    </div>
  </ng-template>

  <kendo-grid-column title=""
                     [width]="5"
                     class="no-padding">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.isExternal"
           class="icon-staff-unit-external"></div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title=""
                     [width]="30"
                     class="no-padding">
    <ng-template kendoGridCellTemplate let-dataItem>
      <img *ngIf="dataItem.imageUrl"
           title=""
           class="staff-unit-img"
           [src]="dataItem.imageUrl"/>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field= "code"
    [width]="110"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;context: {text: '№',title: 'Табельный номер'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.code}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="occupationName"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;
                    context: {text: 'Должность', title: 'Должность сотрудника'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.occupationName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="employeeFullName"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;
                  context: {text: 'ФИО', title: 'Фамилия, имя и отчество сотрудника'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.employeeFullName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="startDate"
    [width]="100"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;context: {text: 'Начало',title: 'Дата начала работы'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.startDate | kendoDate: "dd.MM.yyyy"}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="endDate"
    [width]="100"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;context: {text: 'Окончание' ,title: 'Дата окончания работы'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.endDate | kendoDate: "dd.MM.yyyy"}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="rate"
    [width]="70"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;
                    context: {text: 'Ставка', title: 'Ставка'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.rate}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field= "subdivisionName"
  >
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="longHeaderTemplate;
                    context: {text: 'Подразделение', title: 'Подразделение'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.subdivisionName}}
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<ng-template #longHeaderTemplate let-text="text" let-title="title">
  <span [title]="title" style="overflow: hidden; text-overflow: ellipsis;">
    {{text}}
  </span>
</ng-template>
