<app-textbox-select [size]="size"
                    [disabled]="disabled"
                    [text]="(text$ | async) ?? 'НЕОБХОДИМО ВЫБРАТЬ ПОДРАЗДЕЛЕНИЕ'"
                    (click$)="isOpenDialog = true"
>
</app-textbox-select>

<app-subdivision-selecting-dialog *ngIf="isOpenDialog"
                                   [disableOkIfNotSelection]="!isSupportNotSelection"
                                   [disableOkIfNotSelectionChange]="true"
                                   [multipleSelected]="isSupportMultipleSelection"
                                   [displayTextFn]="selectionDialog_displayTextFn"
                                   [dataSourceService]="dataSourceService"
                                   [selection]="selectionService.tempSelection.data$ | async"
                                   (cancel$)="isOpenDialog = false"
                                   (select$)="onDialogSubdivisionSelect()"
>
</app-subdivision-selecting-dialog>
