import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  StaffUnitType
} from "../../graph-table-workspace/table-grid/classes/view-models/row-and-table-cell-view-model.class";

@Component({
  selector: 'app-staff-unit-type-list-settings',
  templateUrl: './staff-unit-type-list-settings.component.html',
  styleUrl: './staff-unit-type-list-settings.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffUnitTypeListSettingsComponent {

  /** Выбранные типы занятости */
  public checkedStaffUnitTypes: StaffUnitType[] = [];
  /** Тип занятости не выбран */
  public disabled: boolean = true;

  @Input() public dataSource: StaffUnitType[];
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() public select$: EventEmitter<number[]> = new EventEmitter<number[]>();


  /** Обработка события выбора в списке типов исполнения должностей */
  public setCheckedStaffUnitTypes($event: StaffUnitType[]) {
    this.checkedStaffUnitTypes = $event;
    this.disabled = !$event.length;
  }

  /** Обработка события нажания на кнопку "Выбрать" */
  public onOk() {
    this.select$.emit(this.checkedStaffUnitTypes.map(s => s.id));
    this.select$.complete();
  }

  /** Обработка события нажания на кнопку "Отмена" */
  public onCancel() {
    this.cancel$.emit();
    this.cancel$.complete();
  }
}
