import {Injectable} from "@angular/core";
import {FreeRateGridService_RowItem} from "../free-rate-grid/free-rate-grid.service";
import {IDropDownItem} from "../../../../../../../src/app/classes/requestResults/iDropDownItem";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormServiceBase} from "../../../../../../../src/app/services/abstracts/form-service-base";
import {IEmployee} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";

/** Сервис работы с формой */
@Injectable()
export class EditMoonlighterComponentFormService extends FormServiceBase<EditMoonlighterComponentFormService_InitFormParam, FormControls>{

  public create(param: EditMoonlighterComponentFormService_InitFormParam){
    const formControls = new FormControls(
      new UntypedFormControl(param.baseEmployeeValue, [Validators.required]),
      new UntypedFormControl(param.freeIntervalValue, [Validators.required]),
      new UntypedFormControl({value: param.rateValue, disabled: !param.rateValue}, [Validators.required]),
      new UntypedFormControl({value: param.startValue, disabled: !param.startValue}, [Validators.required]),
      new UntypedFormControl({value: param.endValue, disabled: !param.endValue}, [Validators.required]),
      new UntypedFormControl({value: param.percentValue, disabled: !param.percentValue}),
      new UntypedFormControl(param.type, [Validators.required]),
      new UntypedFormControl(param.comment),
      new UntypedFormControl(param.proxyHours),
      new UntypedFormControl(),
      new UntypedFormControl(param.milk, [Validators.required]),
    )

    return new EditMoonlighterComponentFormService_Result(formControls, new UntypedFormGroup(super.getForFromGroup(formControls)));
  }
}

/** Результат метода initForm */
export class EditMoonlighterComponentFormService_Result{
  public constructor(public readonly controls: FormControls,
                     public readonly form: UntypedFormGroup) {
  }
}

export class EditMoonlighterComponentFormService_InitFormParam{
  constructor(public readonly baseEmployeeValue: BaseEmployee,
              public readonly freeIntervalValue: FreeRateGridService_RowItem,
              public readonly rateValue: number,
              public readonly percentValue: number,
              public readonly startValue: Date,
              public readonly endValue: Date,
              public readonly type: IDropDownItem,
              public readonly comment: string,
              public readonly proxyHours: number,
              public readonly milk: boolean) {
  }
}

export class FormControls{
  public constructor(public readonly baseEmployee: UntypedFormControl,
                     public readonly freeInterval: UntypedFormControl,
                     public readonly rate: UntypedFormControl,
                     public readonly start: UntypedFormControl,
                     public readonly end: UntypedFormControl,
                     public readonly percent: UntypedFormControl,
                     public readonly typeEmployment: UntypedFormControl,
                     public readonly comment: UntypedFormControl,
                     public readonly proxyHours: UntypedFormControl,
                     public readonly proxyDays: UntypedFormControl,
                     public readonly milk: UntypedFormControl
  ) {  }
}

export class BaseEmployee implements Pick<IEmployee, 'ownerId' | 'startDate' | 'endDate' | 'code'> {
  constructor(
    public ownerId: number,
    public startDate: Date,
    public endDate: Date,
    public code: string,
    public fio: string
  ) {}
}
