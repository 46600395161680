<kendo-textbox *ngIf="(asObservable.stream$ | async) as component"
               [readonly]="true"
               [value]="component.text"
               [disabled]="component.disabled"
               [size]="component.size"
               class="textbox"
>
  <ng-template kendoTextBoxSuffixTemplate>
    <kendo-textbox-separator></kendo-textbox-separator>
    <button kendoButton
            fillMode="clear"
            [icon]="'folder-open'"
            title="Выбрать"
            (click)="onButtonClick()"
    ></button>
  </ng-template>
</kendo-textbox>
