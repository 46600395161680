import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WebApi1Service } from "../web-api1.service";
import { defer, Observable, of, ReplaySubject } from "rxjs";
import { IDropDownItem } from "../../../../classes/requestResults/iDropDownItem";
import { takeUntil, tap } from "rxjs/operators";
import { AuthService } from "../../../../modules/auth/services/auth.service";
import { VichLog } from "src/app/classes/domain/POCOs/timesheet/VichLog";
import {ITuberRegisterSettingsResponse, registerFieldEnum} from "./api1-tuber-controller.service";
import {
  VichRegisterTypeSettingsSet
} from "../../../../components/vich/vich-register-grid/services/vich-register-grid-component.service";

@Injectable({
  providedIn: "root"
})
export class Api1VichControllerService implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly httpClient: HttpClient,
              private readonly webApi1Service: WebApi1Service,
              private readonly authService: AuthService
  ) {
    this.authService.isAuth$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if (!value) {
        this._typesService = null;
      }
    })
  }

  private _typesService: Array<IDropDownItem> = null;
  /** Получить список ВИЧ видов исследования (КЭШ) */
  public get typesService_cached$(): Observable<Array<IDropDownItem>> {
    return defer(() => {
      return this._typesService == null ?
        this.typesService$.pipe(tap(value => this._typesService = value)) :
        of(this._typesService);
    });
  }
  /** Получить список ВИЧ видов исследования */
  public get typesService$(): Observable<Array<IDropDownItem>> {
    return this.httpClient
      .get<Array<IDropDownItem>>(this.webApi1Service.controllers.vich.actions.getTypesService.toString());
  }

  private _typesResearch: Array<IDropDownItem> = null;
  /** Получить список ВИЧ видов обслуживания (КЭШ) */
  public get typesResearch_cached$(): Observable<Array<IDropDownItem>> {
    return defer(() => {
      return this._typesResearch == null ?
        this.typesResearch$.pipe(tap(value => this._typesResearch = value)) :
        of(this._typesResearch);
    });
  }
  /** Получить список ВИЧ видов обслуживания */
  public get typesResearch$(): Observable<Array<IDropDownItem>> {
    return this.httpClient
      .get<Array<IDropDownItem>>(this.webApi1Service.controllers.vich.actions.getTypesResearch.toString());
  }

  private _typesWork: Array<ITypeWorkAndMaterial> = null;
  /** Получить список ВИЧ видов обслуживания (КЭШ) */
  public get typesWork_cached$(): Observable<Array<ITypeWorkAndMaterial>> {
    return defer(() => {
      return this._typesWork == null ?
        this.typesWork$.pipe(tap(value => this._typesWork = value)) :
        of(this._typesWork);
    });
  }
  /** Получить список ВИЧ типов работы, материалов */
  public get typesWork$(): Observable<Array<ITypeWorkAndMaterial>> {
    return this.httpClient
      .get<Array<ITypeWorkAndMaterial>>(this.webApi1Service.controllers.vich.actions.getTypesWorkAndMaterialNamesWithType.toString());
  }

  private _registerSettingsModels: IVichRegisterSettingsResponse = null;
  /** Получить отложенный стрим, содержащий параметры ВИЧ журналов всех типов и версий (КЭШ) */
  public get registerSettingsModels_cached$(): Observable<IVichRegisterSettingsResponse> {
    return defer(() => {
      return this._registerSettingsModels == null
        ? this.registerSettingsModels$.pipe(tap(value =>
          this._registerSettingsModels = value))
        : of(this._registerSettingsModels);
    });
  }

  /** Получить параметры ВИЧ журналов всех типов и версий */
  public get registerSettingsModels$(): Observable<IVichRegisterSettingsResponse> {
    return this.httpClient
      .post<IVichRegisterSettingsResponse>(
        this.webApi1Service.controllers.vich.actions.getVichRegisterSettings.toString(),
        {});
  }

  /** Добавить запись о случае работы с ВИЧ */
  public addLog$(model: IAddVichLogRequest): Observable<VichLog> {
    return this.httpClient.post<VichLog>(
      this.webApi1Service.controllers.vich.actions.addLog.toString(),
      model
    );
  }

  /** Редактирование записи о случае работы с ВИЧ */
  public editLog$(model: IEditVichLogRequest): Observable<VichLog> {
    return this.httpClient.post<VichLog>(
      this.webApi1Service.controllers.vich.actions.editLog.toString(),
      model
    );
  }

  /** Удалить запись о случае работы с ВИЧ */
  public removeLog$(id: number): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.vich.actions.removeLog.toString(), { id }
    );
  }

  /** Получить случаи работы сотрудника с ВИЧ на дату */
  public getLogs$(staffUnitOwnerIds: Array<number>, date: Date): Observable<Array<VichLog>> {
    return this.httpClient.post<Array<VichLog>>(
      this.webApi1Service.controllers.vich.actions.getLogs.toString(), { staffUnitOwnerIds, date }
    );
  }

  /** Может ли пользователь редактировать добавлять удалять данные на дату */
  public getCanAddEditRemove$(date: Date): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.vich.actions.canAddEditRemove.toString(), { date }
    );
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

export interface IAddVichLogRequest extends IVichLogEditingProperty {
  date: Date,
  descriptorId: number
}

export interface IEditVichLogRequest extends IVichLogEditingProperty {
  id: number
}

export interface IVichLogEditingProperty {
  staffUnitId: number
  diseaseNumber: string,
  patientFio: string,
  typeServiceId: number
  typeResearchId: number
  customTypeResearch: string
  typeWorkId: number
  customTypeWork: string
  researchCount: number
  researchProtocol: string
  subdivisionId: number
  operatingTime: number
}

export interface IVichRegisterSettingsResponse{
  missedFieldIds: registerFieldEnum[],
  settingsSets: VichRegisterTypeSettingsSet[]
}

export interface ITypeWorkAndMaterial{
  id: number,
  text: string,
  type: number
}

