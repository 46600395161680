import {ObservableEmittedType} from "../../../../../../../../../../src/app/types/observable.type";
import {CellDataDirective, GraphGrid2Component} from "../../components/graph-grid2/graph-grid2.component";
import {Observable, ReplaySubject, skipWhile, switchMap, tap} from "rxjs";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {MenuItem} from "@progress/kendo-angular-menu";
import {StaffUnit} from "../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {StaffUnitTypeEnum} from "../../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {Injectable, OnDestroy} from "@angular/core";
import {
  DataSource,
  DataSourceReadOnly
} from "../../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {
  Api1PrintReportControllerService
} from "../../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import * as FileSaver from "file-saver";
import * as moment from "moment/moment";
import {
  DisplayErrorsService
} from "../../../../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {
  TracerServiceBase
} from "../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {exErrorHandler} from "../../../../../../../../../../src/app/operators/ex-error-handler";
import {traceFunc} from "../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TraceParamEnum
} from "../../../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../../../../src/app/modules/trace/decorators/param.decorator";
import {GraphRowEdit2Service} from "../graph-row-edit2.service";
import {GraphGridDataSource_DataItem} from "../data-sources/graph-grid-data-sources/graph-grid-data-source.classes";
import {
  GraphGrid_GraphDayCell
} from "../data-sources/graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.classes";
import {IGraph} from "../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/Graph";
import {IDayDeviation} from "../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {DateHelper} from "../../../../../../../../../../src/app/helpers/dateHelper";

/** Тип события контекста */
type ContextEmitType = ObservableEmittedType<GraphGrid2Component['rowContextMenu']>;

/** Тип элемента контекста */
type ContextItemType = Readonly<Required<Pick<MenuItem, 'text' | 'icon'>> & {
  items: ContextItemType[],
  onClick: () => void,
  disabled: boolean,
}>;

/** Тип функции получения стрима устаревания редактирования. При первом эмите окно должно закрыться */
type RowEditExpiredGetterType = (staffUnitId: number) => Observable<void>;

/** Тип функции получения текущей редакции */
type RedactionGetterType = () => {id: number};
/** Тип функции получения текущего графика */
type GraphGetterType = () => Pick<IGraph, 'id' | 'month' | 'subdivisionId'>

/** Тип функции получения родительской строки по идентификатору proxy */
type ParentRowGetterType = (proxyId: string) => GraphGridDataSource_DataItem;
/** Тип функции получения ячеек по идентификатору исполнения должности */
type CellsGetterType = (staffUnitId: number) => GraphGrid_GraphDayCell[];
/** Функция установки {@link IDayDeviation} */
type DayDeviationSetterType = (cells: GraphGrid_GraphDayCell[], dayDeviation: IDayDeviation) => void;

/** Тип события сервиса */
type EventType = {
  target: HTMLElement,
  items: ContextItemType[],
  onClick: (item: ContextItemType) => void,
  onClose: () => void,
}

/** Сервис контекста строки */
@Injectable()
@traceClass('GraphRowContextService')
export class GraphRowContextService implements OnDestroy{
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  private readonly _contextDataSource = new DataSource<EventType>()
  /** Источник данных для контекста */
  public get contextDataSource(): DataSourceReadOnly<EventType> {
    return this._contextDataSource;
  }

  /** Конструктор */
  constructor(private readonly api1PrintReportControllerService: Api1PrintReportControllerService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly graphRowEditService: GraphRowEdit2Service,

              private readonly tracerService: TracerServiceBase) {

  }

  private _isInitialized = false;
  /**
   * Инициализировать сервис
   * @param context$ событие контекста
   * @param isGraphEditReplaySubject$ стрим редактируется ли график или нет. Должен быть {@link ReplaySubject}
   * @param redactionGetter функция получения текущей редакции
   * @param graphGetter функция получения текущего графика
   * @param parentRowGetter функция получения родительской строки по идентификатору proxy
   * @param cellsGetter функция получения ячеек графика по идентификатору исполнения должности
   * @param dayDeviationSetter функция установки отклонений в ячейку
   * @param rowEditExpiredGetter функция получения стрима который сообщает об необходимости завершить редактирование(закрыть окно)
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public onInit(context$: Observable<ContextEmitType>,
                isGraphEditReplaySubject$: Observable<boolean>,
                redactionGetter: RedactionGetterType,
                graphGetter: GraphGetterType,
                parentRowGetter: ParentRowGetterType,
                cellsGetter: CellsGetterType,
                dayDeviationSetter: DayDeviationSetterType,
                rowEditExpiredGetter: RowEditExpiredGetterType){
    if(this._isInitialized){
      throw new Error('Повторная инициализация сервисе НЕ поддерживается');
    }

    this._isInitialized = true;

    context$
      .pipe(
        filter(x => x.cells.length === 1),
        switchMap(value => {
          return isGraphEditReplaySubject$.pipe(
            map(isEdit => {
              return {
                ...value,
                isEdit: isEdit
              }
            }),
            takeUntil(
              value.expired$
                .pipe(
                  tap(() => {
                    if(!!this._contextDataSource.data){
                      this._contextDataSource.setData(undefined)
                    }
                  })
                )
            )
          )
        }),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        this._contextDataSource.setData({
          target: value.htmlElement,
          items: this.getContextItems(
            value.isEdit,
            value.cells[0],
            redactionGetter,
            value.expired$,
            graphGetter,
            parentRowGetter,
            cellsGetter,
            dayDeviationSetter,
            rowEditExpiredGetter,
            isGraphEditReplaySubject$,
          ),
          onClick: (item) => {
            item.onClick();
          },
          onClose: () => {this._contextDataSource.setData(undefined)}
        })
    })
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this._contextDataSource.onDestroy();
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

  /** Получить элементы для контекста */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private getContextItems(@traceParam() isEdit: boolean,
                          cell: CellDataDirective,
                          redactionGetter: RedactionGetterType,
                          expired$: Observable<any>,
                          graphGetter: GraphGetterType,
                          parentRowGetter: ParentRowGetterType,
                          cellsGetter: CellsGetterType,
                          dayDeviationSetter: DayDeviationSetterType,
                          rowEditExpiredGetter: RowEditExpiredGetterType,
                          isGraphEditReplaySubject$: Observable<boolean>,): ContextItemType[] {
    const staffUnit = cell.row.staffUnit;

    /** Дополнительная информация исполнения должности */
    const staffUnitInfo = (() => {
      return {
        isInMonth: StaffUnit.inMonth(staffUnit.startDate, staffUnit.endDate),
        isBase: StaffUnit.isBasic(staffUnit.typeId)
      }
    })();

    const result: ContextItemType[] = [];

    if(isEdit){
      result.push(
        createItem('Редактировать', 'edit', !staffUnitInfo.isInMonth || staffUnitInfo.isBase, undefined, () => {
          const rowEditExpired$ = rowEditExpiredGetter(staffUnit.id);

          switch (staffUnit.typeId) {
            case StaffUnitTypeEnum.MoonlighterInner:
              if(staffUnit.parentId){
                this.openEditProxy(cell.row, parentRowGetter, redactionGetter, graphGetter, cellsGetter, dayDeviationSetter, rowEditExpired$, isGraphEditReplaySubject$);
              } else {
                this.openEditNotProxy(cell.row, redactionGetter, graphGetter, rowEditExpired$, isGraphEditReplaySubject$);
              }
              break;
            case StaffUnitTypeEnum.CombinationInner:
              if(staffUnit.parentId){
                this.openEditProxy(cell.row, parentRowGetter, redactionGetter, graphGetter, cellsGetter, dayDeviationSetter, rowEditExpired$, isGraphEditReplaySubject$);
              } else {
                this.openEditNotProxy(cell.row, redactionGetter, graphGetter, rowEditExpired$, isGraphEditReplaySubject$);
              }
              break;
            case StaffUnitTypeEnum.Uvor:
              if(staffUnit.parentId){
                this.openEditProxy(cell.row, parentRowGetter, redactionGetter, graphGetter, cellsGetter, dayDeviationSetter, rowEditExpired$, isGraphEditReplaySubject$);
              } else {
                this.openEditNotProxy(cell.row, redactionGetter, graphGetter, rowEditExpired$, isGraphEditReplaySubject$);
              }
              break;
            case StaffUnitTypeEnum.Duty:
              this.openEditDuty(cell.row, redactionGetter, graphGetter, rowEditExpired$, isGraphEditReplaySubject$);
              break;
            default: throw new Error('out of range staffUnitTypeId');
          }
        }),
        createItem('Удалить', 'delete', !staffUnitInfo.isInMonth || staffUnitInfo.isBase, undefined, () => {
          this.graphRowEditService.deleteRow$(redactionGetter().id, staffUnit.ownerId, staffUnit.timestamp)
            .pipe(
              takeUntil(expired$),
              takeUntil(isGraphEditReplaySubject$.pipe(skipWhile(value => value))),
              takeUntil(this.streams$.unsubscribe),
            )
            .subscribe();
        })
      )
    }

    //--Печать--
    const printItemItems: ContextItemType[] = [];

    if(staffUnit.typeId === StaffUnitTypeEnum.MoonlighterInner){
      printItemItems.push(
        createItem('Оформление внутреннего совместительства', 'print', false, undefined, () => {
          this.api1PrintReportControllerService.printInternalConcurrencyRegistration$(
            staffUnit.ownerId,
            redactionGetter().id,
            "internalConcurrency_registration")
            .pipe(
              take(1),
              exErrorHandler(this.displayErrorsService),
            ).subscribe(value => {
            FileSaver.saveAs(value, `Документы_Совместительство_${moment().format('DD_MM_yyyy')}.xlsx`);
          })
        })
      )
    }

    if(staffUnit.typeId === StaffUnitTypeEnum.Uvor){
      printItemItems.push(
        createItem('Оформление увеличения объема работ', 'print', false, undefined, () => {
          this.api1PrintReportControllerService.printUvorRegistration$(
            staffUnit.ownerId,
            redactionGetter().id,
            "uvor_registration"
          ).pipe(
            take(1),
            exErrorHandler(this.displayErrorsService),
          ).subscribe(value => {
            FileSaver.saveAs(value, `Документы_УВОР_${moment().format('DD_MM_yyyy')}.xlsx`);
          })
        })
      )
    }

    if(StaffUnit.isCombination(staffUnit.typeId)){
      printItemItems.push(
        createItem('Оформление совмещения', 'print', false, undefined, () => {
          this.api1PrintReportControllerService.printCombinationRegistration$(
            staffUnit.ownerId,
            redactionGetter().id,
            "combination_registration"
          ).pipe(
            take(1),
            exErrorHandler(this.displayErrorsService),
          ).subscribe(value => {
            FileSaver.saveAs(value, `Документы_Совмещение_${moment().format('DD_MM_yyyy')}.xlsx`);
          })
        })
      )
    }

    if(staffUnit.typeId === StaffUnitTypeEnum.Duty){
      printItemItems.push(
        createItem('Оформление дежурства', 'print', false, undefined, () => {
          this.api1PrintReportControllerService.printDutyRegistration$(
            staffUnit.ownerId,
            redactionGetter().id,
            'duty_registration'
          ).pipe(
            take(1),
            exErrorHandler(this.displayErrorsService),
          ).subscribe(value => {
            FileSaver.saveAs(value, `Документы_Дежурство_${moment().format('DD_MM_yyyy')}.xlsx`);
          })
        })
      )
    }

    result.push(
      createItem('Печать', 'print', printItemItems.every(x => x.disabled), printItemItems, undefined)
    );
    //------

    return result;

    /** Создать элемент */
    function createItem(text: string, icon: string, disabled: boolean, items: ContextItemType[], onClick: () => void): ContextItemType{
      return {
        text: text,
        icon: icon,
        disabled: disabled,
        items: items,
        onClick: disabled ? () => {throw new Error('Вызов обработчика при отключенной кнопки')} : onClick
      }
    }
  }

  /** Открыть диалог редактирования proxy */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private openEditProxy(proxy: GraphGridDataSource_DataItem,
                        parentGetter: ParentRowGetterType,
                        redactionGetter: RedactionGetterType,
                        graphGetter: GraphGetterType,
                        cellsGetter: CellsGetterType,
                        dayDeviationSetter: DayDeviationSetterType,
                        expired$: Observable<void>,
                        isGraphEditReplaySubject$: Observable<boolean>){
    const parent = parentGetter(proxy.id.uid);

    this.graphRowEditService.openEditProxyDialog$(
      redactionGetter(),
      graphGetter(),
      {
        staffUnit: proxy.staffUnit,
        employee: proxy.employee
      },
      {
        staffUnit: parent.staffUnit,
        employee: parent.employee,
        position: parent.position,
        allGraphDayGetter: () => {
          return cellsGetter(parent.staffUnit.id)
            .map(x => ({
              date: x.day.date,
              dayDeviation: x.graphDayCurrent.dayDeviation
            }))
        },
      },
    )
      .pipe(
        takeUntil(expired$),
        switchMap(value => value),
        tap(value => {
          const parentCells = cellsGetter(parent.staffUnit.id)
            .filter(x => DateHelper.isExistInRange(x.day.date, value.start, value.end));

          dayDeviationSetter(parentCells, value.dayDeviation);
        }),
        takeUntil(isGraphEditReplaySubject$.pipe(skipWhile(value => value))),
        takeUntil(this.streams$.unsubscribe),
      )
      .subscribe();
  }

  /** Открыть диалог редактирования moonlighter  */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private openEditNotProxy(row: GraphGridDataSource_DataItem,
                           redactionGetter: RedactionGetterType,
                           graphGetter: GraphGetterType,
                           expired$: Observable<any>,
                           isGraphEditReplaySubject$: Observable<boolean>){
    this.graphRowEditService.openEditNotProxyDialog$(
      redactionGetter(),
      graphGetter(),
      {
        staffUnit: row.staffUnit,
        employee: row.employee,
        position: row.position,
        financingSource: row.financingSource
      }
    )
      .pipe(
        takeUntil(expired$),
        takeUntil(isGraphEditReplaySubject$.pipe(skipWhile(value => value))),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe();
  }

  /** Открыть окно редактирования duty */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private openEditDuty(row: GraphGridDataSource_DataItem,
                       redactionGetter: RedactionGetterType,
                       graphGetter: GraphGetterType,
                       expired$: Observable<any>,
                       isGraphEditReplaySubject$: Observable<boolean>){
    this.graphRowEditService.openEditDutyDialog$(
      redactionGetter(),
      graphGetter(),
      {
        staffUnit: row.staffUnit,
        employee: row.employee,
        position: row.position
      }
    )
      .pipe(
        takeUntil(expired$),
        takeUntil(isGraphEditReplaySubject$.pipe(skipWhile(value => value))),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe();
  }
}
