import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "../../../../src/app/modules/auth/guards/authGuard";
import {GraphTableWorkspaceComponent} from "./components/graph-table-workspace/graph-table-workspace.component";
import {WorkspaceErrorComponent} from "./components/workspace-error/workspace-error.component";
import {
  LogicEnum,
  UserFuncGuard,
  UserFuncGuardOption,
  UserFuncGuardOptionItem
} from "../../../../src/app/modules/auth/guards/userFuncGuard";
import {TimeSheetFunctionEnum} from "../../../../src/app/classes/domain/AccessEnums/timeSheet/timeSheetFunctionEnum";
import {TableGridComponent} from "./components/graph-table-workspace/table-grid/table-grid.component";
import {MonitoringComponent} from "./components/monitoring/monitoring.component";
import {GraphGridComponent} from "./components/graph-table-workspace/graph-grid/graph-grid.component";
import {ApprovingComponent} from "./components/monitoring/approving/approving.component";
import {WorkspaceComponent} from "../../../../src/app/components/workspace/workspace.component";
import { AdministrationComponent } from './components/administration/administration.component';
import { AdministrationResponsibleMaintainingComponent } from './components/administration/responsible-maintaining/responsible-maintaining.component';
import { AdministrationResponsibleSignComponent } from './components/administration/responsible-sign/responsible-sign.component';
import { AdministrationTableApprovalRouteComponent } from './components/administration/table-approval-route/table-approval-route.component';
import { AdministrationGraphApprovalRouteComponent } from './components/administration/graph-approval-route/graph-approval-route.component';
import {AuthModule} from "../../../../src/app/modules/auth/auth.module";
import { PatientMovementsDirectoryComponent } from './components/patient-movements/directory/patient-movements-directory.component';
import { PatientMovementsSvodDirectoryComponent } from './components/patient-movements/directory-svod/patient-movements-svod-directory.component';
import {GraphComponent} from "./components/graph-table-workspace2/components/graph/graph.component";
import {GraphTableWorkspace2Component} from "./components/graph-table-workspace2/graph-table-workspace2.component";
import {YearMonthService} from "../../../../src/app/services/year-month-services/year-month.service";
import {
  SubdivisionsTreelistComponentDataSourceService2
} from "../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {
  GraphTableToolbarStorageService,
  GraphTableToolbarStorageServiceBase
} from "./components/graph-table-workspace2/components/graph-table-toolbar2/services/graph-table-toolbar-storage.service";
import {
  GraphTableToolbar1Service,
  GraphTableToolbarServiceBase
} from "./components/graph-table-workspace2/components/graph-table-toolbar2/services/graph-table-toolbar.service";
import {
  GraphComponent1Service,
  GraphComponentServiceBase
} from "./components/graph-table-workspace2/components/graph/services/graph-component.service";
import {
  RedactionGrouperDataSourceServiceGraph2
} from "./components/shareds/redactions/redaction-grid2/services/redaction-grouper-data-source.service";
import {
  GraphComponentStorageService,
  GraphComponentStorageServiceBase
} from "./components/graph-table-workspace2/components/graph/services/graph-component-storage.service";
import {
  GraphComponentEditService,
  GraphComponentEditServiceBase
} from "./components/graph-table-workspace2/components/graph/services/graph-component-edit.service";

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {
    path: 'workspace',
    component: WorkspaceComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'graphandtable',
        component: GraphTableWorkspaceComponent,
        children: [
          {
            path: 'graph',
            component: GraphGridComponent,
            data: {
              userFuncGuardOptions: new UserFuncGuardOption(
                LogicEnum.and,
                [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.GraphAccess])])
            },
            canActivate: [UserFuncGuard]
          },
          {
            path: 'table',
            component: TableGridComponent,
            data: {
              userFuncGuardOptions: new UserFuncGuardOption(
                LogicEnum.and,
                [new UserFuncGuardOptionItem(LogicEnum.and,[TimeSheetFunctionEnum.TableAccess])])
            },
            canActivate: [UserFuncGuard]
          }]
      },

      {
        path: 'graphandtable2',
        component: GraphTableWorkspace2Component,
        providers: [
          {provide: GraphTableToolbarStorageServiceBase, useClass: GraphTableToolbarStorageService},
          YearMonthService,
          SubdivisionsTreelistComponentDataSourceService2,
          {provide: GraphTableToolbarServiceBase, useClass: GraphTableToolbar1Service},
        ],
        children: [
          {
            path: 'graph',
            component: GraphComponent,
            providers: [
              RedactionGrouperDataSourceServiceGraph2,
              {provide: GraphComponentServiceBase, useClass: GraphComponent1Service},
              {provide: GraphComponentStorageServiceBase, useClass: GraphComponentStorageService},
              {provide: GraphComponentEditServiceBase, useClass: GraphComponentEditService}
            ],
            data: {
              userFuncGuardOptions: new UserFuncGuardOption(
                LogicEnum.and,
                [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.GraphAccess])])
            },
            canActivate: [UserFuncGuard]
          },
          {
            path: '**',
            redirectTo: 'graph',
            pathMatch: 'full'
          }
        ]
      },

      {
        path: 'monitoring',
        component: MonitoringComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.or, [TimeSheetFunctionEnum.GraphApproving, TimeSheetFunctionEnum.TableApproving])])
        }
      },
      {
        path: 'patients-movements',
        component: PatientMovementsDirectoryComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.GraphAccess])])
        }
      },
      {
        path: 'patients-movements-svod',
        component: PatientMovementsSvodDirectoryComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.EditPatientMovementsSvod])])
        }
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.AllGraphViewing])])
        }
      },
      {
        path: 'administration/responsible-maintaining',
        component: AdministrationResponsibleMaintainingComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.AllGraphViewing])])
        }
      },
      {
        path: 'administration/responsible-sign',
        component: AdministrationResponsibleSignComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.AllGraphViewing])])
        }
      },
      {
        path: 'administration/graph-approval-route',
        component: AdministrationGraphApprovalRouteComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.AllGraphViewing])])
        }
      },
      {
        path: 'administration/table-approval-route',
        component: AdministrationTableApprovalRouteComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(
            LogicEnum.and,
            [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.AllGraphViewing])])
        }
      },
      {path: 'error', component: WorkspaceErrorComponent}
    ]
  },
  {
    path: 'approving',
    component: ApprovingComponent,
    canActivate: [UserFuncGuard],
    data: {
      userFuncGuardOptions: new UserFuncGuardOption(
        LogicEnum.and, [new UserFuncGuardOptionItem(LogicEnum.or, [TimeSheetFunctionEnum.GraphApproving, TimeSheetFunctionEnum.TableApproving])]
      )
    },
    children:[
      {
        path: 'graph',
        component: GraphGridComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(LogicEnum.and, [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.GraphApproving])])
        }
      },
      {
        path: 'table',
        component: TableGridComponent,
        canActivate: [UserFuncGuard],
        data: {
          userFuncGuardOptions: new UserFuncGuardOption(LogicEnum.and, [new UserFuncGuardOptionItem(LogicEnum.and, [TimeSheetFunctionEnum.TableApproving])])
        }
      }
    ]
  },
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [
    AuthModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
