import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContextMenuPopupEvent, ContextMenuSelectEvent, MenuItem} from "@progress/kendo-angular-menu";
import {traceClass} from 'src/app/modules/trace/decorators/class.decorator';
import {TracerServiceBase} from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import {IAddTimeIntervalComponent_Event} from "../../../../shareds/add-time-interval/add-time-interval.component";
import {ITimeIntervalPanelComponent, TimeIntervalPanelItem} from './i-time-interval-panel-component';
import {TimeIntervalPanelComponentService} from './services/time-interval-panel-component.service';
import {Observable, ReplaySubject, Subject} from "rxjs";

@Component({
  selector: 'app-time-interval-panel',
  templateUrl: './time-interval-panel.component.html',
  styleUrls: [
    './time-interval-panel.component.css',
    '../../panels.shared.css'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('TimeIntervalPanelComponent')
export class TimeIntervalPanelComponent implements OnInit, OnDestroy, ITimeIntervalPanelComponent {
  @Input() public disabled: boolean = true;

  @Input() public subdivisionOwnerId: number;

  public readonly dataSource$: Observable<TimeIntervalPanelItem[]> = new ReplaySubject<TimeIntervalPanelItem[]>(1);

  private _dataSource: Array<TimeIntervalPanelItem> = new Array<TimeIntervalPanelItem>();
  get dataSource(): Array<TimeIntervalPanelItem> {
    return this._dataSource;
  }
  set dataSource(value: Array<TimeIntervalPanelItem>) {
    this._dataSource = value;
    (this.dataSource$ as Subject<TimeIntervalPanelItem[]>).next(value);
    this.changeDetectorRef.detectChanges();
  }

  public isRemoving: boolean = false;

  public isAdding: boolean = false;

  public contextMenuArray: Array<MenuItem> = [{ text: 'Удалить интервал времени' }];

  /** Количество выделенных интервалов */
  public get totalSelected(): number {
    return this.service.dataSourceSelectedItems.length;
  };

  constructor(private readonly service: TimeIntervalPanelComponentService,
              private readonly tracerService: TracerServiceBase,
              public readonly changeDetectorRef: ChangeDetectorRef) {
    service.component = this
  }



  ngOnInit() {
    this.service.onInit()
  }

  /** Обработка события нажатия на buttonGroup */
  public onButtonClick($event: any, interval: TimeIntervalPanelItem): void {
    this.service.onButtonClick($event, interval)
  }

  /** Добавить временной интервал */
  public onAdd($event: any): void {
    this.service.onAdd($event)
  }

  /** Добавление временного интервала */
  public addedInterval($event: IAddTimeIntervalComponent_Event): void {
    this.service.addedInterval($event)
  }

  /** Удалить несколько временных интервалов */
  public remove($event: any): void {
    this.service.remove($event)
  }

  /** Отмена удаления временных интервалов */
  public cancelRemove($event: any): void {
    this.service.cancelRemove($event)
  }

  /** Событие контекста временного интервала */
  public contextMenuSelect($event: ContextMenuSelectEvent): void {
    this.service.contextMenuSelect($event)
  }

  /** Обработка события предоткрытия окна */
  public contextMenuOpen($event: ContextMenuPopupEvent): void {
    this.service.contextMenuOpen($event)
  }

  ngOnDestroy(): void {
    (this.dataSource$ as Subject<TimeIntervalPanelItem[]>).complete();
  }
}
