import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {
  ISubdivisionsTreelistComponent_Inputs,
  SortDescriptorType
} from "../subdivisions-treelist/subdivisions-treelist.component";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";

@Component({
  selector: 'app-subdivision-selecting-dialog',
  templateUrl: './subdivision-selecting-dialog.component.html',
  styleUrls: ['./subdivision-selecting-dialog.component.css'],
  providers: [DeferSelectionService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('SubdivisionSelectingDialogComponent')
export class SubdivisionSelectingDialogComponent implements ISubdivisionsTreelistComponent_Inputs, OnInit, OnDestroy{
  @Input() public displayTextFn : (subdivision: ISubdivision) => string = x => x.longName;

  private _dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>;
  @Input() public get dataSourceService(){
    return this._dataSourceService;
  }
  public set dataSourceService(value){
    this._dataSourceService = value;
    this.deferSelectionService.originSelection = value?.dataSource;
  }

  @Input() public cellStyle: any;
  @Input() multipleSelected: boolean = false;
  @Input() sortDescriptor: SortDescriptorType;

  /**
   * Отключать кнопку Ok если набор выбранных строк НЕ изменился.<br>
   * Возьмет текущий список выделенных элементов(на момент инициализации), и будет сравнивать при каждом изменении
   */
  @Input() disableOkIfNotSelectionChange: boolean = true;

  /** Отключать кнопку Ok если нет выбора */
  @Input() disableOkIfNotSelection: boolean = true;

  /** Выделенные элементы */
  @Input() public set selection(value: ArrayDataSourceSelection<ISubdivision, number>){
    this.deferSelectionService.originSelection = value;
  }

  /** Событие отмены выбора */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Событие выбора */
  @Output() public select$: EventEmitter<ISubdivision[]> = new EventEmitter<ISubdivision[]>();

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly tracerService: TracerServiceBase,
              public readonly deferSelectionService: DeferSelectionService<SubdivisionsTreelistComponentDataSourceServiceBase<any>['dataSource'], ArrayDataSourceSelection<ISubdivision, number>>) {
    deferSelectionService.tempCtorFunc = dataSource => new ArrayDataSourceSelection<ISubdivision, number>(dataSource);
  }

  @traceFunc()
  ngOnInit() {
  }

  /** Обработка нажатие Отмена */
  @traceFunc()
  onCancel() {
    this.cancel$.emit();
  }

  /** Обработка нажатие ОК */
  @traceFunc()
  onOk() {
    const selected = this.deferSelectionService.tempSelection.data.selectedItems2.data;
    this.deferSelectionService.apply();
    this.select$.emit([...selected]);
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
