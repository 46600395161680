import {CovidLog, ICovidLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog";
import {CovidLog2, ICovidLog2} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {IVichLog, VichLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/VichLog";
import {ITuberLog, TuberLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/TuberLog";
import {
  ExcludeMilkLog,
  IExcludeMilkLog
} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/ExcludeMilkLog";
import {
  IGraphDataSource_DataItem
} from "../graph-data-sources/graph-data-source.classes";
import {GraphDay, IGraphDay} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphDay";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../../../src/app/helpers/arrayHelper";
import {ITimeInterval, TimeInterval} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import {
  DayDeviation,
  IDayDeviation
} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {DateMap} from "../../../../../../../../../../../src/app/classes/maps/date-maps/date-map";
import {ObjComparer} from "../../../../../../../../../../../src/app/classes/object-comparers/object-comparer";
import {Mutable} from "../../../../../../../../../../../src/app/classes/types/object-types";
import {Graph} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/Graph";
import {Day} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/Day";
import {IStaffUnit, StaffUnit} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";

/** Тип функции модификации {@link GraphGrid_EditablePartGraphDayCell} */
type GraphGrid_EditablePartDayCell_ModFuncType = (instance: Mutable<GraphGrid_EditablePartGraphDayCell>) => void;

/** Редактируемая часть ячейки дня графика */
export class GraphGrid_EditablePartGraphDayCell implements Pick<IGraphDay, 'dayDeviationCustomValue' | 'substractLunchFlag' | 'flexDinner'> {

  /**
   * Конструктор
   * @param timeInterval временной интервал
   * @param dayDeviation отклонение
   * @param dayDeviationCustomValue поле {@link IDayDeviation.dayDeviationCustomValue}
   * @param substractLunchFlag поле {@link IDayDeviation.substractLunchFlag}
   * @param flexDinner поле {@link IDayDeviation.flexDinner}
   */
  constructor(public readonly timeInterval: Readonly<Omit<ITimeInterval, 'subdivisionId'>> | undefined,
              public readonly dayDeviation: IDayDeviation | undefined,
              public readonly dayDeviationCustomValue: number,
              public readonly substractLunchFlag: boolean,
              public readonly flexDinner: number) {

  }

  /** Создать по умолчанию */
  public static CreateDefault() {
    const graphDayDefault = this.defaultGraphDay;

    return new GraphGrid_EditablePartGraphDayCell(
      undefined,
      undefined,
      graphDayDefault.dayDeviationCustomValue,
      graphDayDefault.substractLunchFlag,
      graphDayDefault.flexDinner);
  }

  /**
   * Копировать
   * @param source что копировать
   * @param mod функция модификации. Используй готовые наборы {@link setDayDeviation}, {@link setDayDeviationCustomValue}, {@link setTimeInterval}, {@link setSubstractLunchFlag}, {@link setFlexDinner}
   * @constructor
   */
  public static Copy(source: GraphGrid_EditablePartGraphDayCell,
                     mod: GraphGrid_EditablePartDayCell_ModFuncType = undefined): GraphGrid_EditablePartGraphDayCell {
    if(!source){
      return undefined;
    }

    const temp: typeof source = {
      timeInterval: source.timeInterval,
      dayDeviation: source.dayDeviation,
      dayDeviationCustomValue: source.dayDeviationCustomValue,
      substractLunchFlag: source.substractLunchFlag,
      flexDinner: source.flexDinner,
    }

    if(mod){
      mod(temp);
    }

    return  new GraphGrid_EditablePartGraphDayCell(
      temp.timeInterval,
      temp.dayDeviation,
      temp.dayDeviationCustomValue,
      temp.substractLunchFlag,
      temp.flexDinner);
  }

  /**
   * Функция установки {@link dayDeviation} для использования в {@link Copy}<br>
   * Если передан !dayDeviation, установит {@link dayDeviationCustomValue} в значение по умолчанию<br>
   * Если переданный dayDeviation НЕ поддерживает {@link dayDeviationCustomValue}, то установит {@link dayDeviationCustomValue} в значение по умолчанию<br>
   * Если передан !!dayDeviation очистит {@link timeInterval} с учетом переданного {@link clearTimeIntervalIfSupportCompatibility}<br>
   * @param dayDeviation отклонение
   * @param clearTimeIntervalIfSupportCompatibility очищать ли {@link timeInterval} если {@link IDayDeviation.deviationWithInterval} === true
   * @param dayDeviationCustomValue значение для {dayDeviationCustomValue}
   */
  public static setDayDeviation(dayDeviation: GraphGrid_EditablePartGraphDayCell['dayDeviation'],
                                clearTimeIntervalIfSupportCompatibility: boolean,
                                dayDeviationCustomValue?: GraphGrid_EditablePartGraphDayCell['dayDeviationCustomValue']): GraphGrid_EditablePartDayCell_ModFuncType{
    return (instance) => {
      if(!dayDeviation){
        instance.dayDeviation = undefined;
        instance.dayDeviationCustomValue = this.defaultGraphDay.dayDeviationCustomValue;
        return;
      }

      if(instance.timeInterval && (!dayDeviation.deviationWithInterval || clearTimeIntervalIfSupportCompatibility)){ //Если нужно очистить временной интервал
        this.setTimeInterval(undefined, true)(instance);
      }

      instance.dayDeviation = dayDeviation;
      this.setDayDeviationCustomValue(dayDeviationCustomValue)(instance);
    }
  }

  /** Функция установки {@link dayDeviationCustomValue} для использования в {@link Copy} */
  public static setDayDeviationCustomValue(dayDeviationCustomValue: GraphGrid_EditablePartGraphDayCell['dayDeviationCustomValue']): GraphGrid_EditablePartDayCell_ModFuncType {
    return (instance) => {
      if(typeof dayDeviationCustomValue === 'number'){
        if(!instance.dayDeviation){
          throw new Error('При установке dayDeviationCustomValue отклонение НЕ может быть undefined');
        }

        if(!instance.dayDeviation.hasCustomValue){
          throw new Error('Текущее отклонение НЕ поддерживает dayDeviationCustomValue');
        }

        if(dayDeviationCustomValue < 0){
          throw new Error('dayDeviationCustomValue не может быть < 0');
        }

        instance.dayDeviationCustomValue = dayDeviationCustomValue;
      } else {
        if(instance.dayDeviation?.hasCustomValue){
          throw new Error('Текущее отклонение поддерживает dayDeviationCustomValue но НЕ поддерживает установку в undefined');
        }

        instance.dayDeviationCustomValue = this.defaultGraphDay.dayDeviationCustomValue;
      }
    }
  }

  /**
   * Функция установки {@link timeInterval} для использования {@link Copy} <br>
   * Если передан !timeInterval то сбросит {@link substractLunchFlag}, {@link flexDinner} в значения по умолчанию
   * Если передан !!timeInterval то сбросит {@link dayDeviation} с учетом правила {@link clearDayDeviationIfSupportCompatibility}
   * @param timeInterval временной интервал
   * @param clearDayDeviationIfSupportCompatibility очищать ли {@link dayDeviation} если {@link IDayDeviation.deviationWithInterval} === true
   */
  public static setTimeInterval(timeInterval: GraphGrid_EditablePartGraphDayCell['timeInterval'],
                                clearDayDeviationIfSupportCompatibility: boolean): GraphGrid_EditablePartDayCell_ModFuncType {
    return (instance) => {
      if(!timeInterval){
        const defaultGraphDay = this.defaultGraphDay;
        instance.timeInterval = undefined;
        instance.substractLunchFlag = defaultGraphDay.substractLunchFlag;
        instance.flexDinner = defaultGraphDay.flexDinner;
        return;
      }

      if(instance.dayDeviation && (!instance.dayDeviation.deviationWithInterval || clearDayDeviationIfSupportCompatibility)){
        this.setDayDeviation(undefined, true)(instance);
      }

      if(instance.flexDinner){ //Если задан гибкий обед
        const timeIntervalDuration = timeInterval.endInterval - timeInterval.startInterval;

        if(timeIntervalDuration < instance.flexDinner){ //Если установленный обед больше самого временного интервала
          this.setFlexDinner(timeIntervalDuration)(instance);
        }
      }

      instance.timeInterval = timeInterval
    }
  }

  /** Функция установки {@link substractLunchFlag} для использования в {@link Copy} */
  public static setSubstractLunchFlag(substractLunchFlag: GraphGrid_EditablePartGraphDayCell['substractLunchFlag']): GraphGrid_EditablePartDayCell_ModFuncType{
    return (instance) => {
      if(typeof substractLunchFlag !== 'boolean'){
        throw new Error(`substractLunchFlag НЕ поддерживает тип ${typeof substractLunchFlag}`);
      }

      if(substractLunchFlag){
        if(!instance.timeInterval){
          throw new Error('Если !timeInterval, то установка substractLunchFlag = true НЕ поддерживается ');
        }

        if(instance.flexDinner){ //Вычитать обед НЕ поддерживается с гибким обедом
          this.setFlexDinner(undefined)(instance);
        }
      }

      instance.substractLunchFlag = substractLunchFlag;
    }
  }

  /** Функция установки {@link flexDinner} для использования в {@link Copy} */
  public static setFlexDinner(flexDinner: GraphGrid_EditablePartGraphDayCell['flexDinner']): GraphGrid_EditablePartDayCell_ModFuncType{
    return (instance) => {
      if(typeof flexDinner === 'number'){
        if(!instance.timeInterval){
          throw new Error('Если !timeInterval то установка значения flexDinner не допускается');
        }

        if(flexDinner < 0){
          throw new Error('flexDinner НЕ может быть меньше нуля');
        }

        if(!Number.isInteger(flexDinner)){
          throw new Error('flexDinner должен быть целым числом');
        }

        const timeIntervalDuration = instance.timeInterval.endInterval - instance.timeInterval.startInterval;
        if(timeIntervalDuration < flexDinner){
          throw new Error(`Попытка установить обед ${flexDinner} мин., при этом продолжительность временного интервала ${timeIntervalDuration}`);
        }

        if(instance.substractLunchFlag){ //Данный флаг не поддерживается с гибким обедом
          this.setSubstractLunchFlag(false)(instance);
        }

        if(flexDinner === 0){
          flexDinner = this.defaultGraphDay.flexDinner;
        }

        instance.flexDinner = flexDinner;
      } else {
        instance.flexDinner = this.defaultGraphDay.flexDinner;
      }
    }
  }

  private static _fullComparer: ObjComparer<GraphGrid_EditablePartGraphDayCell>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer({
        timeInterval: TimeInterval.fullComparer.pick({
          id: true,
          startInterval: true,
          endInterval: true,
        }).asPropertyCompareFunc(false),
        dayDeviation: DayDeviation.fullComparer.asPropertyCompareFunc(false)
      }).merge(
        GraphDay.fullComparer.pick({
          dayDeviationCustomValue: true,
          substractLunchFlag: true,
          flexDinner: true
        })
      )
    }

    return this._fullComparer;
  }

  private static _defaultGraphDay: Readonly<IGraphDay>;
  /** День графика по умолчанию */
  private static get defaultGraphDay() {
    if(!this._defaultGraphDay){
      this._defaultGraphDay = GraphDay.CreateDefault(undefined, undefined, undefined);
    }

    return this._defaultGraphDay;
  }
}

/** Тип содержащий все Log относящиеся к ячейке дня графика */
export class GraphGrid_LogGraphDayCellType {
  constructor(public readonly covidLogs: Readonly<ICovidLog>[],
              public readonly covidLog2s: Readonly<ICovidLog2>[],
              public readonly vichLogs: Readonly<IVichLog>[],
              public readonly tuberLogs: Readonly<ITuberLog>[],
              public readonly excludeMilkLog: Readonly<IExcludeMilkLog>) {
  }

  private static _fullComparer: ObjComparer<GraphGrid_LogGraphDayCellType>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<GraphGrid_LogGraphDayCellType>({
        covidLogs:  CovidLog.usefulComparer.asArrayCompareFunc(false),
        covidLog2s: CovidLog2.usefulComparer.asArrayCompareFunc(false),
        vichLogs: VichLog.usefulComparer.asArrayCompareFunc(false),
        tuberLogs: TuberLog.usefulComparer.asArrayCompareFunc(false),
        excludeMilkLog: ExcludeMilkLog.usefulComparer.asPropertyCompareFunc(false)
      })
    }

    return this._fullComparer;
  }
}

/** Ячейка дня исполнения должности */
export class GraphGrid_GraphDayCell {
  /** Идентификатор ячейки */
  public readonly id: string;

  /** Имеет ли ячейка изменения. Определяется при сравнении {@link graphDayOrigin} и {@link graphDayCurrent} между собой */
  public readonly isChanged: boolean;

  constructor(public readonly graph: Readonly<IGraphDataSource_DataItem['graph']>,
              public readonly day: Readonly<IGraphDataSource_DataItem['days'][0]>,
              public readonly staffUnitId: number,
              public readonly log: GraphGrid_LogGraphDayCellType,
              public readonly graphDayOrigin: GraphGrid_EditablePartGraphDayCell,
              public readonly graphDayCurrent: GraphGrid_EditablePartGraphDayCell) {
    this.id = `${staffUnitId}/${this.day.id}`;
    this.isChanged = !GraphGrid_EditablePartGraphDayCell.fullComparer.compare(graphDayOrigin, graphDayCurrent, false);
  }

  private static _fullComparer: ObjComparer<GraphGrid_GraphDayCell>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<GraphGrid_GraphDayCell>({
        id: true,
        isChanged: true,
        graph: Graph.fullComparer.asPropertyCompareFunc(false),
        day: Day.usefulComparer.pick({id: true, dayTypeId: true, date: true}).asPropertyCompareFunc(false),
        staffUnitId: true,
        log: GraphGrid_LogGraphDayCellType.fullComparer.asPropertyCompareFunc(false),
        graphDayOrigin: GraphGrid_EditablePartGraphDayCell.fullComparer.asPropertyCompareFunc(false),
        graphDayCurrent: GraphGrid_EditablePartGraphDayCell.fullComparer.asPropertyCompareFunc(false),
      })
    }
    return this._fullComparer;
  }

  /**
   * Копировать
   * @param cell ячейки источник
   * @param mod функция модификации данных
   * @constructor
   */
  public static Copy(cell: Pick<GraphGrid_GraphDayCell, 'graph' | 'day' | 'staffUnitId' | 'log' | 'graphDayOrigin' | 'graphDayCurrent'>,
                     mod: (instance: Mutable<Pick<GraphGrid_GraphDayCell, 'graphDayOrigin' | 'graphDayCurrent'>>) => void = undefined): GraphGrid_GraphDayCell{
    if(!cell){
      return undefined;
    }

    const temp: Parameters<typeof mod>[0] = {
      graphDayOrigin: cell.graphDayOrigin,
      graphDayCurrent: cell.graphDayCurrent,
    }

    if(mod){
      mod(temp);
    }

    return new GraphGrid_GraphDayCell(
      cell.graph,
      cell.day,
      cell.staffUnitId,
      cell.log,
      temp.graphDayOrigin,
      temp.graphDayCurrent
    )
  }

  /**
   * Скопировать ячейку с возможностью изменить {@link graphDayCurrent}
   * @param cell ячейка подлежащая копированию
   * @param mod функция модификации {@link graphDayCurrent}
   * @constructor
   */
  public static CopyWithCurrentEditPart(cell: Parameters<typeof this.Copy>[0],
                                        mod: Parameters<typeof GraphGrid_EditablePartGraphDayCell.Copy>[1]): GraphGrid_GraphDayCell{
    return this.Copy(
      cell,
        value => {
          value.graphDayCurrent = GraphGrid_EditablePartGraphDayCell.Copy(value.graphDayCurrent, mod)
        }
    )
  }


  /**
   * Создать для исполнения должности
   * @param graph график
   * @param days дни месяца
   * @param staffUnit исполнение должности
   * @param covidLogMap словарь относящийся только к этому исполнению должности
   * @param covidLog2Map словарь относящийся только к этому исполнению должности
   * @param vichLogMap словарь относящийся только к этому исполнению должности
   * @param tuberLogMap словарь относящийся только к этому исполнению должности
   * @param excludeMilkLogMap словарь относящийся только к этому исполнению должности
   * @param graphDayMap заполненные дни. словарь относящийся только к этому исполнению должности
   * @param changeMap измененные дни. словарь относящийся только к этому исполнению должности
   * @constructor
   */
  public static CreateForStaffUnit(graph: GraphGrid_GraphDayCell['graph'],
                                   days: GraphGrid_GraphDayCell['day'][],
                                   staffUnit: Pick<IStaffUnit, 'id' | 'startDate' | 'endDate'>,
                                   covidLogMap: DateMap<ICovidLog[]>,
                                   covidLog2Map: DateMap<ICovidLog2[]>,
                                   vichLogMap: DateMap<IVichLog[]>,
                                   tuberLogMap: DateMap<ITuberLog[]>,
                                   excludeMilkLogMap: DateMap<IExcludeMilkLog>,
                                   graphDayMap: DateMap<GraphGrid_EditablePartGraphDayCell>,
                                   changeMap: DateMap<GraphGrid_EditablePartGraphDayCell>) {
    return Array.from(StaffUnit.filterByRangeWork(days, x => x.date, staffUnit.startDate, staffUnit.endDate))
      .map(day => {
        const graphDay = graphDayMap.get(day.date);

        const graphDayOrigin = !graphDay
          ? GraphGrid_EditablePartGraphDayCell.CreateDefault()
          : GraphGrid_EditablePartGraphDayCell.Copy(graphDay);
        const graphDayCurrent = GraphGrid_EditablePartGraphDayCell
          .Copy(changeMap.get(day.date) ?? graphDayOrigin);

        return new GraphGrid_GraphDayCell(
          graph,
          day,
          staffUnit.id,
          {
            covidLogs: covidLogMap.get(day.date) ?? [],
            covidLog2s: covidLog2Map.get(day.date) ?? [],
            vichLogs: vichLogMap.get(day.date) ?? [],
            tuberLogs: tuberLogMap.get(day.date) ?? [],
            excludeMilkLog: excludeMilkLogMap.get(day.date)
          },
          graphDayOrigin,
          graphDayCurrent,
        );
      })
  }

  /**
   * Создать для исполнения должности
   * @param graph график
   * @param days дни месяца
   * @param staffUnit исполнение должности
   * @param covidLogs все относящиеся к текущему исполнению должности
   * @param covidLog2s все относящиеся к текущему исполнению должности
   * @param vichLogs все относящиеся к текущему исполнению должности
   * @param tuberLogs все относящиеся к текущему исполнению должности
   * @param excludeMilkLogs все относящиеся к текущему исполнению должности
   * @param graphDays заполненные дни. все относящиеся к текущему исполнению должности
   * @param changes измененные дни. все относящиеся к текущему исполнению должности
   * @constructor
   */
  public static CreateForStaffUnit2(graph: GraphGrid_GraphDayCell['graph'],
                                    days: GraphGrid_GraphDayCell['day'][],
                                    staffUnit: Pick<IStaffUnit, 'id' | 'startDate' | 'endDate'>,
                                    covidLogs: ICovidLog[],
                                    covidLog2s: ICovidLog2[],
                                    vichLogs: IVichLog[],
                                    tuberLogs: ITuberLog[],
                                    excludeMilkLogs: IExcludeMilkLog[],
                                    graphDays: Array<Pick<IGraphDay, 'date'> & GraphGrid_EditablePartGraphDayCell>,
                                    changes: Array<Pick<IGraphDay, 'date'> & GraphGrid_EditablePartGraphDayCell>) {
    return this.CreateForStaffUnit(
      graph,
      days,
      staffUnit,
      new ArrayExpanded(covidLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(covidLog2s)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(vichLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(tuberLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => x.values),
      new ArrayExpanded(excludeMilkLogs)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values)),
      new ArrayExpanded(graphDays)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values)),
      new ArrayExpanded(changes)
        .groupBy(x => +x.date)
        .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values))
    )
  }
}
