import {Injectable, OnDestroy} from "@angular/core";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {ComponentServiceBase} from "../../../../../../../../../src/app/services/abstracts/component-service-base";
import {IGraphComponent} from "../i-graph.component";
import {
  RedactionGrouperDataSourceServiceGraph2
} from "../../../../shareds/redactions/redaction-grid2/services/redaction-grouper-data-source.service";
import {
  TraceParamEnum
} from "../../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {ReplaySubject, switchMap, tap} from "rxjs";
import {GraphTableToolbarServiceBase} from "../../graph-table-toolbar2/services/graph-table-toolbar.service";
import {map, takeUntil} from "rxjs/operators";
import {LoadingIndicatorService} from "../../../../../../../../../src/app/services/loading-indicator.service";
import {
  DisplayErrorsService
} from "../../../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {exLoadingMessage2} from "../../../../../../../../../src/app/operators/ex-loading-message.operator";
import {exErrorHandler} from "../../../../../../../../../src/app/operators/ex-error-handler";
import {GraphComponentStorageServiceBase} from "./graph-component-storage.service";
import {exDistinctUntilChanged} from "../../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";

@Injectable()
export abstract class GraphComponentServiceBase extends ComponentServiceBase<IGraphComponent> implements OnDestroy {

  private _initialized = false;
  /** Проинициализировать сервис */
  public onInit(component: IGraphComponent): void {
    if(this._initialized) {
      throw new Error('Сервис НЕ поддерживает повторную инициализацию');
    }

    this.component = component;
  }

  /** @inheritDoc */
  abstract ngOnDestroy();
}


@Injectable()
@traceClass('GraphComponent1Service')
export class GraphComponent1Service extends GraphComponentServiceBase{
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  constructor(private readonly graphTableToolbarService: GraphTableToolbarServiceBase,
              private readonly redactionGrouperDataSourceServiceGraph2: RedactionGrouperDataSourceServiceGraph2,
              private readonly graphStorageService: GraphComponentStorageServiceBase,

              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly displayErrorsService: DisplayErrorsService,

              private readonly traceService: TracerServiceBase) {
    super();
  }

  /** @inheritDoc */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  public onInit(component: IGraphComponent) {
    super.onInit(component);

    this.component.redactionGrouperDataSourceService = this.redactionGrouperDataSourceServiceGraph2;

    //При смене редакции сохраняем в storage сервис
    this.graphStorageService.redactionIdDataSource.setData(this.component.redactionGridSelection.selectedIds.change$.pipe(map(value => value[0])));

    //При смене в панели Подразделение/Год/Месяц значения, необходимо перезагружать редакции
    this.graphTableToolbarService.valueDataSource.data$
      .pipe(
        switchMap(value => {
          const redactionIdInStorage = this.graphStorageService.redactionIdDataSource.data;

          return this.redactionGrouperDataSourceServiceGraph2.reloadData$(
            value
          ).pipe(
            tap(value => this.setRedactionIdInRedactionSelection(redactionIdInStorage)),
            exLoadingMessage2({service: this.loadingIndicatorService, message: 'Получение редакций'}),
            exErrorHandler(this.displayErrorsService),
          )
        }),

        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe();

    //Управление состоянием disable панели Подразделение/Месяц/Год в зависимости от состояния редактирования
    this.component.editing
      .pipe(
        exDistinctUntilChanged(undefined),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        this.graphTableToolbarService.disabled = value;
    })
  }

  /**
   * Устанавливает редакцию как выбранную в {@link IGraphComponent.redactionGridSelection}.
   * Если переданного идентификатора нет в источнике данных, то выберет актуальную редакцию
   * @param redactionId идентификатор редакции.
   * @private
   */
  @traceFunc()
  private setRedactionIdInRedactionSelection(redactionId: number | undefined){
    if(!this.component.redactionGrouperDataSourceService.dataSource.data){ //Если нет данных в гроупере
      if(this.component.redactionGridSelection.selectedIds.data.length > 0) { //Если есть выделенные элементы
        this.component.redactionGridSelection.setIds([]);
      }

      return;
    }

    if(!this.component.redactionGridDataSourceService.dataSource.data.some(x => x.redaction.id === redactionId)){ //Если в источнике данных отсутствует редакция с переданным идентификатором
      redactionId = this.component.redactionGrouperDataSourceService.dataSource.data.actualRedactionId; //Берем актуальную редакцию
    }

    this.component.redactionGridSelection.setIds([redactionId]);
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
