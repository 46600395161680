import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TimeInterval } from "src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1TimeIntervalControllerService } from "src/app/services/webApi/webApi1/controllers/api1-timeInterval-controller.service";

@Injectable()
export class TimeIntervalPanelComponentDataService {

  constructor(private readonly api1TimeIntervalControllerService: Api1TimeIntervalControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService) { }

  /** Получение всех Временных интервалов для подразделения */
  getAllTimeIntervalsBySubdivisionId$(subdivisionOwnerId: number): Observable<TimeInterval[]> {
    return this.loadingIndicatorService.addToObservable(
      'Инициализация панели временных интервалов',
      this.api1TimeIntervalControllerService.getAllTimeIntervalsBySubdivisionId$(subdivisionOwnerId)
    )
  }

  /** Удалить временной интервал из подразделения */
  deleteTimeIntervalLinks$(timeIntervalIds: number[], subdivisionId: number): Observable<boolean> {
    return this.api1TimeIntervalControllerService.deleteTimeIntervalLinks$(timeIntervalIds, subdivisionId)
  }

}
