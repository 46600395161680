import { Component, Input, OnInit } from '@angular/core';
import {combineLatest, defer, Observable, ReplaySubject, startWith, switchMap, takeUntil} from 'rxjs';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { PatientMovementsGridComponentService } from './services/patient-movements-grid-component.service';
import { traceFunc } from '../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { traceClass } from '../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { xnameofPath } from '../../../../../../../src/app/functions/nameof';
import { ISubdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, CellClickEvent, EditEvent, GridComponent, SaveEvent } from '@progress/kendo-angular-grid';
import { PatientMovementAdditionallyInfosGridDataSourceService } from '../additionally-infos-grid/services/patient-movement-additionally-infos-grid-data-source.service';
import { CellClickExpandedEvent } from '../../../../../../../src/app/services/directives/grid-treelist-expanded-directive.service';
import { SubdivisionDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/subdivision-data-source.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { IPatientMovement, PatientMovement, PatientMovementCalculatedFields } from '../../../../../../../src/app/classes/domain/POCOs/timesheet/PatientMovement';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { exCreateService$ } from '../../../../../../../src/app/operators/ex-create-service.operator';
import { DbChangedListener } from '../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { take } from 'rxjs/operators';
import { AlertService } from '../../../../../../../src/app/services/alert.service';
import { PatientMovementsGridComponentDataSourceServiceBase } from './services/patient-movements-grid-component-data-source.service';
import * as moment from "moment/moment";
import {
  Api1PrintReportControllerService,
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import * as FileSaver from "file-saver";
import {trace} from "../../../../../../../src/app/modules/trace/operators/trace";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";
import {
  ReportDatesComponentSharedService
} from "../../../../../../../src/app/components/report-dates/services/report-dates-component-shared.service";

@Component({
  selector: 'app-patient-movements-grid',
  templateUrl: './patient-movements-grid.component.html',
  styleUrls: ['./patient-movements-grid.component.css'],
  providers: [
    PatientMovementsGridComponentService,
    PatientMovementAdditionallyInfosGridDataSourceService,
  ],
})
@traceClass('PatientMovementsGridComponent')
export class PatientMovementsGridComponent implements OnInit {

  public subdivisionsData$: Observable<ISubdivision[]>;
  public subdivisionDataSourceService$: Observable<SubdivisionDataSourceService>;
  public form: FormGroup<IPatientMovementsGridComponentForm>;

  public fieldsNames = xnameofPath(PatientMovementDataItem, '.', false);
  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  @Input()
  public isSvod: boolean = false;

  private _dataSourceService: PatientMovementsGridComponentDataSourceServiceBase;

  @Input()
  public get dataSourceService(): PatientMovementsGridComponentDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: PatientMovementsGridComponentDataSourceServiceBase) {
    if (this._dataSourceService) {
      throw Error('Повторная установка [dataSourceService] не поддерживается!');
    }
    this._dataSourceService = value;
  }

  /** Дата отчета */
  private svodDate: Date;

  constructor(public readonly service: PatientMovementsGridComponentService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly alertService: AlertService,
              private readonly traceService: TracerServiceBase,
              private readonly api1PrintReportControllerService: Api1PrintReportControllerService,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly reportDatesComponentSharedService: ReportDatesComponentSharedService) {
  }

  @traceFunc()
  ngOnInit(): void {
    this.subdivisionDataSourceService$ = exCreateService$(() => new SubdivisionDataSourceService(this.dataSourceService, this.dbChangedListener));

    this.subdivisionsData$ = defer(() => combineLatest([
      this.subdivisionDataSourceService$,
      this.form.controls.dataItem.controls.date.valueChanges.pipe(startWith(this.form.controls.dataItem.controls.date.value)),
    ])
      .pipe(
        switchMap(([service, forDate]) => service.reloadData$({ forDate, startDate: null, endDate: null, subdivisionIds: [this.dataSourceService.currentSubdivisionId] })),
        switchMap(v => v.data$),
      ));

    this.reportDatesComponentSharedService.reportDateValues$
      .pipe(takeUntil(this.streams$.unsubscribes),
        trace(this.traceService), exErrorHandler(this.displayErrorService))
      .subscribe(value => this.svodDate = moment(value.reportDate).startOf('day').toDate());
  }

  public dbCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    if (this.form) {
      return;
    }

    const field = e.originalEvent.column.field;
    const dataItem = e.originalEvent.dataItem;

    if (field === this.fieldsNames.letalisMale.toString() || field === this.fieldsNames.letalisFamale.toString()) {
      this.service.showAdditionallyInfosDialog(this.dataSourceService, dataItem.id, dataItem.date, 1);
    } else if (field === this.fieldsNames.military.toString()) {
      this.service.showAdditionallyInfosDialog(this.dataSourceService, dataItem.id, dataItem.date, 2);
    } else if (field === this.fieldsNames.fromNewSubjects.toString()) {
      this.service.showAdditionallyInfosDialog(this.dataSourceService, dataItem.id, dataItem.date, 3);
    }
  }

  /** Вызвать режим добавления новой записи в Grid */
  public addRow(e: AddEvent): void {
    const lastDataItem = this.dataSourceService.dataSource.data.slice(-1)[0];
    const lastCalculatedFields = PatientMovementCalculatedFields.get(lastDataItem);

    this.form = this.createForm({
      consistedVich: lastDataItem?.consistsVich,
      consistedCovid: lastDataItem?.consistsCovid,
      consistedObservation: lastDataItem?.consistsObservation,
      consistedOrvi: lastDataItem?.consistsOrvi,
      consistedChildOrvi: lastDataItem?.consistsChildOrvi,
      consistedPregnantBefore22Weeks: lastDataItem?.consistsPregnantBefore22Weeks,
      consistedPregnantAfter22Weeks: lastDataItem?.consistsPregnantAfter22Weeks,
      consistedMen: lastCalculatedFields?.consistsMen,
      consistedWomen: lastCalculatedFields?.consistsWomen,
    } as IPatientMovement);

    e.sender.addRow(this.form);
  }

  /** Вызвать режим изменения записи в Grid */
  public editRow(e: EditEvent) {
    const item: PatientMovementDataItem = e.dataItem;

    this.form = this.createForm(item);

    e.sender.editRow(e.rowIndex, this.form);
  }

  /** Сохранить запись */
  public saveRow(e: SaveEvent) {
    const value = this.form.value.dataItem as IPatientMovement;

    // Если новая строка
    if (e.isNew) this.add(value, e.sender, e.rowIndex);
    // Если редактирование
    else this.save(value, e.sender, e.rowIndex);
  }

  /** Закрыть редактируемую строку */
  public closeRow(grid: GridComponent, rowIndex: number) {
    grid.closeRow(rowIndex);
    this.form = null;
  }

  /** Удалить запись */
  public delete(entity: PatientMovementDataItem) {
    this.alertService.defaultAlertOption
      .question()
      .mod(x => {
        x.title = 'Подтверждение';
        x.message = 'Удалить запись?';
        x.buttons[1].text = 'Удалить';
        x.buttons[1].callBack = () =>
          this.dataSourceService.delete$(entity.id)
            .pipe(take(1), takeUntil(this.streams$.unsubscribes))
            .subscribe({
              error: error => this.displayErrorsService.handleError(error),
            });
      })
      .showAlert();
  }

  /** Добавить новую запись */
  public add(entity: IPatientMovement, sender: GridComponent, rowIndex: number) {
    this.dataSourceService.add$(entity)
      .pipe(take(1), takeUntil(this.streams$.unsubscribes))
      .subscribe({
        next: () => this.closeRow(sender, rowIndex),
        error: error => this.displayErrorsService.handleError(error),
      });
  }

  /** Сохранить запись */
  public save(entity: IPatientMovement, sender: GridComponent, rowIndex: number) {
    this.dataSourceService.save$(entity)
      .pipe(take(1), takeUntil(this.streams$.unsubscribes))
      .subscribe({
        next: () => this.closeRow(sender, rowIndex),
        error: error => this.displayErrorsService.handleError(error),
      });
  }

  /** Отправить запись в СВОД */
  public send(entity: PatientMovementDataItem) {
    this.alertService.defaultAlertOption
      .question()
      .mod(x => {
        x.title = 'Подтверждение';
        x.message = 'Отправить запись в СВОД?';
        x.buttons[1].text = 'Отправить';
        x.buttons[1].callBack = () =>
          this.dataSourceService.send$(entity.id, entity.timestamp)
            .pipe(take(1), takeUntil(this.streams$.unsubscribes))
            .subscribe({
              error: error => this.displayErrorsService.handleError(error),
            });
      })
      .showAlert();
  }

  /** Обработка события нажатия на кнопку "Печать" */
  @traceFunc()
  onPrintClick() {
    this.api1PrintReportControllerService.printPatientMovement$(this.svodDate)
      .pipe(take(1))
      .subscribe({
        next: value => {
          FileSaver.saveAs(value, `Движение пациентов_${moment().format('DD_MM_yyyy')}.xlsx`);
          },
        error: err => {
          this.displayErrorService.handleError(err);
        }
      });
  }

  /** Создание формы */
  private createForm(entity: IPatientMovement) {
    const form = new FormGroup<IPatientMovementsGridComponentForm>({

      dataItem: new FormGroup<IPatientMovementsGridComponentFormDataItem>({
        id: new FormControl<number>(entity?.id ?? null),
        timestamp: new FormControl<[]>(entity?.timestamp ?? null),
        date: new FormControl<Date>(entity?.date ?? moment(new Date()).startOf('day').toDate(), [Validators.required]),
        subdivisionId: new FormControl<number>(entity?.subdivisionId ?? this.dataSourceService.currentSubdivisionId ?? null, [Validators.required]),
        numberBeds: new FormControl<number>(entity?.numberBeds ?? this.dataSourceService.dataSource.data.slice(-1)[0]?.numberBeds ?? null, [Validators.required]),

        consistedMen: new FormControl<number>(entity?.consistedMen ?? null),
        consistedWomen: new FormControl<number>(entity?.consistedWomen ?? null),
        consistedVich: new FormControl<number>(entity?.consistedVich ?? null),
        consistedCovid: new FormControl<number>(entity?.consistedCovid ?? null),
        consistedObservation: new FormControl<number>(entity?.consistedObservation ?? null),
        consistedOrvi: new FormControl<number>(entity?.consistedOrvi ?? null),
        consistedChildOrvi: new FormControl<number>(entity?.consistedChildOrvi ?? null),
        consistedPregnantBefore22Weeks: new FormControl<number>(entity?.consistedPregnantBefore22Weeks ?? null),
        consistedPregnantAfter22Weeks: new FormControl<number>(entity?.consistedPregnantAfter22Weeks ?? null),

        receivedMen: new FormControl<number>(entity?.receivedMen ?? null),
        receivedWomen: new FormControl<number>(entity?.receivedWomen ?? null),
        receivedVich: new FormControl<number>(entity?.receivedVich ?? null),
        receivedCovid: new FormControl<number>(entity?.receivedCovid ?? null),
        receivedObservation: new FormControl<number>(entity?.receivedObservation ?? null),
        receivedOrvi: new FormControl<number>(entity?.receivedOrvi ?? null),
        receivedChildOrvi: new FormControl<number>(entity?.receivedChildOrvi ?? null),
        receivedChildCommunityAcquiredPneumonia: new FormControl<number>(entity?.receivedChildCommunityAcquiredPneumonia ?? null),
        receivedPregnantBefore22Weeks: new FormControl<number>(entity?.receivedPregnantBefore22Weeks ?? null),
        receivedPregnantAfter22Weeks: new FormControl<number>(entity?.receivedPregnantAfter22Weeks ?? null),

        transToDepartmentMen: new FormControl<number>(entity?.transToDepartmentMen ?? null),
        transToDepartmentWomen: new FormControl<number>(entity?.transToDepartmentWomen ?? null),

        transFromDepartmentMen: new FormControl<number>(entity?.transFromDepartmentMen ?? null),
        transFromDepartmentWomen: new FormControl<number>(entity?.transFromDepartmentWomen ?? null),

        droppedOutMen: new FormControl<number>(entity?.droppedOutMen ?? null),
        droppedOutWomen: new FormControl<number>(entity?.droppedOutWomen ?? null),

        letalisMen: new FormControl<number>(entity?.letalisMen ?? null),
        letalisWomen: new FormControl<number>(entity?.letalisWomen ?? null),

        consistsVich: new FormControl<number>(entity?.consistsVich ?? null),
        consistsCovid: new FormControl<number>(entity?.consistsCovid ?? null),
        consistsObservation: new FormControl<number>(entity?.consistsObservation ?? null),
        consistsOrvi: new FormControl<number>(entity?.consistsOrvi ?? null),
        consistsChildOrvi: new FormControl<number>(entity?.consistsChildOrvi ?? null),
        consistsChildCommunityAcquiredPneumonia: new FormControl<number>(entity?.consistsChildCommunityAcquiredPneumonia ?? null),
        consistsPregnantBefore22Weeks: new FormControl<number>(entity?.consistsPregnantBefore22Weeks ?? null),
        consistsPregnantAfter22Weeks: new FormControl<number>(entity?.consistsPregnantAfter22Weeks ?? null),
        consistsChild: new FormControl<number>(entity?.consistsChild ?? null),
        consistsMoms: new FormControl<number>(entity?.consistsMoms ?? null),

        freeBedsMale: new FormControl<number>(entity?.freeBedsMale ?? null),
        freeBedsFamale: new FormControl<number>(entity?.freeBedsFamale ?? null),

        refusedTotal: new FormControl<number>(entity?.refusedTotal ?? null),
        refusedOrvi: new FormControl<number>(entity?.refusedOrvi ?? null),
        refusedChildOrvi: new FormControl<number>(entity?.refusedChildOrvi ?? null),
        refusedChildCommunityAcquiredPneumonia: new FormControl<number>(entity?.refusedChildCommunityAcquiredPneumonia ?? null),
        refusedTotalRoddom: new FormControl<number>(entity?.refusedTotalRoddom ?? null),
        refusedPregnantBefore22Weeks: new FormControl<number>(entity?.refusedPregnantBefore22Weeks ?? null),
        refusedPregnantAfter22Weeks: new FormControl<number>(entity?.refusedPregnantAfter22Weeks ?? null),

        transOutpatient: new FormControl<number>(entity?.transOutpatient ?? null),
        transSitting: new FormControl<number>(entity?.transSitting ?? null),
        transLying: new FormControl<number>(entity?.transLying ?? null),
        transNontransportable: new FormControl<number>(entity?.transNontransportable ?? null),

        operationsPlanned: new FormControl<number>(entity?.operationsPlanned ?? null),
        operationsEmergency: new FormControl<number>(entity?.operationsEmergency ?? null),

        roddomBirths: new FormControl<number>(entity?.roddomBirths ?? null),
        roddomAbortions: new FormControl<number>(entity?.roddomAbortions ?? null),
        roddomBefore22Weeks: new FormControl<number>(entity?.roddomBefore22Weeks ?? null),
        roddomAfter22Weeks: new FormControl<number>(entity?.roddomAfter22Weeks ?? null),
        roddomInGynecology: new FormControl<number>(entity?.roddomInGynecology ?? null),
        roddomNeonatalResuscitation: new FormControl<number>(entity?.roddomNeonatalResuscitation ?? null),

        miteApplied: new FormControl<number>(entity?.miteApplied ?? null),
        miteImmunoprophylaxis: new FormControl<number>(entity?.miteImmunoprophylaxis ?? null),
        miteDeleted: new FormControl<number>(entity?.miteDeleted ?? null),

        hospitalizationHospital103: new FormControl<number>(entity?.hospitalizationHospital103 ?? null),
        hospitalizationHospitalFlow: new FormControl<number>(entity?.hospitalizationHospitalFlow ?? null),
        hospitalizationHospitalPlan103: new FormControl<number>(entity?.hospitalizationHospitalPlan103 ?? null),
        hospitalizationHospitalPlan: new FormControl<number>(entity?.hospitalizationHospitalPlan ?? null),

        hospitalizationRoddom103: new FormControl<number>(entity?.hospitalizationRoddom103 ?? null),
        hospitalizationRoddomFlow: new FormControl<number>(entity?.hospitalizationRoddomFlow ?? null),
        hospitalizationRoddomPlan103: new FormControl<number>(entity?.hospitalizationRoddomPlan103 ?? null),
        hospitalizationRoddomPlan: new FormControl<number>(entity?.hospitalizationRoddomPlan ?? null),
      }),

      calculatedFields: new FormGroup<IPatientMovementsGridComponentFormCalculatedFields>({
        consistedTotal: new FormControl<number>(null),
        consistsTotal: new FormControl<number>(null),
        consistsMen: new FormControl<number>(null),
        consistsWomen: new FormControl<number>(null),
        freeBeds: new FormControl<number>(null),
        freeBedsPercent: new FormControl<number>(null),
        hospitalizationHospitalTotal: new FormControl<number>(null),
        hospitalizationRoddomTotal: new FormControl<number>(null),
      }),

    });

    form.controls.dataItem.valueChanges
      .pipe(startWith(form.controls.dataItem.value), takeUntil(this.streams$.unsubscribes))
      .subscribe((dataItem: IPatientMovement) => form.controls.calculatedFields.setValue(PatientMovementCalculatedFields.get(dataItem)));

    return form;
  }
}


/** Класс строки грида */
export class PatientMovementDataItem extends PatientMovement {
  /** Подразделение */
  subdivision: ISubdivision;
  /** Калькулируемые поля */
  calculatedFields: PatientMovementCalculatedFields;
  letalisMale: number;
  letalisFamale: number;
  military: number;
  fromNewSubjects: number;
}

/** Интерфейс формы компонента */
export interface IPatientMovementsGridComponentForm {
  /** Изменяемые поля */
  dataItem: FormGroup<IPatientMovementsGridComponentFormDataItem>;
  /** Калькулируемые поля */
  calculatedFields: FormGroup<IPatientMovementsGridComponentFormCalculatedFields>;
}

/** Тип формы изменяемых полей */
export type IPatientMovementsGridComponentFormDataItem = PropertyWrapFormControlType<Omit<PatientMovement, 'modifiedUserId' | 'modifiedDate' | 'comment' | 'deletedFlag' | 'sent'>>
/** Тип формы калькулируемых полей */
export type IPatientMovementsGridComponentFormCalculatedFields = PropertyWrapFormControlType<PatientMovementCalculatedFields>
