<ng-container *ngIf="downloadState == 1">
  <span>Загрузка...</span>
</ng-container>

<ng-container *ngIf="downloadState == 3">
  <span style="color: red">При загрузке произошла ошибка</span>
</ng-container>

<ng-container *ngIf="downloadState == 2">
  <p><strong>Ответственный ведения учета:</strong> {{dataSource.responsible}}</p>
  <app-monitoring-detail-grid [dataSource]="dataSource.information"></app-monitoring-detail-grid>
</ng-container>
