import {Injectable} from "@angular/core";
import {CustomStorageService, StorageLocationEnum, StorageOptions} from "./storages/custom-storage.service";
import * as moment from "moment";
import {AppSettingsService} from "./app-settings.service";
import {traceClass} from "../modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../modules/trace/tracers2/trace-services/tracer-base.service";

/** Общий сервис для всех toolbar */
@Injectable({providedIn: "root"})
@traceClass('CommonToolbarService')
export class CommonToolbarService{
  constructor(private appSettingsService: AppSettingsService,
              private customStorageService: CustomStorageService,
              private readonly tracerService: TracerServiceBase
  ) {
  }

  /** Хранимая дата */
  public get date(): Date{
    return this.customStorageService.get<Date>(this.sessionStorageOptions)
  }

  public set date(value: Date){
    this.tracerService.add2('set date', {obj: value})
    if(!value){
      this.customStorageService.remove(this.sessionStorageOptions);
      return;
    }
    this.customStorageService.set(this.sessionStorageOptions, value);
  }

  /** Получить хранимую дату как объект year/month/day */
  public get dateAsObj(): {year: number, month: number, day: number}{
    const date = this.date;
    if(!date){
      return null;
    }
    const dateAsMoment = moment(date);
    return {
      year: dateAsMoment.year(),
      month: dateAsMoment.month(),
      day: dateAsMoment.date()
    }
  }


  /** Опции настройки CustomStorage для хранения данных даты */
  private get sessionStorageOptions(): StorageOptions {
    return new StorageOptions(
      StorageLocationEnum.SessionStorage,
      `CustomStorageService_${this.appSettingsService.company}_${this.appSettingsService.program}`,
      null,
      false,
      false
    )
  }
}
