import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { IResponsibleItem } from 'src/app/components/administrations/entity/responsible-item.class';
import { WebApi1Service } from '../web-api1.service';
import { IResponsible } from 'src/app/classes/domain/POCOs/timesheet/Responsible';
import { map } from 'rxjs/operators';
import { ISubdivision } from '../../../../classes/domain/POCOs/stafflist/Subdivision';

@Injectable({ providedIn: 'root' })
export class Api1ResponsibleControllerService {

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {
  }

  /* Получить ResponsibleItems для отображения в скисках ответственных */
  public getResponsibleEmployees$(forDate: Date, subdivisionId?: number, includeDeleted = false): Observable<IResponsibleItem[]> {
    return this.httpClient.post<IResponsibleEmployee[]>(
        this.webApi1Service.controllers.responsible.actions.getResponsibleEmployees.toString(),
        { forDate, subdivisionId, includeDeleted })
      .pipe(map(m => m.map(c => <IResponsibleItem>{ employeeCode: c.employeeCode, employeeName: c.employeeName, responsibleId: c.employeeId })));
  }

  /* Получить подразделения за которые ответственен пользователь */
  public getResponsibleSubdivisions$(forDate: Date, includeDeleted = false): Observable<ISubdivision[]> {
    return this.httpClient.post<ISubdivision[]>(
        this.webApi1Service.controllers.responsible.actions.getResponsibleSubdivisions.toString(),
        { forDate, includeDeleted });
  }

  /* Добавление в БД записи об ответственном (Responsible) */
  public addResponsibleEmployee$(employeeId: number, subdivisionId: number): Observable<IResponsible> {
    return this.httpClient.post<IResponsible>(this.webApi1Service.controllers.responsible.actions.add.toString(),
      {
        employeeId: employeeId,
        subdivisionId: subdivisionId,
      });
  }

  /* Удаление из БД записи об ответственном (Responsible) */
  deleteResponsibleEmployee$(employeeId: number, subdivisionId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.responsible.actions.delete.toString(),
      {
        employeeId: employeeId,
        subdivisionId: subdivisionId,
      });
  }


}

interface IResponsibleEmployee {
  employeeId: number,
  employeeName: string,
  employeeCode: string
}
