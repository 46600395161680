import {Injectable, OnDestroy} from "@angular/core";
import {ComponentServiceBase} from "../../../../../../../../../src/app/services/abstracts/component-service-base";
import {
  IGraphTableToolbarComponent, IGraphTableToolbarComponent_StateChangeEventObj
} from "../i-graph-table-toolbar.component";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {GraphTableToolbarStorageServiceBase} from "./graph-table-toolbar-storage.service";
import {
  SubdivisionsTreelistComponentDataSourceService2,
} from "../../../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {
  YearMonthService,
  YearMonthService_Api1DateControllerService
} from "../../../../../../../../../src/app/services/year-month-services/year-month.service";
import {
  Api1DateControllerService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-date-controller.service";
import {ReplaySubject} from "rxjs";
import {LoadingIndicatorService} from "../../../../../../../../../src/app/services/loading-indicator.service";
import {exLoadingMessage2} from "../../../../../../../../../src/app/operators/ex-loading-message.operator";
import {take, takeUntil} from "rxjs/operators";
import {exErrorHandler} from "../../../../../../../../../src/app/operators/ex-error-handler";
import {
  DisplayErrorsService
} from "../../../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {DataSource} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";

/** Базовый сервис для компонента */
@Injectable()
export abstract class GraphTableToolbarServiceBase extends ComponentServiceBase<IGraphTableToolbarComponent> implements OnDestroy {
  /** Проинициализирован ли сервис */
  private _isInitialized = false;

  /** Источник данных значений */
  public abstract readonly valueDataSource: DataSource<IGraphTableToolbarComponent_StateChangeEventObj>;

  public get disabled(){
    return this.component.disabled;
  }
  public set disabled(value){
    this.component.disabled = value;
    this.component.changeDetectorRef.markForCheck();
  }

  /** Инициализировать сервис */
  public onInit(component: IGraphTableToolbarComponent){
    if(this._isInitialized){
      throw new Error('Сервис уже проинициализировать');
    }

    this.component = component;
  }

  /** @inheritDoc */
  public abstract ngOnDestroy(): void;
}

/**
 * Сервис компонента.
 * <br>
 * Инициализация:
 * 1. Инициализирует поля {@link IGraphTableToolbarComponent.subdivisionDataSource}, {@link IGraphTableToolbarComponent.yearMonthService} из инжектируюмых сервисов в конструктора
 * 2. Загружает данные в {@link YearMonthService}
 * 3. Устанавливает Год/Месяц из {@link GraphTableToolbarStorageServiceBase}, если отсутствуют то устанавливает текущей датой
 * 4. После загрузки данных подразделений компонентом, устанавливает выбранное подразделение из {@link GraphTableToolbarStorageServiceBase} или ставит первый из данных.
 * <br>
 * Дальнейшая работа
 * 1. Сохранение в хранилище при изменении данных
 */
@Injectable()
@traceClass('GraphTableToolbar1Service')
export class GraphTableToolbar1Service extends GraphTableToolbarServiceBase {
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** @inheritDoc */
  public valueDataSource = new DataSource<IGraphTableToolbarComponent_StateChangeEventObj>();

  constructor(private readonly storageService: GraphTableToolbarStorageServiceBase,
              private readonly subdivisionsService: SubdivisionsTreelistComponentDataSourceService2,
              private readonly yearMonthService: YearMonthService,
              private readonly dateControllerService: Api1DateControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly displayErrorsService: DisplayErrorsService,

              private readonly traceService: TracerServiceBase) {
    super();
  }

  /** @inheritDoc */
  @traceFunc()
  public onInit(component: IGraphTableToolbarComponent) {
    super.onInit(component);

    component.subdivisionDataSource = this.subdivisionsService;
    component.yearMonthService = this.yearMonthService;

    /** Обработка изменения значения в компоненте */
    component.change
      .pipe(
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        this.valueDataSource.setData(value);
        this.storageService.valueDataSource.setData({
          yearId: value.yearId,
          monthId: value.monthId,
          subdivisionOwnerId: value.subdivisionOwnerId
        })
    })

    const valueFromStorage = this.storageService.valueDataSource.data;

    /** После первого получения данных, установка выбранного подразделения */
    this.subdivisionsService.dataSource.change$
      .pipe(
        take(1),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        if(!!valueFromStorage){
          this.component.subdivisionSelection.setIds([valueFromStorage.subdivisionOwnerId]);
        } else if (value.length > 0) {
          this.component.subdivisionSelection.setIds([value[0].id]);
        }
    })

    //Инициализация данных дла Год/Месяц
    this.yearMonthService.reloadData$(new YearMonthService_Api1DateControllerService(this.dateControllerService))
      .pipe(
        exLoadingMessage2({service: this.loadingIndicatorService, message: 'Получение данных для Год/Месяц'}),
        exErrorHandler(this.displayErrorsService),
        take(1),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        if(!!valueFromStorage){
          this.yearMonthService.setSelection = {yearId: valueFromStorage.yearId, monthId: valueFromStorage.monthId};
        } else {
          this.yearMonthService.setSelection = new Date();
        }
    })
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.valueDataSource.onDestroy();
  }
}
