import {IRouteBase, RouteBase} from "../timesheet/RouteBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITableRoute extends IRouteBase{

}

@classBackend('TableRoute', 'timesheet_table')
@className('TableRoute')
/** POCO маршрута табеля */
export class TableRoute extends RouteBase implements ITableRoute{

}
