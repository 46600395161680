import {Directive, TemplateRef} from "@angular/core";

/** Директива шаблона шапки таблицы графика. */
@Directive({
  selector: '[graph-grid-toolbar-template]',
})
export class GraphGridToolbarTemplateDirective {
  constructor(public readonly templateRef: TemplateRef<any>) {
  }
}
