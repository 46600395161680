import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PatientMovementAdditionallyInfosGridDataSourceServiceBase } from './services/patient-movement-additionally-infos-grid-data-source.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, EditEvent, GridComponent, SaveEvent } from '@progress/kendo-angular-grid';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { IPatientMovementAdditionallyInfo } from '../../../../../../../src/app/classes/domain/POCOs/timesheet/PatientMovementAdditionallyInfo';
import { Observable, ReplaySubject, startWith, switchMap, take, takeUntil } from 'rxjs';
import { AlertService } from '../../../../../../../src/app/services/alert.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { AddPatientMovementAdditionallyInfoRequestDTO, SavePatientMovementAdditionallyInfoRequestDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-patient-movement-additionally-info-controller.service';
import { SubdivisionDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/subdivision-data-source.service';
import { ISubdivision } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision';
import { map } from 'rxjs/operators';
import { GenderEnum } from '../../../../../../../src/app/classes/domain/enums/gender-enum';
import { CustomFormValidators } from '../../../../../../../src/app/validators/custom-form-validators.class';
import { exCreateService$ } from '../../../../../../../src/app/operators/ex-create-service.operator';
import { DbChangedListener } from '../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';

@Component({
  selector: 'app-additionally-infos-grid',
  templateUrl: './patient-movement-additionally-infos-grid.component.html',
  styleUrls: ['./patient-movement-additionally-infos-grid.component.css'],
})
export class PatientMovementAdditionallyInfosGridComponent implements OnInit, OnDestroy {

  public form: FormGroup<PropertyWrapFormControlType<PatientMovementAdditionallyInfosGridComponentDataItem>>;

  public subdivisionDataSourceService$: Observable<SubdivisionDataSourceService>;

  @Input() public currentSubdivisionId: number;
  @Input() public forDate: Date;
  @Input() public typeId: number;

  public subdivisionsData$: Observable<ISubdivision[]>;
  public currentSubdivision$: Observable<ISubdivision>;

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };
  private _dataSourceService: PatientMovementAdditionallyInfosGridDataSourceServiceBase;

  @Input()
  public get dataSourceService(): PatientMovementAdditionallyInfosGridDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: PatientMovementAdditionallyInfosGridDataSourceServiceBase) {
    if (this._dataSourceService) {
      throw Error('Повторная установка [dataSourceService] не поддерживается!');
    }
    this._dataSourceService = value;
  }

  constructor(private readonly alertService: AlertService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly displayErrorsService: DisplayErrorsService) {
  }

  ngOnInit() {
    this.subdivisionDataSourceService$ = exCreateService$(() => new SubdivisionDataSourceService(this.dataSourceService, this.dbChangedListener));

    this.subdivisionsData$ = this.subdivisionDataSourceService$
      .pipe(
        switchMap((service) => service.reloadData$({ forDate: this.forDate, startDate: null, endDate: null, subdivisionIds: null })),
        switchMap(v => v.data$),
      );

    this.currentSubdivision$ = this.subdivisionsData$.pipe(map(v => v.find(pm => pm.id == this.currentSubdivisionId) ?? {} as ISubdivision), startWith({} as ISubdivision));
  }

  /** Вызвать режим добавления новой записи в Grid */
  public addRow(e: AddEvent): void {
    this.form = this.createForm(null);

    e.sender.addRow(this.form);
  }

  /** Вызвать режим изменения записи в Grid */
  public editRow(e: EditEvent) {
    this.form = this.createForm(e.dataItem);

    e.sender.editRow(e.rowIndex, this.form);
  }

  /** Сохранить запись */
  public saveRow(e: SaveEvent) {
    // Если новая строка
    if (e.isNew) this.add(this.form.value as AddPatientMovementAdditionallyInfoRequestDTO, e.sender, e.rowIndex);
    // Если редактирование
    else this.save(this.form.value as SavePatientMovementAdditionallyInfoRequestDTO, e.sender, e.rowIndex);
  }

  /** Закрыть редактируемую строку */
  public closeRow(grid: GridComponent, rowIndex: number) {
    grid.closeRow(rowIndex);
    this.form = null;
  }

  public delete(entity: PatientMovementAdditionallyInfoRow) {
    this.alertService.defaultAlertOption
      .question()
      .mod(x => {
        x.title = 'Подтверждение';
        x.message = 'Удалить запись?';
        x.buttons[1].text = 'Удалить';
        x.buttons[1].callBack = () =>
          this.dataSourceService.delete$(entity.id)
            .pipe(take(1), takeUntil(this.streams$.unsubscribes))
            .subscribe({
              error: error => this.displayErrorsService.handleError(error),
            });
      })
      .showAlert();
  }

  /** Добавить новую запись */
  public add(entity: AddPatientMovementAdditionallyInfoRequestDTO, sender: GridComponent, rowIndex: number) {
    this.dataSourceService.add$(entity)
      .pipe(take(1), takeUntil(this.streams$.unsubscribes))
      .subscribe({
        next: () => this.closeRow(sender, rowIndex),
        error: error => this.displayErrorsService.handleError(error),
      });
  }

  /** Сохранить запись */
  public save(entity: SavePatientMovementAdditionallyInfoRequestDTO, sender: GridComponent, rowIndex: number) {
    this.dataSourceService.save$(entity)
      .pipe(take(1), takeUntil(this.streams$.unsubscribes))
      .subscribe({
        next: () => this.closeRow(sender, rowIndex),
        error: error => this.displayErrorsService.handleError(error),
      });
  }

  /** Создание формы */
  private createForm(entity: IPatientMovementAdditionallyInfo) {
    return new FormGroup<PropertyWrapFormControlType<PatientMovementAdditionallyInfosGridComponentDataItem>>({
      id: new FormControl<number>(entity?.id ?? null),
      timestamp: new FormControl<[]>(entity?.timestamp ?? null),
      comment: new FormControl<string>(''),
      typeId: new FormControl<number>(entity?.typeId ?? this.typeId ?? null),
      patientMovementId: new FormControl<number>(entity?.patientMovementId ?? this.dataSourceService.paramsDataSource.data.patientMovementId ?? null),
      subdivisionId: new FormControl<number>(entity?.subdivisionId ?? this.currentSubdivisionId ?? null, [Validators.required]),
      number: new FormControl<string>(entity?.number ?? null),
      fio: new FormControl<string>(entity?.fio ?? null),
      gender: new FormControl<number>(entity?.gender ?? null, [CustomFormValidators.requiredIf(() => this.typeId === 1)]),
      age: new FormControl<number>(entity?.age ?? null),
      diagnosis: new FormControl<string>(entity?.diagnosis ?? null),
    });
  }

  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}

export class PatientMovementAdditionallyInfoRow {
  constructor(public id: number,
              public timestamp: [],
              public patientMovementId: number,
              public subdivisionId: number,
              public subdivisionLongName,
              public typeId: number,
              public number: string,
              public fio: string,
              public gender: GenderEnum,
              public genderText: string,
              public age: number,
              public diagnosis: string) {
  }
}

export type PatientMovementAdditionallyInfosGridComponentDataItem = Pick<IPatientMovementAdditionallyInfo, 'id' | 'timestamp' | 'comment' | 'typeId' | 'patientMovementId' | 'subdivisionId' | 'number' | 'fio' | 'gender' | 'age' | 'diagnosis'>;
