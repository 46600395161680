import {EditStatusBase, IEditStatusBase} from "../timesheet/EditStatusBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITableEditStatus extends IEditStatusBase{

}

@classBackend('TableEditStatus', 'timesheet_table')
@className('TableEditStatus')
/** POCO информации редактирования табеля */
export class TableEditStatus extends EditStatusBase implements ITableEditStatus{

}
