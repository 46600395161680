import {ChangeDetectionStrategy, Component, EventEmitter, Output, SkipSelf} from '@angular/core';
import {GraphDataSourceService} from "../../graph-table-workspace/graph-grid/secvices/graph-data-source.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-graph-custom-settings',
  templateUrl: './graph-custom-settings.component.html',
  styleUrl: './graph-custom-settings.component.css',
  providers: [GraphDataSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphCustomSettingsComponent {
  /**
   * Событие отмены выбора
   */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Событие выбора
   */
  @Output() public select$: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

  public staffUnits: {id: number, employeeName: string, occupationAndTypeNames: string, rate: number}[] = [];

  protected selectedStaffUnitIds: number[] = [];

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(@SkipSelf() public graphDataService: GraphDataSourceService) {
    this.graphDataService.source$.pipe().subscribe(source => {
      this.staffUnits = source.map(x => ({
        id: x.staffUnit.ownerId,
        employeeName: x.staffUnit.employee.fullName,
        occupationAndTypeNames: `${x.staffUnit.occupation.name} (${x.staffUnit.staffUnitType.description.toLowerCase()})`,
        rate: x.staffUnit.rate
      }));
    });
  }

  public ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public onOk() {
    this.select$.emit(this.selectedStaffUnitIds);
  }

  public onCancel() {
    this.cancel$.emit();
  }
}
