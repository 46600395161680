import {Injectable} from "@angular/core";
import {DialogRef, DialogService, DialogSettings} from "@progress/kendo-angular-dialog";
import {CommentComponent} from "./comment.component";
import {take} from "rxjs/operators";
import {race} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CommentDialogService{
  constructor(private dialogService: DialogService) {
  }

  /** Открыть диалог */
  public open(options?: DialogSettings): IOpenResult{
    if(!options){
      options = {}
    }

    options.content = CommentComponent;
    options.title = 'Комментарий'

    const dialogRef = this.dialogService.open(options);
    const content: CommentComponent = dialogRef.content.instance;

    race([content.cancel$, content.ok$]).pipe(take(1)).subscribe(value => {
      setTimeout(() => {
        dialogRef.close();
      })
    })

    return {
      dialogRef: dialogRef,
      component: content
    }
  }
}

interface IOpenResult{
  dialogRef: DialogRef,
  component: CommentComponent
}
