import { EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { CovidLog2 } from "src/app/classes/domain/POCOs/timesheet/CovidLog2";

export interface ICovid2RegisterGridComponent {
  settings: Covid2RegisterComponentSettings

  isReadOnly: boolean

  data$: Observable<CovidLog2[]>

  form: FormGroup<ICovid2RegisterGridComponentForm>
}

export interface ICovid2RegisterGridComponentForm {
  id: FormControl<number>,
  staffUnitId: FormControl<number>
  diseaseNumber: FormControl<string>
  patientFio: FormControl<string>
}

export interface ICovid2RegisterGridComponentFormRaw {
  id: number,
  staffUnitId: number
  diseaseNumber: string
  patientFio: string
}

export type ICovid2RegisterGridComponentFormRawType = keyof ICovid2RegisterGridComponentFormRaw

/** Настройки(данные) передаваемые в компонент Covid2RegisterGridComponent */
export class Covid2RegisterComponentSettings {
  constructor(public date: Date,
              public staffUnitOwnerId: number) {
  }
}

/** Интерфейс события закрытия окна случаев работы с Covid2 */
export interface ICovid2RegisterGridComponentChangeEvent {
  /** Флаг если строки добавлялись/модифицировались/удалялись */
  changed: boolean;
  /** Количество covidLog2 записей */
  length: number;
}
