import {IRedactionBase, RedactionBase} from "../timesheet/RedactionBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITableRedaction extends IRedactionBase{

}

@classBackend('TableRedaction', 'timesheet_table')
@className('TableRedaction')
/** POCO редакции табеля */
export class TableRedaction extends RedactionBase implements ITableRedaction{

}
