import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {RedactionGrid2Component} from "../redaction-grid2.component";
import {Injectable, OnDestroy} from "@angular/core";
import {race, ReplaySubject} from "rxjs";
import {delay, map, take, takeUntil} from "rxjs/operators";

/** Сервис отображения {@link RedactionGrid2Component} в диалоговом окне */
@Injectable()
export class RedactionGridDialogService implements OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Ссылка на диалог */
  private dialogRef: DialogRef;

  constructor(private readonly dialogService: DialogService) {
  }

  /** Открыть диалоговое окно. Сперва закроет если оно открыто */
  public open() {
    this.close();

    this.dialogRef = this.dialogService.open({
      title: 'Список редакций',
      actions: [
        { text: 'Отмена', primary: false }
      ],
      actionsLayout: 'start',
      content: RedactionGrid2Component,
      maxHeight: '90%',
      width: '90%',
    })

    const component = this.dialogRef.content.instance as RedactionGrid2Component;

    race(component.preview$, component.copy$, component.compare$)
      .pipe(
        take(1),
        takeUntil(this.dialogRef.result),
        takeUntil(this.streams$.unsubscribe),
        delay(1), //Для возможности обработать данные события до уничтожения
      ).subscribe(value => {
        this.close();
      })

    this.dialogRef.result
      .pipe(
        takeUntil(this.streams$.unsubscribe),
      )
      .subscribe(value => {
      this.dialogRef = undefined;
    })

    return {
      dialogRef: this.dialogRef,
      /** Компонент для установки инициализирующих данных */
      redactionGridComponent: component,
      /** Стрим закрытия окна. */
      close$: this.dialogRef.result
        .pipe(
          map(value => {}),
          take(1),
        )
    }
  }

  /** Закрыть диалоговое окно, если оно открыто */
  public close(){
    this.dialogRef?.close();
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.close();
  }
}
