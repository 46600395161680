import {IRouteBase, RouteBase} from "../timesheet/RouteBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IGraphRoute extends IRouteBase{

}

@classBackend('GraphRoute', 'timesheet_graph')
@className('GraphRoute')
/** POCO маршрута графика */
export class GraphRoute extends RouteBase implements IGraphRoute{

}
