import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../interfaces/IEntityId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IStaffUnitType extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Описание типа ставки штатной единицы */
  description: string;
}

@classBackend('StaffUnitType', 'stafflist')
@className('StaffUnitType')
/** Тип ставки штатной единицы (основная, совмещение, совместительство) */
export class StaffUnitType implements IStaffUnitType {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public description: string,
  ) {
  }

  private static _fullComparer: ObjComparer<IStaffUnitType>;
  /** Сравнить по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<IStaffUnitType>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        deletedFlag: true,
        description: true,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IStaffUnitType, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнить по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}
