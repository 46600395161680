import {Injectable} from "@angular/core";
import {defer, Observable, of} from "rxjs";
import {GraphDataSourceService} from "./graph-data-source.service";
import {LoadingIndicatorService} from "../../../../../../../../src/app/services/loading-indicator.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {map} from "rxjs/operators";
import {GraphGridRowModel} from "../classes/view-models/row-and-graph-day-view-model.class";
import {StaffUnitTypeVM} from "../classes/view-models/staff-unit-type-view-model.class";
import {EmployeeVm} from "../classes/view-models/employee-view-model.class";
import {Api1StaffUnitTypeControllerService} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {Api1EmployeeControllerService} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-controller.service";
import {ServerLoggerService} from "../../../../../../../../src/app/services/loggers/server-logger.service";
import * as moment from 'moment';
import {PositionVM} from "../classes/view-models/position-view-model.class";
import {OccupationVM} from "../classes/view-models/occupation-view-model.class";
import {WorkModeVM} from "../classes/view-models/work-mode-view-model.class";
import {Api1PositionControllerService} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-position-controller.service";
import {Api1OccupationsControllerService} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-occupations-controller.service";
import {Api1WorkModeControllerService} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-work-mode-controller.service";
import {FinancingSourceVM} from "../classes/view-models/financing-source-view-model";

/**
 * Сервис для компонента GraphGridComponent
 * Должны быть методы не завязанные на конкретный компонент.
 * Так как данный сервис singleton а другие сервисы используемые в компоненте НЕ singleton
 */
@Injectable({
  providedIn: "root"
})
export class GraphDataSourceHelperService{
  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private api1GraphControlControllerService: Api1GraphControlControllerService,
    private api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
    private api1EmployeeControllerService: Api1EmployeeControllerService,
    private loggerService: ServerLoggerService,
    private api1PositionControllerService: Api1PositionControllerService,
    private api1OccupationsControllerService: Api1OccupationsControllerService,
    private api1WorkModeControllerService: Api1WorkModeControllerService
  ) {
  }

  /** Получить StaffUnitType из View если нет загрузить с сервера */
  public findOrGetStaffUnitType$(graphDataSourceService: GraphDataSourceService, staffUnitTypeId: number): Observable<StaffUnitTypeVM>{
    return this.findOrGet(graphDataSourceService.source,
        x => x.staffUnit.staffUnitType,
        x => x.id == staffUnitTypeId,
      'Получение типа штатной единицы с сервера',
        this.api1StaffUnitTypeControllerService.getByIds$([staffUnitTypeId]).pipe(map(value => {
          return StaffUnitTypeVM.Create2(value[0]);
        })));
  }

  /** Получить Position из View если нет загрузить с сервера */
  public findOrGetPosition$(graphDataSourceService: GraphDataSourceService, positionOwnerId: number, month: Date): Observable<PositionVM>{
    return this.findOrGet(graphDataSourceService.source,
      x => x.staffUnit.position,
      x => x?.ownerId == positionOwnerId,
      'Получение позиции штатной единицы',
      this.api1PositionControllerService.getByOwnerIds$([positionOwnerId], moment(month).endOf("month").toDate(), null, null)
        .pipe(map(value => {
          return PositionVM.Create2(value[0])
        })))
  }

  /** Получить Occupation из View если нет загрузить с сервера */
  public findOrGetOccupation$(graphDataSourceService: GraphDataSourceService, occupationOwnerId: number, month: Date): Observable<OccupationVM>{
    return this.findOrGet(graphDataSourceService.source,
      x => x.staffUnit.occupation,
      x => x?.ownerId == occupationOwnerId,
      'Получение должности штатной единицы',
      this.api1OccupationsControllerService.getForDate$( moment(month).endOf("month").toDate(), null, null, [occupationOwnerId])
        .pipe(map(value => {
          return OccupationVM.Create2(value[0]);
        })))
  }

  /** Получить WorkMode из View если нет загрузить с сервера */
  public findOrGetWorkMode$(graphDataSourceService: GraphDataSourceService, workModeOwnerId: number, month: Date): Observable<WorkModeVM>{
    return this.findOrGet(graphDataSourceService.source,
      x => x.staffUnit.workMode,
      x => x?.ownerId == workModeOwnerId,
      'Получение режима работы штатной единицы',
      this.api1WorkModeControllerService.getForDate$(moment(month).endOf("month").toDate(), null, null, [workModeOwnerId])
        .pipe(map(value => {
          return WorkModeVM.Create2(value[0]);
        })))
  }

  /** Получить Employee из View если нет то загрузить с сервера */
  public findOrGetEmployee$(graphDataSourceService: GraphDataSourceService, employeeOwnerId: number, month: Date): Observable<EmployeeVm>{
    return this.findOrGet(graphDataSourceService.source,
      x => x.staffUnit.employee,
      x => x?.ownerId == employeeOwnerId,
      'Получение сотрудника с сервера',
      this.api1EmployeeControllerService.getForDate$(moment(month).endOf('month').toDate(), null,null,[employeeOwnerId])
        .pipe(map(value => {
          return EmployeeVm.Create2(value[0]);
        })))
  }

  /** Получение FinancingSource из View если его нет то получает из стартовых данных */
  public findOrGetFinancingSource$(graphDataSourceService: GraphDataSourceService, financingSourceId: number): Observable<FinancingSourceVM>{
    return this.findOrGet(graphDataSourceService.source,
      x => x.staffUnit.financingSource,
      x => x?.id == financingSourceId,
      'Получение источника финансирования',
      of(FinancingSourceVM.Create(graphDataSourceService.sourceFromServer.directories.financingSources.find(x => x.id == financingSourceId)))
      );
  }

  /** Найти или загрузить с сервера */
  private findOrGet<TElement>(rows: Array<GraphGridRowModel>, get: (item: GraphGridRowModel) => TElement, comparer: (element: TElement) => boolean,
                              loadingMessage: string, download: Observable<TElement>) : Observable<TElement>{
    return defer(() => {
      const fined = rows.map(x => get(x)).filter(x => !!x).find(comparer);
      return fined ?
        of(fined) :
        this.loadingIndicatorService.addToObservable(
          loadingMessage,
          download
        )
    })

  }
}
