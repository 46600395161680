import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from "rxjs";
import { ComponentServiceBase } from "../../../../../../../src/app/services/abstracts/component-service-base";
import { ICorrectionListComponent } from "../i-correction-list-component";
import { take, takeUntil } from "rxjs/operators";
import {
  Api1PrintReportControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";

@Injectable()
export class CorrectionListComponentService extends ComponentServiceBase<ICorrectionListComponent> implements OnInit, OnDestroy {

  public unsubscribe$ = new ReplaySubject<any>(1);

  constructor(private readonly reportService: Api1PrintReportControllerService,
    private readonly displayErrorsService: DisplayErrorsService,
    private readonly loadingIndicatorService: LoadingIndicatorService) {
    super();
  }


  ngOnInit(): void {
    switch (this.component.type) {
      case 'table_correction':
        this.loadingIndicatorService.addToObservable('Загрузка списка корректировок',
          this.reportService.getCorrectionTableRedactions$(this.component.subdivisionId, this.component.month)
            .pipe(take(1), takeUntil(this.unsubscribe$)))
          .subscribe({
            next: value => this.component.correctionRedactionList = value,
            error: error => {
              this.component.cancel$.emit();
              this.displayErrorsService.showSimpleError(
                "Не получилось загрузить информацию о корректирующих редакциях данного табеля.\r\nПопробуйте еще раз.");
            }
          });
        break;
      case 'graph_correction':
        this.loadingIndicatorService.addToObservable('Загрузка списка корректировок',
          this.reportService.getCorrectionGraphRedactions$(this.component.subdivisionId, this.component.month)
            .pipe(take(1), takeUntil(this.unsubscribe$)))
          .subscribe({
            next: value => this.component.correctionRedactionList = value,
            error: error => {
              this.component.cancel$.emit();
              this.displayErrorsService.showSimpleError(
                "Не получилось загрузить информацию о корректирующих редакциях данного графика.\r\nПопробуйте еще раз.");
            }
          });
        break;
    }
  }

  // Метод для сохранения сформированного отчета "Корректирующий график/табель"
  public print(redactionId: number) {
    switch (this.component.type) {
      case 'table_correction':
        this.reportService.printTable$(redactionId, this.component.type, null, null)
          .subscribe(resp => {
            if (resp.size === 0) {
              this.displayErrorsService.showSimpleError(
                'Отсутствует предыдущая утвержденная версия табеля, необходимая для выявления произведенных изменений');
              return;
            }
            FileSaver.saveAs(resp, `${this.component.text}_${moment().format('DD_MM_yyyy')}.xlsx`);
          });
        break;
      case "graph_correction":
        this.reportService.printGraphNew$(redactionId, this.component.type)
          .subscribe(resp => {
            if (resp.size === 0) {
              this.displayErrorsService.showSimpleError(
                'Отсутствует предыдущая утвержденная версия графика, необходимая для выявления произведенных изменений');
              return;
            }
            FileSaver.saveAs(resp, `${this.component.text}_${moment().format('DD_MM_yyyy')}.xlsx`)
          });
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
