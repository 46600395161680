import {GetGraphModelResult_Directories_DayType} from "../../../../../Classes/GetGraphModelResult";
import {IDayType} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/DayType";

export class DayType implements Pick<IDayType, 'id' | 'name' | 'color'>{
  constructor(
    public id: number,
    public name: string,
    public color: string,
    ) {
  }

  public static Create(source: GetGraphModelResult_Directories_DayType): DayType {
    return new DayType(source.id, source.name, source.color);
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_DayType>): Array<DayType> {
    return source.map(item => {
      return DayType.Create(item);
    });
  }
}
