import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface ITuberLog extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
  /** Номер истории болезни пациента */
  diseaseNumber: string;
  /** ФИО пациента */
  patientFio: string;
  /** Эпидемиологический № */
  epidemNumber: string;
  /** Вид обслуживания */
  typeServiceId: number | null;
  /** Вид исследования */
  typeResearchId: number | null;
  customTypeResearch: string; // Кастомный вид иследования
  typeWorkId: number | null;//Вида работ или название материала
  customTypeWork: string; // Кастомный вид работ или название материала
  researchProtocol: string;// № протокола исследований
  /** Количество проведенных исследований в рамках данной записи */
  researchCount: number | null;
  operatingTime: number;
  /** Идентификатор настроек туб журнала, в рамках которых была создана эта запись */
  descriptorId: number;
}

@classBackend('TuberLog', 'timesheet')
@className('TuberLog')
/** Модель строки туберкулез журнала */
export class TuberLog implements ITuberLog {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public staffUnitId: number,
              public date: Date,
              public diseaseNumber: string,
              public patientFio: string,
              public epidemNumber: string,
              public typeServiceId: number | null,
              public typeResearchId: number | null,
              public customTypeResearch: string,
              public typeWorkId: number | null,
              public customTypeWork: string,
              public researchProtocol: string,
              public researchCount: number | null,
              public operatingTime: number,
              public descriptorId: number
  ) {
  }

  private static _fullComparer: ObjComparer<ITuberLog>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if (!this._fullComparer) {
      this._fullComparer = new ObjComparer<ITuberLog>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        deletedFlag: true,
        staffUnitId: true,
        date: ObjComparer.dateComparer,
        diseaseNumber: true,
        patientFio: true,
        epidemNumber: true,
        typeServiceId: true,
        typeResearchId: true,
        customTypeResearch: true,
        typeWorkId: true,
        customTypeWork: true,
        researchProtocol: true,
        researchCount: true,
        operatingTime: true,
        descriptorId: true
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<ITuberLog, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if (!this._usefulComparer) {
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }

  /** Получить заполненный по умолчанию */
  public static GetEmpty(staffUnitId: number, date: Date): TuberLog {
    return new TuberLog(0, null, null, false,
      staffUnitId, date, '', '', '', 0,
      0, null,0, null,
      '',0, 1, 0);
  }

}

