import * as moment from 'moment'
import {EventEmitter} from "@angular/core";

/** Класс настроек для компонента График/Табель */
export class GraphTableComponentSettings{
  /** Открыть график/табель для */
  openFor: GraphTableComponentSettings_OpenFor;

  /** Флаг - только в режиме чтения */
  readonly: boolean = false;

  /** Отображать ли в шапке таблицы компонент grid-toolbar-management.component */
  showToolbarManagementComponent: boolean = true;

  isEdit$: EventEmitter<boolean> = null;

  constructor(openFor: GraphTableComponentSettings_OpenFor, isEdit$: EventEmitter<boolean>) {
    if(!isEdit$){
      throw new Error('isEdit$ НЕ передан')
    }
    this.validatingOpenFor(openFor);
    this.openFor = openFor;
    this.isEdit$ = isEdit$;
  }

  private validatingOpenFor(openFor: GraphTableComponentSettings_OpenFor){
    this.openFor = openFor;
  }
}

export class GraphTableComponentSettings_OpenFor{
  /** По подразделению/году/месяцу */
  public bySubdivisionOwnerId: IBySubdivisionOwnerId = null;

  /** По идентификатору графика/табеля  */
  public byGraphOrTableId: number = null;

  /** По идентификатору редакции */
  public byRedactionId: number = null;


  constructor(
    bySubdivisionOwnerId?: {subdivisionOwnerId: number, date: {year: number, month: number} | Date },
    byGraphOrTableId?: number,
    byRedactionId?: number) {

    const paramAsArray = [
      bySubdivisionOwnerId,
      byGraphOrTableId,
      byRedactionId
    ];

    if(!paramAsArray.some(x => !!x)){
      throw new Error('Один из параметров должен быть заполнен')
    }

    if(paramAsArray.filter(x => !!x).length > 1){
      throw new Error('Только один из параметров должен быть заполнен')
    }

    if(!!bySubdivisionOwnerId){
      if(!bySubdivisionOwnerId.subdivisionOwnerId){
        throw new Error('Не передан аргумент subdivisionOwnerId')
      }

      if(!bySubdivisionOwnerId.date){
        throw new Error('Не передан аргумент data')
      }

      this.bySubdivisionOwnerId = {
        subdivisionOwnerId: bySubdivisionOwnerId.subdivisionOwnerId,
        date: null,
        year: null,
        month: null
      }
      if(bySubdivisionOwnerId.date instanceof Date){
        const asMoment = moment(bySubdivisionOwnerId.date);
        this.bySubdivisionOwnerId.date = bySubdivisionOwnerId.date;
        this.bySubdivisionOwnerId.year = asMoment.year();
        this.bySubdivisionOwnerId.month = asMoment.month() + 1;
      }else {
        if(!bySubdivisionOwnerId.date.year){
          throw new Error('Год не передан')
        }
        if(!bySubdivisionOwnerId.date.month){
          throw new Error('Месяц не передан')
        }

        this.bySubdivisionOwnerId.date = moment({
          year: bySubdivisionOwnerId.date.year,
          month: bySubdivisionOwnerId.date.month - 1,
          date: 1
        }).toDate();
        this.bySubdivisionOwnerId.year = bySubdivisionOwnerId.date.year;
        this.bySubdivisionOwnerId.month = bySubdivisionOwnerId.date.month;
      }

      return;
    }

    if(byGraphOrTableId){
      this.byGraphOrTableId = byGraphOrTableId;
      return;
    }

    if(byRedactionId){
      this.byRedactionId = byRedactionId;
      return;
    }
  }

  /** Получить значение */
  public getValue() : {type: 'subdivisionOwnerId' | 'graphOrTableId' | 'redactionId', value: number | IBySubdivisionOwnerId}{
    if(this.bySubdivisionOwnerId){
      return {type: "subdivisionOwnerId", value: this.bySubdivisionOwnerId};
    }
    if(this.byGraphOrTableId){
      return {type: "graphOrTableId", value: this.byGraphOrTableId}
    }
    if(this.byRedactionId){
      return {type: 'redactionId', value: this.byRedactionId}
    }
  }
}

export interface IBySubdivisionOwnerId{
  subdivisionOwnerId: number,
  year: number,
  month: number,
  date: Date
}
