import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {
  StaffUnitForProxyList
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";

@Component({
  selector: 'app-staffunit-list-dialog',
  templateUrl: './staffunit-list-dialog.component.html',
  styleUrls: ['./staffunit-list-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffunitListDialogComponent implements OnInit, OnDestroy {
  /** Событие отмены выбора */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Событие выбора */
  @Output() public select$: EventEmitter<StaffUnitForProxyList> = new EventEmitter<StaffUnitForProxyList>();

  @Input() public proxyStartDate: Date;
  @Input() public proxyEndDate: Date;

  public selectedStaffUnit: StaffUnitForProxyList = null;

  private unsubscribe$ = new ReplaySubject<any>(1);

  constructor() { }

  ngOnInit() {
  }

  onCancel() {
    this.selectedStaffUnit = null;
    this.cancel$.emit();
    this.cancel$.complete();
  }

  onOk() {
    if(this.selectedStaffUnit == null){
      throw new Error('Нет выделенной штатной единицы');
    }
    this.select$.emit(this.selectedStaffUnit);
    this.select$.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
