import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RedactionDetailGridComponentRowModel } from "./redaction-detail-grid/redaction-detail-grid.component";
import { ExtensionObj } from "../../../../../../../../src/app/helpers/extensionObj";
import { DataResult, process, State } from "@progress/kendo-data-query";
import {
  IRedactionServiceGetResponse,
  RedactionBaseService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import { Api1RedactionGraphService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-graph.service";
import { Api1RedactionTableService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-table.service";
import { LoadingIndicatorService } from "../../../../../../../../src/app/services/loading-indicator.service";
import {ReplaySubject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import { AlertService } from "../../../../../../../../src/app/services/alert.service";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { ResponseObjError } from "../../../../../../../../src/app/classes/requestResults/responseObjError";
import { KendoNotificationService } from "../../../../../../../../src/app/services/kendo-notification.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';

/** Класс события выделения */
export class RedactionDetailGridComponent_PreviewEvent {
  data: IRedactionServiceGetResponse;
  selectedRedactionId: number;
}

/** Класс управления колонкой команд в таблице редакций */
class ActionColumnState{
  /** Ширина колонки */
  public readonly columnWidth: number;

  constructor(public readonly hasCompareButton: boolean) {
    const tdPadding = 12; //Отступ у td
    const buttonWidth = 32; //Ширина кнопки
    const buttonMargin = 8; //Отступ между кнопками
    const reserve = 5; //Запас на не точность

    const buttonTotal = 2 + (hasCompareButton ? 1 : 0);

    this.columnWidth = (tdPadding * 2) + (reserve) + (buttonWidth * buttonTotal) + (buttonMargin * (buttonTotal - 1));
  }
}

/** Модель строки компонента */
class RedactionGridComponentRedactionRowModel {
  /**
   * Конструктор
   * @param version Версия к которой относится редакция
   * @param id Идентификатор
   * @param parentId Родитель
   * @param date Дата редакции
   * @param modifiedFio Пользователь создавший редакцию
   * @param comment Комментарий пользователя сосдавший редакцию
   * @param routes Макшруты согласования
   */
  constructor(public version: number,
              public id: number,
              public parentId: number,
              public date: Date,
              public modifiedFio: string,
              public comment: string,
              public routes: RedactionDetailGridComponentRowModel[],) {
  }

  /** Статус */
  get status(): string {
    return !this.routes || this.routes.length == 0 ? null : this.routes[0].displayStatus.text;
  }
}

@Component({
  selector: 'app-redaction-select',
  templateUrl: './redaction-grid.component.html',
  styleUrls: ['./redaction-grid.component.css']
})
@traceClass('RedactionGridComponent')
export class RedactionGridComponent implements OnInit, OnDestroy {
  /** Для чего нужно отобразить редакции */
  @Input() redactionFor: 'graph' | 'table' | null = null;
  /** Идентификатор графика/табеля */
  @Input() graphOrTableId: number = null;

  /** Идентификатор выделенной ячеки(Использовать только для инициализации) */
  @Input() initSelectedRowId: number = null;

  /** Имеет ли возможность сравнивать редакции */
  @Input() hasComparerButton: boolean = false;

  /** Событие простмотра редакции */
  @Output() preview$: EventEmitter<RedactionDetailGridComponent_PreviewEvent> = new EventEmitter<RedactionDetailGridComponent_PreviewEvent>();

  /** Событие сравнения редакции */
  @Output() compare$: EventEmitter<number> = new EventEmitter<number>();

  /** Состояние таблицы */
  public state: State = {
    sort: [{ field: 'id', dir: "desc" }],
    group: [{ field: 'version', dir: "desc" }]
  }

  /** DataSource для таблицы */
  public gridView: DataResult;

  /** Данные ответа сервера со всеми редакциями */
  public data: IRedactionServiceGetResponse = null;

  /** Список выделенных строк */
  public rowSelected: Array<number>;

  /** Сервис для работы с сервером */
  private redactionBaseService: RedactionBaseService = null;

  /** Состояние колонки действий таблицы */
  public actionColumnState: ActionColumnState = new ActionColumnState(this.hasComparerButton);

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  };

  constructor(private readonly rGService: Api1RedactionGraphService,
              private readonly rTService: Api1RedactionTableService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly alertService: AlertService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly tracer: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit(): void {
    if (!this.redactionFor) {
      throw new Error('@Input redactionFor - не передан');
    }

    if (!this.graphOrTableId) {
      throw new Error('@Input graphOrTableId - не передан');
    }

    switch (this.redactionFor) {
      case "graph":
        this.redactionBaseService = this.rGService;
        this.actionColumnState = new ActionColumnState(true);
        break;
      case "table":
        this.redactionBaseService = this.rTService;
        break;
      default: throw new Error('Out of range')
    }



    this.loadingIndicatorService.addToObservable(
      'Получение данных о редакциях',
      this.redactionBaseService.get$(this.graphOrTableId)
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.convertResponseAndFillGridView(value);
        this.rowSelected = this.initSelectedRowId ? [this.initSelectedRowId] : []
      }, error: error => {
        this.alertService.defaultAlertOption.downloadError().showAlert();
        return;
      }
    });
  }

  /** Обработка двойного клика по ячейке */
  @traceFunc()
  onDbCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.onPreview($event.originalEvent.dataItem);
  }

  /** Определять отображать/неотображать кнопку деталий */
  public detailTemplateShowIf(dataItem: RedactionGridComponentRedactionRowModel, index: number): boolean {
    return !!dataItem.status;
  }

  /** Обработка события нажатия на кнопку Копировать */
  @traceFunc()
  public onCopy(dataItem: RedactionGridComponentRedactionRowModel) {
    this.alertService.defaultAlertOption.confirmation().mod(x => {
      x.buttons[1].callBack = () => {
        this.redactionBaseService.copy$(dataItem.id)
          .pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
            next: value => {
              this.convertResponseAndFillGridView(value);
              this.onPreview(this.gridView.data[0].items.find(x => x.parentId == dataItem.id));
              this.kendoNotificationService.showSuccess({ content: 'Редакция скопирована' })
            }, error: error => {
              this.kendoNotificationService.showError({ content: 'Не удалось скопировать редакцию' })
              if (ResponseObjError.checkTypeReturnCode(error) == 'e4e9ea20-344b-44ed-a830-4da824ec7eff') {
                const innerError = (error as ResponseObjError<Array<string>>).data;
                this.alertService.defaultAlertOption.warning()
                  .mod(x => x.message = `<strong>${innerError}</strong><br><br>${x.message}`)
                  .showAlert();
                return;
              }
              this.alertService.defaultAlertOption.downloadError().showAlert();
              return;
            }
          })
      }
    }).showAlert();
  }

  /** Обработка события нажатия на кнопку Просмотр */
  @traceFunc()
  public onPreview(dataItem: RedactionGridComponentRedactionRowModel) {
    this.preview$.emit(new ExtensionObj(new RedactionDetailGridComponent_PreviewEvent()).modResult(x => {
      x.data = this.data;
      x.selectedRedactionId = dataItem.id;
    }))
  }

  /** Обработка события нажатия на кнопку Сравнить */
  @traceFunc()
  public onCompare(dataItem: RedactionGridComponentRedactionRowModel){
    this.compare$.emit(dataItem.id);
  }

  /** Конвертирует ответ сервера и заполняет источник данных для таблицы */
  private convertResponseAndFillGridView(response: IRedactionServiceGetResponse): void {
    this.data = response;

    const getterUserFio: (id: number) => string = id => {
      const user = !id ? null : response.users.find(x => x.id == id);
      return user?.fio ?? 'НЕИЗВЕСТНО';
    }

    const gridDataSource: RedactionGridComponentRedactionRowModel[] = response.redactions
      .map(item => new RedactionGridComponentRedactionRowModel(
        item.version,
        item.id,
        item.parentId,
        item.date,
        getterUserFio(item.userId),
        item.comment,
        item.routes.map(route => new RedactionDetailGridComponentRowModel(
          route.id,
          route.date,
          getterUserFio(route.userId),
          route.routeStatus,
          route.displayStatus,
          route.comment,
        ))
      ));

    this.gridView = process(gridDataSource, this.state);
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
