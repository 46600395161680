import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CellClickEvent} from "@progress/kendo-angular-grid";
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {

  public gridAdministrationData: IGridAdministrationItem[] = [
    {name: 'Ответственные за ведение графика/табеля', description: '', url: 'responsible-maintaining'},
    {name: 'Маршрут согласования графика', description: '', url: 'graph-approval-route'},
    {name: 'Маршрут согласования табеля', description: '', url: 'table-approval-route'},
    {name: 'Ответственные за подпись графика/табеля', description: '', url: 'responsible-sign'},
  ];

  basePath = "workspace/administration/";

  constructor(private router: Router,
              ) { }

  ngOnInit(): void {
  }

  onOpenBtnClick($event: IGridAdministrationItem) {
    if (!$event.url){
      alert('не реализовано')
    }

    this.router.navigate([this.basePath + $event.url]);
  }

  /** Обработка двойного клика по ячейки таблицы */
  onDoubleCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.onOpenBtnClick($event.originalEvent.dataItem)
  }
}

export interface IGridAdministrationItem{
  name: string,
  description: string,
  url: string,
}
