import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {AddEvent, CancelEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent} from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { Subdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { IVichRegisterGridComponent, IVichRegisterGridComponentChangeEvent, IVichRegisterGridComponentForm, IVichRegisterGridComponentFormType, VichRegisterComponentGridDataItem, VichRegisterComponentSettings } from './i-vich-register-grid.component';
import { VichRegisterGridComponentDataService } from './services/vich-register-grid-component-data.service';
import {
  VichRegisterGridComponentService
} from './services/vich-register-grid-component.service';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {ITypeWorkAndMaterial} from "../../../services/webApi/webApi1/controllers/api1-vich-controller.service";

@Component({
  selector: 'app-vich-register-grid',
  templateUrl: './vich-register-grid.component.html',
  styleUrls: ['./vich-register-grid.component.css'],
  providers: [
    VichRegisterGridComponentService,
    VichRegisterGridComponentDataService
  ]
})
export class VichRegisterGridComponent implements IVichRegisterGridComponent, OnInit {
  @Input() public settings: VichRegisterComponentSettings

  @Output() public currentRegisterType$ = new EventEmitter<number>();

  public typesResearchSource: IDropDownItem[];
  public typesServiceSource: IDropDownItem[];
  public filteredTypeWorks: ITypeWorkAndMaterial[];
  public typesTypeWorkSource: ITypeWorkAndMaterial[];
  public subdivisionsSource: Subdivision[];
  public isReadOnly: boolean;

  public data$: Observable<VichRegisterComponentGridDataItem[]>

  public form: FormGroup<IVichRegisterGridComponentForm>

  public currentRegisterTypeId: number;

  public valueNormalizer = this.service.valueNormalizer

  constructor(protected readonly service: VichRegisterGridComponentService,
              private readonly chDetector: ChangeDetectorRef) {
    service.component = this;
  }

  ngOnInit(): void {
    this.service.onInit()
  }

  public addRow(e: AddEvent) {
    this.currentRegisterTypeId = this.settings.lastChosenRegisterType;
    this.filterTypeWorksForControlSource(this.currentRegisterTypeId);
    this.service.addRow(this.currentRegisterTypeId, e.sender)
  }

  public editRow(e: EditEvent) {
    this.service.editRow(e);
    this.filterTypeWorksForControlSource(this.currentRegisterTypeId);
  }

  public cancelRow(e: CancelEvent) {
    this.service.closeRow(e.sender, e.rowIndex)
  }

  public saveRow(e: SaveEvent) {
    this.service.saveRow(e)
  }

  public removeRow(e: RemoveEvent) {
    this.service.removeRow(e)
  }

  public getChangeEvent(): IVichRegisterGridComponentChangeEvent {
    return this.service.getChangeEvent()
  }


  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  /** Обработка события выбора типа журнала */
  public changeRegisterType(id: number, grid: GridComponent) {
    grid.closeRow();
    this.form = undefined;

    this.currentRegisterTypeId = id;
    this.settings.lastChosenRegisterType = id;

    this.filterTypeWorksForControlSource(id);

    this.service.addRow(id, grid);
    this.currentRegisterType$.emit(id);

    this.chDetector.detectChanges();
  }

  /** Отфильтровать список для контролла typeWorkAndMaterials */
  public filterTypeWorksForControlSource(registerTypeId: number) {
    switch (registerTypeId) {
      case 3://disinfection
        this.filteredTypeWorks = this.typesTypeWorkSource.filter(tw => tw.type == 1);
        break;
      case 4://disinfectionOOMO
        this.filteredTypeWorks = this.typesTypeWorkSource.filter(tw => tw.type == 2);
        break;
      default: this.filteredTypeWorks = this.typesTypeWorkSource;
    }
  }
}
