<kendo-grid [kendoGridBinding]="data()">
  <kendo-grid-column title="Дата"
                     [field]="xRowPath.route.modifiedDate.toString()"
                     format="HH:mm dd.MM.yyyy"
                     [width]="150"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Исполнитель"
                     [field]="xRowPath.modifiedUser.fio.toString()"
                     [width]="280"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Статус"
                     [width]="200"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.routeStatus?.name }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Комментарий"
                     class="!k-white-space-pre-wrap"
                     [field]="xRowPath.route.comment.toString()"
  >
  </kendo-grid-column>
</kendo-grid>
