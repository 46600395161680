import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {combineLatest, ReplaySubject} from "rxjs";
import {
  ArrayDataSourceSelection
} from "../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {
  GraphTableToolbarComponent_YearMonthService,
  IGraphTableToolbarComponent, IGraphTableToolbarComponent_StateChangeEventObj
} from "./i-graph-table-toolbar.component";
import {xnameofPath} from "../../../../../../../../src/app/functions/nameof";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {GraphTableToolbarServiceBase} from "./services/graph-table-toolbar.service";
import {exDistinctUntilChanged} from "../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import {exElementByIndexOr} from "../../../../../../../../src/app/operators/ex-element-by-index-or";
import {takeUntil} from "rxjs/operators";
import {
  DisplayErrorsService
} from "../../../../../../../../src/app/components/display-errors/services/display-errors.service";

@Component({
  selector: 'app-graph-table-toolbar2',
  templateUrl: './graph-table-toolbar2.component.html',
  styleUrl: './graph-table-toolbar2.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('GraphTableToolbar2Component')
/** Компонент выбора подразделение/год/месяц, для инициализации необходимого графика/табеля */
export class GraphTableToolbar2Component implements IGraphTableToolbarComponent, OnInit, OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  private xPatch = xnameofPath(GraphTableToolbar2Component);

  /** @inheritDoc */
  @Input() disabled: boolean = false;

  /** @inheritDoc */
  public subdivisionSelection: ArrayDataSourceSelection<typeof this._subdivisionDataSource['dataSource']['data'][0], number>;

  private _subdivisionDataSource: SubdivisionsTreelistComponentDataSourceServiceBase<Date>;
  /** @inheritDoc */
  @Input() get subdivisionDataSource() {
    return this._subdivisionDataSource;
  }
  public set subdivisionDataSource(value){
    if(this._subdivisionDataSource === value){
      return;
    }

    if(!!this._subdivisionDataSource){
      throw new Error('Повторная установка источника данных подразделений НЕ поддерживается');
    }

    this._subdivisionDataSource = value;
    this.subdivisionSelection = new ArrayDataSourceSelection(value.dataSource);
  }

  private _yearMonthService: GraphTableToolbarComponent_YearMonthService;
  @Input() public get yearMonthService(){
    return this._yearMonthService;
  }
  public set yearMonthService(value){
    if(this._yearMonthService === value){
      return;
    }

    if(!!this._yearMonthService){
      throw new Error('Повторная установка сервиса данных для Год/Месяц НЕ поддерживается');
    }

    this._yearMonthService = value;
  }

  /** @inheritDoc */
  @Output() public readonly change = new EventEmitter<IGraphTableToolbarComponent_StateChangeEventObj>();

  constructor(@Optional() private readonly service: GraphTableToolbarServiceBase,
              private readonly displayErrorsService: DisplayErrorsService,
              public readonly changeDetectorRef: ChangeDetectorRef,
              private readonly tracerService: TracerServiceBase) {
    if(!!service){
      service.onInit(this);
    }
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {
    if(!this.subdivisionDataSource){
      throw new Error(`${this.xPatch.subdivisionDataSource.toString()} не установлен`);
    }

    if(!this.yearMonthService){
      throw new Error(`${this.xPatch.yearMonthService.toString()} не установлен`);
    }

    //При смене Год/Месяц перезагрузка данных подразделений
    this.yearMonthService.selection.data$
      .pipe(
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
      this.subdivisionDataSource.reloadData(value.date, err => {
        this.displayErrorsService.handleError(err);
      });
    })

    //Обработка изменения данных в компонентах
    combineLatest([
      this.yearMonthService.selection.data$.pipe(exDistinctUntilChanged(undefined)),
      this.subdivisionSelection.selectedItems.data$.pipe(exElementByIndexOr(0, undefined), exDistinctUntilChanged(undefined))
    ]).pipe(
      exDistinctUntilChanged(undefined),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(value => {
      if(!value || !value[0] || !value[1]){
        this.change.emit(undefined);
      } else {
        this.change.emit({
          subdivisionOwnerId: value[1].id,
          yearId: value[0].id.yearId,
          monthId: value[0].id.monthId,
          date: value[0].date,
        })
      }
    })
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.subdivisionSelection?.onDestroy();
  }
}
