import {Injectable} from "@angular/core";
import {combineLatest, Observable} from "rxjs";
import {TuberLog} from "src/app/classes/domain/POCOs/timesheet/TuberLog";
import {IDropDownItem} from "src/app/classes/requestResults/iDropDownItem";
import {LoadingIndicatorService} from "src/app/services/loading-indicator.service";
import {
  Api1TuberControllerService,
  IAddTuberLogRequest,
  IEditTuberLogRequest
} from "src/app/services/webApi/webApi1/controllers/api1-tuber-controller.service";
import {map} from "rxjs/operators";

@Injectable()
export class TuberRegisterGridComponentDataService {
  constructor(private readonly api1TuberControllerService: Api1TuberControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService) { }

  private getTypesResearch$(): Observable<IDropDownItem[]> {
    return this.api1TuberControllerService.typesResearch_cached$
  }

  private getTypesService$(): Observable<IDropDownItem[]> {
    return this.api1TuberControllerService.typesService_cached$
  }

  private getTypesWork$(): Observable<IDropDownItem[]> {
    return this.api1TuberControllerService.typesWork_cached$
      .pipe(map(arr => arr
        .sort((a, b) => a.text.localeCompare(b.text))));
  }

  private getLogs$(staffUnitOwnerIds: number[], date: Date): Observable<TuberLog[]> {
    return this.api1TuberControllerService.getLogs$(staffUnitOwnerIds, date)
  }

  private getCanAddEditRemove$(date: Date): Observable<boolean> {
    return this.api1TuberControllerService.getCanAddEditRemove$(date)
  }

  public getCombineLatestData$(staffUnitOwnerIds: number[], date: Date):
    Observable<[typesResearches: IDropDownItem[], typesServices: IDropDownItem[], typesWorks: IDropDownItem[], logs: TuberLog[],
      canAddEditRemove: boolean]> {
    return combineLatest(
      [
        this.loadingIndicatorService.addToObservable(
          'Загрузка списка видов исследования',
          this.getTypesResearch$()
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка списка видов обслуживания',
          this.getTypesService$()
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка списка ТУБ видов работ и названия материалов',
          this.getTypesWork$()
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка ТУБ логов',
          this.getLogs$(staffUnitOwnerIds, date)
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка общей информации',
          this.getCanAddEditRemove$(date)
        )]
    );
  }

  public addLog$(model: IAddTuberLogRequest): Observable<TuberLog> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление случая работы с туберкулезом',
      this.api1TuberControllerService.addLog$(model)
    );
  }

  public editLog$(model: IEditTuberLogRequest): Observable<TuberLog> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение изменений случая работы с туберкулезом',
      this.api1TuberControllerService.editLog$(model)
    );
  }

  public removeLog$(id: number): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      'Удаление записи о случае работы с туберкулезом',
      this.api1TuberControllerService.removeLog$(id)
    );
  }
}
