import {
  ArrayDataSourceHasIdServiceWithParamsBase,
} from '../../../../../../../../../../../src/app/services/data-source-services/data-source.service';
import {
  DayDeviation,
  IDayDeviation
} from '../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation';
import {Observable, switchMap} from 'rxjs';
import {
  ArrayDataSource,
  ArrayDataSourceHasId,
  DataSource
} from 'src/app/classes/array-data-sources/data-source';
import { DbChangedListener } from '../../../../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { map } from 'rxjs/operators';
import { Api1DayDeviationControllerService } from '../../../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-dayDeviation-controller.service';
import { Injectable } from '@angular/core';
import {CellDataDirective} from "../../graph-grid2/graph-grid2.component";
import {StaffUnit} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {ArrayExpanded} from "../../../../../../../../../../../src/app/helpers/arrayHelper";
import {DayDeviationEnum} from "../../../../../../../../../../../src/app/classes/domain/enums/day-deviation.enum";
import {StaffUnitTypeEnum} from "../../../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";

/** Тип элемента */
type ItemType = {
  readonly dayDeviation: IDayDeviation,
  readonly disabled: boolean,
}

/** Тип параметров */
type ParamsType1 = {}

/** Базовый класс сервиса источника данных */
@Injectable()
export abstract class DayDeviationPanel2ComponentDataSourceServiceBase<TParams> extends ArrayDataSourceHasIdServiceWithParamsBase<TParams, ItemType, IDayDeviation['id']> {
}

/** Сервис источника данных */
@Injectable()
export class DayDeviationPanel2ComponentDataSourceService extends DayDeviationPanel2ComponentDataSourceServiceBase<ParamsType1> {

  /** @inheritDoc */
  public readonly paramsDataSource = new DataSource<ParamsType1>();
  /** @inheritDoc */
  public readonly dataSource = new ArrayDataSourceHasId<ItemType, IDayDeviation['id']>(x => x.dayDeviation.id);
  /** Источник данных выделенных ячеек */
  private readonly selectedCellsDataSource = new ArrayDataSource<CellDataDirective>();

  constructor(private readonly dbChangedListener: DbChangedListener,
              private readonly api1DayDeviationControllerService: Api1DayDeviationControllerService,) {
    super();

    //Обработка изменения выделенных ячеек
    this.selectedCellsDataSource.change$
      .pipe(
        switchMap(selectedCells => {
          const items = this.convert(
            this.dataSource.data.map(x => x.dayDeviation),
            selectedCells
          );

          return this.setData$(items);
        })
      ).subscribe();
  }

  /** @inheritDoc */
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
      dayDeviations: DayDeviation,
    }).pipe(
      map(value => value.data),
      map(value => ([
        ...value.dayDeviations
          .map(s=> s.currentOrOrigin.id),
      ])),
      map(() => this.reloadData$(this.paramsDataSource.data)),
    )
  }

  /** Установить выделенные ячейки */
  public setSelectedCells(cells: CellDataDirective[]){
    this.selectedCellsDataSource.setData(cells);
  }

  /** @inheritDoc */
  protected _reloadFromRemoteByIds$(params: ParamsType1, targets: number[]): Observable<ItemType[]> {
    throw new Error('Перезагрузка не поддерживается');
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamsType1): Observable<ItemType[]> {
    return this.api1DayDeviationControllerService.getAllDayDeviations$()
      .pipe(
        map(dayDeviations => this.convert(dayDeviations, this.selectedCellsDataSource.data))
      );
  }

  /** Конвертация */
  private convert(items: IDayDeviation[], selectedCells: CellDataDirective[]): ItemType[] {
    if(!selectedCells || selectedCells.length === 0){ //Если нет выделенных ячеек
      return items.map(x => createItem(x, true));
    }

    const rows = new ArrayExpanded(selectedCells)
      .map(x => x.row)
      .distinct()
      .array;

    if(rows.some(x => StaffUnit.isDuty(x.staffUnit.typeId))){ //Если содержится дежурство
      return items.map(x => createItem(x, true));
    }

    /** У какой-то выделеной ячейки ExecutionDutiesFlag равен true */
    const someHasExecutionDutiesFlag = rows.some(x => x.staffUnit.executionDutiesFlag);
    /** Выделено ли несколько строк в Графике (с учетом виртуальных строк) */
    const multipleRowsSelected = rows.length > 1;
    /** Все ли выделенные ячейки содержат временной интервал */
    const allSelectedCellsHaseTimeInterval = selectedCells.every(x => !!x.dayCell.cell.graphDayCurrent.timeInterval);

    const result: ItemType[] = items.map(dd => {
      let disabled = false;

      if(dd.deviationWithInterval && (multipleRowsSelected || (!allSelectedCellsHaseTimeInterval && dd.isOperatingFlag))){
        disabled = true;
      } else if(dd.id === DayDeviationEnum.ПК){ //Если ПК
        if(multipleRowsSelected){
          disabled = true;
        } else if(rows.some(x => !StaffUnit.isBasic(x.staffUnit.typeId))){ //Только если основная ставка
          disabled = true;
        }
      } else if(dd.id === DayDeviationEnum.ИО){ //Если ИО
        if(multipleRowsSelected){
          disabled = true;
        } else if(someHasExecutionDutiesFlag){
          disabled = true;
        } else {
          const row = rows[0];

          if(StaffUnit.isBasic(row.staffUnit.typeId)){
            //НЕ отключаем
          } else if(!!row.staffUnit.parentId) { //Прокси
            disabled = true;
          } else if(row.staffUnit.typeId !== StaffUnitTypeEnum.MoonlighterInner && row.staffUnit.typeId !== StaffUnitTypeEnum.CombinationInner){
            disabled = true;
          } else if(StaffUnit.inMonth(row.staffUnit.startDate, row.staffUnit.endDate)){
            disabled = true;
          }
        }
      }

      return createItem(dd, disabled);
    });

    return result;

    /** Создать элемент */
    function createItem(dd: IDayDeviation, disabled: boolean): ItemType{
      return {
        dayDeviation: dd,
        disabled: disabled,
      }
    }
  }

  /** @inheritDoc */
  public ngOnDestroy() {
    super.ngOnDestroy();
    this.selectedCellsDataSource.onDestroy();
  }
}
