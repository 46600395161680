import {Injectable, OnDestroy} from '@angular/core';
import {CorrectionListComponent} from "../correction-list.component";
import {delay, take, takeUntil} from "rxjs/operators";
import {race, ReplaySubject} from "rxjs";
import {DialogService} from "@progress/kendo-angular-dialog";
import {SheetCorrectionType} from "../i-correction-list-component";

@Injectable()
export class CorrectionListDialogService implements OnDestroy {

  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly dialogService: DialogService) { }

  /** Открыть диалог */
  public showDialog(subdivisionId: number, month: Date, type: SheetCorrectionType, text: string){
    const dialogRef = this.dialogService.open({
      title: "Список корректировок",
      content: CorrectionListComponent,
      height: '480px',
      width: '500px'
    });
    const component = dialogRef.content.instance as CorrectionListComponent;
    component.subdivisionId = subdivisionId;
    component.month = month;
    component.text = text;
    component.type = type;

    const unsubscribeRice = race(this.unsubscribe$, component.cancel$, component.select$)
      .pipe(delay(1));
    component.cancel$.pipe(take(1), takeUntil(unsubscribeRice))
      .subscribe(value => dialogRef.close());

    component.select$.pipe(take(1), takeUntil(unsubscribeRice))
      .subscribe(value => {
        dialogRef.close();
      });
  }

  ngOnDestroy(){
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
