import {Injectable, OnDestroy, Output} from "@angular/core";
import {race, ReplaySubject, Subject} from "rxjs";
import {
  EditDutyComponent,
  EditDutyComponent_SaveEvent,
  EditDutyComponent_Settings
} from "../../edit-duty/edit-duty.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {delay, take, takeUntil} from "rxjs/operators";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {TraceParamEnum} from "../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../src/app/modules/trace/decorators/param.decorator";
import {TracerServiceBase} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Injectable({providedIn: 'root'})
@traceClass('EditDutyService')
export class EditDutyService implements OnDestroy {

  /** Событие добавления/редактирования дежурства */
  @Output() save$: Subject<EditDutyComponent_SaveEvent> = new Subject<EditDutyComponent_SaveEvent>();

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private dialogService: DialogService,
              private readonly tracerService: TracerServiceBase) { }

  /** Открыть диалог */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public open(@traceParam({maxLength: 1000}) settings: EditDutyComponent_Settings){
    const dialogRef = this.dialogService.open({
      title: `${settings.type == 'add' ? 'Добавление' : 'Редактирование'} дежурства`,
      content: EditDutyComponent,
      height: '500px',
      width: '500px'
    });
    const editDutyComponent = dialogRef.content.instance as EditDutyComponent;

    editDutyComponent.settings = settings;

    const unsubscribeRice = race(this.streams$.unsubscribe, editDutyComponent.cancel$, editDutyComponent.save$)
      .pipe(delay(1));
    editDutyComponent.cancel$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      dialogRef.close();
    });

    editDutyComponent.save$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      dialogRef.close();
      this.save$.next(value);
    });
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
