import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ContentChild,
  ElementRef, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output, TemplateRef,
  ViewChild,
} from '@angular/core';
import {fromEvent, merge, Observable, race, ReplaySubject, share, startWith, switchMap} from "rxjs";
import {
  GraphDataSourceService
} from "../../services/data-sources/graph-data-sources/graph-data-source.service";
import {
  TracerServiceBase
} from "../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {xnameofPath} from "../../../../../../../../../../src/app/functions/nameof";
import {DayTypeEnumObj} from "../../../../../../../../../../src/app/classes/domain/enums/day-type-enum";
import {filter, map, take, takeUntil, tap} from "rxjs/operators";
import {PopupRef, PopupService} from "@progress/kendo-angular-popup";
import {fromMouseleaveEvent} from "../../../../../../../../../../src/app/functions/observable-functions";
import {SelectedGridCellTargetDirective} from "../../../../../../../../../../src/app/directives/grid-cell-selection/selected-grid-cell-target.directive";
import {DateInStaffUnitRangeType} from "../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {
  SelectedGridCellDirective, SelectedGridCellDirectiveReadOnly
} from "../../../../../../../../../../src/app/directives/grid-cell-selection/selected-grid-cell.directive";
import {ObjComparer} from "../../../../../../../../../../src/app/classes/object-comparers/object-comparer";
import {
  GraphGridDataSource_DataItem
} from "../../services/data-sources/graph-grid-data-sources/graph-grid-data-source.classes";
import {
  GraphGrid_GraphDayCell
} from "../../services/data-sources/graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.classes";
import {exDistinctUntilChanged} from "../../../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../../src/app/helpers/arrayHelper";
import {SortDescriptor} from "@progress/kendo-data-query";
import {AppSettingsService} from "../../../../../../../../../../src/app/services/app-settings.service";
import {MultipleSortSettings} from "@progress/kendo-angular-grid/columns/sort-settings";
import {GraphGridToolbarTemplateDirective} from "./directives/graph-grid-toolbar-template.directive";

/** Тип строки источника данных для юи */
type UiDataSourceItemType = GraphDataSourceService['gridDataSource']['data']['rows'][0];

/** Данные директивы относящиеся к дням */
class DayCellDataDirective {
  /**
   * Конструктор
   * @param type тип ячейки по отношению к исполнению должности. {@link DateInStaffUnitRangeType}
   * @param cell - ячейка
   */
  constructor(public readonly type: DateInStaffUnitRangeType,
              public readonly cell: GraphGrid_GraphDayCell) {
  }

  private static _comparer: ObjComparer<DayCellDataDirective>;
  /** Сравнить по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = new ObjComparer<DayCellDataDirective>({
        type: true,
        cell: true, //Необходимо сравнивать по ссылке. НЕ допускается сравнение по значению
      })
    }

    return this._comparer;
  }
}

/**
 * Тип колонки<br>
 * occupation - Должность<br>
 * fio - ФИО<br>
 * rate - Ставка<br>
 * norma - Норма<br>
 * fact - Факт<br>
 * delta - Дельта<br>
 * day - любая ячейка относящаяся к дню месяца<br>
 */
type CellTypeType = 'occupation' | 'fio' | 'rate' | 'norm' | 'fact' | 'delta' | 'day';

/** Тип данных хранящихся в {@link SelectedGridCellDirectiveReadOnly}.{@link SelectedGridCellDirectiveReadOnly.data} */
export class CellDataDirective {
  constructor(public readonly row: GraphGridDataSource_DataItem,
              public readonly cellType: CellTypeType,
              public readonly dayCell: DayCellDataDirective) {
  }

  private static _comparer: ObjComparer<CellDataDirective>;
  /** Сравнивать по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = new ObjComparer<CellDataDirective>({
        row: true, //Сравниваем по ссылке. НЕ допускается сравнение по значению
        cellType: true,
        dayCell: DayCellDataDirective.comparer.asPropertyCompareFunc(false),
      })
    }

    return this._comparer;
  }
}

/** Тип для события содержащий стрим НЕ актуальности данных */
type EventHasExpiredType = {
  /**
   * Событие происходит, когда текущее событие является уже НЕ актуальным.<br>
   * Событие транслируется один раз, и завершается.<br>
   * @example Используй для закрытия контекста
   */
  readonly expired$: Observable<void>;
}

/** Тип события двойного клика по ячейке дней */
type DayDoubleCellClickEventType = {
  /** Html элемент ячейки на котором произошло событие */
  readonly htmlElement: HTMLElement,
  /** Ячейка на которой произошло событие */
  readonly cell: CellDataDirective
} & EventHasExpiredType;

/** Тип события контекст меню по ячейке дня */
type DayContextMenuEventType = {
  /** Html элемент ячейки на котором произошло событие */
  readonly htmlElement: HTMLElement,
  /** Функция получения всех выделенных ячеек */
  readonly cells: CellDataDirective[]
} & EventHasExpiredType;

/** Тип события двойного клика по ячейке строки */
type RowDoubleCellClickEventType =  DayDoubleCellClickEventType;
/** Тип события контекст меню по ячейке строки */
type RowContextMenuEventType = DayContextMenuEventType;

/** Тип описателя сортировки по умолчанию */
type DefaultSortDescriptor = SortDescriptor & {
  /** Является ли полем, сортировку которого может изменять пользователь */
  isUseful: boolean
}

/**
 * Компонент таблицы График.<br>
 * Для передачи шаблона шапки таблицы используй {@link GraphGridToolbarTemplateDirective} и передай во внутренний html.<br>
 */
@Component({
  selector: 'app-graph-grid2',
  templateUrl: './graph-grid2.component.html',
  styleUrls: [
    './graph-grid2.component.css',
    '../../../../../../../../../../src/app/css/appSelectedGridCell1.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@traceClass('GraphGrid2Component')
export class GraphGrid2Component implements OnInit, AfterViewInit , OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** для html */
  public readonly xPath = xnameofPath(this.asRow(undefined),'.', false);

  private _dataSource: GraphDataSourceService;
  /** Источник данных */
  public get dataSource(){
    return this._dataSource;
  }
  @Input() public set dataSource(value){
    if(this._dataSource){
      throw new Error('Повторная установка источника данных не допускается');
    }
    this._dataSource = value;
  }

  /** Событие изменения выделения ячеек. Массив будет содержать любую ячейку. */
  @Output() public readonly cellSelectedChange = new EventEmitter<CellDataDirective[]>();

  /** Событие изменения выделенных дней */
  @Output() public readonly dayCellSelectionChange = new EventEmitter<CellDataDirective[]>();

  /** Событие контекстного меню на ячейке дней */
  @Output() public readonly dayContextMenu = new EventEmitter<DayContextMenuEventType>();

  /** Событие двойного клика по ячейке дня */
  @Output() public readonly dayCellDoubleClick = new EventEmitter<DayDoubleCellClickEventType>();

  /** Событие изменения выделенных ячеек НЕ относящихся ко дням графика */
  @Output() public readonly rowCellSelectionChange = new EventEmitter<CellDataDirective[]>();

  /** Событие двойного клика по ячейке строки */
  @Output() public readonly rowCellDoubleClick = new EventEmitter<RowDoubleCellClickEventType>();

  /** Событие контекстного меню на ячейке строки */
  @Output() public readonly rowContextMenu = new EventEmitter<RowContextMenuEventType>();

  /** Шаблон контента для Popup иконки исполнения должности */
  @ViewChild('staffUnitImgPopupContentTemplate') protected staffUnitImgPopupContentTemplate: TemplateRef<any>;

  /**
   * Директива выделенных ячеек.
   * НЕ ИСПОЛЬЗУЮ ПОДПИСЬ НА СОБЫТИЯ ИЗ HTML ПО ПРИЧИНЕ УХУДШЕНИЯ ПРОИЗВОДИТЕЛЬНОСТИ
   */
  @ViewChild(SelectedGridCellTargetDirective, {static: true}) protected selectedGridCellTargetDirective: SelectedGridCellTargetDirective;

  /** Стрим строки для отображения popup при наведении на иконку исполнения должности */
  protected staffUnitImgRow$ = new ReplaySubject<UiDataSourceItemType>(1) ;

  /** Настройка сортировки */
  protected sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    showIndexes: true,
  }


  private _sort: SortDescriptor[];
  /** Сортировка таблицы */
  protected get sort(){
    return this._sort;
  }
  protected set sort(sort){
    this._sort = new ArrayExpanded(sort)
      .leftInnerJoinGroupedRight(
        this.getGridDefaultSortSettings(this.appSettingsService.company),
        x => x.field,
        x => x.field,
        (left, rights) => {
          const result: DefaultSortDescriptor = {
            ...left,
            isUseful: ArrayHelper.single(rights).isUseful
          }

          return result;
        })
      .array
      .sort((a, b) => +b.isUseful - +a.isUseful);
  }

  /** Шаблон шапки таблицы графика, помеченный директивой {@link } GraphGridToolbarTemplateDirective */
  @ContentChild(GraphGridToolbarTemplateDirective) graphGridToolbarTemplateDirective: GraphGridToolbarTemplateDirective;

  /** Конструктор */
  constructor(private readonly el: ElementRef,
              private readonly popupService: PopupService,
              private readonly appSettingsService: AppSettingsService,
              private readonly tracerService: TracerServiceBase,) {
    this._sort = this.getGridDefaultSortSettings(appSettingsService.company);
  }

  @traceFunc()
  /** @inheritdoc */
  public ngOnInit(): void {
    if(!this.selectedGridCellTargetDirective){
      throw new Error('Отсутствует директива SelectedGridCellTargetDirective');
    }

    this.onSelectionChangeInDirective();
    this.onDaySelectionChangeInDirective();
    this.onDoubleClickInDirective();
    this.onDayCellContextMenuInDirective();
    this.onRowCellSelectionChangeInDirective();
    this.onNotDayCellContextMenuInDirective();
  }

  @traceFunc()
  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.onStaffUnitImgMouseOverSubscribe();
  }

  /** Типизировать объект в тип строки */
  protected asRow(row: any): UiDataSourceItemType{
    return row;
  }

  /**
   * Получить идентификатор для ячейки
   * @param row строка
   * @param columnIndex индекс колонки
   * @return [идентификатор строки]/[индекс колонки]/[идентификатор редакции]
   */
  protected getCellId(row: UiDataSourceItemType, columnIndex: number): string{
    return `${row.id.uid}/${columnIndex}/${this.dataSource.gridDataSource.data.redactionId}`;
  }

  /** Получить данные для заполнения {@link SelectedGridCellDirective.data} */
  protected getCellData(cellType: CellTypeType, row: GraphGridDataSource_DataItem, dayCell: DayCellDataDirective | undefined): CellDataDirective {
    dayCell = cellType === 'day' ? new DayCellDataDirective(dayCell.type, dayCell.cell) : undefined;
    return new CellDataDirective(row, cellType, dayCell);
  }

  /** Сравнить данные {@link SelectedGridCellDirective.data} */
  protected compareCellData(first: CellDataDirective, second: CellDataDirective): boolean{
    return CellDataDirective.comparer.compare(first, second);
  }

  @traceFunc()
  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.staffUnitImgRow$.complete();
  }

  /** Используется в html для сравнения */
  protected readonly dayTypeEnum = DayTypeEnumObj;

  @traceFunc()
  /** Подпись и обработка при наведении на иконку исполнения должности  */
  private onStaffUnitImgMouseOverSubscribe(){
    const mouseover$ = fromEvent(this.el.nativeElement, 'mouseover')
      .pipe(
        map((event: MouseEvent) => {
          const target = event.target as HTMLElement;
          const uid = target.getAttribute('staffUnitImg');

          return {
            target: target,
            item: this.dataSource?.gridDataSource?.data?.rows?.find(x => x.id.uid === uid),
          };
        }),
        filter(value => !!value.item),
        share()
      );

    let popupRef: PopupRef;
    mouseover$
      .pipe(
        tap(value => {
          popupRef?.close();
          this.staffUnitImgRow$.next(value.item);
          popupRef = this.popupService.open({anchor: value.target, content: this.staffUnitImgPopupContentTemplate});
        }),
        switchMap(value => {
          return merge(
            mouseover$,
            fromMouseleaveEvent(value.target, popupRef.popupElement),
            this.dataSource.gridDataSource.change$,
          ).pipe(
            take(1),
            tap(() => {
              popupRef?.close();
              popupRef = undefined;
              this.staffUnitImgRow$.next(undefined);
            })
          )
        }),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe()
  }

  /** Обработка изменения выделения в директиве */
  @traceFunc()
  private onSelectionChangeInDirective(){
    this.selectedGridCellTargetDirective.selectedChangeExpanded
      .pipe(
        map(value => {
          const cellDataDirectives = value.selectedItems
            .map(x => x.data as CellDataDirective);

          return cellDataDirectives;
        })
      ).subscribe(value => this.cellSelectedChange.next(value));
  }

  /** Обработка изменения выделения дней в директиве */
  @traceFunc()
  private onDaySelectionChangeInDirective(){
    this.cellSelectedChange
      .pipe(
        map(value => {
          if(value.some(x => x.cellType !== 'day')){ //Если есть хоть одна ячейка НЕ относящаяся ко дню
            return new Array<CellDataDirective>(0);
          }

          return value.filter(x => x.dayCell.type === 'inRange')
        }),
        exDistinctUntilChanged(new Array<CellDataDirective>(0), ArrayHelper.equals2)
      ).subscribe(value => {
      this.dayCellSelectionChange.next(value);
    });
  }

  /** Обработка события двойного клика по ячейке в директиве */
  @traceFunc()
  private onDoubleClickInDirective(){
    this.selectedGridCellTargetDirective.doubleCellClick
      .pipe(
        tap(value => {
          value.event.preventDefault();
          value.event.stopPropagation();
        }),
      )
      .subscribe(value => {
        const cell = value.cell.data as CellDataDirective;
        const htmlElement = value.event.target as HTMLElement;
        const expired$ = race(this.selectedGridCellTargetDirective.selectedChangeExpanded, this.selectedGridCellTargetDirective.contextMenu)
          .pipe(
            map(() => {}),
            take(1), //Только первая трансляция
          );

        if(cell.cellType === 'day'){
          if(cell.dayCell.type === 'inRange'){
            const event: DayDoubleCellClickEventType = {
              htmlElement: htmlElement,
              cell: cell,
              expired$: expired$,
            }

            this.dayCellDoubleClick.next(event);
          }
        } else {
          const event: RowDoubleCellClickEventType = {
            htmlElement: htmlElement,
            cell: cell,
            expired$: expired$
          }

          this.rowCellDoubleClick.next(event);
        }
      });
  }

  /** Обработка события контекстного меню ячейки дня в директиве */
  @traceFunc()
  private onDayCellContextMenuInDirective(){
    this.dayCellSelectionChange
      .pipe(
        startWith(new Array<CellDataDirective>(0)),
        switchMap(selectedCells => {
          return this.selectedGridCellTargetDirective.contextMenu
            .pipe(
              tap(value => {
                value.event.preventDefault();
                value.event.stopPropagation();
              }),
              map(contextEvent => {
                return {
                  selectedCells: selectedCells,
                  contextEvent: contextEvent
                }
              })
            )
        }),
      )
      .subscribe(value => {
        const cell = value.contextEvent.cell.data as CellDataDirective;
        if(cell.cellType !== 'day'){ //Если контекст не на ячейке дня
          return;
        }

        const expired$ = race(this.selectedGridCellTargetDirective.selectedChangeExpanded, this.selectedGridCellTargetDirective.contextMenu)
          .pipe(
            map(() => {}),
            take(1),//Только первая трансляция
          )

        if(!value.selectedCells.some(x => x.dayCell.cell.id === (value.contextEvent.cell.data as CellDataDirective).dayCell.cell.id)){ //Если контекст НЕ на выделенных ячеек
          return;
        }

        this.dayContextMenu.next({
          htmlElement: value.contextEvent.event.target as HTMLElement,
          cells: value.selectedCells,
          expired$: expired$,
        })
      })
  }

  /** Обработка события изменения выделения ячеек относящихся НЕ ко дням графика */
  @traceFunc()
  private onRowCellSelectionChangeInDirective(){
    this.cellSelectedChange
      .pipe(
        map(value => {
          if(value.some(x => x.cellType === 'day')){ //Если есть хоть одна ячейка относящаяся ко дню
            return new Array<CellDataDirective>(0);
          }

          return value;
        }),
        exDistinctUntilChanged(new Array<CellDataDirective>(0), ArrayHelper.equals2)
      ).subscribe(value => {
      this.rowCellSelectionChange.next(value);
    });
  }

  /** Обработка события контекстного меню ячейки НЕ относящихся ко дням в директиве */
  @traceFunc()
  private onNotDayCellContextMenuInDirective(){
    this.rowCellSelectionChange
      .pipe(
        startWith(new Array<CellDataDirective>(0)),
        switchMap(selectedCells => {
          return this.selectedGridCellTargetDirective.contextMenu
            .pipe(
              tap(value => {
                value.event.preventDefault();
                value.event.stopPropagation();
              }),
              map(contextEvent => {
                return {
                  selectedCells: selectedCells,
                  contextEvent: contextEvent
                }
              })
            )
        }),
      )
      .subscribe(value => {
        const cell = value.contextEvent.cell.data as CellDataDirective;
        if(cell.cellType === 'day'){ //Если контекст на ячейке дня
          return;
        }

        const expired$ = race(this.selectedGridCellTargetDirective.selectedChangeExpanded, this.selectedGridCellTargetDirective.contextMenu)
          .pipe(
            map(() => {}),
            take(1),//Только первая трансляция
          );

        if(!value.selectedCells.some(x => x === value.contextEvent.cell.data)){
          return;
        }

        this.rowContextMenu.next({
          htmlElement: value.contextEvent.event.target as HTMLElement,
          cells: value.selectedCells,
          expired$: expired$,
        })
      });
  }

  /** Получить первоначальные настройки сортировки графика */
  private getGridDefaultSortSettings(company: string): Array<DefaultSortDescriptor>{
    const factory = (dir: 'asc' | 'desc', field: string, isUseful: boolean): DefaultSortDescriptor => {
      return {
        dir: dir,
        field: field,
        isUseful: isUseful
      }
    }

    if(company.includes('ikb2_registry') || company.includes('gvv2_registry')){   // Сперва сортирует по должности потом по ФИО
      return [
        factory('asc', this.xPath.occupation.name.toString(), true),
        factory('asc', this.xPath.fio.toString(), true),
        factory('asc', this.xPath.isProxy.toString(), false),
        factory('asc', this.xPath.staffUnit.typeId.toString(), false),
        factory('desc', this.xPath.staffUnit.rate.toString(), false),
        factory('asc', this.xPath.id.uid.toString(), false), //Должно быть последнее. Иначе при редактировании происходит смещение строк
        //factory('asc', 'staffUnit.ownerId', false), //Для режима сравнения двух редакций
        //factory('desc', 'compareState', false), //Для режима сравнения двух редакций
      ];
    } else {    // Сперва сортирует по ФИО потом по должности
      return [
        factory('asc', this.xPath.fio.toString(), true),
        factory('asc', this.xPath.occupation.name.toString(), true),
        factory('asc', this.xPath.isProxy.toString(), false),
        factory('asc', this.xPath.staffUnit.typeId.toString(), false),
        factory('desc', this.xPath.staffUnit.rate.toString(), false),
        factory('asc', this.xPath.id.uid.toString(), false), //Должно быть последнее. Иначе при редактировании происходит смещение строк
        //factory('asc', 'staffUnit.ownerId', false), //Для режима сравнения двух редакций
        //factory('desc', 'compareState', false), //Для режима сравнения двух редакций
      ];
    }
  }
}
