import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {AsObservableObj, IAsObservable} from "../../classes/as-observable/as-observable-obj";
import {InputSize} from "@progress/kendo-angular-inputs/common/models/size";
import {traceClass} from "../../modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-textbox-select',
  templateUrl: './textbox-select.component.html',
  styleUrls: ['./textbox-select.component.css']
})
@traceClass('TextboxSelectComponent')
export class TextboxSelectComponent implements IAsObservable<TextboxSelectComponent>, OnDestroy {

  private _disabled: boolean = false;
  /** Отключен ли компонент */
  @Input() public get disabled(){
    return this._disabled;
  }
  public set disabled(value){
    if(this._disabled === value){
      return;
    }

    this._disabled = value;
    this.asObservable.onChange();
  }


  private _text: string = "";
  /** Текст в textbox */
  @Input() public get text(){
    return this._text;
  }
  public set text(value){
    if(this._text === value){
      return;
    }

    this._text = value;
    this.asObservable.onChange();
  }

  private _size: InputSize = null;
  /** Размер */
  @Input() public get size(){
    return this._size;
  }
  public set size(value){
    if(this._size === value){
      return;
    }

    this._size = value;
    this.asObservable.onChange();
  }

  /** Событие нажатия на кнопку Изменить */
  @Output() click$: EventEmitter<void> = new EventEmitter<void>();

  public readonly asObservable: AsObservableObj<TextboxSelectComponent> = new AsObservableObj<TextboxSelectComponent>(() => this, true);

  constructor(private readonly traceService: TracerServiceBase) {

  }

  /** Обработка нажатия на кнопку Выбор */
  @traceFunc()
  public onButtonClick(){
    this.click$.emit();
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.asObservable.complete();
  }
}
