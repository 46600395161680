import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { PatientMovementsGridComponentDataSourceServiceBase } from './patient-movements-grid-component-data-source.service';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { AlertService } from 'src/app/services/alert.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { PatientMovementAdditionallyInfosGridComponent } from '../../additionally-infos-grid/patient-movement-additionally-infos-grid.component';
import { PatientMovementAdditionallyInfosGridDataSourceService } from '../../additionally-infos-grid/services/patient-movement-additionally-infos-grid-data-source.service';

@traceClass('PatientMovementsGridComponentService')
@Injectable()
export class PatientMovementsGridComponentService implements OnDestroy {

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly traceService: TracerServiceBase,
              private readonly alertService: AlertService,
              private readonly dialogService: DialogService,
              private readonly patientMovementAdditionallyInfosGridDataSourceService: PatientMovementAdditionallyInfosGridDataSourceService) {
  }

  public showAdditionallyInfosDialog(dataService: PatientMovementsGridComponentDataSourceServiceBase, patientMovementId: number, forDate: Date, typeId: number) {
    const dialogRef = this.dialogService.open({
      title: 'Дополнительная информация раздела "Движение пациентов"',
      content: PatientMovementAdditionallyInfosGridComponent,
      width: '90%',
      maxHeight: '90%',
    });

    const instance = dialogRef.content.instance as PatientMovementAdditionallyInfosGridComponent;

    instance.dataSourceService = this.patientMovementAdditionallyInfosGridDataSourceService;
    instance.currentSubdivisionId = dataService.currentSubdivisionId;
    instance.forDate = forDate;
    instance.typeId = typeId;

    this.patientMovementAdditionallyInfosGridDataSourceService.reloadData$({
        patientMovementId,
        typeId,
        includeDeleted: false,
      })
      .pipe(
        takeUntil(this.streams$.unsubscribes),
        takeUntil(dialogRef.result),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
