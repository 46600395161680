<div kendoTooltip style="display: flex;">
  <button *ngFor="let item of data"
          kendoButton
          [disabled]="disabled"
          [primary]="item == selected"
          [title]="item.disabled ? 'В этот день Вы не работаете' : ''"
          [ngStyle]="{
            width: '26px',
            marginLeft: item == selected ? '3px' : '1px',
            marginRight: item == selected ? '3px' : '1px',
            boxShadow: item == selected ? '0 0 10px rgba(0,0,0,0.3)' : undefined,
            color: item.disabled ? 'red' : undefined,
            cursor: item.disabled ? 'default' : 'pointer'
            }"
          style="padding-left: 1px; padding-right: 1px; padding-top: 1px;"
          (click)="onButtonClick(item)"
  >
    <div style="display: flex; flex-direction: column; justify-content: center; width: 100%">
      <div style="width: 100%; height: 3px; margin-bottom: 1px;"
           [ngStyle]="{
           background: item.disabled || !item.hasCovid ? '' : '#77c1d2a8'
           }"
      ></div>
      <span>{{item.id}}</span>
    </div>
  </button>
</div>
