import { Component, OnInit } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Api1GraphTableApprovalsControlControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-approvals-control-controller.service';
import { Api1SubdivisionsTreelistControlControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service';
import { ApprovalRouteStepComponent_DataItem, IApprovalRouteStepComponent_Settings, IApprovalRouteStepComponent_Subdivision } from '../approval-route/route-step/approval-route-step.component';
import * as moment from "moment";

@Component({
  selector: 'app-administration-table-approval-route',
  templateUrl: './table-approval-route.component.html',
  styleUrls: ['./table-approval-route.component.css']
})
export class AdministrationTableApprovalRouteComponent implements OnInit {

  public settings: IApprovalRouteStepComponent_Settings;
  public title: string = 'Маршрут согласования табеля';

  constructor(private api1GraphTableApprovalsControlControllerService: Api1GraphTableApprovalsControlControllerService, private subdivisionsTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService )
  {
    const allUsers = api1GraphTableApprovalsControlControllerService.getAllApprovalUsers$().pipe(shareReplay(1));
    const date = new Date();
    const allSubdivisions = subdivisionsTreelistControlControllerService.getForDate$(date, moment(date).add(-2, 'month').toDate(), null).pipe(map(m=> m.map(v=> <IApprovalRouteStepComponent_Subdivision>{id: v.id, longName: v.text, parentId: v.parentId})),shareReplay(1));
    this.settings = {
      allUsers$: allUsers,
      allSubdivisions$: allSubdivisions,
      getApprovalsForSubdivisions$Func: (subdivisionIds: number[]) => api1GraphTableApprovalsControlControllerService.getTableApprovals$(new Date(), subdivisionIds),
      addApprovals$Func: (subdivisionIds: number[], dataItem: ApprovalRouteStepComponent_DataItem, isDeleteExisted: boolean) => api1GraphTableApprovalsControlControllerService.addTableApprovals$(subdivisionIds, { approvalSubdivisionId: dataItem.approvalSubdivision?.id, approvalUserId: dataItem.approvalEmployee?.userId}, isDeleteExisted),
      deleteApprovals$Func: (subdivisionIds: number[], dataItem: ApprovalRouteStepComponent_DataItem) => api1GraphTableApprovalsControlControllerService.deleteTableApprovals$(subdivisionIds, { approvalSubdivisionId: dataItem.approvalSubdivision?.id, approvalUserId: dataItem.approvalEmployee?.userId}),
      reorderApprovals$Func: (subdivisionIds: number[], approval1: ApprovalRouteStepComponent_DataItem, approval2: ApprovalRouteStepComponent_DataItem) => api1GraphTableApprovalsControlControllerService.reorderTableApproval$(subdivisionIds, { approvalSubdivisionId: approval1.approvalSubdivision?.id, approvalUserId: approval1.approvalEmployee?.userId}, { approvalSubdivisionId: approval2.approvalSubdivision?.id, approvalUserId: approval2.approvalEmployee?.userId})
    }
  }

  ngOnInit(): void { }
}
