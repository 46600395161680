/** enum для типа дня */
export enum DayTypeEnum{
  /** Рабочий день */
  BusinessDay = 0,

  /** Выходной день */
  Weekend = 1,

  /** Праздничный день */
  Holiday = 2,

  /** Предпраздничный день */
  PreHoliday = 3,

  /** Нерабочий день (переносы и утвержденные законодательством дополнительные выходные дни) */
  NonWorkingDay = 4,
}

const _dayTypeEnumObj = {
  BusinessDay: DayTypeEnum.BusinessDay,
  Weekend: DayTypeEnum.Weekend,
  Holiday: DayTypeEnum.Holiday,
  PreHoliday: DayTypeEnum.PreHoliday,
  NonWorkingDay: DayTypeEnum.NonWorkingDay,
};

/**
 * {@link DayTypeEnum} как объект<br>
 * Использовать к примеру в сравнении в html
 */
export const DayTypeEnumObj: Readonly<typeof _dayTypeEnumObj> = _dayTypeEnumObj;
