import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  MonitoringToolbarComponentEvent,
  MonitoringToolbarComponentTypeEnum
} from "./monitoring-toolbar/monitoring-toolbar.component";
import {MonitoringGridComponentInput} from "./monitoring-grid/monitoring-grid.component";
import {traceClass} from "../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})
@traceClass('MonitoringComponent')
export class MonitoringComponent implements OnInit, OnDestroy {

  constructor(private readonly tracerService: TracerServiceBase) {

  }

  /** Настройки */
  public gridInput: MonitoringGridComponentInput = null;

  @traceFunc()
  ngOnInit(): void {
  }

  /**
   * Обработка изменения состояния toolbar
   * @param $event
   */
  @traceFunc()
  onToolbarChange($event: MonitoringToolbarComponentEvent) {
    this.gridInput = null;
    if(!$event){
      return;
    }

    setTimeout(() => { //Для удаления таблицы
      switch ($event.type){
        case MonitoringToolbarComponentTypeEnum.Graph:
          this.gridInput = new MonitoringGridComponentInput('graph', $event.date)
          break;
        case MonitoringToolbarComponentTypeEnum.Table:
          this.gridInput = new MonitoringGridComponentInput('table', $event.date)
          break;
        default: throw new Error('Out of range');
      }
    })
  }

  @traceFunc()
  ngOnDestroy(): void {
  }
}
