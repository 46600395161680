import { IEntityDeletedFlag } from "../interfaces/IEntityDeletedFlag";
import { IEntityId } from "../interfaces/IEntityId";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IDayDeviation extends IEntityId, IEntityDeletedFlag {
  /** Наименование отклонения от нормальной работы */
  name: string;
  /** Цифровой код цвета, которым закрашивается клетка в графике или табеле */
  color: string;
  /** Флаг, влияющий на расчет нормы рабочего времени по отклонениям от нормальной работы */
  isOperatingFlag: boolean;
  /** Включается значение CustomValue данного отклонения в расчет нормы */
  isIncludeInCalculate: boolean;
  /** Может ли содержать значение CustomValue */
  hasCustomValue: boolean;
  /** Может ли быть работа (TimeInterval) и данное отклонение в один день */
  deviationWithInterval: boolean;
  /** Данные, отображаемые в ячейках */
  shortName: string;
}

@classBackend('DayDeviation', 'timesheet_graph')
@className('DayDeviation')
/** Отклонение от нормальной работы */
export class DayDeviation implements IDayDeviation {
  constructor(public id: number,
              public deletedFlag: boolean,
              public name: string,
              public color: string,
              public isOperatingFlag: boolean,
              public isIncludeInCalculate: boolean,
              public hasCustomValue: boolean,
              public deviationWithInterval: boolean,
              public shortName: string) { }

  private static _fullComparer: ObjComparer<IDayDeviation>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer({
        id: true,
        deletedFlag: true,
        name: true,
        color: true,
        isOperatingFlag: true,
        isIncludeInCalculate: true,
        hasCustomValue: true,
        deviationWithInterval: true,
        shortName: true,
      })
    }

    return this._fullComparer;
  }

}
