@if(form){
    <div [formGroup]='form'
         class='wrap'>
        @if (periodFieldVisible){
            <span class='label'>Период:</span>
            <kendo-dropdownlist [data]="periods"
                                [formControl]='form.controls.period'
                                valueField='id'
                                textField='text'
                                style='width: 100%;'>
            </kendo-dropdownlist>  }

        @if(!!subPeriods.length){
            <span class='label'>{{ subPeriodLabelText }}</span>
            <kendo-dropdownlist [data]='subPeriods'
                                [formControl]="form.controls.subPeriod"
                                valueField='id'
                                textField='text'
                                style='width: 100%;'>
            </kendo-dropdownlist>
        }

        <span class='label'>Год:</span>
        <kendo-numerictextbox *ngLet="years$ | async as years"
                              [formControl]='form.controls.year'
                              style='width: 100%;'
                              [min]='years?.minYear'
                              [max]='years?.maxYear'
                              [step]='1'
                              [format]='"#"'
                              [decimals]='0'
                              [autoCorrect]='true'>
        </kendo-numerictextbox>

        <kendo-label class="k-checkbox-label"
                     style="margin-left: 5px;"
                     [for]="suTypes"
                     text="Вид занятости:">
        </kendo-label>

        <kendo-multiselect #suTypes
                           style="margin-left: 5px;"
                           [data]="staffUnitTypes$ | async"
                           textField="description"
                           valueField="id"
                           [checkboxes]="true"
                           [autoClose]="false"
                           [formControl]="form.controls.checkedStaffUnitTypes">
        </kendo-multiselect>

    </div>

    <div class='k-form-buttons' style='justify-content: flex-end;'>

        <button kendoButton
                type='button'
                primary='true'
                [disabled]='!form || form?.invalid'
                (click)='onClickOk()'
        >
            Сформировать
        </button>

        <button kendoButton
                type='button'
                (click)='onClickCancel()'
        >
            Отменить
        </button>

    </div>
}
