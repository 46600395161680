import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Self, TemplateRef,
  ViewChild
} from '@angular/core';
import {CellSelectingDirectiveService} from "./directives/cell-selecting.directive.service";
import {
  RedactionBaseService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {
  Api1RedactionGraphService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-graph.service";
import {GridToolbarManagementService} from "../services/grid-toolbar-management.service";
import {GraphTableComponentSettings, IBySubdivisionOwnerId} from "./classes/graphTableComponentSettings";
import {combineLatest, Observable, of, ReplaySubject, switchMap} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {GraphDataSourceHelperService} from "./secvices/graph-data-source-helper.service";
import {SortDescriptor} from "@progress/kendo-data-query";
import {
  ErrorPageSettings,
  WorkSpaceErrorComponentService
} from "../../../services/workspace/work-space-error.component.service";
import {GraphDataSourceService} from "./secvices/graph-data-source.service";
import {GraphDayViewModel, GraphGridRowModel} from "./classes/view-models/row-and-graph-day-view-model.class";
import {GraphCellEditService, GraphEditService_SelectedEvent} from "./secvices/graph-cell-edit.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {ResponseObjError} from "../../../../../../../src/app/classes/requestResults/responseObjError";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {AlertService} from "../../../../../../../src/app/services/alert.service";
import {KendoNotificationService} from "../../../../../../../src/app/services/kendo-notification.service";
import {EditMoonlighterService} from "../services/edit-moonlighter.service";
import {
  ContextCovidLogItem,
  ContextExcludeMilk,
  ContextTuberLogItem,
  ContextVichLogItem,
  GraphGridRowContextService
} from "./secvices/graph-grid-row-context.service";
import {ContextMenuComponent} from "@progress/kendo-angular-menu";
import {GraphRowEditService} from "./secvices/graph-row-edit.service";
import {GraphNormFactCalculatorService} from "./secvices/graph-norm-fact-calculator.service";
import {EditProxyService} from "../services/edit-proxy.service";
import {
  CovidRegisterComponentSettings,
  CovidRegisterComponentSettings_Item,
  CovidRegisterComponentSettings_TimeInterval,
  ICovidRegisterGridComponentChangeEvent
} from "../../../../../../../src/app/components/covid/covid-register-grid/covid-register-grid.component";
import {NumberHelper} from "../../../../../../../src/app/helpers/numberHelper";
import {
  TimeIntervalDurationService
} from "../../../../../../../src/app/services/timeIntervalServices/time-interval-duration.service";
import {
  Api1CovidControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-covid-controller.service";
import {
  Api1TimeSheetSettingsControllerService,
  CovidLogicEnum
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-time-sheet-settings-controller.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {trace} from "../../../../../../../src/app/modules/trace/operators/trace";
import {DateHelper} from "../../../../../../../src/app/helpers/dateHelper";
import {
  DayDeviationPanelComponentService
} from './components/day-deviation-panel/services/day-deviation-panel-component.service';
import {
  DayDeviationPanelComponentDataService
} from './components/day-deviation-panel/services/day-deviation-panel-component-data.service';
import {
  TimeIntervalPanelComponentService
} from './components/time-interval-panel/services/time-interval-panel-component.service';
import {
  TimeIntervalPanelComponentDataService
} from './components/time-interval-panel/services/time-interval-panel-component-data.service';
import {CellClickEvent, RowClassArgs} from "@progress/kendo-angular-grid";
import {
  Covid2RegisterComponentSettings,
  ICovid2RegisterGridComponentChangeEvent
} from 'src/app/components/covid2/covid2-register-grid/i-covid2-register-grid.component';
import {AuthService} from 'src/app/modules/auth/services/auth.service';
import {
  IVichRegisterGridComponentChangeEvent,
  VichRegisterComponentSettings,
  VichRegisterComponentSettings_TimeInterval
} from 'src/app/components/vich/vich-register-grid/i-vich-register-grid.component';
import {TimeSheetFunctionEnum} from 'src/app/classes/domain/AccessEnums/timeSheet/timeSheetFunctionEnum';
import {
  TuberRegisterComponentSettings,
  TuberRegisterComponentSettings_TimeInterval
} from 'src/app/components/tuber/tuber-register-grid/i-tuber-register-grid.component';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {
  CovidRegisterGridDialogComponent
} from 'src/app/components/covid/covid-register-grid-dialog/covid-register-grid-dialog.component';
import {
  Covid2RegisterGridDialogComponent
} from 'src/app/components/covid2/covid2-register-grid-dialog/covid2-register-grid-dialog.component';
import {
  VichRegisterGridDialogComponent
} from 'src/app/components/vich/vich-register-grid-dialog/vich-register-grid-dialog.component';
import {
  TuberRegisterGridDialogComponent
} from 'src/app/components/tuber/tuber-register-grid-dialog/tuber-register-grid-dialog.component';
import {StaffUnitTypeEnum} from "../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {WorkModeTypeEnum} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkModeType";
import {DayTypeEnum, DayTypeEnumObj} from "../../../../../../../src/app/classes/domain/enums/day-type-enum";
import {
  HierarchiStringService
} from "../../../../../../../src/app/components/hierarchi-strings/services/hierarchi-string.service";
import {exLoadingMessage} from "../../../../../../../src/app/operators/ex-loading-message.operator";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {
  Api1TuberControllerService, ITuberRegisterSettingsResponse
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-tuber-controller.service";
import {
  Api1VichControllerService,
  IVichRegisterSettingsResponse
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-vich-controller.service";

//--Классы строк графика
  const rowHasState = 'row-has-state'
  const rowDeleted = [rowHasState, 'row-is-deleted']
  const rowAdded = [rowHasState, 'row-is-added']
  const rowModified = [rowHasState, 'row-is-modified']
  //---


@Component({
  selector: 'app-graph-grid',
  templateUrl: './graph-grid.component.html',
  styleUrls: ['./graph-grid.component.css'],
  providers: [
    CellSelectingDirectiveService,
    HierarchiStringService,
    { provide: 'target', useValue: 'graph' },
    { provide: RedactionBaseService, useExisting: Api1RedactionGraphService },
    { provide: GridToolbarManagementService },
    { provide: GraphCellEditService },
    { provide: TimeIntervalPanelComponentService },
    { provide: TimeIntervalPanelComponentDataService },
    { provide: EditMoonlighterService },
    { provide: EditProxyService },
    { provide: GraphGridRowContextService },
    { provide: GraphRowEditService },
    { provide: GraphNormFactCalculatorService },
    { provide: GraphDataSourceService },
    { provide: DayDeviationPanelComponentService },
    { provide: DayDeviationPanelComponentDataService }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('GraphGridComponent')
export class GraphGridComponent implements OnInit, OnDestroy {
  @Input() settings: GraphTableComponentSettings = null;

  /** Данные для tooltip при наведении на иконку сотрудника */
  public dataForTooltip: GraphGridRowModel;

  /** стримы */
  public streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    selectedCells: new ReplaySubject<GraphEditService_SelectedEvent>(1)
  }

  /** Управляющие переключатели */
  public switches = {
    isComputedCombination: null,
    launchButtonVisibility: false,
    multipleRowsSelected: false,
    openCustomValueDialog: false,
    hasEditCovidVichTuber: false,
    canExcludeMilk: false,
  }

  /** {@link DayTypeEnum} для html */
  public dayTypeEnum = DayTypeEnumObj;

  /** {@link StaffUnitTypeEnum} для работы в html  */
  public readonly staffUnitTypeEnum = {
    Duty: StaffUnitTypeEnum.Duty
  }

  @ViewChild('rowContextMenu') public rowContextMenu: ContextMenuComponent;

  public covidLogic: CovidLogicEnum

  /** Настройки для окна случаев работы с covid для дня */
  public covidRegisterComponentSettings: CovidRegisterComponentSettings = null;

  /** Настройки для окна случаев работы с covid2 для дня */
  public covid2RegisterComponentSettings: Covid2RegisterComponentSettings = null;

  public tubRegisterSettingsArr: ITuberRegisterSettingsResponse;
  private lastChosenTubRegisterType: number;
  public vichRegisterSettingsArr: IVichRegisterSettingsResponse;
  private lastChosenVichRegisterType: number;

  /** Параметры для диалог сервиса метода open  */
  private dialogServiceOpenOpts = {
    minWidth: '800px',
    width: '95%',
    height: '90%',
    preventAction: () => true
  }

  /** Если выделена только одна ячейка, вернет ее vm иначе null */
  private get singleSelectedCell(): GraphDayViewModel {
    if (this.cellSelectingDirectiveService.selecting2.isSelected && this.cellSelectingDirectiveService.selecting2.cellViewModels.length == 1) {
      return this.cellSelectingDirectiveService.selecting2.cellViewModels[0].cellModel
    }

    return null;
  }

  //Ссылка на template содержимого диалогового окна настройки продолжительности обеда
  @ViewChild('flexDinnerTemplate', {read: TemplateRef}) private _flexDinnerTemplate : TemplateRef<any>;
  //Значение установленной продолжительности изменяемого обеда
  public flexDinnerValue: number;

  /** Конструктор */
  constructor(private authService: AuthService,
              private redactionBaseService: RedactionBaseService,
              public gridToolbarManagementService: GridToolbarManagementService,
              private graphDataSourceHelperService: GraphDataSourceHelperService,
              private workSpaceErrorComponentService: WorkSpaceErrorComponentService,
              private cellSelectingDirectiveService: CellSelectingDirectiveService,
              public graphEditService: GraphCellEditService,
              @Self() public timeIntervalPanelComponentService: TimeIntervalPanelComponentService,
              private api1GraphControlControllerService: Api1GraphControlControllerService,
              private loadingIndicatorService: LoadingIndicatorService,
              private alertService: AlertService,
              private kendoNotificationService: KendoNotificationService,
              private editMoonlighterService: EditMoonlighterService,
              public graphGridRowContextService: GraphGridRowContextService,
              public graphRowEditService: GraphRowEditService,
              public graphNormFactCalculatorService: GraphNormFactCalculatorService,
              private timeIntervalDurationService: TimeIntervalDurationService,
              public graphDataSourceService: GraphDataSourceService,
              public covidControllerService: Api1CovidControllerService,
              private api1TimeSheetSettingsControllerService: Api1TimeSheetSettingsControllerService,
              private readonly editProxyService: EditProxyService,
              private readonly tracerService: TracerServiceBase,
              private readonly dialogService: DialogService,
              private readonly hierarchiStringsService: HierarchiStringService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly tuberControllerService: Api1TuberControllerService,
              private readonly vichControllerService: Api1VichControllerService,
              ) { }

  @traceFunc()
  ngOnInit(): void {
    if (!this.settings) {
      throw new Error('Не переданы настройки графика')
    }

    this.gridToolbarManagementService.isEditing$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.settings.isEdit$.emit(value);
    });

    this.gridToolbarManagementService.redactionChange$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if (value.afterSave) {
        return;
      }
      this.initGraphData(value.redactionId);
    });

    this.gridToolbarManagementService.checkErrors$.pipe(
      switchMap(v =>
        this.api1GraphControlControllerService.checkErrors$(v)
          .pipe(
            exLoadingMessage(this.loadingIndicatorService, 'Проверка данных'),
            exErrorHandler(this.displayErrorsService)
          )
      ),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(value => {
        this.hierarchiStringsService.show(
          {
            subdivisionId: this.gridToolbarManagementService.data.graphTable.subdivisionOwnerId,
            month: this.gridToolbarManagementService.data.graphTable.month
          },
          value.map(x => x.errorsDescriptor),
          'Список ошибок');
      });

    this.loadingIndicatorService.addToObservable(
      'Инициализация',
      combineLatest([
        this.initGridToolbarManagementService(),
        this.api1TimeSheetSettingsControllerService.getCovidLogic$_cached$()
      ])
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.covidLogic = value[1];

        this.cellSelectingDirectiveService.selecting2$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
          const selectedCells = GraphEditService_SelectedEvent.Create(value);
          this.streams$.selectedCells.next(selectedCells);
          this.switches.multipleRowsSelected = value.rowRange?.isMultiple ?? false;

          this.switches.launchButtonVisibility = selectedCells.graphDays && selectedCells.graphDays.every(x => x.timeInterval)
            && selectedCells.graphDays.every(b => b.row.staffUnit.workMode.workModeTypeId == WorkModeTypeEnum.flexibleGraph);

          this.switches.hasEditCovidVichTuber = this.authService.user.Functions.some(x => x == TimeSheetFunctionEnum.GraphEditing);

          this.switches.canExcludeMilk = selectedCells.row?.staffUnit?.milk;
        });

      }, error: error => {
        this.workSpaceErrorComponentService.redirect(ErrorPageSettings.AsExtensionObj().modResult(x => {
          x.message = 'При загрузке данных графика произошла ошибка!'
          x.stackTrace = error;
        }));
      }
    });

    this.gridToolbarManagementService.save$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(comment => {
      this.saveGraph(comment);
    });

    //Открытие окна Covid по двойному щелчку мыши
    this.cellSelectingDirectiveService.dblclick$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if (this.gridToolbarManagementService.isEditing && this.switches.hasEditCovidVichTuber && this.singleSelectedCell?.timeInterval) {
        this.onOpenCovid(this.singleSelectedCell);
      }
    })
  }

  /** Обработка события изменения сортировки */
  public onSortChange(sort: Array<SortDescriptor>): void {
    sort.reverse().forEach(sd => {
      let tmpDir = this.graphDataSourceService.state.sort.find(d => d.field == sd.field);
      tmpDir.dir = sd.dir;
      this.graphDataSourceService.state.sort = this.graphDataSourceService.state.sort.filter(x => x.field != sd.field);
      this.graphDataSourceService.state.sort.unshift(tmpDir);
    });
    this.graphDataSourceService.setState(this.graphDataSourceService.state);
    this.cellSelectingDirectiveService.clear();
  }

  /** Сохранить график */
  @traceFunc()
  private saveGraph(comment: string) {
    const saveGraphData = this.graphDataSourceService.getDataForSaveGraph();

    this.loadingIndicatorService.addToObservable(
      'Сохранение графика',
      this.api1GraphControlControllerService.saveGraphObsolete$(
        this.gridToolbarManagementService.redaction.id,
        comment,
        saveGraphData.graphDays)
    ).pipe(trace(this.tracerService)).subscribe({
      next: value => {
        value.ids.forEach(idTuple => {
          const graphDay = saveGraphData.sourceData.find(x => x.guid == idTuple.guid).graphDay as GraphDayViewModel;
          graphDay.id = idTuple.id;
          graphDay.applyChange();
        });

        this.gridToolbarManagementService.OnInit4(value.grouper, null, false, true);
        this.graphDataSourceService.changeRedaction(value.grouper.actualRedactionId); //Устанавливаем редакцию
        this.kendoNotificationService.showSuccess({ content: 'Сохранено' })
      }, error: error => {
        this.kendoNotificationService.showError({ content: 'НЕ удалось сохранить' })
        if (ResponseObjError.checkTypeReturnCode(error) == 'd09bb974-f9eb-45d5-a8e6-392b034aa2a3') {
          const errorMessage = (error as ResponseObjError<string>).data;
          this.alertService.defaultAlertOption.information().mod(x => {
            x.message = `К сожалению мы не сможем сохранить Ваши данные<br>${errorMessage}<br><strong>Страница будет перезагружена</strong>`
            x.buttons[0].callBack = () => {
              this.loadingIndicatorService.add('Перезагрузка страницы');
              window.location.reload();
            }
          }).showAlert();
          return;
        }

        this.alertService.defaultAlertOption.warning().showAlert();
      }
    });
  }

  /** Инициализация данных для графика */
  @traceFunc()
  private initGraphData(redactionId: number) {
    this.graphDataSourceService.ngOnDestroy()
    this.cellSelectingDirectiveService.clear();

    this.graphDataSourceService.onInit(redactionId).pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe({
        next: () => {
          this.kendoNotificationService.showInfo({ content: 'Готов к работе' })
        },
        error: error => {
          this.workSpaceErrorComponentService.redirect(ErrorPageSettings.AsExtensionObj().modResult(x => {
            x.message = 'При загрузке данных графика произошла ошибка!';
            x.stackTrace = error;
          }));
        }
      });
  }

  /** Проинициализировать сервис */
  @traceFunc()
  private initGridToolbarManagementService(): Observable<void> {
    let initObservable: Observable<void>;

    const initType = this.settings.openFor.getValue();
    switch (initType.type) {
      case "subdivisionOwnerId":
        const value = initType.value as IBySubdivisionOwnerId
        initObservable = this.gridToolbarManagementService.onInit3(value.subdivisionOwnerId, value.year, value.month);
        break;
      case "graphOrTableId":
        initObservable = this.gridToolbarManagementService.onInit1(initType.value as number);
        break;
      case "redactionId":
        initObservable = this.gridToolbarManagementService.onInit2(initType.value as number);
        break;
      default: throw new Error('Out of range')
    }

    this.loadingIndicatorService.addToObservable(
      'Получение доступа к возможности редактирования ковид',
      this.api1TimeSheetSettingsControllerService.getOpenCovidDialogSubdivisionIds$_cached$()
    ).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.gridToolbarManagementService.isSupportedCovidFunctional = value === null ||
        value.some(x => x == this.settings.openFor.bySubdivisionOwnerId.subdivisionOwnerId);
    });

    return initObservable;
  }

  /** Обработка события нажатия на клавишу Delete для очитски выделенных ячеек */
  @traceFunc()
  onDeleteKeyDown() {
    if (!this.gridToolbarManagementService.isEditing) {
      return;
    }
    this.graphEditService.clearCells();
  }

  /** Проверка необходимости открытия диалога covid/vich/tuber */
  @traceFunc()
  private checkOpenRegisterDialog(singleCell: GraphDayViewModel) {
    if (!this.gridToolbarManagementService.isSupportedCovidFunctional ||
      (singleCell.day && !DateHelper.isExistInRange(singleCell.day.date, singleCell.row.staffUnit.startDate, singleCell.row.staffUnit.endDate))) {
      return false;
    }

    if (singleCell.asHasStateChange?.isChanged) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'Выбранная Вами ячейка имеет несохраненные данные.</br>Сохраните внесенные изменения и </br>повторите попытку редактирования ячейки.'
      }).showAlert();
      return false;
    }
    return true;
  }

  /** Обработка dblClick по ячейке графика с Covid информацией */
  @traceFunc()
  onOpenCovid(singleCell: GraphDayViewModel) {
    if (!this.checkOpenRegisterDialog(singleCell)) return;

    if (this.covidLogic === CovidLogicEnum.Logic1) {
      this.onOpenCovidRegisterDialog(singleCell)
    } else if (this.covidLogic === CovidLogicEnum.Logic2) {
      this.onOpenCovid2RegisterDialog(singleCell)
    }
  }

  /** Обработка нажатия на кнопку открытия диалога регистрации случаев работы с covid на текущий день */
  @traceFunc()
  onOpenCovidRegisterDialog(singleCell: GraphDayViewModel) {
    this.loadingIndicatorService.addToObservable(
      'Получение данных ковид',
      this.covidControllerService.getCovidRegisterData$(
        this.settings.openFor.bySubdivisionOwnerId.year,
        this.settings.openFor.bySubdivisionOwnerId.month,
        singleCell.day.date,
        null,
        singleCell.row.staffUnit.ownerId)
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const dialog: DialogRef = this.dialogService.open({
        content: CovidRegisterGridDialogComponent,
        ...this.dialogServiceOpenOpts
      })

      const instance = dialog.content.instance as CovidRegisterGridDialogComponent
      instance.settings = new CovidRegisterComponentSettings(
        singleCell.day.date,
        [
          new CovidRegisterComponentSettings_Item(
            value.datas[0].staffUnit.ownerId,
            null,
            (() => {
              const dates = this.timeIntervalDurationService.getDates(
                singleCell.day.date,
                singleCell.timeInterval.start,
                singleCell.timeInterval.end);
              return new CovidRegisterComponentSettings_TimeInterval(dates.start, dates.end, value.datas[0]?.graphDays[0]?.timeIntervalDuration)
            })())],
        false
      )

      dialog.result.subscribe(this.onCloseCovidRegisterDialog.bind(this))
    });
  }

  /** Обработка нажатия на кнопку открытия диалога регистрации случаев работы с covid2 на текущий день */
  @traceFunc()
  onOpenCovid2RegisterDialog(singleCell: GraphDayViewModel) {
    const dialog: DialogRef = this.dialogService.open({
      content: Covid2RegisterGridDialogComponent,
      ...this.dialogServiceOpenOpts
    })

    const instance = dialog.content.instance as Covid2RegisterGridDialogComponent
    instance.settings = new Covid2RegisterComponentSettings(
      singleCell.day.date,
      singleCell.row.staffUnit.ownerId
    )

    dialog.result.subscribe(this.onCloseCovid2RegisterDialog.bind(this))
  }

  /** Обработка закрытия диалога регистрации случаев работы с ковид на текущий день */
  @traceFunc()
  private onCloseCovidRegisterDialog(e: ICovidRegisterGridComponentChangeEvent) {
    if (e.changed) {
      const cell = this.singleSelectedCell;
      cell.covidHours = NumberHelper.round(e.busyTime / 60, 2);
      cell.asObservable.onChange();
    }
  }

  /** Обработка закрытия диалога регистрации случаев работы с ковид2 на текущий день */
  @traceFunc()
  private onCloseCovid2RegisterDialog(e: ICovid2RegisterGridComponentChangeEvent) {
    if (e.changed) {
      const cell = this.singleSelectedCell;
      cell.hasCovid2 = !!e.length;
      cell.asObservable.onChange();
    }
  }

  /** Функция для установки стиля для строки */
  public rowClassCallback = (context: RowClassArgs) => {
    if (!this.gridToolbarManagementService.compareRedactionId) {
      return null;
    }

    const row: GraphGridRowModel = context.dataItem;
    if (!row.compareState) {
      return null;
    }

    switch (row.compareState) {
      case "deleted":
        return rowDeleted;
      case "added":
        return rowAdded;
      case "modified":
        return rowModified;
      default: throw new Error('out of range');
    }
  }

  /** Обработка нажатия на кнопку открытия диалога регистрации случаев работы с ВИЧ на текущий день */
  @traceFunc()
  onOpenVichRegisterDialog(singleCell: GraphDayViewModel) {
    if (!this.checkOpenRegisterDialog(singleCell)) return;

    this.loadingIndicatorService.addToObservable(
      "Загрузка параметров журналов",
      this.vichControllerService.registerSettingsModels_cached$)
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe({
        next: (value: IVichRegisterSettingsResponse) => {
          if(!value.settingsSets.length) throw Error('Для данной клиники не определено ни одного типа ВИЧ журналов');
          this.vichRegisterSettingsArr = value;
          this.createVichDialog(singleCell);
        },
        error: err => {
          this.displayErrorsService.handleError(err);
        }
      });
  }

  private createVichDialog(cell: GraphDayViewModel) {
    const dialog: DialogRef = this.dialogService.open({
      content: VichRegisterGridDialogComponent,
      ...this.dialogServiceOpenOpts
    });

    const instance = dialog.content.instance as VichRegisterGridDialogComponent;

    instance.settings = new VichRegisterComponentSettings(
      cell.day.date,
      cell.row.staffUnit.ownerId,
      (() => {
        const dates = this.timeIntervalDurationService.getDates(
          cell.day.date,
          cell.timeInterval.start,
          cell.timeInterval.end);

        return new VichRegisterComponentSettings_TimeInterval(
          dates.start, dates.end, cell.timeInterval.duration)
      })(),
      this.vichRegisterSettingsArr,
      this.lastChosenVichRegisterType);

    instance.currentRegisterType$
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(value => {
        this.lastChosenVichRegisterType = value;
      });

    dialog.result.subscribe(this.onCloseVichRegisterDialog.bind(this));
  }

  /** Обработка закрытия диалога регистрации случаев работы с ковид на текущий день */
  @traceFunc()
  private onCloseVichRegisterDialog(e: IVichRegisterGridComponentChangeEvent) {
    if (e.changed) {
      const cell = this.singleSelectedCell;
      cell.vichHours = NumberHelper.round(e.busyTime / 60, 2);
      cell.asObservable.onChange();
    }
  }

  /** Обработка нажатия на кнопку открытия диалога регистрации случаев работы с туберкулезом на текущий день */
  @traceFunc()
  onOpenTuberRegisterDialog(singleCell: GraphDayViewModel) {
    if (!this.checkOpenRegisterDialog(singleCell)) return;

    this.loadingIndicatorService.addToObservable(
        "Загрузка параметров журналов",
        this.tuberControllerService.registerSettingsModels_cached$)
        .pipe(take(1), takeUntil(this.streams$.unsubscribe))
        .subscribe({
          next: (value: ITuberRegisterSettingsResponse) => {
            if(!value.settingsSets.length) throw Error('Для данной клиники не определено ни одного типа туб журналов');
            this.tubRegisterSettingsArr = value;
            this.createTuberDialog(singleCell);
        },
      error: err => {
        this.displayErrorsService.handleError(err);
      }
    });
  }

  private createTuberDialog(cell: GraphDayViewModel) {
    const dialog: DialogRef = this.dialogService.open({
      content: TuberRegisterGridDialogComponent,
      ...this.dialogServiceOpenOpts
    });

    const instance = dialog.content.instance as TuberRegisterGridDialogComponent;

    instance.settings = new TuberRegisterComponentSettings(
      cell.day.date,
      cell.row.staffUnit.ownerId,
      (() => {
        const dates = this.timeIntervalDurationService.getDates(
          cell.day.date,
          cell.timeInterval.start,
          cell.timeInterval.end);

        return new TuberRegisterComponentSettings_TimeInterval(
          dates.start, dates.end, cell.timeInterval.duration)
      })(),
      this.tubRegisterSettingsArr,
      this.lastChosenTubRegisterType
    );

    instance.currentRegisterType$
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(value => {
        this.lastChosenTubRegisterType = value;
      });

    dialog.result.subscribe(this.onCloseTuberRegisterDialog.bind(this));
  }

  /** Добавить 'Исключать молоко' */
  private onAddExcludeMilk(singleCell: GraphDayViewModel){
    this.loadingIndicatorService.addToObservable(
      'Добавление "Исключить компенсацию на молоко"',
      this.api1GraphControlControllerService.addExcludeMilkLog$([{staffUnitId: singleCell.row.staffUnit.ownerId, dates: [singleCell.day.date]}])
    ).pipe(takeUntil(this.streams$.unsubscribe))
      .subscribe({
        next: value => {
          singleCell.hasExcludeMilk = true;
          singleCell.asObservable.onChange();
        },
        error: err => {
          this.displayErrorsService.handleError(err);
          this.kendoNotificationService.showError({content: 'При добавлении "Исключить компенсацию за молоко" произошла ошибка'})
        }
      })
  }

  /** Удалить 'Исключать молоко' */
  private onRemoveExcludeMilk(singleCell: GraphDayViewModel){
    this.loadingIndicatorService.addToObservable(
      'Удалить "Исключить компенсацию на молоко"',
      this.api1GraphControlControllerService.deleteExcludeMilkLog$([{staffUnitId: singleCell.row.staffUnit.ownerId, dates: [singleCell.day.date]}])
    ).pipe(takeUntil(this.streams$.unsubscribe))
      .subscribe({
        next: value => {
          singleCell.hasExcludeMilk = false;
          singleCell.asObservable.onChange();
        },
        error: err => {
          this.displayErrorsService.handleError(err)
          this.kendoNotificationService.showError({content: 'При удалении "Исключить компенсацию за молоко" произошла ошибка'})
        }
      })
  }

  /** Обработка закрытия диалога регистрации случаев работы с ковид на текущий день */
  @traceFunc()
  private onCloseTuberRegisterDialog(e: IVichRegisterGridComponentChangeEvent) {
    if (e.changed) {
      const cell = this.singleSelectedCell;
      cell.tuberHours = NumberHelper.round(e.busyTime / 60, 2);
      cell.asObservable.onChange();
    }
  }

  /** Обработка нажатия на ячейку */
  @traceFunc()
  public onCellClick(rowContextMenu: ContextMenuComponent, $event: CellClickEvent) {
    if($event.type !== 'contextmenu') { // Вызов контекста только правой кнопкой
      return;
    }

    if ($event.columnIndex > 7 && !this.checkOpenRegisterDialog(this.singleSelectedCell)){
      return;
    }

    const contextMenuItems = [];

    if (this.switches.hasEditCovidVichTuber && this.singleSelectedCell?.timeInterval){
      contextMenuItems.push(
        new ContextCovidLogItem(false, () => this.onOpenCovid(this.singleSelectedCell)),
        new ContextVichLogItem(false, () => this.onOpenVichRegisterDialog(this.singleSelectedCell)),
        new ContextTuberLogItem(false, () => this.onOpenTuberRegisterDialog(this.singleSelectedCell))
      )
    }

    if (this.switches.canExcludeMilk && this.singleSelectedCell){ //Определение логики элемента контекста для 'Исключать молоко'
      if(this.singleSelectedCell.hasExcludeMilk){ //Если у ячейки имеется метка, то даем возможность ее удалить
        contextMenuItems.push(
          new ContextExcludeMilk('add', false, () => this.onRemoveExcludeMilk(this.singleSelectedCell))
        );
      } else if(this.singleSelectedCell.timeInterval) { //Добавлять можем только если есть временной интервал
        contextMenuItems.push(
          new ContextExcludeMilk('remove', false, () => this.onAddExcludeMilk(this.singleSelectedCell))
        );
      }
    }

    this.graphGridRowContextService.onCellClick(rowContextMenu, $event, contextMenuItems);
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.graphEditService.ngOnDestroy();
    this.timeIntervalPanelComponentService.ngOnDestroy();
    this.editMoonlighterService.ngOnDestroy();
    this.graphGridRowContextService.ngOnDestroy();
    this.graphRowEditService.ngOnDestroy();
    this.graphNormFactCalculatorService.ngOnDestroy();
    this.editProxyService.ngOnDestroy();
    this.gridToolbarManagementService.ngOnDestroy();
  }

  /** Обработка события нажатия на кнопку установки изменяемой продолжительности обеда */
  public onFlexDinnerBtnClick() {
    const dialog: DialogRef = this.dialogService.open({
      content: this._flexDinnerTemplate,
      width: 280,
      height: 170,
      title: 'Продолжительность обеда',
        actions: [
          {text: 'Применить', themeColor: 'primary', ok: true},
          {text: 'Отмена'}]
    })

    this.flexDinnerValue = this.cellSelectingDirectiveService.selecting2.cellViewModels[0].cellModel.flexDinner;
    this.flexDinnerValue = this.singleSelectedCell?.flexDinner ||//если выделена единственная ячейка и значение обеда в ней уже установлено или
      this.cellSelectingDirectiveService.selecting2.cellViewModels // если все ячейки имеют одно и то же значение
        .every(x => x.cellModel.flexDinner === this.flexDinnerValue) && this.flexDinnerValue || 30;//иначе устанавливаем 30 мин

    dialog.result.subscribe(r => {
      if((r as {ok: boolean}).ok){
        this.graphEditService.setFlexDinnerValue(this.flexDinnerValue);
      }
    });
  }
}
