import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";
import { CancelEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent, SelectionEvent } from "@progress/kendo-angular-grid";
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { KendoNotificationService } from 'src/app/services/kendo-notification.service';
import { AlertService } from 'src/app/services/alert.service';
import { shareReplay, take, takeUntil } from 'rxjs/operators';
import { ResponseObjError } from 'src/app/classes/requestResults/responseObjError';
import {ISubdivisionOptional, Subdivision} from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import {Employee} from 'src/app/classes/domain/POCOs/stafflist/Employee';
import { IApprovalsBaseOptional } from 'src/app/classes/domain/POCOs/abstract/IApprovalsBase';
import { ApprovalDTO } from 'src/app/services/webApi/webApi1/controllers/api1-approvals-control-controller.service';
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { FilterExpandSettings } from "@progress/kendo-angular-treeview";
import { DisplayErrorsService } from 'src/app/components/display-errors/services/display-errors.service';

@Component({
  selector: 'app-approval-route-step',
  templateUrl: './approval-route-step.component.html',
  styleUrls: ['./approval-route-step.component.css']
})
export class ApprovalRouteStepComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) private grid: GridComponent;

  private _subdivisionIds: number[];
  private _userList: IApprovalRouteStepComponent_ApprovalEmployee[];
  public userList: IApprovalRouteStepComponent_ApprovalEmployee[];
  @Input("subdivisionIds") public get subdivisionIds() {
    return this._subdivisionIds;
  }

  public set subdivisionIds(val: number[]) {
    this._subdivisionIds = val;
    this.reloadDataSource();
  }

  private _settings: IApprovalRouteStepComponent_Settings;
  @Input("settings") public get settings(): IApprovalRouteStepComponent_Settings {
    return this._settings;
  }

  public set settings(val: IApprovalRouteStepComponent_Settings) {

    val.allUsers$ = val.allUsers$.pipe(shareReplay(1), takeUntil(this.streams$.unsubscribe));
    val.allSubdivisions$ = val.allSubdivisions$.pipe(shareReplay(1), takeUntil(this.streams$.unsubscribe));

    this._settings = val;
  }

  /** Источник данных для таблицы */
  public dataSource: Array<ApprovalRouteStepComponent_DataItem> = [];
  /** Форма для добавления*/
  public formGroup: UntypedFormGroup = null;

  public expandSettings: FilterExpandSettings =
    {
      expandMatches: true,
      maxAutoExpandResults: -1
    }

  public disabledTitle: string = "Назначить ответственным можно либо подразделение, либо сотрудника";

  /** Можно ли добавлять элемент? Активна ли кнопка добавления? */
  public get canAdd(): boolean {
    return this.subdivisionIds?.length > 0;
  }

  private _upItem: ApprovalRouteStepComponent_DataItem;
  public get canReorderUp(): boolean {
    return !!this.currentSelectedItem && !!this._upItem;
  }

  private _downItem: ApprovalRouteStepComponent_DataItem;
  public get canReorderDown(): boolean {
    return !!this.currentSelectedItem && !!this._downItem;
  }

  public selectedKeys: number[] = [];

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  public isEqualData: boolean = true;

  public currentSelectedItem: ApprovalRouteStepComponent_DataItem;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  constructor(public loadingIndicatorService: LoadingIndicatorService,
    public kendoNotificationService: KendoNotificationService,
    public alertService: AlertService, private displayErrorsService: DisplayErrorsService) { }

  ngOnInit(): void {
    if (!this.settings) {
      throw new Error("Parameter [settings] must be declared");
    }
    this.settings.allUsers$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this._userList = value;
    })
  }

  /** Обработка события добавления в grid */
  public onAdd(): void {
    this.formGroup = this.createForm(null);
    this.grid.addRow(this.formGroup);
  }

  /** Обработка события отмены в grid */
  public onCancel($event: CancelEvent) {
    this.closeRow($event.rowIndex);
  }

  /** Обработка события сохранения в grid */
  public onSave($event: SaveEvent) {
    this.saveAdd($event);
    this.closeRow($event.rowIndex)
  }

  /** Обработка события редактирования в grid */
  public onEdit($event: EditEvent) {
    this.formGroup = this.createForm($event.dataItem);
    this.grid.editRow($event.rowIndex, this.formGroup)
  }

  /** Обработка события удаления в grid */
  public onRemove($event: RemoveEvent) {
    this.alertService.defaultAlertOption.confirmation().mod(x => {
      x.buttons[1].callBack = () => {
        this.loadingIndicatorService.addToObservable(
          'Удаление шага маршрута',
          this.settings.deleteApprovals$Func(this.subdivisionIds, $event.dataItem)
        ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
          next: value => {

            this.kendoNotificationService.showSuccess({ content: 'Шаг маршрута удален' })
            this.reloadDataSource();
          }, error: error => {
            this.kendoNotificationService.showError({ content: 'При удалении произошла ошибка' })
            this.displayErrorsService.handleError(error)
          }
        })
      }
    }).showAlert();
  }

  /** Инициализация данных */
  private reloadDataSource() {
    this.currentSelectedItem = null;
    this.updateCanUpDownItems(null);
    this.selectedKeys = [];

    if (!this.subdivisionIds || !this.settings?.getApprovalsForSubdivisions$Func) {
      this.dataSource = [];
      return;
    }

    this.loadingIndicatorService.addToObservable(
      'Получение данных',
      this.settings.getApprovalsForSubdivisions$Func(this.subdivisionIds)
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      //Сортируем по убыванию
      value.sort((n1, n2) => n1.sortOrder - n2.sortOrder);
      const groupedData = value.reduce((ubc, u) => ({
        ...ubc,
        [u.subdivisionId]: [...(ubc[u.subdivisionId] || []), u],
      }), {});

      this.isEqualData = this.isEqualAll(groupedData);
      this.dataSource = this.isEqualData ? groupedData[this.subdivisionIds[0]] : [];

      this.userList = this._userList.filter(f => !this.dataSource?.some(d => d.approvalEmployee?.userId === f.userId));
    });
  }

  private isEqualAll(arr: Object) {
    const keys = Object.keys(arr);
    if (keys.length <= 1) return true;

    const firstItem = arr[keys[0]];
    for (let index = 0; index < keys.length; index++) {
      if (!this.isEqualAllItems(arr[keys[index]], firstItem)) return false;
    }
    return true;
  }

  private isEqualAllItems(arr1: ApprovalDTO[], arr2: ApprovalDTO[]): boolean {
    if (arr1.length !== arr2.length) return false;

    for (let index = 0; index < arr1.length; index++) {
      const item1 = arr1[index];
      const item2 = arr2[index];

      if (item1.approvalSubdivision?.id !== item2.approvalSubdivision?.id || item1.approvalEmployee?.id !== item2.approvalEmployee?.id)
        return false;
    }

    return true;
  }

  reorderUp() {
    this.reorder(this.currentSelectedItem, this._upItem);
  }

  reorderDown() {
    this.reorder(this.currentSelectedItem, this._downItem);
  }

  /**
   * Событие при изменении выбранного элемента
   */
  public onSelectionChanged(e: SelectionEvent) {
    const selectedItem = e.selectedRows[0]?.dataItem as ApprovalRouteStepComponent_DataItem;
    this.currentSelectedItem = selectedItem;
    this.updateCanUpDownItems(selectedItem);
  }

  /** Отменить добавление/редактирование */
  private closeRow(rowIndex: number) {
    this.grid.closeRow(rowIndex)
    this.formGroup = null;
  }

  /** Сохранение добавления записи */
  private saveAdd($event: SaveEvent) {
    const item = $event.dataItem as ApprovalRouteStepComponent_DataItem;
    this.loadingIndicatorService.addToObservable(
      'Добавление шага маршрута',
      this.settings.addApprovals$Func(this.subdivisionIds, item, !this.isEqualData)
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.kendoNotificationService.showSuccess({ content: 'Шаг маршрута успешно добавлен' });
        this.reloadDataSource();
      }, error: error => {
        this.kendoNotificationService.showError({ content: 'НЕ удалось сохранить' })
        if (ResponseObjError.checkTypeReturnCode(error) == '622776d3-49ac-4383-b62d-0f6733558c25') {
          const errorMessage = (error as ResponseObjError<string>).data;
          this.alertService.defaultAlertOption.information().mod(x => {
            x.message = `К сожалению мы не сможем сохранить Ваши данные<br>${errorMessage}<br><strong>Страница будет перезагружена</strong>`
            x.buttons[0].callBack = () => {
              this.loadingIndicatorService.add('Перезагрузка страницы');
              window.location.reload();
            }
          }).showAlert();
          return;
        }
      }
    })
  }

  private reorder(approval1: ApprovalRouteStepComponent_DataItem, approval2: ApprovalRouteStepComponent_DataItem) {
    this.loadingIndicatorService.addToObservable(
      'Изменение порядка',
      this.settings.reorderApprovals$Func(this.subdivisionIds, approval1, approval2)
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.reloadDataSource();
        this.kendoNotificationService.showSuccess({ content: 'Порядок успешно изменен' });
      }, error: error => {
        this.alertService.defaultAlertOption.error()
          .mod(r => r.message = "При изменении порядка произошла ошибка. Повторите попытку позже.")
          .showAlert();
      }
    })
  }

  /** Получить форму для добавления/редактирования */
  private createForm(item: ApprovalRouteStepComponent_DataItem) {
    return new UntypedFormGroup({
      approvalEmployee: new UntypedFormControl(item?.approvalEmployee),
      approvalSubdivision: new UntypedFormControl(item?.approvalSubdivision),
      sortOrder: new UntypedFormControl(item?.sortOrder)
    }, [this.allowedOnlyOneValidator()])
  }

  private allowedOnlyOneValidator(): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => {

      const approvalEmployeeValue = form.get("approvalEmployee").value;
      const approvalSubdivisionValue = form.get("approvalSubdivision").value;

      if (!approvalEmployeeValue && !approvalSubdivisionValue) {
        return { allowedOnlyOne: { needFillAllValues: true } };
      }

      if (approvalEmployeeValue && approvalSubdivisionValue) {
        return { allowedOnlyOne: { needFillOnlyOneValue: true } };
      }

      return null;
    }
  }

  /** Блокировка элементов в трилисте если они уже были добавлены */
  public blockIfApprovalSubdivisionItemDisabled(e: any, dataItem: ISubdivisionOptional) {
    if (this.isApprovalSubdivisionItemDisabled(dataItem)) {
      e.stopPropagation();
    }
  }

  /** Проверяет добавлен ли уже элемент в датасоурс */
  public isApprovalSubdivisionItemDisabled(dataItem: ISubdivisionOptional) {
    return this.dataSource?.some(d => d.approvalSubdivision?.id === dataItem.id);
  }

  private updateCanUpDownItems(val: ApprovalRouteStepComponent_DataItem) {
    if (this.dataSource && val) {
      const data = this.grid.data as ApprovalRouteStepComponent_DataItem[];
      const currentItemIndex = data.findIndex(d => d.approvalSubdivision?.id === val.approvalSubdivision?.id && d.approvalEmployee?.id == val.approvalEmployee?.id);

      this._upItem = currentItemIndex <= 0 ? null : data[currentItemIndex - 1];
      this._downItem = currentItemIndex >= data.length ? null : data[currentItemIndex + 1];
    }
  }

  private isDataItemsEquals(dataItem1: ApprovalRouteStepComponent_DataItem, dataItem2: ApprovalRouteStepComponent_DataItem) {
    dataItem1.approvalEmployee?.id === dataItem2.approvalEmployee?.id && dataItem1.approvalSubdivision?.id === dataItem2.approvalSubdivision?.id
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}


export class ApprovalRouteStepComponent_DataItem {
  constructor(
    public approvalEmployee: IApprovalRouteStepComponent_ApprovalEmployee,
    public approvalSubdivision: ISubdivisionOptional,
    public sortOrder: number
  ) { }
}

export interface IApprovalRouteStepComponent_ApprovalEmployee extends Pick<Employee, 'id'> {
  userId: number;
  fio: string;
}

export interface IApprovalRouteStepComponent_Settings {
  allUsers$: Observable<IApprovalRouteStepComponent_ApprovalEmployee[]>;
  allSubdivisions$: Observable<IApprovalRouteStepComponent_Subdivision[]>;
  getApprovalsForSubdivisions$Func: (subdivisionIds: number[]) => Observable<Array<ApprovalDTO>>;
  addApprovals$Func: (subdivisionIds: number[], dataItem: ApprovalRouteStepComponent_DataItem, isDeleteExisted: boolean) => Observable<any>;
  deleteApprovals$Func: (subdivisionIds: number[], dataItem: ApprovalRouteStepComponent_DataItem) => Observable<any>;
  reorderApprovals$Func: (subdivisionIds: number[], approval1: ApprovalRouteStepComponent_DataItem, approval2: ApprovalRouteStepComponent_DataItem) => Observable<IReorderResult>;
}

export interface IApprovalRouteStepComponent_Subdivision extends Pick<Subdivision, 'id' | 'longName'>, Partial<Pick<Subdivision, 'parentId'>> {}

export interface IReorderResult {
  current: IApprovalsBaseOptional,
  affected: IApprovalsBaseOptional,
}
