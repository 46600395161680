<kendo-splitter *ngIf="!isDone"
                style="height: 100%;"
                orientation="vertical"
>
  <kendo-splitter-pane style="margin-bottom: 5px;">
    <app-graph-table-workspace #graphTableWorkspaceComponent
                               [initGraphTableToolbarComponent] = false>
    </app-graph-table-workspace>
  </kendo-splitter-pane>

  <kendo-splitter-pane size="70px"
                       min="50px"
                       max="50%"
                       collapsible="true"
                       style="margin-top: 5px;"
  >
    <div style="display: flex; flex-wrap: nowrap; height: 100%"
         class="k-toolbar"
    >
      <div style="flex: 1; overflow: auto; min-width: 400px; height: 100%">
        <kendo-textarea [resizable]="'auto'"
                        maxlength="1000"
                        placeholder="Здесь может быть Ваш комментарий"
                        style="height: 100%; width: 100%"
                        [(value)]="comment"
                        (valueChange)="onTextAreaValueChange($event)"
        ></kendo-textarea>
      </div>

      <div style="width: 20px"></div>

      <div style="align-self: flex-end; display: flex; flex-wrap: nowrap"
           class="button-group"
           [title]="(disabledButtons | async) ? 'При просмотре другого графика/табеля управляющие кнопки не активны' : ''"
      >
        <button kendoButton
                icon="undo"
                style="margin-right: 30px;"
                (click)="onCancel()"
                [disabled]="disabledButtons | async"
        >
          Отменить согласование
        </button>
        <button kendoButton
                icon="cancel"
                primary="true"
                (click)="onDecline()"
                [disabled]="(disabledButtons | async) || !this.comment"
        >
          Отклонить
        </button>
        <button kendoButton
                icon="check"
                primary="true"
                (click)="onApproved()"
                [disabled]="disabledButtons | async"
        >
          Согласовать
        </button>
      </div>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>

<div *ngIf="isDone"
     style="text-align: center; margin: 20px;"
>
  Согласование выполнено!
</div>
