import { Injectable, OnDestroy } from "@angular/core";
import {
  CellSelectingDirectiveService,
  SelectedCellsEventObj
} from "../directives/cell-selecting.directive.service";
import {
  GraphDayViewModel,
  GraphGridRowModel
} from "../classes/view-models/row-and-graph-day-view-model.class";
import { ExtensionObj } from "../../../../../../../../src/app/helpers/extensionObj";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { ArrayHelper } from "../../../../../../../../src/app/helpers/arrayHelper";
import { DayDeviation } from "../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import { DayDeviationVM } from "../classes/view-models/day-deviation-view-model.class";
import { TimeInterval } from "../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import { TimeIntervalVM } from "../classes/view-models/time-interval-view-model.class";
import { GridToolbarManagementService } from "../../services/grid-toolbar-management.service";
import { traceClass } from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { DayDeviationPanelComponentService } from "../components/day-deviation-panel/services/day-deviation-panel-component.service";
import { TimeIntervalPanelComponentService } from "../components/time-interval-panel/services/time-interval-panel-component.service";
import { IStaffUnit } from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {StaffUnitTypeEnum} from "../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {WorkModeTypeEnum} from "../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkModeType";
import {StaffUnitVM} from "../classes/view-models/staff-unit-view-model.class";

/** Сервис для редактирования ячеек графика */
@Injectable()
@traceClass('GraphCellEditService')
export class GraphCellEditService implements OnDestroy {

  /** Имеится ли возможность вычитать обед */
  public hasLaunch: boolean = false;
  public isFlexDinner: boolean = false;

  /** Выделенные ячейки */
  public selected$ = new ReplaySubject<GraphEditService_SelectedEvent>(1);

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private cellSelectingDirectiveService: CellSelectingDirectiveService,
              private dayDeviationPanelComponentService: DayDeviationPanelComponentService,
              private timeIntervalPanelComponentService: TimeIntervalPanelComponentService,
              private gridToolbarManagementService: GridToolbarManagementService,
              private readonly tracerService: TracerServiceBase) {

    this.cellSelectingDirectiveService.selecting2$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {

      const selectedCells = GraphEditService_SelectedEvent.Create(value);
      const selectedCellsHasTimeIntervals = selectedCells.graphDays && selectedCells.graphDays.every(x => x.timeInterval);
      this.hasLaunch = selectedCellsHasTimeIntervals
        && selectedCells.graphDays.every(b => b.row.staffUnit.workMode.workModeTypeId == WorkModeTypeEnum.flexibleGraph);
      this.isFlexDinner = selectedCellsHasTimeIntervals
        && selectedCells.graphDays.every(gd => gd.row.staffUnit.workMode.workModeTypeId == WorkModeTypeEnum.flexibleGraphAndDinner);

      this.dayDeviationPanelComponentService.selectedCells$.next({
        isMultipleRow: selectedCells.isMultipleRow,
        row: !selectedCells?.row ? null : {
          workDayHourDuration: selectedCells.row.staffUnit.workMode.workDayHourDuration
        },
        get cells(): {
          timeInterval: {
            duration: number
          },
          staffUnit: Pick<IStaffUnit, 'executionDutiesFlag'>
        }[] {
          return selectedCells?.graphDays?.map(x => {
            return {
              timeInterval: !x.timeInterval ? null : {
                duration: x.timeInterval.duration
              },
              staffUnit: {
                executionDutiesFlag: x.row.staffUnit.executionDutiesFlag,
                typeId: x.row.staffUnit.staffUnitType.id
              }
            }
          });
        },
        containsDutyRow: selectedCells.graphDays?.some(x => x.row.staffUnit.staffUnitType.id === StaffUnitTypeEnum.Duty),
        containsCombinationRow: selectedCells.graphDays?.some(x =>
          x.row.staffUnit.staffUnitType.id === StaffUnitTypeEnum.CombinationInner ||
          x.row.staffUnit.staffUnitType.id === StaffUnitTypeEnum.CombinationExternal),
        containsUvorRow: selectedCells.graphDays?.some(x => x.row.staffUnit.staffUnitType.id === StaffUnitTypeEnum.Uvor),
        canSetIo: selectedCells.row ? this.canSetIo(selectedCells.row.staffUnit, gridToolbarManagementService.data.graphTable.month) : false
      })

      this.timeIntervalPanelComponentService.selectedCells$.next({
        get cells(): {
          readonly deviation: {
            name: string,
            deviationWithInterval: boolean
          }
        }[] {
          return selectedCells?.graphDays?.map(x => {
            return {
              deviation: !x.dayDeviation ? null : {
                name: x.dayDeviation.shortName,
                deviationWithInterval: x.dayDeviation.deviationWithInterval
              }
            }
          })
        }
      });

      this.selected$.next(selectedCells);
    });

    this.dayDeviationPanelComponentService.select$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.setDayDeviation(value.dayDeviation, value.isClearTimeInterval, value.customValue)
    })

    this.timeIntervalPanelComponentService.click$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.setTimeInterval(value.timeInterval, value.isClearDeviation);
    })
  }

  /** Может ли быть установлено отклонение ИО для данного StaffUnit */
  private canSetIo(staffUnit: StaffUnitVM, monthDate: Date): boolean{
    switch (staffUnit?.staffUnitType.id) {
      case StaffUnitTypeEnum.Basic:
        return true;
      case StaffUnitTypeEnum.MoonlighterInner:
      case StaffUnitTypeEnum.MoonlighterExternal:
      case StaffUnitTypeEnum.CombinationInner:
        return  !staffUnit.isInMonth(monthDate);

      default: return false;
    }
  }

  /** Устанавливаем отклонения в ячейки */
  @traceFunc()
  private setDayDeviation(dayDeviation: DayDeviation, clearTimeInterval: boolean, customValue: number) {
    if (!this.gridToolbarManagementService.isEditing) {
      throw new Error("Попытка добавления dayDeviation в ячейки графика, находясь не в режиме редактирования.")
    }

    this.selected$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      value.graphDays.forEach(x => {
        x.dayDeviation = DayDeviationVM.Create2(dayDeviation);

        //если данное отклонение не может быть с customValue и при этом оно не null, то устанавливаем его в null
        if (!dayDeviation.hasCustomValue) {
          if (x.customValue !== null) {
            x.customValue = null;
          }
        } else {
          if (customValue != null) {
            x.customValue = customValue;
          }
        }

        if (clearTimeInterval && !!x.timeInterval) {
          x.timeInterval = null;
        }

        x.onChange();
      })

      this.cellSelectingDirectiveService.repeatEvent();
    })
  }

  /** Устанавливает временной интервал в ячейку */
  @traceFunc()
  private setTimeInterval(timeInterval: TimeInterval, clearDayDeviation: boolean) {
    if (!this.gridToolbarManagementService.isEditing) {
      throw new Error("Попытка добавления timeInterval в ячейки графика, находясь не в режиме редактирования.")
    }

    this.selected$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      value.graphDays.filter(x => x?.timeInterval?.id != timeInterval?.id)
        .forEach(x => {
          x.timeInterval = !timeInterval ? null : TimeIntervalVM.Create2(timeInterval)
          if (clearDayDeviation && !!x.dayDeviation) {
            x.dayDeviation = null;
          }
          x.onChange();
        })

      this.cellSelectingDirectiveService.repeatEvent();
    })
  }

  /** Очистить ячейки */
  @traceFunc()
  public clearCells() {
    if (!this.gridToolbarManagementService.isEditing) {
      throw new Error("Попытка очистки ячеек графика, находясь не в режиме редактирования.")
    }
    this.selected$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      value.graphDays?.filter(x => !!x).forEach(x => {
        if (!!x.timeInterval || !!x.dayDeviation) {
          if (!!x.timeInterval) { x.timeInterval = null }
          if (!!x.dayDeviation) { x.dayDeviation = null }
          if (x.flexDinner) { x.flexDinner = null; }

          x.onChange();
        }
      })

      this.cellSelectingDirectiveService.repeatEvent();
    });
  }

  /** Обработка нажатия на кнопку Вычитать обед */
  @traceFunc()
  public onSubtractLunchBtnClick() {
    this.selected$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      value.graphDays.forEach(g =>
        this.setSubtractLunchFlag(g, true));
    });
  }

  /** Обработка нажатия на кнопку НЕ Вычитать обед */
  @traceFunc()
  public onSetNotLunchBtnClick() {
    this.selected$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      value.graphDays.forEach(g =>
        this.setSubtractLunchFlag(g, false));
    });
  }

  /** Установить значение поля "flexDinner" у переданного параметром {@link GraphDayViewModel} */
  @traceFunc()
  public setFlexDinnerValue(value: number){
    this.selected$.pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(x => {
        x.graphDays.forEach(graphDay => {
        graphDay.flexDinner = value;
        graphDay.onChange();
      });
    });
  }

  @traceFunc()
  private setSubtractLunchFlag(graphDay: GraphDayViewModel, subtractLunchFlagValue: boolean): void {
    graphDay.subtractLunch = subtractLunchFlagValue;
    graphDay.onChange();
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/**
 * Класс события выделения
 */
export class GraphEditService_SelectedEvent {
  /** Выделенная трока. null если не выделено или выделено несколько */
  public row: GraphGridRowModel = null;
  /** Выделенные ячейки. Не должны входить те в которых не работал staffUnit */
  public graphDays: Array<GraphDayViewModel> | null;
  /** Выделено несколько строк? */
  public isMultipleRow: boolean = false;
  /** Выделено ли */
  public isSelected: boolean = false;

  /**
   * Создать экземпляр
   * @param source
   * @constructor
   */
  public static Create(source: SelectedCellsEventObj): GraphEditService_SelectedEvent {
    return new ExtensionObj(new GraphEditService_SelectedEvent()).modResult(item => {
      item.row = !source.isSelected || source.rowRange.isMultiple ? null : source.cellViewModels[0].rowModel;
      item.graphDays = source.cellViewModels.some(x => true) && source.cellViewModels.every(x => x.cellModel instanceof GraphDayViewModel) ?
        source.cellViewModels.map(x => x.cellModel as GraphDayViewModel).filter(x => {
          return (!x.row.staffUnit.startDate || x.row.staffUnit.startDate <= x.day.date) &&
            (!x.row.staffUnit.endDate || x.row.staffUnit.endDate >= x.day.date);
        }) :
        null;
      item.graphDays = item.graphDays && item.graphDays.some(x => true) ? item.graphDays : null;
      item.isSelected = item.graphDays && item.graphDays.length > 0;
      item.isMultipleRow = item.graphDays && ArrayHelper.distinctBy(item.graphDays, x => x.row.staffUnit.ownerId).length > 1;
    });
  }
}
