import {Pipe, PipeTransform} from "@angular/core";
import {DateHelper} from "../helpers/dateHelper";

@Pipe({
  name: 'minutesToHours'
})
/**
 * Пайп преобразует минуты в часы<br>
 * Использует метод {@link DateHelper.minutesToHours}<br>
 */
export class MinutesToHoursPipe implements PipeTransform {
  transform<T>(minutes: number, digits: number = -1): number {
    return DateHelper.minutesToHours(minutes, digits);
  }
}
