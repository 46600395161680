<kendo-dialog-titlebar (close)="onClose($event)">
  Журнал учета рабочего времени с туберкулезными пациентами
</kendo-dialog-titlebar>

<app-tuber-register-grid #grid
                         [settings]="settings"
                         (currentRegisterType$)="currentRegisterType$.emit($event)"
></app-tuber-register-grid>

<kendo-dialog-actions layout="normal">
  <button kendoButton
          (click)="onClose($event)"
          [disabled]="!!grid.form"
          [primary]="true"
  >
    Закрыть
  </button>
</kendo-dialog-actions>
