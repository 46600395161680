import {Injectable, OnDestroy} from "@angular/core";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../../../../../../src/app/services/storages/custom-storage.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";

/** Сервис хранения данных компонента в SessionStorage */
@Injectable({providedIn: "root"})
@traceClass('GraphTableToolbarStorageService')
export class GraphTableToolbarStorageService implements OnDestroy{

  private _value: StorageObj = null;
  /** Хранимое значение */
  public get value(){
    return this._value;
  }
  public set value(value){
    this._value = StorageObj.Copy(value);
    this.customStorageService.set(this.storageOptions, this._value);
    this.traceService.add2('Установка поля value', {obj: value})
  }

  constructor(private readonly customStorageService: CustomStorageService,
              private readonly traceService: TracerServiceBase) {
    const storageObj = this.customStorageService.get<StorageObj>(this.storageOptions);
    this._value = StorageObj.Copy(storageObj);
  }

  /** Ключ для localStorage */
  private storageOptions: StorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'GraphTableToolbarStorageService',
    null,
    true,
    false
  )

  ngOnDestroy(): void {
  }
}

/** Хранимый объект */
class StorageObj {
  /**
   * Конструктор
   * @param subdivisionOwnerId Идентификатор подразделения
   * @param yearId Год
   * @param monthId Месяц
   */
  constructor(public readonly subdivisionOwnerId: number,
              public readonly yearId: number,
              public readonly monthId: number) {
  }

  /** Копировать */
  public static Copy(storageObj: StorageObj){
    return !storageObj ? null : new StorageObj(storageObj.subdivisionOwnerId, storageObj.yearId, storageObj.monthId)
  }
}
