import { Injectable } from '@angular/core';
import {DialogService} from "@progress/kendo-angular-dialog";
import {DisplayErrorsService} from "../../display-errors/services/display-errors.service";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {Observable, race, switchMap} from "rxjs";
import {delay, take, takeUntil} from "rxjs/operators";
import * as FileSaver from "file-saver";
import * as moment from "moment/moment";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {
  PrintQuarterSettingsWithStaffUnitTypes, PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  PrintFormQuarterSettingsWithStaffUnitTypesComponent
} from "./print-form-quarter-settings-with-staff-unit-types.component";

@Injectable()
@traceClass("PrintFormQuarterSettingsWithStaffUnitTypesService")
export class PrintFormQuarterSettingsWithStaffUnitTypesService {

  constructor(private readonly dialogService: DialogService,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly traceService: TracerServiceBase,
  ) { }

  /** Открыть диалог */
  @traceFunc()
  public showDialog(innerKey: string, name: string, subdivisionId: number,
                    requestFunc: (settings: PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes) =>
                      Observable<Blob>){
    const dialogRef = this.dialogService.open({
      title: `Настройки отчета`,
      content: PrintFormQuarterSettingsWithStaffUnitTypesComponent,
      actionsLayout: 'end',
      minHeight: '250px',
      width: '500px'
    });

    const component = dialogRef.content.instance as PrintFormQuarterSettingsWithStaffUnitTypesComponent;

    const unsubscribeRice = race(component.cancel$, component.print$)
      .pipe(delay(1));

    component.cancel$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(() => {
      dialogRef.close();
      this.traceService.add('Нажата кнопка отмены');
    });

    component.print$.pipe(take(1), takeUntil(unsubscribeRice),
      switchMap((value: PrintQuarterSettingsWithStaffUnitTypes) => {
        dialogRef.close();
        this.traceService.add2('Нажата кнопка сохранения отчёта', { obj: value });
        value.printFormInnerKey = innerKey;
        const settings = new PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes(
          value.printFormInnerKey,
          value.subPeriodName,
          value.startDate,
          value.endDate,
          false,
          [subdivisionId],
          value.staffUnitTypeIds);

        return requestFunc(settings);
      })).subscribe({
      next: resp => this.saveFile(resp, name),
      error: err => this.displayErrorService.handleError(err)
    });
  }

  /** Сохранить байтмассив в виде файла с определенным расширением */
  private saveFile(fileByteArr: Blob, name: string){
    FileSaver.saveAs(fileByteArr, `${name}_${moment().format('DD_MM_yyyy')}.xlsx`);
  }
}
