import {Injectable} from "@angular/core";
import {concatMap, defer, EMPTY, mergeWith, Observable, of, ReplaySubject, share, Subject} from "rxjs";
import {
  ArrayDataSourceIEntityId,
  DataSource
} from "../../../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {
  ArrayDataSourceIEntityIdServiceWithParamsBase
} from "../../../../../../../../../../../src/app/services/data-source-services/data-source.service";
import {
  Api1GraphControlControllerService,
  IGetGraphRowResponse
} from "../../../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {Day} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/Day";
import {
  ArrayExpanded,
  ArrayHelper,
} from "../../../../../../../../../../../src/app/helpers/arrayHelper";
import * as moment from "moment";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {CovidLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog";
import {CovidLog2} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {VichLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/VichLog";
import {TuberLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/TuberLog";
import {ExcludeMilkLog} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/ExcludeMilkLog";
import {DayType} from "../../../../../../graph-table-workspace/graph-grid/classes/view-models/day-type-view-model.class";
import {StaffUnit} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {Subdivision} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import {Occupation} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Occupation";
import {WorkMode} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkMode";
import {Position} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Position";
import {Employee} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {DbChangedListener} from "../../../../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {
  DataHasOwnerStateBuilder
} from "../../../../../../../../../../../src/app/classes/data-state-builders/data-has-owner-state-builder";
import {DataStateBuilder} from "../../../../../../../../../../../src/app/classes/data-state-builders/data-state-builder";
import {exDistinctUntilChanged} from "../../../../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import {DateHelper} from "../../../../../../../../../../../src/app/helpers/dateHelper";
import {
  GraphDataSource_DayType,
  IGraphDataSource_DataItem
} from "./graph-data-source.classes";
import {
  GraphGrid_GraphDayCellDataSourceService
} from "../graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.service";
import {GraphGridDataSource_DataItem} from "../graph-grid-data-sources/graph-grid-data-source.classes";
import {
  GraphGrid_GraphDayCell
} from "../graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.classes";
import {
  GraphGridNormFactDataSource
} from "../graph-grid-norm-fact-data-source/graph-grid-norm-fact-data-source.service";
import {
  GraphGridNormFactDataSource_Item
} from "../graph-grid-norm-fact-data-source/graph-grid-nom-fact-data-source.classes";
import {traceFunc} from "../../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TraceParamEnum
} from "../../../../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../../../../../src/app/modules/trace/decorators/param.decorator";
import {LoadingIndicatorService} from "../../../../../../../../../../../src/app/services/loading-indicator.service";
import {exLoadingMessage} from "../../../../../../../../../../../src/app/operators/ex-loading-message.operator";

/** Тип параметров источника данных */
type ParamType = {
  /** Идентификатор редакции графика */
  redactionId: number,
  /** Слушать ли изменения по signalR. К примеру если график согласован то слушать не надо */
  useSignalR: boolean,
}

/** Тип результата анализа signalR */
type SignalRResultType = {
  /** Перезагрузить все данные */
  reloadAll: boolean,
  /** Использовать ли индикатор загрузки */
  useLoadingIndicator: boolean,
  /** Список идентификаторов исполнения должности для перезагрузки */
  staffUnitIds: number[] | undefined
}

/** Дополнительная информация о данных сервиса данных */
type GraphDataSourceService_AdditionDataInfoType = {
  /** График */
  graph: IGetGraphRowResponse['graph'],
  /** Идентификатор редакции */
  redactionId: number,
  /** Дата аудита данных */
  auditDate: Date | undefined,
  /** Дни */
  days: GraphDataSource_DayType[],
}

/** Данные для юи */
type GraphDataSourceService_GridDataType = GraphDataSourceService_AdditionDataInfoType & {
  /** Строки */
  rows: GraphGridDataSource_DataItem[],
  /** {@link Map} получения родителя по {@link GraphGridDataSource_DataItem_IdClass.uid*/
  childParentMap: Map<string, GraphGridDataSource_DataItem>,
  /** Источник данных ячеек */
  cellsDataSource: GraphGrid_GraphDayCellDataSourceService,
  /** Источник данных для Норм/Факт */
  normFactDataSource: GraphGridNormFactDataSource,
}

/** Тип измененной ячейки для отправки на сервер */
type ChangedCellsRequestType = Parameters<Api1GraphControlControllerService['getGraphRow2$']>[4][0];

/** Сервис серверных данных для Графика */
@Injectable()
@traceClass('GraphDataSourceService')
export class GraphDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<ParamType, IGraphDataSource_DataItem> {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    useSignalR: new ReplaySubject<boolean>(1),
  }

  /** @inheritDoc */
  public readonly paramsDataSource: DataSource<ParamType> = new DataSource<ParamType>();
  /** @inheritDoc */
  public readonly dataSource: ArrayDataSourceIEntityId<IGraphDataSource_DataItem> = new ArrayDataSourceIEntityId<IGraphDataSource_DataItem>();

  /** Источник дополнительных данных о данных в {@link dataSource} */
  private readonly additionDataSource: DataSource<GraphDataSourceService_AdditionDataInfoType> = new DataSource<GraphDataSourceService_AdditionDataInfoType>();

  /** Источник данных для отображения в юи */
  public readonly gridDataSource: DataSource<GraphDataSourceService_GridDataType> = new DataSource<GraphDataSourceService_GridDataType>();

  /** Источник данных для ячеек дней исполнений должностей */
  public readonly graphDayCellDataSource: GraphGrid_GraphDayCellDataSourceService = new GraphGrid_GraphDayCellDataSourceService(this.traceService);

  /** Источник данных для Норма/Факт исполнений должностей */
  private readonly normFactDataSource: GraphGridNormFactDataSource = new GraphGridNormFactDataSource(this.traceService);

  /** Сервис очереди обновления нормы/факта */
  private readonly staffUnitNormFactQueueService: StaffUnitNormFactQueueService = new StaffUnitNormFactQueueService();

  /**
   * Конструктор
   */
  constructor(private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly loadingIndicatorService: LoadingIndicatorService,

              private readonly traceService: TracerServiceBase) {
    super();

    //Очищаем данные при смене параметров
    this.paramsDataSource.beforeChange$
      .subscribe(() => {
        this.staffUnitNormFactQueueService.clear();
      })

    //Связка серверного источника данных со всеми зависимыми источниками данных. Основная логика
    this.dataSource
      .observe$(undefined, (x1, x2) => x1 === x2)
      .pipe(
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe(value => {
        if(!value?.length){
          return;
        }

        /** Содержит сгруппированные данные */
        const items = (() => {
          const tempMap = new ArrayExpanded(value)
            .groupBy(x => x.state)
            .toMap(x => x.key, x => x.values);

          return {
            added: tempMap.get('added') ?? [],
            modified: tempMap.get('modified') ?? [],
            deleted: tempMap.get('deleted') ?? [],
          }
        })();

        /** Содержит текущие значения источников данных */
        const values = {
          graphGridRows: this.gridDataSource.data?.rows ?? [],
          graphDayCells: this.graphDayCellDataSource.data,
          normFactDataSource: this.normFactDataSource.data,
        }

        //Создаем необходимые строки(добавленые и модифицированные). Обязательно перед удалением строк
        const serverRowsToCreate = [...items.added, ...items.modified]
          .map(x => x.current);
        const createdGraphGridRows = GraphGridDataSource_DataItem.CreateManyFromServerData(serverRowsToCreate);

        //Удаляем строки из источников данных подлежащие модификации и удалению
        const staffUnitIdsForDelete = [...items.modified, ...items.deleted]
          .map(x => x.currentOrOrigin.id);

        if(staffUnitIdsForDelete.length > 0){
          values.graphGridRows = values.graphGridRows
            .filter(x => !staffUnitIdsForDelete.includes(x.staffUnit.id));
          values.graphDayCells = values.graphDayCells
            .filter(x => !staffUnitIdsForDelete.includes(x.staffUnitId));
          values.normFactDataSource = values.normFactDataSource
            .filter(x => !staffUnitIdsForDelete.includes(x.row.staffUnit.id))
        }

        //Добавляем строки в источники данных подлежащие модификации и добавлению
        if(serverRowsToCreate.length > 0){
          values.graphGridRows = [...values.graphGridRows, ...createdGraphGridRows];
          values.graphDayCells = [...values.graphDayCells, ...this.getCells(serverRowsToCreate)];
          values.normFactDataSource = [...values.normFactDataSource, ...this.getNormFacts(createdGraphGridRows)];
        }

        //Устанавливаем данные
        this.graphDayCellDataSource.setData(values.graphDayCells);
        this.normFactDataSource.setData(values.normFactDataSource);
        this.gridDataSource.setData({
          ...this.additionDataSource.data,
          rows: values.graphGridRows,
          childParentMap: RowHelper.getParentMap(values.graphGridRows),
          cellsDataSource: this.graphDayCellDataSource,
          normFactDataSource: this.normFactDataSource,
        });
      });
  }

  /** Метод управляет включением-отключением signalR. */
  @traceFunc()
  public enabledSignalR(value: boolean) {
    this.streams$.useSignalR.next(value);
  }

  /**
   * Редактировать ячейки.
   * @param cells ячейки для модификации
   * @param mod функция модификации. Используй готовые функции {@link GraphGrid_EditablePartGraphDayCell.setDayDeviation}, {@link GraphGrid_EditablePartGraphDayCell.setTimeInterval} и др.
   * @param updateOnlyChanged Если true, обновит в источнике данных только те ячейки, которые были изменены функцией {@link mod}
   * @return только измененные ячейки. Даже если {@link updateOnlyChanged} === false
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public editCells(@traceParam() cells: GraphGrid_GraphDayCell[],
                   mod: Parameters<typeof GraphGrid_GraphDayCell.CopyWithCurrentEditPart>[1],
                   @traceParam() updateOnlyChanged: boolean){
    const result = this.graphDayCellDataSource.edit(cells, mod, updateOnlyChanged);
    this.onCellEdited(result);

    return result;
  }

  /**
   * Очистить ячейки
   * @param cells Ячейки подлежащие очистке
   * @param updateOnlyChanged Если true, обновит в источнике данных только те ячейки, которые были изменены при очистке
   * @return только измененные ячейки. Даже если {@link updateOnlyChanged} === false
   */
  @traceFunc()
  public clearCells(cells: GraphGrid_GraphDayCell[], updateOnlyChanged: boolean){
    const result = this.graphDayCellDataSource.clear(cells, updateOnlyChanged);
    this.onCellEdited(result);

    return result;
  }

  /** Сохранить график */
  @traceFunc()
  public save$(comment: string){
    const changedCells = this.graphDayCellDataSource.changedCellsSelection.selectedItems2.data;

    if(!changedCells?.length){
      return of(true);
    }

    return this.api1GraphControlControllerService.saveGraph$(
      this.additionDataSource.data.redactionId,
      comment,
      changedCells.map(x => ({
        staffUnitId: x.staffUnitId,
        date: x.day.date,
        timeIntervalId: x.graphDayCurrent.timeInterval?.id,
        dayDeviationId: x.graphDayCurrent.dayDeviation?.id,
        dayDeviationCustomValue: x.graphDayCurrent.dayDeviationCustomValue,
        flexDinner: x.graphDayCurrent.flexDinner,
        substractLunchFlag: x.graphDayCurrent.substractLunchFlag,
      }))
    );
  }

  /** Проверить график на ошибки */
  @traceFunc()
  public checkErrors$(){
    const graph = this.additionDataSource.data.graph;

    return this.api1GraphControlControllerService.checkErrors$({subdivisionId: graph.subdivisionId, month: graph.month});
  }

  /** Добавить исключение выплаты за молоко */
  @traceFunc()
  public addExcludeMilkLog$(items: {staffUnitId: number, dates: Date[]}[]){
    return this.api1GraphControlControllerService
      .addExcludeMilkLog$(items);
  }

  /** Удалить исключение выплаты за молоко */
  @traceFunc()
  public removeExcludeMilkLog$(items: {staffUnitId: number, dates: Date[]}[]){
    return this.api1GraphControlControllerService
      .deleteExcludeMilkLog$(items);
  }

  /** Получить ячейки для всех исполнений должностей */
  private getCells(serverRows: IGraphDataSource_DataItem[]){
    const additionData = this.additionDataSource.data;

    const changedCellMap = new ArrayExpanded(this.graphDayCellDataSource.changedCellsSelection.selectedItems2.data)
      .groupBy(x => x.staffUnitId, (key, items) => ({
        staffUnitId: key,
        items: items.map(x => ({
          date: x.day.date,
          ...x.graphDayCurrent
        }))
      }))
      .toMap(x => x.staffUnitId, x => x.items);

    return new ArrayExpanded(serverRows)
      .map(serverRow => {
        return GraphGrid_GraphDayCell.CreateForStaffUnit2(
          additionData.graph,
          additionData.days,
          serverRow.subItems[0].staffUnit, //Берем первый, так как дата начала и окончания у всех будет одинаковая
          serverRow.covidLogs,
          serverRow.covidLog2s,
          serverRow.vichLogs,
          serverRow.tuberLogs,
          serverRow.excludeMilkLogs,
          serverRow.graphDays.map(x => {
            return {
              date: x.graphDay.date,
              timeInterval: x.timeInterval,
              dayDeviation: x.dayDeviation,
              dayDeviationCustomValue: x.graphDay.dayDeviationCustomValue,
              flexDinner: x.graphDay.flexDinner,
              substractLunchFlag: x.graphDay.substractLunchFlag
            }
          }),
          changedCellMap.get(serverRow.id) ?? []
        )
      })
      .flatMap(x => x)
      .array;
  }

  /** Получить данные для норм/факт */
  private getNormFacts(rows: GraphGridDataSource_DataItem[]){
    return rows
      .filter(x => !this.staffUnitNormFactQueueService.has(x.id.staffUnitId)) //Не должно быть в очереди на обновление
      .map(row => {
        return GraphGridNormFactDataSource_Item.Create(row, row.normFact);
      })
  }

  /** Пост обработка редактирования ячеек. */
  @traceFunc()
  private onCellEdited(results: ReturnType<typeof this.graphDayCellDataSource['edit']>){
    if(!results?.length){
      return;
    }

    const staffUnitIds = new ArrayExpanded(results)
      .map(x => x.newValue.staffUnitId)
      .distinct()
      .array;

    this.normFactDataSource.deleteByStaffUnitId(...staffUnitIds);
    this.staffUnitNormFactQueueService.add(staffUnitIds);
  }

  /** @inheritDoc */
  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.useSignalR.complete();
    this.gridDataSource.onDestroy();
    this.additionDataSource.onDestroy();
    this.graphDayCellDataSource.onDestroy();
    this.normFactDataSource.onDestroy();
    this.staffUnitNormFactQueueService.onDestroy();
    super.ngOnDestroy();
  }

  /**
   * Перезагрузка строк по signalR и при редактировании ячейки для пересчета нормы/фатка
   * @protected
   */
  @traceFunc()
  protected useSignalR$(): Observable<Observable<any>> | null {
    const reloadFromNormFact$ = this.staffUnitNormFactQueueService.reloadNormFact$
      .pipe(
        concatMap(staffUnitIds$ => {
         return of(
           staffUnitIds$
             .pipe(
               switchMap(staffUnitIds => this.reloadFromSignalR$(staffUnitIds))
             )
         );
        })
      );

    return reloadFromNormFact$
      .pipe(
        mergeWith(this._useSignalR$())
      );
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamType, changedGraphDays: ChangedCellsRequestType[] = undefined): Observable<IGraphDataSource_DataItem[]> {
    return this.api1GraphControlControllerService.getGraphRow2$(
      params.redactionId,
      undefined,
      true,
      true,
      changedGraphDays ?? []
    ).pipe(
      map(response => {
        const additionData: GraphDataSourceService_AdditionDataInfoType = {
          graph: response.graph,
          redactionId: params.redactionId,
          auditDate: response.auditDate,
          days: convertToDayType(response.days, response.dayTypes)
        }

        this.additionDataSource.setData(additionData);

        this.streams$.useSignalR.next(params.useSignalR); //Устанавливаем использовать ли signalR
        return RowHelper.convert(response, additionData.days, response.graph, params.redactionId);
      })
    );
  }

  /** @inheritDoc */
  protected _reloadFromRemoteByIds$(params: ParamType, targets: number[]): Observable<IGraphDataSource_DataItem[]> {
    const changedGraphDays = this.getChangedCellsForReloadItems(targets);

    return this.api1GraphControlControllerService.getGraphRow2$(
      params.redactionId,
      targets,
      false,
      false,
      changedGraphDays,
    ).pipe(
      map(response =>
        RowHelper.convert(
          response,
          this.additionDataSource.data.days,
          this.additionDataSource.data.graph,
          params.redactionId)
      )
    );
  }

  /** Метод возвращает стрим прослушки signalR */
  private _useSignalR$(): Observable<Observable<any>>{
    return this.streams$.useSignalR
      .pipe(
        exDistinctUntilChanged(undefined),
        switchMap(useSignalR => {
          if (!useSignalR) {
            return of(EMPTY);
          }

          return this.dbChangedListener.onMulti({
            subdivisions: Subdivision,
            occupations: Occupation,
            workModes: WorkMode,
            positions: Position,
            employees: Employee,
            staffUnits: StaffUnit,
            days: Day,
            covidLogs: CovidLog,
            covidLog2s: CovidLog2,
            vichLogs: VichLog,
            tuberLogs: TuberLog,
            excludeMilkLogs: ExcludeMilkLog
          }).pipe(
            map(value => {
              //----Анализ перезагрузки всех данных----
              //Анализ дней
              const intersectDays = new ArrayExpanded(value.data.days)
                .map(x => x.currentOrOrigin)
                .innerJoin(this.additionDataSource.data.days, x => x.date, x => x.date);

              if (intersectDays.left.length > 0) { //Перезагрузка всех данных
                return getResult_ReloadAll();
              }
              //Анализ подразделений
              const allSubItems = ArrayHelper.flatMapBy(this.dataSource.data, x => x.subItems);

              const subdivisions = new DataHasOwnerStateBuilder(value.data.subdivisions, allSubItems, x => x.subdivision.id).build_()
                .source
                .filter(x => x.state == 'modified') //Только модифицированные
                .filter(x => x.dataItem) //Только относящиеся к данным источника данных

              if (subdivisions.length > 0) {
                return getResult_ReloadAll();
              }

              //--------------------------------------------------
              //Анализ перегрузки строк
              const staffUnitIdsForReload: Array<number> = [
                ...new DataHasOwnerStateBuilder(value.data.occupations, allSubItems, x => x.occupation.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.data.workModes, allSubItems, x => x.workMode.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.data.positions, allSubItems, x => x.position.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.data.employees, allSubItems, x => x.employee.id).build_()
                  .source
                  .filter(x => x.state === 'modified') //Только модифицированные
                  .filter(x => x.dataItem) //Только относящиеся к данным источника данных
                  .map(x => x.dataItem.staffUnit.id),

                ...new DataHasOwnerStateBuilder(value.data.staffUnits, allSubItems, x => x.staffUnit.id).build_()
                  .source
                  //Для оптимизации можно добавить фильтрацию что диапазон попадает в месяц, сейчас изменения в любом подразделении и месяце будет делаться запрос
                  .map(x => x.signalR.currentOrOrigin.ownerId),

                ...new DataHasOwnerStateBuilder(value.data.staffUnits, allSubItems, x => x.staffUnit.parentId).build_()
                  .source
                  //Для оптимизации можно добавить фильтрацию что диапазон попадает в месяц, сейчас изменения в любом подразделении и месяце будет делаться запрос
                  .map(x => x.signalR.currentOrOrigin.ownerId),

                ...new DataStateBuilder(value.data.covidLogs, this.dataSource.data2, (l, r) => l.staffUnitId === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => +DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === +this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.data.covidLog2s, this.dataSource.data2, (l, r) => l.staffUnitId === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => +DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === +this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.data.vichLogs, this.dataSource.data2, (l, r) => l.staffUnitId === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => +DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === +this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.data.tuberLogs, this.dataSource.data2, (l, r) => l.staffUnitId === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => +DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === +this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),

                ...new DataStateBuilder(value.data.excludeMilkLogs, this.dataSource.data2, (l, r) => l.staffUnitId === r.id).build_()
                  .source
                  .filter(x => x.dataItem)//Только относящиеся к данным источника данных
                  .filter(x => +DateHelper.getStartOfMounth(x.signalR.currentOrOrigin.date) === +this.additionDataSource.data.graph.month)//Относящиеся к этому месяцу
                  .map(x => x.dataItem.id),
              ]

              return getResult_ReloadById(staffUnitIdsForReload, value.isSelfInitiator);
            }),
            map(value => {
              const stream$ = value.reloadAll
                ? this._reloadData$(this.paramsDataSource.data, this.getChangedCellsForReloadItems(undefined)) as Observable<any>
                : this.reloadFromSignalR$(value.staffUnitIds) as Observable<any>

              return stream$.pipe(
                exLoadingMessage(this.loadingIndicatorService, 'Обновление данных', () => value.useLoadingIndicator)
              )
            })
          )
        }),
      )

    /** Получить результат - перезагрузить все */
    function getResult_ReloadAll(): SignalRResultType {
      return {
        reloadAll: true,
        useLoadingIndicator: true,
        staffUnitIds: undefined
      }
    }

    /** Получить результат - перезагрузить по списку id */
    function getResult_ReloadById(ids: number[], useLoadingIndicator: boolean): SignalRResultType {
      return {
        reloadAll: false,
        useLoadingIndicator: useLoadingIndicator,
        staffUnitIds: ids
      }
    }
  }

  /** Получить данные измененных ячеек для перезагрузки элементов */
  private getChangedCellsForReloadItems(staffUnitIds: number[] | undefined){
    return this.graphDayCellDataSource
      .getChangedCells(staffUnitIds)
      .map(x => {
        const item: ChangedCellsRequestType = {
          staffUnitId: x.staffUnitId,
          date: x.day.date,
          timeIntervalId: x.graphDayCurrent.timeInterval?.id,
          dayDeviationId: x.graphDayCurrent.dayDeviation?.id,
          flexDinner: x.graphDayCurrent.flexDinner,
          substractLunchFlag: x.graphDayCurrent.substractLunchFlag,
          dayDeviationCustomValue: x.graphDayCurrent.dayDeviationCustomValue
        }

        return item;
      });
  }
}

/** Конвертирует, сортирует переданные данные в {@link DayType} */
function convertToDayType(days: IGetGraphRowResponse['days'], dayTypes: IGetGraphRowResponse['dayTypes']): GraphDataSource_DayType[] {
  return new ArrayExpanded(days)
    .leftOuterJoinGroupedRight(
      dayTypes,
      x => x.dayTypeId,
      x => x.id,
      (left, rights) => {
        const r = ArrayHelper.single(rights);
        const result: GraphDataSource_DayType = {
          id: left.id,
          dayTypeId: left.dayTypeId,
          date: left.date,
          dayType: r,
          dayInMonth: moment(left.date).date(),
        };
        return result;
      })
    .array
    .sort((a, b) => a.dayInMonth - b.dayInMonth)
}

/** Класс помощник конвертации данных сервера в тип данных источника данных */
class RowHelper {

  /** Получить {@link Map} связи proxy с родителем */
  public static getParentMap(sources: GraphGridDataSource_DataItem[]): Map<string,GraphGridDataSource_DataItem>{
    const isProxyMap = new ArrayExpanded(sources)
      .groupBy(x => typeof x.staffUnit.parentId === 'number')
      .toMap(x => x.key);

    return new ArrayExpanded(isProxyMap.get(true)?.values ?? [])
      .leftInnerJoinGroupedRight(
        isProxyMap.get(false)?.values ?? [],
        x => x.staffUnit.parentId,
        x => x.staffUnit.id,
        (left, rights) => {
          return {
            key: left.id.uid,
            parent: ArrayHelper.single(
              rights,
              x =>
                left.dumpListChangeRange.endDate >= x.dumpListChangeRange.date && left.dumpListChangeRange.endDate <= x.dumpListChangeRange.endDate //Дата окончания должна находится внутри родителя
            )
          }
        })
      .toMap(x => x.key, x => x.parent);
  }

  /** Конвертировать ответ сервера в строки источника данных */
  public static convert(source: IGetGraphRowResponse,
                        days: GraphDataSource_DayType[],
                        graph: IGetGraphRowResponse['graph'],
                        redactionId: number): IGraphDataSource_DataItem[] {
    return this.buildRows(
      source.rows,
      this.getAsMap(source),
      days,
      graph,
      redactionId
    );
  }

  /** Построить строки */
  private static buildRows(source: IGetGraphRowResponse['rows'],
                           map: ReturnType<typeof this.getAsMap>,
                           days: GraphDataSource_DayType[],
                           graph: IGetGraphRowResponse['graph'],
                           redactionId: number): IGraphDataSource_DataItem[] {
    return source
      .map(item => {
        const result: IGraphDataSource_DataItem = {
          id: item.id,
          graph: graph,
          redactionId: redactionId,
          days: days,
          graphDays: (map.graphDay.get(item.id) ?? [])
            .map(x => ({
              graphDay: x,
              timeInterval: x.timeIntervalId ? map.timeInterval.get(x.timeIntervalId) : undefined,
              dayDeviation: x.dayDeviationId ? map.dayDeviation.get(x.dayDeviationId) : undefined
            })),
          covidLogs: map.covidLogs.get(item.id) ?? [],
          covidLog2s: map.covidLog2s.get(item.id) ?? [],
          vichLogs: map.vichLogs.get(item.id) ?? [],
          tuberLogs: map.tuberLogs.get(item.id) ?? [],
          excludeMilkLogs: map.excludeMilkLogs.get(item.id) ?? [],
          subItems: item.items
            .map(sub => {
              const staffUnit = map.staffUnit.get(item.id).getDumpOr(sub.dumpListChangeRange.endDate);
              const parentStaffUnit = !staffUnit.parentId
                ? undefined
                : map.staffUnit.get(staffUnit.parentId).getDumpOr(sub.dumpListChangeRange.endDate);

              const positionId = !parentStaffUnit
                ? staffUnit.positionId
                : parentStaffUnit.positionId;
              const position = map.position.get(positionId).getDumpOr(sub.dumpListChangeRange.endDate);

              const financingSourceId = !parentStaffUnit
                ? staffUnit.financingSourceId
                : parentStaffUnit.financingSourceId;

              const subResult: IGraphDataSource_DataItem['subItems'][0] = {
                subdivision: map.subdivision.get(position.subdivisionId).getDumpOr(sub.dumpListChangeRange.endDate),
                occupation: map.occupation.get(position.occupationId).getDumpOr(sub.dumpListChangeRange.endDate),
                workMode: map.workMode.get(position.workModeId).getDumpOr(sub.dumpListChangeRange.endDate),
                position: position,
                employee: map.employee.get(staffUnit.employeeId).getDumpOr(sub.dumpListChangeRange.endDate),
                staffUnit: staffUnit,
                staffUnitType: map.staffUnitType.get(staffUnit.typeId),
                financingSource: map.financingSource.get(financingSourceId),
                data: sub
              }

              return subResult;
            })
        }

        return result;
      })
  }

  /** Получить данные как {@link Map} */
  private static getAsMap(
    source: Pick<
      IGetGraphRowResponse,
      'subdivisions' | 'occupations' | 'workModes' | 'positions' | 'employees' | 'staffUnits' | 'staffUnitTypes' |
      'financingSources' | 'timeIntervals' | 'dayDeviations' | 'graphDays' | 'covidLogs' | 'covidLog2s' |
      'vichLogs' | 'tuberLogs' | 'excludeMilkLogs'
    >
  ) {
    return {
      subdivision: new ArrayExpanded(source.subdivisions)
        .toMap(x => x.dumpItems[0].dump.id),
      occupation: new ArrayExpanded(source.occupations)
        .toMap(x => x.dumpItems[0].dump.id),
      workMode: new ArrayExpanded(source.workModes)
        .toMap(x => x.dumpItems[0].dump.id),
      position: new ArrayExpanded(source.positions)
        .toMap(x => x.dumpItems[0].dump.id),
      employee: new ArrayExpanded(source.employees)
        .toMap(x => x.dumpItems[0].dump.id),
      staffUnit: new ArrayExpanded(source.staffUnits)
        .toMap(x => x.dumpItems[0].dump.id),
      staffUnitType: new ArrayExpanded(source.staffUnitTypes)
        .toMap(x => x.id),
      financingSource: new ArrayExpanded(source.financingSources)
        .toMap(x => x.id),
      timeInterval: new ArrayExpanded(source.timeIntervals)
        .toMap(x => x.id),
      dayDeviation: new ArrayExpanded(source.dayDeviations)
        .toMap(x => x.id),
      graphDay: internal(source.graphDays, x => x.staffUnitId),
      covidLogs: internal(source.covidLogs, x => x.staffUnitId),
      covidLog2s: internal(source.covidLog2s, x => x.staffUnitId),
      vichLogs: internal(source.vichLogs, x => x.staffUnitId),
      tuberLogs: internal(source.tuberLogs, x => x.staffUnitId),
      excludeMilkLogs: internal(source.excludeMilkLogs, x => x.staffUnitId)
    }

    /** Метод помощник */
    function internal<T>(sources: T[], staffUnitIdGetter: (item: T) => number): Map<number, T[]> {
      return new ArrayExpanded(sources)
        .groupBy(staffUnitIdGetter)
        .toMap(x => x.key, item => item.values)
    }
  }
}


/** Класс отвечает за очередь обновления нормы/факта исполнения должности */
class StaffUnitNormFactQueueService{
  private readonly streams = {
    unsubscribe: new ReplaySubject<any>(1),
    /** Стрим очереди */
    queue: new Subject<Observable<number[]>>(),
    clear: new Subject<void>(),
  }

  /** Стрим необходимости перезагрузки данных Нормы/Факта у исполнений должности */
  public get reloadNormFact$(): Observable<Observable<number[]>> {
    return this.streams.queue;
  }

  /** Буфер значений */
  private readonly _set = new Set<number>();

  /** Добавить в очередь */
  public add(staffUnitIds: number[]){
    if(!staffUnitIds.length){
      return;
    }

    const setIsEmpty = this._set.size === 0;

    staffUnitIds.forEach(value => {
      this._set.add(value)
    })

    if(setIsEmpty){
      this.streams.queue.next(this.createObservable$());
    }
  }

  /** Содержится ли идентификатор в очереди на обработку */
  public has(staffUnitId: number){
    return this._set.has(staffUnitId);
  }

  /** Очистить очередь */
  public clear(){
    this._set.clear();
    this.streams.clear.next();
  }

  /** Разрушить */
  public onDestroy(){
    this.clear();
    this.streams.unsubscribe.next(null);
    this.streams.unsubscribe.complete();
    this.streams.queue.complete();
    this.streams.clear.complete();
  }

  /** Создать наблюдаемое */
  private createObservable$(): Observable<number[]>{
    return defer(() => {
      const items = Array.from(this._set.values());
      this._set.clear();

      return of(items);
    }).pipe(
      takeUntil(this.streams.clear),
      takeUntil(this.streams.unsubscribe),
      share(), //Должен не зависеть от количества подписчиков
    )
  }
}
