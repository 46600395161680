import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface ICovidLog2 extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
  /** Номер истории болезни пациента */
  diseaseNumber: string;
  /** ФИО пациента */
  patientFio: string;
}

@classBackend('CovidLog2', 'timesheet')
@className('CovidLog2')
/** Модель строки Covid журнала */
export class CovidLog2 implements ICovidLog2 {
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public staffUnitId: number,
              public date: Date,
              public diseaseNumber: string,
              public patientFio: string,
  ) {
  }

  /** Получить заполненный по умолчанию */
  public static GetEmpty(staffUnitId: number, date: Date): CovidLog2 {
    return new CovidLog2(0, null, null, false, staffUnitId, date, '', '');
  }

  private static _fullComparer: ObjComparer<ICovidLog2>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        deletedFlag: true,
        staffUnitId: true,
        date: ObjComparer.dateComparer,
        diseaseNumber: true,
        patientFio: true
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<ICovidLog2, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}
