import {EditStatusBase, IEditStatusBase} from "../timesheet/EditStatusBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IGraphEditStatus extends IEditStatusBase{

}

@classBackend('GraphEditStatus', 'timesheet_graph')
@className('GraphEditStatus')
/** POCO информации о редактировании графика */
export class GraphEditStatus extends EditStatusBase implements IGraphEditStatus{

}
