import {ChangeDetectionStrategy, Component, input, OnDestroy, OnInit, output} from '@angular/core';
import {
  DayDeviationPanel2ComponentDataSourceServiceBase
} from './services/day-deviation-panel2-component-data-source.service';
import {
  DayDeviation,
  IDayDeviation
} from '../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation';
import {traceClass} from '../../../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import {
  TracerServiceBase
} from '../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import {readonlyInput} from '../../../../../../../../../../src/app/functions/signal.functions';
import {traceFunc} from "../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-day-deviation-panel2',
  templateUrl: './day-deviation-panel2.component.html',
  styleUrl: './day-deviation-panel2.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('DayDeviationPanel2Component')
export class DayDeviationPanel2Component implements OnInit, OnDestroy {
  /** Отключена ли панель */
  public readonly disable = input<boolean>(false);
  /** Событие клика по отклонению */
  public readonly dayDeviationClick = output<DayDeviation>();
  /** Источник данных */
  public readonly dataSourceService = input.required<DayDeviationPanel2ComponentDataSourceServiceBase<any>>();

  constructor(private readonly tracerService: TracerServiceBase) {
    readonlyInput(this.dataSourceService);
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {
  }

  /** Обработка клика по отклонению */
  @traceFunc()
  protected onClick(event: IDayDeviation): void {
    this.dayDeviationClick.emit(event);
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
  }
}
