<kendo-grid [data]="gridAdministrationData"
            class="administration-grid"
            KendoGridExpanded
            (dbCellClick)="onDoubleCellClick($event)"
>

  <kendo-grid-command-column title="" [width]="60">
    <ng-template kendoGridCellTemplate let-item>
      <button kendoButton
              [primary]="true"
              (click)="onOpenBtnClick(item)"
              [icon]="'folder-open'"
              title="Открыть справочник"
      >
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-column field="name" title="Название" [width]="500">
  </kendo-grid-column>

  <kendo-grid-column field="description" title="Описание">
  </kendo-grid-column>
</kendo-grid>
