import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {DialogContentBase, DialogRef} from "@progress/kendo-angular-dialog";
import {ArrayHelper} from "src/app/helpers/arrayHelper";
import {SelectWorkingEmployeesComponentGridItem} from "../../services/select-working-employees-component-data.service";

@Component({
  selector: 'select-working-employees-dialog',
  templateUrl: 'select-working-employees-dialog.component.html',
  styleUrls: ['select-working-employees-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWorkingEmployeesDialogComponent extends DialogContentBase {

  /** Дата начала выгрузки */
  @Input() startDate: Date;

  /** Дата окончания выгрузки */
  @Input() endDate: Date;

  /** Дата формирования выгрузки */
  @Input() forDate: Date;

  /** Выбранные сотрудники */
  @Input() employeesIds: number[];

  public selectedItems: SelectWorkingEmployeesComponentGridItem[];

  public isCanSave: boolean = false;

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  public onCancelAction(): void {
    this.dialog.close();
  }

  public onConfirmAction(): void {
    this.dialog.close({text:"Выбрать", primary: true});
  }

  public onItemsSelected(items: SelectWorkingEmployeesComponentGridItem[]) {
    this.isCanSave = items?.length > 0 && !ArrayHelper.equals(items.map(i=> i.id), this.employeesIds)
    this.selectedItems = items;
  }
}
