import {Injectable} from "@angular/core";
import {defer, Observable, of} from "rxjs";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {Api1StaffListSettingsControllerService} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service";
import {
  AddCombinationFree$Param,
  AddMoonlighter$Param,
  Api1GraphControlControllerService, EditCombinationFree$Param, EditMoonlighter$Param
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {StaffUnit} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {Api1EmployeeControllerService} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-controller.service";
import {map} from "rxjs/operators";
import {Employee} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {
  Api1StaffUnitTypeControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {IStaffUnitType} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnitType";

@Injectable()
export class EditMoonlighterComponentDataService{

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly settingsService: Api1StaffListSettingsControllerService,
              private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly api1EmployeeControllerService: Api1EmployeeControllerService,
              private readonly api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService) {
  }

  /** Получение данных о сотруднике */
  public getEmployeeByOwnerId$(employeeId: number, start: Date, end: Date): Observable<Employee>{
    return defer(() => {
      return !employeeId ?
        of(null) :
        this.loadingIndicatorService.addToObservable(
          'Получение данных сотрудника',
          this.api1EmployeeControllerService.getForDate$(
            end, start, end, [employeeId]
          ).pipe(map(x => x[0]))
        )
    })
  }

  /** Получение типов совместительств */
  public getStaffUnitTypeByIds$(ids: number[]): Observable<IStaffUnitType[]>{
    return this.loadingIndicatorService.addToObservable(
      'Получение типов совместительств',
      this.api1StaffUnitTypeControllerService.getByIds$(ids)
    )
  }


  /** Получение шага ставки */
  public rateStep$(){
    return this.loadingIndicatorService.addToObservable(
      'Получение шага ставки',
      this.settingsService.rateStepWithCash$
    )
  }

  /** Добавление Moonlighter */
  public addMoonlighter$(param: AddMoonlighter$Param){
    return this.addStaffUnitBase$(this.api1GraphControlControllerService.addMoonlighter$(param))
  }

  /** Добавление Combination на свободную ставку в БД */
  public addCombinationFree$(param: AddCombinationFree$Param){
    return this.addStaffUnitBase$(this.api1GraphControlControllerService.addCombinationFree$(param))
  }

  /** Добавление УВОР на свободную ставку в БД */
  public addUvorFree$(param: AddCombinationFree$Param){
    return this.addStaffUnitBase$(this.api1GraphControlControllerService.addUvorFree$(param))
  }

  /** Редактирование Moonlighter */
  public editMoonlighter$(param: EditMoonlighter$Param){
    return this.editStaffUnitBase$(this.api1GraphControlControllerService.editMoonlighter$(param))
  }

  /** Редактирование Combination на свободную ставку */
  public editCombinationFree$(param: EditCombinationFree$Param){
    return this.editStaffUnitBase$(this.api1GraphControlControllerService.editCombinationFree$(param))
  }

  /** Редактирование Combination на свободную ставку */
  public editUvorFree$(param: EditCombinationFree$Param){
    return this.editStaffUnitBase$(this.api1GraphControlControllerService.editUvorFree$(param))
  }


  /** Базовый метод добавления сотрудника */
  private addStaffUnitBase$(addedStaffUnit$: Observable<StaffUnit>){
    return this.loadingIndicatorService.addToObservable('Добавление', addedStaffUnit$)
  }

  /** Базовый метод редактирования сотрудника */
  private editStaffUnitBase$(editedStaffUnit$: Observable<StaffUnit>){
    return this.loadingIndicatorService.addToObservable(
      'Сохранение редактирования',
      editedStaffUnit$
    );
  }
}
