import {GetGraphModelResult_Directories_DayDeviation} from "../../../../../Classes/GetGraphModelResult";
import {
  DayDeviation,
  IDayDeviation
} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";

export class DayDeviationVM implements Pick<IDayDeviation, 'id' | 'name' | 'color' | 'isIncludeInCalculate' | 'hasCustomValue' | 'deviationWithInterval' | 'shortName'>{
  constructor(
    public id: number,
    public name: string,
    public color: string,
    public isIncludeInCalculate: boolean,
    public hasCustomValue: boolean,
    public deviationWithInterval: boolean,
    public shortName: string,
  ) {
  }


  public static Create(source: GetGraphModelResult_Directories_DayDeviation): DayDeviationVM {
    return new DayDeviationVM(
      source.id,
      source.name,
      source.color,
      source.isIncludeInCalculate,
      source.hasCustomValue,
      source.deviationWithInterval,
      source.shortName)
  }

  public static Create2(source: DayDeviation){
    return new DayDeviationVM(
      source.id,
      source.name,
      source.color,
      source.isIncludeInCalculate,
      source.hasCustomValue,
      source.deviationWithInterval,
      source.shortName)
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_DayDeviation>): Array<DayDeviationVM> {
    return source.map(item => DayDeviationVM.Create(item));
  }

  /**
   * Сравнение
   */
  public static IsEquals(obj1: DayDeviationVM, obj2: DayDeviationVM): boolean{
    if(!obj1 && !obj2){
      return true;
    }

    if(!obj1 || !obj2){
      return false;
    }

    return obj1.id == obj2.id;
  }
}
