import {Observable, of, switchMap} from "rxjs";
import { DataSource } from "src/app/classes/array-data-sources/data-source";
import {
  DataSourceWithParamsBase
} from "../../../../../../../../../src/app/services/data-source-services/data-source.service";
import {
  RedactionServiceGetResponse
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {DbChangedListener} from "../../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {Graph} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/Graph";
import {GraphRoute} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphRoute";
import {GraphRedaction} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphRedaction";
import {GraphEditStatus} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphEditStatus";
import {filter, map} from "rxjs/operators";
import {Table} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_table/Table";
import {TableRedaction} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_table/TableRedaction";
import {TableRoute} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_table/TableRoute";
import {TableEditStatus} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_table/TableEditStatus";
import {
  Api1RedactionGraphService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-graph.service";
import {Injectable} from "@angular/core";
import {
  Api1RedactionTableService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/api1-redaction-table.service";
import {exLoadingMessage} from "../../../../../../../../../src/app/operators/ex-loading-message.operator";
import {LoadingIndicatorService} from "../../../../../../../../../src/app/services/loading-indicator.service";


/** Тип 1 параметров */
type ParamType1 = { graphOrTableId: number };
/** Тип 2 параметров */
type ParamType2 = { subdivisionOwnerId: number, yearId: number, monthId: number };

/** Тип - любой из параметров */
type AllParamType = ParamType1 | ParamType2;

/** Базовый класс для Гроупера редакций */
export abstract class RedactionGrouperDataSourceServiceBase<TParams extends AllParamType> extends DataSourceWithParamsBase<TParams, RedactionServiceGetResponse> {
  public readonly paramsDataSource: DataSource<TParams> = new DataSource<TParams>();
  public readonly dataSource: DataSource<RedactionServiceGetResponse> = new DataSource<RedactionServiceGetResponse>();

  /** Отправить на согласование */
  public abstract toApproving$(redactionId: number, comment: string): Observable<void>;
  /** Вернуть с согласования */
  public abstract fromApproving$(redactionId: number): Observable<void>;
  /** Копировать редакцию */
  public abstract copy$(redactionId: number): Observable<void>;
}

/** Базовый класс Гроупера редакций графика */
export abstract class RedactionGrouperDataSourceServiceGraphBase<TParam extends AllParamType> extends RedactionGrouperDataSourceServiceBase<TParam> {

  protected constructor(private readonly dbChangedListener: DbChangedListener,
                        public readonly api1RedactionGraphService: Api1RedactionGraphService,
                        private readonly loadingIndicatorService: LoadingIndicatorService) {
    super();
  }

  /** @inheritDoc */
  public toApproving$(redactionId: number, comment: string): Observable<void> {
    return this.api1RedactionGraphService.toApproving$(redactionId, comment);
  }
  /** @inheritDoc */
  public fromApproving$(redactionId: number): Observable<void> {
    return this.api1RedactionGraphService.fromApproving$(redactionId)
  }
  /** @inheritDoc */
  public copy$(redactionId: number) {
    return this.api1RedactionGraphService
      .copy$(redactionId)
      .pipe(
        map(value => {})
      );
  }

  /** @inheritDoc */
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
      graphs: Graph,
      redactions: GraphRedaction,
      routes: GraphRoute,
      editStatuses: GraphEditStatus,
    }).pipe(
      map(value => ({
        data: value.data,
        currentGraphTableId: this.dataSource.data?.graphTable?.id,
        useLoadingIndicator: value.isSelfInitiator,
      })),
      filter(value => typeof value.currentGraphTableId === 'number'), //Если установлены данные
      map(value => {
        //Проверка графика
        if(value.data.graphs.some(x => x.currentOrOrigin.id === value.currentGraphTableId)){
          return value;
        }

        //Проверка статуса редактирования. Обрабатываем только добавленные или удаленные. Так как updateLastActivity приводит к перезагрузке
        if(value.data.editStatuses.some(x => x.state !== 'modified' && x.currentOrOrigin.targetFkId === value.currentGraphTableId)){
          return value;
        }

        //Проверка на редакции
        if(value.data.redactions.some(x => x.currentOrOrigin.workTimeSheetId === value.currentGraphTableId)){
          return value;
        }

        const currentRedactionIdSet = new Set<number>(this.dataSource.data.redactions.map(x => x.redaction.id));
        //Проверка на маршруты к существующим редакциям
        if(value.data.routes.some(x => currentRedactionIdSet.has(x.currentOrOrigin.redactionId))){
          return value;
        }

        return undefined;
      }),
      filter(x => !!x),
      switchMap(value => of(
        this.updateData$()
          .pipe(
            exLoadingMessage(this.loadingIndicatorService, 'Обновление данных редакций', () => value.useLoadingIndicator)
          )
      ))
    )
  }
}

@Injectable()
/** Сервис источника данных гроупера редакций для графика по идентификатору графика */
export class RedactionGrouperDataSourceServiceGraph1 extends RedactionGrouperDataSourceServiceGraphBase<ParamType1> {

  constructor(dbChangedListener: DbChangedListener,
              api1RedactionGraphService: Api1RedactionGraphService,
              loadingIndicatorService: LoadingIndicatorService) {
    super(dbChangedListener, api1RedactionGraphService, loadingIndicatorService);
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamType1): Observable<RedactionServiceGetResponse> {
    return this.api1RedactionGraphService.get3$(params.graphOrTableId);
  }
}

@Injectable()
/** Сервис источника данных гроупера редакций для графика по идентификатору подразделения, году, месяцу */
export class RedactionGrouperDataSourceServiceGraph2 extends RedactionGrouperDataSourceServiceGraphBase<ParamType2> {

  constructor(dbChangedListener: DbChangedListener,
              api1RedactionGraphService: Api1RedactionGraphService,
              loadingIndicatorService: LoadingIndicatorService) {
    super(dbChangedListener, api1RedactionGraphService, loadingIndicatorService);
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamType2): Observable<RedactionServiceGetResponse> {
    return this.api1RedactionGraphService.get4$(params.subdivisionOwnerId, params.yearId, params.monthId);
  }
}

/** Базовый класс Гроупера редакций табеля */
export abstract class RedactionGrouperDataSourceServiceTableBase<TParam extends AllParamType> extends RedactionGrouperDataSourceServiceBase<TParam> {

  protected constructor(private readonly dbChangedListener: DbChangedListener,
                        public readonly api1RedactionTableService: Api1RedactionTableService) {
    super();
  }

  /** @inheritDoc */
  public toApproving$(redactionId: number, comment: string): Observable<void> {
    return this.api1RedactionTableService.toApproving$(redactionId, comment);
  }
  /** @inheritDoc */
  public fromApproving$(redactionId: number): Observable<void> {
    return this.api1RedactionTableService.fromApproving$(redactionId);
  }
  /** @inheritDoc */
  public copy$(redactionId: number) {
    return this.api1RedactionTableService
      .copy$(redactionId)
      .pipe(
        map(value => {})
      );
  }

  /** @inheritDoc */
  protected useSignalR$(): Observable<Observable<any>> | null {
    return  this.dbChangedListener.onMulti({
      tables: Table,
      redactions: TableRedaction,
      routes: TableRoute,
      editStatuses: TableEditStatus,
    }).pipe(
      map(value => value.data),
      map(value => ({data: value, currentGraphTableId: this.dataSource.data?.graphTable?.id})),
      filter(value => typeof value.currentGraphTableId === 'number'), //Если установлены данные
      map(value => {
        //Проверка табеля
        if(value.data.tables.some(x => x.currentOrOrigin.id === value.currentGraphTableId)){
          return true;
        }

        //Проверка статуса редактирования
        if(value.data.editStatuses.some(x => x.currentOrOrigin.targetFkId === value.currentGraphTableId)){
          return true;
        }

        //Проверка на редакции
        if(value.data.redactions.some(x => x.currentOrOrigin.workTimeSheetId === value.currentGraphTableId)){
          return true;
        }

        const currentRedactionIdSet = new Set<number>(this.dataSource.data.redactions.map(x => x.redaction.id));
        //Проверка на маршруты к существующим редакциям
        if(value.data.routes.some(x => currentRedactionIdSet.has(x.currentOrOrigin.redactionId))){
          return true;
        }

        return false;
      }),
      filter(x => x),
      switchMap(value => of(this.updateData$()))
    )
  }
}

@Injectable()
/** Сервис источника данных гроупера редакций для графика по идентификатору графика */
export class RedactionGrouperDataSourceServiceTable1 extends RedactionGrouperDataSourceServiceTableBase<ParamType1> {

  constructor(dbChangedListener: DbChangedListener,
              api1RedactionTableService: Api1RedactionTableService) {
    super(dbChangedListener, api1RedactionTableService);
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamType1): Observable<RedactionServiceGetResponse> {
    return this.api1RedactionTableService.get3$(params.graphOrTableId);
  }
}

@Injectable()
/** Сервис источника данных гроупера редакций для графика по идентификатору графика */
export class RedactionGrouperDataSourceServiceTable2 extends RedactionGrouperDataSourceServiceTableBase<ParamType2> {

  constructor(dbChangedListener: DbChangedListener,
              api1RedactionTableService: Api1RedactionTableService) {
    super(dbChangedListener, api1RedactionTableService);
  }

  /** @inheritDoc */
  protected _reloadData$(params: ParamType2): Observable<RedactionServiceGetResponse> {
    return this.api1RedactionTableService.get4$(params.subdivisionOwnerId, params.yearId, params.monthId);
  }
}
