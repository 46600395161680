import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import {process, SortDescriptor, State} from "@progress/kendo-data-query";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {take} from "rxjs/operators";
import {throwError} from "rxjs";
import {CellClickEvent, GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {
  Api1StaffUnitsControlControllerService,
  StaffUnitForProxyList
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";
import {CellClickExpandedEvent} from "src/app/services/directives/grid-treelist-expanded-directive.service";


@Component({
  selector: 'app-staffunit-grid',
  templateUrl: './staffunit-grid.component.html',
  styleUrls: ['./staffunit-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaffunitGridComponent implements OnInit {

  @Input() proxyStartDate: Date;
  @Input() proxyEndDate: Date;
  /**
   * Событие клика по строке
   */
  @Output() public onceClick: EventEmitter<StaffUnitForProxyList> = new EventEmitter<StaffUnitForProxyList>();

  /**
   * Событие двойного клика по строке
   */
  @Output() public doubleClick: EventEmitter<StaffUnitForProxyList> = new EventEmitter<StaffUnitForProxyList>();

  private clickedRowItem: StaffUnitForProxyList;
  private _source: Array<StaffUnitForProxyList>;
  public gridView: GridDataResult;
  public canFiltering: boolean;

  public pageSize = 50;
  public skip = 0;

  /**
   * порядок сортировки по умолчанию (при загрузке и при отсутствии сортировки)
   */
  public state: State = {
    sort: [{
      field: 'occupationName', dir: "asc"
    }]
  };

  public searchValue: string;

  constructor(private staffUnitsControlService: Api1StaffUnitsControlControllerService,
              private loadingIndicatorService: LoadingIndicatorService,
              public readonly changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadingIndicatorService.addToObservable(
      'Загрузка списка сотрудников',
      this.staffUnitsControlService.getStaffUnitsForProxy$(this.proxyStartDate, this.proxyEndDate)
    ).pipe(take(1)).subscribe({
      next: value => {
        this._source = value;
        this.canFiltering = true;
        this.loadSource();
      },
      error: error => {
        throwError(() => error);
      }
    });

    this.searchValue = '';
  }

  onDblClick($event: CellClickExpandedEvent<CellClickEvent>) {
    if ($event.originalEvent.dataItem) {
      this.doubleClick.emit($event.originalEvent.dataItem);
    }
  }

  onCellClick($event: CellClickEvent) {
    this.clickedRowItem = $event.dataItem;
    this.onceClick.emit(this.clickedRowItem);
  }

  sortChange($event: Array<SortDescriptor>) {
    this.state.sort = $event;
    this.loadSource();
  }

  filterSource() {
    this.state.filter = {
      logic: 'or',
      filters: [
        { field: 'occupationName', operator: 'contains', value: this.searchValue },
        { field: 'employeeFullName', operator: 'contains', value: this.searchValue },
        { field: 'subdivisionName', operator: 'contains', value: this.searchValue, ignoreCase: false },
        { field: 'code', operator: 'contains', value: this.searchValue, ignoreCase: false }
      ]
    };

    this.skip = 0;
    this.loadSource();
  }

  pageChange({ skip }: PageChangeEvent) {
    this.skip = skip;
    this.loadSource();
  }

  private loadSource(): void {
    const data = process(this._source, this.state).data;

    this.gridView = {
      data: data.slice(this.skip, this.skip + this.pageSize),
      total: data?.length || this._source.length
    }

    this.changeDetectorRef.detectChanges();
  }
}
