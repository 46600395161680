import {Pipe, PipeTransform} from "@angular/core";
import {CellDataDirective} from "../components/graph-grid2/graph-grid2.component";
import {WorkModeTypeEnum} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkModeType";

/** Результат пайпа */
type TransformResultType = {
  /** Отключена ли кнопка */
  disabled: boolean,
  /** Ячейки подлежащие установке */
  cellDirectives: CellDataDirective[],
}

/** Пайп трансвормирует выделенные ячейки графика в объект управления кнопкой Обед */
@Pipe({
  name: 'graphCustomLunchButton',
})
export class GraphCustomLunchButtonPipe implements PipeTransform {
  transform(selectedCells: CellDataDirective[]): TransformResultType {
    if(!selectedCells?.length) {
      return undefined;
    }

    if(selectedCells.some(x => x.row.workMode.workModeTypeId !== WorkModeTypeEnum.flexibleGraphAndDinner)){
      return undefined;
    }

    const directives = selectedCells.filter(x => x.dayCell.cell.graphDayCurrent.timeInterval);

    return {
      disabled: !directives.length,
      cellDirectives: directives
    }
  }
}
