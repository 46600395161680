import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WebApi1Service } from "../web-api1.service";
import { Observable, ReplaySubject } from "rxjs";
import { CovidLog2 } from "src/app/classes/domain/POCOs/timesheet/CovidLog2";

@Injectable({
  providedIn: "root"
})
export class Api1Covid2ControllerService implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private httpClient: HttpClient, private webApi1Service: WebApi1Service) { }

  /** Добавить запись о случае работы с covid */
  public addCovidLog$(model: IAddCovidLog2Request): Observable<CovidLog2> {
    return this.httpClient.post<CovidLog2>(
      this.webApi1Service.controllers.covid2.actions.addCovidLog.toString(),
      model
    );
  }

  /** Редактирование записи о случае работы с covid */
  public editCovidLog$(model: IEditCovidLog2Request): Observable<CovidLog2> {
    return this.httpClient.post<CovidLog2>(
      this.webApi1Service.controllers.covid2.actions.editCovidLog.toString(),
      model
    );
  }

  /** Удалить запись о случае работы с covid */
  public removeCovidLog$(id: number): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.covid2.actions.removeCovidLog.toString(),
      { id }
    );
  }

  /** Получить случаи работы сотрудника с covid на дату */
  public getCovidLogs$(staffUnitOwnerIds: Array<number>, date: Date): Observable<Array<CovidLog2>> {
    return this.httpClient.post<Array<CovidLog2>>(
      this.webApi1Service.controllers.covid2.actions.getCovidLogs.toString(),
      { staffUnitOwnerIds, date }
    );
  }

  /** Может ли пользователь редактировать добавлять удалять данные на дату */
  public canAddEditRemove$(date: Date): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.covid2.actions.canAddEditRemove.toString(),
      { date }
    );
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

export interface IAddCovidLog2Request extends ICovidLog2EditingProperty {
  date: Date
}

export interface IEditCovidLog2Request extends ICovidLog2EditingProperty {
  id: number
}

export interface ICovidLog2EditingProperty {
  staffUnitId: number,
  diseaseNumber: string,
  patientFio: string
}
