import {
  ArrayDataSourceHasId, DataSource
} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {
  RedactionBaseService_Type, RedactionServiceGetResponse,
  RedactionServiceGetResponse_Redaction
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {
  ArrayDataSourceServiceWithParamsBase
} from "../../../../../../../../../src/app/services/data-source-services/data-source.service";
import {Observable, of} from "rxjs";
import {RedactionGrouperDataSourceServiceBase} from "./redaction-grouper-data-source.service";

/** Класс источника данных для таблицы редакций */
export class RedactionGridDataSource extends ArrayDataSourceHasId<RedactionServiceGetResponse_Redaction, number> {
  constructor(public readonly type: RedactionBaseService_Type) {
    super(x => x.redaction.id);
  }
}

/** Базовый класс сервиса источника данных для таблицы редакций */
export abstract class RedactionGridDataSourceServiceBase<TParams> extends ArrayDataSourceServiceWithParamsBase<TParams, RedactionServiceGetResponse_Redaction> {
  /** @inheritDoc */
  public readonly dataSource: RedactionGridDataSource;

  protected constructor(public readonly type: RedactionBaseService_Type) {
    super()

    this.dataSource = new RedactionGridDataSource(this.type);
  }
}

/**
 * Класс сервиса источника данных для таблицы редакций.<br>
 * Данные строятся на основе {@link RedactionServiceGetResponse}.<br>
 * Для получения используй любую реализацию {@link RedactionGrouperDataSourceServiceBase}.<br>
 * Данный сервис НЕ использует signalR.<br>
 */
export class RedactionGridDataSource1Service extends RedactionGridDataSourceServiceBase<RedactionServiceGetResponse> {
  /** @inheritDoc */
  public readonly paramsDataSource: DataSource<RedactionServiceGetResponse> = new DataSource<RedactionServiceGetResponse>();

  constructor(type: RedactionBaseService_Type) {
    super(type);
  }

  /** @inheritDoc */
  protected _reloadData$(params: RedactionServiceGetResponse): Observable<RedactionServiceGetResponse_Redaction[]> {
    if(!params){
      return of([])
    }

    if(params.type !== this.type){
      throw new Error(`Для формирования данны параметры, где тип === ${params.type}. При этом данный сервис имеет тип === ${this.type}`);
    }

    return of(params.redactions);
  }
}
