import {Injectable} from "@angular/core";
import {traceClass} from "../../../../modules/trace/decorators/class.decorator";
import {DialogService} from "@progress/kendo-angular-dialog";
import {TracerServiceBase} from "../../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {DisplayErrorsService} from "../../../display-errors/services/display-errors.service";
import {traceFunc} from "../../../../modules/trace/decorators/func.decorator";
import {delay, Observable, race, ReplaySubject} from "rxjs";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {
  ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent
} from "../report-settings-with-timeintervals-and-subdivision-tree-list.component";
import {ArrayDataSourceSelection} from "../../../../classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../../classes/domain/POCOs/stafflist/Subdivision";
import {take, takeUntil} from "rxjs/operators";
import {exErrorHandler} from "../../../../operators/ex-error-handler";
import { ReportSettingsWithSubdivisionList
} from "../../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {trace} from "../../../../modules/trace/operators/trace";
import * as FileSaver from "file-saver";
import * as moment from "moment/moment";


@Injectable({
  providedIn: 'root'
})
@traceClass('ReportSettingsWithTimeintervalsAndSubdivisionListDialogService')
export class ReportSettingsWithTimeintervalsAndSubdivisionListDialogService {

  private unsubscribe$ = new ReplaySubject(1);

  constructor(private readonly dialogService: DialogService,
              private readonly traceService: TracerServiceBase,
              private readonly displayErrorService: DisplayErrorsService) {
  }

  @traceFunc()
  showDialog(innerKey: string, text: string, requestObsFunc: (settings: any) => Observable<Blob>,
             dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>) {
    const dialogRef = this.dialogService.open({
      title: `Настройки печати "${text}"`,
      content: ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent,
      maxWidth: '90%',
      width: 700,
      maxHeight: '90%'
    });

    const component = dialogRef.content.instance as ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent;

    let selection = new ArrayDataSourceSelection<ISubdivision, number>(dataSourceService.dataSource);

    component.subdivisionDataSourceService = dataSourceService;
    component.selection = selection;

    component.dateChange$.pipe(takeUntil(component.unsubscribe$))
      .subscribe({
        next: value => {
          component.subdivisionDataSourceService
            .reloadData$(value)
            .pipe(exErrorHandler(this.displayErrorService), take(1))
            .subscribe();
        }
      });

    const unsubscribeRace = race(this.unsubscribe$, component.cancel$, component.print$)
      .pipe(delay(1));

    component.cancel$.pipe(take(1), takeUntil(unsubscribeRace))
      .subscribe(value => {
        component.subdivisionDataSourceService.ngOnDestroy();
        component.selection.onDestroy();
        dialogRef.close();
        this.traceService.add('Нажата кнопка отмены');
      });

    component.print$.pipe(take(1), takeUntil(unsubscribeRace)).subscribe(value => {
      component.subdivisionDataSourceService.ngOnDestroy();
      component.selection.onDestroy();
      dialogRef.close();
      this.traceService.add2('Нажата кнопка формирования отчёта', {obj: value});
      value.innerKey = innerKey;

      requestObsFunc(new ReportSettingsWithSubdivisionList(
        innerKey, value.reportDate, value.subdivisionIdList,
        value.reportTimeIntervalList, value.considerLastMinutFlag))
        .pipe(trace(this.traceService))
        .subscribe({
          next: resp => {
            FileSaver.saveAs(resp, `${text}_${moment().format('DD_MM_yyyy')}.xlsx`);
          },
          error: err => {
            this.displayErrorService.handleError(err);
          }
        });
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
