<ng-container *ngIf="(formObj$ | async) as formObjAsync">
  <kendo-splitter *ngIf="formObjAsync.form"
                  [formGroup]="formObjAsync.form"
                  orientation="vertical"
                  style="height: 100%; width: 100%; border: none"
  >
    <kendo-splitter-pane style="margin-bottom: 5px;">
      <div class="content" style="flex-grow: 1; height: 100%">
        <div class="label" style="align-self: flex-start; padding-top: 7px;">Вакантные ставки:</div>
        <app-free-rate-grid style="overflow: hidden; min-height: 150px;"></app-free-rate-grid>

        <div class="label">Сотрудник:</div>
        <div style="display: flex; flex-wrap: nowrap; width: 100%;">
          <kendo-textbox [readonly]="true"
                         style="flex-grow: 1"
                         [value]="employeeTextBoxValue"
          >
          </kendo-textbox>
          <button *ngIf="settings.type == 'add'"
                  kendoButton
                  [disabled]="!formObjAsync.controls.freeInterval?.valid"
                  (click)="onEmployeeSelecting()"
          >...</button>
        </div>

        <div class="label">Количество ставок:</div>
        <kendo-numerictextbox style="width: 100px"
                              formControlName="rate"
                              KendoNumericExpanded
                              readonlyInput = true
                              [step]=rateStep
                              [decimals]="2"
                              format="0.00"
                              [autoCorrect]="true"
                              [min]=rateStep
                              [max]="rateMax"
        >

        </kendo-numerictextbox>

        <div *ngIf="settings.combinationType !== 'moonlighter'" class="label">Размер выплаты (%):</div>
        <kendo-numerictextbox *ngIf="settings.combinationType !== 'moonlighter'"
                              style="width: 100px"
                              formControlName="percent"
                              [step]="1"
                              [decimals]="2"
                              format="0.##"
                              [autoCorrect]="true"
                              [min]="0"
                              [max]="percentMax"
        >
        </kendo-numerictextbox>

        <div class="label">Срок действия:</div>
        <kendo-daterange>
          <div style="display: grid; grid-template-columns: 35px auto 10px 35px auto 1fr; grid-template-rows: auto">
            <span class="with-to-span">с:</span>
            <kendo-dateinput class="date-range-item"
                             kendoDateRangeStartInput
                             readonly="true"
                             formControlName="start"
                             [min]="minRange"
            >
            </kendo-dateinput>
            <div></div>
            <span class="with-to-span">по:</span>
            <kendo-dateinput class="date-range-item"
                             kendoDateRangeEndInput
                             readonly="true"
                             formControlName="end"
                             [max]="maxRange"
            >
            </kendo-dateinput>
          </div>
        </kendo-daterange>

        <div style="justify-self: right; align-self: center;"
             class="vertical-text-center-align">
          Количество часов:
        </div>

        <div style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
          <kendo-numerictextbox
            readonly="true"
            [spinners]="false"
            formControlName="proxyHours">
          </kendo-numerictextbox>
          <div style="margin-left: 20px;">
            Количество календарных дней:
          </div>
          <kendo-numerictextbox
            style="margin-left: 10px;"
            readonly="true"
            [spinners]="false"
            formControlName="proxyDays"
            format="n0">
          </kendo-numerictextbox>
        </div>

        <div class="label">Вид занятости:</div>
        <kendo-dropdownlist style="width: 280px"
                            [data]="typeEmployments"
                            [valueField]="'id'"
                            [textField]="'text'"
                            formControlName="typeEmployment"
        >
        </kendo-dropdownlist>

        <div *ngIf="settings.combinationType == 'moonlighter'"
             style="line-height: 1.2; text-align: right;">
          Участвует в выплате компенсации за молоко:
        </div>
        <input kendoCheckBox
               *ngIf="settings.combinationType == 'moonlighter'"
               style="align-self: center;"
               type="checkbox"
               [indeterminate]="false"
               formControlName="milk">

        <div class="label">Комментарий:</div>
        <textarea kendoTextArea
                  [autoSize]="true"
                  maxlength="100"
                  formControlName="comment"
                  style="width: 100%"
        ></textarea>

      </div>
    </kendo-splitter-pane>

    <kendo-splitter-pane [resizable]="false" style="height: auto; margin-top: 5px;">
      <div class="k-form-buttons"
           style="display: flex; justify-content: flex-end; width: 100%"
      >
        <button kendoButton
                (click)="onCancel()"
        >
          Отмена
        </button>
        <button kendoButton
                primary="true"
                (click)="onSave()"
                [disabled]="!formObjAsync.form.valid || formObjAsync.form.pristine"
        >
          Сохранить
        </button>
      </div>
    </kendo-splitter-pane>
  </kendo-splitter>
</ng-container>
