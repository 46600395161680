/** Необходимо для локализации kendo  */
import {IntlModule} from "@progress/kendo-angular-intl";
import '@progress/kendo-angular-intl/locales/ru/all'
import {MessageService} from "@progress/kendo-angular-l10n";
import {KendoMessageService} from "../../../../src/app/services/kendo-message.service";
/** --------------------------------- */

import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from "../../../../src/app/modules/auth/auth.module";
import {environment} from "../environments/environment";
import {EnvironmentService} from "../../../../src/app/services/environment.service";
import {JWT_OPTIONS, JwtConfig, JwtModule} from "@auth0/angular-jwt";
import {AuthService} from "../../../../src/app/modules/auth/services/auth.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProgressBarModule} from "@progress/kendo-angular-progressbar";
import {SharedComponentModule} from "../../../../src/app/modules/shareds/shared-component/shared-component.module";
import {DrawerModule, LayoutModule} from "@progress/kendo-angular-layout";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {GraphTableWorkspaceComponent} from './components/graph-table-workspace/graph-table-workspace.component';
import {GridModule, SharedModule} from "@progress/kendo-angular-grid";
import {
  CellSelectingDirective
} from "./components/graph-table-workspace/graph-grid/directives/cell-selecting.directive";
import {BodyModule} from "@progress/kendo-angular-grid";
import {
  DayDeviationPanelComponent
} from './components/graph-table-workspace/graph-grid/components/day-deviation-panel/day-deviation-panel.component';
import {
  TimeIntervalPanelComponent
} from './components/graph-table-workspace/graph-grid/components/time-interval-panel/time-interval-panel.component';
import {PopupModule} from "@progress/kendo-angular-popup";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MenuModule} from '@progress/kendo-angular-menu';
import {ContextMenuModule} from '@progress/kendo-angular-menu';
import {AddTimeIntervalComponent} from './components/shareds/add-time-interval/add-time-interval.component';
import {DateInputsModule, TimePickerModule} from "@progress/kendo-angular-dateinputs";
import {DialogModule} from "@progress/kendo-angular-dialog";
import {FloatingLabelModule, LabelModule} from "@progress/kendo-angular-label";
import {DropDownListModule, DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {
  CheckBoxModule,
  FormFieldModule,
  NumericTextBoxModule,
  TextAreaModule,
  TextBoxModule
} from "@progress/kendo-angular-inputs";
import {WorkspaceErrorComponent} from './components/workspace-error/workspace-error.component';
import {ErrorHandlerService} from "../../../../src/app/services/loggers/error-handler.service";
import {ServerLoggerService} from "../../../../src/app/services/loggers/server-logger.service";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {
  SubdivisionsTreelistComponent
} from "../../../../src/app/components/subdivisions/subdivisions-treelist/subdivisions-treelist.component";
import {ExcelModule, TreeListModule} from '@progress/kendo-angular-treelist';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {
  SubdivisionSelectingDialogComponent
} from "../../../../src/app/components/subdivisions/subdivision-selecting-dialog/subdivision-selecting-dialog.component";
import {AlertComponent} from "../../../../src/app/components/alert/alert.component";
import {
  GraphTableToolbarComponent
} from './components/graph-table-workspace/graph-table-toolbar/graph-table-toolbar.component';
import {
  GraphDayCellComponent
} from './components/graph-table-workspace/graph-grid/components/graph-day-cell/graph-day-cell.component';
import {DayDeviationGridComponent} from './components/dayDeviation/day-deviation-grid/day-deviation-grid.component';
import {
  DayDeviationDialogComponent
} from './components/dayDeviation/day-deviation-dialog/day-deviation-dialog.component';
import {
  StaffunitListDialogComponent
} from './components/proxy-staffunit/staffunit-list-dialog/staffunit-list-dialog.component';
import {StaffunitGridComponent} from './components/proxy-staffunit/staffunit-grid/staffunit-grid.component';
import {CustomValueComponent} from './components/custom-value/custom-value.component';
import {WebApiAuthService} from "../../../../src/app/services/webApi/webApiAuth/web-api-auth.service";
import {DateHelper} from "../../../../src/app/helpers/dateHelper";
import {
  DeepServerFormatInterceptor
} from "../../../../src/app/modules/auth/interceptors/deep-server-format.interceptor";
import {TreeViewModule} from "@progress/kendo-angular-treeview";
import {ListViewModule} from "@progress/kendo-angular-listview";
import {
  CovidRegisterGridDialogComponent
} from "../../../../src/app/components/covid/covid-register-grid-dialog/covid-register-grid-dialog.component";
import {
  CovidRegisterGridComponent
} from "../../../../src/app/components/covid/covid-register-grid/covid-register-grid.component";
import {
  CovidJournalToolbarComponent
} from "../../../employee/src/app/components/covid/journal/covid-journal-toolbar/covid-journal-toolbar.component";
import {
  CovidJournalComponent
} from "../../../employee/src/app/components/covid/journal/covid-journal/covid-journal.component";
import {
  CovidDayPickerComponent
} from "../../../employee/src/app/components/covid/journal/covid-day-picker/covid-day-picker.component";
import {TableGridComponent} from './components/graph-table-workspace/table-grid/table-grid.component';
import {MonitoringComponent} from './components/monitoring/monitoring.component';
import {MonitoringToolbarComponent} from './components/monitoring/monitoring-toolbar/monitoring-toolbar.component';
import {MonitoringGridComponent} from './components/monitoring/monitoring-grid/monitoring-grid.component';
import {
  MonitoringDetailGridComponent
} from './components/monitoring/monitoring-grid/monitoring-detail-grid/monitoring-detail-grid.component';
import {
  MonitoringGridDetailComponent
} from './components/monitoring/monitoring-grid/monitoring-grid-detail/monitoring-grid-detail.component';
import {
  RedactionGridComponent
} from './components/shareds/redactions/redaction-grid/redaction-grid.component';
import {
  RedactionDetailGridComponent,
} from './components/shareds/redactions/redaction-grid/redaction-detail-grid/redaction-detail-grid.component';
import {KendoGridExpandedDirective} from "../../../../src/app/directives/kendo-grid-expanded.directive";
import {
  GridToolbarManagementComponent
} from './components/graph-table-workspace/grid-toolbar-management/grid-toolbar-management.component';
import {GraphGridComponent} from './components/graph-table-workspace/graph-grid/graph-grid.component';
import {
  ProxyButtonPanelComponent
} from './components/graph-table-workspace/graph-grid/components/proxy-button-panel/proxy-button-panel.component';
import {EditMoonlighterComponent} from './components/edit-moonlighter/edit-moonlighter.component';
import {FreeRateGridComponent} from './components/edit-moonlighter/free-rate-grid/free-rate-grid.component';
import {KendoNumericExpandedDirective} from "../../../../src/app/directives/kendo-numeric-expanded.directive";
import {
  TableCodePanelComponent
} from './components/graph-table-workspace/table-grid/components/table-code-panel/table-code-panel.component';
import {CutLongStringDirective} from "../../../../src/app/directives/cut-long-string.directive";
import {EditProxyComponent} from './components/edit-proxy/edit-proxy.component';
import {
  InternetConnectionComponent
} from "../../../../src/app/components/internet-connection/internet-connection.component";
import {AppSettingsService} from "../../../../src/app/services/app-settings.service";
import {lastValueFrom} from "rxjs";
import {tap} from "rxjs/operators";
import {JwtHelper} from "../../../../src/app/helpers/JwtHelper";
import {ApprovingComponent} from './components/monitoring/approving/approving.component';
import {KendoTreeListExpandedDirective} from "../../../../src/app/directives/kendo-treelist-expanded.directive";
import {CommentComponent} from "../../../../src/app/components/comment/comment.component";
import {ChildInputPlaceholderDirective} from "../../../../src/app/directives/child-input-placeholder.directive";
import {WorkspaceComponent} from "../../../../src/app/components/workspace/workspace.component";
import {WorkspaceServiceBase} from "../../../../src/app/components/workspace/workspace.service";
import {TimesheetWorkspaceService} from "./services/timesheet-workspace.service";
import {AdministrationComponent} from "./components/administration/administration.component";
import {
  AdministrationResponsibleComponent
} from "src/app/components/administrations/administration-responsible/administration-responsible.component";
import {
  ResponsibleGridComponent
} from "src/app/components/administrations/administration-responsible/responsible-grid/responsible-grid.component";
import {
  AdministrationResponsibleMaintainingComponent
} from "./components/administration/responsible-maintaining/responsible-maintaining.component";
import {
  AdministrationResponsibleSignComponent
} from "./components/administration/responsible-sign/responsible-sign.component";
import {EditDutyComponent} from './components/edit-duty/edit-duty.component';
import {
  AdministrationGraphApprovalRouteComponent
} from "./components/administration/graph-approval-route/graph-approval-route.component";
import {
  AdministrationTableApprovalRouteComponent
} from "./components/administration/table-approval-route/table-approval-route.component";
import {ApprovalRouteComponent} from "./components/administration/approval-route/approval-route.component";
import {
  ApprovalRouteStepComponent
} from "./components/administration/approval-route/route-step/approval-route-step.component";
import {
  SelectWorkingEmployeesComponent
} from "src/app/components/select-working-employees/select-working-employees.component";
import {
  SelectWorkingEmployeesDialogComponent
} from "src/app/components/select-working-employees/dialog/select-working-employees-component/select-working-employees-dialog.component";
import {TracerServiceBase} from "../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {TracerService} from "../../../../src/app/modules/trace/tracers2/trace-services/tracerService";
import {
  TraceStorageService
} from "../../../../src/app/modules/trace/tracers2/trace-storage-services/trace-storage.service";
import {CorrectionListComponent} from "./components/correction-list/correction-list.component";
import {TableDismissedComponent} from './components/table-dismissed/table-dismissed/table-dismissed.component';
import {IdenticalRequestInterceptor} from "../../../../src/app/modules/auth/interceptors/identical-request.interceptor";
import {CatchErrorInterceptor} from "../../../../src/app/modules/auth/interceptors/catch-error.interceptor";
import {WorkSpaceErrorComponentService} from "./services/workspace/work-space-error.component.service";
import {TraceInterceptor} from "../../../../src/app/modules/auth/interceptors/trace.interceptor";
import {
  PrintQuarterSettingsWithSubdivisionsComponent
} from "../../../../src/app/components/print-form-settings/print-quarter-settings-with-subdivisions/print-quarter-settings-with-subdivisions.component";
import {BlobInterceptor} from "../../../../src/app/modules/auth/interceptors/blob.interceptor";
import {
  KendoDropdownButtonExpandedDirective
} from "../../../../src/app/directives/kendo-dropdown-button-expanded.directive";
import {
  CellColorComponent
} from './components/graph-table-workspace/graph-grid/components/graph-day-cell/components/cell-color/cell-color.component';
import {
  GraphRedactionComparerDirective
} from "./components/graph-table-workspace/graph-grid/directives/graph-redaction-comparer.directive";
import {TextboxSelectComponent} from "../../../../src/app/components/textbox-select/textbox-select.component";
import {
  SubdivisionTextboxSelectComponent
} from "../../../../src/app/components/subdivisions/subdivision-textbox-select/subdivision-textbox-select.component";
import {CustomStorageService} from "../../../../src/app/services/storages/custom-storage.service";
import {CustomStorageRealService} from "../../../../src/app/services/storages/custom-storage-real.service";
import {CryptService} from "../../../../src/app/services/cryptServices/crypt.service";
import {
  RedactionButtonComponent
} from './components/graph-table-workspace/grid-toolbar-management/components/redaction-button/redaction-button.component';
import {
  Covid2RegisterGridDialogComponent
} from "src/app/components/covid2/covid2-register-grid-dialog/covid2-register-grid-dialog.component";
import {
  Covid2RegisterGridComponent
} from "src/app/components/covid2/covid2-register-grid/covid2-register-grid.component";
import {VichRegisterGridComponent} from "src/app/components/vich/vich-register-grid/vich-register-grid.component";
import {
  VichRegisterGridDialogComponent
} from "src/app/components/vich/vich-register-grid-dialog/vich-register-grid-dialog.component";
import {
  TuberRegisterGridDialogComponent
} from "src/app/components/tuber/tuber-register-grid-dialog/tuber-register-grid-dialog.component";
import {TuberRegisterGridComponent} from "src/app/components/tuber/tuber-register-grid/tuber-register-grid.component";
import {
  PatientMovementsGridComponent
} from './components/patient-movements/directory-grid/patient-movements-grid.component';
import {
  PrintFormFromtillDatesSettingsComponent
} from "../../../../src/app/components/print-form-settings/print-form-fromtill-dates-settings/print-form-fromtill-dates-settings.component";
import {
  SubdivisionTreeview2Component
} from "../../../../src/app/components/subdivisions/subdivision-treeview2/subdivision-treeview2.component";
import {
  SubdivisionTreeviewWithCheckbox2Component
} from "../../../../src/app/components/subdivisions/subdivision-treeview-with-checkbox2/subdivision-treeview-with-checkbox2.component";
import {
  PatientMovementAdditionallyInfosGridComponent
} from './components/patient-movements/additionally-infos-grid/patient-movement-additionally-infos-grid.component';
import {
  PatientMovementsDirectoryComponent
} from './components/patient-movements/directory/patient-movements-directory.component';
import {ReportDatesComponent} from '../../../../src/app/components/report-dates/report-dates.component';
import {
  PatientMovementsSvodDirectoryComponent
} from './components/patient-movements/directory-svod/patient-movements-svod-directory.component';
import {
  GraphCellSelectingDirective
} from "./components/graph-table-workspace/graph-grid/directives/graph-cell-selecting.directive";
import {
  PrintFormForDateSettingsWithSubdivisionTreeComponent
} from "../../../../src/app/components/print-form-settings/print-form-for-date-settings-with-subdivision-tree/print-form-for-date-settings-with-subdivision-tree.component";
import {HierarchiStringsComponent} from "../../../../src/app/components/hierarchi-strings/hierarchi-strings.component";
import {
  ReportPeriodSettingsComponent
} from "../../../../src/app/components/print-form-settings/report-period-settings/report-period-settings.component";
import {ICON_SETTINGS, IconModule} from '@progress/kendo-angular-icons';
import {
  PrintFormForDateSettingsComponent
} from "../../../../src/app/components/print-form-settings/print-form-for-date-settings/print-form-for-date-settings.component";
import {
  ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent
} from "../../../../src/app/components/print-form-settings/report-settings-with-timeintervals-and-subdivision-tree-list/report-settings-with-timeintervals-and-subdivision-tree-list.component";
import {
  DeepCopyRequestInterceptor
} from "../../../../src/app/modules/auth/interceptors/deep-copy-request.interceptor";
import {
  StandardServerResponseInterceptor
} from "../../../../src/app/modules/auth/interceptors/standard-server-response.interceptor";
import {
  CovidRegisterSettingsComponent
} from "./components/settings-for-reports/covid-register-settings/covid-register-settings.component";
import {
  StaffUnitTypeListSettingsComponent
} from "./components/settings-for-reports/staff-unit-type-list-settings/staff-unit-type-list-settings.component";
import {GenderTextPipe} from '../../../../src/app/pipes/gender-text.pipe';
import {
  PrintFormFromTillDatesWithStaffunitTypesSettingsComponent
} from "../../../../src/app/components/print-form-settings/print-form-from-till-dates-with-staffunit-types-settings/print-form-from-till-dates-with-staffunit-types-settings.component";
import {
  KendoGridSelectByArrayDataSourceSelectionDirective
} from "../../../../src/app/directives/selections/kendo-grid-select-by-array-data-source-selection.directive";
import {
  ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent
} from "../../../../src/app/components/print-form-settings/for-date-with-subdivisions-and-staffunit-types-settings/for-date-with-subdivisions-and-staffunit-types-settings.component";
import {
  GraphGrid2Component
} from "./components/graph-table-workspace2/components/graph/components/graph-grid2/graph-grid2.component";
import {
  SelectedGridCellDirective
} from "../../../../src/app/directives/grid-cell-selection/selected-grid-cell.directive";
import {
  SelectedGridCellTargetDirective
} from "../../../../src/app/directives/grid-cell-selection/selected-grid-cell-target.directive";
import {
  GraphDayCell2Component
} from "./components/graph-table-workspace2/components/graph/components/graph-grid2/components/graph-day-cell2/graph-day-cell2.component";
import {
  TimeIntervalDurationPipe,
  TimeIntervalNightDurationPipe,
  TimeIntervalToStringPipe
} from "../../../../src/app/pipes/time-interval.pipe";
import {MinutesToHoursPipe} from "../../../../src/app/pipes/minutes-to-hours.pipe";
import {ArrayOrEmptyPipe} from "../../../../src/app/pipes/array-pipes/array-or-empty.pipe";
import {ArraySumByPipe} from "../../../../src/app/pipes/array-pipes/array-sum.pipe";
import {NgLetDirective} from "../../../../src/app/directives/let.directive";
import {GraphComponent} from "./components/graph-table-workspace2/components/graph/graph.component";
import {
  GraphGridToolbarTemplateDirective
} from "./components/graph-table-workspace2/components/graph/components/graph-grid2/directives/graph-grid-toolbar-template.directive";
import {
  KendoTreelistDataSourceSelectionDirective
} from "../../../../src/app/directives/selections/kendo-treelist-data-source-selection.directive";
import {
  KendoDropDownListDataSourceSelectionDirective
} from "../../../../src/app/directives/selections/kendo-drop-down-list-data-source-selection.directive";
import {
  GraphCustomSettingsComponent
} from "./components/settings-for-reports/graph-custom-settings/graph-custom-settings.component";
import {RedactionGrid2Component} from "./components/shareds/redactions/redaction-grid2/redaction-grid2.component";
import {ArrayElementAtPipe} from "../../../../src/app/pipes/array-pipes/array-element.pipe";
import {GraphTableWorkspace2Component} from "./components/graph-table-workspace2/graph-table-workspace2.component";
import {
  GraphTableToolbar2Component
} from "./components/graph-table-workspace2/components/graph-table-toolbar2/graph-table-toolbar2.component";
import {
  GraphTableStateInfoComponent
} from "./components/graph-table-workspace2/components/graph-table-state-info/graph-table-state-info.component";
import {
  TimeIntervalPanel2Component
} from './components/graph-table-workspace2/components/graph/components/time-interval-panel2/time-interval-panel2.component';
import {
  AddTimeInterval2Component
} from './components/graph-table-workspace2/components/graph/components/time-interval-panel2/add/add-time-interval2.component';
import {
  DayDeviationPanel2Component
} from './components/graph-table-workspace2/components/graph/components/day-deviation-panel2/day-deviation-panel2.component';
import {ArraySortPipe} from "../../../../src/app/pipes/array-pipes/array-sort.pipe";
import {
  PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent
} from "../../../../src/app/components/print-form-settings/print-quarter-settings-with-subdivisions-and-staffunittypes/print-quarter-settings-with-subdivisions-and-staff-unit-types.component";
import {
  PrintFormQuarterSettingsWithStaffUnitTypesComponent
} from "../../../../src/app/components/print-form-settings/print-form-quarter-settings-with-staff-unit-types/print-form-quarter-settings-with-staff-unit-types.component";
import {GraphLunchButtonPipe} from "./components/graph-table-workspace2/components/graph/pipes/graph-lunch-button.pipe";
import {
  GraphClearButtonPipe
} from "./components/graph-table-workspace2/components/graph/pipes/graph-clear-button.pipe";
import {
  GraphCustomLunchButtonPipe
} from "./components/graph-table-workspace2/components/graph/pipes/graph-custom-lunch-button.pipe";
import {ThrottleClickDirective} from "../../../../src/app/directives/throttle-click.directive";
import {GraphMilkButtonPipe} from "./components/graph-table-workspace2/components/graph/pipes/graph-milk-button.pipe";
import {KendoContextMenuExpandedDirective} from "../../../../src/app/directives/kendo-context-menu-expanded.directive";
import {GraphAddButtonsPipe} from "./components/graph-table-workspace2/components/graph/pipes/graph-add-buttons.pipe";
import {AppUpdateComponent} from "../../../../src/app/components/app-update/app-update.component";
import { RedactionDetailGrid2Component } from './components/shareds/redactions/redaction-grid2/redaction-detail-grid2/redaction-detail-grid2.component';

/**
 * Функция настройки Jwt
 * @param authService
 */
export function jwtOptionsFactory(
  authService: AuthService,
  webApiAuthService: WebApiAuthService,
  appSettingsService: AppSettingsService
): JwtConfig {
  return {
    tokenGetter: (request) => {
      if (request.headers.has(JwtHelper.SkipTokenHeaderName)) {
        return "";
      }

      return lastValueFrom(authService.tokenOrUpdate$);
    },
    allowedDomains: [
      new URL(appSettingsService.webApiBaseUrl).host,
      new URL(appSettingsService.webApiAuthBaseUrl).host
    ],
    disallowedRoutes: [
      webApiAuthService.controllers.auth.actions.getToken.toString(),
      webApiAuthService.controllers.auth.actions.getTokenAndAuthUser.toString(),
      webApiAuthService.controllers.auth.actions.refreshToken.toString(),
      webApiAuthService.controllers.auth.actions.refreshTokenAndAuthUser.toString()
    ]
  }
}

/** Функция инициализирует приложение. Будет выполнятся до запуска и приложение будет ожидать ее выполнение */
function appInitializer(appSettingsService: AppSettingsService, environmentService: EnvironmentService) {
  return () => {
    return lastValueFrom(appSettingsService.initFromRemote$().pipe(tap(value => {
      DateHelper.serverDateSettings = {format: appSettingsService.webApiFormatSettings.date};
      environmentService.production = environment.production;
    })))
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AppUpdateComponent,
    WorkspaceComponent,
    GraphTableWorkspaceComponent,
    GraphTableWorkspace2Component,
    CellSelectingDirective,
    GraphCellSelectingDirective,
    DayDeviationPanelComponent,
    TimeIntervalPanelComponent,
    AddTimeIntervalComponent,
    WorkspaceErrorComponent,
    SubdivisionsTreelistComponent,
    SubdivisionSelectingDialogComponent,
    AlertComponent,
    GraphTableToolbarComponent,
    GraphTableToolbar2Component,
    GraphDayCellComponent,
    DayDeviationGridComponent,
    DayDeviationDialogComponent,
    StaffunitListDialogComponent,
    StaffunitGridComponent,
    CustomValueComponent,
    CovidJournalToolbarComponent,
    KendoTreeListExpandedDirective,
    CovidJournalComponent,
    CovidDayPickerComponent,
    CovidRegisterGridComponent,
    CovidRegisterGridDialogComponent,
    CovidRegisterSettingsComponent,
    Covid2RegisterGridComponent,
    Covid2RegisterGridDialogComponent,
    VichRegisterGridComponent,
    VichRegisterGridDialogComponent,
    TuberRegisterGridComponent,
    TuberRegisterGridDialogComponent,
    ReportPeriodSettingsComponent,
    TableGridComponent,
    MonitoringComponent,
    MonitoringToolbarComponent,
    MonitoringGridComponent,
    MonitoringDetailGridComponent,
    MonitoringGridDetailComponent,
    RedactionGridComponent,
    RedactionGrid2Component,
    RedactionDetailGridComponent,
    KendoGridExpandedDirective,
    GridToolbarManagementComponent,
    GraphGridComponent,
    GraphGrid2Component,
    GraphComponent,
    GraphTableStateInfoComponent,
    GraphGridToolbarTemplateDirective,
    GraphDayCell2Component,
    ProxyButtonPanelComponent,
    EditMoonlighterComponent,
    FreeRateGridComponent,
    KendoNumericExpandedDirective,
    ProxyButtonPanelComponent,
    TableCodePanelComponent,
    CutLongStringDirective,
    EditProxyComponent,
    InternetConnectionComponent,
    ApprovingComponent,
    CommentComponent,
    ChildInputPlaceholderDirective,
    AdministrationComponent,
    AdministrationResponsibleComponent,
    ResponsibleGridComponent,
    AdministrationResponsibleMaintainingComponent,
    AdministrationResponsibleSignComponent,
    EditDutyComponent,
    AdministrationGraphApprovalRouteComponent,
    AdministrationTableApprovalRouteComponent,
    ApprovalRouteComponent,
    SubdivisionTreeview2Component,
    ApprovalRouteStepComponent,
    SelectWorkingEmployeesComponent,
    SelectWorkingEmployeesDialogComponent,
    CorrectionListComponent,
    TableDismissedComponent,
    PrintQuarterSettingsWithSubdivisionsComponent,
    SubdivisionTreeviewWithCheckbox2Component,
    KendoDropdownButtonExpandedDirective,
    CellColorComponent,
    GraphRedactionComparerDirective,
    TextboxSelectComponent,
    SubdivisionTextboxSelectComponent,
    RedactionButtonComponent,
    PatientMovementsGridComponent,
    PrintFormFromtillDatesSettingsComponent,
    PatientMovementAdditionallyInfosGridComponent,
    PatientMovementsDirectoryComponent,
    PatientMovementsSvodDirectoryComponent,
    ReportDatesComponent,
    PrintFormForDateSettingsWithSubdivisionTreeComponent,
    HierarchiStringsComponent,
    PrintFormForDateSettingsComponent,
    ReportSettingsWithTimeintervalsAndSubdivisionTreeListComponent,
    StaffUnitTypeListSettingsComponent,
    CovidRegisterSettingsComponent,
    GenderTextPipe,
    PrintFormFromTillDatesWithStaffunitTypesSettingsComponent,
    KendoGridSelectByArrayDataSourceSelectionDirective,
    KendoTreelistDataSourceSelectionDirective,
    KendoDropDownListDataSourceSelectionDirective,
    ForDateWithSubdivisionsAndStaffunitTypesSettingsComponent,
    NgLetDirective,
    SelectedGridCellDirective,
    SelectedGridCellTargetDirective,
    TimeIntervalToStringPipe,
    MinutesToHoursPipe,
    ArrayOrEmptyPipe,
    ArraySumByPipe,
    ArraySortPipe,
    ArrayElementAtPipe,
    TimeIntervalDurationPipe,
    TimeIntervalNightDurationPipe,
    GraphCustomSettingsComponent,
    TimeIntervalPanel2Component,
    AddTimeInterval2Component,
    DayDeviationPanel2Component,
    PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent,
    PrintFormQuarterSettingsWithStaffUnitTypesComponent,
    GraphLunchButtonPipe,
    GraphClearButtonPipe,
    GraphCustomLunchButtonPipe,
    ThrottleClickDirective,
    GraphMilkButtonPipe,
    KendoContextMenuExpandedDirective,
    GraphAddButtonsPipe,
    RedactionDetailGrid2Component,
  ],
  exports: [
    SubdivisionTextboxSelectComponent,
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AuthModule,
    FormsModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService, WebApiAuthService, AppSettingsService],
      },
    }),
    ProgressBarModule,
    SharedComponentModule,
    DrawerModule,
    ButtonsModule,
    GridModule,
    PopupModule,
    MenuModule,
    ContextMenuModule,
    TimePickerModule,
    IntlModule,
    DialogModule,
    FloatingLabelModule,
    DropDownListModule,
    TextBoxModule,
    LabelModule,
    LayoutModule,
    TreeListModule,
    ListViewModule,
    TreeViewModule,
    TooltipModule,
    BodyModule,
    DateInputsModule,
    NumericTextBoxModule,
    SharedModule,
    DropDownsModule,
    TextAreaModule,
    FormFieldModule,
    CheckBoxModule,
    ExcelModule,
    IconModule], providers: [
    {provide: LOCALE_ID, useValue: 'ru-RU'},
    {provide: MessageService, useClass: KendoMessageService},
    {provide: TracerServiceBase, useValue: new TracerService(new TraceStorageService())},
    {provide: CustomStorageService, useClass: CustomStorageRealService, deps: [AppSettingsService, CryptService]},
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
      deps: [ServerLoggerService, EnvironmentService, TracerServiceBase]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeepCopyRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdenticalRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeepServerFormatInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StandardServerResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CatchErrorInterceptor,
      multi: true,
      deps: [AuthService, WorkSpaceErrorComponentService, ServerLoggerService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AppSettingsService, EnvironmentService],
      multi: true
    },
    {provide: WorkspaceServiceBase, useExisting: TimesheetWorkspaceService},
    {provide: ICON_SETTINGS, useValue: {type: "font"}},
    provideHttpClient(withInterceptorsFromDi())
  ]
})

export class AppModule {
}
