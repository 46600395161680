<div *ngIf="dataSourceService"
     #anchor
     [title]="(disabled()) ? 'Временные интервалы в данный момент неактивны' : ''"
     class="container"
>
  <div class="intervals"
       kendoContextMenuTargetContainer
       #target='kendoContextMenuTargetContainer'
  >
    <button *ngFor="let ti of ((dataSourceService().dataSource.data$ | async) | arraySort: dataSourceService().timeIntervalComparer)"
            kendoButton
            [ngClass]="{
              'not-deleting-time-interval': !ti.subdivisionId,
              'as-new-time-interval': asNew()?.includes(ti.id)
            }"
            [primary]="toRemove()?.includes(ti.id)"
            [size]="'none'"
            [kendoContextMenuTarget]="!!ti.subdivisionId ? ti : undefined"
            [disabled]="(disabled()) || (!!toRemove() && !ti.subdivisionId)"
            (click)="onButtonClick(ti)"
    >
      {{(ti.startInterval | timeIntervalToString) + '-' + (ti.endInterval | timeIntervalToString)}}
    </button>
  </div>

  <div style="flex: 1"></div>

  <div class="command-buttons">
    @if (!toRemove()){
      <button kendoButton
              [ngStyle]="{
                background: '#bdffbd'
              }"
              [disabled]="!!toRemove() || (disabled())"
              title="Добавить временной интервал"
              (click)="onAdd()"
              [icon]="'plus'"
      >
      </button>
    }
    <button kendoButton
            [ngStyle]="{
              background: '#FCE4E4',
            }"
            [disabled]="!!toRemove() && (toRemove()?.length === 0) || (disabled())"
            title="Удалить несколько временных интервалов"
            (click)="remove()"
            [icon]="'delete'"
    >
    </button>

    @if(!!toRemove()){
      <button kendoButton
              title="Отменить удаление временных интервалов"
              (click)="cancelRemove()"
              [icon]="'cancel'"
      >
      </button>
    }
  </div>

  @if(!!toRemove()){
    <kendo-popup [anchor]="anchor"
                 [anchorAlign]="{
                  horizontal: 'center',
                  vertical: 'bottom',
                 }"
    >
      <div class="deleting-content">
        Выберите временные интервалы подлежащие удалению
      </div>
    </kendo-popup>
  }

  <kendo-contextmenu [target]="target"
                     [items]="contextMenuArray"
                     (select)="contextMenuSelect($event)"
                     (popupOpen)="contextMenuOpen($event)"
  ></kendo-contextmenu>

</div>

