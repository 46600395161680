import {Pipe, PipeTransform} from "@angular/core";
import {CellDataDirective} from "../components/graph-grid2/graph-grid2.component";

/** Результирующий тип трансформации */
type TransformReturnType = {
  /** Отключена ли кнопка */
  disable: boolean,
  /** Директивы ячеек подлежащие очистки */
  cellDirectives: CellDataDirective[]
}

/** Пайп трансформирует выделенные ячейки в объект управления кнопкой Очистить  */
@Pipe({
  name: 'graphClearButton'
})
export class GraphClearButtonPipe implements PipeTransform {
  transform(selectedCells: CellDataDirective[]): TransformReturnType {
    const cellDirectives = (selectedCells ?? [])
      .filter(x => x.dayCell.cell.graphDayCurrent.timeInterval || x.dayCell.cell.graphDayCurrent.dayDeviation);

    return {
      disable: !cellDirectives.length,
      cellDirectives: cellDirectives,
    }
  }
}
