import {Dump, IDump} from "./dump";

/** Базовый интерфейс дамп листа */
interface IDumpListBase<TDump extends IDump<any>>{
  /**
   * Массив дампов.
   * Отсортированы по {@link IDump.date} в порядке возрастания и должны быть уникальными.
   * Дата {@link IDump.endDate} должно быть на 1 тик меньше чем {@link IDump.date} следующего дампа.
   * Дата {@link IDump.endDate}должна быть окончанием рута или датой окончания окна выборки.
   * Должен быть как минимум 1 дамп.
   */
  dumpItems: TDump[];
}

/** Интерфейс дамп листа */
export interface IDumpList<T> extends IDumpListBase<IDump<T>> {


}

/** Тип диапазона действия дамп листа */
type DumpListDateRangeType = {
  /** {@link IDump.date} первого дампа */
  start: Date,
  /** {@link IDump.endDate} последнего дампа */
  end: Date
}

/** Класс дамп листа */
export class DumpList<T> implements IDumpList<T> {

  /**
   * Получить первый дамп.<br>
   * Содержит {@link dumpItems}[0].{@link IDump.dump}.<br>
   * Использовать для получения не изменяемых данных в дампах.<br>
   */
  public readonly firstDump: T;

  /** Срок действия дамп листа */
  public readonly dateRange: DumpListDateRangeType;

  /**
   * Конструктор дамп листа
    * @param dumpItems смотри {@link IDumpListBase.dumpItems}
   */
  constructor(public readonly dumpItems: Dump<T>[]) {
    this.firstDump = dumpItems[0].dump;
    this.dateRange = {
      start: dumpItems[0].date,
      end: dumpItems[dumpItems.length - 1].endDate
    }
  }

  /**
   * Получить элемент из {@link dumpItems} по времени.
   * @param date время
   * @return последний элемент из {@link dumpItems} у которого {@link IDump.date} <= {@link date}, если переданное время раньше времени первого дампа, то вернет первый дамп
   */
  public getItem(date: Date): Dump<T>{
    for (let i = this.dumpItems.length - 1; i >= 0 ; i--) {
      if(this.dumpItems[i].date <= date){
        return this.dumpItems[i];
      }
    }

    return this.dumpItems[0];
  }

  /**
   * Получить элемент из {@link dumpItems} по времени или {@link or}.<br>
   * @param date время
   * @param or элемент по умолчанию
   * @return если {@link date} не попадает в диапазон {@link dateRange} вернет {@link or}, иначе результат метода {@link getItem}
   */
  public getItemOr(date: Date, or: Dump<T> = undefined): Dump<T>{
    return date < this.dateRange.start || date > this.dateRange.end
      ? or
      : this.getItem(date)
  }

  /**
   * Получить {@link IDump.dump} на дату.<br>
   * Метод использует метод {@link getItem}.<br>
   * @param date время на которое нужно получить дамп
   */
  public getDump(date: Date): T{
    return this.getItem(date).dump;
  }

  /**
   * Получить {@link IDump.dump} на дату или {@link or}.<br>
   * @param date время на которое нужно получить дамп
   * @param or значение по умолчанию
   * @return если {@link date} не попадает в диапазон {@link dateRange} вернет {@link or}, иначе результат метода {@link getDump}
   */
  public getDumpOr(date: Date, or: T = undefined): T{
    const item = this.getItemOr(date, undefined);
    return item ? item.dump : or;
  }

  /** Получить все {@link IDump.dump} из {@link dumpItems}*/
  public getDumps(): T[]{
    return this.dumpItems.map(x => x.dump);
  }

  /** Создать на основе {@link IDumpList} */
  public static Create<T>(source: IDumpList<T>): DumpList<T>{
    return new DumpList<T>(
      source.dumpItems
        .map(x => Dump.Create(x))
    )
  }

  /** Создать несколько на основе {@link IDumpList}[] */
  public static CreateMany<T>(sources: IDumpList<T>[]): DumpList<T>[] {
    return sources.map(x => this.Create(x));
  }
}

/** Тип для замены всех полей типа {@link IDumpList}(в том числе массив) на тип {@link DumpList}. Только первый уровень! */
export type ReplaceIDumpListToDumpListType<T> = {
  [K in keyof T]: T[K] extends IDumpList<infer U>
    ? DumpList<U>
    : T[K] extends (infer R)[]
      ? R extends IDumpList<infer U>
        ? DumpList<U>[]
        : T[K]
      : T[K];
}
