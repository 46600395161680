import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

/** Компонент отвечает за подсветку (работал, не работал, цвет отклонения) */
@Component({
  selector: 'app-cell-color',
  templateUrl: './cell-color.component.html',
  styleUrls: ['./cell-color.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellColorComponent {
  @HostBinding('style.background') backgroundColor = '';

  /** Входной параметр цвета */
  @Input() set color(value: string) {
    this.backgroundColor = value
  }
  constructor() { }
}
