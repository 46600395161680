<kendo-grid [data]="gridResponsiblesData">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton
            [primary]="true"
            [disabled]="!subdivisionId"
            (click)="addBtnClick()"
    >
      <span class="k-icon k-i-plus k-font-icon"></span>
      <span>Добавить</span>
    </button>
  </ng-template>

  <kendo-grid-column title="Сотрудник"
                     field="userName"
                     class="no-padding"
                     [style]="{'padding': '0'}"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container
        [ngTemplateOutlet]="!dataItem.responsibleId ? newRowTemplate : rowTemplate"
        [ngTemplateOutletContext]="{dataItem}">
      </ng-container>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-command-column [width]="70">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div style="text-align: center">
        <button kendoButton
                [ngStyle]="{
                padding: '0px',
                paddingLeft: '3px',
                paddingRight: '3px',
                margin: '1px',
                height: '30px',
                width: '30px'
                }" [primary]="true"
                (click)="deleteBtnClick(dataItem)"
        >
          <span class="k-icon  k-i-delete k-font-icon"></span>
        </button>
      </div>

    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>

<ng-template #rowTemplate  let-userName=dataItem.employeeName let-userCode=dataItem.employeeCode>
  <div style="display: flex; flex-direction: row; justify-content: start; align-items: stretch;">
    <div style="width: 150px; margin-left: 10px;">{{userCode}}</div>
    <div>{{userName}}</div>
  </div>
</ng-template>

<ng-template #newRowTemplate kendoGridCellTemplate>
  <ng-container style="display: flex;">
    <kendo-dropdownlist [data]="userDropDownListData"
                        [filterable]="true"
                        textField="employeeName"
                        valueField="responsibleId"
                        listHeight="300"
                        style="width: 100%;"
                        (filterChange)="userListFilter($event)"
                        (selectionChange)="selectEmployee($event);"
    >
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <div style="width: 150px">{{dataItem.employeeCode}}</div>
        <div>{{dataItem.employeeName}}</div>
      </ng-template>
    </kendo-dropdownlist>
  </ng-container>

</ng-template>
