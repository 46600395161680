import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../../../../../src/app/services/storages/custom-storage.service";
import {ExtensionObj} from "../../../../../../../src/app/helpers/extensionObj";
import * as moment from "moment";
import {AuthService} from "../../../../../../../src/app/modules/auth/services/auth.service";
import {TimeSheetFunctionEnum} from "../../../../../../../src/app/classes/domain/AccessEnums/timeSheet/timeSheetFunctionEnum";
import {AlertService} from "../../../../../../../src/app/services/alert.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {TracerServiceBase} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Component({
  selector: 'app-monitoring-toolbar',
  templateUrl: './monitoring-toolbar.component.html',
  styleUrls: ['./monitoring-toolbar.component.css']
})
@traceClass('MonitoringToolbarComponent')
export class MonitoringToolbarComponent implements OnInit, OnDestroy {
  /** Событие трансляции изменения в toolbar */
  @Output() public $changeEvent = new EventEmitter<MonitoringToolbarComponentEvent>();

  public minDate: Date = moment({year: 2015}).toDate();
  public maxDate: Date = moment({year: 3000}).toDate();

  public date: Date;
  public type: MonitoringToolbarComponentTypeEnum;

  /** Доступы */
  public access: {graph: boolean, table: boolean};


  constructor(private customStorageService: CustomStorageService,
              private authService: AuthService,
              private alertService: AlertService,
              private tracerService: TracerServiceBase) {
    this.access = {
      graph: authService.user.Functions.some(x => x == TimeSheetFunctionEnum.GraphApproving),
      table: authService.user.Functions.some(x => x == TimeSheetFunctionEnum.TableApproving)
    }
  }

  @traceFunc()
  ngOnInit(): void {
    if(!this.access.graph && !this.access.table){
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'У вас нет доступа к согласованию'
      }).showAlert();
      return;
    }

    const fromStorage = this.customStorageService.get<MonitoringToolbarComponentEvent>(MonitoringToolbarComponent.storageOptions);
    this.date = fromStorage? new Date(fromStorage.date) : moment().startOf('month').toDate();
    this.type = fromStorage?.type ?? (this.access.graph ? MonitoringToolbarComponentTypeEnum.Graph : MonitoringToolbarComponentTypeEnum.Table );
    this.emitEvent();
  }

  /**
   * Обработка события изменения группы кнопок
   * @param event
   */
  @traceFunc()
  public onButtonGroupChange(event: MonitoringToolbarComponentTypeEnum){
    if(this.type == event) {return;}
    this.type = event;
    this.updateStorage();
    this.emitEvent();
  }

  /**
   * Событие изменения даты
   * @param event
   */
  @traceFunc()
  public onDateChange(event: Date){
    if(this.date == event) {return;}
    this.date = event ? moment(event).startOf('month').toDate() : null;
    this.updateStorage();
    this.emitEvent();
  }

  /**
   * Обновление данных хранящихся в sessionStorage
   * @private
   */
  @traceFunc()
  private updateStorage(){
    if(this.isValid){
      this.customStorageService.set(MonitoringToolbarComponent.storageOptions, new ExtensionObj(new MonitoringToolbarComponentEvent()).modResult(x => {
        x.date = this.date;
        x.type = this.type;
      }))
    }
  }

  /**
   * Транслировать событие изменения
   * @private
   */
  @traceFunc()
  private emitEvent(){
    this.$changeEvent.emit( this.isValid ?
      new ExtensionObj(new MonitoringToolbarComponentEvent()).modResult(x => {
        x.date = this.date;
        x.type = this.type;
      }) :
      null)
  }

  /**
   * Валиден ли toolbar
   * @private
   */
  private get isValid():boolean{
    return !!this.type && !!this.date;
  }

  /**
   * Опции хранения состояния
   * @private
   */
  private static storageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'MonitoringToolbarComponent',
    null,
    true,
    false
  );

  @traceFunc()
  ngOnDestroy(): void {
  }
}

/**
 * Событие изменения состояния toolbar
 */
export class MonitoringToolbarComponentEvent{
  type: MonitoringToolbarComponentTypeEnum;
  date: Date;
}

/**
 * Перечисление типов мониторинга
 */
export enum MonitoringToolbarComponentTypeEnum{
  Graph = 1,
  Table = 2
}
