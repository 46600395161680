<kendo-splitter orientation="vertical" style="height: 100%">
  <kendo-splitter-pane [resizable]="false" style="height: 51px; margin-bottom: 3px; display: flex; justify-content: flex-end;" class="k-toolbar">
    <div class="report-date-control">
      <app-report-dates></app-report-dates>
    </div>
  </kendo-splitter-pane>
  <kendo-splitter-pane style="height: 100%; overflow: hidden">
    <app-patient-movements-grid [dataSourceService]="dataSourceService"></app-patient-movements-grid>
  </kendo-splitter-pane>
</kendo-splitter>
