import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Observable, ReplaySubject, Subject} from "rxjs";
import {FreeRateGridService} from "./free-rate-grid/free-rate-grid.service";
import {IDropDownItem} from "../../../../../../src/app/classes/requestResults/iDropDownItem";
import {EditMoonlighterComponentService} from "./services/edit-moonlighter-component.service";
import {EditMoonlighterComponentDataService} from "./services/edit-moonlighter-component-data.service";
import {
  EditMoonlighterComponentFormService,
  EditMoonlighterComponentFormService_Result
} from "./services/edit-moonlighter-component-form.service";
import {EditMoonlighterComponentSignalRService} from "./services/edit-moonlighter-component-signalR.service";
import {
  EditMoonlighterComponent_SaveEvent,
  EditMoonlighterComponent_Settings,
  IEditMoonlighterComponent
} from "./i-edit-moonlighter-component";
import {Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-moonlighter',
  templateUrl: './edit-moonlighter.component.html',
  styleUrls: ['./edit-moonlighter.component.css'],
  providers: [FreeRateGridService,
    EditMoonlighterComponentService,
    EditMoonlighterComponentDataService,
    EditMoonlighterComponentFormService,
    EditMoonlighterComponentSignalRService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMoonlighterComponent implements OnInit, OnDestroy, IEditMoonlighterComponent {
  @Input() settings: EditMoonlighterComponent_Settings = null;

  @Output() cancel$ = new EventEmitter<void>();
  @Output() save$ = new EventEmitter<EditMoonlighterComponent_SaveEvent>();

  private _rateStep: number;
  get rateStep(): number {
    return this._rateStep;
  }
  set rateStep(value: number) {
    this._rateStep = value;
    this.changeDetectorRef.detectChanges();
  }

  private _employeeTextBoxValue: string = '';
  get employeeTextBoxValue(): string {
    return this._employeeTextBoxValue;
  }
  set employeeTextBoxValue(value: string) {
    this._employeeTextBoxValue = value;
    this.changeDetectorRef.detectChanges();
  }

  private _typeEmployments: Array<IDropDownItem> = null;
  get typeEmployments(): Array<IDropDownItem> {
    return this._typeEmployments;
  }
  set typeEmployments(value: Array<IDropDownItem>) {
    this._typeEmployments = value;
    this.changeDetectorRef.detectChanges();
  }

  private _rateMax: number = 1;
  get rateMax(): number {
    return this._rateMax;
  }
  set rateMax(value: number) {
    this._rateMax = value;
    this.changeDetectorRef.detectChanges();
  }

  private _percentMax: number = 100;
  get percentMax(): number {
    return this._percentMax;
  }
  set percentMax(value: number) {
    this._percentMax = value;
    this.changeDetectorRef.detectChanges();
  }

  private _minRange: Date;
  get maxRange(): Date {
    return this._maxRange;
  }
  set maxRange(value: Date) {
    this._maxRange = value;
    this.changeDetectorRef.detectChanges();
  }

  private _maxRange: Date;
  get minRange(): Date {
    return this._minRange;
  }
  set minRange(value: Date) {
    this._minRange = value;
    this.changeDetectorRef.detectChanges();
  }

  public formObj$: Observable<EditMoonlighterComponentFormService_Result> = new ReplaySubject<EditMoonlighterComponentFormService_Result>(1);

  private _formObj: EditMoonlighterComponentFormService_Result;
  get formObj(): EditMoonlighterComponentFormService_Result {
    return this._formObj;
  }
  set formObj(value: EditMoonlighterComponentFormService_Result) {
    this._formObj = value;
    (this.formObj$ as Subject<EditMoonlighterComponentFormService_Result>).next(value);

    if (this.settings.combinationType !== 'moonlighter')
      this.formObj.controls.percent.addValidators(Validators.required);

    this.changeDetectorRef.detectChanges();
  }

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private service: EditMoonlighterComponentService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
    service.component = this;
  }

  public ngOnInit(): void {
    if(!this.settings){
      throw new Error('@Input settings НЕ передан')
    }
    this.settings.validation();
    this.service.onInit();
  }

  /** Обработка нажатия на Отмена */
  public onCancel() {
    this.cancel$.emit();
  }

  /** Обработка нажатия на Сохранить */
  public onSave() {
    switch (this.settings.type){
      case "add":
        this.service.saveAdd();
        return;
      case "edit":
        this.service.saveEdit();
        return;
      default: throw new Error('OutOfRange');
    }
  }

  /** Обработка нажатия ктопки выбора сотрудника */
  public onEmployeeSelecting(){
    this.service.openDialogSelectingEmployee();
  }

  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.service.ngOnDestroy();
    (this.formObj$ as Subject<EditMoonlighterComponentFormService_Result>).complete();
  }
}

