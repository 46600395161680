import {ReplaySubject, Subject} from "rxjs";
import {ServerLoggerService} from "../../../../../../../../src/app/services/loggers/server-logger.service";
import {Api1GraphControlControllerService} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {auditTime, buffer, take, takeUntil} from "rxjs/operators";
import {ExtensionObj} from "../../../../../../../../src/app/helpers/extensionObj";
import {CalculateNormaAndFactRequestParam, CellInfo, RowInfo} from "../../../../Classes/RowInfo";
import {GraphGridRowModel} from "../classes/view-models/row-and-graph-day-view-model.class";
import {GraphDataSourceService} from "./graph-data-source.service";
import {Injectable, OnDestroy} from "@angular/core";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {TraceParamEnum} from "../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";

/** Сервис расчета Нормы и Факта при изменениях ячеек
 * Устроен для повторного использования. Можно на ходу OnDestroy и тут же OnInit
 */
@Injectable()
@traceClass('GraphNormFactCalculatorService')
export class GraphNormFactCalculatorService implements OnDestroy{
  private unsubscribe$: ReplaySubject<any> = null;
  /** Задачи для перерасчета нормы/факта */
  private tasks: Array<{task: Array<INormFactCalculatorServiceTask>}> = null;
  /** VM данных графика */
  private dataSourceService: GraphDataSourceService = null;
  /** Идентификатор текущей редакции */
  private redactionId: number = null;

  /** Пересчитать норму и факт */
  public recalculate$ = new Subject<void>();

  constructor(
    private loggerService: ServerLoggerService,
    private api1GraphControlControllerService: Api1GraphControlControllerService,
    private tracerService: TracerServiceBase) {
  }

  /** Инициализировать */
  @traceFunc()
  onInit(redactionId: number, dataSourceService: GraphDataSourceService) {
    this.unsubscribe$ = new ReplaySubject<any>(1);
    this.tasks = new Array<{task: Array<INormFactCalculatorServiceTask>}>();
    this.redactionId = redactionId;
    this.dataSourceService = dataSourceService;

    this.recalculate$
      .pipe(
        takeUntil(this.unsubscribe$),
        buffer(this.recalculate$.pipe(takeUntil(this.unsubscribe$), auditTime(200)))
      ).subscribe(v => {
        const value = this.dataSourceService.source.filter(x => x.norm == null || x.fact == null)
        if(!value.some(x => x)){//Защищаем от пустой задачи или если onDestroy
          return;
        }

        //Помечаем ранее добавленные строки. Они не будут обновляться в юи предыдущим запросом расчета
        ([].concat(...this.tasks.map(x => x.task)) as Array<INormFactCalculatorServiceTask>)
          .filter(x => value.find(gGRM => gGRM == x.graphGridRowModel))
          .forEach(x => x.isCancel = true);

        const newTasks = value.map(item => new class implements INormFactCalculatorServiceTask{
          graphGridRowModel = item;
          isCancel = false;
        });

        this.tasks.push({task: newTasks}); //Добавляем в массив задач
        this.recalculate({task: newTasks});
    });

    this.recalculate$.next();
  }

  /** Пересчитываем норму и факт */
  private recalculate(task: {task: Array<INormFactCalculatorServiceTask>}){
    this.api1GraphControlControllerService.calculateRowNormaFact$(
      new ExtensionObj(new CalculateNormaAndFactRequestParam())
        .modResult(x => {
          x.rId = this.redactionId;
          x.gRL = task.task.map<RowInfo>(d => RowInfo.Create(
            d.graphGridRowModel.staffUnit.ownerId,
            d.graphGridRowModel.graphDays.filter(x => x != null && (x.dayDeviation || x.timeInterval))
              .map<CellInfo>(el => CellInfo.Create(el.day.date,
                (el.timeInterval) ? el.timeInterval.id : null,
                (el.dayDeviation) ? el.dayDeviation.id : null,
                el.customValue,
                el.subtractLunch,
                (el.dayDeviation) ? el.dayDeviation.isIncludeInCalculate : undefined,
                el.flexDinner)
              )));
        })).pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(v => {
      task.task.forEach(x => {
        if(x.isCancel){ //Недопускаем установку значений на отмененные следующим запросом
          return;
        }
        const staffUnitSource = v.find(z => x.graphGridRowModel.staffUnit.ownerId == z.staffUnitOwnerId);
        if (staffUnitSource) {
          x.graphGridRowModel.setNormaAndFact(staffUnitSource.result.norma, staffUnitSource.result.fact);
        } else {
          this.loggerService.warn('Посчитали норму/факт не для всех сотрудников');
        }
      });
      this.tasks = this.tasks.filter(t => t == task)
    });
  }

  @traceFunc()
  ngOnDestroy() {
    this.unsubscribe$?.next(null);
    this.unsubscribe$?.complete();
    this.unsubscribe$ = null;

    this.redactionId = null;
    this.dataSourceService = null;
  }
}

/** Интерфейс задачи */
interface INormFactCalculatorServiceTask{
  /** Модель строки */
  graphGridRowModel: GraphGridRowModel;
  /**
   * Отменено ли обновление нормы/факта после получения результата.
   * Необходимо в том случае, если строка находится в двух задачах на перерасчет
   */
  isCancel: boolean;
}
