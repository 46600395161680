<kendo-splitter *ngIf="form"
                [formGroup]="form"
                orientation="vertical"
                style="height: 100%; width: 100%; border: none;">
  <kendo-splitter-pane style="margin-bottom: 5px;">
    <div id="addProxyDialogForm">
      <div style="text-align: right;"
           class="vertical-text-center-align">
        {{settings.lableAndTitle_Settings.obj}}
      </div>
      <div>
        <input kendoTextBox
               class="input-text"
               readonly="true"
               formControlName="basicEmployeeFullName">
      </div>
      <div></div>
      <div style="text-align: right;"
           class="vertical-text-center-align">
        {{settings.lableAndTitle_Settings.subj}}
      </div>
      <div>
        <input kendoTextBox
               class="input-text"
               readonly="true"
               formControlName="proxyEmployeeFullName">
      </div>
      <div>
        <button *ngIf="settings.type == 'add'"
                class="input-text"
                (click)="openPoxyStaffUnitChoiceWindow()">
          ...
        </button>
      </div>
      <div style="text-align: right" class="vertical-text-center-align">
        Ставка:
      </div>
      <div>
        <kendo-numerictextbox formControlName="proxyRate"
                              [min]=rateStep
                              [max]="maxRate"
                              [autoCorrect]="true"
                              [step]=rateStep
                              [decimals]="2"
                              format="0.00"
                              style="width: 100%"
                              KendoNumericExpanded
                              readonlyInput = true>
        </kendo-numerictextbox>
      </div>
      <div></div>
      <div *ngIf="settings.combinationType !== 'proxy'"
           style="text-align: right"
           class="vertical-text-center-align">
        Размер выплаты (%):
      </div>
      <div *ngIf="settings.combinationType !== 'proxy'">
        <kendo-numerictextbox formControlName="percent"
                              [min]="0"
                              [max]="maxPercent"
                              [autoCorrect]="true"
                              [step]="1"
                              [decimals]="2"
                              format="0.##"
                              style="width: 100%">
        </kendo-numerictextbox>
      </div>
       <div *ngIf="settings.combinationType !== 'proxy'"></div>
      <div style="text-align: right"
           class="vertical-text-center-align">
        Дата начала:
      </div>
      <div>
        <kendo-datepicker [readOnlyInput]="true"
                          [min]="minShownDate"
                          [max]="maxShownDate"
                          [disabledDates]="startDateValidator"
                          style="width: 100%"
                          formControlName="proxyStartDate">
          <kendo-datepicker-messages
            today="Сегодня">
          </kendo-datepicker-messages>
        </kendo-datepicker>

        <kendo-formerror *ngIf="form.controls.proxyStartDate.invalid">{{form.controls.proxyStartDate.errors.io}}</kendo-formerror>
      </div>
      <div></div>
      <div style="text-align: right"
           class="vertical-text-center-align">
        Дата окончания:
      </div>
      <div>
        <kendo-datepicker
          [readOnlyInput]="true"
          [min]="minShownDate"
          [max]="maxShownDate"
          [disabledDates]="endDateValidator"
          style="width: 100%"
          formControlName="proxyEndDate">
          <kendo-datepicker-messages
            today="Сегодня">
          </kendo-datepicker-messages>
        </kendo-datepicker>

        <kendo-formerror *ngIf="form.controls.proxyEndDate.invalid">{{form.controls.proxyEndDate.errors.io}}</kendo-formerror>
      </div>
      <div></div>

      <div style="text-align: right"
           class="vertical-text-center-align">
        Количество часов:
      </div>
      <kendo-numerictextbox
        class="input-text"
        readonly="true"
        [spinners]="false"
        formControlName="proxyHours">
      </kendo-numerictextbox>
      <div></div>
      <div style="text-align: right; line-height: 1.2em;"
           class="vertical-text-center-align">
        Количество календарных дней:
      </div>
      <kendo-numerictextbox
        class="input-text"
        [format]="'n0'"
        readonly="true"
        [spinners]="false"
        formControlName="proxyDays">
      </kendo-numerictextbox>
      <div></div>

      <div style="text-align: right"
           class="vertical-text-center-align">
        {{settings.lableAndTitle_Settings.reason}}
      </div>
      <div>
        <input kendoTextBox
               class="input-text"
               readonly="true"
               [title]="(settings.selectedDayDeviation && settings.selectedDayDeviation.name.length > 30)?
                      settings.selectedDayDeviation.shortName + ' ' + settings.selectedDayDeviation.name:
                      ''"
               formControlName="selectedDayDeviation">
      </div>
      <div>
        <button class="input-text"
                (click)="openDayDeviationDialog = true">
          ...
        </button>
      </div>
      <div *ngIf="settings.combinationType == 'proxy'"
           class="vertical-text-center-align"
           style="line-height: 1.2; text-align: right;">
        Участвует в выплате компенсации за молоко:
      </div>
      <input kendoCheckBox
             *ngIf="settings.combinationType == 'proxy'"
             style="align-self: center;"
             type="checkbox"
             [indeterminate]="false"
             formControlName="milk">
    </div>

    <div style="display: flex; width: 100%">
      <kendo-floatinglabel class="proxy-comment"
                               text="Комментарий">
        <textarea kendoTextArea
                  maxlength="100"
                  formControlName="comment"
                  style="width: 100%; resize: none; overflow: auto;">
        </textarea>
      </kendo-floatinglabel>
    </div>
  </kendo-splitter-pane>

  <kendo-splitter-pane [resizable]="false" style="height: auto; margin-top: 5px;">
    <div class="k-form-buttons"
         style="display: flex; justify-content: flex-end; width: 100%">
      <button kendoButton
              (click)="onCancel()">
        Отмена
      </button>
      <button kendoButton
              primary="true"
              (click)="onSave()"
              [disabled]="!form.valid || form.pristine">
        Сохранить
      </button>
    </div>
  </kendo-splitter-pane>

</kendo-splitter>

<app-day-deviation-dialog *ngIf="openDayDeviationDialog"
                          (cancel$)="onDayDevisionSelectingCancel()"
                          (select$)="onDayDevisionSelected($event)">
</app-day-deviation-dialog>

<app-staffunit-list-dialog *ngIf="openStaffUnitDialog"
                           (cancel$)="onProxySelectingCancel()"
                           (select$)="onProxySelected($event)"
                           [proxyStartDate]="form.controls.proxyStartDate.value"
                           [proxyEndDate]="form.controls.proxyEndDate.value">
</app-staffunit-list-dialog>
