import {
  IRedactionServiceGetResponse,
  RedactionBaseService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {Inject, Injectable, OnDestroy, Self} from "@angular/core";
import {AuthService} from "../../../../../../../src/app/modules/auth/services/auth.service";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import { map, take, takeUntil, tap} from "rxjs/operators";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../../../../../src/app/services/storages/custom-storage.service";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {TraceParamEnum} from "../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../src/app/modules/trace/decorators/param.decorator";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  ICheckGraphErrorsRequest
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";

@Injectable()
@traceClass('GridToolbarManagementService', {traceParamType: TraceParamEnum.traceByDecorators})
export class GridToolbarManagementService implements OnDestroy{
  /** Идентификатор текущего пользователя */
  public readonly currentUserId: number = null;
  /** Идентификатор цели(график или табель) */
  public targetId: number;
  /** Информация о версии графика/табеля */
  public version: {
    number: number,
    isActual: boolean
  };
  /** Информация о редакции графика/табеля */
  public redaction: {
    id: number,
    date: Date,
    isActual: boolean
  }
  /** Информация о статусе графика/табеля */
  public status: {
    status: 'notEdit' | 'edit' | 'approving' | 'approved',
    editUser: {id: number, fio: string},
    canFromApproving: boolean;
  }

  /** Данные по которым строиться vm */
  public data: IRedactionServiceGetResponse;

  /** Событие изменения редакции */
  public redactionChange$: Observable<IRedactionChangeEvent> = new ReplaySubject<IRedactionChangeEvent>(1);
  /** Событие начала/окончания редактирования */
  public isEditing$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  /** Редактируется ли в данный момент. Результат события isEditing$ */
  public isEditing: boolean;
  /** Поддерживает ли выбранное подразделение функционал ведения covid журнала */
  public isSupportedCovidFunctional: boolean;

  /** Событие нажатия на Сохранить */
  public save$: Subject<string> = new Subject<string>();

  /** Событие нажатия на Проверить */
  public checkErrors$: Subject<ICheckGraphErrorsRequest> = new Subject<ICheckGraphErrorsRequest>();

  /** Стрим идентификатора редакции для сравнения */
  public compareRedactionId$: Observable<number> = new ReplaySubject(1);

  private _compareRedactionId: number = null;

  /** Идентификатор редакции для сравнения */
  public get compareRedactionId(){
    return this._compareRedactionId;
  }
  public set compareRedactionId(value){
    this._compareRedactionId = value;
    (this.compareRedactionId$ as Subject<number>).next(value);
  }

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(@traceParam() @Inject('target') @Self() public target: 'graph' | 'table',
             @Self() private redactionBaseService: RedactionBaseService,
              authService: AuthService,
              private storageService: CustomStorageService,
              private loadingIndicatorService: LoadingIndicatorService,
              private readonly tracerService: TracerServiceBase,
              ) {
    this.currentUserId = authService.user.Id;

    this.isEditing$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {this.isEditing = value});

    this.storageOptions1 = new StorageOptions(
      StorageLocationEnum.SessionStorage, `GridToolbarManagementService-${target}`, null, false);
  }

  /**
   * Проинициализировать по идентификатору графика/табеля
   * @return Observable возвращающий идентификатор редакции
   */
  @traceFunc()
  onInit1(graphOrTableId: number): Observable<void>{
    const storageObj1 = this.storageService.get<ISessionStorageObj1>(this.storageOptions1);
    if(storageObj1?.graphOrTableId != graphOrTableId){
      this.storageService.remove(this.storageOptions1);
    }

    return this.toWrap(this.redactionBaseService.get$(graphOrTableId))
      .pipe(tap(x => {
        const storageObj1 = this.getStorage(x.graphTable.id);
        this.OnInit4(x, storageObj1?.redactionId, true);
      }), map(x => null));
  }

  /**
   * Проинициализировать по идентификатору редакции
   * @return Observable возвращающий идентификатор редакции
   */
  @traceFunc()
  onInit2(redactionId: number): Observable<void>{
    throw new Error('Not Implement')
  }

  /**
   * Проинициализировать по идентификатору подразделения/году/месяцу
   * @return Observable возвращающий идентификатор редакции
   */
  @traceFunc()
  onInit3(subdivisionOwnerId: number, year: number, month: number): Observable<void>{
    return this.toWrap(
      this.redactionBaseService.get2$(subdivisionOwnerId, year, month)
    ).pipe(tap(x => {
      const storageObj1 = this.getStorage(x.graphTable.id);
      this.OnInit4(x, storageObj1?.redactionId, true)
    }), map(x => null))
  }

  /**
   * Проинициализировать
   * @param data данные для инициализации
   * @param redactionId идентификатор редакции, если передано null делает актуальную(последнюю) редакцию
   * @param emitChangeIfEquals Эмитить ли событие изменения редакции, если редакция не изменилась
   * @param afterSave Происходит инициализация после сохранения?
   * @constructor
   */
  @traceFunc()
  public OnInit4(data: IRedactionServiceGetResponse, redactionId: number, emitChangeIfEquals: boolean, afterSave: boolean = false){
    if(!redactionId || redactionId == data.actualRedactionId){
      this.storageService.remove(this.storageOptions1);
    }else {
      this.storageService.set<ISessionStorageObj1>(
        this.storageOptions1,
        {redactionId: redactionId, graphOrTableId: data.graphTable.id});
    }

    this.data = data;
    this.targetId = data.graphTable.id;

    redactionId = !!redactionId ? redactionId : data.actualRedactionId;
    let redaction = data.redactions.find(x => x.id == redactionId);
    if(!redaction){
      redaction = data.redactions.find(x => x.id == data.actualRedactionId);
      this.storageService.remove(this.storageOptions1);
    }

    const version = data.versions.find(x => x.id == redaction?.version)
    this.version = {
      number: version?.id,
      isActual: version?.id == data.actualVersionId
    }

    this.redaction = {
      id: redaction.id,
      date: redaction.date,
      isActual: redaction.id == data.actualRedactionId
    }

    const editUser = data.users.find(x => x.id == data.userEditId);
    this.status = {
      canFromApproving: data.canFromApproving,
      status: data.inWork ?
        (!data.userEditId ? "notEdit" : "edit") :
        (data.isApproved ? "approved" : "approving"),
      editUser: editUser
    };

    if(data.userEditId == this.currentUserId){
      this.isEditing$.next(true);
    }

    const emitRedactionChange = () => {
      (this.redactionChange$ as ReplaySubject<IRedactionChangeEvent>).next({
        redactionId: this.redaction.id,
        afterSave: afterSave
      })
    }

    if(emitChangeIfEquals){
      emitRedactionChange();
    }else {
      this.redactionChange$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(preRedaction => {
        if(!emitChangeIfEquals && preRedaction.redactionId == this.redaction.id){
          return;
        }
        emitRedactionChange();
      });
    }
  }

  /** Обработка события нажатия на кнопку Проверить */
  public onCheckErrors(){
    this.checkErrors$.next({month: this.data.graphTable.month, subdivisionId: this.data.graphTable.subdivisionOwnerId});
  }

  /** Получить хранимый объект. Если идентификатор цели не равняется хранимому то сбрасывает */
  private getStorage(graphOrTableId: number): ISessionStorageObj1{
    let storageObj1 = this.storageService.get<ISessionStorageObj1>(this.storageOptions1)
    if(!!storageObj1 && storageObj1.graphOrTableId != graphOrTableId){
      this.storageService.remove(this.storageOptions1);
      storageObj1 = null;
    }
    return storageObj1;
  }

  /** Обвернуть Observable в индикатор загрузки */
  private toWrap(observable: Observable<IRedactionServiceGetResponse>): Observable<IRedactionServiceGetResponse>{
    return this.loadingIndicatorService.addToObservable(
      'Получение данных о редакциях/версиях',
      observable
    );
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

  private readonly storageOptions1: StorageOptions;
}

interface ISessionStorageObj1{
  graphOrTableId: number,
  redactionId: number
}

/** Интерфейс события изменения редакции */
interface IRedactionChangeEvent{
  /** Идентификатор редакции */
  redactionId: number,
  /** Изменение редакции происходит после сохранения? */
  afterSave: boolean
}
