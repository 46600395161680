import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as moment from "moment";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-inline-day-picker',
  templateUrl: './covid-day-picker.component.html',
  styleUrls: ['./covid-day-picker.component.css']
})
@traceClass('CovidDayPickerComponent')
export class CovidDayPickerComponent implements OnInit, OnDestroy {
  @Input() settings: CovidDayPickerComponentSettings;
  @Input() disabled: boolean = false;
  @Output() dayChange$: EventEmitter<number> = new EventEmitter<number>();
  public data: Array<ICovidDayPickerItem> = null;
  public selected: ICovidDayPickerItem = null;

  constructor(private readonly tracerService: TracerServiceBase) { }

  @traceFunc()
  ngOnInit() {
    if(!this.settings){
      throw new Error('Переданы не валидные настройки компонента')
    }

    this.data = [];
    const daysInMonth = moment({year: this.settings.year, month: this.settings.month, date: 1}).daysInMonth();
    for (let day = 1; day <= daysInMonth; day++){
      const disabled = this.settings.disables ?
        this.settings.disables.some(x => x == day) :
        false;
      const hasCovid = this.settings.hasCovids ?
        this.settings.hasCovids.some(x => x == day) :
        false;
      this.data.push({
        id: day,
        disabled: disabled,
        hasCovid: hasCovid
      })
    }

    this.selected = this.settings.selected == null ? null : this.data.find(x => x.id == this.settings.selected);
    if(this.selected && this.selected.disabled){
      this.selected = null;
    }
  }

  /** Обработка нажатия на кнопку дня */
  @traceFunc()
  public onButtonClick(item: ICovidDayPickerItem) {
    if(item.disabled){
      return;
    }
    this.selected = item;
    this.dayChange$.emit(item.id);
  }

  /** Установить флаг содержит ли ковид у выделенного дня **/
  @traceFunc()
  public setHasCovidToSelectDay(value: boolean){
    if(!this.selected){
      return;
    }
    this.selected.hasCovid = value;
  }

  @traceFunc()
  ngOnDestroy() {
  }
}

export class CovidDayPickerComponentSettings{
  /**
   *
   * @param year Год
   * @param month Месяц
   * @param disables Список отключенных кнопок
   * @param hasCovids Список содержащие ковид записи
   * @param selected Выделенный день
   */
  constructor(public year: number,
              public month: number,
              public disables: Array<number>,
              public hasCovids: Array<number>,
              public selected: number,) {
  }
}

interface ICovidDayPickerItem{
  id: number,
  disabled: boolean,
  hasCovid: boolean
}
