<kendo-grid [kendoGridBinding]="staffUnits"
            kendoGridSelectBy="id"
            [(selectedKeys)]="selectedStaffUnitIds"
            [selectable]="{mode: 'multiple',
                  enabled: true,
                  checkboxOnly: true}"
            scrollable="scrollable"
            class="staff-unit-grid"
>
  <kendo-grid-checkbox-column [width]="30"
                              [showSelectAll]="true">
  </kendo-grid-checkbox-column>

  <kendo-grid-column field="employeeName"
                     width="150"
                     title="ФИО">
  </kendo-grid-column>
  <kendo-grid-column field="occupationAndTypeNames"
                     width="300"
                     title="Должность">
  </kendo-grid-column>
  <kendo-grid-column field="rate"
                     width="50"
                     title="Ставка">
  </kendo-grid-column>
</kendo-grid>

<div class='k-form-buttons' style='justify-content: flex-end;'>
  <button kendoButton
          type='button'
          primary='true'
          [disabled]="!selectedStaffUnitIds.length"
          (click)='onOk()'>
    Сформировать
  </button>

  <button kendoButton
          type='button'
          (click)='onCancel()'>
    Отменить
  </button>
</div>


