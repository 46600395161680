import { MenuItem } from "@progress/kendo-angular-menu";
import { TimeInterval } from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";

/** Панель - временные интервалы */
export interface ITimeIntervalPanelComponent {

  /** Отключена ли панель */
  disabled: boolean

  /** Идентификатор подразделения */
  subdivisionOwnerId: number

  /** Источник данных */
  dataSource: Array<TimeIntervalPanelItem>

  /** Флаг - в режиме удаления временных интервалов? */
  isRemoving: boolean

  /** Флаг - в режиме добавления временного интервала? */
  isAdding: boolean

  /** Список контекстного меню для интервала */
  contextMenuArray: Array<MenuItem>

}

/** Элемент панели Временные интервалы */
export class TimeIntervalPanelItem {

  /** Временной интервал */
  public timeInterval: TimeInterval
  /** Дата начала как строка */
  public startIntervalStr: string;
  /** Дата окончания как строка */
  public endIntervalStr: string;
  /** Флаг - может ли быть удаленным. Есть интервалы которые общие.. их нельзя удалять */
  public canRemove: boolean;

  /** Флаг - выделен ли. Используется для удаления нескольких временных интервалов */
  public isSelected: boolean;

  /** Флаг - что он был только что добавлен. Используется для подсветки нового временного интервала */
  public asNew: boolean;

  constructor(timeInterval: TimeInterval,
              canRemove: boolean,
              isSelected: boolean = false,
              asNew: boolean = false) {
    this.timeInterval = timeInterval;
    this.startIntervalStr = TimeInterval.intervalToString(timeInterval.startInterval);
    this.endIntervalStr = TimeInterval.intervalToString(timeInterval.endInterval);
    this.canRemove = canRemove;
    this.isSelected = isSelected;
    this.asNew = asNew;
  }

}

/** Выделенные ячейки */
export interface TimeIntervalPanelComponentService_SelectedCells {

  readonly cells: {
    deviation: {
      name: string,
      deviationWithInterval: boolean
    }
  }[]

}

/** Интерфейс события клика по временному интервалу */
export interface ITimeIntervalClickEvent {

  /** Временной интервал */
  timeInterval: TimeInterval,
  /** Очищать ли отклонения */
  isClearDeviation: boolean

}
