import {Injectable, OnDestroy} from '@angular/core';
import {race, ReplaySubject} from "rxjs";
import {DialogService} from "@progress/kendo-angular-dialog";
import {delay, take, takeUntil} from "rxjs/operators";
import {TableDismissedComponent} from "../table-dismissed/table-dismissed.component";

@Injectable({
  providedIn: 'root'
})
export class TableDismissedDialogService implements OnDestroy {

  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly dialogService: DialogService) { }

  /** Открыть диалог */
  public showDialog(subdivisionId: number, month: Date, redactionId: number){
    const dialogRef = this.dialogService.open({
      title: "Список уволенных сотрудников",
      content: TableDismissedComponent,
      height: '80%',
      width: '800px',
      minWidth: '500px'
    });
    const component = dialogRef.content.instance as TableDismissedComponent;
    component.subdivisionId = subdivisionId;
    component.month = month;
    component.redactionId = redactionId;

    const unsubscribeRice = race(this.unsubscribe$, component.cancel$, component.select$)
      .pipe(delay(1));
    component.cancel$.pipe(take(1), takeUntil(unsubscribeRice))
      .subscribe(value => dialogRef.close());

    component.select$.pipe(take(1), takeUntil(unsubscribeRice))
      .subscribe(value => {
        dialogRef.close();
      });
  }

  ngOnDestroy(){
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
