import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {
  ICalculateNormaFactResponse,
  ICalculateNormaFactResult
} from "../../../../classes/requestResults/CalculateNormaFactResponse";
import {
  CalculateNormaAndFactRequestParam
} from "../../../../../../projects/timesheet/src/app/Classes/RowInfo";
import {GetGraphModelResult} from "../../../../../../projects/timesheet/src/app/Classes/GetGraphModelResult";
import {map} from "rxjs/operators";
import {IRedactionServiceGetResponse} from "./redactions/redaction-base.service";
import {IStaffUnit, StaffUnit} from "../../../../classes/domain/POCOs/stafflist/StaffUnit";
import {IExcludeMilkLog} from "../../../../classes/domain/POCOs/timesheet/ExcludeMilkLog";
import {
  HierarchiStringSmall
} from "../../../../classes/requestResults/EditEntityWithDependenciesErrorData";
import {IDumpListChangeRange} from "../../../../classes/dumps/dump-list-change";
import {DumpList, IDumpList, ReplaceIDumpListToDumpListType} from "../../../../classes/dumps/dump-list";
import {ISubdivision} from "../../../../classes/domain/POCOs/stafflist/Subdivision";
import {IOccupation} from "../../../../classes/domain/POCOs/stafflist/Occupation";
import {IWorkMode} from "../../../../classes/domain/POCOs/stafflist/WorkMode";
import {IPosition} from "../../../../classes/domain/POCOs/stafflist/Position";
import {IEmployee} from "../../../../classes/domain/POCOs/stafflist/Employee";
import {IStaffUnitType} from "../../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {IFinancingSource} from "../../../../classes/domain/POCOs/stafflist/FinancingSource";
import {IDay} from "../../../../classes/domain/POCOs/timesheet/Day";
import {IDayType} from "../../../../classes/domain/POCOs/timesheet/DayType";
import {ITimeInterval} from "../../../../classes/domain/POCOs/timesheet_graph/TimeInterval";
import {IDayDeviation} from "../../../../classes/domain/POCOs/timesheet_graph/DayDeviation";
import {IGraphDay} from "../../../../classes/domain/POCOs/timesheet_graph/GraphDay";
import {ICovidLog} from "../../../../classes/domain/POCOs/timesheet/CovidLog";
import {ICovidLog2} from "../../../../classes/domain/POCOs/timesheet/CovidLog2";
import {IVichLog} from "../../../../classes/domain/POCOs/timesheet/VichLog";
import {ITuberLog} from "../../../../classes/domain/POCOs/timesheet/TuberLog";
import {IGraph} from "../../../../classes/domain/POCOs/timesheet_graph/Graph";

/**
 * Сервис работы с контроллером GraphControlController
 */
@Injectable({
  providedIn: "root"
})
export class Api1GraphControlControllerService {

  public constructor(
    private httpClient: HttpClient,
    private webApi1Service: WebApi1Service
  ) {  }

  /** Объект инициализации Графика(Подразделение, Год, Месяц) */
  public get getDataForGraph$(): Observable<GetDataForGraphResponse>{
    return this.httpClient.get<GetDataForGraphResponse>(
      this.webApi1Service.controllers.graphControl.actions.getDataForGraph.toString()
    )
  }

  /**
   * Получить данные для построения графика
    * @param redactionId идентификатор редакции
   * @param staffUnitIds фильтр по идентификаторам исполнения должности
   * @param includeDays включать ли в ответ {@link IDay} и {@link IDayType}
   */
  public getGraphRow2$(redactionId: number,
                       staffUnitIds: number[] | undefined,
                       includeDays: boolean,
                       includeGraph: boolean,
                       changedGraphDays: Pick<IGraphDay, 'staffUnitId' | 'date' | 'timeIntervalId' | 'dayDeviationId' | 'dayDeviationCustomValue' | 'substractLunchFlag' | 'flexDinner'>[]): Observable<IGetGraphRowResponse> {

    const changedGraphDaysCopy: Parameters<typeof this.getGraphRow2$>[4] = changedGraphDays
      .map(x => ({
        staffUnitId: x.staffUnitId,
        date: x.date,
        timeIntervalId: x.timeIntervalId,
        dayDeviationId: x.dayDeviationId,
        dayDeviationCustomValue: x.dayDeviationCustomValue,
        substractLunchFlag: x.substractLunchFlag,
        flexDinner: x.flexDinner,
      }))

    return this.httpClient.post<IGetGraphRowResponseInternal>(
      this.webApi1Service.controllers.graphControl.actions.getGraphRow.toString(),
      {
        redactionId: redactionId,
        staffUnitIds: staffUnitIds,
        includeDays: includeDays,
        includeGraph: includeGraph,
        changedGraphDays: changedGraphDaysCopy
      }
    ).pipe(
      map(x => {
        return {
          graph: x.graph,
          auditDate: x.auditDate,
          rows: x.rows,
          subdivisions: DumpList.CreateMany(x.subdivisions),
          occupations: DumpList.CreateMany(x.occupations),
          workModes: DumpList.CreateMany(x.workModes),
          positions: DumpList.CreateMany(x.positions),
          employees: DumpList.CreateMany(x.employees),
          staffUnits: DumpList.CreateMany(x.staffUnits),
          staffUnitTypes: x.staffUnitTypes,
          financingSources: x.financingSources,
          days: x.days,
          dayTypes: x.dayTypes,
          timeIntervals: x.timeIntervals,
          dayDeviations: x.dayDeviations,
          graphDays: x.graphDays,
          covidLogs: x.covidLogs,
          covidLog2s: x.covidLog2s,
          vichLogs: x.vichLogs,
          tuberLogs: x.tuberLogs,
          excludeMilkLogs: x.excludeMilkLogs
        }
      })
    );
  }

  /** @deprecated Получение строк графика для рендеринга */
  public getGraphRow$(graphRedactionId: number): Observable<GetGraphModelResult>{
    return this.httpClient.get<GetGraphModelResult>(
      this.webApi1Service.controllers.graphControl.actions.getGraphRow.toString(),
      {
        params: new HttpParams({
          fromObject: {
            graphRedactionId: graphRedactionId.toString()
          }})
      });
  }

  /** Получение нормы и фактического отработанного времени */
  public calculateRowNormaFact$(calculateEntity: CalculateNormaAndFactRequestParam): Observable<Array<ICalculateNormaFactResponse>>{
    return this.httpClient.post<Array<{s: number, r: {n: number, f: number}}>>(
      this.webApi1Service.controllers.graphControl.actions.calculateRowNormaFact.toString(),
      calculateEntity
    ).pipe(map(value => {
      return value.map(item => new class implements ICalculateNormaFactResponse{
        staffUnitOwnerId= item.s;
        result = new class implements ICalculateNormaFactResult {
          fact = item.r.f;
          norma = item.r.n;
          nightTimeHours = null;
          holidayHours = null;
          dayFactInfos = null;
        }
      })
    }));
  }

  /** Рассчитать норму для должности и ставке за определенный период */
  public calculateNormaForPosition$(redactionId: number, positionId: number, rate: number, startDate: Date, endDate: Date) : Observable<number>{
    return this.httpClient.post<number>(this.webApi1Service.controllers.graphControl.actions.calculateNormaForPosition.toString(),
      {
        redactionId: redactionId,
        positionOwnerId: positionId,
        rate: rate,
        startDate: startDate,
        endDate: endDate
      }
    );
  }

  /** @deprecated Сохранить график */
  public saveGraphObsolete$(redactionId: number, comment: string, items: Array<ISaveGraphRequestGraphDaysItem>): Observable<ISaveGraphResponse>{
    return this.httpClient.post<ISaveGraphResponse>(
      this.webApi1Service.controllers.graphControl.actions.saveGraphObsolete.toString(),
      {
        redactionId: redactionId,
        comment: comment,
        items: items
      }
    );
  }

  /** Сохранить график */
  public saveGraph$(redactionId: number,
                     comment: string,
                     graphDays: Array<Pick<IGraphDay, 'staffUnitId' | 'timeIntervalId' | 'dayDeviationId' | 'dayDeviationCustomValue' | 'date' | 'substractLunchFlag' | 'flexDinner'>>){
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.graphControl.actions.saveGraph.toString(),
      {
        redactionId: redactionId,
        comment: comment,
        graphDays: graphDays
      }
    )
  }

  /** Проверить график на ошибки */
  public checkErrors$(checkParams: ICheckGraphErrorsRequest): Observable<Array<ICheckGraphErrorsResponse>>{
    return this.httpClient.post<Array<ICheckGraphErrorsResponse>>(
      this.webApi1Service.controllers.graphControl.actions.checkErrors.toString(),
      {
        month: checkParams.month,
        subdivisionId: checkParams.subdivisionId
      }
    )
  }

  /** Удаление StaffUnit из графика */
  public deleteStaffUnit$(redactionId: number, ownerId: number, timestamp: []): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.graphControl.actions.deleteStaffUnit.toString(),
      {
        redactionId: redactionId,
        ownerId: ownerId,
        timestamp: timestamp
      }
    );
  }

  /** Добавление Moonlighter */
  public addMoonlighter$(param: AddMoonlighter$Param){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addMoonlighter.toString(),
      param)
  }

  /** Редактирование Moonlighter */
  public editMoonlighter$(param: EditMoonlighter$Param){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editMoonlighter.toString(),
      param)
  }

  /** Добавление Proxy в БД */
  public addProxy$(parentOwnerId: number, proxyEmployeeOwnerId: number, startDate: Date, endDate: Date,
                   rate: number, comment: string, staffUnitType: string, milk: boolean): Observable<StaffUnit>{
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addProxy.toString(),
      {
        parentOwnerId: parentOwnerId,
        proxyEmployeeOwnerId: proxyEmployeeOwnerId,
        startDate: startDate,
        endDate: endDate,
        rate: rate,
        comment: comment,
        staffUnitType: staffUnitType,
        milk: milk
      });
  }

  /** Редактирование существующей в БД записи о Proxy */
  public editProxy$( redactionId: number, proxyOwnerId: number,
                     proxyStartDate: Date, proxyEndDate: Date,
                     proxyRate: number, proxyPercent: number,
                     milk: boolean, proxyComment: string,
                     timestamp: []): Observable<StaffUnit>{
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editProxy.toString(),
      {
        redactionId: redactionId,
        proxyOwnerId: proxyOwnerId,
        startDate: proxyStartDate,
        endDate: proxyEndDate,
        rate: proxyRate,
        percent: proxyPercent,
        milk: milk,
        comment: proxyComment,
        timestamp: timestamp
      });
  }

  /** Редактирование существующей в БД записи о Combination, назначенного на занятую ставку */
  public editCombinationBusy$( redactionId: number, proxyOwnerId: number,
                     proxyStartDate: Date, proxyEndDate: Date,
                     proxyRate: number, proxyPercent: number,
                     proxyComment: string, timestamp: []): Observable<StaffUnit>{
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editCombinationBusy.toString(),
      {
        redactionId: redactionId,
        proxyOwnerId: proxyOwnerId,
        startDate: proxyStartDate,
        endDate: proxyEndDate,
        rate: proxyRate,
        percent: proxyPercent,
        comment: proxyComment,
        timestamp: timestamp
      });
  }

  /** Редактирование существующей в БД записи о Combination, назначенного на свободную ставку */
  public editCombinationFree$(param: EditCombinationFree$Param){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editCombinationFree.toString(),
      param)
  }

  /** Добавление Combination на занятую ставку в БД */
  public addCombinstionBusy$(parentOwnerId: number, combinationEmployeeOwnerId: number, startDate: Date, endDate: Date, rate: number, percent: number, comment: string, staffUnitType: string): Observable<StaffUnit>{
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addCombinationBusy.toString(),
      {
        parentOwnerId: parentOwnerId,
        proxyEmployeeOwnerId: combinationEmployeeOwnerId,
        startDate: startDate,
        endDate: endDate,
        rate: rate,
        percent: percent,
        comment: comment,
        staffUnitType: staffUnitType
      });
  }

  /** Добавление Combination на свободную ставку в БД */
  public addCombinationFree$(param: AddCombinationFree$Param){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addCombinationFree.toString(),
      param)
  }

  /** Добавление дежурства (DutyStaffUnit) в БД */
  public addDuty$(redactionId: number, baseStaffUnitOwnerId: number,
                  start: Date, end: Date, comment: string, positionId: number, milk: boolean){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addDuty.toString(),
      {
        redactionId: redactionId,
        baseStaffUnitOwnerId: baseStaffUnitOwnerId,
        startDate: start,
        endDate: end,
        comment: comment,
        positionId: positionId,
        milk: milk
      })
  }

  /** Редактирование существующей в БД записи о дежурном (Duty) */
  public editDuty$(redactionId: number, ownerId: number, positionId: number,
                   start: Date, end: Date, milk: Date,
                   comment: string, timestamp: []){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editDuty.toString(),
      {
        redactionId: redactionId,
        ownerId: ownerId,
        positionId: positionId,
        startDate: start,
        endDate: end,
        milk: milk,
        comment: comment,
        timestamp: timestamp
      })
  }

  /** Добавление сотрудника УВОР на свободную ставку */
  public addUvorFree$(param: AddCombinationFree$Param){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addUvorFree.toString(),
      param);
  }

  /** Добавление сотрудника УВОР на занятую ставку */
  public addUvorBusy$(parentOwnerId: number, combinationEmployeeOwnerId: number,
                      startDate: Date, endDate: Date, rate: number,
                      percent: number, comment: string, staffUnitType: string): Observable<StaffUnit>{
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.addUvorBusy.toString(),
      {
        parentOwnerId: parentOwnerId,
        proxyEmployeeOwnerId: combinationEmployeeOwnerId,
        startDate: startDate,
        endDate: endDate,
        rate: rate,
        percent: percent,
        comment: comment,
        staffUnitType: staffUnitType
      });
  }

  /** Редактирование имеющейся в БД записи о сотруднике УВОР, назначенном на свободную ставку */
  public editUvorFree$(param: EditCombinationFree$Param){
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editUvorFree.toString(),
      param)
  }

  /** Редактирование имеющейся в БД записи о сотруднике УВОР, назначенном на занятую ставку */
  public editUvorBusy$( redactionId: number, proxyOwnerId: number,
                        proxyStartDate: Date, proxyEndDate: Date,
                        proxyRate: number, proxyPercent: number,
                        proxyComment: string, timestamp: []): Observable<StaffUnit>{
    return this.httpClient.post<StaffUnit>(this.webApi1Service.controllers.graphControl.actions.editUvorBusy.toString(),
      {
        redactionId: redactionId,
        proxyOwnerId: proxyOwnerId,
        startDate: proxyStartDate,
        endDate: proxyEndDate,
        rate: proxyRate,
        percent: proxyPercent,
        comment: proxyComment,
        timestamp: timestamp
      });
  }

  /** Добавить {@link IExcludeMilkLog} */
  public addExcludeMilkLog$(items: IExcludeMilkLogDTO[]){
    return this.httpClient.post<IExcludeMilkLog[]>(this.webApi1Service.controllers.graphControl.actions.addExcludeMilkLog.toString(),
      items)
  }

  /** Удалить {@link IExcludeMilkLog} */
  public deleteExcludeMilkLog$(items: IExcludeMilkLogDTO[]){
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.graphControl.actions.deleteExcludeMilkLog.toString(),
      items)
  }
}

/** Интерфейс параметров для методов добавления/удаления ExcludeMilkLog */
export interface IExcludeMilkLogDTO{
  staffUnitId: number,
  dates: Date[]
}

export interface GetDataForGraphResponse {
  /** Идентификатор подразделения */
  subdivisionOwnerId?: number;
  /** Год */
  yearId: number;
  /** Месяц */
  monthId: number;
}

export interface ISaveGraphRequestGraphDaysItem{
  /**Guid - по нему будут модифицироваться id из ответа */
  guid: number,
  /**StaffUnitOwnerId */
  sUOId: number,
  /** timeIntervalId */
  tIId: number,
  /** dayDeviationId */
  dDId: number,
  /** dayDeviationCustomValue */
  dDCV: number,
  date: Date,
  /** subtractLunch */
  sL: boolean
  /** timeIntervalDuration */
  tiDr: number
  /** Размер обеда, изменяемый вручную */
  flDnr: number | null
}

export interface ISaveGraphResponse{
  ids: Array<{guid, id}>,
  grouper: IRedactionServiceGetResponse
}


interface IRowItem{
  index: number,
  normFact: ICalculateNormaFactResult,
  dumpListChangeRange: IDumpListChangeRange,
}

/** Ответ сервера метода {@link Api1GraphControlControllerService.getGraphRow2$} */
interface IGetGraphRowResponseInternal{
  graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>,
  /** Дата аудита на которую формировались данные */
  auditDate: Date | undefined,
  rows: {id: number, items: IRowItem[]}[],
  subdivisions: IDumpList<ISubdivision>[],
  occupations: IDumpList<IOccupation>[],
  workModes: IDumpList<IWorkMode>[],
  positions: IDumpList<IPosition>[],
  employees: IDumpList<IEmployee>[],
  staffUnits: IDumpList<IStaffUnit>[],
  staffUnitTypes: Pick<IStaffUnitType, 'id' | 'description'>[],
  financingSources: Pick<IFinancingSource, 'id' | 'name' | 'shortName'>[],
  days: Pick<IDay, 'id' | 'dayTypeId' | 'date'>[],
  dayTypes: IDayType[],
  timeIntervals: ITimeInterval[],
  dayDeviations: IDayDeviation[],
  graphDays: IGraphDay[],
  covidLogs: ICovidLog[],
  covidLog2s: ICovidLog2[],
  vichLogs: IVichLog[],
  tuberLogs: ITuberLog[],
  excludeMilkLogs: IExcludeMilkLog[],
}

/** Результат метода {@link Api1GraphControlControllerService.getGraphRow2$} */
export type IGetGraphRowResponse = ReplaceIDumpListToDumpListType<IGetGraphRowResponseInternal>;

/** Параметр для метода */
export class AddMoonlighter$Param{
  public constructor(public readonly redactionId: number, public readonly start: Date, public readonly end: Date,
                     public readonly comment: string, public readonly employeeId: number, public readonly positionId: number,
                     public readonly financingSourceId: number, public readonly staffUnitTypeId: number, public readonly rate: number,
                     public readonly milk: boolean) {
  }
}

/** Параметр для метода */
export class EditMoonlighter$Param{
  public constructor(public readonly redactionId: number, public readonly ownerId: number,
                     public readonly start: Date, public readonly end: Date, public readonly rate: number,
                     public readonly comment: string, public readonly positionId: number,
                     public readonly financingSourceId: number, public readonly type: number,
                     public readonly milk: boolean, public readonly timestamp: []) {
  }
}

/** Параметр для метода */
export class AddCombinationFree$Param extends AddMoonlighter$Param{
  public constructor(redactionId: number, start: Date, end: Date, comment: string, employeeId: number, positionId: number,
                     financingSourceId: number, staffUnitTypeId: number, rate: number, public readonly percent: number, public readonly milk,
  ) {
    super(redactionId, start, end, comment, employeeId, positionId, financingSourceId, staffUnitTypeId, rate, milk);
  }

  public static Create1(param: AddMoonlighter$Param, percent: number){
    return new AddCombinationFree$Param(
      param.redactionId, param.start, param.end, param.comment, param.employeeId,
      param.positionId, param.financingSourceId, param.staffUnitTypeId, param.rate, percent, param.milk
    )
  }
}

/** Параметр для метода */
export class EditCombinationFree$Param extends EditMoonlighter$Param{
  public constructor(redactionId: number, ownerId: number, start: Date,
                     end: Date, rate: number, comment: string,
                     type: number, positionId: number,
                     financingSourceId: number, public readonly percent: number,
                     milk: boolean, timestamp: []) {
    super(redactionId, ownerId, start, end, rate, comment, positionId, financingSourceId, type, milk, timestamp);
  }

  public static Create1(param: EditMoonlighter$Param, percent: number){
    return new EditCombinationFree$Param(
      param.redactionId, param.ownerId, param.start, param.end, param.rate, param.comment, param.type,
      param.positionId, param.financingSourceId, percent, param.milk, param.timestamp
    )
  }
}

/** Модель запроса на сервер проверки данных графика на наличие ошиблок */
export interface ICheckGraphErrorsRequest{
  /** Месяц, за который сформирован проверяемый график */
  month: Date,
  /** Идентификатор подразделения, график которого проверяется на наличие ошибок */
  subdivisionId: number
}

/** Модель ответа сервера с результатом проверки графика на наличие ошиблок */
export interface ICheckGraphErrorsResponse{
  /** Корректны ли данные проверяемого графика */
  success: boolean,
  /** Описатель ошиблок, обнаруженных при проверке графика по определенному параметру*/
  errorsDescriptor: HierarchiStringSmall
}
