<kendo-grid [kendoGridBinding]="dataSourceService.dataSource.data$ | async"
            KendoGridExpanded
            columnsPadding="2px 4px"
            style="height: 100%; min-height: 200px"

            (add)="addRow($event)"
            (edit)="editRow($event)"
            (save)="saveRow($event)"
            (cancel)="closeRow($event.sender, $event.rowIndex)"
            (remove)="delete($event.dataItem)"

            [sortable]="true"
            [sort]="[{ field: 'id', dir: 'desc' }]"

            [pageable]="true"
            [pageSize]="15"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand primary="true" [disabled]="!!form">
      <span class="k-icon k-i-plus k-font-icon"></span>
      Добавить
    </button>
  </ng-template>

  <kendo-grid-column title="Отделение" field="subdivisionLongName" [width]="200">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-dropdowntree kendoDropDownTreeExpandable
                          [kendoDropDownTreeFlatBinding]="subdivisionsData$ | async"

                          parentIdField="parentId"
                          valueField="id"
                          textField="longName"
                          [valuePrimitive]="true"
                          [popupSettings]="{ width: 'auto' }"
                          [filterable]="true"

                          [dataItem]="currentSubdivision$ | async"
                          [formControl]="form.controls.subdivisionId"
      >
      </kendo-dropdowntree>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Номер ИБ" field="number" [width]="100">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-textbox [formControl]="form.controls.number">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="ФИО пациента" field="fio" [width]="200">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-textbox [formControl]="form.controls.fio">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Пол пациента" field="genderText" [width]="100" *ngIf="typeId === 1">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-dropdownlist [data]="[{ id: 0, text: 'Мужской' }, { id: 1, text: 'Женский' }]"
                          [valuePrimitive]="true"
                          valueField="id"
                          textField="text"

                          [formControl]="form.controls.gender"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Возраст" field="age" [width]="100" *ngIf="typeId === 1">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-numerictextbox
            [min]="0"
            [max]="200"
            format="0"
            [autoCorrect]="true"

            [formControl]="form.controls.age"
      >
      </kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Диагноз" field="diagnosis" [width]="500" *ngIf="typeId === 1">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-textbox [formControl]="form.controls.diagnosis">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="wrapped-column-header" [width]="70" [sticky]="true">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand
              primary="true"
              title="Редактировать"
              [disabled]="!!form"
              *ngIf="!dataItem.sent"
      >
        <span class="k-icon k-i-edit k-font-icon"></span>
      </button>
      <button kendoGridRemoveCommand
              title="Удалить"
              [disabled]="!!form"
              *ngIf="!dataItem.sent"
      >
        <span class="k-icon k-i-delete k-font-icon"></span>
      </button>
      <button kendoGridSaveCommand
              primary="true"
              title="Сохранить"
              [disabled]="form?.invalid || (form?.controls.id.value && form?.pristine)"
      >
        <span class="k-icon k-i-save k-font-icon"></span>
      </button>
      <button kendoGridCancelCommand title="Отмена">
        <span class="k-icon k-i-cancel k-font-icon"></span>
      </button>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
