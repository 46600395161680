import {ChangeDetectionStrategy, Component, computed, input, OnDestroy} from '@angular/core';
import {AuthService} from "../../../../../../../../src/app/modules/auth/services/auth.service";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";

/**
 * Компонент отображает статус Графика или Табеля.
 * @example Статус: В работе
  */
@Component({
  selector: 'app-graph-table-state-info',
  templateUrl: './graph-table-state-info.component.html',
  styleUrl: './graph-table-state-info.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('GraphTableStateInfoComponent')
export class GraphTableStateInfoComponent implements OnDestroy{
  /** Идентификатор пользователя редактирующий График/Табель */
  public editUserId = input<number>();
  /** Фио пользователя, редактирующего График/Табель */
  public editUserFio = input<string>();
  /** Статус Графика/Табеля */
  public status = input<string>();

  /** Выводимый текст */
  protected text = computed(() => {
    if(!this.editUserId()){
      return this.status() ?? 'НЕИЗВЕСТНЕН';
    }

    const fio = this.editUserFio() ?? 'НЕИЗВЕСТНО';
    const prefix = this.editUserId() === this.authService.user.Id ? '(Вы)' : '';
    return `редактирует ${prefix} ${fio}`;
  })

  constructor(private readonly authService: AuthService,
              private readonly traceService: TracerServiceBase) {
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {

  }
}
