import {ArrayHelper} from "../../helpers/arrayHelper";

/** Интерфейс дампа */
export interface IDump<T>{
  /**
   * Время на которое построен дамп.<br>
   * Является началом действия дампа.<br>
   */
  date: Date;

  /**
   * Окончание действия дампа.<br>
   * {@link date} следующего дампа минус 1 тик.<br>
   */
  endDate: Date;

  /**
   * Идентификаторы действий изменивших текущий дамп по сравнению с предыдущим дампом.
   * У первого дампа должен быть пустой массив.
   * Отсутствуют повторяющиеся идентификаторы.
   */
  changesByActionId: number[];

  /** Дамп */
  dump: T;
}

/** Класс дампа */
export class Dump<T> implements IDump<T>{

  /**
   * Конструктор дампа
   * @param date смотри {@link IDump.date}
   * @param endDate смотри {@link IDump.endDate}
   * @param changesByActionId смотри {@link IDump.changesByActionId}
   * @param dump смотри {@link IDump.dump}
   */
  constructor(public readonly date: Date,
              public readonly endDate: Date,
              public readonly changesByActionId: number[],
              public readonly dump: T) {
  }

  /** Проверка переданной {@link date} на нахождение между {@link date} и {@link endDate} */
  public isInside(date: Date): boolean{
    return date >= this.date && date <= this.endDate;
  }

  /**
   * Фильтровать {@link changesByActionId} по переданному списку идентификаторов действий.
   * @param actionIds Если передано {@link null} или {@link undefined} вернет полную копию {@link changesByActionId}
   */
  public filterChanges(actionIds: number[] | null | undefined): number[] {
    return !actionIds
      ? [...this.changesByActionId]
      : ArrayHelper.innerJoin(
        actionIds,
        this.changesByActionId,
          x => x,
          x => x,
        (lefts, rights) => rights);
  }

  /** Создать на основе {@link IDump} */
  public static Create<T>(source: IDump<T>): Dump<T>{
    return new Dump<T>(source.date, source.endDate, source.changesByActionId, source.dump)
  }

  /** Создать несколько на основе {@link IDump}[] */
  public static CreateMany<T>(sources: IDump<T>[]): Dump<T>[] {
    return sources.map(x => this.Create(x));
  }
}
