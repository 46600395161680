import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../../../../../src/app/services/storages/custom-storage.service";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {debounceTime, filter, take, takeUntil} from "rxjs/operators";
import {GraphTableWorkspaceComponent} from "../../graph-table-workspace/graph-table-workspace.component";
import {MonitoringBase} from "../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/monitoringBase";
import {Api1MonitoringGraphService} from "../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/api1-monitoring-graph.service";
import {Api1MonitoringTableService} from "../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/api1-monitoring-table.service";
import {AlertService} from "../../../../../../../src/app/services/alert.service";
import {ResponseObjError} from "../../../../../../../src/app/classes/requestResults/responseObjError";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {
  MonitoringGraphListener,
  MonitoringListenerBase,
  MonitoringTableListener
} from "../../../services/listeners/monitoring-listener";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {trace} from "../../../../../../../src/app/modules/trace/operators/trace";
import {TraceParamEnum} from "../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../src/app/modules/trace/decorators/param.decorator";

@Component({
  selector: 'app-approving',
  templateUrl: './approving.component.html',
  styleUrls: ['./approving.component.css'],
  providers: [
    {provide: 'initializeType', useValue: 'notInitialize'},
  ]
})
@traceClass('ApprovingComponent')
export class ApprovingComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Комментарий */
  public comment: string = '';
  /** Выполнено ли согласование */
  public isDone: boolean = false;
  /** Отключены ли кнопки управления */
  public disabledButtons = new BehaviorSubject(false);

  /** Информация о графике/табеле который согласует пользователь */
  private graphOrTable: IGraphOrTableQueryParam = null;

  @ViewChild('graphTableWorkspaceComponent') graphTableWorkspaceComponent: GraphTableWorkspaceComponent;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    commentChange: new Subject<string>()
  }

  private service: MonitoringBase;
  private monitoringListener: MonitoringListenerBase;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private customStorageService: CustomStorageService,
              private alertService: AlertService,
              private loadingIndicatorService: LoadingIndicatorService,
              private readonly tracerService: TracerServiceBase,
              api1MonitoringGraphService: Api1MonitoringGraphService,
              api1MonitoringTableService: Api1MonitoringTableService,
              monitoringGraphListener: MonitoringGraphListener,
              monitoringTableListener: MonitoringTableListener) {

    const type = this.getTypeFromQueryString();

    this.tracerService.add2('Тип', {obj: type})
    switch (type){
      case "graph":
        this.service = api1MonitoringGraphService;
        this.monitoringListener = monitoringGraphListener;
        break;
      case "table":
        this.service = api1MonitoringTableService;
        this.monitoringListener = monitoringTableListener;
        break;
      default: throw new Error('out of range');
    }

    const queryParams = this.getQueryParams();
    if(queryParams){
      this.customStorageService.set<IStorageObj>(this.storageOptions, {graphOrTable: queryParams, comment: "", isDone: false});
    }

    const storageObj = this.customStorageService.get<IStorageObj>(this.storageOptions);

    if(!storageObj?.graphOrTable?.id){
      throw new Error('Ошибка запуска окна согласования')
    }

    this.tracerService.add2('Инициализирующие параметры', {obj: storageObj})

    this.graphOrTable = storageObj.graphOrTable;
    this.comment = storageObj.comment;
    this.isDone = storageObj.isDone;
  }

  @traceFunc()
  ngOnInit(): void {
    this.streams$.commentChange.pipe(takeUntil(this.streams$.unsubscribe), debounceTime(400)).subscribe(value => {
      const storageOgj = this.customStorageService.get<IStorageObj>(this.storageOptions);
      storageOgj.comment = value;

      this.customStorageService.set<IStorageObj>(this.storageOptions, storageOgj);
    });

    this.monitoringListener.on().pipe(
      filter(value => value.data.id == this.graphOrTable.id),
      trace(this.tracerService),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(value => {
      this.tryClosePage();
    })
  }

  @traceFunc()
  ngAfterViewInit(): void {
    this.graphTableWorkspaceComponent.graphTableToolbarComponentService.change$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.disabledButtons.next(
        this.graphOrTable.subdivisionId != value.subdivisionOwnerId ||
        this.graphOrTable.year != value.yearId ||
        this.graphOrTable.month != value.monthId
      );
    })

    this.graphTableWorkspaceComponent.graphTableToolbarComponentService.onInit()
      .tryInitFromValues(+this.graphOrTable.subdivisionId, +this.graphOrTable.year, +this.graphOrTable.month)
      .init$()
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe({
        error: err => {
          this.alertService.defaultAlertOption.downloadError().showAlert();
        }
      })
  }

  /** Обработка события изменения значения комментария */
  onTextAreaValueChange($event: string) {
    this.streams$.commentChange.next($event);
  }

  /** Обработка события отмены согласования */
  @traceFunc()
  public onCancel(){
    this.onButtonClick(
      'Отменить согласование?',
      'Отмена согласования',
      this.service.cancelApproving$(this.graphOrTable.id),
      'При отмене согласования произошла ошибка'
    )
  }

  /** Обработка события отказа в согласовании */
  @traceFunc()
  public onDecline(){
    this.onButtonClick(
      'Отклонить?',
      'Отклонение',
      this.service.decline$(this.graphOrTable.id, this.comment),
      'При отклонении произошла ошибка'
    );
  }

  /** Обработка события согласования */
  @traceFunc()
  public onApproved(){
    this.onButtonClick(
      'Согласовать?',
      'Согласование',
      this.service.approved$(this.graphOrTable.id, this.comment),
      'При согласовании произошла ошибка'
    );
  }

  /** Ключ хранения данных в sessionStorage */
  private readonly storageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'ApprovingComponent',
    null,
    false,
    false
  );

  /**
   * Общая обработка событий нажатия на кнопки
   * @param confirmMessage сообщение подтвеждения действия
   * @param loadingMessage сообщение при загрузке данных
   * @param observable действие
   * @param errorTitleMessage заголовок при ошибке
   * @private
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private onButtonClick<T>(@traceParam() confirmMessage: string, @traceParam() loadingMessage: string, observable: Observable<T>, @traceParam() errorTitleMessage: string){
    this.alertService.defaultAlertOption.confirmation().mod(x => {
      x.message = confirmMessage
      x.buttons[1].callBack = () => {
        this.loadingIndicatorService.addToObservable(
          loadingMessage,
          observable
        ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({ next: value => {
          this.tryClosePage();
        }, error: error => {
          const alertOptions = this.alertService.defaultAlertOption.error().mod(x => {
            x.titleMessage = errorTitleMessage
          })

          if(ResponseObjError.checkType(error)){
            alertOptions.mod(x => x.message = (error as ResponseObjError<string>).data);
          }else {
            alertOptions.mod(x => x.message = 'Повторите попытку');
          }

          alertOptions.showAlert();
        }})
      }
    }).showAlert();
  }

  /** Получить параметры из строки запроса. После их очищает */
  @traceFunc()
  private getQueryParams(): IGraphOrTableQueryParam{
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const result: IGraphOrTableQueryParam = {
      id: queryParams['id'],
      subdivisionId: queryParams['subdivisionId'],
      year: queryParams['year'],
      month: queryParams['month']
    };

    if(result.id || result.subdivisionId || result.year || result.month){
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: ''
      });

      if(!result.id || !result.subdivisionId || !result.year || !result.month){
        throw new Error(`Переданы не все параметры: ${JSON.stringify(result)}`)
      }

      return result;
    }

    return null;
  }

  /** Получить - согласуем график или табель из строки запроса */
  @traceFunc()
  private getTypeFromQueryString(): 'graph' | 'table'{
    const type = this.activatedRoute.snapshot.firstChild?.url[0]?.path;

    switch (type){
      case 'graph':
        return 'graph';
      case 'table':
        return 'table';
      default: throw new Error('В строке запроса отсутствует или НЕ валидна инфорамция согласуется график или табель')
    }
  }

  /** Закрыть текущую вкладку */
  @traceFunc()
  private tryClosePage(){
    const storageOgj = this.customStorageService.get<IStorageObj>(this.storageOptions);
    storageOgj.isDone = true;
    this.customStorageService.set(this.storageOptions, storageOgj);

    this.isDone = true;
    setTimeout(x => {
      try {
        window.close();
      }catch (e){

      }
    }, 400);
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Объект хранящийся в sessionStorage */
interface IStorageObj{
  /** Информация о графике/табеле */
  graphOrTable: IGraphOrTableQueryParam
  /** Комментарий к согласованию */
  comment: string,
  /** Был ли результат согласования */
  isDone: boolean
}

/** Интерфейс графика/табеля хранящийся в sessionStorage */
interface IGraphOrTableQueryParam{
  id: number,
  subdivisionId: number,
  year: number,
  month: number
}
