/*
* Enum таблицы RS_Functions
 */
export enum TimeSheetFunctionEnum {
  /* График */
  GraphAccess = 'График',
  /* Редактирование графика */
  GraphEditing = 'Редактирование графика',
  /* Отправка графика */
  GraphSending = 'Отправка графика на согласование',
  /* Согласование графика */
  GraphApproving = 'Согласование графика',
  /* Просмотр всех графиков */
  AllGraphViewing = 'Просмотр всех графиков',
  /* Просмотр сводного реестра графики */
  GraphTotalRegisterViewing = 'Просмотр сводного реестра графики',
  /* Табель */
  TableAccess = 'Табель',
  /* Редактирование табеля */
  TableEditing = 'Редактирование табеля',
  /* Отправка табеля */
  TableSending = 'Отправка табеля на согласование',
  /* Согласование табеля*/
  TableApproving = 'Согласование табеля',
  /* Просмотр всех табелей */
  AllTableViewing = 'Просмотр всех табелей',
  /* Просмотр сводного реестра табели */
  TableTotalRegisterViewing = 'Просмотр сводного реестра табели',
  /* Доступ к справочникам */
  AccessToCatalogs = 'Доступ к справочникам',
  /*Администрирование */
  Administering = 'Администрирование',
  EditPatientMovementsSvod = 'Редактирование cвода движения пациентов'
}
