import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {FreeRateGridService} from "./free-rate-grid.service";
import {RowArgs, SelectionEvent} from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-free-rate-grid',
  templateUrl: './free-rate-grid.component.html',
  styleUrls: ['./free-rate-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeRateGridComponent implements OnInit, OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Список развернутых групп */
  public expandedGroupKeys: Array<{field: string, value: string}> = [];

  constructor(public readonly freeRateGridService: FreeRateGridService,
              public readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    const selectedRow = this.freeRateGridService.selectedRow;
    if(selectedRow){
      this.expandedGroupKeys.push({field: 'groupField', value: selectedRow.groupField});
    }

    this.changeDetectorRef.detectChanges();
  }

  /** Функция определяет выделена ли строка */
  public isRowSelected(view: FreeRateGridComponent): (e: RowArgs)=>boolean {
    return (e) => {
      return e.dataItem == view.freeRateGridService.selectedRow;
    }
  }

  /** Обработка события выделения строки */
  onSelectionChange($event: SelectionEvent) {
    const row = $event?.selectedRows?.length == 1 ? $event.selectedRows[0].dataItem : null;
    this.freeRateGridService.selectedRow = row;
    this.freeRateGridService.selected$.next(row);
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
