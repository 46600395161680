import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  MultiplePeriodSettingsEnum, PrintQuarterSettingsWithStaffUnitTypes
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {DropDownItem} from "../../../classes/requestResults/iDropDownItem";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {Api1DateControllerService} from "../../../services/webApi/webApi1/controllers/api1-date-controller.service";
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {
  CheckedListItemsValidator,
  RequiredSubPeriodsValue
} from "../report-settings-form-validators";
import {map, take, takeUntil} from "rxjs/operators";
import {StaffUnitType} from "../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {
  Api1StaffUnitTypeControllerService
} from "../../../services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {trace} from "../../../modules/trace/operators/trace";
import {PropertyWrapFormControlType} from "../../../classes/types/property-wrap-form-control-type";
import {Observable, share} from "rxjs";

@Component({
  selector: 'app-print-form-quarter-settings-with-staff-unit-types',
  templateUrl: './print-form-quarter-settings-with-staff-unit-types.component.html',
  styleUrl: './print-form-quarter-settings-with-staff-unit-types.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('PrintFormQuarterSettingsWithStaffUnitTypesComponent')
export class PrintFormQuarterSettingsWithStaffUnitTypesComponent implements OnInit, OnDestroy, IPrintFormSettingsComponentBase {

  @Input() public periodTypes: MultiplePeriodSettingsEnum[] = [
    MultiplePeriodSettingsEnum.year,
    MultiplePeriodSettingsEnum.halfOfYear,
    MultiplePeriodSettingsEnum.quarter,
    MultiplePeriodSettingsEnum.coupleOfMonths,
    MultiplePeriodSettingsEnum.months,
  ];

  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() public print$: EventEmitter<PrintQuarterSettingsWithStaffUnitTypes> = new EventEmitter<PrintQuarterSettingsWithStaffUnitTypes>();

  public startDate: Date;
  public endDate: Date;
  public periods: DropDownItem[];
  public subPeriodLabelText: string;
  public subPeriods: DropDownItem[] = [];
  public currentPeriod: DropDownItem;
  public periodFieldVisible: boolean;
  public form: FormGroup<PrintFormQuarterSettingsWithStaffUnitTypesFormType>;
  public staffUnitTypes$: Observable<StaffUnitType[]>;
  public years$: Observable<{ minYear: number, maxYear: number }>;
  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _months: DropDownItem[];
  private _coupleMonths: DropDownItem[];
  private _halvesOfYear: DropDownItem[];
  private _quarters: DropDownItem[];

  constructor(private readonly dateControllerService: Api1DateControllerService,
              private readonly staffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
              private readonly traceService: TracerServiceBase,

  ) {  }

  public ngOnInit(): void {
    this.periodsInit();
    this.staffUnitTypes$ = this.staffUnitTypeControllerService.getAll$()
      .pipe(trace(this.traceService),  map(x => x.filter(j => !j.deletedFlag)),
          take(1), takeUntil(this.unsubscribe$));
    const currentYear = new Date().getFullYear();

    this.years$ = this.dateControllerService.getYears$.pipe(takeUntil(this.unsubscribe$),
      map(years => {
        if (!years.length) {
          return { minYear: currentYear, maxYear: currentYear };
        }
        const minYear = years.length > 5 ? years[years.length - 5] : years[0];
        return { minYear: minYear.id, maxYear: years[years.length - 1].id}
      }), share());

    this.formInit(currentYear);
  }

  @traceFunc()
  public onClickOk() {
    this.print$.next(new PrintQuarterSettingsWithStaffUnitTypes(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.subPeriod.value?.text.toLowerCase(),
      this.startDate,
      this.endDate,
      this.form.controls.checkedStaffUnitTypes.value.map(su => su.id)));
    this.print$.complete();
  }

  @traceFunc()
  public onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  /* Инициализировать форму и подписаться на события изменения ее полей */
  private formInit(currentYear: number) {
    this.form = new FormGroup<PrintFormQuarterSettingsWithStaffUnitTypesFormType>({
      period: new FormControl<DropDownItem>(this.currentPeriod, [Validators.required]),
      subPeriod: new FormControl<DropDownItem>({value: this.subPeriods[0], disabled: !this.currentPeriod.id}, [RequiredSubPeriodsValue()]),
      year: new FormControl<number>(currentYear,[Validators.required]),
      checkedStaffUnitTypes: new FormControl<Array<StaffUnitType>>([], CheckedListItemsValidator()),
    });

    this.setStartEndDates(currentYear);

    this.form.controls.year.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.setStartEndDates(value);
      });

    this.form.controls.period.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.subPeriodLabelText = !!value.id ? `${value.text}:` : '';
        this.currentPeriod = value;
        this.subPeriods = this.getSubPeriods();
        this.form.controls.subPeriod.setValue(this.subPeriods[0]);
        !this.currentPeriod.id ? this.form.controls.subPeriod.disable() : this.form.controls.subPeriod.enable();
        this.setStartEndDates(this.form.controls.year.value);
      });

    this.form.controls.subPeriod.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        this.setStartEndDates(this.form.controls.year.value);
      });
  }

  /** Установить start end даты */
  private setStartEndDates(year: number): void{
    switch (this.currentPeriod.id) {
      case 0: // Год
        this.startDate = new Date(year, 0, 1);
        this.endDate = new Date(year, 12, 0, 23, 59, 59);
        return;
      case 1: // Полугодие
        this.setStartEndDateByPeriodLength(year, 6);
        return;
      case 2: // Квартал
        this.setStartEndDateByPeriodLength(year, 3);
        return;
      case 3: // пара месяцев
        this.setStartEndDateByPeriodLength(year, 2);
        return;
      case 4: // месяц
        this.setStartEndDateByPeriodLength(year, 1);
    }
  }

  /** Установить start/end Date согласно переданной параметром продолжительности периода*/
  private setStartEndDateByPeriodLength(year: number, periodLength: number) : void{
    this.startDate = new Date(year, (this.form.controls.subPeriod.value.id - 1) * periodLength, 1);
    this.endDate = new Date(year, (this.form.controls.subPeriod.value.id) * periodLength, 0, 23, 59, 59);
  }

  /* Инициализировать периоды, отображаемые в контроллах окна настроек отчета */
  private periodsInit(){
    this.dateControllerService.getPosibleMonth$
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(months => {
        this._months = months;
        for (let i = 0; i < 6; i++) {
          this._coupleMonths = Array.from(Array(6).keys())
            .map(i => new DropDownItem(i + 1, `${months[i * 2].text} - ${months[i * 2 + 1].text}`));
        }
      });

    this._quarters = [
      {id: 1, text: '1 квартал'},
      {id: 2, text: '2 квартал'},
      {id: 3, text: '3 квартал'},
      {id: 4, text: '4 квартал'}
    ];

    this._halvesOfYear  = [
      {id: 1, text: 'Первое полугодие'},
      {id: 2, text: 'Второе полугодие'}
    ];

    this.periods = [
      {id: 0, text:  'Год'},
      {id: 1, text: 'Полугодие'},
      {id: 2, text: 'Квартал'},
      {id: 3, text: 'Двухмесячный промежуток'},
      {id: 4, text: 'Месяц'}
    ].filter(p => this.periodTypes.some(pEn => pEn === p.id));

    this.currentPeriod = this.periods[0];
    this.periodFieldVisible = this.periodTypes.length > 1;
    this.subPeriodLabelText = !!this.currentPeriod.id ? `${this.currentPeriod.text}:` : '';
    this.subPeriods = this.getSubPeriods();
  }

  /** Получить подпериоды */
  private getSubPeriods() {
    switch (this.currentPeriod.id) {
      case 1:  return this._halvesOfYear;
      case 2: return this._quarters;
      case 3: return this._coupleMonths;
      case 4: return this._months;
      default: return [];
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

export type QuarterSettingsWithStaffUnitFormValueObj = {
  period: DropDownItem,
  subPeriod: DropDownItem,
  year: number,
  checkedStaffUnitTypes: Array<StaffUnitType>
};
export type PrintFormQuarterSettingsWithStaffUnitTypesFormType = PropertyWrapFormControlType<QuarterSettingsWithStaffUnitFormValueObj> ;
