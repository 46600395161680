import {IRedactionBase, RedactionBase} from "../timesheet/RedactionBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IGraphRedaction extends IRedactionBase{

}

@classBackend('GraphRedaction', 'timesheet_graph')
@className('GraphRedaction')
/** POCO редакции графика */
export class GraphRedaction extends RedactionBase implements IGraphRedaction{

}
