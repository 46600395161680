import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CovidRegisterSection,
  RegisterReportSettings
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  StaffUnitType
} from "../../graph-table-workspace/table-grid/classes/view-models/row-and-table-cell-view-model.class";
import {StaffUnitTypeEnum} from "../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {
  Api1StaffUnitTypeControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-covid-register-settings',
  templateUrl: './covid-register-settings.component.html',
  styleUrls: ['./covid-register-settings.component.css']
})
export class CovidRegisterSettingsComponent implements OnInit {

  /**
   * Событие отмены выбора
   */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Событие выбора
   */
  @Output() public select$: EventEmitter<RegisterReportSettings> = new EventEmitter<RegisterReportSettings>();

  @Input() public firstReportSectionYear : number;
  @Input() public firstReportSectionMonth : number;
  @Input() public firstReportSectionDate : Date;

  public maxDate: Date;
  public minDate: Date;
  public disabledDates: Array<Date>;
  /** Выбранные даты начала секций журнала */
  public selectedReportSections: Array<CovidRegisterSection> = new Array<CovidRegisterSection>();
  /** Перечень допустимых типов занятости, по которым будут формироваться данные отчета */
  public staffUnitTypes: StaffUnitType[];
  /** Выбранные типы занятости */
  public checkedStaffUnitTypes: StaffUnitType[] = [];
  /** Тип занятости не выбран */
  public needCheckStaffUnitType: boolean = true;


  constructor(public api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService) { }

  public ngOnInit(): void {
    this.minDate = this.firstReportSectionDate;
    this.maxDate = new Date(this.firstReportSectionDate.getFullYear(), this.firstReportSectionDate.getMonth() +1, 0);
    this.selectedReportSections.push(new CovidRegisterSection(this.minDate));

    this.api1StaffUnitTypeControllerService.getByIds$(
      [StaffUnitTypeEnum.Basic,StaffUnitTypeEnum.MoonlighterInner,StaffUnitTypeEnum.MoonlighterExternal])
      .pipe(take(1))
      .subscribe(x => {
        this.staffUnitTypes = x;
      });
  }

  /**
   * Удалить раздел из журнала
   */
  public DeleteReportSection(dataItem: Date, index: number) {
    if(this.selectedReportSections.length > 1) {
      this.selectedReportSections.splice(index, 1);
    }
  }

  /**
   * Добавить раздел в журнал
   */
  public AddReportSection() {
    let reportSectionCount = this.selectedReportSections.length;
    if(reportSectionCount > 1){
      this.SortItem();
    }
    let lastSectionDate = this.selectedReportSections[reportSectionCount - 1];
    this.disabledDates = this.selectedReportSections.map(x => x.SectionDate);

    if(reportSectionCount > 0 && lastSectionDate.SectionDate < this.maxDate){
      this.selectedReportSections.push(
        new CovidRegisterSection(new Date(this.firstReportSectionDate.getFullYear(),
          this.firstReportSectionDate.getMonth(),
          lastSectionDate.SectionDate.getDate() + 1)));
    }
  }

  /**
   * Обработка события нажания на кнопку "Выбрать"
   */
  public onOk() {
    this.SortItem();
    this.select$.emit(new RegisterReportSettings(
      this.selectedReportSections,
      this.checkedStaffUnitTypes.map(s => s.id)));
    this.select$.complete();
  }

  /**
   * Обработка события нажания на кнопку "Отмена"
   */
  onCancel() {
    this.cancel$.emit();
    this.cancel$.complete();
  }

  /**
   * Сортировка дат в списке дат разделов журнала
   */
  private SortItem() {
    this.selectedReportSections.sort((firstEl, secondEl) => firstEl.SectionDate > secondEl.SectionDate ? 1 : -1);
  }

  /** Обработка события выбора в списке типов исполнения должностей */
  public setCheckedStaffUnitTypeIds($event: StaffUnitType[]) {
    this.checkedStaffUnitTypes = $event;
    this.needCheckStaffUnitType = !$event.length;
  }
}
