import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap, tap} from "rxjs";
import {ComponentServiceBase} from "../../../../../../../../src/app/services/abstracts/component-service-base";
import {IGraphTableToolbarComponent} from "../i-graph-table-toolbar-component";
import {GraphTableToolbarStorageService} from "./graph-table-toolbar-storage.service";
import {GraphTableToolbarDataService} from "./graph-table-toolbar-data.service";
import {AlertService} from "../../../../../../../../src/app/services/alert.service";
import {YearMonthService, YearMonthService_Api1DateControllerService} from "../../../../../../../../src/app/services/year-month-services/year-month.service";
import {
  Api1DateControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-date-controller.service";
import {delay, map, shareReplay, takeUntil} from "rxjs/operators";
import {LoadingIndicatorService} from "../../../../../../../../src/app/services/loading-indicator.service";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {SubdivisionsTreelistComponentDataSourceService2
} from "../../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {DbChangedListener} from "../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {AuthService} from "../../../../../../../../src/app/modules/auth/services/auth.service";
import {
  Api1SubdivisionsTreelistControlControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service";
import {
  ArrayDataSourceSelection
} from "../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import {exElementByIndexOr} from "../../../../../../../../src/app/operators/ex-element-by-index-or";
import {exDistinctUntilChanged} from "../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";

/** Сервис для компонента */
@Injectable()
@traceClass('GraphTableToolbarComponentService')
export class GraphTableToolbarComponentService extends ComponentServiceBase<IGraphTableToolbarComponent> implements OnDestroy{
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Отключен ли компонент */
  public get disabled(){
    return this.component.disabled;
  }
  public set disabled(value){
    this.component.disabled = value;
  }

  /** Для подразделения */
  public readonly forSubdivision: {
    service: SubdivisionsTreelistComponentDataSourceService2,
    selection: ArrayDataSourceSelection<ISubdivision, number>
  };

  /** Сервис управления Год/Месяц */
  public readonly yearMonthService: YearMonthService;

  /** Стрим изменения состояния */
  public readonly change$: Observable<GraphTableToolbarComponentService_StateChangeEventObj> = new ReplaySubject<GraphTableToolbarComponentService_StateChangeEventObj>(1);

  /** Конструктор */
  constructor(private readonly storageService: GraphTableToolbarStorageService,
              private readonly dataService: GraphTableToolbarDataService,
              private readonly alertService: AlertService,
              private readonly dateControllerService: Api1DateControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly authService: AuthService,
              private readonly api1SubdivisionsTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService,
              private readonly traceService: TracerServiceBase) {
    super();
    this.yearMonthService = new YearMonthService();

    const subdivisionService = new SubdivisionsTreelistComponentDataSourceService2(
      this.api1SubdivisionsTreelistControlControllerService, this.loadingIndicatorService, this.dbChangedListener, this.authService, this.alertService);

    this.forSubdivision = {
      service: subdivisionService,
      selection: new ArrayDataSourceSelection<ISubdivision, number>(subdivisionService.dataSource)
    };

    this.yearMonthService.selection.data$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this._onDateChange(value?.date);
    })

    combineLatest([
      this.yearMonthService.selection.data$.pipe(exDistinctUntilChanged(undefined)),
      this.forSubdivision.selection.selectedItems.data$.pipe(exElementByIndexOr(0, undefined), exDistinctUntilChanged(undefined))
    ]).pipe(
      exDistinctUntilChanged(undefined),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(value => {
      this._onDateOrSubdivisionChange(this._convert1(value));
    })
  }

  private _onInit$: Observable<GraphTableToolbarComponentService_OnInitSettings_Values>;
  /** Инициализировать сервис */
  @traceFunc()
  public onInit(): GraphTableToolbarComponentService_OnInitSettings {
    if(!!this._onInit$){
      throw new Error('Повторная инициализация НЕ допускается')
    }

    return new GraphTableToolbarComponentService_OnInitSettings(this.traceService.copy(), this.storageService, this.dataService, (values$) => {
      this._onInit$ = combineLatest([
        values$,
        this.loadingIndicatorService.addToObservable(
          'Инициализация данных Год/Месяц',
          this.yearMonthService.reloadData$(new YearMonthService_Api1DateControllerService(this.dateControllerService)))
      ]).pipe(
        map(value => value[0]),
        tap(value => {
          this.yearMonthService.setSelection = !value ? undefined : {yearId: value.yearId, monthId: value.monthId};
        }),
        delay(20), //Делаем задержку для установки подразделения
        tap(value => {
          this.forSubdivision.selection.setIds(!value?.subdivisionOwnerId ? [] : [value.subdivisionOwnerId]);
        }),
        shareReplay(1)
      );

      return this._onInit$;
    });
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    (this.change$ as Subject<any>).complete();
    this.yearMonthService.ngOnDestroy();
    this.forSubdivision.service.ngOnDestroy();
    this.forSubdivision.selection.onDestroy();
  }

  /** Обработка изменения Даты */
  @traceFunc()
  private _onDateChange(value: Date){
    this.forSubdivision.service.reloadData$(value).pipe(takeUntil(this.streams$.unsubscribe)).subscribe();
  }

  /** Обработка изменения Даты или Подразделения */
  @traceFunc()
  private _onDateOrSubdivisionChange(value: GraphTableToolbarComponentService_StateChangeEventObj){
    this.storageService.value = !value ? null : {subdivisionOwnerId: value.subdivisionOwnerId, yearId: value.yearId, monthId: value.monthId};
    (this.change$ as Subject<GraphTableToolbarComponentService_StateChangeEventObj>).next(value);
  }

  /** Метод конвертор. Если хоть одна часть данных будет НЕ заполнена, то вернет undefined */
  private _convert1(value: [YearMonthService['selection']['data'], ISubdivision]){
    return !value || !value[0] || !value[1] ?
      undefined :
      new GraphTableToolbarComponentService_StateChangeEventObj(value[1].id, value[0].date, value[0].id.yearId, value[0].id.monthId);
  }
}

/** Объект события изменения состояния */
class GraphTableToolbarComponentService_StateChangeEventObj {
  constructor(public readonly subdivisionOwnerId: number,
              public readonly date: Date,
              public readonly yearId: number,
              public readonly monthId: number) {
  }
}

/** Значения для настроек инициализации сервиса */
export class GraphTableToolbarComponentService_OnInitSettings_Values {
  constructor(public readonly subdivisionOwnerId: number,
              public readonly yearId: number,
              public readonly monthId: number) {
  }
}

/** Класс настроек инициализации сервиса */
@traceClass('GraphTableToolbarComponentService_OnInitSettings')
export class GraphTableToolbarComponentService_OnInitSettings {
  /** Стрим для конечного выполнения */
  private stream$: Observable<GraphTableToolbarComponentService_OnInitSettings_Values> = of(null);

  /**
   * Конструктор
   * @param traceService
   * @param storageService
   * @param dataService
   * @param transformFunc функция трансформации результата. Вызывается в момент вызова метода init$()
   */
  constructor(private readonly traceService: TracerServiceBase,
              private readonly storageService: GraphTableToolbarStorageService,
              private readonly dataService: GraphTableToolbarDataService,
              private readonly transformFunc: (values$: Observable<GraphTableToolbarComponentService_OnInitSettings_Values>) => Observable<GraphTableToolbarComponentService_OnInitSettings_Values>) {
  }

  /** Попробовать проинициализировать из SessionStorage. Если успешно дальнейшее выполнение прекращается */
  @traceFunc()
  public tryInitFromSessionStorage(){
    this.stream$ = this.stream$.pipe(switchMap(value => {
      return !!value ?
        of(value) :
        of(this.storageService.value).pipe(switchMap(storageObj => !storageObj ?
          of(null) :
          of(new GraphTableToolbarComponentService_OnInitSettings_Values(storageObj.subdivisionOwnerId, storageObj.yearId, storageObj.monthId))))
    }))

    return this;
  }

  /** Попробовать проинициализировать из сервера. Если успешно дальнейшее выполнение прекращается */
  @traceFunc()
  public tryInitFromServer(){
    this.stream$ = this.stream$.pipe(switchMap(value => {
      return !!value ?
        of(value) :
        this.dataService.getDataForGraph$().pipe(switchMap(fromServer => {
          return !fromServer ?
            of(null) :
            of(new GraphTableToolbarComponentService_OnInitSettings_Values(fromServer.subdivisionOwnerId, fromServer.yearId, fromServer.monthId))
        }))
    }))


    return this;
  }

  /** Попробовать проинициализировать значениями. */
  @traceFunc()
  public tryInitFromValues(subdivisionOwnerId: number, yearId: number, monthId: number){
    this.stream$ = this.stream$.pipe(switchMap(value => {
      return !!value ?
        of(value) :
        of(new GraphTableToolbarComponentService_OnInitSettings_Values(subdivisionOwnerId, yearId, monthId))
    }))

    return this;
  }

  /** Проинициализировать */
  @traceFunc()
  public init$(): Observable<GraphTableToolbarComponentService_OnInitSettings_Values>{
    return this.transformFunc(this.stream$);
  }
}
