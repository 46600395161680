import {UntypedFormGroup} from "@angular/forms";

/**
 * Базовый класс сервисов инициализации форм
 * TInitFormParam - тип входного объекта метода initForm
 * TInitFormControls - тип объекта всех control в форме
 */
export abstract class FormServiceBase<TInitFormParam, TInitFormControls>{
  /** Метод создания формы */
  public abstract create(param: TInitFormParam): {controls: TInitFormControls, form: UntypedFormGroup};

  /**
   * Получить объект для передачи в конструктор new FormGroup
   * Конвертирует переданный объект в [key: string]: AbstractControl
   */
  protected getForFromGroup(controlObj: any){
    return Object.assign(controlObj);
  }
}
