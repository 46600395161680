<div class="wrap" [formGroup]="form">
  <div class="timeIntervalsBlock">
    <label class="k-checkbox-label"
           for='date'
           style="grid-area: date-lable;"
    >Дата: </label>
    <kendo-datepicker
      id="date"
      style="width: fit-content; justify-self: end; grid-area: date;"
      formControlName="date"
      [format]="datePickerViewParams.format"
      [activeView]="datePickerViewParams.activeView"
      [bottomView]="datePickerViewParams.deepBottomView"
      [topView]="datePickerViewParams.deepTopView"
    >
      <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
    </kendo-datepicker>
    <div style="grid-area: checks-intervals">
      <input type="checkbox"
             kendoCheckBox
             id="choseTimeintervals"
             formControlName="showTimeIntervalsBlock"/>
      <label class="k-checkbox-label"
             for="choseTimeintervals"
      >Выбрать интервал времени</label>
    </div>
    <div *ngIf="form.controls.showTimeIntervalsBlock.value"
                  style="grid-area: intervals;">
      <div style="margin-bottom: 10px;">
        <input type="checkbox"
               kendoCheckBox
               id="lastMinute"
               formControlName="considerLastMinuteFlag"/>
        <label class="k-checkbox-label"
               for="lastMinute"
        >Учитывать конец интервала</label>
      </div>
      <kendo-listview [data]="selectedTimeIntervals">
        <ng-template kendoListViewItemTemplate let-index="index" let-isLast="isLast" let-isFirst="isFirst" let-dataItem="dataItem">
          <div class="wrap-timeintervals">
            <label for="start">с:</label>
            <kendo-timepicker id=start
                              [(value)]="dataItem.startTime"
                              [max]="dataItem.endTime"
                              [readOnlyInput]="true"
                              style="width: 35%;"
            ></kendo-timepicker>
            <label for="end">по:</label>
            <kendo-timepicker id=end
                              [(value)]="dataItem.endTime"
                              [min]="dataItem.startTime"
                              [readOnlyInput]="true"
                              style="width: 35%;"
            ></kendo-timepicker>
            <button *ngIf="!isFirst"
                    kendoButton
                    [primary]="true"
                    style="width: 25px;
                  vertical-align: bottom;
                  margin-bottom: 2px;
                  margin-top: 2px"
                    (click)="deleteTimeInterval(dataItem, index, isLast)"
            ><span class="k-icon k-i-delete  k-font-icon"></span></button>
            <button *ngIf="isLast"
                    [disabled]="selectedTimeIntervals[selectedTimeIntervals.length-1].endTime.getTime() === endOfReportDayTime.getTime()"
                    kendoButton
                    style="width: 25px;
                  vertical-align: bottom;
                  margin-bottom: 2px;
                  margin-top: 2px;
                  background: lawngreen;"
                    (click)="addTimeInterval()"
            ><span class="k-icon k-i-plus  k-font-icon"></span></button>
          </div>
        </ng-template>
      </kendo-listview>
    </div>
  </div>
</div>

<app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                         [selection]="selection"
                                         [expandAll]="expandAllSubdivisions"
                                         [checkableSettings]="checkableSettings"
                                         [displayTextFn]="displayTextFn"
                                         (checkedSubdivisions$)="setCheckedSubdivisionsValue($event)"
                                         (allSubdivisions$)="setAllSubdivisionsFlagValue($event)">
</app-subdivision-treeview-with-checkbox2>

<div class="k-form-buttons" style="justify-content: flex-end;">

  <button kendoButton
          type="button"
          primary="true"
          [disabled]="form.invalid"
          (click)="onClickOk()"
  >
    Сформировать
  </button>

  <button kendoButton
          type="button"
          (click)="onClickCancel()"
  >
    Отменить
  </button>

</div>
