import {ChangeDetectionStrategy, Component, Input, Output} from "@angular/core";
import {State} from "@progress/kendo-data-query";
import {GridDataResult} from "@progress/kendo-angular-grid";
import {ISelectWorkingEmployeesComponent} from "./i-select-working-employees.component";
import {SelectWorkingEmployeesComponentService} from "./services/select-working-employees-component.service";
import {
  SelectWorkingEmployeesComponentDataService,
  SelectWorkingEmployeesComponentGridItem
} from "./services/select-working-employees-component-data.service";
import {Observable, ReplaySubject, Subject} from "rxjs";

@Component({
  selector: 'select-working-employees',
  templateUrl: './select-working-employees.component.html',
  styleUrls: ['./select-working-employees.component.css'],
  providers: [SelectWorkingEmployeesComponentService, SelectWorkingEmployeesComponentDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWorkingEmployeesComponent implements ISelectWorkingEmployeesComponent {
/** Дата начала выгрузки */
  @Input() startDate: Date;

  /** Дата окончания выгрузки */
  @Input() endDate: Date;

  /** Дата формирования выгрузки */
  @Input() forDate: Date;

  /** Выбранные сотрудники */
  @Input() employeesIds: number[];

  /** Subject изменений выбранныъ элементов */
  @Output("selectedItems$") selectedItems$ = new Subject<SelectWorkingEmployeesComponentGridItem[]>();

  /** Список выбранных Id элементов в гриде */
  public selectedKeys: number[] = [];

  public readonly gridView$: Observable<GridDataResult> = new ReplaySubject<GridDataResult>(1);

  private _gridView: GridDataResult;
  /** Данные для отображения в гриде */
  get gridView(): GridDataResult {
    return this._gridView;
  }
  set gridView(value: GridDataResult) {
    this._gridView = value;
    (this.gridView$ as Subject<GridDataResult>).next(value);
  }


  /** Состояние грида при иницализации */
  public gridState:State = {
    sort:  [{
      field: 'fio',
      dir: 'asc'
    }],
    take: 50,
    skip: 0
  };

  /** Строка поиска (фильтрации) */
  public searchValue: string = '';

  constructor(private service: SelectWorkingEmployeesComponentService) {
    service.component = this;
  }

  ngOnInit(): void {
    this.selectedKeys = this.employeesIds;
    this.service.reloadData(this.forDate, this.startDate, this.endDate);
  }

  /**
   * Функция фильтрации поиска, для грида
   */
   public onFilter(): void {
    if(!this.gridView) return;
    this.service.filterData(this.searchValue);
  }

  /** Событие происходит при изменении состояния грида (будь то изменениесортировки, пагинация и т.д) */
  public onDataStateChange(state:State){
    this.service.onDataStateChange(state);
  }

  /** СОбытие происходит при изменении выбранных элементов */
  public onSelectedKeysChange(selectedKeys: number[]) {
    this.service.selectedKeysChanged(selectedKeys);
  }

  ngOnDestroy(): void {
    this.service.ngOnDestroy();
    (this.gridView$ as Subject<GridDataResult>).complete();
  }

}
