import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {IMonitoringDetailGetResponse_Information} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/monitoringBase";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-monitoring-detail-grid',
  templateUrl: './monitoring-detail-grid.component.html',
  styleUrls: ['./monitoring-detail-grid.component.css']
})
@traceClass('MonitoringDetailGridComponent')
export class MonitoringDetailGridComponent implements OnInit, OnDestroy {
  @Input() dataSource: Array<IMonitoringDetailGetResponse_Information> = null;

  dataSource$: Observable<Array<IMonitoringDetailGetResponse_Information>> = null;

  constructor(private readonly tracerService: TracerServiceBase) { }

  @traceFunc()
  ngOnInit(): void {
    if(!this.dataSource){
      throw new Error('dataSource not pass to component')
    }

    this.dataSource$ = of(this.dataSource
      .sort((val1, val2) => +val2.dateTime - +val1.dateTime));
  }

  @traceFunc()
  ngOnDestroy(): void {
  }
}
