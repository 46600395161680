import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { KendoNotificationService } from 'src/app/services/kendo-notification.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { IApprovalRouteStepComponent_Settings } from './route-step/approval-route-step.component';
import {
  SubdivisionsTreelistComponentDataSourceService, SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {
  Api1SubdivisionControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service";
import {DbChangedListener} from "../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ForDate, IForDate} from "../../../../../../../src/app/classes/for-date";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {trace} from "../../../../../../../src/app/modules/trace/operators/trace";
import {takeUntil} from "rxjs/operators";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";

@Component({
  selector: 'app-administration-approval-route',
  templateUrl: './approval-route.component.html',
  styleUrls: ['./approval-route.component.css']
})
@traceClass('ApprovalRouteComponent')
export class ApprovalRouteComponent implements OnInit, OnDestroy {

  @Input("settings") public settings: IApprovalRouteStepComponent_Settings;

  /** Список идентификаторов выбранных подразделений */
  public checkedSubdivisionsKeys: [] = [];

  private streams$ = {
    unsubscribe : new ReplaySubject<any>(1)
  }

  /** Сервис данных, отображаемых в списке подразделений */
  public subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<IForDate>;

  /** Настройки checkBox-ов, отображаемых в строках списка подразделений */
  public subdivisionTreeViewChekableSettings: CheckableSettings = {
    checkChildren: true,
    checkParents: false,
    checkOnClick: true,
    enabled: true,
    mode: "multiple"
  };

  constructor(public readonly loadingIndicatorService: LoadingIndicatorService,
              public readonly kendoNotificationService: KendoNotificationService,
              public readonly alertService: AlertService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly traceService: TracerServiceBase,
              private readonly subdivisionControllerService: Api1SubdivisionControllerService,
              private readonly dbChangedListener: DbChangedListener,
              ) {
    this.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
      subdivisionControllerService, loadingIndicatorService, dbChangedListener);
  }

  @traceFunc()
  ngOnInit(): void
  {
    const date = new Date();
    this.subdivisionDataSourceService.reloadData$(new ForDate(date, null, date))
      .pipe(trace(this.traceService),
        exErrorHandler(this.displayErrorsService),
        takeUntil(this.streams$.unsubscribe))
      .subscribe();

    if(this.settings){
      new Error("Parameter [settings] must be declared in component ApprovalRouteComponent");
    }
  }

  /** Обработчик события выбора подразделения */
  @traceFunc()
  public onSubdivisionsChecked(subdivisionIds: Array<number>) {
    this.checkedSubdivisionsKeys = <[]>subdivisionIds;
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.subdivisionDataSourceService.ngOnDestroy();
  }
}
