import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { ITimeIntervalClickEvent, ITimeIntervalPanelComponent, TimeIntervalPanelComponentService_SelectedCells, TimeIntervalPanelItem } from "../i-time-interval-panel-component";
import { ReplaySubject } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { AlertButton, AlertService } from "src/app/services/alert.service";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { ArrayHelper } from "src/app/helpers/arrayHelper";
import { TimeInterval } from "src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import { IAddTimeIntervalComponent_Event } from "projects/timesheet/src/app/components/shareds/add-time-interval/add-time-interval.component";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { ContextMenuPopupEvent, ContextMenuSelectEvent } from "@progress/kendo-angular-menu";
import { TimeIntervalPanelComponentDataService } from "./time-interval-panel-component-data.service";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Injectable()
@traceClass('TimeIntervalPanelComponentService')
export class TimeIntervalPanelComponentService extends ComponentServiceBase<ITimeIntervalPanelComponent> implements OnDestroy {

  /** Событие выделения ячеек */
  public selectedCells$: ReplaySubject<TimeIntervalPanelComponentService_SelectedCells> = new ReplaySubject<TimeIntervalPanelComponentService_SelectedCells>(1);

  /** Отключена ли панель */
  public disable: boolean = true;

  /** Событие клика по временному интервалу */
  public click$: EventEmitter<ITimeIntervalClickEvent> = new EventEmitter<ITimeIntervalClickEvent>();

  /** Массив выделенных интервалов */
  public get dataSourceSelectedItems(): TimeIntervalPanelItem[] {
    return this.component.dataSource.filter(item => item.isSelected);
  };

  /** Массив не выделенных интервалов */
  public get dataSourceNotSelectedItems(): TimeIntervalPanelItem[] {
    return this.component.dataSource.filter(item => !item.isSelected);
  };

  private streams$ = {
    unsubscribe: new ReplaySubject<any>()
  }

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly alertService: AlertService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly dataService: TimeIntervalPanelComponentDataService,
              private readonly tracerService: TracerServiceBase) {
    super();
    this.selectedCells$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.disable = !(value?.cells?.length > 0)
    })
  }

  onInit(): void {
    this.dataService.getAllTimeIntervalsBySubdivisionId$(this.component.subdivisionOwnerId)
      .pipe(
        take(1), takeUntil(this.streams$.unsubscribe),
        map((value) => value.map(d => new TimeIntervalPanelItem(d, !!d.subdivisionId)))).subscribe({
          next: value => {
            this.component.dataSource = value.sort((a, b) => this.timeIntervalComparer(a, b));
          },
          error: () => {
            this.alertService.defaultAlertOption.error().showAlert()
          }
        });
  }

  /** Обработка нажатия на временной интервал */
  @traceFunc()
  public setTimeInterval($event: TimeInterval): void {
    if (!$event) return;

    this.selectedCells$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const dayDeviationNames = ArrayHelper.distinct(value.cells.filter(x => !!x.deviation && x.deviation.deviationWithInterval)
        .map(x => x.deviation.name))
        .join(', ');

      if (dayDeviationNames.length > 0) {
        this.alertService.defaultAlertOption.question()
          .mod(x => {
            x.message = `<strong>${dayDeviationNames}</strong> и работа проходят в один и тот же день?`;
            x.buttons = new Array<AlertButton>(
              AlertButton.Get('Отмена', false, true, () => { }),
              AlertButton.Get('НЕТ', true, false, () => {
                this.click$.emit({ timeInterval: $event, isClearDeviation: true });
              }),
              AlertButton.Get('ДА', true, false, () => {
                this.click$.emit({ timeInterval: $event, isClearDeviation: false });
              })
            );
          }).showAlert();
        return;
      }

      this.click$.emit({
        isClearDeviation: true,
        timeInterval: $event
      })
    })
  }

  /** Обработка события нажатия на buttonGroup */
  public onButtonClick($event: any, interval: TimeIntervalPanelItem): void {
    if (!this.component.isRemoving) {
      this.setTimeInterval(interval.timeInterval);
      this.component.dataSource.forEach(ti => {
        if (ti.asNew) ti.asNew = false;
      })
    }
  }

  /** Добавить временной интервал */
  public onAdd($event: any): void {
    if (this.component.isRemoving || this.component.isAdding || this.component.disabled) {
      throw new Error('Ошибка валидации доступа к диалогу добавления интервала времени')
    }

    this.component.isAdding = true;
  }

  /** Добавление временного интервала */
  public addedInterval($event: IAddTimeIntervalComponent_Event): void {
    this.component.isAdding = false;
    if (!$event) return;

    let existingInterval = this.component.dataSource
      .find(x => x.timeInterval.startInterval == $event.startInterval && x.timeInterval.endInterval == $event.endInterval);
    if (existingInterval) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'Данный временной интервал уже содержится в списке!<br><br>После закрытия данного диалогового окна он будет подсвечен.';
        x.buttons[0].callBack = () => {
          existingInterval.asNew = true;
          this.component.dataSource = this.component.dataSource;
        }
      }).showAlert();
      return;
    }

    $event.save$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        if (!value.isSuccess) {
          this.kendoNotificationService.showError({ content: 'НЕ удалось добавить временной интервал' })
          return;
        }

        const ti = new TimeIntervalPanelItem(value.timeInterval, true, false, true);
        this.component.dataSource.push(ti);
        this.component.dataSource = this.component.dataSource.sort((a, b) => this.timeIntervalComparer(a, b));
        this.kendoNotificationService.showSuccess({ content: 'Временной интервал добавлен' })
      }, error: () => {
        this.alertService.defaultAlertOption.error().showAlert()
        this.kendoNotificationService.showError({ content: 'НЕ удалось добавить временной интервал' })
      }
    })
  }

  /** Сравнить TimeIntervalPanelItem */
  public timeIntervalComparer(firstTI: TimeIntervalPanelItem, secondTI: TimeIntervalPanelItem): number {
    return firstTI.canRemove == secondTI.canRemove ?
      (firstTI.timeInterval.startInterval == secondTI.timeInterval.startInterval ?
        (firstTI.timeInterval.endInterval > secondTI.timeInterval.endInterval ? 1 : -1) :
        (firstTI.timeInterval.startInterval > secondTI.timeInterval.startInterval ? 1 : -1)
      ) :
      +firstTI.canRemove - +secondTI.canRemove;
  }

  /** Удалить несколько временных интервалов */
  public remove($event: any): void {
    if (!this.component.isRemoving) { //Первый клик
      this.component.isRemoving = true;
    } else { //Второй клик
      const selectedItems = this.dataSourceSelectedItems;
      if (selectedItems.length > 0) {
        this.loadingIndicatorService.addToObservable(
          'Удаление временных интервалов',
          this.dataService.deleteTimeIntervalLinks$(selectedItems.map(x => x.timeInterval.id), this.component.subdivisionOwnerId)
        ).pipe(take(1), takeUntil(this.streams$.unsubscribe))
          .subscribe({
            next: value => {
              this.component.isRemoving = false;
              this.kendoNotificationService.showSuccess({ content: 'Временные интервалы удалены' });
              if (value) {
                this.component.dataSource = this.dataSourceNotSelectedItems
              }
            },
            error: () => {
              this.component.isRemoving = false;
              if (selectedItems.length > 0) { selectedItems.forEach(item => item.isSelected = false) }

              this.alertService.defaultAlertOption.error().showAlert()
              this.kendoNotificationService.showError({ content: 'НЕ получилось удалить временные интервалы' })
              this.component.dataSource = this.component.dataSource;
            }
          });
      }
    }
  }

  /** Отмена удаления временных интервалов */
  public cancelRemove($event: any): void {
    this.component.dataSource.forEach((item) => {
      item.isSelected = false;
    })
    this.component.isRemoving = false;
  }

  /** Событие контекста временного интервала */
  public contextMenuSelect($event: ContextMenuSelectEvent): void {
    const item: TimeIntervalPanelItem = $event.target.data;

    const itemIndex = this.component.dataSource.indexOf(item);
    if (itemIndex >= 0 && item.canRemove) {
      const loadMessage = this.loadingIndicatorService.add("Удаление временного интервала");
      const timeIntervalIds: Array<number> = [item.timeInterval.id];
      this.dataService.deleteTimeIntervalLinks$(timeIntervalIds, this.component.subdivisionOwnerId)
        .pipe(take(1), takeUntil(this.streams$.unsubscribe))
        .subscribe({
          next: value => {
            if (value) {
              this.component.dataSource.splice(itemIndex, 1)
            }
            this.loadingIndicatorService.remove(loadMessage);
            this.kendoNotificationService.showSuccess({ content: 'Временной интервал удален' })
          },
          error: () => {
            this.loadingIndicatorService.remove(loadMessage);
            this.alertService.defaultAlertOption.error().showAlert()
            this.kendoNotificationService.showError({ content: 'НЕ удалось удалить временной интервал' })
          }
        });
    }
  }

  /** Обработка события предоткрытия окна */
  public contextMenuOpen($event: ContextMenuPopupEvent): void {
    const data: TimeIntervalPanelItem = $event.target.data;
    if (this.component.isRemoving || !data || !data.canRemove) {
      $event.preventDefault();
    }
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

}
