<kendo-dialog #addTimeIntervalDialog
              [title]="'Добавить интервал времени'"
              (close)="dialogClose($event)"
>
  <div>
    <div class="range-error" *ngIf="!timeRangeIsValid">
      Начало интервала больше окончания интервала
    </div>
    <div>
      <div>
        <table>
          <tr>
            <td>
              <div class="interval-label">Начало интервала:</div>
              <div class="time-interval-info-content">
                <kendo-floatinglabel text="час."
                                     class="interval-drop-down-lable">
                  <kendo-dropdownlist [data]="dataSourceForStartHour"
                                      [(value)]="values.start.hour"
                                      valueField="id"
                                      textField="text"
                                      class="interval-drop-down-list"
                  ></kendo-dropdownlist>
                </kendo-floatinglabel>
                <kendo-floatinglabel text="мин."
                                     class="interval-drop-down-lable">
                  <kendo-dropdownlist [data]="dataSources.minutes"
                                      [(value)]="values.start.minute"
                                      valueField="id"
                                      textField="text"
                                      class="interval-drop-down-list"
                  ></kendo-dropdownlist>
                </kendo-floatinglabel>
              </div>
            </td>
            <td>
              <div class="interval-label">Конец интервала:</div>
              <div class="time-interval-info-content">
                <kendo-floatinglabel text="час."
                                     class="interval-drop-down-lable">
                  <kendo-dropdownlist [data]="dataSources.hours"
                                      [(value)]="values.end.hour"
                                      valueField="id"
                                      textField="text"
                                      class="interval-drop-down-list"
                                      (selectionChange)="endIntervalHourSelectionChange($event)"
                  ></kendo-dropdownlist>
                </kendo-floatinglabel>
                <kendo-floatinglabel text="мин."
                                     class="interval-drop-down-lable">
                  <kendo-dropdownlist [data]="dataSourceForEndMinute"
                                      [(value)]="values.end.minute"
                                      valueField="id"
                                      textField="text"
                                      class="interval-drop-down-list"
                  ></kendo-dropdownlist>
                </kendo-floatinglabel>
              </div>
            </td>
          </tr>
        </table>
      </div>



    </div>
    <div class="time-interval-info" *ngIf="timeRangeIsValid">
      <div>Справочная информация:</div>
      <div class="time-interval-info-content">
        <table>
          <tr>
            <td class="td-label">Длительность (всего):</td>
            <td>{{timeLength}} час.</td>
          </tr>
          <tr>
            <td class="td-label">Длительность (ночь):</td>
            <td>{{nightTimeLength}} час.</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <kendo-dialog-actions layout="normal">
    <button kendoButton (click)="addTimeIntervalDialog.close.emit()">Отмена</button>
    <button kendoButton primary="true"
            (click)="addTimeInterval()"
            [disabled]="!timeRangeIsValid">Добавить</button>
  </kendo-dialog-actions>
</kendo-dialog>
