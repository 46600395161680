<kendo-grid *ngIf="dataSource && dataSource$"
            [data]="dataSource"
            scrollable="scrollable"
            style="max-height: 300px;"
>
  <kendo-grid-column title="Статус"
                     [width]="180"
                     field="displayStatus"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Этап согласования"
                     field="stage"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Дата изменения"
                     [width]="140"
                     field="dateTime"
                     format="HH:mm dd.MM.yyyy"
  ></kendo-grid-column>

  <kendo-grid-column title="Автор изменения"
                     [width]="300"
                     field="author"
  ></kendo-grid-column>

  <kendo-grid-column title="Комментарий"
                     field="comment"
                     [style]="{'white-space': 'pre-line', 'overflow-wrap': 'break-word'}"
  ></kendo-grid-column>
</kendo-grid>
