import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit, OnDestroy {
  /** Количество строк в textarea */
  @Input() rows: number = 4;
  /** Количество символов в строке textarea */
  @Input() cols: number = 50;
  /** Принцип изменения размера textarea */
  @Input() resizable: 'vertical' | 'horizontal' | 'both' | 'none' | 'auto' = 'none'

  /** Минимальная валидная длина */
  @Input() minLength: number = 0;
  /** Максимальная возможная длина */
  @Input() maxLength: number = 100;

  @Input() placeholder: string = '';

  /** Событие отмены */
  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Событие выбора */
  @Output() ok$: EventEmitter<string> = new EventEmitter<string>();

  /** Текст */
  @Input() text: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  /** Обработка события нажатия на кнопку ОК */
  public onOk(){
    this.ok$.emit(this.text ? this.text : '');
  }

  ngOnDestroy() {
    this.ok$.complete();
    this.cancel$.complete();
  }
}
