<kendo-splitter orientation="vertical"
                style="height: 100%"
>
  <kendo-splitter-pane style="height: auto"
                       [resizable]="false"
  >
    <app-monitoring-toolbar class="k-toolbar"
                            ($changeEvent)="onToolbarChange($event)"
    >
    </app-monitoring-toolbar>
  </kendo-splitter-pane>
  <kendo-splitter-pane>
    <app-monitoring-grid *ngIf="gridInput"
                         [input]="gridInput"
    >
    </app-monitoring-grid>
  </kendo-splitter-pane>
</kendo-splitter>
<div kendoDialogContainer></div>
