import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import { DialogContentBase, DialogRef, PreventableEvent } from '@progress/kendo-angular-dialog';
import { VichRegisterComponentSettings } from '../vich-register-grid/i-vich-register-grid.component';
import { VichRegisterGridComponent } from '../vich-register-grid/vich-register-grid.component';

@Component({
  selector: 'app-vich-register-grid-dialog',
  templateUrl: './vich-register-grid-dialog.component.html',
  styleUrls: ['./vich-register-grid-dialog.component.css']
})
export class VichRegisterGridDialogComponent extends DialogContentBase {
  /** Настройки данного компонента */
  @Input() public settings: VichRegisterComponentSettings;

  @Output() public currentRegisterType$ = new EventEmitter<number>;

  /** Ссылка на grid компонент */
  @ViewChild('grid') public grid: VichRegisterGridComponent

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  /** Обработка закрытия окна */
  @HostListener('document:keydown.escape', ['$event']) // Захват нажатий на Esc
  public onClose(e: PreventableEvent) {
    e.preventDefault() // Предотвратить дальнейшее распространение стандартного события - например нажатие на крестик

    if (this.grid.form) return // Если процесс добавления/редактирования не завершен - отменить закрытие диалога

    this.dialog.close(this.grid.getChangeEvent()) // Закрыть диалог и отправить евент изменений
  }
}
