import {Pipe, PipeTransform} from "@angular/core";
import {CellDataDirective} from "../components/graph-grid2/graph-grid2.component";
import {ArrayExpanded} from "../../../../../../../../../src/app/helpers/arrayHelper";

/** Тип результата трансформации */
type ResultType = {
  /** Доступна ли кнопка Исключить молоко */
  exclude: boolean,
  /** Доступна ли кнопка Включить молоко */
  include: boolean,
  /** Ячейки подлежащие установки Исключить молоко */
  toExclude: CellDataDirective[],
  /** Ячейки подлежащие установки Включить молоко */
  toInclude: CellDataDirective[],
}

@Pipe({
  name: 'graphMilkButton'
})
export class GraphMilkButtonPipe implements PipeTransform {
  public transform(selectedCells: CellDataDirective[]): ResultType {
    if(!selectedCells?.length) {
      return undefined;
    }

    if(selectedCells.some(x => !x.row.staffUnit.milk)){
      return undefined;
    }

    const map = new ArrayExpanded(selectedCells)
      .filter(x => !!x.dayCell.cell.graphDayCurrent.timeInterval || !!x.dayCell.cell.log.excludeMilkLog)
      .groupBy(x => !!x.dayCell.cell.log.excludeMilkLog)
      .toMap(x => x.key, x => x.values)

    const toExclude = map.get(false) ?? [];
    const toInclude = map.get(true) ?? [];

    return {
      exclude: !!toExclude.length,
      include: !!toInclude.length,
      toExclude: toExclude,
      toInclude: toInclude,
    }
  }
}
