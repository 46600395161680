/**
 * Объект, содержащий информацию, необходимую для подсчета нормы и факта
 */
export class CalculateNormaAndFactRequestParam {
  /** RedactionId */
  rId: number;
  /** GraphRowList */
  gRL: Array<RowInfo>;
}

/**
 * Объект, содержащий информацию о графике работы конкретной штатной единицы
 */
export class RowInfo {
  /** StaffUnitOwnerId */
  sUOId: number;
  /** CellInfoList */
  cIL: Array<CellInfo>;

  public static Create(
    staffUnitOwnerId: number,
    cellInfoList: Array<CellInfo>): RowInfo{
    const instance = new RowInfo();

    instance.sUOId = staffUnitOwnerId;
    instance.cIL = cellInfoList;

    return instance;
  }
}

/**
 * Класс данных ячейки графика для подсчета нормы и факта
  */
export class CellInfo {
  /** Date */
  d: Date;
  /** TimeIntervalId */
  tIId: number | null;
  /** DayDeviationId */
  dDId: number | null;
  /** CustomValue */
  cV: number | null;
  /** SubstractLunchFlag */
  sLF: boolean;
  /** IsIncludeInCalculate */
  iIIC: boolean;
  /** FlexDinner */
  flDnr: number | null;

  public static Create(date: Date,
                timeIntervalId: number,
                dayDeviationId: number,
                customValue: number,
                subtractLunchFlag: boolean,
                isIncludeInCalculate: boolean,
                flexDinner: number | null) : CellInfo{
    const instance = new CellInfo();
    instance.d = date;
    instance.tIId = timeIntervalId;
    instance.dDId = dayDeviationId;
    instance.cV = customValue;
    instance.sLF= subtractLunchFlag;
    instance.iIIC= isIncludeInCalculate;
    instance.flDnr = flexDinner;
    return instance;
  }

}
