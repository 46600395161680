import {Injectable, OnDestroy} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {defer, Observable, of, ReplaySubject} from "rxjs";
import {IDropDownItem} from "../../../../classes/requestResults/iDropDownItem";
import {takeUntil, tap} from "rxjs/operators";
import {CovidLog} from "../../../../classes/domain/POCOs/timesheet/CovidLog";
import {AuthService} from "../../../../modules/auth/services/auth.service";
import {
  GetGraphModelResult_Directories_Day,
  GetGraphModelResult_Directories_DayType
} from "../../../../../../projects/timesheet/src/app/Classes/GetGraphModelResult";
import {Position} from "../../../../classes/domain/POCOs/stafflist/Position";
import {Occupation} from "../../../../classes/domain/POCOs/stafflist/Occupation";
import {IStaffUnitType} from "../../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {IEntityId} from "../../../../classes/domain/POCOs/interfaces/IEntityId";
import {IDayDeviation} from "../../../../classes/domain/POCOs/timesheet_graph/DayDeviation";
import {CovidLog2} from "src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {VichLog} from "src/app/classes/domain/POCOs/timesheet/VichLog";
import {TuberLog} from "src/app/classes/domain/POCOs/timesheet/TuberLog";
import {IGraphDay} from "../../../../classes/domain/POCOs/timesheet_graph/GraphDay";
import {StaffUnit} from "../../../../classes/domain/POCOs/stafflist/StaffUnit";
import {Subdivision} from "../../../../classes/domain/POCOs/stafflist/Subdivision";

@Injectable({
  providedIn: "root"
})
export class Api1CovidControllerService implements OnDestroy{
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service,
              private authService: AuthService
  ) {
    this.authService.isAuth$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      if(!value){
        this._manipulations = null;
      }
    })
  }

  private _manipulations: Array<IDropDownItem> = null;
  /** Получить список манипуляций (КЭШ) */
  public get manipulations_cached$(): Observable<Array<IDropDownItem>>{
    return defer(() => {
      return this._manipulations == null ?
        this.manipulations$.pipe(tap(value => this._manipulations = value)) :
        of(this._manipulations);
    });
  }

  /** Получить список манипуляций */
  public get manipulations$(): Observable<Array<IDropDownItem>> {
    return this.httpClient
      .get<Array<IDropDownItem>>(this.webApi1Service.controllers.covid.actions.getManipulations.toString());
  }

  /** Добавить запись о случае работы с covid */
  public addCovidLog$(model: IAddCovidLogRequest): Observable<CovidLog>{
    return this.httpClient.post<CovidLog>(
      this.webApi1Service.controllers.covid.actions.addCovidLog.toString(),
      model
      );
  }

  /** Редактирование записи о случае работы с covid */
  public editCovidLog$(model: IEditCovidLogRequest): Observable<CovidLog>{
    return this.httpClient.post<CovidLog>(
      this.webApi1Service.controllers.covid.actions.editCovidLog.toString(),
      model
    );
  }

  /** Удалить запись о случае работы с covid */
  public removeCovidLog$(id: number): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.covid.actions.removeCovidLog.toString(),
      {
        id: id
      }
    );
  }

  /** Получить случаи работы сотрудника с covid на дату */
  public getCovidLogs$(staffUnitOwnerIds: Array<number>, date: Date): Observable<Array<CovidLog>>{
    return this.httpClient.post<Array<CovidLog>>(
      this.webApi1Service.controllers.covid.actions.getCovidLogs.toString(),
      {
        staffUnitOwnerIds: staffUnitOwnerIds,
        date: date
      }
    );
  }

  /** Может ли пользователь редактировать добавлять удалять данные на дату */
  public canAddEditRemove$(date: Date): Observable<boolean>{
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.covid.actions.canAddEditRemove.toString(),
      {
        date: date
      }
    );
  }

  /** Получить данные для отображения в окне редактирования Covid журнала */
  public getCovidRegisterData$(year: number, month: number,
                               date: Date, employeeOwnerId?: number,
                               staffUitOwnerId?: number): Observable<GetRowsResponse>{
    return this.httpClient.post<GetRowsResponse>(this.webApi1Service.controllers.covid.actions.getCovidRegisterData.toString(),
      {
        yearId: year,
        monthId: month,
        employeeOwnerId: employeeOwnerId,
        staffUnitOwnerId: staffUitOwnerId,
        date: date
      });
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

export interface IAddCovidLogRequest extends ICovidLogEditingProperty{
  date: Date
}

export interface IEditCovidLogRequest extends ICovidLogEditingProperty{
  id: number
}

export interface ICovidLogEditingProperty{
  staffUnitId: number,
  diseaseNumber: string,
  patientFio: string,
  mode: number,
  covidManipulationId: number,
  researchCount: number,
  operatingTime: number
}


export class GetRowsResponse{
  constructor(public datas: Array<GetRowsResponse_DataItem>,
              public directories: GetRowsResponse_DataDirectories,) {
  }
}

export class GetRowsResponse_DataItem{
  constructor(public staffUnit: GetRowsResponse_Data_StaffUnit,
              public graphDays: Array<GetRowsResponse_Data_GraphDay>,
              public covidLogs: Array<GetRowsResponse_Data_CovidLog>,
              public covid2Logs: Array<GetRowsResponse_Data_Covid2Log>,
              public vichLogs: Array<GetRowsResponse_Data_VichLog>,
              public tuberLogs: Array<GetRowsResponse_Data_TuberLog>) {
  }
}

export class GetRowsResponse_Data_StaffUnit implements Pick<StaffUnit, 'ownerId' | 'startDate' | 'endDate' | 'parentId' | 'positionId' | 'rate' | 'typeId'>{
  constructor(public ownerId: number,
              public startDate: Date,
              public endDate: Date,
              public parentId: number,
              public positionId: number,
              public rate: number,
              public typeId: number,) {
  }
}

export class GetRowsResponse_Data_GraphDay implements Pick<IGraphDay, 'id' | 'date' | 'timeIntervalId' | 'dayDeviationId' | 'dayDeviationCustomValue' | 'substractLunchFlag' | 'flexDinner'> {
  constructor(public id: number,
              public date: Date,
              public timeIntervalId: number,
              public dayDeviationId: number,
              public dayDeviationCustomValue: number,
              public substractLunchFlag: boolean,
              public flexDinner: number | null,
              public timeIntervalDuration: number,) {
  }
}

export class GetRowsResponse_DataDirectories{
  constructor(public days: Array<GetGraphModelResult_Directories_Day>,
              public dayTypes: Array<GetGraphModelResult_Directories_DayType>,
              public subdivisions: Array<GetRowsResponse_Directories_Subdivision>,
              public positions: Array<GetRowsResponse_Directories_Position>,
              public occupations: Array<GetRowsResponse_Directories_Occupation>,
              public staffUnitTypes: Array<GetRowsResponse_Directories_StaffUnitType>,
              public timeIntervals: Array<GetRowsResponse_Directories_TimeInterval>,
              public dayDeviations: Array<GetRowsResponse_Directories_DayDeviation>,) {
  }
}

export class GetRowsResponse_Directories_Subdivision implements Pick<Subdivision, 'ownerId'> {
  constructor(public ownerId: number,
              public name: string,) {
  }
}

export class GetRowsResponse_Directories_Position implements Pick<Position, 'ownerId' | 'occupationId' | 'subdivisionId'>{
  constructor(public ownerId: number,
              public occupationId: number,
              public subdivisionId: number,) {
  }
}

export class GetRowsResponse_Directories_Occupation implements Pick<Occupation, 'ownerId' | 'name'>{
  constructor(public ownerId: number,
              public name: string,) {
  }
}

export class GetRowsResponse_Directories_StaffUnitType implements Pick<IStaffUnitType, 'id' | 'description'>{
  constructor(public id: number,
              public description: string,) {
  }
}

export class GetRowsResponse_Directories_TimeInterval implements IEntityId{
  constructor(public id: number,
              public start: string,
              public end: string) {
  }
}

export class GetRowsResponse_Directories_DayDeviation implements Pick<IDayDeviation, 'id' | 'name' | 'color' | 'shortName'>{
  constructor(public id: number,
              public name: string,
              public color: string,
              public shortName: string) {
  }
}

export class GetRowsResponse_Data_CovidLog implements Pick<CovidLog, 'date'> {
  constructor(public date: Date,
              public covidHours: number,) {
  }
}

export class GetRowsResponse_Data_Covid2Log implements Pick<CovidLog2, 'date'> {
  constructor(public date: Date,) { }
}

export class GetRowsResponse_Data_VichLog implements Pick<VichLog, 'date'> {
  constructor(public date: Date,
              public vichHours: number,) {
  }
}

export class GetRowsResponse_Data_TuberLog implements Pick<TuberLog, 'date'> {
  constructor(public date: Date,
              public tuberHours: number,) {
  }
}
