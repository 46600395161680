<kendo-grid [data]="correctionRedactionList"
            [hideHeader]="true"
            (edit)="print($event.dataItem.redactionId)"
            KendoGridExpanded
            (dbCellClick)="print($event.originalEvent.dataItem.redactionId)"
>
  <kendo-grid-column>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span>{{text}}</span><span style="margin-left: 5px; margin-right: 20px">{{dataItem.versionNumber}}</span><span>{{dataItem.redactionDate | kendoDate :"dd.MM.yyyy"}}</span>
  </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="62">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button kendoGridEditCommand
              [primary]="true"
      >
        <span class="k-icon k-i-print k-font-icon"></span>
      </button>
    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>
