import {
  CalculateNormaFactResult,
  ICalculateNormaFactResult, IDayFactInfo
} from "../../../../../../../../../../../src/app/classes/requestResults/CalculateNormaFactResponse";
import {ObjComparer} from "../../../../../../../../../../../src/app/classes/object-comparers/object-comparer";
import {GraphGridDataSource_DataItem} from "../graph-grid-data-sources/graph-grid-data-source.classes";
import {NumberHelper} from "../../../../../../../../../../../src/app/helpers/numberHelper";

/** Элемент для источника данных {@link GraphGridNormFactDataSource} */
export class GraphGridNormFactDataSource_Item implements ICalculateNormaFactResult {

  /** Разница между {@link norma} и {@link fact} */
  public readonly delta: number;

  constructor(public readonly row: GraphGridDataSource_DataItem,
              public readonly norma: number,
              public readonly fact: number,
              public readonly nightTimeHours: number,
              public readonly holidayHours: number,
              public readonly dayFactInfos: IDayFactInfo[],) {
    this.delta = NumberHelper.round(fact - norma, 2);
  }

  private static _comparer: ObjComparer<GraphGridNormFactDataSource_Item>;
  /** Сравнить по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = CalculateNormaFactResult.comparer.add({
        delta: true,
        row: GraphGridDataSource_DataItem.fullComparer
          .delete({
            normFact: true, //Обязательно удаляем сравнение
          })
          .asPropertyCompareFunc(false)
      })
    }

    return this._comparer;
  }

  /** Создать */
  public static Create(row: GraphGridDataSource_DataItem, normFact: ICalculateNormaFactResult){
    return new GraphGridNormFactDataSource_Item(
      row,
      normFact.norma,
      normFact.fact,
      normFact.nightTimeHours,
      normFact.holidayHours,
      normFact.dayFactInfos,
    )
  }
}
