import { Injectable } from "@angular/core";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";
import { AuthService } from "../../../../../../../src/app/modules/auth/services/auth.service";
import { EmployeeGraphGridViewModel } from "../classes/employee-graph-grid-view-model";
import { map, take, takeUntil } from "rxjs/operators";
import { DayType } from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/day-type-view-model.class";
import { Day } from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/day-view-model.class";
import { EmployeeGraphGridRow } from "../classes/employee-graph-grid-row";
import { EmployeeGraphGridStaffUnit } from "../classes/employee-graph-grid-staff-unit";
import { EmployeeGraphGridCell } from "../classes/employee-graph-grid-cell";
import { DayDeviationVM } from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/day-deviation-view-model.class";
import { TimeIntervalVM } from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/time-interval-view-model.class";
import { process } from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { IconsUrlHelper } from "src/app/helpers/icons-url.helper";
import { Api1CovidControllerService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-covid-controller.service";
import { traceClass } from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { trace } from "../../../../../../../src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Injectable({
  providedIn: "root"
})
/** Сервис для компонента EmployeeGraphGridService */
@traceClass('EmployeeGraphGridService')
export class EmployeeGraphGridService {

  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private authService: AuthService,
              private api1CovidControllerService: Api1CovidControllerService,
              private readonly tracerService: TracerServiceBase) {
  }

  /** Получить данные для отображения */
  @traceFunc()
  public getDataSource$(yearId: number, monthId: number, employeeOwnerId: number): Observable<EmployeeGraphGridViewModel> {
    return this.loadingIndicatorService.addToObservable(
      'Загрузка данных',
      this.api1CovidControllerService.getCovidRegisterData$(yearId, monthId, null, employeeOwnerId)
    ).pipe(trace(this.tracerService), map(value => {
      const days = Day.CreateArrayAndSortByDate(value.directories.days, DayType.CreateArray(value.directories.dayTypes));

      const view = new EmployeeGraphGridViewModel(
        days,
        value.datas.map(source => {
          const staffUnitType = value.directories.staffUnitTypes.find(x => x.id == source.staffUnit.typeId);
          const position = value.directories.positions.find(p => p.ownerId == source.staffUnit.positionId);

          const row = new EmployeeGraphGridRow(
            IconsUrlHelper.getstaffUnitImagePath(staffUnitType.id, !!source.staffUnit.parentId),
            value.directories.subdivisions.find(s => s.ownerId == position.subdivisionId).name,
            value.directories.occupations.find(o => o.ownerId == position.occupationId).name,
            new EmployeeGraphGridStaffUnit(
              source.staffUnit.ownerId,
              source.staffUnit.startDate,
              source.staffUnit.endDate,
              source.staffUnit.rate,
              source.staffUnit.typeId
            ),
            days.map(day => {
              const sourceGraphDay = source.graphDays.find(g => +g.date == +day.date);
              const sourceCovidHours = source.covidLogs.find(x => +x.date == +day.date);
              const sourceHasCovid2 = source.covid2Logs.some(x => +x.date == +day.date);
              const sourceVichHours = source.vichLogs.find(x => +x.date == +day.date);
              const sourceTuberHours = source.tuberLogs.find(x => +x.date == +day.date);

              return !!sourceGraphDay ? new EmployeeGraphGridCell(
                day,
                sourceGraphDay.id,
                !sourceGraphDay.timeIntervalId ?
                  null :
                  (() => {
                    const source = value.directories.timeIntervals.find(x => x.id == sourceGraphDay.timeIntervalId);
                    return new TimeIntervalVM(source.id, source.start, source.end, sourceGraphDay.timeIntervalDuration, null)
                  })(),
                !sourceGraphDay.dayDeviationId ?
                  null :
                  (() => {
                    const sourceDayDeviation = value.directories.dayDeviations.find(x => x.id == sourceGraphDay.dayDeviationId);
                    return new DayDeviationVM(
                      sourceDayDeviation.id,
                      sourceDayDeviation.name,
                      sourceDayDeviation.color,
                      null,
                      null,
                      null,
                      sourceDayDeviation.shortName
                    )
                  })(),
                sourceGraphDay.dayDeviationCustomValue,
                sourceGraphDay.substractLunchFlag,
                sourceGraphDay.flexDinner,
                sourceCovidHours?.covidHours,
                sourceHasCovid2,
                sourceVichHours?.vichHours,
                sourceTuberHours?.tuberHours)
                : new EmployeeGraphGridCell(day, null, null, null, 0,
                  false, null, sourceCovidHours?.covidHours, sourceHasCovid2, sourceVichHours?.vichHours, sourceTuberHours?.tuberHours)
            })
          );

          return row;
        }),
        value.directories.subdivisions
      )

      view.rows = process(view.rows, {
        sort: [
          { field: 'staffUnit.rate', dir: "desc" },
          { field: 'subdivisionName', dir: "asc" },
          { field: 'occupationName', dir: "asc" }
        ]
      }).data;

      return view;
    }));

  }

}
