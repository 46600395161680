import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {
  Api1StaffUnitsControlControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";


@Injectable({
  providedIn: 'root'
})
export class TableDismissedDataService {

  constructor(private readonly api1StaffUnitsControlControllerService: Api1StaffUnitsControlControllerService ) { }

  public getData$(subdivisionId: number, month: Date, redactionId: number) : Observable<DissmissedStaffUnitBlockItem[]> {
    return this.api1StaffUnitsControlControllerService.getDismissedStaffUnitsBySubdivisionId$(month, null, redactionId, subdivisionId)
  }
}

/** Модель данных, содержащая информацию о закрываемой основной ставке или внешнем совместительстве и всех связанных с ним совместительствах */
export class DissmissedStaffUnitBlockItem {
  constructor(public baseStaffunitOwnerId: number,
              public employeeName: string,
              /** Дата закрытия основной ставки или внешнего совместительства */
              public dismissedDate: Date,
              /** Перечень StaffUnit этого Employee, сгруппированные по периоду работы основной ставки
               * (базовая ставка + совместители, которые закрылись до даты увольнения)*/
              public staffUnitInfos: StaffUnitInfo[]  ) {  }
}

/** Модель данных, содержащая информацию о конкретном StaffUnit, относящихся к закрываемой основной ставке или внешнему совместительству */
export class StaffUnitInfo {
  constructor(public ownerId: number,
              public startDate: Date,
              public endDate: Date,
              public occupationName: string,
              public staffUnitType: string) {  }
}
