<div style="display: flex; flex-wrap: wrap;">
  <div>
    <button kendoButton
            imageUrl="../../../../../../assets/icons/moonlighter-combination/moonlighter_bosy_rate_add.png"
            [size]="'small'"
            title="Добавить совместительство за счёт временного отсутствия сотрудника"
            [disabled]="isProxyButtonDisabled"
            (click)="onAddProxy('proxy')">
      Совместительство
    </button>
    <button kendoButton
            title="Добавить совместительство за счет вакантной ставки"
            (click)="onAddMoonlighter('moonlighter')"
            imageUrl="../../../../../../assets/icons/moonlighter-combination/moonlighter_free_rate_add.png"
            [size]="'small'"
    >
      Совместительство
    </button>
  </div>
  <div *ngIf="isisComputedCombination">
    <button kendoButton
            title="Добавить совмещение за счёт временного отсутствия сотрудника"
            imageUrl="../../../../../../assets/icons/moonlighter-combination/combination_bosy_rate_add.png"
            [size]="'small'"
            [disabled]="isCombinationButtonDisabled"
            (click)="onAddProxy('combination')"
    >
      Совмещение
    </button>

    <button kendoButton
            title="Добавить совмещение за счет вакантной ставки"
            imageUrl="../../../../../../assets/icons/moonlighter-combination/combination_free_rate_add.png"
            [size]="'small'"
            (click)="onAddMoonlighter('combination')"
    >
      Совмещение
    </button>
  </div>

  <div *ngIf="isUsedDuty">
    <button kendoButton
            title="Добавить дежурство"
            imageUrl="../../../../../../assets/icons/moonlighter-combination/duty_add.png"
            [size]="'small'"
            (click)="onAddDuty()"
    >
      Дежурство
    </button>
  </div>

  <div *ngIf="isUsedUvor">
    <button kendoButton
            title="Добавить увеличение объема работ за счёт временного отсутствия сотрудника"
            imageUrl="../../../../../../assets/icons/moonlighter-combination/uvor_busy_rate_add.png"
            [size]="'small'"
              [disabled]="isUvorButtonDisabled"
            (click)="onAddProxy('uvor')"
            >
      УВОР
    </button>
    <button kendoButton
            title="Добавить увеличение объема работ за счет вакантной ставки"
            imageUrl="../../../../../../assets/icons/moonlighter-combination/uvor_free_rate_add.png"
            [size]="'small'"
            (click)="onAddMoonlighter('uvor')"
    >
      УВОР
    </button>
  </div>

</div>
<div kendoDialogContainer></div>
