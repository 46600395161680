import { IEntityId } from '../interfaces/IEntityId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITimeIntervalSubdivision extends IEntityId {
  /* FK на таблицу timesheet_graph.TimeIntervals */
  timeIntervalId: number;
  /* FK на таблицу stafflist.Subdivisions */
  subdivisionId?: number;
}

@classBackend('TimeIntervalSubdivision', 'timesheet_graph')
@className('TimeIntervalSubdivision')
export class TimeIntervalSubdivision implements ITimeIntervalSubdivision {
  constructor(public id: number,
              public timeIntervalId: number,
              public subdivisionId?: number,
  ) {
  }
}