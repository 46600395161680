import {Pipe, PipeTransform} from "@angular/core";
import {IStaffUnitType} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import {CellDataDirective} from "../components/graph-grid2/graph-grid2.component";
import {ArrayHelper} from "../../../../../../../../../src/app/helpers/arrayHelper";
import {StaffUnitTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {StaffUnit} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";

/** Тип для кнопки */
type ButtonType = {
  /** Тип исполнения должности к которой относится кнопка */
  staffUnitType: StaffUnitTypeEnum,
  disabled: boolean,
}

/** Результирующий тип трансформации */
type TransformReturnType = {
  /** Кнопка Совместительство proxy */
  moonlighterProxyButton: ButtonType | undefined,
  /** Кнопка Совместительство */
  moonlighterFreeButton: ButtonType | undefined,
  /** Кнопка Совмещение proxy */
  combinationProxyButton: ButtonType | undefined,
  /** Кнопка Совмещение */
  combinationFreeButton: ButtonType | undefined,
  /** Кнопка УВОР proxy */
  uvorProxyButton: ButtonType | undefined,
  /** Кнопка УВОР */
  uvorFreeButton: ButtonType | undefined,
  /** Кнопка Дежурство */
  dutyButton: ButtonType | undefined,

  /** Если выделенные ячейки относятся к одному исполнению должности, то будет заполнено */
  singleRowSelected: CellDataDirective['row'] | undefined,
  /** Выделенные ячейки дней, относящиеся к одному исполнению должности */
  singleRowDaySelectedCells: CellDataDirective[]
}

@Pipe({
  name: 'graphAddButtons'
})
/** Пайп конвертации данных в объект управления кнопками добавления исполнения должности */
export class GraphAddButtonsPipe implements PipeTransform {
  transform(staffUnitTypes: Pick<IStaffUnitType, 'id'>[], daySelectedCells: CellDataDirective[], allSelectedCells: CellDataDirective[]): TransformReturnType {
    if(!staffUnitTypes){
      return undefined;
    }

    const staffUnitTypeSet = new Set<StaffUnitTypeEnum>(staffUnitTypes.map(x => x.id));

    allSelectedCells = allSelectedCells ? allSelectedCells : [];
    daySelectedCells = daySelectedCells ? daySelectedCells : [];

    const singleSelectedRow = (() => {
      const cellsDistinctByOwnerId = ArrayHelper.distinctBy(allSelectedCells, x => x.row.staffUnit.ownerId);
      if(cellsDistinctByOwnerId.length !== 1){
        return undefined;
      }

      return cellsDistinctByOwnerId[0].row;
    })();

    return {
      moonlighterProxyButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.MoonlighterInner)
        ? undefined
        : {
        staffUnitType: StaffUnitTypeEnum.MoonlighterInner,
        disabled: !singleSelectedRow || !StaffUnit.isSupportedMoonlighterProxy(singleSelectedRow.staffUnit.typeId, singleSelectedRow.staffUnit.parentId),
        },
      moonlighterFreeButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.MoonlighterInner)
        ? undefined
        : {
        staffUnitType: StaffUnitTypeEnum.MoonlighterInner,
        disabled: false,
        },
      combinationProxyButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.CombinationInner)
        ? undefined
        : {
        staffUnitType: StaffUnitTypeEnum.CombinationInner,
        disabled: !singleSelectedRow || !StaffUnit.isSupportedCombinationProxy(singleSelectedRow.staffUnit.typeId, singleSelectedRow.staffUnit.parentId),
        },
      combinationFreeButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.CombinationInner)
        ? undefined
        : {
        staffUnitType: StaffUnitTypeEnum.CombinationInner,
        disabled: false,
        },
      uvorProxyButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.Uvor)
        ? undefined
        : {
        staffUnitType: StaffUnitTypeEnum.Uvor,
        disabled: !singleSelectedRow || !StaffUnit.isSupportedUvorProxy(singleSelectedRow.staffUnit.typeId, singleSelectedRow.staffUnit.parentId),
        },
      uvorFreeButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.Uvor)
        ? undefined
        : {
          staffUnitType: StaffUnitTypeEnum.Uvor,
        disabled: false
        },
      dutyButton: !staffUnitTypeSet.has(StaffUnitTypeEnum.Duty)
        ? undefined
        : {
          staffUnitType: StaffUnitTypeEnum.Duty,
        disabled: false
        },
      singleRowSelected: singleSelectedRow,
      singleRowDaySelectedCells: !singleSelectedRow ? [] : daySelectedCells,
    };
  }
}
