import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogAction } from "@progress/kendo-angular-dialog";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";
import {
  CalculateDurationParam,
  TimeIntervalDurationService
} from "../../../../../../../src/app/services/timeIntervalServices/time-interval-duration.service";
import { TimeInterval } from "../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import { Observable, of, throwError } from "rxjs";
import { AlertService } from "../../../../../../../src/app/services/alert.service";
import { Api1TimeIntervalControllerService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-timeInterval-controller.service";
import { catchError, map } from "rxjs/operators";
import { ResponseObjError } from "../../../../../../../src/app/classes/requestResults/responseObjError";
import { KendoNotificationService } from "../../../../../../../src/app/services/kendo-notification.service";

/**
 * Окно добавления временных интервалов
 */
@Component({
  selector: 'app-add-time-interval',
  templateUrl: './add-time-interval.component.html',
  styleUrls: ['./add-time-interval.component.css']
})
export class AddTimeIntervalComponent {
  /** Идентификатор выбранного подразделения */
  @Input() public subdivisionOwnerId: number;

  /** Событие отмены добавления интервала */
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  /** Событие сохранения интервала времени */
  @Output() save = new EventEmitter<IAddTimeIntervalComponent_Event>();

  /** Значения DropDown */
  public values: { start: GroupDropDownItem, end: GroupDropDownItem } = null;

  /** Источники данных для DropDown */
  public dataSources: { hours: Array<DropDownItem>, minutes: Array<DropDownItem> } = null;

  /** Источник данных для часов начала интервала */
  public get dataSourceForStartHour(): Array<DropDownItem> {
    return this.dataSources.hours.filter(item => {
      return item.id != 24;
    })
  }

  /** Источник данных для минут окончания интервала */
  public get dataSourceForEndMinute(): Array<DropDownItem> {
    return this.values.end.hour.id != 24 ?
      this.dataSources.minutes :
      this.dataSources.minutes.filter(item => { return item.id == 0 });
  }

  public validationMessage: string = '';

  /** Введенные данные валидны? */
  public get timeRangeIsValid(): boolean {
    try {
      this.timeIntervalDurationService.validationParameters(this.paramToCalculateDuration)
      this.validationMessage = '';
      return true;
    } catch (e) {
      this.validationMessage = e.message;
      return false;
    }
  }

  /** Длительность интервала */
  public get timeLength(): number {
    return this.timeIntervalDurationService
      .calculateDuration(this.paramToCalculateDuration);
  }

  /** Длительность ночного режима */
  public get nightTimeLength(): number {
    return this.timeIntervalDurationService
      .calculateNightsDuration(this.paramToCalculateDuration);
  }

  /**
   * Конструктор
   */
  constructor(
    private timeIntervalDurationService: TimeIntervalDurationService,
    private loadingIndicatorService: LoadingIndicatorService,
    private alertService: AlertService,
    private api1TimeIntervalControllerService: Api1TimeIntervalControllerService,
  ) {
    this.dataSources = {
      hours: DropDownItem.GetMany(0, 24),
      minutes: DropDownItem.GetMany(0, 59)
    }

    this.values = {
      start: GroupDropDownItem.Get(this.dataSources.hours[8], this.dataSources.minutes[0]),
      end: GroupDropDownItem.Get(this.dataSources.hours[17], this.dataSources.minutes[0])
    };
  }

  /**
   * Обработка события отмены окна
   * @param $event
   */
  dialogClose($event: DialogAction) {
    this.cancel.emit();
  }

  /** Обработка события Добавить */
  addTimeInterval() {
    const startInterval = (+this.values.start.hour.text) * 60 + (+this.values.start.minute.text);
    const endInterval = (+this.values.end.hour.text) * 60 + (+this.values.end.minute.text);

    this.save.emit({
      subdivisionOwnerId: this.subdivisionOwnerId,
      startInterval: startInterval,
      endInterval: endInterval,
      save$: this.loadingIndicatorService.addToObservable(
        'Добавление временного интервала',
        this.api1TimeIntervalControllerService.addTimeIntervalToSubdivision$(this.subdivisionOwnerId, startInterval, endInterval)
          .pipe(map(x => {
            return {
              isSuccess: true,
              timeInterval: x
            }
          }), catchError((err, caught) => {
            if (ResponseObjError.checkTypeReturnCode(err) == 'e9536410-3501-4c4d-a85c-a9939cf9b165') {
              const innerError = (err as ResponseObjError<string>).data;
              this.alertService.defaultAlertOption.information().mod(x => {
                x.message = innerError;
              }).showAlert()

              return of({ isSuccess: false, timeInterval: null })
            }

            return throwError(() => err)
          }))
      )
    });
  }

  /**
   * Обработка события изменения выбранного в DropDown часа окончания интервала времени
   * @param $event
   */
  endIntervalHourSelectionChange($event: DropDownItem) {
    if ($event.id == 24) {
      this.values.end.minute = this.dataSources.minutes[0];
    }
  }

  /**
   * Получить параметры для расчета длительности интервала
   */
  private get paramToCalculateDuration(): CalculateDurationParam {
    return CalculateDurationParam.Get(
      this.values.start.hour.id,
      this.values.end.hour.id,
      this.values.start.minute.id,
      this.values.end.minute.id,
      2
    );
  }
}

/** Интерфейс события добавления интервала*/
export interface IAddTimeIntervalComponent_Event {
  /** Идентификатор подразделения */
  subdivisionOwnerId: number,
  /** Начало интервала */
  startInterval: number,
  /** Окончание интервала */
  endInterval: number,
  /** Стрим добавления */
  save$: Observable<{
    /** Выполнилось ли без ошибок */
    isSuccess: boolean,
    /** Временной интервал */
    timeInterval: TimeInterval
  }>
}

/**
 * Класс элемента DropDown
 */
class DropDownItem {
  /**
   * Идентификатор
   */
  public id: number;
  /**
   * Текст
   */
  public text: string;

  /**
   * Конструктор
   * @param id
   * @constructor
   */
  public static Get(id: number): DropDownItem {
    const instance = new DropDownItem();
    instance.id = id;
    instance.text = id.toString();
    if (instance.text.length < 2) {
      instance.text = `0${instance.text}`;
    }

    return instance;
  }

  /**
   * Конструктор
   * @param startId начальный идентификатор
   * @param endId конечный идентификатор
   * @constructor
   */
  public static GetMany(startId: number, endId: number): Array<DropDownItem> {
    if (startId > endId) {
      throw new Error('start id > end id')
    }

    const returnArray: Array<DropDownItem> = new Array<DropDownItem>();
    for (; startId <= endId; startId++) {
      returnArray.push(DropDownItem.Get(startId));
    }

    return returnArray;
  }
}

/**
 * Группа DropDownItem час + минут
 */
class GroupDropDownItem {
  /**
   * Час
   */
  hour: DropDownItem;
  /**
   * Минута
   */
  minute: DropDownItem;

  /**
   * Конструктор
   * @param hour
   * @param minute
   * @constructor
   */
  public static Get(hour: DropDownItem, minute: DropDownItem): GroupDropDownItem {
    const instance = new GroupDropDownItem();
    instance.hour = hour;
    instance.minute = minute;

    return instance;
  }
}
