import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'arrayOrEmpty'
})
/** Пайп возвращает переданный массив или пустой массив. Использовать для предотвращения падения на {@link null} или {@link undefined} */
export class ArrayOrEmptyPipe implements PipeTransform {
  transform<T>(arr: T[]): T[] {
    return !arr ? [] : arr;
  }
}
