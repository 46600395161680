import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {StaffUnitTypeEnum} from "../../../classes/domain/enums/StaffUnitTypeEnum";
import {take} from "rxjs/operators";
import {
  Api1StaffUnitTypeControllerService
} from "../../../services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {StaffUnitType} from "../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {
  PrintFormFromTillDatesWithStaffUnitTypesSettings
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators
} from "@angular/forms";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import * as moment from "moment/moment";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {CheckedListItemsValidator} from "../report-settings-form-validators";

@Component({
  selector: 'app-print-form-from-till-dates-with-staffunit-types-settings',
  templateUrl: './print-form-from-till-dates-with-staffunit-types-settings.component.html',
  styleUrl: './print-form-from-till-dates-with-staffunit-types-settings.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('PrintFormFromTillDatesWithStaffunitTypesSettingsComponent')
export class PrintFormFromTillDatesWithStaffunitTypesSettingsComponent implements OnInit {

  /** Перечень допустимых типов занятости, по которым будут формироваться данные отчета */
  public staffUnitTypes: StaffUnitType[];

  public minDate: Date;
  public maxDate: Date;

  public form: FormGroup;

  /** Дата инициализации периода, за который формируется отчет */
  @Input() public initialDate: Date;
  /** Событие выбора */
  @Output() public select$: EventEmitter<PrintFormFromTillDatesWithStaffUnitTypesSettings> =
    new EventEmitter<PrintFormFromTillDatesWithStaffUnitTypesSettings>;
  /** Событие отмены выбора */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly traceService: TracerServiceBase,
              private readonly api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
              private readonly chengeDetector: ChangeDetectorRef,
              ) {
  }
  ngOnInit(): void {
    this.api1StaffUnitTypeControllerService.getByIds$(
      [StaffUnitTypeEnum.Basic,StaffUnitTypeEnum.MoonlighterInner,StaffUnitTypeEnum.MoonlighterExternal])
      .pipe(take(1))
      .subscribe(x => {
        this.staffUnitTypes = x;
        this.formInit();
      });
  }

  /** Обработка события нажания на кнопку "Выбрать" */
  @traceFunc()
  public onOk() {
    this.select$.emit(new PrintFormFromTillDatesWithStaffUnitTypesSettings(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.fromDate.value,
      this.form.controls.tillDate.value,
      this.form.controls.staffUnitTypeIds.value.map(s => s.id)));
    this.select$.complete();
  }

  /** Обработка события нажания на кнопку "Отмена" */
  @traceFunc()
  onCancel() {
    this.cancel$.emit();
    this.cancel$.complete();
  }

  /** Инициализация формы */
  @traceFunc()
  private formInit() {
    const startDate = this.initialDate
      ? moment(this.initialDate).startOf("day").toDate()
      : moment(new Date()).startOf("month").startOf('day').toDate();
    const endDate = moment(startDate).endOf("month").startOf("day").toDate();

    this.form = new FormGroup({
      fromDate: new FormControl<Date>(startDate, {validators: Validators.required}),
      tillDate: new FormControl<Date>(endDate, {validators: Validators.required}),
      staffUnitTypeIds: new FormControl<number[]>([], CheckedListItemsValidator()),
    });

    this.chengeDetector.detectChanges();
  }
}
