import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CorrectionListComponentService} from "./services/correction-list-component.service";
import {
  CorrectionRedactionInfo
} from "../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {ICorrectionListComponent, SheetCorrectionType} from "./i-correction-list-component";

@Component({
  selector: 'app-correction-list',
  templateUrl: './correction-list.component.html',
  styleUrls: ['./correction-list.component.css'],
  providers: [CorrectionListComponentService]
})
export class CorrectionListComponent implements ICorrectionListComponent, OnInit {

  /**
   * Событие отмены выбора
   */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Событие выбора
   */
  @Output() public select$: EventEmitter<void> = new EventEmitter<void>();

  public subdivisionId : number;
  public month : Date;

  public correctionRedactionList: Array<CorrectionRedactionInfo> = [];
  public text: string;
  public type: SheetCorrectionType;

  constructor( private readonly correctionListComponentService: CorrectionListComponentService ) {
    correctionListComponentService.component = this;
  }

  ngOnInit(): void {
    this.correctionListComponentService.ngOnInit();
  }

  print($event: number) {
    this.correctionListComponentService.print($event);
    this.select$.next();
  }
}
