<select-working-employees
  [startDate]="startDate"
  [endDate]="endDate"
  [forDate]="forDate"
  [employeesIds]="employeesIds"
  (selectedItems$)="onItemsSelected($event)"></select-working-employees>

<kendo-dialog-actions>
  <div class="dialog-buttons">
    <button kendoButton (click)="onCancelAction()">Отмена</button>
    <button
      kendoButton
      (click)="onConfirmAction()"
      [primary]="true"
      [disabled]="!isCanSave"
    >
      Выбрать
    </button>
  </div>
</kendo-dialog-actions>
