import {Pipe, PipeTransform} from "@angular/core";
import {ITimeInterval, TimeInterval} from "../classes/domain/POCOs/timesheet_graph/TimeInterval";

@Pipe({
  name: 'timeIntervalToString'
})
/**
 * Преобразование {@link TimeInterval.startInterval} или {@link TimeInterval.endinterval} в строку<br>
 * Используется метод {@link TimeInterval.intervalToString}<br>
 */
export class TimeIntervalToStringPipe implements PipeTransform {
  transform(value: number): string {
    return TimeInterval.intervalToString(value);
  }
}


@Pipe({
  name: 'timeIntervalDuration'
})
/**
 * Пайп получает продолжительность из {@link ITimeInterval}
 * Используется метод {@link TimeInterval.getDuration}<br>
 */
export class TimeIntervalDurationPipe implements PipeTransform {
  transform(value: Pick<ITimeInterval, 'startInterval' | 'endInterval'>, digits: number = 2): number {
    return TimeInterval.getDuration(value.startInterval, value.endInterval, digits);
  }
}

@Pipe({
  name: 'timeIntervalNightDuration'
})
/**
 * Пайп получает продолжительность ночной работы из {@link ITimeInterval}
 * Используется метод {@link TimeInterval.getNightDuration}<br>
 */
export class TimeIntervalNightDurationPipe implements PipeTransform {
  transform(value: Pick<ITimeInterval, 'startInterval' | 'endInterval'>, digits: number = 2): number {
    return TimeInterval.getNightDuration(value.startInterval, value.endInterval, digits);
  }
}
