import {Pipe, PipeTransform} from "@angular/core";


@Pipe({
    name: 'arrayElementAt'
})
/** Пайп получения элемента из массива по индексу.<br> */
export class ArrayElementAtPipe implements PipeTransform {
    /**
     * Преобразовать
     * @param array массив. Безопасно передавать undefined
     * @param index индекс. Безопасно передавать отрицательный или больше длины массива
     * @param or значение если элемента нет.
     */
    transform<T>(array: T[], index: number, or: T = undefined): T | undefined {
        if (!Array.isArray(array) || index < 0 || index >= array.length) {
            return or;
        }
        return array[index];
    }
}
