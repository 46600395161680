import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {ReplaySubject} from "rxjs";
import {
  RedactionGridDataSourceServiceBase
} from "./services/redaction-grid-data-source.service";
import {CellClickEvent} from "@progress/kendo-angular-grid";
import {
  CellClickExpandedEvent
} from "../../../../../../../../src/app/services/directives/grid-treelist-expanded-directive.service";
import {xnameofPath} from "../../../../../../../../src/app/functions/nameof";
import {
  ArrayDataSourceSelection
} from "../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {
  DeferSelectionService
} from "../../../../../../../../src/app/services/defer-selection-services/defer-selection.service";
import {takeUntil} from "rxjs/operators";
import {ArrayExpanded} from "../../../../../../../../src/app/helpers/arrayHelper";
import {TraceParamEnum} from "../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {State} from "@progress/kendo-data-query";

/** Тип строки */
type RowType = RedactionGridDataSourceServiceBase<any>['dataSource']['data'][0];

/** Таблица редакций */
@Component({
  selector: 'app-redaction-grid2',
  templateUrl: './redaction-grid2.component.html',
  styleUrl: './redaction-grid2.component.css',
  providers: [DeferSelectionService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('RedactionGrid2Component')
export class RedactionGrid2Component implements OnInit, OnDestroy {
  protected xRowPath = xnameofPath(this?.dataSource?.data[0], '.', false);

  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Словарь версий */
  protected versionMap: Map<number, RowType['version']> = new Map<number, RowType["version"]>();

  private _dataSource: RedactionGridDataSourceServiceBase<any>['dataSource'];
  /** Источник данных */
  public get dataSource(){
    return this._dataSource;
  }
  @Input() public set dataSource(value){
    if(value === this._dataSource){
      return;
    }

    if(this._dataSource){
      throw new Error('Повторная установка источника данных не поддерживается')
    }

    this._dataSource = value;
    this.versionMap.clear();

    if(value){
      value.data$
          .pipe(
              takeUntil(this.streams$.unsubscribe)
          ).subscribe(redactions => {
            new ArrayExpanded(redactions.map(x => x.version))
                .distinctBy(x => x.id)
                .array
                .forEach(x => this.versionMap.set(x.id, x))
      })
    }

    this.deferSelectionService.originSelection = value;
  }

  private _selection: ArrayDataSourceSelection<RowType, number>;
  /** выборщик */
  public get selection(){
    return this._selection;
  }
  @Input() public set selection(value){
    this._selection = value;
    this.deferSelectionService.originSelection = value;
  }

  constructor(protected readonly deferSelectionService: DeferSelectionService<typeof this.dataSource, ArrayDataSourceSelection<RowType, number>>,
              private readonly traceService: TracerServiceBase) {
    deferSelectionService.tempCtorFunc = (dataSource) => new ArrayDataSourceSelection(dataSource);
    deferSelectionService.isDeferApply = true;
  }

  /** Отображать ли кнопку команды Просмотр*/
  @Input() public previewCommandButton: boolean = true;
  /** Отображать ли кнопку команды Копировать */
  @Input() public copyCommandButton: boolean = true;
  /** Отображать ли кнопку команды Сравнить */
  @Input() public compareCommandButton: boolean = false;

  /** Событие нажатия на кнопку Просмотр */
  @Output() public readonly preview$ = new EventEmitter<number>();
  /** Событие нажатия на кнопку Копировать */
  @Output() public readonly copy$ = new EventEmitter<number>();
  /** Событие нажатия на кнопку Сравнить */
  @Output() public readonly compare$ = new EventEmitter<number>();

  /** Состояние таблицы */
  protected state: State = {
    sort: [{ field: this.xRowPath.redaction.id.toString(), dir: "desc" }],
    group: [{ field: this.xRowPath.versionId.toString(), dir: "desc" }],
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {
  }

  /** Привести к типу строки */
  protected asRow(row: any): RowType{
    return row as RowType;
  }

  /** Определять отображать/не отображать кнопку деталий */
  public detailTemplateShowIf(dataItem: RowType, index: number): boolean {
    return !!dataItem.status;
  }

  /** Обработка двойного клика по строке */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  protected onDbCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.onPreview(this.asRow($event.originalEvent.dataItem));
  }

  /** Обработка нажатия на кнопку ПРОСМОТР */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  protected onPreview(dataItem: RowType) {
    this.deferSelectionService.tempSelection.data?.setItems([dataItem]);
    this.deferSelectionService.apply();
    this.preview$.emit(dataItem.redaction.id);
  }

  /** Обработка нажатия на кнопку КОПИРОВАТЬ */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  protected onCopy(dataItem: RowType) {
    this.copy$.emit(dataItem.redaction.id);
  }

  /** Обработка нажатия на кнопку СРАВНИТЬ */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  protected onCompare(dataItem: RowType) {
    this.compare$.emit(dataItem.redaction.id);
  }

  @traceFunc()
  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.versionMap.clear();
  }
}
