import {Injectable, OnDestroy} from "@angular/core";
import {
  Api1SubdivisionsTreelistControlControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service";
import {LoadingIndicatorService} from "../../../../../../../../src/app/services/loading-indicator.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";

/** Сервис получения данных с сервера */
@Injectable({providedIn: "root"})
@traceClass('GraphTableToolbarDataService')
export class GraphTableToolbarDataService implements OnDestroy{

  constructor(private readonly subdivisionsTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly traceService: TracerServiceBase) {
  }

  /** Получение данных строки запроса */
  @traceFunc()
  public getDataForGraph$(){
    return this.loadingIndicatorService.addToObservable(
      'Получение данных строки запроса',
      this.api1GraphControlControllerService.getDataForGraph$
    )
  }

  @traceFunc()
  ngOnDestroy(): void {
  }
}
