import {Injectable} from "@angular/core";
import {DateHelper} from "../../helpers/dateHelper";
import {ObjectHelper} from "../../helpers/objectHelper";
import {NumberHelper} from "../../helpers/numberHelper";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class TimeIntervalDurationService {
  /**
   * Валидация параметров для расчета длительностей интервала
   * Если не валидны будет брошена ошибка с текстом причины
   * @param param
   */
  public validationParameters(param: CalculateDurationParam): boolean
  {
    //Проверка на null
    if(ObjectHelper.isNullOrUndefined(param.startHour)){throw new RangeError('Час начала периода не определен')}
    if(ObjectHelper.isNullOrUndefined(param.endHour)){throw new RangeError('Час окончания периода не определен')}
    if(ObjectHelper.isNullOrUndefined(param.startMinute)){throw new RangeError('Минута начала периода не определена')}
    if(ObjectHelper.isNullOrUndefined(param.endMinute)){throw new RangeError('Минута окончания периода не определена')}
    //Проверка низа диаппазона
    if(param.startHour < 0) { throw new RangeError('Час начала интервала меньше нуля')}
    if(param.endHour < 0) {throw new RangeError('Час окончания интервала меньше нуля')}
    if(param.startMinute < 0) {throw new RangeError('Минута начала интервала меньше нуля')}
    if(param.endMinute < 0) {throw new RangeError('Минута окончания интервала меньше нуля')}
    //Проверка верха диаппазона
    if(param.startHour > 23) {throw new RangeError('Час начала интервала больше 23')}
    if(param.endHour > 24) {throw new RangeError('Час окончания интервала больше 24')}
    if(param.startMinute > 59) {throw new RangeError('Минута начала интервала больше 59')}
    if(param.endMinute > 59) {throw new RangeError('Минута окончания интервала большу 59')}
    if(param.endHour == 24 && param.endMinute > 0) {
      throw new RangeError('Если час окончания интервала равен 24, то минута не может быть больше 0')
    }
    //проверка бизнес логики
    if(param.startHour > param.endHour || (param.startHour == param.endHour && param.startMinute >= param.endMinute)){
      throw new RangeError('Время начала интервала больше времени окончания интервала')
    }
    return true;
  }


  /**
   * Расчитать длительность всего интервала (дневные + ночные часы)
   * @param param
   */
  public calculateDuration(param: CalculateDurationParam): number
  {
    this.validationParameters(param);

    const startAsMinutes = DateHelper.hoursToMinutes(param.startHour) + param.startMinute;
    const endAsMinutes = DateHelper.hoursToMinutes(param.endHour) + param.endMinute;

    return DateHelper.minutesToHours((endAsMinutes - startAsMinutes), param.digits);
  }

  /**
   * Расчитать дневную длительность интервала (с 6:00 по 22:00)
   * @param param
   */
  public calculateDayDuration(param: CalculateDurationParam): number
  {
    this.validationParameters(param)

    let startAsMinutes = DateHelper.hoursToMinutes(param.startHour) + param.startMinute;
    let endAsMinutes = DateHelper.hoursToMinutes(param.endHour) + param.endMinute;

    startAsMinutes = Math.max(startAsMinutes, DateHelper.hoursToMinutes(6));
    endAsMinutes = Math.min(endAsMinutes, DateHelper.hoursToMinutes(22));
    if (startAsMinutes > endAsMinutes){
      return 0;
    }
    return startAsMinutes > endAsMinutes ?
      0 :
      DateHelper.minutesToHours(endAsMinutes - startAsMinutes, param.digits);
  }

  /**
   * Расчитать дневную длительность интервала (с 22:00 по 06:00)
   * @param startHour
   * @param endHour
   * @param startMinute
   * @param endMinute
   * @param isToRound Необходимо ли округлить результат
   */
  public calculateNightsDuration(param: CalculateDurationParam): number
  {
    this.validationParameters(param)

    const paramClone = CalculateDurationParam.Clone(param);
    paramClone.digits = -1;

    const nightsDuration = this.calculateDuration(paramClone) - this.calculateDayDuration(paramClone);
    return NumberHelper.round(nightsDuration, param.digits);
  }

  /**
   * Получить даты из строк временного интервала
   * @param date Дата к которой относится интервал. К ней будет плюсоваться время
   * @param start Начало временного интервала
   * @param end Конец временного интервала
   */
  public getDates(date: Date, start: string, end: string, splitter: string = ':'): {start: Date, end: Date}{

    /*return {
      start: moment(date).add(start, 'minute').toDate(),
      end: moment(date).add(end, 'minute').toDate()
    }*/

    const startArray = start.split(splitter);
    const endArray = end.split(splitter);
    if(startArray.length != 2 || endArray.length != 2){
      throw new Error('Переданные данные интервала не валидны');
    }

    return {
      start: moment(date).hour(Number.parseInt(startArray[0])).minutes(Number.parseInt(startArray[1])).second(0).toDate(),
      end: moment(date).hour(Number.parseInt(endArray[0])).minutes(Number.parseInt(endArray[1])).second(0).toDate()
    };
  }
}

/**
 * Класс параметров расчета длительности интервала времени
 */
export class CalculateDurationParam {
  /** Час начала интервала времени */
  startHour: number;
  /** Час окончания интервала времени */
  endHour: number;
  /** Минута начала интервала времени */
  startMinute: number;
  /** Минута окончания интервала времени */
  endMinute: number;
  /**
   * Количество точек после запятой.
   * Усли меньше 0 то не округляется вообще
   */
  digits: number;

  /**
   * Копировать объект
   * @param source
   * @constructor
   */
  public static Clone(source: CalculateDurationParam): CalculateDurationParam{
    return CalculateDurationParam.Get(
      source.startHour,
      source.endHour,
      source.startMinute,
      source.endMinute,
      source.digits
    );
  }

  /**
   * Конструктор
   * @param startHour
   * @param endHour
   * @param startMinute
   * @param endMinute
   * @constructor
   */
  public static Get(startHour: number, endHour: number, startMinute: number, endMinute: number, digits: number){
    const instance = new CalculateDurationParam();
    instance.startHour = startHour;
    instance.endHour = endHour;
    instance.startMinute = startMinute;
    instance.endMinute = endMinute;
    instance.digits = digits;
    return instance;
  }
}

