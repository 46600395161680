/** Интерфейс содержащий объект-обветку состояния объекта */
export interface IAsHasStateChange<T>{
  /** Обвертка состояния объекта */
  readonly asHasStateChange: AsHasStateChangeObj<T>
}

/** Объект-обвертка содержащее состояние - изменен ли объект */
export class AsHasStateChangeObj<T>{
  /** Значения */
  private values: {init: T, current: T};

  /** Текущее значение. Устанавливать при каждом изменении объекта */
  public get current(){
    return this.values.current;
  }
  public set current(value){
    this.values.current = value;
  }

  /** Инициализирующее значение */
  public get init(){
    return this.values.init;
  }
  public set init(value){
    this.values.init = value;
  }

  /** Изменен ли объект */
  public get isChanged(){
    return !this.comparer(this.values.init, this.values.current);
  }

  /**
   * Конструктор
   * @param initValue инициализирующее значение
   * @param currentValue текущее значение
   * @param copy функция копирующая объект
   * @param comparer функция сравнивает два значения возвращающая true если объекты равны
   */
  constructor(initValue: T,
              currentValue: T,
              public readonly copy: (x: T) => T,
              public readonly comparer: ((init: T, current: T) => boolean)) {
    this.values = {
      init: initValue,
      current: currentValue
    };
  }

  /** Сбросить текущее значение в состояние инициализации */
  public reset(): AsHasStateChangeObj<T>{
    this.current = this.copy(this.values.init);
    return this;
  }

  /** Сохранить изменения. Инициализирующее значение установит в текущее */
  public save(): AsHasStateChangeObj<T>{
    this.values.init = this.copy(this.current);
    return this;
  }

  /** Функция конструктор */
  public static Create<T>(initValue: T, copy: (x: T) => T, comparer: (init: T, current: T) => boolean): AsHasStateChangeObj<T>{
    return new AsHasStateChangeObj<T>(initValue, copy(initValue), copy, comparer);
  }
}
