<div class="main-div" [title]="disabled ? 'В данный момент панель неактивна' : ''">
  <div style="flex: 1; ">
    <div class="control">
      <div class="label">Подразделение:</div>
      <app-subdivision-textbox-select [size]="'small'"
                                      [disabled]="disabled || !(yearMonthService.selection.data$ | async)"
                                      [dataSourceService]="subdivisionDataSource"
                                      [selection]="subdivisionSelection"
                                      class="subdivision-text-box"
      >

      </app-subdivision-textbox-select>
    </div>
  </div>

  <div style="flex-wrap: nowrap">
    <div style="display: flex; flex-wrap: nowrap">
      <div class="control">
        <div class="label">Год:</div>
        <div style="flex: 1">
          <kendo-dropdownlist style="width: auto"
                              [data]="yearMonthService.year.dataSource.data$ | async"
                              [valueField]="'id'"
                              [textField]="'text'"
                              [kendoDropDownListDataSourceSelection]="yearMonthService.year.selection"
                              [disabled]="disabled"
                              [listHeight]="400"
                              class="year-dropdownlist"
          >
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="control">
        <div class="label">Месяц:</div>
        <div>
          <kendo-dropdownlist style="width: 7em"
                              [data]="yearMonthService.month.dataSource.data$ | async"
                              [valueField]="'id'"
                              [textField]="'text'"
                              [kendoDropDownListDataSourceSelection]="yearMonthService.month.selection"
                              [disabled]="disabled"
                              [listHeight]="400"
                              class="month-dropdownlist"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </div>
  </div>
</div>
