import { Injectable, OnDestroy } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { AddEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from "@progress/kendo-angular-grid";
import { ReplaySubject, take, takeUntil } from "rxjs";
import { ArrayDataSourceIEntityId } from "src/app/classes/array-data-sources/data-source";
import { CovidLog2 } from "src/app/classes/domain/POCOs/timesheet/CovidLog2";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { AlertService } from "src/app/services/alert.service";
import { ICovid2RegisterGridComponent, ICovid2RegisterGridComponentChangeEvent, ICovid2RegisterGridComponentForm, ICovid2RegisterGridComponentFormRaw } from "../i-covid2-register-grid.component";
import { Covid2RegisterGridComponentDataService } from "./covid2-register-grid-component-data.service";


@Injectable()
export class Covid2RegisterGridComponentService extends ComponentServiceBase<ICovid2RegisterGridComponent> implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  private hasChange = false

  private directoryData = new ArrayDataSourceIEntityId<CovidLog2>();

  constructor(private readonly dataService: Covid2RegisterGridComponentDataService, private readonly alertService: AlertService) {
    super();
  }

  public onInit(): void {
    this.component.data$ = this.directoryData.data$

    this.dataService
      .getCombineLatestData$([this.component.settings.staffUnitOwnerId], this.component.settings.date)
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(this.getCombineLatestDataSubscribe.bind(this))
  }

  private getCombineLatestDataSubscribe([logs, canAddEditRemove]) {
    this.component.isReadOnly = !canAddEditRemove
    this.directoryData.setData(logs)
  }

  private createFormGroup(
    id: number,
    staffUnitId: number,
    diseaseNumber: string,
    patientFio: string
  ) {
    const requiredValidator = (control: AbstractControl<any, any>) => !control.value ? { 'message': 'Поле должно<br>быть заполнено' } : null;

    const controls = {
      id: new FormControl<number>(id),
      staffUnitId: new FormControl<number>(staffUnitId),
      diseaseNumber: new FormControl<string>(diseaseNumber, [requiredValidator]),
      patientFio: new FormControl<string>(patientFio, [requiredValidator])
    }

    this.component.form = new FormGroup<ICovid2RegisterGridComponentForm>(controls);
  }

  public addRow(e: AddEvent): void {
    this.createFormGroup(
      0,
      this.component.settings.staffUnitOwnerId,
      null, null
    )

    e.sender.addRow(this.component.form);
  }

  public editRow(e: EditEvent) {
    const item: CovidLog2 = e.dataItem;

    this.createFormGroup(
      item.id,
      item.staffUnitId,
      item.diseaseNumber,
      item.patientFio
    );

    e.sender.editRow(e.rowIndex, this.component.form);
  }

  public saveRow(e: SaveEvent) {
    const value = this.component.form.value as ICovid2RegisterGridComponentFormRaw

    // Если новая строка
    if (e.isNew) this.saveNewRow(value, e.sender, e.rowIndex)
    // Если редактирование
    else this.saveExistRow(value, e.sender, e.rowIndex)
  }

  public removeRow(e: RemoveEvent) {
    const id = e.dataItem.id;
    this.alertService.defaultAlertOption.question().mod(x => {
      x.title = 'Подтверждение';
      x.message = 'Удалить случай работы с Covid?';
      x.buttons[1].text = 'Удалить'
      x.buttons[1].callBack = () =>
        this.dataService.removeLog$(id)
          .pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
            next: () => {
              this.hasChange = true
              this.directoryData.deleteItemByIds(true, id)
            },
            error: () => this.alertService.defaultAlertOption.warning().mod(x => {
              x.message = 'При удалении записи о случае работы с Covid произошла ошибка!<br>Рекомендуем перезагрузить страницу'
            }).showAlert()
          })
    }).showAlert();
  }

  /** Закрыть редактируемую строку */
  public closeRow(grid: GridComponent, rowIndex: number) {
    grid.closeRow(rowIndex);
    this.component.form = null;
  }

  private saveNewRow(
    {
      staffUnitId, diseaseNumber, patientFio
    }: ICovid2RegisterGridComponentFormRaw,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.dataService.addLog$({
      staffUnitId,
      date: this.component.settings.date,
      diseaseNumber,
      patientFio
    }).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.hasChange = true
        this.directoryData.addItems(true, value);
        this.closeRow(sender, rowIndex)
      }, error: () => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении новой записи произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  private saveExistRow(
    {
      id, staffUnitId, diseaseNumber, patientFio
    }: ICovid2RegisterGridComponentFormRaw,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.dataService.editLog$({
      id,
      staffUnitId,
      diseaseNumber,
      patientFio
    }).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.hasChange = true
        this.directoryData.updateItems2(true, value)
        this.closeRow(sender, rowIndex)
      }, error: () => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении изменений произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  public getChangeEvent(): ICovid2RegisterGridComponentChangeEvent {
    return {
      changed: this.hasChange,
      length: this.directoryData?.data?.length ?? 0
    }
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
