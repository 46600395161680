<kendo-grid [kendoGridBinding]="data$ | async"
            (add)="addRow($event)"
            (cancel)="cancelRow($event)"
            (save)="saveRow($event)"
            (edit)="editRow($event)"
            (remove)="removeRow($event)"
            KendoGridExpanded
            columnsPadding="2px 4px"
            style="height: 100%; min-height: 200px"
>
  <ng-template kendoGridToolbarTemplate>
    <div style="width: 100%; display: flex; justify-content: space-between">
      <div>
        <button *ngIf="!isReadOnly" kendoGridAddCommand primary="true" [disabled]="form">
          <span class="k-icon k-i-plus k-font-icon"></span>
          Добавить
        </button>
      </div>
      <div style="display: flex; align-self: flex-end;">
        <div>Дата: <strong>{{ settings.date | kendoDate:'dd.MM.yyyy' }}</strong></div>
      </div>
    </div>
  </ng-template>

  <kendo-grid-column title="Номер ИБ" headerClass="wrapped-column-header">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.diseaseNumber }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <input #diseaseNumber
            kendoTextBox
            [formControl]="form.controls.diseaseNumber"
            [maxLength]="50"
      />
      <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: diseaseNumber, control: form.controls.diseaseNumber, noWrap: true }"></ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="ФИО пациента" headerClass="wrapped-column-header">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.patientFio }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <input #patientFio
            kendoTextBox
            [formControl]="form.controls.patientFio"
            [maxLength]="100"
      />
      <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: patientFio, control: form.controls.patientFio, noWrap: true }"></ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="!isReadOnly" title="" [width]="100">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand
              icon="edit"
              primary="true"
              title="Редактировать"
              [disabled]="!!form"
      ></button>
      <button kendoGridRemoveCommand
              icon="delete"
              title="Удалить"
              [disabled]="!!form"
      ></button>
      <button kendoGridSaveCommand
              primary="true"
              title="Сохранить"
              [disabled]="form?.invalid || form?.pristine"
      >
        <span class="k-icon k-i-save k-font-icon"></span>
      </button>
      <button kendoGridCancelCommand title="Отмена">
        <span class="k-icon k-i-cancel k-font-icon"></span>
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<ng-template #validationTooltip let-anchor="anchor" let-control="control" let-noWrap="noWrap">
  <kendo-popup *ngIf="control.invalid && control.enabled"
               [anchor]="anchor"
               popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg popup-correction-has-tooltip-class"
  >
    <div [ngStyle]="{ whiteSpace: noWrap ? 'nowrap' : 'none' }"
         [innerHTML]="control.errors?.message">
    </div>
  </kendo-popup>
</ng-template>
