import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class GraphDayCellService {
  /** Проверяет, является ли ячейка пустой */
  public isEmpty(staffUnit_startDate: Date, staffUnit_endDate: Date, day: Date): boolean{
    return (staffUnit_startDate && staffUnit_startDate > day) ||
      (staffUnit_endDate && staffUnit_endDate < day);
  }
}
