import {
  Directive, ElementRef, EventEmitter,
  OnDestroy, OnInit, Output,
} from "@angular/core";
import {
  SelectedGridCellDirective,
  SelectedGridCellDirectiveReadOnly,
  TargetDirective
} from "./selected-grid-cell.directive";
import {fromEvent, pairwise, ReplaySubject, startWith, switchMap, tap} from "rxjs";
import {ArrayExpanded, ArrayHelper} from "../../helpers/arrayHelper";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {ArrayDataSourceHasId} from "../../classes/array-data-sources/data-source";
import {ArrayDataSourceSelection} from "../../classes/array-data-sources/selections/array-data-source-selection";

/** Тип данных события изменения выделения */
type SelectedEventType = SelectedGridCellDirectiveReadOnly[];

/** Тип измененной ячейки */
type ChangedCellType = {type: 'added' | 'modified' | 'deleted', directive: SelectedGridCellDirective};

/** Тип данных события изменения выделения с расширенными данными*/
export type SelectedEventExpandedType = {
  /** Выделенные ячейки */
  selectedItems: SelectedGridCellDirectiveReadOnly[];
  /** Не выделенные ячейки */
  notSelectedItems: SelectedGridCellDirectiveReadOnly[];
  /** Все ячейки */
  all: SelectedGridCellDirectiveReadOnly[];
}

/** Тип события двойного клика */
type DoubleCellClickEventType = {
  /** Событие */
  event: MouseEvent,
  /** Ячейка */
  cell: SelectedGridCellDirectiveReadOnly,
}

/** Тип события контекстного меню */
type ContextMenuCellClickEventType = DoubleCellClickEventType;

/**
 * Тип обвертки над {@link SelectedGridCellDirective}.<br>
 * Необходимо для источника данных и выборщика. При изменении данных внутри {@link SelectedGridCellDirective} нужно изменять ссылку(пересоздавать) для верного определения изменения
 */
type SelectedGridCellDirectiveWrapType = {
  /** Директива ячейки */
  readonly directive: SelectedGridCellDirective
}

/**
 * Директива помечает html элемент как таблица в которой происходит выделение по ячейки<br>
 * Все выделяемые html элементы-ячейки должны быть помечены директивой {@link SelectedGridCellDirective}<br>
 * Директива также устанавливает css классы в html элементы директивы {@link SelectedGridCellDirective}. Смотри {@link CssClassType}<br>
 */
@Directive({
  selector: '[appSelectedGridCellTarget]',
  providers: [{provide: TargetDirective, useExisting: SelectedGridCellTargetDirective}]
})
export class SelectedGridCellTargetDirective implements TargetDirective, OnInit, OnDestroy {
  /** Стримы */
  private readonly stream$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Источник данных для директив ячеек */
  private _cellDirectivesDataSource: ArrayDataSourceHasId<SelectedGridCellDirectiveWrapType, string>;
  /** Выделение */
  private _selection: ArrayDataSourceSelection<SelectedGridCellDirectiveWrapType, string>;

  /** Хранилище директив ячеек */
  private readonly _cellDirectiveSet = new Set<SelectedGridCellDirective>();

  /** Событие изменения выделения */
  @Output() public selectedChange = new EventEmitter<SelectedEventType>();

  /**
   * Событие изменения с расширенными данными<br>
   * Происходит когда изменилось выделение или изменились ячейки
   */
  @Output() public selectedChangeExpanded = new EventEmitter<SelectedEventExpandedType>();

  /** Событие двойного клика по ячейке */
  @Output() public doubleCellClick = new EventEmitter<DoubleCellClickEventType>();

  /** Событие вызова контекстного меню */
  @Output() public contextMenu = new EventEmitter<ContextMenuCellClickEventType>();

  /** Конструктор */
  constructor(public readonly elRef: ElementRef) {
  }

  /** @inheritdoc */
  public ngOnInit() {
    this._cellDirectivesDataSource = new ArrayDataSourceHasId<SelectedGridCellDirectiveWrapType, string>(x => x.directive.id);
    this._selection = new ArrayDataSourceSelection<SelectedGridCellDirectiveWrapType, string>(this._cellDirectivesDataSource);


    //Трансляция события изменения выделения
    this._selection.selectedItems.change$
      .subscribe(value => {
        this.selectedChange.next(value.map(x => x.directive));
      })

    //Трансляция события изменения выделения с расширенными данными
    this._selection.selectedItems2.change$
      .pipe(
        map(selectedWraps => {
          const selected = selectedWraps.map(x => x.directive);
          const all = this._cellDirectivesDataSource.data.map(x => x.directive);

          const event: SelectedEventExpandedType = {
            selectedItems: selected,
            notSelectedItems: ArrayHelper
              .disjointElements(
                selected,
                all,
                x => x.id,
                x => x.id,
                (lefts, rights) => rights),
            all: all
          }

          return event;
        })
      ).subscribe(value => {
        this.processCells(value.selectedItems, value.all);
        this.selectedChangeExpanded.next(value)
    });

    //Изменение выделения левой клавишей мыши
    this.getSelectionChange$()
      .subscribe(value => {

        const rowIndex = {
          min: Math.min(value.firstDirective.rowIndex, value.secondDirective.rowIndex),
          max: Math.max(value.firstDirective.rowIndex, value.secondDirective.rowIndex)
        }

        const columnIndex = {
          min: Math.min(value.firstDirective.columnIndex, value.secondDirective.columnIndex),
          max: Math.max(value.firstDirective.columnIndex, value.secondDirective.columnIndex)
        }

        const newSelected = new ArrayExpanded(this._cellDirectivesDataSource.data)
          .filter(x => x.directive.rowIndex >= rowIndex.min && x.directive.rowIndex <= rowIndex.max && x.directive.columnIndex >= columnIndex.min && x.directive.columnIndex <= columnIndex.max)
          .map(x => x.directive.id)
          .fullGroupJoin(
            value.ids,
              x => x,
              x => x)
          .filter(x => x.rights[0] === undefined || x.lefts[0] === undefined)
          .map(x => x.key)
          .array;

        this._selection.setIds(newSelected, true, 'user');
    })

    //Двойной клик
    fromEvent(this.elRef.nativeElement, 'dblclick')
      .pipe(
        map(event => event as MouseEvent), //Типизируем
        map(event => {
          const result: DoubleCellClickEventType = {
            event: event,
            cell: this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(event))
          };

          return result;
        }),
        filter(event => !!event.cell),
        takeUntil(this.stream$.unsubscribe),
      )
      .subscribe(value => this.doubleCellClick.next(value));

    //Контекстное меню
    fromEvent(this.elRef.nativeElement, 'contextmenu')
      .pipe(
        map(event => event as MouseEvent), //Типизируем
        map(event => {
          const result: DoubleCellClickEventType = {
            event: event,
            cell: this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(event))
          };

          return result;
        }),
        filter(event => !!event.cell),
        takeUntil(this.stream$.unsubscribe),
      )
      .subscribe(value => { this.contextMenu.next(value)});
  }

  /** Очистить выделение */
  public clear(initiator: Parameters<typeof this._selection.setIds>[2] = 'program'){
    if(this._selection.selectedIds.data.length === 0 && this._selection.withInitiator.data.initiator === initiator){
      return;
    }

    this._selection.setIds([], true, initiator);
  }

  /** @inheritdoc */
  public registryCellDirective(directive: SelectedGridCellDirective){
    if(this._cellDirectiveSet.has(directive)){
      throw new Error('Директива ячейки уже зарегистрирована');
    }

    this._cellDirectiveSet.add(directive);
    this.onCellDirective({type: 'added', directive: directive});
  }

  /** @inheritdoc */
  public unregistryCellDirective(directive: SelectedGridCellDirective){
    if(!this._cellDirectiveSet.has(directive)){
      throw new Error('Директива ячейки еще не зарегистрирована');
    }

    this._cellDirectiveSet.delete(directive);
    this.onCellDirective({type: 'deleted', directive: directive});
  }

  /** @inheritdoc */
  public cellDirectiveChanged(directive: SelectedGridCellDirective){
    if(!this._cellDirectiveSet.has(directive)){
      throw new Error('Директива ячейки еще не зарегистрирована');
    }

    this.onCellDirective({type: 'modified', directive: directive});
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.stream$.unsubscribe.next(null);
    this.stream$.unsubscribe.complete();
    this._cellDirectivesDataSource.onDestroy();
    this._selection.onDestroy();
    this._cellDirectiveSet.clear();
  }

  /** Измененные ячейки */
  private _changedCellsBuffer: ChangedCellType[];
  /** Обработать ячейку */
  private onCellDirective(cell: ChangedCellType){
    if(this._changedCellsBuffer){
      this._changedCellsBuffer.push(cell);
      return;
    }

    this._changedCellsBuffer = [cell];

    Promise.resolve().then(() => {
      this.onCellDirectives(this._changedCellsBuffer);
      this._changedCellsBuffer = undefined;
    }).catch(reason => {
      this._changedCellsBuffer = undefined;
      console.error('Caught Error:', reason);
      throw reason;
    })
  }

  /** Применить изменение всех ячеек */
  private onCellDirectives(cellDirectives: ChangedCellType[]){
    if(!cellDirectives?.length) {
      return;
    }

    /** измененные директивы */
    const items = (() => {
      const tempMap = new ArrayExpanded(cellDirectives)
        .groupBy(
          x => x.type,
          (key, items) => ({
            key: key,
            values: items.map(x => x.directive)
          }))
        .toMap(x => x.key, x => x.values)

      return {
        added: tempMap.get('added') ?? [],
        modified: tempMap.get('modified') ?? [],
        deleted: tempMap.get('deleted') ?? [],
      };
    })();

    /** Текущее состояние */
    const currents = {
      directives: this._cellDirectivesDataSource.data
    };

    //Удаляем элементы(удаленные/модифицированные)
    const toDeleteSet = new Set([...items.modified, ...items.deleted]);
    if(toDeleteSet.size > 0){
      currents.directives = currents.directives.filter(x => !toDeleteSet.has(x.directive))
    }

    //Добавляем элементы(добавленные/модифицированные)
    const toAdding = [...items.added, ...items.modified]
      .map<SelectedGridCellDirectiveWrapType>(x => ({
        directive: x
      }));
    if(toAdding.length > 0){
      currents.directives = [...currents.directives, ...toAdding];
    }

    //Валидируем полученные данные
    const notUniqueIds = new ArrayExpanded(currents.directives)
      .groupBy(
        x => x.directive.id)
      .array
      .some(x => x.values.length > 1);

    if(notUniqueIds){
      throw new Error('Директивы ячеек имеют повторяющиеся идентификаторы');
    }

    const notUniqueRowColumnIndex = new ArrayExpanded(currents.directives)
      .groupBy(
        x => `${x.directive.rowIndex}_${x.directive.columnIndex}`)
      .array
      .some(x => x.values.length > 1);

    if(notUniqueRowColumnIndex){
      throw new Error('Директивы ячеек имеют повторяющиеся индексы [индекс строки, индекс колонки]');
    }

    //Устанавливаем данные
    this._cellDirectivesDataSource.setData(currents.directives);
  }

  /** Найти html элемент имеющий директиву {@link SelectedGridCellDirective} */
  private getHtmlElementFromMouseEvent(event: MouseEvent){
    let element = event.target as HTMLElement;

    while (true){
      if(element === this.elRef.nativeElement){
        return undefined;
      }

      if(element.hasAttribute && element.hasAttribute('appSelectedGridCell')){
        return element;
      }

      element = element.parentElement;

      if(!element){
        return undefined;
      }
    }
  }

  /**
   * Получить директиву по событию мыши
   * @return (директиву + событие) или undefined, если событие мыши не затрагивает элемент помеченный {@link SelectedGridCellDirective}
   */
  private findDirectiveByHtmlElement(htmlElement: HTMLElement): SelectedGridCellDirective{
    return htmlElement
      ? this._cellDirectivesDataSource.data
        .find(x => x.directive.elRef.nativeElement == htmlElement)?.directive
      : undefined;
  }

  /** Получить стрим изменения выделения левой клавишей мыши */
  private getSelectionChange$(){
    const events$ = {
      /** Нажатие */
      mousedown: fromEvent(this.elRef.nativeElement, 'mousedown'),
      /** мышь переместилась на другой html элемент */
      mouseover: fromEvent(this.elRef.nativeElement, 'mouseover'),
      /** Отпускание */
      mouseup: fromEvent(this.elRef.nativeElement, 'mouseup'),
      /** Выход за предел основного html элемента */
      mouseleave: fromEvent(this.elRef.nativeElement, 'mouseleave')
    }

    return events$.mousedown
      .pipe(
        map(event => event as MouseEvent), //Типизируем
        filter(event => event.button === 0), //Только левая клавиша мыши
        map((event) => {
          const directive = this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(event));

          return {
            /** Идентификаторы выделенных ячеек на момент нажатия левой клавиши */
            ids: event.ctrlKey //Если с нажатой клавишей ctrl
              ? [...this._selection.selectedIds.data] //Необходимо добавлять к текущим выбранным
              : [], //Очищаем историю
            directive: directive,
            lastDirectiveId: '',
          }
        }),
        filter(value => !!value.directive), //Интересует клик по элементу с директивой
        switchMap(value => {
          return events$.mouseover
            .pipe(
              map((mouseover: MouseEvent) => this.findDirectiveByHtmlElement(this.getHtmlElementFromMouseEvent(mouseover))),
              startWith(value.directive),
              filter(x => !!x), //Интересует только переход на элемент имеющий директиву
              filter(x => x.id !== value.lastDirectiveId), //Не интересует перемещение внутри элемента с директивой
              tap(x => {
                value.lastDirectiveId = x.id
              }), //Запоминаем как последний выделенный
              map(x => ({
                /** Идентификаторы выделенных ячеек на момент нажатия левой клавиши */
                ids: value.ids,
                /** Ячейка на которой нажали левой клавишей первый раз */
                firstDirective: value.directive,
                /** Ячейка над которой находится мышь в данный момент */
                secondDirective: x
              })),
              //
              takeUntil(
                this._cellDirectivesDataSource.data$
                  .pipe(
                    pairwise(),
                    filter(x => !ArrayHelper.equals2(x[0], x[1])),
                    take(1)
                  )
              ), //Пока не будет изменения в директивах.
              takeUntil(events$.mouseup),
              takeUntil(events$.mouseleave),
            )
        }),
        takeUntil(this.stream$.unsubscribe)
      )
  }

  /** Обработать ячейки */
  private processCells(selectedCells: SelectedGridCellDirectiveReadOnly[], allCells: SelectedGridCellDirectiveReadOnly[]){
    const selectedIds = new Set<string>();
    const selectedRows = new Set<number>();
    const selectedColumns = new Set<number>();

    for (let selectedItem of selectedCells) {
      selectedIds.add(selectedItem.id);
      selectedRows.add(selectedItem.rowIndex);
      selectedColumns.add(selectedItem.columnIndex);
    }

    for (let item of allCells as SelectedGridCellDirective[]) {
      item.inSelectedRow = !selectedRows.has(item.rowIndex) ? 'out' : (selectedRows.size === 1 ? 'single' : 'multi');
      item.inSelectedColumn = !selectedColumns.has(item.columnIndex) ? 'out' : (selectedColumns.size === 1 ? 'single' : 'multi');
      item.isSelected = selectedIds.has(item.id);
    }
  }
}
