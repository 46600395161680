<ng-container *ngIf="!isEqualData">
  <div class="not-equal-approvals-info-div">В выбранных подразделениях шаги согласования не равны, при изменении шагов согласования, данные будут перезаписаны во всех выбранных подразделениях</div>
</ng-container>
<kendo-grid [data]="dataSource"
            (add)="onAdd()"
            (cancel)="onCancel($event)"
            (save)="onSave($event)"
            (remove)="onRemove($event)"
            kendoGridSelectBy="id"
            (selectionChange)="onSelectionChanged($event)"
            [selectedKeys]="selectedKeys"
            [selectable]="{
              mode: 'single',
              enabled: true
            }"
            #container
>
  <ng-template kendoGridToolbarTemplate>
    <button
      primary="true"
      kendoGridAddCommand
      [disabled]="!canAdd"
      [icon]="'add'"
      title="Добавить новую запись"
    >
      Добавить
    </button>

    <button
      primary="true"
      kendoButton
      [disabled]="!canReorderUp"
      [icon]="'arrow-up'"
      title="Переместить вверх в порядке"
      (click)="reorderUp()"
    >Вверх</button>

    <button
      primary="true"
      kendoButton
      [disabled]="!canReorderDown"
      [icon]="'arrow-down'"
      title="Переместить вниз в порядке"
      (click)="reorderDown()"
    >Вниз</button>

  </ng-template>


  <kendo-grid-column title="Ответственныe">

  <ng-template kendoGridEditTemplate let-isNew="isNew">

    <kendo-combobox [formControl]="formGroup.get('approvalEmployee')"
      #fioComboBox
      [data]="userList"
      [kendoDropDownFilter]="filterSettings"
      valueField="id"
      textField="fio"
      placeholder="ФИО"
      [disabled]="!!subdivisionDropDowntree.value"
      [title]="subdivisionDropDowntree.value ? disabledTitle : ''"
      >
    </kendo-combobox>
    или
    <kendo-dropdowntree
    #subdivisionDropDowntree
    [formControl]="formGroup.get('approvalSubdivision')"
    kendoDropDownTreeExpandable
    [expandOnFilter]="expandSettings"
    [kendoDropDownTreeFlatBinding]="settings.allSubdivisions$ | async"
    parentIdField="parentId"
    valueField="id"
    textField="longName"
    [filterable] = true
    placeholder="Подразделение"
    listHeight="400"
    [disabled]="!!fioComboBox.value"
    [title]="fioComboBox.value ? disabledTitle : ''"
    >
  <ng-template
    kendoDropDownTreeNodeTemplate
    let-dataItem
    let-index="index"
  >
  <div [style.opacity]="isApprovalSubdivisionItemDisabled(dataItem) ? 0.4 : 1" (click)="blockIfApprovalSubdivisionItemDisabled($event, dataItem)">{{ dataItem.longName }}</div>
  </ng-template>
  </kendo-dropdowntree>
  </ng-template>

  <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
    {{dataItem.approvalSubdivision?.longName}} {{dataItem.approvalEmployee?.fio}}
  </ng-template>

  </kendo-grid-column>

  <kendo-grid-command-column [width]="110">
    <ng-template kendoGridCellTemplate>
      <button kendoGridRemoveCommand
              type="button"
              icon="delete"
              *ngIf="!formGroup"
              title="Удалить"
      ></button>
      <button kendoGridSaveCommand
              type="button"
              icon="save"
              primary="true"
              title="Сохранить"
              *ngIf="formGroup"
              [disabled]="!formGroup.valid || !formGroup.dirty"
      >
      </button>
      <button kendoGridCancelCommand
              type="button"
              icon="cancel"
              title="Отмена"
      ></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
