import {GetGraphModelResult_Directories_Day} from "../../../../../Classes/GetGraphModelResult";
import {DayType} from "./day-type-view-model.class";
import {IDay} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/Day";

export class Day implements Pick<IDay, 'id' | 'date'> {
  constructor(
    public id: number,
    public date: Date,
    public type: DayType,
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_Day, dayTypes: Array<DayType>): Day {
    return new Day(source.id, source.date, dayTypes.find(x => x.id == source.dayTypeId));
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_Day>, dayTypes: Array<DayType>): Array<Day> {
    return source.map(item => {
      return Day.Create(item, dayTypes);
    });
  }

  public static CreateArrayAndSortByDate(source: Array<GetGraphModelResult_Directories_Day>, dayTypes: Array<DayType>): Array<Day> {
    return Day.CreateArray(source, dayTypes).sort((a, b) => +a.date - +b.date)
  }
}
