import {
  GraphDayViewModel,
  GraphGridRowModel
} from "../classes/view-models/row-and-graph-day-view-model.class";
import {Day} from "../classes/view-models/day-view-model.class";
import {GraphNormFactCalculatorService} from "./graph-norm-fact-calculator.service";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {map} from "rxjs/operators";
import {DayType} from "../classes/view-models/day-type-view-model.class";
import {
  Api1GraphControlControllerService,
  ISaveGraphRequestGraphDaysItem
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {LoadingIndicatorService} from "../../../../../../../../src/app/services/loading-indicator.service";
import {process, SortDescriptor, State} from "@progress/kendo-data-query";
import {CellSelectingDirectiveService} from "../directives/cell-selecting.directive.service";
import {GetGraphModelResult} from "../../../../Classes/GetGraphModelResult";
import {AppSettingsService} from "../../../../../../../../src/app/services/app-settings.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {trace} from "../../../../../../../../src/app/modules/trace/operators/trace";

/**  Класс данных для грида */
@Injectable()
@traceClass('GraphDataSourceService')
export class GraphDataSourceService implements OnDestroy{
  /** Данные полученные с сервера */
  public sourceFromServer: GetGraphModelResult = null;

  /** Дни месяца */
  public days: Array<Day>;

  /** Источник данных как стрим */
  public source$: ReplaySubject<GraphGridRowModel[]> = new ReplaySubject<GraphGridRowModel[]>(1);

  private _source: Array<GraphGridRowModel>;
  /** Источник для grid */
  public get source(){
    return this._source;
  }
  public set source(value){
    this._source = value;
    this.source$.next(value);
  }

  /** Изменен ли график */
  public get hasChange(): boolean {
    if (this.source == null) {
      return false;
    }
    return this.source.some(item => item.changedGraphDays.length > 0);
  };

  /** Состояние графика */
  public state: State = {
    sort: this.getGridDefaultSortSettings()
  };

  private unsubscribe$: ReplaySubject<any> = null;

  constructor(public graphNormFactCalculatorService: GraphNormFactCalculatorService,
              private api1GraphControlControllerService: Api1GraphControlControllerService,
              private loadingIndicatorService: LoadingIndicatorService,
              private appSettingsService: AppSettingsService,
              private cellSelectingDirectiveService: CellSelectingDirectiveService,
              private tracerService: TracerServiceBase) {
  }

  /** Инициализировать данные */
  @traceFunc()
  onInit(redactionId: number, loadingMessage='Загрузка данных графика'): Observable<void>{
    this.unsubscribe$ = new ReplaySubject<any>(1);
    this.cellSelectingDirectiveService.clear(); //Очищаем выделенные ячейки

    return this.loadingIndicatorService.addToObservable(
      loadingMessage,
      this.api1GraphControlControllerService.getGraphRow$(redactionId))
      .pipe(trace(this.tracerService), map(value => {
        this.sourceFromServer = value;
        this.days = Day.CreateArrayAndSortByDate(value.directories.days, DayType.CreateArray(value.directories.dayTypes));
        this.source = GraphGridRowModel.CreateArray(value, this.days, this.graphNormFactCalculatorService);
        this.setState(this.state);
        this.changeRedaction(redactionId);
      }));
  }

  /** Изменить редакцию без загрузки данных
   *  Необходимо к примеру после сохранения, редакция изменилась данные нет
   */
  @traceFunc()
  public changeRedaction(redactionId: number){
    this.graphNormFactCalculatorService.ngOnDestroy();
    this.graphNormFactCalculatorService.onInit(redactionId, this);
  }

  /** Установить состояние данных(сортировку фильтрацию и тд) */
  public setState(state: State){
    this.state = state;
    this.source = process(this.source, state).data
  }

  /** Добавление строки в DataSource */
  @traceFunc()
  public addRow(row: GraphGridRowModel){
    this.source.push(row);
    row.staffUnit.asObservable.onChange();
    this.setState(this.state);
    row.onChangedGraphDay(null);
  }

  /** Удаление строки */
  @traceFunc()
  public removeRow(staffUnitOwnerId: number){
    this.cellSelectingDirectiveService.clear();
    this.source = this.source.filter(x => x.staffUnit.ownerId != staffUnitOwnerId);
  }

  /** Получить данные для запроса сохранения графика */
  @traceFunc()
  public getDataForSaveGraph()
    : { sourceData: Array<{guid: number, staffUnitId: number, graphDay: GraphDayViewModel}>, graphDays: Array<ISaveGraphRequestGraphDaysItem>} {
    const tempData =[].concat(...this.source.filter(x => x.changedGraphDays.length > 0).map(r => r.changedGraphDays.map((gD, index) =>{
      return {
        staffUnitId: r.staffUnit.ownerId,
        graphDay: gD
      }
    }))).map((x, index) =>  {
      return {
        guid: index + 1,
        staffUnitId: x.staffUnitId,
        graphDay: x.graphDay
      }
    });

    return {
      sourceData: tempData,
      graphDays: tempData.map(x => new class implements ISaveGraphRequestGraphDaysItem{
        guid = x.guid;
        sUOId = x.staffUnitId;
        tIId = x.graphDay.timeInterval ? x.graphDay.timeInterval.id : null;
        dDId = x.graphDay.dayDeviation ? x.graphDay.dayDeviation.id : null;
        dDCV = x.graphDay.dayDeviation?.hasCustomValue ? x.graphDay.customValue : null;
        date = x.graphDay.day.date;
        sL = x.graphDay.subtractLunch;
        tiDr = undefined;
        flDnr = x.graphDay.flexDinner ? x.graphDay.flexDinner : null;
      })
    }
  }

  /** Получить первоначальные настройки сортировки графика */
  public getGridDefaultSortSettings(): Array<SortDescriptor>{
    const factory = (dir: 'asc' | 'desc', field: string): SortDescriptor => {
      return {
        dir: dir,
        field: field
      }
    }
    //TODO: ВЫНЕСТИ НАСТРОЙКИ НА СЛОЙ БД
    if(this.appSettingsService.company.includes('ikb2_registry')){    // ПАРАМЕТРЫ СОРТИРОВКИ ДЛЯ ikb2
      return [
        factory('asc', 'staffUnit.occupation.name'),
        factory('asc', 'staffUnit.employee.fullName'),
        factory('asc', 'staffUnit.isProxy'),
        factory('asc', 'staffUnit.IsEmployment'),
        factory('desc', 'staffUnit.rate'),
        factory('asc', 'staffUnit.ownerId'), //Для режима сравнения двух редакций
        factory('desc', 'compareState'), //Для режима сравнения двух редакций
      ];
    } else if(this.appSettingsService.company.includes('gvv2_registry')){   // ПАРАМЕТРЫ СОРТИРОВКИ ДЛЯ GVV2
      return [
        factory('asc', 'staffUnit.occupation.name'),
        factory('asc', 'staffUnit.employee.fullName'),
        factory('asc', 'staffUnit.isProxy'),
        factory('asc', 'staffUnit.IsEmployment'),
        factory('desc', 'staffUnit.rate'),
        factory('asc', 'staffUnit.ownerId'), //Для режима сравнения двух редакций
        factory('desc', 'compareState'), //Для режима сравнения двух редакций
      ];
    } else {    // ПАРАМЕТРЫ СОРТИРОВКИ ДЛЯ ДРУГИХ КЛИНИК (как в ГВВ - сначала по ФИО, потом должность)
      return [
        factory('asc', 'staffUnit.employee.fullName'),
        factory('asc', 'staffUnit.occupation.name'),
        factory('asc', 'staffUnit.isProxy'),
        factory('asc', 'staffUnit.IsEmployment'),
        factory('desc', 'staffUnit.rate'),
        factory('asc', 'staffUnit.ownerId'), //Для режима сравнения двух редакций
        factory('desc', 'compareState'), //Для режима сравнения двух редакций
      ];
    }
  }

  @traceFunc()
  ngOnDestroy() {
    this.unsubscribe$?.next(null);
    this.unsubscribe$?.complete();
    this.unsubscribe$ = null;
    this.graphNormFactCalculatorService.ngOnDestroy();
    this.sourceFromServer = null;
    this.days = null;
    this.source = null;
  }
}
