import {GetGraphModelResult_Directories_Occupation} from "../../../../../Classes/GetGraphModelResult";
import {Occupation} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Occupation";

export class OccupationVM {
  constructor(
    public ownerId: number,
    public name: string,
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_Occupation): OccupationVM {
    return new OccupationVM(source.ownerId, source.name)
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_Occupation>): Array<OccupationVM> {
    return source.map(item => OccupationVM.Create(item));
  }

  public static Create2(source: Occupation): OccupationVM{
    return new OccupationVM(source.ownerId, source.name);
  }
}
