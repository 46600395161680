import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CovidJournalToolbarComponent,
  CovidJournalToolbarComponentChangeEvent
} from "../covid-journal-toolbar/covid-journal-toolbar.component";
import {
  CovidRegisterComponentSettings,
  CovidRegisterComponentSettings_Item, CovidRegisterComponentSettings_TimeInterval, ICovidRegisterGridComponentChangeEvent
} from "../../../../../../../../src/app/components/covid/covid-register-grid/covid-register-grid.component";
import {ExtensionObj} from "../../../../../../../../src/app/helpers/extensionObj";
import * as moment from "moment";
import {GraphDayCellService} from "../../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/components/graph-day-cell/services/graph-day-cell.service";
import {TimeIntervalDurationService} from "../../../../../../../../src/app/services/timeIntervalServices/time-interval-duration.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-covid-journal',
  templateUrl: './covid-journal.component.html',
  styleUrls: ['./covid-journal.component.css']
})
@traceClass('CovidJournalComponent')
export class CovidJournalComponent implements OnInit, OnDestroy {
  @ViewChild('toolbarComponent') private toolbarComponent: CovidJournalToolbarComponent;

  public toolbarDisabled: boolean = false;
  public covidJournalToolbarComponentChangeEvent: CovidJournalToolbarComponentChangeEvent = null;
  public gridSettings: CovidRegisterComponentSettings = null;

  constructor(private graphDayCellService: GraphDayCellService,
              private timeIntervalDurationService: TimeIntervalDurationService,
              private readonly tracerService: TracerServiceBase) {

  }

  @traceFunc()
  ngOnInit() {
  }

  /** Событие изменения toolbar */
  @traceFunc()
  toolbarChange($event: CovidJournalToolbarComponentChangeEvent){
    this.covidJournalToolbarComponentChangeEvent = $event;
    if(!$event){
      this.gridSettings = null;
    }else {
      this.gridSettings = (() => {
        const date = moment({year: $event.values.year.id, month: $event.values.month.id - 1, date: $event.values.day}).toDate();

        return new CovidRegisterComponentSettings(
          date,
          $event.view.rows.map(row => {
            if(this.graphDayCellService.isEmpty(row.staffUnit.startDate, row.staffUnit.endDate, date)){
              return null;
            }
            if(!$event.values.day){
              return null;
            }

            const graphDay = row.graphDays[$event.values.day -1];
            const timeInterval = graphDay.timeInterval;
            if(!timeInterval){
              return null;
            }

            return new CovidRegisterComponentSettings_Item(
              row.staffUnit.id,
              {
                imageUrl: row.imageUrl,
                occupation: row.occupationName,
                subdivision: row.subdivisionName,
                rate: row.staffUnit.rate
              },
              (() => {
                const dates = this.timeIntervalDurationService.getDates(
                  date,
                  timeInterval.start,
                  timeInterval.end);

                return new CovidRegisterComponentSettings_TimeInterval(dates.start, dates.end, timeInterval.duration);
              })()
            )
          }).filter(x => x),
          true
        )
      })()
    }
  }

  /** Обработка события изменения данных в таблице */
  @traceFunc()
  onGridChange($event: ICovidRegisterGridComponentChangeEvent) {
    this.toolbarComponent.daySelector.setHasCovidToSelectDay(!!$event.busyTime); //Обновляем флаг содержит ли ковид часы
  }

  /** Обработка события добавления/редактирования записи в таблице */
  @traceFunc()
  onGridEditOrAdd($event: boolean){
    this.toolbarDisabled = $event;
  }

  @traceFunc()
  ngOnDestroy() {
  }
}
