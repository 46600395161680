import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  Api1DayDeviationControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-dayDeviation-controller.service";
import {DayDeviation} from "../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {take} from "rxjs/operators";
import {CellClickEvent} from "@progress/kendo-angular-grid";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {throwError} from "rxjs";
import {CellClickExpandedEvent} from 'src/app/services/directives/grid-treelist-expanded-directive.service';

@Component({
  selector: 'app-day-deviation-grid',
  templateUrl: './day-deviation-grid.component.html',
  styleUrls: ['./day-deviation-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayDeviationGridComponent implements OnInit {

  public source: Array<DayDeviation>;

  /**
   * Событие клика по строке
   */
  @Output() public onceClick: EventEmitter<DayDeviation> = new EventEmitter<DayDeviation>();

  /**
   * Событие двойного клика по строке
   */
  @Output() public doubleClick: EventEmitter<DayDeviation> = new EventEmitter<DayDeviation>();


  constructor(private dayDeviationControllerService: Api1DayDeviationControllerService,
              private loadingIndicatorService: LoadingIndicatorService,
              public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadingIndicatorService.addToObservable(
      'Загрузка списка отклонений',
      this.dayDeviationControllerService.getAllDayDeviations$()
    ).pipe(take(1)).subscribe({
      next: res => {
        this.source = res;
        this.changeDetectorRef.detectChanges();
      },
      error: error => {
        throwError(() => error);
      }
    });
  }

  onDblClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.doubleClick.emit($event.originalEvent.dataItem);
  }

  onCellClick($event: CellClickEvent) {
    this.onceClick.emit($event.dataItem);
  }
}
