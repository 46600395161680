import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { ErrorPageSettings, WorkSpaceErrorComponentService } from "projects/timesheet/src/app/services/workspace/work-space-error.component.service";
import { catchError, defer, mergeMap, Observable, ReplaySubject, Subject, take, takeUntil, tap } from "rxjs";
import { DayDeviation } from "src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import { ArrayHelper } from "src/app/helpers/arrayHelper";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { AlertButton, AlertService } from "src/app/services/alert.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { DayDeviationItem, IDayDeviationClickEvent, IDayDeviationPanelComponent, ISelectedForPanelsEvent } from "../i-day-deviation-panel-component";
import { DayDeviationPanelComponentDataService } from "./day-deviation-panel-component-data.service";
import {DayDeviationEnum} from "../../../../../../../../../../src/app/classes/domain/enums/day-deviation.enum";

@Injectable()
@traceClass('DayDeviationPanelComponentService')
export class DayDeviationPanelComponentService extends ComponentServiceBase<IDayDeviationPanelComponent> implements OnDestroy {

  /** Стрим события выделения для панелей Отклонений/временных интервалов и др */
  public selectedCells$: Subject<ISelectedForPanelsEvent> = new ReplaySubject<ISelectedForPanelsEvent>(1);

  /** Событие выбора отклонения */
  public select$ = new EventEmitter<IDayDeviationClickEvent>();

  /** Отключена ли панель отклонений */
  public disabled: boolean = false;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Список отклонений */
  private _dayDeviationsRaw: Array<DayDeviationItem>;

  /** Стрим списка отклонений */
  private _dayDeviations$ = new ReplaySubject<Array<DayDeviationItem>>(1)

  private set _dayDeviations(dayDeviations: Array<DayDeviationItem>) {
    this._dayDeviationsRaw = dayDeviations
    this._dayDeviations$.next(dayDeviations)
  }

  private get _dayDeviations(): Array<DayDeviationItem> {
    return this._dayDeviationsRaw
  }

  /** Стрим получения отклонений */
  public dayDeviations$ = defer(() => {
    if (!!this._dayDeviations) {
      return this._dayDeviations$
    }

    return this.dataService.getAllDayDeviations().pipe(
      trace(this.tracerService),
      tap(dayDeviations => {
        this._dayDeviations = dayDeviations.map(dayDeviation => new DayDeviationItem(dayDeviation))
      }),
      take(1),
      takeUntil(this.streams$.unsubscribe),
      catchError((error, caught) => {
        this.workSpaceErrorComponentService.redirect(ErrorPageSettings.AsExtensionObj().modResult(x => {
          x.message = 'При загрузке данных произошла ошибка!'
          x.stackTrace = error;
        }))
        return caught;
      }),
      mergeMap(() => this._dayDeviations$)
    )
  })

  /** Все ли ячейки содержат Временной интервал */
  private allSelectedCellsHaveTimeInterval: boolean

  constructor(private readonly dataService: DayDeviationPanelComponentDataService,
              private readonly workSpaceErrorComponentService: WorkSpaceErrorComponentService,
              private readonly tracerService: TracerServiceBase,
              private readonly alertService: AlertService,
              private readonly notificationService: KendoNotificationService) {
    super();
  }

  public onInit() {
    this.selectedCellsSubscribe()
  }

  /** Обработка события нажатия на отклонение */
  public onButtonClick(dayDeviation: DayDeviation) {
    if (dayDeviation && dayDeviation.id == DayDeviationEnum.ИО) {
      this.notificationService.showWarning({ content: 'Вакантная ставка на период ИО появится только после сохранения графика', hideAfter: 5000 })
    }

    const result: IDayDeviationClickEvent = {
      dayDeviation: dayDeviation,
      isClearTimeInterval: true,
      customValue: null
    }

    if (!dayDeviation.hasCustomValue && !dayDeviation.deviationWithInterval) { //Выходим если отклонение НЕ может быть с интервалом и при этом нет значения
      this.select$.emit(result)
      return;
    }

    this.question1(dayDeviation).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(question1 => {
      if (question1 == 'cancel') {
        return;
      }
      if (question1 == 'yeas') {
        result.isClearTimeInterval = false;
      }

      if (dayDeviation.hasCustomValue) {
        this.selectedCells$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
          const getMinValueFromCells = () => {
            const durations = ArrayHelper
              .distinct(value.cells.map(x => x.timeInterval?.duration))
              .filter(x => !!x)
              .sort((a, b) => {
                return a - b;
              });

            return durations.length > 0 ? durations[0] : 24;
          }

          result.customValue = 0;
          this.component.customValueModel = {
            iDayDeviationClickEvent: result,
            maxValue: dayDeviation.isIncludeInCalculate ?
              value.row.workDayHourDuration :
              getMinValueFromCells()
          };
        })
      } else {
        this.select$.emit(result);
      }
    })
  }

  /** Обработка установки значения у отклонения */
  public onCustomValueSet($event: number) {
    this.select$.emit(this.component.customValueModel.iDayDeviationClickEvent)
    this.component.customValueModel = null;
  }

  /** Подписаться на событие выделения ячеек */
  private selectedCellsSubscribe() {
    this.selectedCells$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const isSelected = value.cells?.some(x => x) ?? false;
      const disabled = this.disabled || !isSelected || value.containsDutyRow;

      /** У какой-то выделеной ячейки ExecutionDutiesFlag равен true */
      const someHasExecutionDutiesFlag = isSelected && value.cells?.some(x => x?.staffUnit?.executionDutiesFlag)
      /** Является ли кто-то из выделенных сотрудников совместителем или работающим по УВОР */
      const someIsCombinationOrUvor = isSelected && (value.containsCombinationRow || value.containsUvorRow);
      /** Выделено ли несколько строк в Графике */
      const multipleRowsSelected = value.isMultipleRow;

      this.allSelectedCellsHaveTimeInterval = value.cells?.every(c => c.timeInterval !== null)
      this.component.disabled = disabled

      this._dayDeviations = this._dayDeviations?.map(dd => {
        dd.isDisabled = disabled ||
          (dd.dayDeviation.deviationWithInterval &&
            (multipleRowsSelected ||
              (!this.allSelectedCellsHaveTimeInterval && dd.dayDeviation.isOperatingFlag)))
          || (someHasExecutionDutiesFlag && dd.dayDeviation.id == DayDeviationEnum.ИО
          || (someIsCombinationOrUvor && dd.dayDeviation.id == DayDeviationEnum.ПК))
          || (dd.dayDeviation.id == DayDeviationEnum.ИО && !value.canSetIo);

        return dd;
      })
    })
  }

  /** Отобразить вопрос */
  private question1(dayDeviation: DayDeviation): Observable<'cancel' | 'yeas' | 'no' | null> {
    return new Observable(subscriber => {
      this.selectedCells$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(event => {
        if (!dayDeviation.deviationWithInterval || !this.allSelectedCellsHaveTimeInterval) {
          subscriber.next(null);
          subscriber.complete();
          return;
        }

        this.alertService.defaultAlertOption.question()
          .mod(x => {
            x.message = `<strong>${dayDeviation.shortName}</strong> и работа проходят в один и тот же день?`;
            x.buttons = new Array<AlertButton>(
              AlertButton.Get('Отмена', false, true, () => {
                subscriber.next("cancel");
                subscriber.complete();
              }),
              AlertButton.Get('НЕТ', true, false, () => {
                subscriber.next('no');
                subscriber.complete();
              }),
              AlertButton.Get('ДА', true, false, () => {
                subscriber.next('yeas');
                subscriber.complete();
              })
            );
          }).showAlert();
      });
    })
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
