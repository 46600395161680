<kendo-grid
  [data]="source"
  [sortable]="true"
  [selectable]="{mode: 'single'}"
  KendoGridExpanded (dbCellClick)="onDblClick($event)"
  (cellClick)="onCellClick($event)"
  style="max-height: 100%"
>

  <kendo-grid-column
    title="Наименование"
    class="no-padding"
    width="95%"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Код"
    width="10%"
    class="no-padding"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [style.background-color]="dataItem.color"
           [style.min-height]="'28px'"
           [style.min-width]="'max-content'"
           [style.line-height]="'28px'"
           style="text-align: center; font-weight: bold; color: black; min-width: 50px; height: 100%;"
      >{{dataItem.shortName}}</div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
