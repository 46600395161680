import { DrawerItemCustom, WorkspaceServiceBase } from '../../../../../src/app/components/workspace/workspace.service';
import { TimeSheetFunctionEnum } from '../../../../../src/app/classes/domain/AccessEnums/timeSheet/timeSheetFunctionEnum';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimesheetWorkspaceService extends WorkspaceServiceBase {
  public programName = 'Графики и табели по учёту рабочего времени';

  getItems$(basePath: string, userFunctions: string[], companyName: string): Observable<DrawerItemCustom[]> {
    const items: DrawerItemCustom[] = [];

    const access = {
      administration: userFunctions?.some(x => x == TimeSheetFunctionEnum.AllGraphViewing) ?? false,
      graph: userFunctions?.some(x => x == TimeSheetFunctionEnum.GraphAccess) ?? false,
      table: userFunctions?.some(x => x == TimeSheetFunctionEnum.TableAccess) ?? false,
      monitoring: userFunctions?.some(x => x == TimeSheetFunctionEnum.GraphApproving ||
        x == TimeSheetFunctionEnum.TableApproving) ?? false,
      patientMovement: userFunctions?.some(x => x == TimeSheetFunctionEnum.TableAccess) ?? false,
      patientMovementSvod: userFunctions?.some(x => x == TimeSheetFunctionEnum.EditPatientMovementsSvod) ?? false,
    };

    if (access.graph) {
      items.push({ text: 'График', icon: 'graph', selected: true, routPath: basePath + 'graphandtable/graph' });
    }

    if (access.table) {
      items.push({ text: 'Табель', icon: 'table', routPath: basePath + 'graphandtable/table' });
    }

    if (items.length > 0 && (access.monitoring || access.administration)) { //Разделитель
      items.push({ separator: true });
    }

    if (access.monitoring) {
      items.push({ text: 'Мониторинг', icon: 'toggle-full-screen-mode', routPath: basePath + 'monitoring' });
    }

    if (access.patientMovement && companyName.includes("ikb2_registry")) {
      items.push({ text: 'Движение пациентов в учреждении', icon: 'track-changes-enable', routPath: basePath + 'patients-movements' });
    }

    if (access.patientMovementSvod) {
      items.push({ text: 'СВОД движения пациентов в учреждении', icon: 'track-changes-enable', routPath: basePath + 'patients-movements-svod' });
    }

    if (access.administration) {
      items.push({ text: 'Администрирование', icon: 'gears', routPath: basePath + 'administration' });
    }

    if (false) { //Включить когда будут справочники
      items.push({ text: 'Справочники', icon: 'dictionary-add' });
    }

    return of(items);
  }
}
