import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { tap} from "rxjs";
import { IResponsibleGridSettings } from './responsible-grid/responsible-grid.component';
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";
import {ArrayDataSourceHasId} from "../../../classes/array-data-sources/data-source";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {exElementByIndexOr} from "../../../operators/ex-element-by-index-or";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-administration-responsible',
  templateUrl: './administration-responsible.component.html',
  styleUrls: ['./administration-responsible.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('AdministrationResponsibleComponent')
export class AdministrationResponsibleComponent implements OnInit, OnDestroy {
  /** Настройки */
  @Input() public responsibleSettings: IResponsibleGridSettings;

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>;
  /** Сервис получения данных для подразделений */
  @Input() public get subdivisionDataSourceService(){
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value){
    this._subdivisionDataSourceService = value;
    this.deferSubdivisionSelectionService.originSelection = value?.dataSource;
  }

  /** Selection для подразделений.(НЕ ОБЯЗАТЕЛЬНО) */
  @Input() public set subdivisionSelection(value: ArrayDataSourceSelection<ISubdivision, number>){
    this.deferSubdivisionSelectionService.originSelection = value;
  }

  /** Стрим выбранного идентификатора подразделения */
  public selectedSubdivisionId: number;

  /** Костыль для изъятия правой таблицы для ее перерисовки */
  public hideRight = true;

  constructor(public readonly deferSubdivisionSelectionService: DeferSelectionService<ArrayDataSourceHasId<ISubdivision, number>, ArrayDataSourceSelection<ISubdivision, number>>,
              private tracerService: TracerServiceBase) {
    deferSubdivisionSelectionService.tempCtorFunc = dataSource => new ArrayDataSourceSelection<ISubdivision, number>(dataSource);
    deferSubdivisionSelectionService.isDeferApply = false;

    deferSubdivisionSelectionService.tempSelection.data$.subscribe(value => {
      if(!value){
        this.selectedSubdivisionId = undefined;
        return;
      }

      value.selectedIds.data$
        .pipe(
          exElementByIndexOr(0, undefined),
          tap(value => {this.hideRight = true; setTimeout(() => {this.hideRight = false})}), //TODO: костыль, смотри this.hideRight
        ).subscribe(id => this.selectedSubdivisionId = id);
    })
  }

  @traceFunc()
  ngOnInit(){
  }

  @traceFunc()
  ngOnDestroy(){
  }
}
