import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {GraphDataSourceService} from "./services/data-sources/graph-data-sources/graph-data-source.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {DbChangedListener} from "../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {defer, Observable, of, ReplaySubject, Subject, switchMap, tap, throwError} from "rxjs";
import {
  RedactionGridDialogService
} from "../../../shareds/redactions/redaction-grid2/services/redaction-grid-dialog.service";
import {
  RedactionGrouperDataSourceServiceGraphBase
} from "../../../shareds/redactions/redaction-grid2/services/redaction-grouper-data-source.service";
import {xnameofPath} from "../../../../../../../../src/app/functions/nameof";
import {IGraphComponent} from "./i-graph.component";
import {GraphComponentServiceBase} from "./services/graph-component.service";
import {
  RedactionGridDataSource1Service
} from "../../../shareds/redactions/redaction-grid2/services/redaction-grid-data-source.service";
import {
  RedactionGridDataSourceSelection
} from "../../../shareds/redactions/redaction-grid2/services/redaction-grid-data-source.selection";
import {
  DisplayErrorsService
} from "../../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {LoadingIndicatorService} from "../../../../../../../../src/app/services/loading-indicator.service";
import {delay, filter, finalize, map, take, takeUntil} from "rxjs/operators";
import {exErrorHandler} from "../../../../../../../../src/app/operators/ex-error-handler";
import {
  exLoadingMessage,
  exLoadingMessage2
} from "../../../../../../../../src/app/operators/ex-loading-message.operator";
import {KendoNotificationService} from "../../../../../../../../src/app/services/kendo-notification.service";
import {GraphComponentEditServiceBase} from "./services/graph-component-edit.service";
import {AlertService} from "../../../../../../../../src/app/services/alert.service";
import {
  HierarchiStringService
} from "../../../../../../../../src/app/components/hierarchi-strings/services/hierarchi-string.service";
import {trace} from "../../../../../../../../src/app/modules/trace/operators/trace";
import {CommentDialogService} from "../../../../../../../../src/app/components/comment/comment-dialog.service";
import {exDistinctUntilChanged} from "../../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import {
  TimeIntervalPanel2ComponentDataSourceService,
} from "./components/time-interval-panel2/services/time-interval-panel2-component-data-source.service";
import {
  DayDeviationPanel2ComponentDataSourceService
} from './components/day-deviation-panel2/services/day-deviation-panel2-component-data-source.service';
import {CellDataDirective, GraphGrid2Component} from "./components/graph-grid2/graph-grid2.component";
import {ObservableEmittedType} from "../../../../../../../../src/app/types/observable.type";
import {IDayDeviation} from "../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {
  ITimeInterval,
  TimeInterval
} from "../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import {
  GraphGrid_EditablePartGraphDayCell
} from "./services/data-sources/graph-grid-graph-day-cell-data-sources/graph-grid-graph-day-cell-data-source.classes";
import {DialogService} from "@progress/kendo-angular-dialog";
import {ArrayExpanded} from "../../../../../../../../src/app/helpers/arrayHelper";
import {DayDeviationEnum} from "../../../../../../../../src/app/classes/domain/enums/day-deviation.enum";
import {TraceParamEnum} from "../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../../src/app/modules/trace/decorators/param.decorator";
import {GraphRowContextService} from "./services/context-services/graph-row-context.service";
import {GraphRowEdit2Service} from "./services/graph-row-edit2.service";
import {
  StaffUnitTypeDataSourceService2
} from "../../../../../../../../src/app/services/common-data-source-services/staff-unit-type-data-source.service";
import {StaffUnitTypeEnum} from "../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {DateHelper} from "../../../../../../../../src/app/helpers/dateHelper";

/** Модель для окна значения временного интервала */
class DayDeviationCustomValueVM {
  private _value$: Subject<number> = new Subject<number>();
  /** Стрим значения */
  public get value$(): Observable<number> {
    return this._value$.pipe(
      finalize(() => this.onDestroy())
    );
  }

  constructor(public readonly dayDeviationName: string,
              public readonly initValue: number,
              public readonly maxValue: number,
              private readonly onDestroyCallback: () => void) {
  }

  /** Обработка нажатия на Ок */
  public onClick(value: number){
    this._value$.next(value);
    this.onDestroy();
  }

  private _isDestroyed = false;
  /** Разрушить */
  public onDestroy(){
    if(this._isDestroyed){
      return;
    }

    this._isDestroyed = true;
    this._value$.complete();
    this.onDestroyCallback();
  }
}

/** Модель для окна значения обеда */
class FlexDinnerValueVM {
  private _value$: Subject<number> = new Subject<number>();
  /** Стрим значения */
  public get value$(): Observable<number> {
    return this._value$.pipe(
      finalize(() => this.onDestroy()),
    );
  }

  /** Конструктор */
  constructor(public readonly initValue: number,
              public readonly maxValue: number,
              private readonly onDestroyCallback: () => void) {
  }

  /** Обработка нажатия на Ок */
  public onClick(value: number){
    this._value$.next(value);
    this.onDestroy();
  }

  private _isDestroyed = false;
  /** Разрушить */
  public onDestroy(){
    if(this._isDestroyed){
      return;
    }

    this._isDestroyed = true;
    this._value$.complete();
    this.onDestroyCallback();
  }
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrl: './graph.component.css',
  providers: [
    RedactionGridDialogService,
    HierarchiStringService,
    TimeIntervalPanel2ComponentDataSourceService,
    DayDeviationPanel2ComponentDataSourceService,
    GraphRowContextService,
    GraphRowEdit2Service,
    StaffUnitTypeDataSourceService2
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('GraphComponent')
export class GraphComponent implements IGraphComponent, OnInit, OnDestroy {
  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Пути */
  private readonly xPatch = xnameofPath(GraphComponent);

  /** @inheritDoc */
  public readonly redactionGridDataSourceService: RedactionGridDataSource1Service = new RedactionGridDataSource1Service('graph');
  /** @inheritDoc */
  public readonly redactionGridSelection = new RedactionGridDataSourceSelection(this.redactionGridDataSourceService.dataSource);

  private _redactionGrouperDataSourceService: RedactionGrouperDataSourceServiceGraphBase<any>;
  /** @inheritDoc */
  @Input() public get redactionGrouperDataSourceService(){
    return this._redactionGrouperDataSourceService;
  }
  public set redactionGrouperDataSourceService(value){
    if(value === this._redactionGrouperDataSourceService){
      return;
    }

    if(!!this._redactionGrouperDataSourceService){
      throw new Error(`Повторная установка ${this.xPatch.redactionGrouperDataSourceService.toString()} НЕ поддерживается`);
    }

    this._redactionGrouperDataSourceService = value;
    this.redactionGridDataSourceService.reloadData(this._redactionGrouperDataSourceService.dataSource.data2$);
  }

  /** @inheritDoc */
  @Output() public readonly editing = new EventEmitter<boolean>();


  private _graphGridComponent: GraphGrid2Component;
  /** Компонент - таблица графика */
  protected get graphGridComponent(){
    return this._graphGridComponent;
  }
  @ViewChild(GraphGrid2Component) protected set graphGridComponent(value){
    if(this._graphGridComponent === value){
      return
    }
    this._graphGridComponent = value;

    this.initRowContextService();
  }

  /** Источник данных для таблицы графика */
  protected graphGridDataSource: GraphDataSourceService = new GraphDataSourceService(this.api1GraphControlControllerService, this.dbChangedListener, this.loadingIndicatorService, this.tracerService);

  /** Модель для окна установки значения отклонения */
  protected readonly dayDeviationCustomValueVM = signal<DayDeviationCustomValueVM>(undefined);

  /** Модель для окна установки обеда */
  protected readonly flexDinnerValueVM = signal<FlexDinnerValueVM>(undefined);

  constructor(private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly redactionDialogService: RedactionGridDialogService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly notificationService: KendoNotificationService,
              private readonly alertService: AlertService,
              private readonly hierarchiStringsService: HierarchiStringService,
              private readonly commentDialogService: CommentDialogService,
              private readonly graphRowEditService: GraphRowEdit2Service,

              protected readonly timeIntervalDataSourceService: TimeIntervalPanel2ComponentDataSourceService,
              protected readonly dayDeviationPanelComponentDataSourceService: DayDeviationPanel2ComponentDataSourceService,
              protected readonly dialogService: DialogService,
              protected readonly staffUnitTypeDataSourceService: StaffUnitTypeDataSourceService2,

              protected readonly editService: GraphComponentEditServiceBase,
              @Optional() private readonly componentService: GraphComponentServiceBase,

              protected readonly rowContextService: GraphRowContextService,

              private readonly tracerService: TracerServiceBase,) {
    this.componentService?.onInit(this);

    this.redactionGridSelection.autoFollowUpToActual = true;
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {
    if(!this.redactionGrouperDataSourceService){
      throw new Error(`${this.xPatch.redactionGridDataSourceService.toString()} не передан`);
    }
    this.editService.onInit(this.redactionGridSelection);

    this.subscribeGraphGridParamChange();

    //Управление загрузкой данных для типов исполнения должностей
    this.editService.permissionDataSource.data$
      .pipe(
        filter(value => value === 'editing'),
        take(1),
        switchMap(() => {
          return this.staffUnitTypeDataSourceService.reloadData$({ids: undefined, deletedFlag: false})
            .pipe(
              exLoadingMessage(this.loadingIndicatorService, 'Получение типов исполнения должности'),
              exErrorHandler(this.displayErrorsService)
            )
        }),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe()

    //Трансляция события редактируется ли График
    this.editService.permissionDataSource.data$
      .pipe(
        filter(() => this.editService.permissionDataSource.wasEmitted), //Пока не проинициализируются данные пропускаем
        map(value => value === 'editing'),
        exDistinctUntilChanged(undefined),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe(value => this.editing.emit(value));

    //Управление данными временных интервалов
    this.editing
      .pipe(
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe(value => {
        if(!value){
          if(!!this.timeIntervalDataSourceService.paramsDataSource.data){
            this.timeIntervalDataSourceService.reloadData(undefined);
          }

          return;
        }

        if(!this.timeIntervalDataSourceService.paramsDataSource.data){
          this.timeIntervalDataSourceService.reloadData(
            {subdivisionId: this.redactionGrouperDataSourceService.dataSource.data.graphTable.subdivisionId},
            (err) => this.displayErrorsService.handleError(err),
            {service: this.loadingIndicatorService, message: 'Получение данных временных интервалов'}
          )
        }
      })

    //Управление данными отклонений
    this.editing
      .pipe(
        filter(value => value),
        take(1), //Необходима загрузить данные один первый раз
        switchMap(() => {
          return this.dayDeviationPanelComponentDataSourceService.reloadData$({})
            .pipe(
              exLoadingMessage(this.loadingIndicatorService, 'Получение данных отклонений'),
              exErrorHandler(this.displayErrorsService),
              take(1)
            )
        }),
        switchMap(() => this.graphGridComponent.dayCellSelectionChange),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        this.dayDeviationPanelComponentDataSourceService.setSelectedCells(value);
    })
  }

  /** Обработка клика по кнопки смены редакции */
  @traceFunc()
  protected onRedactionButtonClick() {
    const result = this.redactionDialogService.open();
    result.redactionGridComponent.dataSource = this.redactionGridDataSourceService.dataSource;
    result.redactionGridComponent.selection = this.redactionGridSelection;
  }

  /** Обработка начала редактирования */
  @traceFunc()
  protected onStartEdit() {
    this.editService.start$()
      .pipe(
        take(1),
        exLoadingMessage2({service: this.loadingIndicatorService, message: 'Начинаем редактирование'}),
        switchMap(() => this.graphGridDataSource.updateData$()
          .pipe(
            exLoadingMessage(this.loadingIndicatorService, 'Получение данных графика')
          )),
        exErrorHandler(this.displayErrorsService),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(() => this.notificationService.showSuccess({content: 'Редактирование начато'}));
  }

  /** Обработка окончания редактирования */
  @traceFunc()
  protected onEndEdit() {
    defer(() => {
      if(!this.graphGridDataSource.graphDayCellDataSource.getChangedCells().length){
        return of(undefined);
      }

      return this.alertService.defaultAlertOption
        .confirmation()
        .mod(x => {
          x.titleMessage = 'Имеются НЕ сохраненные изменения';
          x.message = 'При подтверждении действий произойдет их потеря!';
        })
        .showAlert$({
          cancel: {text: 'Отмена', isCancel: true},
          ok: {text: 'Подтвердить', isPrimary: true}
        }).pipe(
          map(() => undefined),
          takeUntil(this.redactionGrouperDataSourceService.dataSource.change$.pipe(takeUntil(this.streams$.unsubscribe)))
        )
    }).pipe(
      switchMap(() => {
        return this.editService.end$()
          .pipe(
            take(1),
            exLoadingMessage(this.loadingIndicatorService, 'Завершаем редактирование'),
            exErrorHandler(this.displayErrorsService),
          )
      }),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(() => this.notificationService.showSuccess({content: 'Редактирование завершено'}))
  }

  /** Обработка пере начала редактирования. */
  @traceFunc()
  protected onReEdit(){
    this.alertService.defaultAlertOption
      .confirmation()
      .mod(x => {
        x.titleMessage = 'Возможно редактирование происходит на другой вкладке?'
        x.message = 'Если в ней имеются измененные данные произойдет их потеря!'
        x.buttons[1].callBack = () => {
          this.editService.reStart$()
            .pipe(
              exLoadingMessage(this.loadingIndicatorService, 'Завершаем редактирование'),
              switchMap(() => this.graphGridDataSource.updateData$()
                .pipe(
                  exLoadingMessage(this.loadingIndicatorService, 'Получение данных графика')
                )),
              exErrorHandler(this.displayErrorsService),
              takeUntil(this.streams$.unsubscribe)
            ).subscribe(() => this.notificationService.showSuccess({content: 'Редактирование продолжено на этой вкладке'}))
        }
      })
      .showAlert(this.redactionGrouperDataSourceService.dataSource.change$.pipe(takeUntil(this.streams$.unsubscribe)));
  }

  /** Обработка на нажатие кнопки Проверить */
  @traceFunc()
  protected onCheckError(){
    this.graphGridDataSource.checkErrors$()
      .pipe(
        trace(this.tracerService),
        exLoadingMessage(this.loadingIndicatorService, 'Проверка данных'),
        exErrorHandler(this.displayErrorsService),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        this.hierarchiStringsService
          .show(
            {
              subdivisionId: this.redactionGrouperDataSourceService.dataSource.data.graphTable.subdivisionId,
              month: this._redactionGrouperDataSourceService.dataSource.data.graphTable.month,
            },
            value.map(x => x.errorsDescriptor),
            'Список ошибок');
    })
  }

  /** Обработка нажатия на кнопку Отправить на согласование */
  @traceFunc()
  protected onToApproving() {
    this.graphGridDataSource.checkErrors$()
      .pipe(
        trace(this.tracerService),
        exLoadingMessage(this.loadingIndicatorService, 'Проверка данных'),
        switchMap(checkResult => {
          let result: Observable< {result: boolean}>;

          if(checkResult.every(x => x.success)){
            result = of({result: true});
          } else {
            const hierarchiStringsComponent = this.hierarchiStringsService.show(
              {
                subdivisionId: this.redactionGrouperDataSourceService.dataSource.data.graphTable.subdivisionId,
                month: this._redactionGrouperDataSourceService.dataSource.data.graphTable.month,
              },
              checkResult.map(x => x.errorsDescriptor),
              'Список ошибок',
              true);

            result = hierarchiStringsComponent.dialog.result as typeof result;
          }

          return result;
        }),
        take(1),
        filter(value => value.result), //Нужны только если нажали Продолжить или небыло ошибок
        switchMap(() => this.commentDialogService.open().component.ok$),
        switchMap(value => {
          const redaction = this.redactionGridSelection.selectedItems2.data[0];
          if(!redaction.canToApproving){
            return throwError(() => new Error('Вы не можете отправлять данную редакцию на согласование'))
          }

          return this.redactionGrouperDataSourceService.toApproving$(redaction.redaction.id, value)
            .pipe(
              trace(this.tracerService),
              exLoadingMessage(this.loadingIndicatorService, 'Отправка графика на согласование')
            );
        }),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe({
      next: () => {
        this.notificationService.showSuccess({content: 'График отправлен на согласование'});
      },
      error: err => {
        this.notificationService.showError({content: 'Не удалось отправит график на согласование'});
        this.displayErrorsService.handleError(err);
      }
    });
  }

  /** Обработка нажатия на кнопку Вернуть в работу */
  @traceFunc()
  protected onFromApproving() {
    this.alertService.defaultAlertOption.confirmation()
      .mod(opt => {
        opt.message = 'Вернуть в работу?';
        opt.buttons[1].text = 'Вернуть';
        opt.buttons[1].callBack = () => {
          of(this.redactionGridSelection.selectedItems2.data[0])
            .pipe(
              switchMap(redaction => {
                if(!redaction?.canFromApproving){
                  return throwError(() => new Error('Вы не можете вернуть график в работу'))
                }

                return this.redactionGrouperDataSourceService.fromApproving$(redaction.redaction.id)
                  .pipe(
                    trace(this.tracerService)
                  );
              }),
              exLoadingMessage(this.loadingIndicatorService, 'Возврат графика в работу'),
              takeUntil(this.streams$.unsubscribe)
            ).subscribe({
            next: () => {
              this.notificationService.showSuccess({content: 'Возвращен в работу'});
            },
            error: err => {
              this.notificationService.showError({content: 'При возврате в работу произошла ошибка'});
              this.displayErrorsService.handleError(err);
            }
          });
        }
      }).showAlert(this.redactionGrouperDataSourceService.dataSource.change$.pipe(takeUntil(this.streams$.unsubscribe)));
  }

  /** Сохранить график */
  @traceFunc()
  protected onSave() {
    const dialog = this.commentDialogService.open();

    dialog.component.ok$
      .pipe(
        take(1),
        switchMap(comment => this.graphGridDataSource.save$(comment)
          .pipe(
            exLoadingMessage(this.loadingIndicatorService, 'Сохранения графика'),
            exErrorHandler(this.displayErrorsService),
          )
        ),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe({
        next: () => {
          this.notificationService.showSuccess({content: 'График сохранен'});
        },
        error: () => {
          this.notificationService.showError({content: 'При сохранении графика произошла ошибка'})
        }
      });
  }

  /** Очистить ячейки */
  @traceFunc()
  protected onClear(cells: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    this.graphGridDataSource.clearCells(cells.map(x => x.dayCell.cell), true);
  }

  /** Нажатие на кнопку отклонений */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onDayDeviationClick(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>,
                                @traceParam() dayDeviation: IDayDeviation){
    const cells = selectedDirectives?.map(x => x.dayCell.cell) ?? [];

    if(!dayDeviation){ //Если не передали, то очищаем
      this.graphGridDataSource.editCells(
        cells,
        GraphGrid_EditablePartGraphDayCell.setDayDeviation(undefined, false),
        true,
      );

      return;
    }

    defer(() => {
      if (dayDeviation.deviationWithInterval && cells.some(x => x.graphDayCurrent.timeInterval)) {
        return this.alertService.defaultAlertOption.question()
          .mod(x => {
            x.message = `<strong>${dayDeviation.shortName}</strong> и работа проходят в один и тот же день?`
          })
          .showAlert$({
            cancel: {text: 'Отмена', isCancel: true},
            no: {text: 'НЕТ', isPrimary: true},
            yes: {text: 'ДА', isPrimary: true},
          }).pipe(
            map(value => value === 'no'),
          )
      }

      return of(true);
    }).pipe(
      switchMap(clearTimeInterval => {
        this.dayDeviationCustomValueVM()?.onDestroy();

        if(!dayDeviation.hasCustomValue){
          return of({
            clearTimeInterval: clearTimeInterval,
            dayDeviationCustomValue: undefined as number,
          });
        }

        this.dayDeviationCustomValueVM.set(
          new DayDeviationCustomValueVM(
            dayDeviation.shortName,
            0,
            dayDeviation.isIncludeInCalculate
              ? (() => {
                const workdayHourDurations = new ArrayExpanded(selectedDirectives)
                  .map(x => x.row.workMode.workdayHourDuration)
                  .distinct()
                  .array;

                return !workdayHourDurations.length ? 0 : Math.min(...workdayHourDurations);
              })()
              : (() => {
                if(clearTimeInterval){
                  return 0;
                }

                const timeIntervalDurations = new ArrayExpanded(cells)
                  .filter(x => !!x.graphDayCurrent.timeInterval)
                  .map(x => TimeInterval.getDuration(x.graphDayCurrent.timeInterval.startInterval, x.graphDayCurrent.timeInterval.endInterval))
                  .distinct()
                  .array;

                return !timeIntervalDurations.length ? 24 : Math.min(...timeIntervalDurations);
              })(),
            () => {
              this.dayDeviationCustomValueVM.set(undefined);
            }
          )
        );

        return this.dayDeviationCustomValueVM().value$.pipe(
          map(dayDeviationCustomValue => {
            return {
              clearTimeInterval: clearTimeInterval,
              dayDeviationCustomValue: dayDeviationCustomValue,
            }
          })
        )
      }),
      takeUntil(
        this.graphGridDataSource.graphDayCellDataSource.changedCellsSelection.dataSource.change$
          .pipe(
            tap(() => this.notificationService.showInfo({content: 'Данные в выделенных ячейках изменены'}))
          )
      ),
      takeUntil(this.streams$.unsubscribe),
    ).subscribe(value => {
      this.graphGridDataSource.editCells(
        cells,
        GraphGrid_EditablePartGraphDayCell.setDayDeviation(dayDeviation, value.clearTimeInterval, value.dayDeviationCustomValue),
        true
      )

      if (dayDeviation.id === DayDeviationEnum.ИО) {
        this.notificationService.showWarning({ content: 'Вакантная ставка на период ИО появится только после сохранения графика', hideAfter: 5000 })
      }
    })
  }

  /** Нажатие на кнопку временных интервалов */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onTimeIntervalClick(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>,
                                @traceParam() timeInterval: ITimeInterval){
    const cells = selectedDirectives?.map(x => x.dayCell.cell) ?? [];

    if(!timeInterval){
      this.graphGridDataSource.editCells(
        cells,
        GraphGrid_EditablePartGraphDayCell.setTimeInterval(undefined, false),
        true,
      );

      return;
    }

    defer(() => {
      const dayDeviations = new ArrayExpanded(cells)
        .filter(x => x.graphDayCurrent.dayDeviation?.deviationWithInterval)
        .map(x => x.graphDayCurrent.dayDeviation.shortName)
        .distinct()
        .array;

      if(!dayDeviations.length){
        return of(true);
      }

      return this.alertService.defaultAlertOption.question()
        .mod(x => {
          x.message = `<strong>${dayDeviations.join(',')}</strong> и работа проходят в один и тот же день?`
        })
        .showAlert$({
          cancel: {text: 'Отмена', isCancel: true},
          no: {text: 'НЕТ', isPrimary: true},
          yes: {text: 'ДА', isPrimary: true},
        }).pipe(
          map(value => value === 'no'),
        )
    }).pipe(
      takeUntil(
        this.graphGridDataSource.graphDayCellDataSource.changedCellsSelection.dataSource.change$
          .pipe(
            tap(() => this.notificationService.showInfo({content: 'Данные в выделенных ячейках изменены'}))
          )
      ),
      takeUntil(this.streams$.unsubscribe),
    ).subscribe(clearDayDeviation => {
      this.graphGridDataSource.editCells(
        cells,
        GraphGrid_EditablePartGraphDayCell.setTimeInterval(timeInterval, clearDayDeviation),
        true,
      );
    })
  }

  /** Обработка нажатия на кнопку Исключить молоко */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onExcludeMilk(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    const params = this.getParamsForIncludeOrExcludeMilk(selectedDirectives);

    if(!params.length){
      return;
    }

    this.graphGridDataSource.addExcludeMilkLog$(params)
      .pipe(
        exLoadingMessage(this.loadingIndicatorService, 'Исключение компенсации за молоко'),
        exErrorHandler(this.displayErrorsService),
        takeUntil(this.streams$.unsubscribe),
      ).subscribe();
  }



  /** Обработка нажатия на кнопку Включить молоко */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onIncludeMilk(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    const params = this.getParamsForIncludeOrExcludeMilk(selectedDirectives);

    if(!params.length){
      return;
    }

    this.graphGridDataSource.removeExcludeMilkLog$(params)
      .pipe(
        exLoadingMessage(this.loadingIndicatorService, 'Влкючение компенсации за молоко'),
        exErrorHandler(this.displayErrorsService),
        takeUntil(this.streams$.unsubscribe),
      ).subscribe();
  }


  /** Обработка нажатия на кнопку Вычитать обед */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onExcludeLunch(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    this.graphGridDataSource.editCells(
      selectedDirectives.map(x => x.dayCell.cell),
      GraphGrid_EditablePartGraphDayCell.setSubstractLunchFlag(true),
      true
    )
  }

  /** Обработка нажатия на кнопку Учитывать обед */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onIncludeLunch(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    this.graphGridDataSource.editCells(
      selectedDirectives.map(x => x.dayCell.cell),
      GraphGrid_EditablePartGraphDayCell.setSubstractLunchFlag(false),
      true
    )
  }

  /** Обработка нажатия на Плавающий обед */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onCustomLunch(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    this.flexDinnerValueVM()?.onDestroy();

    if(!selectedDirectives?.length){
      return;
    }

    const minTimeIntervalDuration = Math.min(
      ...selectedDirectives
        .map(x => x.dayCell.cell.graphDayCurrent.timeInterval.endInterval - x.dayCell.cell.graphDayCurrent.timeInterval.startInterval)
    );

    const initValue = (
      () => {
        let temp = Math.min(
          ...selectedDirectives
            .map(x => x.dayCell.cell.graphDayCurrent.flexDinner)
            .filter(x => !!x)
        )

        return Number.isFinite(temp) ? temp : 30;
      }
    )();

    this.flexDinnerValueVM.set(
      new FlexDinnerValueVM(
        Math.min(initValue, minTimeIntervalDuration),
        minTimeIntervalDuration,
        () => {
          this.flexDinnerValueVM.set(undefined);
        })
    );

    this.flexDinnerValueVM().value$
      .pipe(
        takeUntil(
          this.graphGridDataSource.graphDayCellDataSource.changedCellsSelection.dataSource.change$
            .pipe(
              tap(() => this.notificationService.showInfo({content: 'Данные в выделенных ячейках изменены'}))
            )
        ),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(flexDinnerValue => {
        this.graphGridDataSource.editCells(
          selectedDirectives.map(x => x.dayCell.cell),
          GraphGrid_EditablePartGraphDayCell.setFlexDinner(flexDinnerValue),
          true
        )
    })

  }

  /**
   * Добавить proxy.<br>
   * Метод подготавливает данные для вызова {@link GraphRowEdit2Service.openAddProxyDialog$}.<br>
   * @param type тип добавляемого proxy
   * @param singleRow выделенная одна строка или undefined если несколько
   * @param singleRowDaySelectedCells выделенные дни графика одной строки или пустой массив
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onAddProxy(@traceParam() type: StaffUnitTypeEnum,
                       singleRow: CellDataDirective['row'] | undefined,
                       singleRowDaySelectedCells: CellDataDirective[]){
    const redaction = this.redactionGridSelection.selectedItems2.data[0];

    this.graphRowEditService.openAddProxyDialog$(
      type as any, //Валидация будет происходить в сервисе
      redaction.redaction,
      redaction.version.fullData.graphTable,
      {
        staffUnit: singleRow.staffUnit,
        employee: singleRow.employee,
        position: singleRow.position,
        selectedGraphDays: singleRowDaySelectedCells
          .map(x => ({
            date: x.dayCell.cell.day.date,
            dayDeviation: x.dayCell.cell.graphDayCurrent.dayDeviation
          })),
        allGraphDayGetter: () => {
          return this.graphGridDataSource.graphDayCellDataSource.data
            .filter(x => x.staffUnitId === singleRow.staffUnit.id)
            .map(x => ({
              date: x.day.date,
              dayDeviation: x.graphDayCurrent.dayDeviation
            }));
        }
      },
    )
      .pipe(
        takeUntil(this.closeEditDialog2$(singleRow.staffUnit.id)), //Если изменилась строка, то закрываем диалоговое окно
        switchMap(value => value), //Открытие алерта
        tap(value => {
          const cells = this.graphGridDataSource.graphDayCellDataSource.data
            .filter(x => x.staffUnitId === singleRow.staffUnit.id)
            .filter(x => DateHelper.isExistInRange(x.day.date, value.start, value.end));

          this.graphGridDataSource.editCells(
            cells,
            GraphGrid_EditablePartGraphDayCell.setDayDeviation(value.dayDeviation, true, undefined),
            true
          )
        }),
        takeUntil(this.closeEditDialog1$()),//закрываем алерт если завершили редактирование
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe()
  }

  /**
   * Добавить на свободную ставку.<br>
   * Метод подготавливает данные для вызова {@link GraphRowEdit2Service.openAddNotProxyDialog$}.<br>
   * @param type тип добавляемого proxy
   * @param singleRow выделенная одна строка или undefined если несколько
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onAddNotProxy(@traceParam() type: StaffUnitTypeEnum,
                          singleRow: CellDataDirective['row'] | undefined){
    const redaction = this.redactionGridSelection.selectedItems2.data[0];

    this.graphRowEditService.openAddNotProxyDialog$(
      type as any, //Валидируется в сервисе
      singleRow?.employee?.id,
      redaction.redaction,
      redaction.version.fullData.graphTable,
    )
      .pipe(
        takeUntil(this.closeEditDialog1$()),
        takeUntil(this.streams$.unsubscribe),
      )
      .subscribe();
  }

  /**
   * Добавить Дежурство
   * @param singleRow выделенная одна строка или undefined если несколько
   * @param singleRowDaySelectedCells выделенные дни графика одной строки или пустой массив
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  protected onAddDuty(singleRow: CellDataDirective['row'] | undefined,
                      singleRowDaySelectedCells: CellDataDirective[]){
    const redaction = this.redactionGridSelection.selectedItems2.data[0];

    this.graphRowEditService.openAddDutyDialog$(
      redaction.redaction,
      redaction.version.fullData.graphTable,
      !singleRow ? undefined : {
        staffUnit: singleRow.staffUnit,
        employee: singleRow.employee,
        position: singleRow.position,
        selectedGraphDays: singleRowDaySelectedCells.map(x => ({date: x.dayCell.cell.day.date}))
      },
    )
      .pipe(
        takeUntil(this.closeEditDialog1$()),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe();
  }

  /**
   * Подписка на изменения параметров таблицы графика.<br>
   * {@link redactionGridSelection}
   */
  @traceFunc()
  private subscribeGraphGridParamChange(){
    // Отмена измененных ячеек при любом изменении в редакциях
    this.redactionGridSelection.paramsDataSource.beforeChange$
      .subscribe(() => {
        this.graphGridDataSource.graphDayCellDataSource.cancelChangedCells();
      })

    // Получение данных
    this.redactionGridSelection.paramsDataSource.data$
      .subscribe(value => {
        if(!value){
          this.graphGridDataSource.reloadData(undefined);
          return;
        }

        if (value.isRedactionIdChanged) {
          this.graphGridDataSource.reloadData$({
            redactionId: value.redaction.redaction.id,
            useSignalR: value.useSignalR,
          })
            .pipe(
              delay(1), //Задержка позволяет до прорисовки что б была индикация
              exLoadingMessage2({service: this.loadingIndicatorService, message: 'Получение данных графика'}),
              exErrorHandler(this.displayErrorsService),
              takeUntil(this.streams$.unsubscribe),
            )
            .subscribe(() => {
              this.notificationService.showInfo({content: 'Готов к работе'})
            })

          return;
        }

        this.graphGridDataSource.enabledSignalR(value.useSignalR);
      })
  }

  /** Получить параметры для запроса на Добавление/Удаление молока */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private getParamsForIncludeOrExcludeMilk(selectedDirectives: ObservableEmittedType<typeof this.graphGridComponent['dayCellSelectionChange']>){
    return new ArrayExpanded(selectedDirectives ?? [])
      .groupBy(
        x => x.row.staffUnit.id,
        (key, items) => ({
          staffUnitId: key,
          dates: items.map(x => x.dayCell.cell.day.date)
        })
      ).array;
  }

  /** Метод инициализирует {@link rowContextService} */
  @traceFunc()
  private initRowContextService(){
    this.rowContextService.onInit(
      this.graphGridComponent.rowContextMenu,
      this.editService.permissionDataSource.data$
        .pipe(
          map(value => value === 'editing')
        ),
      () => {
        const redaction = this.redactionGridSelection.selectedItems2.data[0].redaction;
        return {
          id: redaction.id
        }
      },
      () => {
        return this.redactionGridSelection.selectedItems2.data[0].version.fullData.graphTable;
      },
      (uid) => this.graphGridDataSource.gridDataSource.data.childParentMap.get(uid),
      (staffUnitId) => this.graphGridDataSource.graphDayCellDataSource.data
        .filter(x => x.staffUnitId === staffUnitId),
      (cells, dayDeviation) => {
        this.graphGridDataSource.editCells(
          cells,
          GraphGrid_EditablePartGraphDayCell.setDayDeviation(dayDeviation, true, undefined),
          true
        )
      },
      (staffUnitId) => {
        return this.closeEditDialog2$(staffUnitId);
      }
    );
  }

  /**
   * Возвращает стрим когда необходимо закрыть окно редактирования/добавления исполнения должности.
   * Стрим сообщает когда закончилось редактирование
   */
  @traceFunc()
  private closeEditDialog1$(){
    return this.editing
      .pipe(
        filter(value => !value),
        tap(() => this.notificationService.showInfo({content: 'Редактирование прекращено по причине выхода из режима редактирования'})),
        take(1),
        map(() => {})
      );
  }

  /**
   * Возвращает стрим когда необходимо закрыть окно редактирования/добавления исполнения должности.
   * Стрим сообщает когда изменилась строка
   */
  @traceFunc()
  private closeEditDialog2$(staffUnitId: number){
    return this.graphGridDataSource.dataSource.observe$()
      .pipe(
        filter(value => {
          return value.some(x => x.currentOrOrigin.id === staffUnitId)
        }),
        tap(() => this.notificationService.showInfo({content: 'Редактирование прекращено по причине изменения данных'})),
        take(1),
        map(() => {})
      )
  }

  /** @inheritDoc */
  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.redactionGridDataSourceService.ngOnDestroy();
    this.redactionGridSelection.onDestroy();
    this.graphGridDataSource.ngOnDestroy();
  }
}
