import {Pipe, PipeTransform} from "@angular/core";
import {CellDataDirective} from "../components/graph-grid2/graph-grid2.component";
import {WorkModeTypeEnum} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkModeType";

/** Тип результата трансформации */
type ResultType = {
  /** Доступна ли кнопка Вычитать обед */
  exclude: boolean,
  /** Доступна ли кнопка Учитывать обед */
  include: boolean,
  /** Ячейки подлежащие установки Вычитать обед === true */
  toExclude: CellDataDirective[],
  /** Ячейки подлежащие установки Вычитать обед === false */
  toInclude: CellDataDirective[],
};

/** Пайп конвертирует выделенные ячейки в настройки [disable] кнопок Учитывать/Вычитать обед */
@Pipe({
  name: 'graphLunchButton',
})
export class GraphLunchButtonPipe implements PipeTransform {
  transform(selectedCells: CellDataDirective[]): ResultType {
    if(!selectedCells?.length) {
      return undefined;
    }

    if(selectedCells.some(x => x.row.workMode.workModeTypeId !== WorkModeTypeEnum.flexibleGraph)){
      return undefined;
    }

    const toExclude = selectedCells
      .filter(x => x.dayCell.cell.graphDayCurrent.timeInterval && !x.dayCell.cell.graphDayCurrent.substractLunchFlag)

    const toInclude = selectedCells
      .filter(x => x.dayCell.cell.graphDayCurrent.timeInterval && x.dayCell.cell.graphDayCurrent.substractLunchFlag)

    return {
      exclude: !!toExclude.length,
      toExclude: toExclude,
      include: !!toInclude.length,
      toInclude: toInclude,
    }
  }
}
