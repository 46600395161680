import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { CovidRegisterComponentSettings, CovidRegisterGridComponent, ICovidRegisterGridComponentChangeEvent } from "../covid-register-grid/covid-register-grid.component";
import { DialogContentBase, DialogRef, PreventableEvent } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-covid-register-grid-dialog',
  templateUrl: './covid-register-grid-dialog.component.html',
  styleUrls: ['./covid-register-grid-dialog.component.css']
})
export class CovidRegisterGridDialogComponent extends DialogContentBase {
  /** Настройки данного компонента */
  @Input() public settings: CovidRegisterComponentSettings;

  /** Ссылка на grid компонент */
  @ViewChild('grid') public grid: CovidRegisterGridComponent

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  /** Обработка закрытия окна */
  @HostListener('document:keydown.escape', ['$event']) // Захват нажатий на Esc
  public onClose(e: PreventableEvent) {
    e.preventDefault() // Предотвратить дальнейшее распространение стандартного события - например нажатие на крестик 

    if (this.grid.formGroup) return // Если процесс добавления/редактирования не завершен - отменить закрытие диалога

    this.dialog.close(
      {
        changed: this.grid.gridHasChange,
        busyTime: this.grid.gridData.reduce((previousValue, currentValue) =>
          previousValue + currentValue.covidLog.operatingTime, 0)
      } as ICovidRegisterGridComponentChangeEvent
    ) // Закрыть диалог и отправить евент изменений
  }
}
