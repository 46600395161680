<div class="dd"
     [title]="disable() ? 'Отклонения в данный момент не активны' : ''"
>
  <ng-container *ngFor="let dd of dataSourceService().dataSource.data$ | async">
    <button kendoButton
            [size]="'none'"
            [disabled]="disable() || dd.disabled"
            [title]="dd.dayDeviation.name"
            [style.background-color]="dd.dayDeviation.color"
            (click)="onClick(dd.dayDeviation)"
            class="dd-item"
    >
      {{ dd.dayDeviation.shortName }}
    </button>
  </ng-container>
</div>
