import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CovidLog } from "../../../classes/domain/POCOs/timesheet/CovidLog";
import { IDropDownItem } from "../../../classes/requestResults/iDropDownItem";
import * as moment from "moment";
import { ExtensionObj } from "../../../helpers/extensionObj";
import { process } from "@progress/kendo-data-query";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { combineLatest, ReplaySubject, Subject } from "rxjs";
import { LoadingIndicatorService } from "../../../services/loading-indicator.service";
import { AlertService } from "../../../services/alert.service";
import { Api1CovidControllerService } from "../../../services/webApi/webApi1/controllers/api1-covid-controller.service";
import { take, takeUntil } from "rxjs/operators";
import { AddEvent, CancelEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from "@progress/kendo-angular-grid";
import { DateHelper } from "../../../helpers/dateHelper";

@Component({
  selector: 'app-covid-register-grid',
  templateUrl: './covid-register-grid.component.html',
  styleUrls: ['./covid-register-grid.component.css']
})
export class CovidRegisterGridComponent implements OnInit, OnDestroy {
  /** Настройки данного компонента */
  @Input() settings: CovidRegisterComponentSettings = null;
  /** Событие закрытие окна. Передается количество минут в ковид*/
  @Output() public change$: EventEmitter<ICovidRegisterGridComponentChangeEvent> = new EventEmitter<ICovidRegisterGridComponentChangeEvent>();
  /** Событие редактирования или добавления записи */
  @Output() public isEditOrAdd$: EventEmitter<boolean> = new EventEmitter<boolean>();

  _gridData: Array<CovidRegisterComponentGridDataItem> = [];
  /** Источник данных для таблицы */
  public get gridData(): Array<CovidRegisterComponentGridDataItem> {
    return this._gridData;
  }
  public set gridData(value) {
    this._gridData = process(value, {
      sort: [
        { field: 'settingItem.timeInterval.start', dir: 'asc' },
        { field: 'covidLog.id', dir: "asc" }
      ]
    }).data;
    const newSourceForGrid = [...this.gridData];
    for (let i = 0; i < 3; i++) { //Добавляем в низу пустые строки
      newSourceForGrid.push((() => {
        const covidLog = CovidLog.GetEmpty(null, this.settings.date);

        return new CovidRegisterComponentGridDataItem(covidLog,
          this.modeSource.find(x => x.id == covidLog.mode), null, null)
      })()
      )
    }
    this.gridData$.next(newSourceForGrid);
    //Определяем сколько всего занято времени на covid
    this.recalculateBusyTime();
  }

  private _isReadOnly: boolean = true;
  /** Таблица находится ли в режиме только чтения */
  public get isReadOnly() {
    return this._isReadOnly;
  }

  /** Информация всего времени/занято ковид */
  public info: {
    available: number,
    busy: number
  } = null;

  /** Стрим для таблицы */
  public gridData$: Subject<Array<CovidRegisterComponentGridDataItem>> = new Subject<Array<CovidRegisterComponentGridDataItem>>();

  /** Флаг определяет добавлялись/радектировались/удалялись ли строки случаев работы с covid */
  public gridHasChange: boolean = false;

  /** Источник данных выбора штатной единицы */
  public staffUnitDropDownSource: Array<IDropDownItem> = null;
  /** Элемент по умолчанию для выбора штатной единицы */
  private staffUnitDropDownDefaultItem: IDropDownItem = { id: null, text: 'Выбрать...' }
  /** Стрим элемента по умолчанию в выборе штатной единицы */
  public staffUnitDropDownDefaultItem$: ReplaySubject<IDropDownItem> = new ReplaySubject<IDropDownItem>(1);

  private _formGroup: UntypedFormGroup;
  /** Форма для редактирования/добавления строк таблицы */
  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }
  public set formGroup(value) {
    this._formGroup = value;
    this.isEditOrAdd$.emit(!!value);
  }

  /** Источник данных для режима работы */
  public modeSource: Array<IDropDownItem> = [
    { id: 1, text: 'Работа с контактными пациентами' },
    { id: 2, text: 'Работа с пациентами, у которых подтвержден диагноз COVID-19' }
  ];

  /** Источник данных для манипуляций */
  public manipulationSource: Array<IDropDownItem>;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  constructor(private loadingIndicatorService: LoadingIndicatorService,
    private alertService: AlertService,
    private api1CovidControllerService: Api1CovidControllerService) {

  }

  ngOnInit() {
    if (!this.settings) {
      throw new Error('settings не передан в компонент')
    }
    this.settings.throwIsNotValid();

    this.info = {
      available: this.settings.items.map(x => x.timeInterval.duration)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0),
      busy: 0
    }
    // Заполнение источника выпадающего списка временных интервалов
    this.staffUnitDropDownSource = process(this.settings.items.map(x => {
      return {
        id: x.staffUnitOwnerId,
        text: x.timeInterval.asString
      }
    }), { sort: [{ field: 'text', dir: 'asc' }] }).data
    //---------------

    combineLatest(
      [this.loadingIndicatorService.addToObservable(
        'Загрузка списка манипуляции',
        this.api1CovidControllerService.manipulations_cached$
      ),
      this.loadingIndicatorService.addToObservable(
        'Загрузка списка случаев работы с covid',
        this.api1CovidControllerService.getCovidLogs$(this.settings.items.map(x => x.staffUnitOwnerId), this.settings.date)
      ),
      this.loadingIndicatorService.addToObservable(
        'Загрузка общей информации',
        this.api1CovidControllerService.canAddEditRemove$(this.settings.date)
      )]
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.manipulationSource = value[0];
        this.gridData = value[1].map(item => (() => {
          const row = new CovidRegisterComponentGridDataItem(
            item,
            this.modeSource.find(mode => mode.id == item.mode),
            !item.covidManipulationId ?
              null :
              value[0].find(manipulation => manipulation.id == item.covidManipulationId),
            null
          )
          this.fillSettingItem(row);

          return row;
        })())
        this._isReadOnly = !value[2];
      }, error: error => {
        this.alertService.defaultAlertOption.error()
          .mod(x => x.message = 'При загрузке данных произошла непредвиденная ошибка')
          .showAlert();
      }
    })
  }

  /** Добавить случай работы с ковид */
  addCovidCase($event: AddEvent) {
    if (!this.checkCanAddRow(null)) {
      return;
    }
    this.createFormGroup(0, '', '', this.modeSource[0], null, 0, null, 1)
    $event.sender.addRow(this.formGroup);
  }

  /** Инициализировать источник данных и элемент по умолчанию для выбора временного интервала */
  private initTimeIntervalDropDown(value: IDropDownItem): IDropDownItem {
    if (!value && this.staffUnitDropDownSource.length > 1) {
      this.staffUnitDropDownDefaultItem$.next(this.staffUnitDropDownDefaultItem)
    } else {
      this.staffUnitDropDownDefaultItem$.next(undefined);
    }

    if (!value) {
      return this.staffUnitDropDownSource.length > 1 ? this.staffUnitDropDownDefaultItem : this.staffUnitDropDownSource[0];
    } else {
      return value;
    }
  }

  /** Создает форму для редактирования/добавления строки таблицы */
  private createFormGroup(id: number,
    diseaseNumber: string,
    patientFio: string,
    mode: IDropDownItem,
    covidManipulation: IDropDownItem,
    researchCount: number,
    staffUnit: IDropDownItem,
    length: number) {
    if (!mode) {
      throw new Error('Режим работы не передан')
    }

    staffUnit = this.initTimeIntervalDropDown(staffUnit);

    const controls = {
      id: new UntypedFormControl(id),
      diseaseNumber: new UntypedFormControl(diseaseNumber),
      patientFio: new UntypedFormControl(patientFio),
      mode: new UntypedFormControl(mode),
      manipulation: new UntypedFormControl(covidManipulation),
      researchCount: new UntypedFormControl(researchCount),
      staffUnit: new UntypedFormControl(staffUnit),
      length: new UntypedFormControl(length),
      lengthFree: new UntypedFormControl(0)
    }

    //Валидатор обязательно заполненых контролов
    const globalValidator = (control: AbstractControl): ValidationErrors | null => {
      const requireTotal = [!!controls.patientFio.value, !!controls.manipulation.value, !!controls.researchCount.value]
        .filter(x => x).length;
      if (requireTotal == 0) {
        if (controls.diseaseNumber.value) {
          return null;
        }
        return { 'message': 'Одно из этих полей<br>должно быть заполнено' }
      }
      if (requireTotal > 1 && control.value && controls.researchCount.value) {
        return { 'message': 'Только одно из этих полей<br>может быть заполнено' }
      }
      return null;
    }

    controls.diseaseNumber.setValidators([
      control => {
        if (!control.value && controls.patientFio.value) {
          return { 'message': 'Поле должно<br>быть заполнено' };
        }
        return null;
      }
    ]);
    controls.diseaseNumber.valueChanges.subscribe(value => {
      if (value) {
        if (controls.researchCount.value) {
          controls.researchCount.setValue(0, { onlySelf: true })
        }
      }
    })


    controls.patientFio.setValidators([
      globalValidator,
      control => {
        if (!control.value && controls.diseaseNumber.value) {
          return { 'message': 'Поле должно быть заполнено' }
        }
        return null;
      }
    ]);
    controls.patientFio.valueChanges.subscribe(value => {
      if (value) {
        if (controls.researchCount.value) {
          controls.researchCount.setValue(0, { onlySelf: true })
        }
      }
    })

    controls.mode.setValidators(control => {
      if (!control.value) {
        return { 'message': 'Поле должно быть заполнено' }
      }
      return null;
    })

    controls.manipulation.setValidators(globalValidator);
    controls.manipulation.valueChanges.subscribe(value => {
      if (value) {
        if (controls.researchCount.value) {
          controls.researchCount.setValue(0, { onlySelf: true })
        }
      }
    })

    controls.researchCount.setValidators(globalValidator);
    controls.researchCount.valueChanges.subscribe((value: number) => {
      if (value) {
        if (controls.patientFio.value) {
          controls.patientFio.setValue("", { onlySelf: true })
        }
        if (controls.diseaseNumber.value) {
          controls.diseaseNumber.setValue("", { onlySelf: true })
        }

        if (controls.manipulation.value) {
          controls.manipulation.setValue(null, { onlySelf: true })
        }
      }
    })

    controls.staffUnit.setValidators(control => {
      if (!control.value || !control.value.id) {
        return { 'message': 'Выберите интервал<br>в котором работали' }
      }

      this.staffUnitDropDownDefaultItem$.next(undefined);

      return null;
    })

    controls.length.setValidators(control => {
      if (!control.value) {
        return { 'message': 'Поле должно быть заполнено' }
      }

      if (!controls.staffUnit.value || !controls.staffUnit.value.id) {
        return { 'message': 'Поле `Интервал`<br>должно быть выбрано' }
      }

      const busyAndIntervalData = this.getBusyAndIntervalData(controls.staffUnit.value.id, controls.id.value);
      const lengthFree = busyAndIntervalData.timeIntervalLength - busyAndIntervalData.totalBusy - control.value;

      controls.lengthFree.setValue(lengthFree, { onlySelf: true });
      if (lengthFree < 0) {
        return { 'message': 'Превышает рабочее время' }
      }

      return null;
    })

    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges.subscribe(value => {
      controls.diseaseNumber.updateValueAndValidity({ onlySelf: true });
      controls.patientFio.updateValueAndValidity({ onlySelf: true });
      controls.mode.updateValueAndValidity({ onlySelf: true });
      controls.manipulation.updateValueAndValidity({ onlySelf: true });
      controls.researchCount.updateValueAndValidity({ onlySelf: true });
      controls.staffUnit.updateValueAndValidity({ onlySelf: true });
      controls.length.updateValueAndValidity({ onlySelf: true });
      this.formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false }); //Валидируем форму после валидации всех контролов
    })
  }

  /** Отображать ли контрол Количество исследований */
  public get displayResearchCount(): boolean {
    if (!this.formGroup) {
      return false;
    }
    const formValue = this.formGroup.value;

    if (formValue.researchCount) { //Если указано количество исследований то в любом случае отображаем
      return true;
    }

    if (formValue.diseaseNumber || formValue.patientFio || formValue.manipulation) {
      return false;
    }

    return true;
  }

  /** Получить название заполненых колонок для tooltip НЕДОСТУПНО колонки Количество исследований */
  public get titleForUnavailable2(): string {
    if (!this.formGroup) {
      return '';
    }

    return [
      { value: this.formGroup.value.diseaseNumber, name: 'Номер ИБ' },
      { value: this.formGroup.value.patientFio, name: 'ФИО пациента' },
      { value: this.formGroup.value.manipulation, name: 'Вид манипуляции' }
    ].filter(x => x.value)
      .map(x => x.name)
      .join(", ")
  }

  /** отмена добавления/редактирования строки таблицы */
  cancelCovidCase($event: CancelEvent) {
    this.closeRow($event.sender, $event.rowIndex);
  }

  /** редактирование строки таблицы */
  editRow($event: EditEvent) {
    if (!this.checkCanAddRow($event.dataItem.covidLog.id)) {
      return;
    }
    const item: CovidRegisterComponentGridDataItem = $event.dataItem;
    this.createFormGroup(
      item.covidLog.id,
      item.covidLog.diseaseNumber,
      item.covidLog.patientFio,
      item.mode,
      item.manipulation,
      item.covidLog.researchCount,
      !item.settingItem ? null : this.staffUnitDropDownSource.find(x => x.id == item.settingItem.staffUnitOwnerId),
      item.covidLog.operatingTime
    );

    $event.sender.editRow($event.rowIndex);
  }

  /** Сохранение добавляемой/редактируемой строки */
  saveRow($event: SaveEvent) {
    if (!this.formGroup.valid) {
      throw new Error('Введенные данные не валидны')
    }

    if ($event.isNew) { //Если новая строка
      this.saveNewRow(
        $event.dataItem.staffUnit.id,
        $event.dataItem.diseaseNumber,
        $event.dataItem.patientFio,
        $event.dataItem.mode,
        $event.dataItem.manipulation,
        $event.dataItem.researchCount,
        $event.dataItem.length,
        $event.sender,
        $event.rowIndex
      );
      return;
    }

    const newValues = this.formGroup.value;

    if (!newValues.id) { //Если новая строка через редактирование пустой
      this.saveNewRow(
        newValues.staffUnit.id,
        newValues.diseaseNumber,
        newValues.patientFio,
        newValues.mode,
        newValues.manipulation,
        newValues.researchCount,
        newValues.length,
        $event.sender,
        $event.rowIndex
      );
      return;
    }

    //Если редактирование
    this.loadingIndicatorService.addToObservable(
      'Сохранение изменений случая работы с covid',
      this.api1CovidControllerService.editCovidLog$({
        id: newValues.id,
        staffUnitId: newValues.staffUnit.id,
        diseaseNumber: newValues.diseaseNumber,
        patientFio: newValues.patientFio,
        mode: newValues.mode.id,
        covidManipulationId: newValues.manipulation ? newValues.manipulation.id : null,
        researchCount: newValues.researchCount,
        operatingTime: newValues.length
      })).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
        next: value => {
          const item: CovidRegisterComponentGridDataItem = $event.dataItem;
          item.covidLog = value;
          item.manipulation = newValues.manipulation;
          item.mode = newValues.mode;
          item.settingItem = this.settings.items.find(x => x.staffUnitOwnerId == value.staffUnitId);

          this.gridHasChange = true;
          this.closeRow($event.sender, $event.rowIndex);
          this.recalculateBusyTime();
          this.emitChangeEvent();
        }, error: error => {
          this.alertService.defaultAlertOption.warning().mod(x => {
            x.message = 'При сохранении изменений произошла ошибка!<br>Рекомендуем перезагрузить страницу'
          }).showAlert();
        }
      })
  }

  /** Сохранить новый случай работы с ковид */
  private saveNewRow(
    staffUnitOwnerId: number,
    diseaseNumber: string,
    patientFio: string,
    mode: IDropDownItem,
    manipulation: IDropDownItem,
    researchCount: number,
    length: number,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.loadingIndicatorService.addToObservable(
      'Добавление случая работы с covid',
      this.api1CovidControllerService.addCovidLog$({
        staffUnitId: staffUnitOwnerId,
        date: this.settings.date,
        diseaseNumber: diseaseNumber,
        patientFio: patientFio,
        mode: mode.id,
        covidManipulationId: manipulation ? manipulation.id : null,
        researchCount: researchCount,
        operatingTime: length
      })
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.gridData.push((() => {
          const row = new CovidRegisterComponentGridDataItem(
            value, mode, manipulation, null
          );
          this.fillSettingItem(row);

          return row;
        })())

        this.gridData = this.gridData; //Сортируем

        this.gridHasChange = true;
        this.closeRow(sender, rowIndex);
        this.emitChangeEvent();
      }, error: error => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении новой записи произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  /** Удалить строку из таблицы */
  removeRow($event: RemoveEvent) {
    this.alertService.defaultAlertOption.question().mod(x => {
      x.title = 'Подтверждение';
      x.message = 'Удалить случай работы с Covid?';
      x.buttons[1].text = 'Удалить'
      x.buttons[1].callBack = () => {
        this.loadingIndicatorService.addToObservable(
          'Удаление записи о случае работы с covid',
          this.api1CovidControllerService.removeCovidLog$($event.dataItem.covidLog.id)
        ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
          next: value => {
            this.gridHasChange = true;
            this.gridData = this.gridData.filter(x => x.covidLog.id != $event.dataItem.covidLog.id);
            this.emitChangeEvent();
          }, error: error => {
            this.alertService.defaultAlertOption.warning().mod(x => {
              x.message = 'При удалении записи о случае работы с covid произошла ошибка!<br>Рекомендуем перезагрузить страницу'
            }).showAlert();
          }
        })
      };
    }).showAlert();
  }

  /** Закрыть редактируемую строку */
  private closeRow(grid: GridComponent, rowIndex: number) {
    grid.closeRow(rowIndex);
    this.formGroup = null;
  }

  /** Заполнить деталии у строки таблицы */
  private fillSettingItem(target: CovidRegisterComponentGridDataItem): void {
    target.settingItem = this.settings.items.find(r => r.staffUnitOwnerId == target.covidLog.staffUnitId);
  }

  /** Сообщить что в таблице что то изменилось **/
  private emitChangeEvent() {
    this.change$.emit({
      changed: this.gridHasChange,
      busyTime: this.gridData.reduce((previousValue, currentValue) =>
        previousValue + currentValue.covidLog.operatingTime, 0)
    })
  }

  /** Функция определяет необходимость отображения деталии строки */
  detailShowIf(item: CovidRegisterComponentGridDataItem): boolean {
    if (!item.covidLog || !item.covidLog.id) {
      return false;
    }
    return true;
  }

  /** Перерасчет всего времени с ковид */
  private recalculateBusyTime() {
    this.info.busy = DateHelper.minutesToHours(
      this._gridData.reduce((previousValue, x) =>
        previousValue + x.covidLog.operatingTime, 0),
      2
    );
  }

  /** Получить данные о занятом времени и длительности временного интервала **/
  private getBusyAndIntervalData(staffUnitOwnerId: number, notIncludeCovidLogId: number): { totalBusy: number, timeIntervalLength: number } {
    const timeInterval = this.settings.items.find(x => x.staffUnitOwnerId == staffUnitOwnerId).timeInterval;

    return {
      totalBusy: this.gridData
        .filter(x => x.settingItem.staffUnitOwnerId == staffUnitOwnerId)
        .filter(x => x.covidLog.id != notIncludeCovidLogId)
        .reduce((previousValue, currentValue) => previousValue + currentValue.covidLog.operatingTime, 0),
      timeIntervalLength: timeInterval.duration * 60
    }
  }

  /** Проверка возможности добавления нового случая работы с ковид */
  private checkCanAddRow(notIncludeCovidLogId: number): boolean {
    const can = this.gridData.length == 0 ?
      true :
      this.gridData
        .map(x => this.getBusyAndIntervalData(x.settingItem.staffUnitOwnerId, notIncludeCovidLogId))
        .some(x => x.timeIntervalLength - x.totalBusy > 0)

    if (!can) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'Вы не можете добавить новую запись<br>Все рабочее время уже занято'
      }).showAlert();
    }

    return can;
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Модель строки таблицы */
class CovidRegisterComponentGridDataItem {
  constructor(public covidLog: CovidLog,
    public mode: IDropDownItem,
    public manipulation: IDropDownItem,
    public settingItem: CovidRegisterComponentSettings_Item,) {
  }
}

/** Настройки(данные) передаваемые в компонент CovidRegisterComponent */
export class CovidRegisterComponentSettings {
  constructor(public date: Date,
    public items: Array<CovidRegisterComponentSettings_Item>,
    public showDetails: boolean,) {
  }

  /** Валидировать */
  public throwIsNotValid() {
    if (!this.date) {
      throw new Error('date is null')
    }

    if (!this.items && !this.items.some(x => x)) {
      throw new Error('items is null or empty')
    }

    this.items.forEach((item, index) => {
      if (!item.isValid()) {
        throw new Error(`item by index ${index} not valid`)
      }
      if (!item.timeInterval.isValid()) {
        throw new Error(`Time interval for item by index ${index} is not valid`)
      }

      if (!item.timeInterval.isValidToDate(this.date)) {
        throw new Error(`Дата временного интервала item by index ${index}`)
      }
    })
  }
}

/** Класс строки таблицы */
export class CovidRegisterComponentSettings_Item {
  constructor(public staffUnitOwnerId: number,
    public details: {
      imageUrl: string,
      subdivision: string,
      occupation: string,
      rate: number,
    },
    public timeInterval: CovidRegisterComponentSettings_TimeInterval) {
  }

  /** Валидация */
  public isValid() {
    return this.staffUnitOwnerId && this.timeInterval;
  }
}

/** Временной интервал */
export class CovidRegisterComponentSettings_TimeInterval {
  constructor(public start: Date,
    public end: Date,
    public duration: number) {
  }

  /** Получает интервал как строку */
  public get asString(): string {
    return moment(this.start).format('HH:mm') + '-' + moment(this.end).format('HH:mm')
  }

  /** Проверка что данные валидны */
  public isValid(): boolean {
    return !!this.start && !!this.end && +this.start < +this.end;
  }

  /** Проверяет содержание даты в start и end переменных */
  public isValidToDate(date: Date): boolean {
    const momentDate = moment(date).startOf('day');
    const startMoment = moment(this.start).startOf('day');
    const endMoment = moment(this.end).startOf('day');

    return +momentDate == +startMoment &&
      (+momentDate == +endMoment || +moment(date).add(1, 'day') == +endMoment);
  }

  /** Создать экземпляр */
  public static Create(start: Date, end: Date): CovidRegisterComponentSettings_TimeInterval {
    return new CovidRegisterComponentSettings_TimeInterval(
      start, end, moment(end).diff(start, 'minute')
    )
  }
}

/** Интерфейс события закрытия окна случаев работы с ковид */
export interface ICovidRegisterGridComponentChangeEvent {
  /** Флаг если строки добавлялись/модифицировались/удалялись */
  changed: boolean;
  /** Общее время работы с covid */
  busyTime: number;
}
