import {AfterViewInit, Directive, Input} from "@angular/core";
import {ContextMenuComponent} from "@progress/kendo-angular-menu";

/** Директива расширяет возможности {@link ContextMenuComponent} */
@Directive({
  selector: '[KendoContextMenuExpanded]'
})
export class KendoContextMenuExpandedDirective implements AfterViewInit{

  /** При создании компонента сразу откроется */
  @Input() public opened: Parameters<ContextMenuComponent['show']>[0];

  /** Конструктор */
  constructor(private readonly contextMenu: ContextMenuComponent) {
  }

  /** @inheritDoc */
  public ngAfterViewInit(): void {
    if(this.opened){
      this.contextMenu.show(this.opened);
    }
  }
}
