<div appSelectedGridCellTarget
     class="container"
> <!--Див для директивы, НЕ работает внутри kendo-grid-->
  <kendo-grid *ngIf="dataSource?.gridDataSource?.data$ | async as data"
              [kendoGridBinding]="data.rows"
              [size]="'small'"
              [sortable]="sortSettings"
              [(sort)]="sort"
              class="graph-grid"
  >

    <ng-template *ngIf="graphGridToolbarTemplateDirective"
                 kendoGridToolbarTemplate
    >
      <ng-container *ngTemplateOutlet="graphGridToolbarTemplateDirective.templateRef"></ng-container>
    </ng-template>

    <kendo-grid-column title=""
                       [width]="5"
                       class="no-padding unselectable"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          @if (dataItem.isExternal){
            <div class="staff-unit-proxy"></div>
          }
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [width]="30"
                       class="no-padding unselectable"
                       headerClass="no-padding"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <img *ngIf="dataItem.imageUrl"
               [src]="dataItem.imageUrl"
               class="staff-unit-img"
               [attr.staffUnitImg]="dataItem.id.uid"
          />
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Должность"
                       [field]="xPath.occupation.name.toString()"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       sortable="true"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell
               [rowIndex]="rowIndex"
               [columnIndex]="columnIndex"
               [id]="getCellId(dataItem, columnIndex)"
               [dataCompareFn]="compareCellData"
               [data]="getCellData('occupation', dataItem, undefined)"
               class="align-left-center padding-2px"
               style="min-height: 3em;"
          >
            <span appCutLongString
                  [lineCount]="3"
                  [title]="dataItem.occupation.name"
            >
              {{ dataItem.occupation.name }}
            </span>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="ФИО"
                       [field]="xPath.fio.toString()"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       sortable="true"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell
               [rowIndex]="rowIndex"
               [columnIndex]="columnIndex"
               [id]="getCellId(dataItem, columnIndex)"
               [dataCompareFn]="compareCellData"
               [data]="getCellData('fio', dataItem, undefined)"
               class="align-left-center padding-2px"
          >
            <span appCutLongString
                  [lineCount]="3"
                  [title]= "dataItem.fio"
            >
              {{dataItem.fio}}
            </span>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [field]="xPath.staffUnit.rate.toString()"
                       [width]="50"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       [sortable]="false"
    >
      <ng-template kendoGridHeaderTemplate>
        <ng-container *ngLet="'Ставка' as title">
          <span class="ellipsis" [title]="title">{{title}}</span>
        </ng-container>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <div appSelectedGridCell
               [rowIndex]="rowIndex"
               [columnIndex]="columnIndex"
               [id]="getCellId(dataItem, columnIndex)"
               [dataCompareFn]="compareCellData"
               [data]="getCellData('rate', dataItem, undefined)"
               class="align-center-center"
          >
            <span>{{dataItem.staffUnit.rate}}</span>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <ng-container *ngLet="(data.normFactDataSource.data$ | async)?.length as normFactLength"> <!--НЕОБХОДИМО ДЛЯ ПЕРЕРИСОВКИ ВЛОЖЕННОГО ПРИ ЛЮБОМ ИЗМЕНЕНИИ-->

      <kendo-grid-column title=""
                         [width]="42"
                         class="unselectable center no-padding"
                         headerClass="no-padding center"
      >
        <ng-template kendoGridHeaderTemplate>
          <ng-container *ngLet="'Норма' as title">
            <span class="ellipsis" [title]="title">{{title}}</span>
          </ng-container>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
          <ng-container *ngLet="asRow(dataItem) as dataItem">
            <ng-container *ngLet="data.normFactDataSource.get(dataItem.id.uid) as normFactData">
              <div appSelectedGridCell
                   [rowIndex]="rowIndex"
                   [columnIndex]="columnIndex"
                   [id]="getCellId(dataItem, columnIndex)"
                   [dataCompareFn]="compareCellData"
                   [data]="getCellData('norm', dataItem, undefined)"
                   class="align-center-center"
              >
                @if (normFactData){
                  {{normFactData.norma}}
                } @else {
                  <ng-container *ngTemplateOutlet="normFactLoading"></ng-container>
                }
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title=""
                         [width]="42"
                         class="unselectable center no-padding"
                         headerClass="no-padding center"
      >
        <ng-template kendoGridHeaderTemplate>
          <ng-container *ngLet="'Факт' as title">
            <span class="ellipsis" [title]="title">{{title}}</span>
          </ng-container>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
          <ng-container *ngLet="asRow(dataItem) as dataItem">
            <ng-container *ngLet="data.normFactDataSource.get(dataItem.id.uid) as normFactData">
              <div appSelectedGridCell
                   [rowIndex]="rowIndex"
                   [columnIndex]="columnIndex"
                   [id]="getCellId(dataItem, columnIndex)"
                   [dataCompareFn]="compareCellData"
                   [data]="getCellData('norm', dataItem, undefined)"
                   class="align-center-center"
              >
                @if (normFactData){
                  {{normFactData.fact}}
                } @else {
                  <ng-container *ngTemplateOutlet="normFactLoading"></ng-container>
                }
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title=""
                         [width]="45"
                         class="unselectable center no-padding"
                         headerClass="no-padding center"
      >
        <ng-template kendoGridHeaderTemplate>
          <ng-container *ngLet="'Дельта' as title">
            <span class="ellipsis" [title]="title">{{title}}</span>
          </ng-container>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
          <ng-container *ngLet="asRow(dataItem) as dataItem">
            <ng-container *ngLet="data.normFactDataSource.get(dataItem.id.uid) as normFactData">
              <div appSelectedGridCell
                   [rowIndex]="rowIndex"
                   [columnIndex]="columnIndex"
                   [id]="getCellId(dataItem, columnIndex)"
                   [dataCompareFn]="compareCellData"
                   [data]="getCellData('norm', dataItem, undefined)"
                   class="align-center-center"
                   [ngStyle]="{'background-color': !normFactData?.delta ? undefined : (normFactData.delta < 0 ? '#4caf5085' : '#ff000063')}"
              >
                @if (normFactData){
                  {{normFactData.delta}}
                } @else {
                  <ng-container *ngTemplateOutlet="normFactLoading"></ng-container>
                }
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </kendo-grid-column>

    </ng-container>

    <kendo-grid-column *ngFor="let day of data.days"
                       [title]="day.dayInMonth.toString()"
                       [width]="36"
                       class="unselectable no-padding"
                       headerClass="no-padding center"
                       [style]="{
                         background: day.dayTypeId == dayTypeEnum.BusinessDay ? null :
                          day.dayTypeId == dayTypeEnum.Weekend ? 'rgba(255, 255, 0, 0.15)' :
                            day.dayTypeId == dayTypeEnum.PreHoliday ? 'rgba(0, 255, 63, 0.15)' :
                             day.dayTypeId == dayTypeEnum.NonWorkingDay ? 'rgba(253, 233, 16, 0.25)' :
                             'rgba(255, 23, 0, 0.15)'
                       }"
    >
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <ng-container *ngLet="dataItem.getCellType(day.date) as cellType">

            <ng-container *ngLet="(data.cellsDataSource.change$ | async)?.length as cellsLength"> <!--НЕОБХОДИМО ДЛЯ ПЕРЕРИСОВКИ ВЛОЖЕННОГО ПРИ ЛЮБОМ ИЗМЕНЕНИИ-->

              <ng-container *ngLet="data.cellsDataSource.get(dataItem.id.staffUnitId, day.date) as cell">
                <div appSelectedGridCell
                     [rowIndex]="rowIndex"
                     [columnIndex]="columnIndex"
                     [id]="getCellId(dataItem, columnIndex)"
                     [dataCompareFn]="compareCellData"
                     [data]="getCellData('day', dataItem, {type: cellType, cell: cell})"
                >
                  <app-graph-day-cell2 [type]="cellType"
                                       [staffUnit]="dataItem.staffUnit"
                                       [graphDay]="cell?.graphDayCurrent"
                                       [timeInterval]="cell?.graphDayCurrent?.timeInterval"
                                       [dayDeviation]="cell?.graphDayCurrent?.dayDeviation"
                                       [excludeMilk]="cell?.log?.excludeMilkLog"
                                       [covidLogs]="cell?.log?.covidLogs"
                                       [covidLog2s]="cell?.log?.covidLog2s"
                                       [tuberLogs]="cell?.log?.tuberLogs"
                                       [vichLogs]="cell?.log?.vichLogs"
                                       [hasChange]="cell?.isChanged"
                  >

                  </app-graph-day-cell2>
                </div>
              </ng-container>

            </ng-container>

          </ng-container>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<ng-template #staffUnitImgPopupContentTemplate>
  <ng-container *ngIf="staffUnitImgRow$ | async as row">
    <ng-container *ngLet="(dataSource?.gridDataSource?.data$ | async)?.childParentMap?.get(row.id.uid) as parent">
      <div style="display: flex; flex-direction: column; gap: 10px; margin: 10px; align-items: center;">
        <div style="font-size: 1em; font-weight: bold; text-decoration: underline; text-align: center">{{row.staffUnitType.description}}</div>

        <div style="display: grid; width: fit-content; grid-template-columns: auto auto; grid-gap: 1px 10px;">
          @if (parent){
            <div style="text-align: end">За сотрудника:</div>
            <div>{{parent.fio}}</div>
          }

          <div style="text-align: end">Дата начала:</div>
          <div>{{row.staffUnit.startDate | kendoDate:'dd.MM.yyy'}}</div>

          <div style="text-align: end">Дата окончания:</div>
          <div>{{row.staffUnit.endDate | kendoDate:'dd.MM.yyy'}}</div>

          @if (row.staffUnit.percent; as percent){
            <div style="text-align: end">Размер выплаты:</div>
            <div>{{percent}} %</div>
          }
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #normFactLoading>
  <div class="k-icon k-i-loading k-font-icon"
       style="color: limegreen;">
  </div>
</ng-template>


