<kendo-grid [data]="freeRateGridService.gridView$ | async"
            style="max-height: 100%;"
            [selectable]="{enabled: true, mode: 'single'}"
            sortable="true"
            (selectionChange)="onSelectionChange($event)"
            [rowSelected]="isRowSelected(this)"

            [groupable]="{enabled: false, showFooter: false}"
            kendoGridExpandGroupBy
            [(expandedGroupKeys)]="expandedGroupKeys"

            KendoGridExpanded
            scrollIntoSelect="true"
>
  <kendo-grid-column title=" "
                     [width]="20"
                     hidden="true"
                     field="groupField"
  >
    <ng-template kendoGridGroupHeaderTemplate let-value="value" let-group>
      <span *ngIf="group.items[0] as item"
            style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center"
      >
        <span>{{item.occupation.name}}</span>
        <span style="font-weight: normal; font-size: 0.8em; font-style: italic; margin-left: 10px;">{{item.workMode.name}}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ставка"
                     field="rate"
                     format="0.##"
                     [width]="80"
                     sortable="true"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Начало"
                     field="start"
                     format="dd.MM.yyyy"
                     [width]="180"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Окончание"
                     field="end"
                     format="dd.MM.yyyy"
                     [width]="180"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Источник финансирования"
                     field="financingSource.shortName"
  >
  </kendo-grid-column>

</kendo-grid>
