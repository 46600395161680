<kendo-grid
  [data]="gridView$ | async"
  [groupable]="false"
  [reorderable]="false"
  [resizable]="false"
  [style.maxHeight.%]="100"

  kendoGridSelectBy="id"
  [selectedKeys]="selectedKeys"
  (selectedKeysChange)="onSelectedKeysChange($event)"

  [selectable]="{
    mode: 'single',
    enabled: true
  }"
  [sortable]="{
    allowUnsort: true,
    mode: 'single'
  }"
  [sort]="gridState.sort"
  [filter]="gridState.filter"
  [pageable]="true"
  scrollable="true"
  [skip]="gridState.skip"
  [pageSize]="gridState.take"

  (dataStateChange)="onDataStateChange($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="grid-header-content">
      <kendo-textbox class="search-input" placeholder="Поиск" [(value)]="searchValue" (afterValueChanged)="onFilter()" [clearButton]="true" ></kendo-textbox>
    </div>
  </ng-template>

  <kendo-grid-column field="code" title="Табельный номер" [width]="150" ></kendo-grid-column>
  <kendo-grid-column field="fio" title="ФИО"></kendo-grid-column>
  <kendo-grid-column field="startDate" title="Дата начала" [width]="120">
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.startDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="endDate" title="Дата окончания" [width]="140">
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.endDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="subdivisionsString" title="Подразделения"></kendo-grid-column>
  <kendo-grid-column field="occupationsString" title="Должности"></kendo-grid-column>


</kendo-grid>
