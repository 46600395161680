import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ArrayDataSourceIEntityId, DataSource } from 'src/app/classes/array-data-sources/data-source';
import { DataStateBuilder } from 'src/app/classes/data-state-builders/data-state-builder';
import { ISubdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { IPatientMovement, PatientMovement, PatientMovementCalculatedFields } from 'src/app/classes/domain/POCOs/timesheet/PatientMovement';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from 'src/app/services/data-source-services/data-source.service';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { DbChangedListener } from 'src/app/services/signal-r/listeners/db-changed-listener';
import { Api1PatientMovementControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-patient-movement-controller.service';
import { IForDate } from '../../../../../../../../src/app/classes/for-date';
import { Api1SubdivisionsTreelistControlControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service';
import { PatientMovementAdditionallyInfo } from '../../../../../../../../src/app/classes/domain/POCOs/timesheet/PatientMovementAdditionallyInfo';
import { PatientMovementDataItem } from '../patient-movements-grid.component';
import { Api1ResponsibleControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-responsible-controller.service';

/** Базовый класс сервиса данных */
export abstract class PatientMovementsGridComponentDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, PatientMovementDataItem> {
  abstract currentSubdivisionId: number;

  abstract get$(params: IForDate, ids: number[]): Observable<PatientMovement[]>

  abstract add$(entity: IPatientMovement): Observable<PatientMovement>

  abstract save$(entity: IPatientMovement): Observable<PatientMovement>

  abstract send$(id: number, timestamp: []): Observable<PatientMovement>

  abstract delete$(id: number): Observable<PatientMovement>

  abstract getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;
}

/**
 * Сервис данных.<br>
 * Используются параметры IPatientMovementsGridComponentDataSourceServiceParams<br>
 * Используется signalR - изменение данных справочника
 */
@Injectable()
@traceClass('PatientMovementsGridComponentDataSourceService')
export class PatientMovementsGridComponentDataSourceService
  extends ArrayDataSourceIEntityIdServiceWithParamsBase<IPatientMovementsGridComponentDataSourceServiceParams, PatientMovementDataItem>
  implements PatientMovementsGridComponentDataSourceServiceBase {

  readonly paramsDataSource = new DataSource<IPatientMovementsGridComponentDataSourceServiceParams>();
  readonly dataSource = new ArrayDataSourceIEntityId<PatientMovementDataItem>();
  public currentSubdivisionId: number;

  @traceFunc()
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
        patientMovements: PatientMovement,
        additionallyInfos: PatientMovementAdditionallyInfo,
      })
      .pipe(
        map(value => value.data),
        map(value => ([
          ...new DataStateBuilder(value.patientMovements, this.dataSource.data2, (s, i) => s.id === i.id).build_()
            .source.map(x => x.signalR.currentOrOrigin.id),
          ...new DataStateBuilder(value.additionallyInfos, this.dataSource.data2, (x, y) => x.patientMovementId === y.id).build_().source
            .filter(x => x.dataItem)
            .map(x => x.dataItem.id),
        ])),
        map(value => this.reloadFromSignalR$(value)),
      );
  }

  @traceFunc()
  protected _reloadData$(params: IPatientMovementsGridComponentDataSourceServiceParams): Observable<PatientMovementDataItem[]> {
    return this.loadingIndicatorService.addToObservable(
      'Обновление данных',
      this.get$(params),
    );
  }

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1PatientMovementControllerService: Api1PatientMovementControllerService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly api1ResponsibleControllerService: Api1ResponsibleControllerService,
              private readonly traceService: TracerServiceBase) {
    super();
  }

  /** Получить */
  @traceFunc()
  public get$({ startDate, endDate, includeDeleted, includeOnlySent }: IPatientMovementsGridComponentDataSourceServiceParams, ids: number[] = null): Observable<PatientMovementDataItem[]> {
    return this.api1PatientMovementControllerService.get$(startDate, endDate, ids, includeDeleted, includeOnlySent)
      .pipe(
        map(data => {
          this.currentSubdivisionId = data.currentSubdivisionId;
          return data.rows.map(row => ({ ...row.patientMovement, subdivision: row.subdivision, calculatedFields: PatientMovementCalculatedFields.get(row.patientMovement), letalisMale: row.letalisMale, letalisFamale: row.letalisFamale, military: row.military, fromNewSubjects: row.fromNewSubjects } as PatientMovementDataItem));
        }),
      );
  }

  /** Добавить */
  @traceFunc()
  public add$(entity: IPatientMovement): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.add$(entity);
  }

  /** Сохранить */
  @traceFunc()
  public save$(entity: IPatientMovement): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.save$(entity);
  }

  /** Отправить в СВОД */
  @traceFunc()
  public send$(id: number, timestamp: []): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.send$(id, timestamp);
  }

  /** Удалить */
  @traceFunc()
  public delete$(id: number): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.delete$(id);
  }

  @traceFunc()
  public getSubdivisionsForDate$({ forDate }: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]> {
    return this.api1ResponsibleControllerService.getResponsibleSubdivisions$(forDate);
  }

  @traceFunc()
  protected _reloadFromRemoteByIds$(params: IPatientMovementsGridComponentDataSourceServiceParams, targets: number[]): Observable<PatientMovementDataItem[]> {
    return this.get$(params, targets);
  }
}

/**
 * Сервис данных для СВОДа.<br>
 * Используются параметры IPatientMovementsGridComponentDataSourceServiceParams<br>
 * Используется signalR - изменение данных справочника
 */
@Injectable()
@traceClass('PatientMovementsSvodGridComponentDataSourceService')
export class PatientMovementsSvodGridComponentDataSourceService
  extends ArrayDataSourceIEntityIdServiceWithParamsBase<IPatientMovementsGridComponentDataSourceServiceParams, PatientMovementDataItem>
  implements PatientMovementsGridComponentDataSourceServiceBase {

  readonly paramsDataSource = new DataSource<IPatientMovementsGridComponentDataSourceServiceParams>();
  readonly dataSource = new ArrayDataSourceIEntityId<PatientMovementDataItem>();
  public currentSubdivisionId: number;

  @traceFunc()
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
        patientMovements: PatientMovement,
        additionallyInfos: PatientMovementAdditionallyInfo,
      })
      .pipe(
        map(value => value.data),
        map(value => ([
          ...new DataStateBuilder(value.patientMovements, this.dataSource.data2, (s, i) => s.id === i.id).build_()
            .source.map(x => x.signalR.currentOrOrigin.id),
          ...new DataStateBuilder(value.additionallyInfos, this.dataSource.data2, (x, y) => x.patientMovementId === y.id).build_().source
            .filter(x => x.dataItem)
            .map(x => x.dataItem.id),
        ])),
        map(value => this.reloadFromSignalR$(value)),
      );
  }

  @traceFunc()
  protected _reloadData$(params: IPatientMovementsGridComponentDataSourceServiceParams): Observable<PatientMovementDataItem[]> {
    return this.loadingIndicatorService.addToObservable(
      'Обновление данных',
      this.get$(params),
    );
  }

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1PatientMovementControllerService: Api1PatientMovementControllerService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly subdivisionsTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService,
              private readonly traceService: TracerServiceBase) {
    super();
  }

  /** Получить */
  @traceFunc()
  public get$({ startDate, endDate, includeDeleted, includeOnlySent }: IPatientMovementsGridComponentDataSourceServiceParams, ids: number[] = null): Observable<PatientMovementDataItem[]> {
    return this.api1PatientMovementControllerService.get$(startDate, endDate, ids, includeDeleted, includeOnlySent)
      .pipe(
        map(data => {
          this.currentSubdivisionId = data.currentSubdivisionId;
          return data.rows.map(row => ({ ...row.patientMovement, subdivision: row.subdivision, calculatedFields: PatientMovementCalculatedFields.get(row.patientMovement), letalisMale: row.letalisMale, letalisFamale: row.letalisFamale, military: row.military, fromNewSubjects: row.fromNewSubjects } as PatientMovementDataItem));
        }),
      );
  }

  /** Добавить */
  @traceFunc()
  public add$(entity: IPatientMovement): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.add$(entity);
  }

  /** Сохранить */
  @traceFunc()
  public save$(entity: IPatientMovement): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.save$(entity);
  }

  /** Отправить в СВОД */
  @traceFunc()
  public send$(id: number, timestamp: []): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.send$(id, timestamp);
  }

  /** Удалить */
  @traceFunc()
  public delete$(id: number): Observable<PatientMovement> {
    return this.api1PatientMovementControllerService.delete$(id);
  }

  @traceFunc()
  public getSubdivisionsForDate$({ forDate }: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]> {
    return this.subdivisionsTreelistControlControllerService.getForTimeSheet2$(forDate, subdivisionIds);
  }

  @traceFunc()
  protected _reloadFromRemoteByIds$(params: IPatientMovementsGridComponentDataSourceServiceParams, targets: number[]): Observable<PatientMovementDataItem[]> {
    return this.get$(params, targets);
  }
}

export interface IPatientMovementsGridComponentDataSourceServiceParams extends IForDate {
  includeDeleted: boolean;
  includeOnlySent: boolean;
}
