import { Injectable } from '@angular/core';
import { PatientMovementAdditionallyInfoRow } from '../patient-movement-additionally-infos-grid.component';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { map, Observable } from 'rxjs';
import { PatientMovementAdditionallyInfo } from '../../../../../../../../src/app/classes/domain/POCOs/timesheet/PatientMovementAdditionallyInfo';
import { AddPatientMovementAdditionallyInfoRequestDTO, Api1PatientMovementAdditionallyInfoControllerService, GetPatientMovementAdditionallyInfosRequestDTO, SavePatientMovementAdditionallyInfoRequestDTO } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-patient-movement-additionally-info-controller.service';
import { ArrayDataSourceIEntityId, DataSource } from '../../../../../../../../src/app/classes/array-data-sources/data-source';
import { IEntityId } from '../../../../../../../../src/app/classes/domain/POCOs/interfaces/IEntityId';
import { traceFunc } from '../../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { DataStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-state-builder';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { ISubdivision } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision';
import { Api1SubdivisionsTreelistControlControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service';
import { IForDate } from '../../../../../../../../src/app/classes/for-date';
import { GenderTextPipe } from '../../../../../../../../src/app/pipes/gender-text.pipe';

/** Базовый класс сервиса источника данных */
@Injectable()
export abstract class PatientMovementAdditionallyInfosGridDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, PatientMovementAdditionallyInfoRow> {

  abstract add$(entity: AddPatientMovementAdditionallyInfoRequestDTO): Observable<PatientMovementAdditionallyInfo>;

  abstract save$(entity: SavePatientMovementAdditionallyInfoRequestDTO): Observable<[]>;

  abstract delete$(id: number): Observable<PatientMovementAdditionallyInfo>;

  abstract get$(entity: GetPatientMovementAdditionallyInfosRequestDTO): Observable<PatientMovementAdditionallyInfoRow[]>;
  abstract getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;
}

@Injectable()
@traceClass('PatientMovementAdditionallyInfosGridDataSourceService')
export class PatientMovementAdditionallyInfosGridDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<GetPatientMovementAdditionallyInfosRequestDTO, PatientMovementAdditionallyInfoRow> implements PatientMovementAdditionallyInfosGridDataSourceServiceBase {

  public readonly dataSource = new ArrayDataSourceIEntityId<PatientMovementAdditionallyInfoRow>();
  public readonly paramsDataSource = new DataSource<GetPatientMovementAdditionallyInfosRequestDTO>();

  constructor(private readonly api1PatientMovementAdditionallyInfoControllerService: Api1PatientMovementAdditionallyInfoControllerService,
              private readonly dbChangedListener: DbChangedListener,

              private readonly subdivisionsTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService,
              private readonly tracerService: TracerServiceBase,
              private readonly genderTextPipe: GenderTextPipe) {
    super()
  }

  @traceFunc()
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(PatientMovementAdditionallyInfo)
      .pipe(
        map(value => value.data),
        map(value => new DataStateBuilder(value, this.dataSource.data2, (s, i) => s.id === i.id).build_()),
        map(value => value.source.map(x => x.dataItem ? x.dataItem.id : x.signalR.currentOrOrigin.id)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  @traceFunc()
  protected _reloadData$(params: GetPatientMovementAdditionallyInfosRequestDTO): Observable<PatientMovementAdditionallyInfoRow[]> {
    return this.get$(params);
  }

  @traceFunc()
  protected _reloadFromRemoteByIds$(params: GetPatientMovementAdditionallyInfosRequestDTO, targets: IEntityId["id"][]): Observable<PatientMovementAdditionallyInfoRow[]> {
    return this.get$(params);
  }

  @traceFunc()
  public add$(entity: AddPatientMovementAdditionallyInfoRequestDTO): Observable<PatientMovementAdditionallyInfo> {
    return this.api1PatientMovementAdditionallyInfoControllerService.add$(entity);
  }

  @traceFunc()
  public delete$(id: number): Observable<PatientMovementAdditionallyInfo> {
    return this.api1PatientMovementAdditionallyInfoControllerService.delete$(id);
  }

  @traceFunc()
  public get$(entity: GetPatientMovementAdditionallyInfosRequestDTO): Observable<PatientMovementAdditionallyInfoRow[]> {
    return this.api1PatientMovementAdditionallyInfoControllerService.get$(entity)
      .pipe(map(data => data.rows.map(r => new PatientMovementAdditionallyInfoRow(
        r.additionallyInfo.id,
        r.additionallyInfo.timestamp,
        r.additionallyInfo.patientMovementId,
        r.additionallyInfo.subdivisionId,
        r.subdivision.longName,
        r.additionallyInfo.typeId,
        r.additionallyInfo.number,
        r.additionallyInfo.fio,
        r.additionallyInfo.gender,
         this.genderTextPipe.transform(r.additionallyInfo.gender),
        r.additionallyInfo.age,
        r.additionallyInfo.diagnosis
      ))));
  }

  @traceFunc()
  public save$(entity: SavePatientMovementAdditionallyInfoRequestDTO): Observable<[]> {
    return this.api1PatientMovementAdditionallyInfoControllerService.save$(entity);
  }

  @traceFunc()
  public getSubdivisionsForDate$({ forDate }: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]> {
    return this.subdivisionsTreelistControlControllerService.getForTimeSheet2$(forDate, subdivisionIds);
  }
}

