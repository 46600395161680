import {ObjComparer} from "../object-comparers/object-comparer";
import {ArrayHelper} from "../../helpers/arrayHelper";

/** Интерфейс одного изменения в {@link IDumpList} */
export interface IDumpListChange {
  /** Дата изменения */
  date: Date;
  /** Идентификаторы изменений */
  changesByActionId: number[]
}

/** Класс одного изменения в {@link IDumpList} */
export class DumpListChange implements IDumpListChange {
  constructor(public readonly date: Date,
              public readonly changesByActionId: number[]) {
  }

  private static _comparer: ObjComparer<IDumpListChange>;
  /** Сравнить по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = new ObjComparer<IDumpListChange>({
        date: ObjComparer.dateComparer,
        changesByActionId: (value1, value2) => {
          return ArrayHelper.equals2(value1, value2, (x1, x2) => x1 === x2)
        }
      })
    }

    return this._comparer;
  }

}

/** Интерфейс одного изменения в {@link IDumpList} имеющий дату окончания действия */
export interface IDumpListChangeRange extends IDumpListChange{
  /** Дата окончания действия */
  endDate: Date;
}

/** Класс одного изменения в {@link IDumpList} имеющий дату окончания действия */
export class DumpListChangeRange implements IDumpListChangeRange {
  constructor(public readonly date: Date,
              public readonly changesByActionId: number[],
              public readonly endDate: Date) {
  }

  private static _comparer: ObjComparer<IDumpListChangeRange>;
  /** Сравнить по всем полям */
  public static get comparer(){
    if(!this._comparer){
      const instance = DumpListChange.comparer.add({
        endDate: ObjComparer.dateComparer,
      })

      this._comparer = instance;
    }

    return this._comparer;
  }
}
