import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IDropDownItem } from "../../../../../../../../src/app/classes/requestResults/iDropDownItem";
import { combineLatest, ReplaySubject } from "rxjs";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../../../../../../src/app/services/storages/custom-storage.service";
import { take, takeUntil } from "rxjs/operators";
import { ExtensionObj } from "../../../../../../../../src/app/helpers/extensionObj";
import * as moment from "moment";
import {
  ErrorPageSettings,
  WorkSpaceErrorComponentService
} from "../../../../../../../timesheet/src/app/services/workspace/work-space-error.component.service";
import { LoadingIndicatorService } from "../../../../../../../../src/app/services/loading-indicator.service";
import { Api1DateControllerService } from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-date-controller.service";
import {
  CovidDayPickerComponent,
  CovidDayPickerComponentSettings
} from "../covid-day-picker/covid-day-picker.component";
import { EmployeeGraphGridService } from "../../../employee-graph-grid/services/employee-graph-grid.service";
import { AuthService } from "../../../../../../../../src/app/modules/auth/services/auth.service";
import { EmployeeGraphGridViewModel } from "../../../employee-graph-grid/classes/employee-graph-grid-view-model";
import { GraphDayCellService } from "../../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/components/graph-day-cell/services/graph-day-cell.service";
import { EmployeeGraphGridCell } from "../../../employee-graph-grid/classes/employee-graph-grid-cell";
import { CommonToolbarService } from "../../../../../../../../src/app/services/common-toolbar.service";
import { traceFunc } from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { trace } from "../../../../../../../../src/app/modules/trace/operators/trace";
import { traceClass } from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";

@Component({
  selector: 'app-covid-journal-toolbar',
  templateUrl: './covid-journal-toolbar.component.html',
  styleUrls: [
    './covid-journal-toolbar.component.css',
    '../../../employee-graph-toolbar/employee-graph-toolbar.component.css'
  ]
})
@traceClass('CovidJournalToolbarComponent')
export class CovidJournalToolbarComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Output() change$: EventEmitter<CovidJournalToolbarComponentChangeEvent> = new EventEmitter<CovidJournalToolbarComponentChangeEvent>();

  @ViewChild('daySelector') public daySelector: CovidDayPickerComponent;

  public streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    years: new ReplaySubject<Array<IDropDownItem>>(1),
    months: new ReplaySubject<Array<IDropDownItem>>(1)
  }

  public values: CovidJournalToolbarValue = null;

  /** данные **/
  private gridView: EmployeeGraphGridViewModel = null;

  /** Настройка для компонента выбора дня */
  public inlineDayPickerComponentSettings: CovidDayPickerComponentSettings = null;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
    private api1DateControllerService: Api1DateControllerService,
    private customStorageService: CustomStorageService,
    private errorComponentService: WorkSpaceErrorComponentService,
    private employeeGraphGridService: EmployeeGraphGridService,
    private authService: AuthService,
    private graphDayCellService: GraphDayCellService,
    private commonToolbarService: CommonToolbarService,
    private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    combineLatest(
      [this.loadingIndicatorService.addToObservable(
        'Получение списка `Год`',
        this.api1DateControllerService.getYears$
      ),
      this.loadingIndicatorService.addToObservable(
        'Получение списка `Месяц`',
        this.api1DateControllerService.getPosibleMonth$
      )]
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.streams$.years.next(value[0]);
        this.streams$.months.next(value[1]);

        let commonToolbarData = this.commonToolbarService.dateAsObj;
        if (!commonToolbarData) {
          const year = value[0].find(x => x.id == moment().year());
          commonToolbarData = {
            year: !year ? value[0][value[0].length - 1].id : year.id,
            month: value[1].find(x => x.id == moment().month() + 1).id - 1,
            day: 1
          }
        }

        const storageObj = this.customStorageService.get<CovidJournalToolbarStorageObj>(this.dataStorageOptions);
        this.loadData(
          value[0].find(x => x.id == commonToolbarData.year),
          value[1].find(x => x.id == commonToolbarData.month + 1),
          storageObj ? storageObj.day : 1
        )
      }, error: error => {
        this.errorComponentService.redirect(ErrorPageSettings.AsExtensionObj().modResult(x => {
          x.stackTrace = error;
          x.message = 'При загрузке данных произошла ошибка';
        }))
      }
    })
  }

  /** Получить активный день */
  @traceFunc()
  private getActiveDay(year: number, month: number, day: number): number {
    const dateAsMoment = moment({ year: year, month: month - 1, day: 1 });
    const forMax = Math.max(day - 1, dateAsMoment.daysInMonth() - day);
    for (let i = 0, sub = day, add = day; i <= forMax; i++, sub = day - i, add = day + i) {
      if (sub > 0 && this.getCells(moment(dateAsMoment).set('date', sub).toDate()).some(x => x)) {
        return sub;
      }
      if (add <= dateAsMoment.daysInMonth() && this.getCells(moment(dateAsMoment).set('date', add).toDate()).some(x => x)) {
        return add;
      }
    }

    return null;
  }

  /** Получить ячейку содержащую временной интервал на дату(день) */
  @traceFunc()
  private getCells(date: Date): Array<EmployeeGraphGridCell> {
    return this.gridView.rows
      .filter(row => !this.graphDayCellService.isEmpty(row.staffUnit.startDate, row.staffUnit.endDate, date))
      .map(row => row.graphDays[date.getDate() - 1])
      .filter(cell => !!cell.timeInterval);
  }

  /** Обработка события изменения данных */
  @traceFunc()
  public onChange() {
    this.loadData(this.values.year, this.values.month, this.values.day);
  }

  /** Обработка смены дня */
  @traceFunc()
  onDayChange($event: number) {
    this.values.day = $event;
    this.onChangeValues();
  }

  /** Загрузить данные */
  @traceFunc()
  private loadData(year: IDropDownItem, month: IDropDownItem, day: number) {
    this.gridView = null;
    this.inlineDayPickerComponentSettings = null;

    this.employeeGraphGridService.getDataSource$(year.id, month.id, this.authService.user.MedStaffId)
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
        next: gridView => {
          this.gridView = gridView;

          if (day == null) {
            const currentDateAsMoment = moment();
            day = currentDateAsMoment.year() == year.id && currentDateAsMoment.month() + 1 == month.id ?
              currentDateAsMoment.date() : 1;
          }

          this.values = {
            year: year,
            month: month,
            day: this.getActiveDay(year.id, month.id, day)
          }

          const disabledDays = this.gridView.days.map((day, index) => {
            return {
              day: index + 1,
              hasTimeInterval: this.getCells(day.date).some(x => x)
            }
          }).filter(x => !x.hasTimeInterval)
            .map(x => x.day);

          const hasCovids = this.gridView.days.map((day, index) => {
            return {
              day: index + 1,
              hasCovid: this.getCells(day.date).some(x => x.covidHours)
            }
          }).filter(x => x.hasCovid)
            .map(x => x.day);

          setTimeout(() => {
            this.inlineDayPickerComponentSettings = new CovidDayPickerComponentSettings(
              this.values.year.id,
              this.values.month.id - 1,
              disabledDays,
              hasCovids,
              this.values.day
            )
          });

          this.onChangeValues();
        }, error: error => {
          this.errorComponentService.redirect(ErrorPageSettings.AsExtensionObj().modResult(x => {
            x.stackTrace = error;
            x.message = 'При загрузке данных произошла ошибка';
          }))
        }
      })
  }

  /** Сохранить в sessionStorage */
  private onChangeValues() {
    const newStorageValue = new ExtensionObj(new CovidJournalToolbarStorageObj()).modResult(x => {
      x.day = this.values.day;
    });

    this.commonToolbarService.date = moment({ year: this.values.year.id, month: this.values.month.id - 1 }).toDate();
    this.customStorageService.set(this.dataStorageOptions, newStorageValue);
    this.change$.emit(null);
    setTimeout(() => {
      this.change$.emit(new ExtensionObj(new CovidJournalToolbarComponentChangeEvent).modResult(x => {
        x.values = this.values;
        x.view = this.gridView;
      }))
    });
  }

  /** Настройки хранения данных в sessionStorage */
  private dataStorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage, 'CovidJournalToolbar', null, true, false);

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Класс значений */
export class CovidJournalToolbarValue {
  year: IDropDownItem;
  month: IDropDownItem;
  day: number;
}

/** Событие изменения значений */
export class CovidJournalToolbarComponentChangeEvent {
  /** Значения выбранные в toolbar */
  values: CovidJournalToolbarValue;
  /** вью */
  view: EmployeeGraphGridViewModel;
}

/** Объект хранимый в sessionStorage */
class CovidJournalToolbarStorageObj {
  day: number;
}
