<ng-container *ngLet="redactionGrouperDataSourceService.dataSource.data$ | async as redactionGrouper">
  @if (redactionGrouper){
    <app-graph-grid2 #graphGrid [dataSource]="graphGridDataSource">

      <ng-container *ngLet="redactionGridSelection.selectedItems2.data$ | async | arrayElementAt : 0 as selectedRedaction">
        <ng-container *ngLet="editService.permissionDataSource.data$ | async as editPermission">
          <ng-container *ngLet="graphGridDataSource.graphDayCellDataSource.changedCellsSelection.selectedItems2.data$ | async | arrayOrEmpty as changedCells">
            <ng-container *ngLet="graphGrid.dayCellSelectionChange | async as dayCellsSelected">
              <ng-container *ngLet="!!dayCellsSelected?.length as hasDayCellsSelected">
                <ng-template graph-grid-toolbar-template>
                  <div class="toolbar-container">
                    <div class="toolbar-row1">
                      <app-redaction-button [type]="'graph'"
                                            [size]="'small'"
                                            [disabled]="editPermission === 'editing'"
                                            [redaction]="!selectedRedaction ? undefined : {
                                              id: selectedRedaction.redaction.id,
                                              modifiedDate: selectedRedaction.redaction.modifiedDate,
                                              isActualRedaction: selectedRedaction.isActual,
                                              version: selectedRedaction.versionId,
                                              isActualVersion: selectedRedaction.version.isActual,
                                            }"
                                            (clickByRoot$)="onRedactionButtonClick()"
                      >

                      </app-redaction-button>

                      @if(redactionGrouper){
                        <app-graph-table-state-info [editUserId]="redactionGrouper.userEdit?.id"
                                                    [editUserFio]="redactionGrouper.userEdit?.fio"
                                                    [status]="redactionGrouper.versions[0].displayStatus?.text"
                        >

                        </app-graph-table-state-info>
                      }

                      <div class="toolbar-row1-buttons">
                        @if(!!selectedRedaction){
                          @if (selectedRedaction.isActual && changedCells.length === 0) {
                            <button kendoButton
                                    [size]="'small'"
                                    title="Проверить график"
                                    (click)="onCheckError()"
                            >
                        <span class="k-icon k-i-check k-font-icon"
                              style="color: #00a200; font-weight: bold">
                        </span>
                              Проверить
                            </button>
                          }

                          @if(editPermission !== 'editing'){
                            <button kendoButton
                                    [size]="'small'"
                                    [icon]="'print'"
                            >
                              Печать
                            </button>
                          }

                          @if(editPermission === 'canEditing'){
                            <button kendoButton
                                    [size]="'small'"
                                    [primary]="true"
                                    [icon]="'edit'"
                                    (click)="onStartEdit()"
                            >
                              Начать редактирование
                            </button>
                          } @else if(editPermission === 'canReediting') {
                            <button kendoButton
                                    [size]="'small'"
                                    [primary]="true"
                                    [icon]="'edit'"
                                    (click)="onReEdit()"
                            >
                              Продолжить редактирование
                            </button>
                          } @else if (editPermission === 'editing'){
                            <button kendoButton
                                    [size]="'small'"
                                    [primary]="false"
                                    [icon]="'cancel'"
                                    (click)="onEndEdit()"
                            >
                              Завершить редактирование
                            </button>
                          } @else {
                          }

                          @if (editPermission === 'editing'){
                            <button kendoButton
                                    [size]="'small'"
                                    [primary]="true"
                                    (click)="onSave()"
                                    [disabled]="changedCells.length === 0"
                                    [icon]="'save'"
                            >
                              Сохранить
                            </button>
                          }

                          @if(selectedRedaction.canToApproving){
                            <button kendoButton
                                    [size]="'small'"
                                    (click)="onToApproving()"
                            >
                              Отправить на согласование
                              <span class="k-icon k-font-icon k-i-arrow-right" style="margin-left: 4px;"></span>
                            </button>
                          }

                          @if(selectedRedaction.canFromApproving){
                            <button kendoButton
                                    [size]="'small'"
                                    (click)="onFromApproving()"
                            >
                              Вернуть в работу
                              <span class="k-icon k-i-arrow-left k-font-icon" style="margin-left: 4px;"></span>
                            </button>
                          }
                        }
                      </div>
                    </div>

                    @if(editPermission === 'editing'){
                      <div class="toolbar-row2">
                        <div *ngIf="(staffUnitTypeDataSourceService.dataSource.data2$ | async | graphAddButtons: dayCellsSelected: (graphGrid.cellSelectedChange | async)) as graphAddButtons"
                             class="add-staff-unit-buttons"
                        >
                          <div *ngIf="graphAddButtons.moonlighterProxyButton || graphAddButtons.moonlighterFreeButton"
                               class="add-staff-unit-buttons-group"
                          >
                            <button *ngIf="graphAddButtons.moonlighterProxyButton"
                                    kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.moonlighterProxyButton.disabled"
                                    title="Добавить совместительство за счёт временного отсутствия сотрудника"
                                    (click)="onAddProxy(graphAddButtons.moonlighterProxyButton.staffUnitType, graphAddButtons.singleRowSelected, graphAddButtons.singleRowDaySelectedCells)"
                            >
                              <img src="/assets/icons/moonlighter-combination/moonlighter_bosy_rate_add.png">
                              <span>Совместительство</span>
                            </button>

                            <button *ngIf="graphAddButtons.moonlighterFreeButton"
                                    kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.moonlighterFreeButton.disabled"
                                    title="Добавить совместительство за счет вакантной ставки"
                                    (click)="onAddNotProxy(graphAddButtons.moonlighterFreeButton.staffUnitType, graphAddButtons.singleRowSelected)"
                            >
                              <img src="/assets/icons/moonlighter-combination/moonlighter_free_rate_add.png">
                              <span>Совместительство</span>
                            </button>
                          </div>

                          <div *ngIf="graphAddButtons.combinationProxyButton || graphAddButtons.combinationFreeButton"
                               class="add-staff-unit-buttons-group"
                          >
                            <button *ngIf="graphAddButtons.combinationProxyButton"
                                    kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.combinationProxyButton.disabled"
                                    title="Добавить совмещение за счёт временного отсутствия сотрудника"
                                    (click)="onAddProxy(graphAddButtons.combinationProxyButton.staffUnitType, graphAddButtons.singleRowSelected, graphAddButtons.singleRowDaySelectedCells)"
                            >
                              <img src="/assets/icons/moonlighter-combination/combination_bosy_rate_add.png">
                              <span>Совмещение</span>
                            </button>

                            <button *ngIf="graphAddButtons.combinationFreeButton"
                                    kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.combinationFreeButton.disabled"
                                    title="Добавить совмещение за счет вакантной ставки"
                                    (click)="onAddNotProxy(graphAddButtons.combinationFreeButton.staffUnitType, graphAddButtons.singleRowSelected)"
                            >
                              <img src="/assets/icons/moonlighter-combination/combination_free_rate_add.png">
                              <span>Совмещение</span>
                            </button>
                          </div>

                          <div *ngIf="graphAddButtons.uvorProxyButton || graphAddButtons.uvorFreeButton"
                               class="add-staff-unit-buttons-group"
                          >
                            <button *ngIf="graphAddButtons.uvorProxyButton"
                                    kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.uvorProxyButton.disabled"
                                    title="Добавить увеличение объема работ за счёт временного отсутствия сотрудника"
                                    (click)="onAddProxy(graphAddButtons.uvorProxyButton.staffUnitType, graphAddButtons.singleRowSelected, graphAddButtons.singleRowDaySelectedCells)"
                            >
                              <img src="/assets/icons/moonlighter-combination/uvor_busy_rate_add.png">
                              <span>УВОР</span>
                            </button>
                            <button *ngIf="graphAddButtons.uvorFreeButton"
                                    kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.uvorFreeButton.disabled"
                                    title="Добавить увеличение объема работ за счет вакантной ставки"
                                    (click)="onAddNotProxy(graphAddButtons.uvorFreeButton.staffUnitType, graphAddButtons.singleRowSelected)"
                            >
                              <img src="/assets/icons/moonlighter-combination/uvor_free_rate_add.png">
                              <span>УВОР</span>
                            </button>
                          </div>

                          <div *ngIf="graphAddButtons.dutyButton"
                               class="add-staff-unit-buttons-group"
                          >
                            <button kendoButton
                                    [size]="'small'"
                                    [disabled]="graphAddButtons.dutyButton.disabled"
                                    title="Добавить дежурство"
                                    (click)="onAddDuty(graphAddButtons.singleRowSelected, graphAddButtons.singleRowDaySelectedCells)"
                            >
                              <img src="/assets/icons/moonlighter-combination/duty_add.png">
                              <span>Дежурство</span>
                            </button>
                          </div>
                        </div>

                        <div style="flex: 1"></div>

                        @if (dayCellsSelected | graphMilkButton; as graphMilkButton){
                          <div class="button-group">
                            <button kendoButton
                                    title="Исключить компенсацию за молоко"
                                    [size]="'small'"
                                    [disabled]="!graphMilkButton.exclude"
                                    [icon]="'minus'"
                                    (click)="onExcludeMilk(graphMilkButton.toExclude)"
                            >
                              Молоко
                            </button>
                            <button kendoButton
                                    title="Включить компенсацию за молоко"
                                    [size]="'small'"
                                    [disabled]="!graphMilkButton.include"
                                    [icon]="'plus'"
                                    (click)="onIncludeMilk(graphMilkButton.toInclude)"
                            >
                              Молоко
                            </button>
                          </div>
                        }

                        @if (dayCellsSelected | graphLunchButton; as graphLunchButton){
                          <div class="button-group">
                            <button kendoButton
                                    [size]="'small'"
                                    [disabled]="!graphLunchButton.exclude"
                                    (click)="onExcludeLunch(graphLunchButton.toExclude)"
                                    imageUrl="../../../../../icons/lunch/lunch_green.png"
                                    class="lunch-button"
                            >
                              Вычитать обед
                            </button>

                            <button kendoButton
                                    [size]="'small'"
                                    [disabled]="!graphLunchButton.include"
                                    (click)="onIncludeLunch(graphLunchButton.toInclude)"
                                    imageUrl="../../../../../icons/lunch/lunch_red.png"
                                    class="lunch-button"
                            >
                              Учитывать обед
                            </button>
                          </div>
                        }

                        @if (dayCellsSelected | graphCustomLunchButton; as graphCustomLunchButton){
                          <button kendoButton
                                  [size]="'small'"
                                  [disabled]="graphCustomLunchButton.disabled"
                                  [icon]="'gear'"
                                  (click)="onCustomLunch(graphCustomLunchButton.cellDirectives)"
                          >
                            Обед
                          </button>
                        }

                        @if (dayCellsSelected | graphClearButton; as graphClearButton){
                          <button kendoButton
                                  title="Очистить выделенные ячейки"
                                  [disabled]="graphClearButton.disable"
                                  [size]="'small'"
                                  [icon]="'apply-format'"
                                  (click)="onClear(graphClearButton.cellDirectives)"
                          >
                            Очистить
                          </button>
                        }
                      </div>

                      <app-day-deviation-panel2 [dataSourceService]="dayDeviationPanelComponentDataSourceService"
                                                [disable]="!hasDayCellsSelected"
                                                (dayDeviationClick)="onDayDeviationClick(dayCellsSelected, $event)"
                      ></app-day-deviation-panel2>

                      <app-time-interval-panel2 [dataSourceService]="timeIntervalDataSourceService"
                                                [disabled]="!hasDayCellsSelected"
                                                (timeIntervalClick)="onTimeIntervalClick(dayCellsSelected, $event)"
                      ></app-time-interval-panel2>
                    }
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-graph-grid2>
  } @else {
    Ожидаем редакции
  }
</ng-container>

<kendo-dialog *ngIf="dayDeviationCustomValueVM()"
              title = "Установка времени"
              (close) = "dayDeviationCustomValueVM().onDestroy()"
              (keydown.escape) = "dayDeviationCustomValueVM().onDestroy()">

  <div style="display: flex; flex-wrap: nowrap; gap: 20px; align-items: center; justify-content: center;">
    <div style="text-wrap: nowrap">
      Часы <strong>{{dayDeviationCustomValueVM().dayDeviationName}}</strong>:
    </div>
    <kendo-numerictextbox #dayDeviationCustomValueNumeric
                          style="width: 80px"
                          [value]="dayDeviationCustomValueVM().initValue"
                          [min]="0.0"
                          [max]="dayDeviationCustomValueVM().maxValue"
                          [autoCorrect]="true"
                          [step]="0.1"
                          format="0.##"
                          decimals="2"
    ></kendo-numerictextbox>
  </div>

  <kendo-dialog-actions layout="normal">
    <button kendoButton
            (click)="dayDeviationCustomValueVM().onDestroy()"
    >
      Отмена
    </button>
    <button kendoButton
            [disabled]="(dayDeviationCustomValueNumeric.valueChange | async) < 0.001"
            primary="primary"
            (click)="dayDeviationCustomValueVM().onClick(dayDeviationCustomValueNumeric.value)"
    >
      Сохранить
    </button>
  </kendo-dialog-actions>

</kendo-dialog>

<kendo-dialog *ngIf="flexDinnerValueVM()"
              title="Продолжительность обеда"
              (close)="flexDinnerValueVM().onDestroy()"
              (keydown.escape) = "flexDinnerValueVM().onDestroy()"
>
  <div style="display: flex; flex-wrap: nowrap; gap: 20px; align-items: center; justify-content: center;">
    <div style="text-wrap: nowrap">
      Минуты:
    </div>
    <kendo-numerictextbox #flexDinnerNumeric
                          style="width: 80px"
                          [value]="flexDinnerValueVM().initValue"
                          [min]="0"
                          [max]="flexDinnerValueVM().maxValue"
                          [autoCorrect]="true"
                          [step]="1"
                          [format]="'n0'"
                          [decimals]="0"
    ></kendo-numerictextbox>
  </div>

  <kendo-dialog-actions layout="normal">
    <button kendoButton
            (click)="flexDinnerValueVM().onDestroy()"
    >
      Отмена
    </button>
    <button kendoButton
            [primary]="true"
            (click)="flexDinnerValueVM().onClick(flexDinnerNumeric.value)"
    >
      Применить
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

@if (rowContextService.contextDataSource.data$ | async; as context){
  <kendo-contextmenu KendoContextMenuExpanded
                     [opened]="context.target"
                     [items]="context.items"
                     (select)="context.onClick($event.item)"
                     (popupClose)="context.onClose()"
  >

  </kendo-contextmenu>
}

<div kendoDialogContainer></div>
