import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityId } from '../interfaces/IEntityId';
import { IParentId } from '../interfaces/IParentId';

export interface IRedactionBase extends IEntityId, IEntityModify, IEntityComment, IParentId {
  /** Ссылка на родителя */
  parentId: number | null;
  /** Идентификатор графика/табеля, к которому относится информация о редакции и версии. FK на таблицу timesheet_graph.Graphs/timesheet_table.Tables */
  workTimeSheetId: number;
}

/** Базовый класс редакции Графика/Табеля */
export abstract class RedactionBase implements IRedactionBase {
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public comment: string,
              public parentId: number | null,
              public workTimeSheetId: number,
  ) {
  }
}
