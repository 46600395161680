import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityId } from '../interfaces/IEntityId';

export interface IEditStatusBase extends IEntityId, IEntityModify {
  /** Время последней активности пользователя по редактированию графика */
  lastActivity: Date;
  /** Идентификатор графика/табеля */
  targetFkId: number;
}

/** Базовый класс информации редактирования Графика/Табеля  */
export abstract class EditStatusBase implements IEditStatusBase {
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public lastActivity: Date,
              public targetFkId: number,
  ) {
  }
}
