import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';

export interface IRouteBase extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag {
  /** Идентификатор редакции */
  redactionId: number;
  /** Идентификатор User-а, согласовавшего график/табель FK на таблицу Approvals  */
  approvalsId: number;
  /** Статус согласования графика */
  status: number;
}

/** Информация о статусе согласования графика (от рабочей версии до утвержденной) */
export abstract class RouteBase implements IRouteBase {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public redactionId: number,
              public approvalsId: number,
              public status: number,
  ) {
  }
}
