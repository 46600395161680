import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimeInterval, TimeInterval } from 'src/app/classes/domain/POCOs/timesheet_graph/TimeInterval';
import { Api1TimeIntervalControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-timeInterval-controller.service';
import { ArrayDataSourceIEntityId, DataSource } from 'src/app/classes/array-data-sources/data-source';
import { map } from 'rxjs/operators';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../../../../src/app/services/data-source-services/data-source.service';
import { DbChangedListener } from '../../../../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { TimeIntervalSubdivision } from '../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeIntervalSubdivision';

/** Тип параметров для получения данных */
export type TimeIntervalPanelComponentDataSourceService_Params = {
  subdivisionId: number
}

/** Базовый класс сервиса данных */
@Injectable()
export abstract class TimeIntervalPanel2ComponentDataSourceServiceBase<TParam> extends ArrayDataSourceIEntityIdServiceWithParamsBase<TParam, ITimeInterval> {
  /** Удалить временные интервалы */
  abstract deleteTimeIntervalLinks$(timeIntervalIds: number[]): Observable<boolean>;
  /** Добавить временной интервал */
  abstract addTimeIntervalToSubdivision$(startInterval: number, endInterval: number): Observable<TimeInterval>;
  /** Сравнить TimeIntervalPanelItem */
  abstract timeIntervalComparer(firstTI: ITimeInterval, secondTI: ITimeInterval): number;
}

@Injectable()
export class TimeIntervalPanel2ComponentDataSourceService extends TimeIntervalPanel2ComponentDataSourceServiceBase<TimeIntervalPanelComponentDataSourceService_Params> {
  /** @inheritDoc */
  public readonly dataSource = new ArrayDataSourceIEntityId<ITimeInterval>();
  /** @inheritDoc */
  public readonly paramsDataSource = new DataSource<TimeIntervalPanelComponentDataSourceService_Params>();

  constructor(private readonly api1TimeIntervalControllerService: Api1TimeIntervalControllerService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
      timeIntervals: TimeInterval,
      timeIntervalSubdivisions: TimeIntervalSubdivision,
    }).pipe(
      map(value => value.data),
      map(value => ([
        ...value.timeIntervals
          .filter(s => s.currentOrOrigin.subdivisionId === this.paramsDataSource.data.subdivisionId)
          .map(s=> s.currentOrOrigin.id),
        ...value.timeIntervalSubdivisions
          .filter(s => s.currentOrOrigin.subdivisionId === this.paramsDataSource.data.subdivisionId)
          .map(s=> s.currentOrOrigin.timeIntervalId),
      ])),
      map(value => this.reloadFromSignalR$(value)),
    )
  }

  /** @inheritDoc */
  protected _reloadFromRemoteByIds$(params: TimeIntervalPanelComponentDataSourceService_Params, targets: number[]): Observable<ITimeInterval[]> {
    return this.api1TimeIntervalControllerService.getAllTimeIntervalsBySubdivisionId$(params.subdivisionId);
  }

  /** @inheritDoc */
  protected _reloadData$(params: TimeIntervalPanelComponentDataSourceService_Params): Observable<ITimeInterval[]> {
    return this._reloadFromRemoteByIds$(params, []);
  }

  /** @inheritDoc */
  public deleteTimeIntervalLinks$(timeIntervalIds: number[]): Observable<boolean> {
    return this.api1TimeIntervalControllerService.deleteTimeIntervalLinks$(timeIntervalIds, this.paramsDataSource.data.subdivisionId);
  }

  /** @inheritDoc */
  public addTimeIntervalToSubdivision$(startInterval: number, endInterval: number): Observable<TimeInterval> {
    return this.api1TimeIntervalControllerService.addTimeIntervalToSubdivision$(this.paramsDataSource.data.subdivisionId, startInterval, endInterval);
  }

  /** @inheritDoc */
  public timeIntervalComparer(firstTI: ITimeInterval, secondTI: ITimeInterval): number {
    return (firstTI.subdivisionId === null) === (secondTI.subdivisionId === null) ?
      (firstTI.startInterval == secondTI.startInterval ?
          (firstTI.endInterval > secondTI.endInterval ? 1 : -1) :
          (firstTI.startInterval > secondTI.startInterval ? 1 : -1)
      ) :
      +(secondTI.subdivisionId === null) - +(firstTI.subdivisionId === null);
  }

}
