import {EmployeeGraphGridCell} from "./employee-graph-grid-cell";
import {EmployeeGraphGridStaffUnit} from "./employee-graph-grid-staff-unit";

export class EmployeeGraphGridRow {
  constructor(public imageUrl: string,
              public subdivisionName: string,
              public occupationName: string,
              public staffUnit: EmployeeGraphGridStaffUnit,
              public graphDays: Array<EmployeeGraphGridCell>,) {
  }
}
