import { Injectable } from "@angular/core";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1DayDeviationControllerService } from "src/app/services/webApi/webApi1/controllers/api1-dayDeviation-controller.service";

@Injectable()
export class DayDeviationPanelComponentDataService {

  constructor(private readonly api1DayDeviationControllerService: Api1DayDeviationControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService) { }


  /** Получить все отклонения */
  public getAllDayDeviations() {
    return this.loadingIndicatorService.addToObservable(
      'Получение отклонений',
      this.api1DayDeviationControllerService.getAllDayDeviations$()
    )
  }
}
