import {SignalRHub} from "../signalR-hub";
import {Observable, of} from "rxjs";
import {ISendObj} from "../i-send-obj";
import {finalize, switchMap} from "rxjs/operators";

/** Базовый класс прослушивания метода SignalR */
export abstract class ListenerBase<TData> {
  /** Метод прослушивания */
  public abstract readonly method: string;

  protected constructor(private readonly signalRHub: SignalRHub) {
  }

  /**
   * Подписаться на изменение POCOs объекта<br>
   * Отправка подписи на сервер произойдет в момент подписи на результирующий Observable, а не в момент вызова этого метода<br>
   * Отправка отписки на сервер произойдет автоматически, когда завершится стрим(.pipe(take() | takeUntil()))<br><br>
   *
   * @example
   * .on().pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => { ..Логика })
   * В данном коде: как только сработает событие this.streams$.unsubscribe, стрим будет завершен и в следствие отпишется от сервера.
   * Т.е необходимо контролировать время жизни возвращаемого стрима. Все остальное происходит под капотом.
   */
  public on(): Observable<ISendObj<TData>> {
    return of(null).pipe(switchMap(x => {
      return this.signalRHub.subscribe<TData>(this.method);
    })).pipe(
      finalize(() => {
        this.signalRHub.unsubscribe(this.method);
      })
    )
  }
}
