import { Injectable } from "@angular/core";
import { DialogCloseResult, DialogRef, DialogService, DialogSettings } from "@progress/kendo-angular-dialog";
import { Subject } from "rxjs";
import { SelectWorkingEmployeesComponentGridItem } from "../services/select-working-employees-component-data.service";
import { SelectWorkingEmployeesDialogComponent } from "./select-working-employees-component/select-working-employees-dialog.component";

@Injectable({
  providedIn: "root"
})
export class SelectWorkingEmployeesDialogService{
  constructor(private dialogService: DialogService) {
  }

  /** Показать диалог, пример кода:
   * ``` typescript
   *  const sr = this.selectWorkingEmployeesDialogService.show({employeesIds:[1234]});
      sr.result$.subscribe(result => {
        if(result.type === 'cancel') {
          console.log(`cancel or close`,result);
        } else {
          console.log(`ok`,result);
        }
      });
   * ```
  */
  public show(params?: SelectWorkingEmployeesDialog_ShowDialogParams, options?: DialogSettings): IShowResult{
    if(!options){
      options = {}
      options.title = 'Выбор сотрудника'
      options.height = "90%"
      options.width = "90%"
    }

    options.content = SelectWorkingEmployeesDialogComponent;

    const dialogRef = this.dialogService.open(options);
    const component: SelectWorkingEmployeesDialogComponent = dialogRef.content.instance;

    component.startDate = params?.startDate;
    component.endDate = params?.endDate;
    component.forDate = params?.forDate;
    component.employeesIds = params?.employeesIds;

    const retSubj$ = new Subject<SelectWorkingEmployeesDialog_Result>();

    dialogRef.result.subscribe(result => {
      if(result instanceof DialogCloseResult) {
        retSubj$.next(new SelectWorkingEmployeesDialog_Result('cancel'));
      } else {
        retSubj$.next(new SelectWorkingEmployeesDialog_Result('ok',component.selectedItems));
      }
      retSubj$.complete();
    })

    return {
      dialogRef: dialogRef,
      result$: retSubj$
    }
  }
}

export interface IShowResult{
  dialogRef: DialogRef,
  result$: Subject<SelectWorkingEmployeesDialog_Result>
}


export class SelectWorkingEmployeesDialog_Result {
  constructor(
    public type: 'ok'| 'cancel',
    public selectedItems?: SelectWorkingEmployeesComponentGridItem[]
  ) {}
}

export class SelectWorkingEmployeesDialog_ShowDialogParams {
  constructor(
    /** Идентификаторы сотрудников (будут выбраны при открытии диалога) */
    public employeesIds?: number[],
    /** Дата выгрузки (по умолчанию DateTIme.Now)*/
    public forDate?: Date,
    /** Дата начала (по умолчанию null)*/
    public startDate?: Date,
    /** Дата окончания (по умолчанию null)*/
    public endDate?: Date
  ){}
}


