import {
  DateInStaffUnitRangeType,
  IStaffUnit,
  StaffUnit
} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {Employee, IEmployee} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {IGraphDataSource_DataItem} from "../graph-data-sources/graph-data-source.classes";
import {IconsUrlHelper} from "../../../../../../../../../../../src/app/helpers/icons-url.helper";
import {DateMap} from "../../../../../../../../../../../src/app/classes/maps/date-maps/date-map";
import {ObjComparer} from "../../../../../../../../../../../src/app/classes/object-comparers/object-comparer";
import {Graph, IGraph} from "../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/Graph";
import {ISubdivision, Subdivision} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import {IOccupation, Occupation} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Occupation";
import {IWorkMode, WorkMode} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkMode";
import {IPosition, Position} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Position";
import {IStaffUnitType, StaffUnitType} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import {IFinancingSource, FinancingSource} from "../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/FinancingSource";
import {
  IDumpListChangeRange,
  DumpListChangeRange
} from "../../../../../../../../../../../src/app/classes/dumps/dump-list-change";
import {
  CalculateNormaFactResult,
  ICalculateNormaFactResult
} from "../../../../../../../../../../../src/app/classes/requestResults/CalculateNormaFactResponse";

/** Класс идентификатора строки юи */
export class GraphGridDataSource_DataItem_IdClass {
  /** Уникальный идентификатор = {@link staffUnitId}_{@link index}_{@link redactionId} */
  public readonly uid: string;

  /**
   * Конструктор
   * @param staffUnitId Идентификатор исполнения должности
   * @param index Индекс виртуальной строки
   */
  public constructor(public readonly staffUnitId: number,
                     public readonly index: number,
                     public readonly redactionId: number){
    this.uid = `${staffUnitId}_${index}_${redactionId}`;
  }

  private static _comparer: ObjComparer<GraphGridDataSource_DataItem_IdClass>;
  /** Сравнение по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = new ObjComparer<GraphGridDataSource_DataItem_IdClass>({
        uid: true,
        staffUnitId: true,
        index: true,
        redactionId: true,
      });
    }

    return this._comparer;
  }
}

/** Класс строки для отображения в ui */
export class GraphGridDataSource_DataItem {
  /** Составной идентификатор строки */
  public readonly id: GraphGridDataSource_DataItem_IdClass;

  /** Состояние строки сравнения двух редакций. Если {@link undefined} - оригинальная строка */
  public compareState: 'added' | 'deleted' | 'modified' | undefined;

  /** Иконка */
  public readonly imageUrl: string;

  /** Является ли внешним исполнением должности */
  public readonly isExternal: boolean;

  /** Является ли исполнение должности за кого-то */
  public readonly isProxy: boolean;

  /** ФИО */
  public readonly fio: string;

  /** Конструктор */
  constructor(public readonly redactionId: number,
              public readonly indexSubItem: number,
              public readonly graph: Readonly<Pick<IGraph, "id" | "subdivisionId" | "month">>,
              public readonly subdivision: Readonly<ISubdivision>,
              public readonly occupation: Readonly<IOccupation>,
              public readonly workMode: Readonly<IWorkMode>,
              public readonly position: Readonly<IPosition>,
              public readonly employee: Readonly<IEmployee>,
              public readonly staffUnit: Readonly<IStaffUnit>,
              public readonly staffUnitType: Readonly<Pick<IStaffUnitType, 'id' | 'description'>>,
              public readonly financingSource: Readonly<Pick<IFinancingSource, 'id' | 'name' | 'shortName'>>,
              public readonly dumpListChangeRange: Readonly<IDumpListChangeRange>,
              public readonly normFact: Readonly<ICalculateNormaFactResult>) {
    this.id = new GraphGridDataSource_DataItem_IdClass(staffUnit.id, indexSubItem, redactionId);
    this.imageUrl = IconsUrlHelper.getstaffUnitImagePath(staffUnit.typeId, StaffUnit.isProxy(staffUnit.parentId));
    this.isExternal = StaffUnit.isExternal(staffUnit.typeId);
    this.isProxy = StaffUnit.isProxy(staffUnit.parentId);
    this.fio = Employee.fullName(employee);
  }

  private readonly _cellTypeMap = new DateMap<ReturnType<typeof this.getCellType>>();
  /** Получить тип ячейки. Используется кэш на основе {@link Map} */
  public getCellType(date: Date): DateInStaffUnitRangeType{
    let value = this._cellTypeMap.get(date);
    if(value){
      return value;
    }

    value = StaffUnit.checkDateInRange(date, this.staffUnit.startDate, this.staffUnit.endDate, this.dumpListChangeRange.date, this.dumpListChangeRange.endDate);

    this._cellTypeMap.set(date, value);
    return value;
  }

  private static _fullComparer: ObjComparer<Omit<GraphGridDataSource_DataItem, 'getCellType'>>;
  /** Сравнить по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      const excludeParentComparer = new ObjComparer<Omit<GraphGridDataSource_DataItem, 'getCellType'>>({
        //
        id: GraphGridDataSource_DataItem_IdClass.comparer.asPropertyCompareFunc(false),
        graph: Graph.fullComparer.asPropertyCompareFunc(),
        redactionId: true,
        indexSubItem: true,
        compareState: true,
        imageUrl: true,
        isExternal: true,
        isProxy: true,
        fio: true,
        //
        subdivision: Subdivision.usefulComparer.asPropertyCompareFunc(false),
        occupation: Occupation.usefulComparer.asPropertyCompareFunc(false),
        workMode: WorkMode.usefulComparer.asPropertyCompareFunc(false),
        position: Position.usefulComparer.asPropertyCompareFunc(false),
        employee: Employee.usefulComparer.asPropertyCompareFunc(false),
        staffUnit: Employee.usefulComparer.asPropertyCompareFunc(false),
        staffUnitType: StaffUnitType.usefulComparer.asPropertyCompareFunc(false),
        financingSource: FinancingSource.usefulComparer.asPropertyCompareFunc(false),
        //
        dumpListChangeRange: DumpListChangeRange.comparer.asPropertyCompareFunc(false),
        normFact: CalculateNormaFactResult.comparer.asPropertyCompareFunc(false),
      });

      this._fullComparer = excludeParentComparer;
    }

    return this._fullComparer;
  }

  /** Создать несколько на основе серверных данных */
  public static CreateManyFromServerData(sources: IGraphDataSource_DataItem[]): GraphGridDataSource_DataItem[]{
    const result = Array.from(internal());

    return result;

    function* internal(){
      for (let source of sources) {
        for (let subItem of source.subItems) {
          yield new GraphGridDataSource_DataItem(
            source.redactionId,
            subItem.data.index,
            source.graph,
            subItem.subdivision,
            subItem.occupation,
            subItem.workMode,
            subItem.position,
            subItem.employee,
            subItem.staffUnit,
            subItem.staffUnitType,
            subItem.financingSource,
            subItem.data.dumpListChangeRange,
            subItem.data.normFact);
        }
      }
    }
  }
}
