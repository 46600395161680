import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Observable } from "rxjs";
import { Subdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { VichLog } from "src/app/classes/domain/POCOs/timesheet/VichLog";
import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";
import {
  ITypeWorkAndMaterial,
  IVichRegisterSettingsResponse
} from "../../../services/webApi/webApi1/controllers/api1-vich-controller.service";

export interface IVichRegisterGridComponent {
  settings: VichRegisterComponentSettings

  typesResearchSource: IDropDownItem[]
  typesServiceSource: IDropDownItem[]
  filteredTypeWorks: ITypeWorkAndMaterial[]
  typesTypeWorkSource: ITypeWorkAndMaterial[]
  subdivisionsSource: Subdivision[]
  isReadOnly: boolean
  /** Выбранный тип журнала */
  currentRegisterTypeId: number;

  data$: Observable<VichRegisterComponentGridDataItem[]>

  form: FormGroup<IVichRegisterGridComponentForm>
}

export interface IVichRegisterGridComponentForm {
  id: FormControl<number>,
  staffUnitId: FormControl<number>
  descriptorId: FormControl<number>,
  registerType: FormControl<number>,
  diseaseNumber: FormControl<string>
  patientFio: FormControl<string>
  typeServiceId: FormControl<number>
  typeResearch: FormControl<IDropDownItem>
  typeWork: FormControl<IDropDownItem>
  researchCount: FormControl<number>
  researchProtocol: FormControl<string>
  subdivisionId: FormControl<number>
  operatingTime: FormControl<number>
  operatingTimeFree: FormControl<number>
}

export type IVichRegisterGridComponentFormValue = FormGroup<IVichRegisterGridComponentForm>["value"]

export type IVichRegisterGridComponentFormType = keyof IVichRegisterGridComponentFormValue

/** Модель строки таблицы */
export class VichRegisterComponentGridDataItem {
  constructor(public id: number,
              public descriptorId: number,
              public registerTypeName: string,
              public log: VichLog,
              public subdivision: Subdivision,
              public typeService: IDropDownItem,
              public typeResearch?: IDropDownItem,
              public typeWork?: ITypeWorkAndMaterial) {
  }
}

/** Настройки(данные) передаваемые в компонент VichRegisterGridComponent */
export class VichRegisterComponentSettings {
  constructor(public date: Date,
              public staffUnitOwnerId: number,
              public timeInterval: VichRegisterComponentSettings_TimeInterval,
              /** Объект, содержащий все настройки журналов */
              public vichRegisterSettingsArrObj: IVichRegisterSettingsResponse,
              /** Последний выбранный тип журнала */
              public lastChosenRegisterType: number) {
  }
}

/** Временной интервал */
export class VichRegisterComponentSettings_TimeInterval {
  constructor(public start: Date,
              public end: Date,
              public duration: number) {
  }

  /** Получает интервал как строку */
  public get asString(): string {
    return moment(this.start).format('HH:mm') + '-' + moment(this.end).format('HH:mm')
  }

  /** Проверка что данные валидны */
  public isValid(): boolean {
    return !!this.start && !!this.end && +this.start < +this.end;
  }

  /** Проверяет содержание даты в start и end переменных */
  public isValidToDate(date: Date): boolean {
    const momentDate = moment(date).startOf('day');
    const startMoment = moment(this.start).startOf('day');
    const endMoment = moment(this.end).startOf('day');

    return +momentDate == +startMoment &&
      (+momentDate == +endMoment || +moment(date).add(1, 'day') == +endMoment);
  }

  /** Создать экземпляр */
  public static Create(start: Date, end: Date): VichRegisterComponentSettings_TimeInterval {
    return new VichRegisterComponentSettings_TimeInterval(
      start, end, moment(end).diff(start, 'minute')
    )
  }
}

/** Интерфейс события закрытия окна случаев работы с ВИЧ */
export interface IVichRegisterGridComponentChangeEvent {
  /** Флаг если строки добавлялись/модифицировались/удалялись */
  changed: boolean;
  /** Общее время работы с ВИЧ */
  busyTime: number;
}
