import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { IResponsibleGridSettings } from 'src/app/components/administrations/administration-responsible/responsible-grid/responsible-grid.component';
import { IResponsibleItem } from 'src/app/components/administrations/entity/responsible-item.class';
import { Api1ResponsibleControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-responsible-controller.service';
import {
  SubdivisionsTreelistComponentDataSourceService
} from "../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import * as moment from "moment";

@Component({
  selector: 'app-administration-responsible-maintaining',
  templateUrl: './responsible-maintaining.component.html',
  styleUrls: ['./responsible-maintaining.component.css'],
  providers: [SubdivisionsTreelistComponentDataSourceService]
})
@traceClass('AdministrationResponsibleMaintainingComponent')
export class AdministrationResponsibleMaintainingComponent implements OnInit {

  public responsibleSettings: IResponsibleGridSettings;
  public title: string = "Ответственные за ведение графика/табеля";

  private _unsubscribe$ = new ReplaySubject<any>(1);

  constructor(public readonly subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceService,
              private responsibleControllerService: Api1ResponsibleControllerService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase)
  {
    const date = moment(new Date()).startOf('day').toDate();

    this.subdivisionDataSourceService.reloadData$({forDate: date, startDate: moment(date).add(-2, 'month').toDate(), endDate: null})
      .pipe(
        exErrorHandler(displayErrorsService),
        takeUntil(this._unsubscribe$)
      )
      .subscribe()

    this.responsibleSettings = this.getResponsibleSettings();
  }

  @traceFunc()
  ngOnInit(){

  }

  @traceFunc()
  private getResponsibleSettings() : IResponsibleGridSettings {
    let self = this;

    const allResponsibleUsersFunc = (subdivisionId: number) => self.responsibleControllerService.getResponsibleEmployees$(new Date(), subdivisionId).pipe(takeUntil(self._unsubscribe$));

    const allUsers$ = allResponsibleUsersFunc(null).pipe(shareReplay(1));

    return new class implements IResponsibleGridSettings {
      getResponsibles$Func =  allResponsibleUsersFunc;
      getAllResponsibles$Func = () => allUsers$;
      addResponsible$Func = (rui: IResponsibleItem, subdivisionId: number)=> self.responsibleControllerService.addResponsibleEmployee$(rui.responsibleId, subdivisionId);
      deleteResponsible$Func = (rui: IResponsibleItem, subdivisionId: number)=> self.responsibleControllerService.deleteResponsibleEmployee$(rui.responsibleId, subdivisionId);
    }
  }

  @traceFunc()
  ngOnDestroy(){
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
}
