/** Интерфейс компонента */
import {StaffUnit} from "../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {EditMoonlighterComponentFormService_Result} from "./services/edit-moonlighter-component-form.service";
import {EventEmitter} from "@angular/core";
import {IDropDownItem} from "../../../../../../src/app/classes/requestResults/iDropDownItem";

export interface IEditMoonlighterComponent{
  /** Событие отмены */
  cancel$: EventEmitter<void>;

  /** Событие сохранения */
  save$: EventEmitter<EditMoonlighterComponent_SaveEvent>;

  /** Настройки компонента при инициализации */
  settings: EditMoonlighterComponent_Settings;

  /** Объект формы */
  formObj: EditMoonlighterComponentFormService_Result;

  /** Доступный шаг ставки */
  rateStep: number;

  /** Источник данных Вид занятости */
  typeEmployments: Array<IDropDownItem>;

  /** Максимальное значение для контрола Количество ставок */
  rateMax: number;

  /** Максимальное значение для котрола Процент выплаты */
  percentMax: number;

  /** Минимальное допустимое значение для диапазона дат */
  minRange: Date;

  /** Максимально допустимое значение для диапазона дат */
  maxRange: Date;

  /** Текст в контроле Сотрудник: */
  employeeTextBoxValue: string;
}

/** Класс события сохранения */
export class EditMoonlighterComponent_SaveEvent {
  constructor(public type: EditMoonlighterComponent_Type,
              public staffUnit: StaffUnit) {
  }
}

/** Тип добавление или редактирование */
export type EditMoonlighterComponent_Type = 'add' | 'edit';

/** Редактируемая запись */
export class EditMoonlighterComponent_StaffUnit {
  /**
   * Конструктор
   * @param ownerId Идентификатор
   * @param start Начало
   * @param end Окончание
   * @param rate Ставка
   * @param financingSourceId Источник финансирования
   */
  constructor(public ownerId: number,
              public start: Date,
              public end: Date,
              public rate: number,
              public financingSourceId: number,
              public staffUnitTypeId: number,
              public positionId: number,
              public milk: boolean,
              public comment: string,
              public timestamp: [],
              public percent?: number) {
  }

  /** Валидировать объект */
  public validation() {
    if (!this.ownerId) {
      throw new Error('ownerId должен быть заполнен')
    }
    if (!this.rate) {
      throw new Error('rate должен быть заполнен')
    }
    if (!this.financingSourceId) {
      throw new Error('financingSourceId должен быть заполнен')
    }
    if (!this.staffUnitTypeId) {
      throw new Error('staffUnitTypeId должен быть заполнен')
    }
    if (!this.positionId) {
      throw new Error('positionId должен быть заполнен')
    }
    if (!this.timestamp) {
      throw new Error('timestamp должен быть заполнен')
    }
  }
}

/** Настройки компонента */
export class EditMoonlighterComponent_Settings {
  /**
   * Конструктор
   * @param type Режим
   * @param month Месяц в котором добавляется совмещение
   * @param subdivisionOwnerId Подразделение
   * @param employeeOwnerId OwnerId сотрудника
   * @param staffUnit Данные о выделенной штатной единицы
   * @param combinationType Назначение на свободную ставку (free) или на занятую (busy). Устанавливается только для combination
   */
  constructor(public type: EditMoonlighterComponent_Type,
              public month: Date,
              public redactionId: number,
              public subdivisionOwnerId: number,
              public employeeOwnerId: number,
              public staffUnit: EditMoonlighterComponent_StaffUnit,
              public combinationType: 'moonlighter' | 'combination' | 'uvor') {
  }

  /** Валидировать объект */
  public validation() {
    if (!this.month) {
      throw new Error('month должен быть заполнен')
    }
    if (!this.redactionId) {
      throw new Error('redactionId должен быть заполнен')
    }
    if (!this.subdivisionOwnerId) {
      throw new Error('subdivisionOwnerId должен быть заполнен')
    }
    if (!this.employeeOwnerId && this.staffUnit) {
      throw new Error('employeeOwnerId должен быть заполнен')
    }
    if (this.type == "add" && this.staffUnit) {
      throw new Error('staffUnit должен == null если режим добавление совмещения')
    }
    if (this.type == "edit" && !this.staffUnit) {
      throw new Error('StaffUnit должен быть заполнен если режим редактирования')
    }
    if (this.type == 'edit') {
      this.staffUnit.validation();
    }
    //в режиме редактирования совмещения должно быть заполнено поле percent (не undefined)
    if (this.type == 'edit' && this.combinationType && typeof this.staffUnit?.percent === "undefined") {
      throw new Error('Поле percent должно быть заполнено')
    }
  }
}
