<div *ngIf="form"
     [formGroup]="form"
     class="wrap">
  <kendo-daterange class="start-end-date">
    <kendo-formfield class="date-range-field">
      <kendo-label [for]="fromDate"
                   text="с:"></kendo-label>
      <kendo-dateinput #fromDate
                       kendoDateRangeStartInput
                       autoCorrectOn="blur"
                       [min]="minDate"
                       [max]="maxDate"
                       formControlName="fromDate">
      </kendo-dateinput>
    </kendo-formfield>
    <kendo-formfield class="date-range-field">
      <kendo-label [for]="tillDate" text="по:"></kendo-label>
      <kendo-dateinput #tillDate
                       kendoDateRangeEndInput
                       autoCorrectOn="blur"
                       [min]="minDate"
                       [max]="maxDate"
                       formControlName="tillDate">
      </kendo-dateinput>
    </kendo-formfield>
  </kendo-daterange>

  <div class="staff-unit-type-multiselect-section">
    <kendo-label text="Вид занятости:">
    </kendo-label>
    <kendo-multiselect [data]="staffUnitTypes"
                       textField="description"
                       valueField="id"
                       formControlName="staffUnitTypeIds"
                       [checkboxes]="true"
                       [autoClose]="false"
                       style="width: 70%;">
    </kendo-multiselect>
  </div>

  <div class="k-form-buttons" style="justify-content: flex-end;">
    <button kendoButton
            type="button"
            primary="true"
            [disabled] = "form.invalid"
            (click)="onOk()">
      Сформировать
    </button>

    <button kendoButton
            type="button"
            (click)="onCancel()">
      Отменить
    </button>
  </div>
</div>
