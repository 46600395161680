import {GetGraphModelResult_Directories_TimeInterval} from "../../../../../Classes/GetGraphModelResult";
import {TimeInterval} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";

export class TimeIntervalVM {
  constructor(
    public id: number,
    public start: string,
    public end: string,
    public duration: number,
    public nightDuration: number,
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_TimeInterval): TimeIntervalVM {
    return new TimeIntervalVM(source.id, source.start, source.end, TimeInterval.getDuration(source.startInterval, source.endInterval), TimeInterval.getNightDuration(source.startInterval, source.endInterval));
  }

  public static Create2(source: TimeInterval): TimeIntervalVM {
    return new TimeIntervalVM(source.id,
      TimeInterval.intervalToString(source.startInterval),
      TimeInterval.intervalToString(source.endInterval),
      TimeInterval.getDuration(source.startInterval, source.endInterval),
      TimeInterval.getNightDuration(source.startInterval, source.endInterval));
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_TimeInterval>): Array<TimeIntervalVM> {
    return source.map(item => TimeIntervalVM.Create(item));
  }

  /**
   * Сравнение
   */
  public static IsEquals(obj1: TimeIntervalVM, obj2: TimeIntervalVM): boolean{
    if(!obj1 && !obj2){
      return true;
    }

    if(!obj1 || !obj2){
      return false;
    }

    return obj1.id == obj2.id;
  }
}
