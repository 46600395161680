import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administration-responsible-sign',
  templateUrl: './responsible-sign.component.html',
  styleUrls: ['./responsible-sign.component.css']
})
export class AdministrationResponsibleSignComponent implements OnInit {

  public title: string = "Ответственные за подпись графика/табеля";

  constructor( ) { }

  ngOnInit(): void { }
}
