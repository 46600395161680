import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {interval, ReplaySubject, switchMap} from "rxjs";
import {
  MonitoringBase,
  MonitoringGetResponse,
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/monitoringBase";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";
import { take, takeUntil } from "rxjs/operators";
import {AlertOption, AlertService} from "../../../../../../../src/app/services/alert.service";
import { process, State } from "@progress/kendo-data-query";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { ResponseObjError } from "../../../../../../../src/app/classes/requestResults/responseObjError";
import { CommentDialogService } from "../../../../../../../src/app/components/comment/comment-dialog.service";
import {
  MonitoringGraphListener,
  MonitoringListenerBase,
  MonitoringTableListener
} from "../../../services/listeners/monitoring-listener";
import { KendoNotificationService } from "../../../../../../../src/app/services/kendo-notification.service";
import { Api1MonitoringGraphService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/api1-monitoring-graph.service";
import { Api1MonitoringTableService } from "../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/api1-monitoring-table.service";
import { traceClass } from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { trace } from "../../../../../../../src/app/modules/trace/operators/trace";
import { TraceParamEnum } from "../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import { traceParam } from "../../../../../../../src/app/modules/trace/decorators/param.decorator";

@Component({
  selector: 'app-monitoring-grid',
  templateUrl: './monitoring-grid.component.html',
  styleUrls: ['./monitoring-grid.component.css']
})
@traceClass('MonitoringGridComponent')
export class MonitoringGridComponent implements OnInit, OnDestroy {
  @Input() public input: MonitoringGridComponentInput;

  private data: Array<MonitoringGetResponse> = null;
  public dataSource: Array<MonitoringGetResponse> = null;

  public state: State = {
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      { field: 'status.name', dir: "asc" },
      { field: 'graphOrTable.subdivisionName', dir: "asc" }
    ]
  }

  /** Список идентификаторов подразделений у которых открыты деталии */
  public expandedDetailKeys: number[] = [];

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Сервис для получения данных. Устанавливается в зависимости от типа */
  public monitoringService: MonitoringBase = null;
  /** SignalR слушатель. Устанавливается в зависимости от типа */
  public monitoringListener: MonitoringListenerBase = null;

  constructor(private loadingIndicatorService: LoadingIndicatorService,
    private alertService: AlertService,
    private commentDialogService: CommentDialogService,
    private kendoNotificationService: KendoNotificationService,
    private api1MonitoringGraphService: Api1MonitoringGraphService,
    private api1MonitoringTableService: Api1MonitoringTableService,
    private monitoringGraphListener: MonitoringGraphListener,
    private monitoringTableListener: MonitoringTableListener,
    private readonly tracerService: TracerServiceBase) {

  }

  @traceFunc()
  ngOnInit(): void {
    this.tracerService.add2('Входные настройки', { obj: this.input })
    switch (this.input.type) {
      case "graph":
        this.monitoringService = this.api1MonitoringGraphService;
        this.monitoringListener = this.monitoringGraphListener;
        break;
      case "table":
        this.monitoringService = this.api1MonitoringTableService;
        this.monitoringListener = this.monitoringTableListener;
        break;
      default: throw new Error('Out of range');
    }

    this.loadingIndicatorService
      .addToObservable(
        'Получение данных мониторинга',
        this.monitoringService.get$(this.input.date)
      ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe({
        next: value => {
          this.data = value;
          this.setDataSource();
          this.subscribeToSignalR();
        }, error: error => {
          this.alertService.defaultAlertOption.downloadError()
            .showAlert();
        }
      })
  }

  /** Обработка изменения состояния */
  onDataStateChange($event: DataStateChangeEvent) {
    this.state = $event;
    this.setDataSource();
  }

  /** Начать согласование */
  @traceFunc()
  public onStartApproving(item: MonitoringGetResponse) {
    this.loadingIndicatorService.addToObservable(
      'Начинаем согласование',
      this.monitoringService.approving$(item.graphOrTable.id)
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.openApprovingPage(item);
      }, error: error => {
        this.catchError1(error, 'Попытка начать согласование закончилась ошибкой.')
      }
    })
  }

  /** Продолжить согласование */
  @traceFunc()
  public onRedoApproving(item: MonitoringGetResponse) {
    this.openApprovingPage(item);
  }

  /** Вернуть на согласование */
  @traceFunc()
  public onUnderApproving(item: MonitoringGetResponse) {
    this.alertService.defaultAlertOption.confirmation().mod(x => {
      x.message = 'Отменить согласование?'
      x.buttons[1].callBack = () => {
        this.loadingIndicatorService.addToObservable(
          'Отмена согласования',
          this.monitoringService.toUnderApproving$(item.graphOrTable.id)
        ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
          next: value => {
            this.openApprovingPage(item);
          }, error: error => {
            this.catchError1(error, 'Попытка отмены согласования закончилась ошибкой.')
          }
        })
      }
    }).showAlert();
  }

  /** Отправить на доработку */
  @traceFunc()
  public onUnderRevision(item: MonitoringGetResponse) {
    const dialog = this.commentDialogService.open();
    dialog.component.minLength = 1;

    const toUnderRevision$ = (comment: string) => {
      return this.loadingIndicatorService.addToObservable(
        'Отправка на доработку',
        this.monitoringService.toUnderRevision$(item.graphOrTable.id, comment)
      )
    }

    dialog.component.ok$.pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(comment => {
      toUnderRevision$(comment).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
        next: value => {
          //Пусто
        }, error: error => {
          if(this.input.type == 'graph' && ResponseObjError.checkTypeReturnCode(error) == '9f3612f0-a950-42f7-82c4-ed33c5fac654'){
            this.alertService.defaultAlertOption.question().mod(x => {
              x.message = 'Табель данного графика имеет статус "Согласован"<br><br><strong>Отправить табель на доработку?</strong><br>'
              x.buttons[1].callBack = () => {
                const obj = error as ResponseObjError<{ Id: number, Comment: string }>;
                this.loadingIndicatorService.addToObservable(
                  'Отправка Табеля на доработку',
                  this.api1MonitoringTableService.toUnderRevision$(obj.data.Id, obj.data.Comment)
                ).pipe(
                  trace(this.tracerService),
                  switchMap(value => toUnderRevision$(comment).pipe(trace(this.tracerService))),
                  take(1),
                  takeUntil(this.streams$.unsubscribe)).subscribe({
                    next: value => {
                      //Пустой
                    },
                    error: error => this.catchError1(error, 'При отправке на доработку Табеля произошла ошибка')
                })
              }
            }).showAlert();
          } else {
            this.catchError1(error, 'При отправке на доработку произошла ошибка');
          }
        }
      })
    });
  }

  /** Функция определяет по какому полю определяются открытые деталии */
  public expandDetailsBy = (dataItem: MonitoringGetResponse): any => {
    return dataItem.graphOrTable.id;
  };

  /** Обработка ошибок */
  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  private catchError1(error: any, @traceParam() titleMessage: string) {
    const alertOptions = this.alertService.defaultAlertOption.error().mod(x => {
      x.titleMessage = titleMessage;
    });

    if (ResponseObjError.checkType(error)) {
      alertOptions.mod(x => x.message = (error as ResponseObjError<string>).data);
    } else {
      alertOptions.mod(x => x.message = 'Попробуйте повторить попытку.')
    }

    alertOptions.showAlert();
  }

  /** Открыть новую вкладку Согласования */
  @traceFunc()
  private openApprovingPage(item: MonitoringGetResponse) {
    const graphOrTable = item.graphOrTable;
    window.open(
      `/approving/${this.input.type}?id=${graphOrTable.id}&subdivisionId=${graphOrTable.subdivisionId}&year=${graphOrTable.year}&month=${graphOrTable.month}`,
      '_blank')
      .focus()
  }

  /** Подписываемся на прослушку signalR */
  @traceFunc()
  private subscribeToSignalR() {
    this.monitoringListener.on().pipe(takeUntil(this.streams$.unsubscribe)).subscribe(message => {
      if (this.data.some(x => x.graphOrTable.id == message?.data?.id)) {
        this.updateRowFor(message.data.id, this.input.date, message.data.subdivisionId);
      } else {
        //Возможно нужно будет проверять относится ли он к данному пользователю и если да то добавлять в dataSource
      }
    })
  }

  /** Обновить данные строки */
  @traceFunc()
  private updateRowFor(id: number, date: Date, subdivisionId: number) {
    if (!this.data.some(x => x.graphOrTable.id == id)) {
      return;
    }

    this.loadingIndicatorService.addToObservable(
      'Обновление данных',
      this.monitoringService.getFor$(this.input.date, subdivisionId)
    ).pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: row => {
        // работа с деталями строки
        const isOpenDetail = this.expandedDetailKeys.some(x => x == id);
        if (isOpenDetail) {
          this.expandedDetailKeys = this.expandedDetailKeys.filter(x => x != id); //Закрываем деталии у текущей строки
          interval(200).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
            this.expandedDetailKeys.push(id);
          })
        }
        //---------------------------

        this.data = this.data.filter(x => x.graphOrTable.id != id);
        this.data.push(row);
        this.setDataSource();
        this.kendoNotificationService.showInfo({
          content: `"${row.graphOrTable.subdivisionName}" ${this.input.type == 'graph' ? 'график' : 'табель'} обновлен`
        })
      }, error: error => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.titleMessage = 'При обновлении данных произошла ошибка';
          x.message = 'Отображаемые данные уже не актуальны. Перезагрузите страницу';
        }).showAlert();
        this.kendoNotificationService.showError({
          content: 'НЕ удалось обновить данные'
        })
      }
    })
  }

  /**
   * Установить источник данных для таблицы
   * @private
   */
  @traceFunc()
  private setDataSource() {
    this.dataSource = process(this.data, this.state).data;
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Входные данные для компонента */
export class MonitoringGridComponentInput {
  /**
   * Конструктор
   * @param type Тип данных
   * @param date Дата мониторинга
   */
  constructor(public type: 'graph' | 'table',
    public date: Date,) {
  }
}
