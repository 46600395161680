import {ObjComparer} from "../object-comparers/object-comparer";
import {ArrayHelper} from "../../helpers/arrayHelper";

/** Информация о дне при подсчете нормы и факта */
export interface IDayFactInfo{
  /** Дата */
  date: Date,
  /** Факт отработанного времени за текущий день */
  dayFact: number,
  /** Факт отработанного времени в ночное время за текущий день */
  nightDuration: number,
  /** Продолжительность обеда, учитываемая в данном дне */
  dinnerDuration: number,
  /** Тип дня недели */
  dayTypeId: number,
}

export class DayFactInfo implements IDayFactInfo{
  constructor(public readonly date: Date,
              public readonly dayFact: number,
              public readonly nightDuration: number,
              public readonly dinnerDuration: number,
              public readonly dayTypeId: number) {

  }

  private static _comparer: ObjComparer<IDayFactInfo>;
  /** Сравнение по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = new ObjComparer<IDayFactInfo>({
        date: ObjComparer.dateComparer,
        dayFact: true,
        nightDuration: true,
        dinnerDuration: true,
        dayTypeId: true,
      })
    }

    return this._comparer;
  }

}

export interface ICalculateNormaFactResult {
  /** Норма времени для данной штатной единицы */
  norma: number;
  /** Фактическое значение времени для данной штатной единицы */
  fact: number;
  /** Количество ночных часов в факте отработанного времени за рассчитываемый период */
  nightTimeHours: number;
  /** Количество праздничных часов в факте отработанного времени за рассчитываемый период */
  holidayHours: number;
  /** Подневная информация о факте отработанного времени в рамках рассчитываемого периода */
  dayFactInfos: IDayFactInfo[];
}

export class CalculateNormaFactResult implements ICalculateNormaFactResult {
  constructor(public readonly norma: number,
              public readonly fact: number,
              public readonly nightTimeHours: number,
              public readonly holidayHours: number,
              public readonly dayFactInfos: IDayFactInfo[]) {
  }

  private static _comparer: ObjComparer<ICalculateNormaFactResult>;
  /** Сравнение по всем полям */
  public static get comparer(){
    if(!this._comparer){
      this._comparer = new ObjComparer<ICalculateNormaFactResult>({
        norma: true,
        fact: true,
        nightTimeHours: true,
        holidayHours: true,
        dayFactInfos: DayFactInfo.comparer.asArrayCompareFunc(false)
      })
    }

    return this._comparer;
  }

}


/**
 * Объект, возвращаемый сервером и содержащий информацию
 * о норме и факте часов для конкретной щтатной единицы
 */
export interface ICalculateNormaFactResponse {

  /** Идентификатор штатной единицы */
  staffUnitOwnerId: number;
  /** Результат */
  result: ICalculateNormaFactResult;
}
