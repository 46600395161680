import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DayDeviation} from "../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";

@Component({
  selector: 'app-day-deviation-dialog',
  templateUrl: './day-deviation-dialog.component.html',
  styleUrls: ['./day-deviation-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayDeviationDialogComponent implements OnInit {
  /** Событие отмены выбора */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Событие выбора */
  @Output() public select$: EventEmitter<DayDeviation> = new EventEmitter<DayDeviation>();

  /** Выделенное отклонения */
   public selectedDayDeviation: DayDeviation = null;

  constructor() { }

  ngOnInit() {
  }

  /** Обработка нажатия Отмена */
  onCancel() {
    this.selectedDayDeviation = null;
    this.cancel$.emit();
    this.cancel$.complete();
  }

  /** Обработка нажатие ОК */
  onOk() {
    if(this.selectedDayDeviation == null){
      throw new Error('Нет выделенного отклонения');
    }
    this.select$.emit(this.selectedDayDeviation);
    this.select$.complete();
  }
}
