import {Injectable, OnDestroy} from "@angular/core";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  CustomStorageService,
  StorageLocationEnum, StorageOptions
} from "../../../../../../../../../src/app/services/storages/custom-storage.service";
import {DataSource} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {IEditStatusBase} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/EditStatusBase";

/** Тип для статуса редактирования */
type EditStatusStorageType = Pick<IEditStatusBase, 'id' | 'targetFkId'> & {
  /** Время последнего обновления */
  lastUpdate: number
};

/** Базовый сервис хранилища компонента */
@Injectable()
export abstract class GraphComponentStorageServiceBase implements OnDestroy{
  /** Источник данных для идентификатора редакции */
  public abstract readonly redactionIdDataSource: DataSource<number>;
  /** Редактируется ли график */
  public abstract readonly editStatusDataSource: DataSource<EditStatusStorageType>;

  /** Конструктор */
  protected constructor() {
  }

  /** @inheritDoc */
  public abstract ngOnDestroy(): void;
}

@Injectable()
@traceClass('GraphComponentStorageService')
/** Сервис хранилища компонента */
export class GraphComponentStorageService extends GraphComponentStorageServiceBase{
  /** @inheritDoc */
  public readonly redactionIdDataSource = new DataSource<number>();
  /** @inheritDoc */
  public readonly editStatusDataSource = new DataSource<EditStatusStorageType>();

  /** Конструктор */
  constructor(private readonly customStorageService: CustomStorageService,
              private readonly traceService: TracerServiceBase) {
    super();

    this.redactionId();
    this.editStatus();
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.redactionIdDataSource.onDestroy();
    this.editStatusDataSource.onDestroy();
  }

  /** Работа с {@link redactionIdStorageOptions} */
  @traceFunc()
  private redactionId(){
    const storageObj = this.customStorageService.get<number>(this.redactionIdStorageOptions);
    this.traceService.add2('Данные в хранилище redactionId на момент инициализации', {obj: storageObj})

    this.redactionIdDataSource.setData(storageObj);

    this.redactionIdDataSource.change$.subscribe(value => {
      this.customStorageService.set(this.redactionIdStorageOptions, value);
      this.traceService.add2('Данные в хранилище redactionId изменились', {obj: value});
    });
  }

  /** Работа с {@link isEditingStorageOptions} */
  @traceFunc()
  private editStatus(){
    const storageObj = this.customStorageService.get<EditStatusStorageType>(this.isEditingStorageOptions);
    this.traceService.add2('Данные в хранилище editStatus на момент инициализации', {obj: storageObj})

    this.editStatusDataSource.setData(storageObj);

    this.editStatusDataSource.change$.subscribe(value => {
      this.customStorageService.set(this.isEditingStorageOptions, value);
      this.traceService.add2('Данные в хранилище editStatus изменились', {obj: value});
    });
  }

  /** Ключ для localStorage */
  private readonly redactionIdStorageOptions: StorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'GraphComponentStorageService/RedactionId',
    null,
    false,
    false
  );

  /** Ключ для localStorage */
  private readonly isEditingStorageOptions: StorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'GraphComponentStorageService/EditStatus',
    null,
    false,
    false
  );
}
