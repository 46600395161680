import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {traceClass} from 'src/app/modules/trace/decorators/class.decorator';
import {TracerServiceBase} from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import {DayDeviation} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {IDayDeviationClickEvent, IDayDeviationPanelComponent} from './i-day-deviation-panel-component';
import {DayDeviationPanelComponentService} from './services/day-deviation-panel-component.service';

/** Компонет списка откланений в графике */
@Component({
  selector: 'app-day-deviation-panel',
  templateUrl: './day-deviation-panel.component.html',
  styleUrls: [
    './day-deviation-panel.component.css',
    '../../panels.shared.css'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('TimeIntervalPanelComponent')
export class DayDeviationPanelComponent implements OnInit, IDayDeviationPanelComponent {

  private _customValueModel: {
    iDayDeviationClickEvent: IDayDeviationClickEvent,
    maxValue: number,
  };
  public get customValueModel(){
    return this._customValueModel;
  }
  public set customValueModel(value){
    this._customValueModel = value;
    this.changeDetectorRef.detectChanges();
  }

  public disabled: boolean = true

  /** Стрим отклонений */
  public get dayDeviations$() {
    return this.service.dayDeviations$
  }

  constructor(private readonly service: DayDeviationPanelComponentService,
              private readonly tracerService: TracerServiceBase,
              private readonly changeDetectorRef: ChangeDetectorRef,) {
    service.component = this;
  }

  ngOnInit(): void {
    this.service.onInit()
  }

  /** Обработка события нажатия */
  public onButtonClick(dayDeviation: DayDeviation) {
    this.service.onButtonClick(dayDeviation)
  }

  /** Обработка события установки значения отклонения */
  public onCustomValueSet($event: number) {
    this.service.onCustomValueSet($event)
  }

}
