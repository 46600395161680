<ng-container *ngIf="dataSource">
  <kendo-grid [kendoGridBinding]="dataSource.data$ | async"
              [selectable]="{enabled: true, mode: 'single'}"
              [group]="state.group"
              [sort]="state.sort"

              kendoGridSelectBy
              [KendoGridSelectByArrayDataSourceSelection]="deferSelectionService.tempSelection.data$ | async"

              KendoGridExpanded
              (dbCellClick)="onDbCellClick($event)"
              [scrollIntoSelect]="true"
  >
    <kendo-grid-column title="Редакция"
                       [field]="xRowPath.redaction.modifiedDate.toString()"
                       format="HH:mm dd.MM.yyyy"
                       [width]="150"
    >

    </kendo-grid-column>
    <kendo-grid-column title="Исполнитель"
                       [field]="xRowPath.modifiedUser.fio.toString()"
                       [width]="300"
    >

    </kendo-grid-column>
    <kendo-grid-column title="Комментарий"
                       [field]="xRowPath.redaction.comment.toString()"
    >

    </kendo-grid-column>

    <kendo-grid-column title="Статус"
                       [width]="200"
                       [field]="xRowPath.displayStatus.text.toString()"
    >
    </kendo-grid-column>

    <kendo-grid-column title="Версия"
                       hidden="hidden"
                       [field]="xRowPath.versionId.toString()"
    >
      <ng-template kendoGridGroupHeaderTemplate let-value="value" let-index="index">
        <ng-container *ngIf="(dataSource.data$ | async) as data">
          @if(dataSource.type === 'table'){
            <span>{{(value > 0 ? 'Корректирующий' : 'Первичный')}} № {{value}}</span>
          } @else if(dataSource.type === 'graph') {
            <span>Версия {{value}}</span>
          } @else {
            <span>что то не так!</span>
          }

          <span *ngLet="versionMap.get(value) as version" style="margin-left: 50px">
            @if (!version) {
              Что то не так!
            } @else {
              {{ version.routeStatus?.name }}
            }
          </span>
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column *ngIf="previewCommandButton || copyCommandButton || compareCommandButton"
                               title="Действие"
                               [autoSize]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngLet="asRow(dataItem) as dataItem">
          <ng-container *ngLet="((deferSelectionService.originSelectionDataSource.data$ | async)?.selectedIds?.data$ | async) | arrayElementAt:0: -1000000 as originSelectedRedactionId">
            <button kendoButton primary="true"
                    *ngIf="previewCommandButton"
                    [disabled]="originSelectedRedactionId === dataItem.redaction.id"
                    [icon]="'preview'"
                    title="Просмотр"
                    (click)="onPreview(dataItem)"
            >
            </button>
            <button kendoButton
                    *ngIf="copyCommandButton && dataItem.canCopy"
                    primary="true"
                    [icon]="'copy'"
                    title="Использовать для работы"
                    (click)="onCopy(dataItem)"
            >
            </button>
            <button kendoButton
                    *ngIf="compareCommandButton"
                    [hidden]="originSelectedRedactionId - 1 < dataItem.redaction.id"
                    primary="false"
                    [icon]="'equal'"
                    title="Сравнить"
                    (click)="onCompare(dataItem)"
            >
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridDetailTemplate let-dataItem
                 [kendoGridDetailTemplateShowIf]="detailTemplateShowIf"
    >
      <app-redaction-detail-grid2 [data]="dataItem.routes"></app-redaction-detail-grid2>
    </ng-template>
  </kendo-grid>
</ng-container>
