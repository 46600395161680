import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {Injectable, OnDestroy} from "@angular/core";
import {ArrayHelper} from "../../../../../../../../src/app/helpers/arrayHelper";

/** Сервис выделенного диаппазона строк и колонок */
@Injectable()
export class CellSelectingDirectiveService implements OnDestroy{

  private _selecting2: SelectedCellsEventObj = new SelectedCellsEventObj([]);
  /** Выделенные ячейки в данный момент */
  public get selecting2(){
    return this._selecting2;
  }
  public set selecting2(value){
    this._selecting2 = value;
    (this.selecting2$ as Subject<SelectedCellsEventObj>).next(value);
  }

  /** Событие изменения области выделения */
  public readonly selecting2$: Observable<SelectedCellsEventObj> = new BehaviorSubject<SelectedCellsEventObj>(this._selecting2);

  /** Событие двойного клика по ячейке */
  public readonly dblclick$: Observable<void> = new Subject<void>();

  /** Событие программного очищения области выделения */
  public clear$ : Observable<void> = new Subject<void>();

  /** Конструктор */
  constructor() {
  }

  /** Очистить выделенные ячейки */
  public clear(){
    (this.clear$ as Subject<void>).next();
  }

  /**
   * Повторить событие выделения ячеек.
   * Возьмет текущие выделенные и вновь бросит событие.
   * Необходимо для перерасчета после изменений в GraphDay. К примеру добавляем интервал в ячейку, должны появится кнопки вычета обеда
   */
  public repeatEvent(){
    (this.selecting2$ as ReplaySubject<SelectedCellsEventObj>).next(this._selecting2);
  }

  ngOnDestroy(): void {
  }
}

/** Класс диапазона */
class Range{
  /** Флаг выделения */
  public readonly isSelected: boolean = false;

  /** Флаг выделения нескольких */
  public readonly isMultiple: boolean = false;

  /**
   * Конструктор
   * @param min Минимальное значение
   * @param max Максимальное значение
   */
  constructor(public readonly min: number,
              public readonly max: number) {
    if(min <= max){
      this.isSelected = true;
    }

    if(min < max){
      this.isMultiple = true;
    }
  }
}

type CellViewModelType = {cell: ICellSelect, model: ICellSelectingDirectiveModel}

/** Класс события выделения ячеек */
export class SelectedCellsEventObj {
  /** Выделено ли хоть одна ячейка */
  public readonly isSelected: boolean;

  /** Выделенные ячейки */
  public readonly selectedCells: ICellSelect[];

  /** Модели выделенных ячеек */
  public readonly cellViewModels: ICellSelectingDirectiveModel[];

  /** Данные выделенных ячеек. Элемент массива содержит как ячейку так и ее модель */
  public readonly datas: CellViewModelType[];

  /** Диапазон строк */
  public readonly rowRange: Range;

  /** Диапазон колонок */
  public columnRang: Range;

  constructor(cellViewModels: CellViewModelType[]) {
    this.selectedCells = cellViewModels.map(x => x.cell);
    this.cellViewModels = cellViewModels.map(x => x.model);
    this.datas = cellViewModels;

    this.isSelected = cellViewModels.length > 0;

    let rowMin = Number.MAX_SAFE_INTEGER;
    let rowMax = Number.MIN_SAFE_INTEGER;
    let columnMin = Number.MAX_SAFE_INTEGER;
    let columnMax = Number.MIN_SAFE_INTEGER;

    for (let selectedCell of this.selectedCells) {
      rowMin = Math.min(rowMin, selectedCell.rowIndex);
      rowMax = Math.max(rowMax, selectedCell.rowIndex);
      columnMin = Math.min(columnMin, selectedCell.columnIndex);
      columnMax = Math.max(columnMax, selectedCell.columnIndex);
    }

    this.rowRange = new Range(rowMin, rowMax);
    this.columnRang = new Range(columnMin, columnMax);
  }

  /** Проверить находится ли ячейка в диапазоне выделенных строк */
  public checkIsInRows(rowIndex: number): boolean{
    return this.isSelected ? rowIndex >= this.rowRange.min && rowIndex <= this.rowRange.max : false;
  }

  /** Проверить находится ли ячейка в диапазоне выделенных колонок */
  public checkIsInColumns(columnIndex: number): boolean{
    return this.isSelected ? columnIndex >= this.columnRang.min && columnIndex <= this.columnRang.max : false;
  }

  /** Проверить находится ли в выделенных ячейках. */
  public checkIsSelected(rowIndex: number, columnIndex: number): boolean{
    return this.isSelected ? !!this.selectedCells.find(x => x.rowIndex == rowIndex && x.columnIndex == columnIndex) : false;
  }

  /**
   * Объединить выделенные ячейки<br>
   * Если повторное выделение ячейки, то снимет выделение<br>
   * @return новый экземпляр {@link SelectedCellsEventObj}
   */
  public merge(cellViewModels: CellViewModelType[]){
    const disjoint = ArrayHelper.disjointElements(
      this.datas,
      cellViewModels,
      this.keySelector,
      this.keySelector
    );

    return new SelectedCellsEventObj([...disjoint.left, ...disjoint.right]);
  }

  private keySelector(item: CellViewModelType){
    return `${item.cell.rowIndex}/${item.cell.columnIndex}`;
  }
}

/** Интерфейс выделенной ячейки */
export interface ICellSelect {
  /** Индекс строки */
  rowIndex: number;

  /** Индекс колонки */
  columnIndex: number;
}

/** Класс модели выделенной ячейки */
export interface ICellSelectingDirectiveModel {
  /** Контекст строки */
  rowModel: any;
  /** Название свойства строки откуда берется модель */
  rowPropertyName: string;
  /** Контекст ячейки */
  cellModel: any;
}

/** Интерфейс директивы */
export interface ICellSelectingDirective {
  /** Модель ячейки */
  readonly cellModel: null | ICellSelectingDirectiveModel;
  /** Обработка события изменения диаппазона */
  onSelectChange(value: SelectedCellsEventObj);

  /** Выделена ли ячейка */
  readonly isSelected: boolean;
}
