<kendo-dialog title="Выбор подразделения:"
              [maxHeight]="'90%'"
              [maxWidth]="'90%'"
              [minWidth]="'50%'"
              [width]="800"
              (close)="onCancel()"
              (keydown.escape)="onCancel()"
>
  <kendo-dialog-actions layout="normal">
    <button kendoButton
            (click)="onCancel()"
    >
      Отмена
    </button>
    <button kendoButton
            primary="primary"
            [disabled]="(disableOkIfNotSelection && !deferSelectionService.hasSelection.data) || (disableOkIfNotSelectionChange && !deferSelectionService.hasSelectionChange.data)"
            (click)="onOk()"
    >
      Выбрать
    </button>
  </kendo-dialog-actions>
  <app-subdivisions-treelist [dataSourceService]="dataSourceService"
                              [selection]="(deferSelectionService.tempSelection.data$ | async)"
                              [displayTextFn]="displayTextFn"
                              [cellStyle]="cellStyle"
                              [multipleSelected]="multipleSelected"
                              (doubleClick)="onOk()"
  >
  </app-subdivisions-treelist>
</kendo-dialog>
