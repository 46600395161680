import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { PreventableEvent } from '@progress/kendo-angular-common';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Covid2RegisterGridComponent } from '../covid2-register-grid/covid2-register-grid.component';
import { Covid2RegisterComponentSettings } from '../covid2-register-grid/i-covid2-register-grid.component';

@Component({
  selector: 'app-covid2-register-grid-dialog',
  templateUrl: './covid2-register-grid-dialog.component.html',
  styleUrls: ['./covid2-register-grid-dialog.component.css']
})
export class Covid2RegisterGridDialogComponent extends DialogContentBase {
  /** Настройки данного компонента */
  @Input() public settings: Covid2RegisterComponentSettings;

  /** Ссылка на grid компонент */
  @ViewChild('grid') public grid: Covid2RegisterGridComponent

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  /** Обработка закрытия окна */
  @HostListener('document:keydown.escape', ['$event']) // Захват нажатий на Esc
  public onClose(e: PreventableEvent) {
    e.preventDefault() // Предотвратить дальнейшее распространение стандартного события - например нажатие на крестик 

    if (this.grid.form) return // Если процесс добавления/редактирования не завершен - отменить закрытие диалога

    this.dialog.close(this.grid.getChangeEvent()) // Закрыть диалог и отправить евент изменений
  }
}