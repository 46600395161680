import {Directive, ElementRef, input, OnDestroy, OnInit, output} from "@angular/core";
import {fromEvent, Subscription, ThrottleConfig, throttleTime} from "rxjs";

/** Значение по умолчанию для duration*/
const defaultDuration = 100;

/**
 * Директива позволяет пропускать события клика по html элементу по переданным параметрам.<br>
 * Под капотом используется {@link throttleTime}
 */
@Directive({
  selector: '[throttleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  /** Подпись на оригинальное событие */
  private subscription : Subscription;

  /** Время пропуска событий (миллесекунд). По умолчанию {@link defaultDuration} */
  public readonly duration = input<number>(undefined, {alias: 'throttleClick'});

  /** Транслировать ли первое событие клика. Смотри {@link ThrottleConfig.leading} */
  public readonly leading = input<boolean>(true);

  /** Транслировать ли последнее событие клика, если события происходили в момент пропуска {@link duration}. Смотри {@link ThrottleConfig.trailing} */
  public readonly trailing = input<boolean>(false);

  /** Событие - отфильтрованное событие клика */
  public readonly throttleClick = output<MouseEvent>();

  /** Конструктор */
  constructor(private readonly el: ElementRef) {

  }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.subscription = fromEvent(this.el.nativeElement, 'click')
      .pipe(
        throttleTime(
          typeof this.duration() === 'number' ? this.duration() : defaultDuration,
          undefined,
          {
            leading: this.leading(),
            trailing: this.trailing()
          }),
      ).subscribe((value: MouseEvent) => {
        this.throttleClick.emit(value);
    })
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription = undefined;
  }
}
