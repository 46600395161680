import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {
  GraphTableComponentSettings,
  GraphTableComponentSettings_OpenFor
} from "./graph-grid/classes/graphTableComponentSettings";
import {TracerServiceBase} from "../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TraceParamEnum} from "../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceFunc} from "../../../../../../src/app/modules/trace/decorators/func.decorator";
import {trace} from "../../../../../../src/app/modules/trace/operators/trace";
import {GraphTableToolbarComponentService} from "./graph-table-toolbar/services/graph-table-toolbar-component.service";
import {AlertService} from "../../../../../../src/app/services/alert.service";
import {
  SubdivisionsTreelistComponentDataSourceService,
} from "../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {DataSource} from "../../../../../../src/app/classes/array-data-sources/data-source";

/** Тип данных для вывода outlet */
type OutletDataType = {
  subdivisionOwnerId: number,
  yearId: number,
  monthId: number
};

@Component({
  selector: 'app-graph-table-workspace',
  templateUrl: './graph-table-workspace.component.html',
  styleUrls: ['./graph-table-workspace.component.css'],
  providers: [
    GraphTableToolbarComponentService,
    SubdivisionsTreelistComponentDataSourceService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@traceClass('GraphTableWorkspaceComponent')
export class GraphTableWorkspaceComponent implements OnInit, OnDestroy {
  /** Инициализировать ли компонент GraphTableToolbarComponent */
  @Input() initGraphTableToolbarComponent: boolean = true;

  private streams$ = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  /** Событие начала/окончания редактирования графика/табеля */
  private isEdit$ = new EventEmitter<boolean>();

  /** Источник данных необходимых для инициализации графика/табеля */
  protected outletDataSource: DataSource<OutletDataType> = new DataSource<OutletDataType>();

  constructor(public readonly graphTableToolbarComponentService: GraphTableToolbarComponentService,
              private readonly tracerService: TracerServiceBase,
              private readonly alertService: AlertService) {
  }

  @traceFunc()
  ngOnInit() {
    this.isEdit$.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(value => {
      this.graphTableToolbarComponentService.disabled = value;
    })

    this.graphTableToolbarComponentService.change$.pipe(
      trace(this.tracerService),
      takeUntil(this.streams$.unsubscribes)
    ).subscribe(value => {
      this.outletDataSource.setData(null);
      if(!value){ return; }

      setTimeout(() => { //Необходимо для изъятия компонента из дерева html
        this.outletDataSource.setData({
          subdivisionOwnerId: value.subdivisionOwnerId,
          yearId: value.yearId,
          monthId: value.monthId
        })
      })
    })

    if(this.initGraphTableToolbarComponent){
      this.graphTableToolbarComponentService.onInit()
        .tryInitFromSessionStorage()
        .tryInitFromServer()
        .init$()
        .pipe(take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: err => {
            this.alertService.defaultAlertOption.downloadError().showAlert();
          }
        })
    }
  }

  /** Событие смены подкомпонента */
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  onOutletActivate($event: {settings: GraphTableComponentSettings}) {
    this.graphTableToolbarComponentService.disabled = false;

    const settings = new GraphTableComponentSettings(
      new GraphTableComponentSettings_OpenFor({
        subdivisionOwnerId: this.outletDataSource.data.subdivisionOwnerId,
        date: {
          year: this.outletDataSource.data.yearId,
          month: this.outletDataSource.data.monthId
        }
      }),
      this.isEdit$
    );

    $event.settings = settings;
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
    this.graphTableToolbarComponentService.ngOnDestroy();
  }
}
