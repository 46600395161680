import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {AddEvent, CancelEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent} from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import {IDropDownItem} from 'src/app/classes/requestResults/iDropDownItem';
import { ITuberRegisterGridComponent, ITuberRegisterGridComponentChangeEvent, ITuberRegisterGridComponentForm, ITuberRegisterGridComponentFormType, TuberRegisterComponentGridDataItem, TuberRegisterComponentSettings } from './i-tuber-register-grid.component';
import { TuberRegisterGridComponentDataService } from './services/tuber-register-grid-component-data.service';
import {
  TuberRegisterGridComponentService
} from './services/tuber-register-grid-component.service';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-tuber-register-grid',
  templateUrl: './tuber-register-grid.component.html',
  styleUrls: ['./tuber-register-grid.component.css'],
  providers: [
    TuberRegisterGridComponentService,
    TuberRegisterGridComponentDataService
  ]
})
export class TuberRegisterGridComponent implements ITuberRegisterGridComponent, OnInit {
  @Input() public settings: TuberRegisterComponentSettings;

  @Output() public currentRegisterType$ = new EventEmitter<number>();

  public typesResearchSource: IDropDownItem[];
  public typesServiceSource: IDropDownItem[];
  public typesTypeWorkSource: IDropDownItem[];
  public isReadOnly: boolean;

  public data$: Observable<TuberRegisterComponentGridDataItem[]>;

  public form: FormGroup<ITuberRegisterGridComponentForm>;

  public currentRegisterTypeId: number;

  public valueNormalizer = this.service.valueNormalizer;

  constructor(protected readonly service: TuberRegisterGridComponentService,
              private readonly chDetector: ChangeDetectorRef) {
    service.component = this;
  }

  ngOnInit(): void {
    this.service.onInit()
  }

  public addRow(e: AddEvent) {
    this.currentRegisterTypeId = this.settings.lastChosenRegisterType;
    this.service.addRow(this.currentRegisterTypeId, e.sender)
  }

  public editRow(e: EditEvent) {
    this.service.editRow(e)
  }

  public cancelRow(e: CancelEvent) {
    this.service.closeRow(e.sender, e.rowIndex)
  }

  public saveRow(e: SaveEvent) {
    this.service.saveRow(e)
  }

  public removeRow(e: RemoveEvent) {
    this.service.removeRow(e)
  }

  public getChangeEvent(): ITuberRegisterGridComponentChangeEvent {
    return this.service.getChangeEvent()
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  /** Обработка события выбора типа журнала */
  public changeRegisterType(id: number, grid: GridComponent) {
    grid.closeRow();
    this.form = undefined;

    this.currentRegisterTypeId = id;
    this.settings.lastChosenRegisterType = id;

    this.service.addRow(id, grid);
    this.currentRegisterType$.emit(id);

    this.chDetector.detectChanges();
  }
}
