import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  MonitoringDetailGetResponse
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/monitoring/monitoringBase";
import { Observable, ReplaySubject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { traceClass } from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { trace } from "../../../../../../../../src/app/modules/trace/operators/trace";

@Component({
  selector: 'app-monitoring-grid-detail',
  templateUrl: './monitoring-grid-detail.component.html',
  styleUrls: ['./monitoring-grid-detail.component.css']
})
@traceClass('MonitoringGridDetailComponent')
export class MonitoringGridDetailComponent implements OnInit, OnDestroy {
  @Input() entityId: number = null;
  @Input() sourceGetFunc: Observable<MonitoringDetailGetResponse>;

  public dataSource: MonitoringDetailGetResponse = null;
  public downloadState: DownloadStateEnum = DownloadStateEnum.downloading;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly tracerService: TracerServiceBase) { }

  @traceFunc()
  ngOnInit(): void {
    if (!this.entityId) {
      throw new Error('entityId not pass to component');
    }
    if (!this.sourceGetFunc) {
      throw new Error('sourceGetFunc not pass to component');
    }

    this.sourceGetFunc.pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.dataSource = value;
        this.downloadState = DownloadStateEnum.downloaded;
      }, error: error => {
        this.downloadState = DownloadStateEnum.error;
      }
    })
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

enum DownloadStateEnum {
  downloading = 1,
  downloaded = 2,
  error = 3
}
