import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {DialogService} from "@progress/kendo-angular-dialog";
import {
  HierarchiStringSmall
} from "../../../classes/requestResults/EditEntityWithDependenciesErrorData";
import {HierarchiStringsComponent} from "../hierarchi-strings.component";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";

/** Сервис отрывающий компонент {@link HierarchiStringsComponent} в диалоговом окне */
@Injectable()
@traceClass('HierarchiStringService')
export class HierarchiStringService implements OnDestroy {
  /** Стримы */
  private streams$ = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  /** Конструктор */
  constructor(private readonly dialogService: DialogService,
              private readonly traceService: TracerServiceBase) {

  }

  /**
   * Показать окно с конфликтами.<br>
   * @param params поддерживает undefined
   * @param hierarchiStrings источник данных
   * @param exportExcelFileName название файла
   * @param withContinueAction будет ли кнопка Продолжить
   */
  @traceFunc()
  public show(params: HierarchiStringsComponent['params'], hierarchiStrings: HierarchiStringSmall[], exportExcelFileName: string, withContinueAction = false): HierarchiStringsComponent {
    let actions = [{text: 'Закрыть', themeColor: 'primary', result: false}];
    if (withContinueAction) actions.push({text: 'Продолжить', themeColor: 'base', result: true});

    const dialogRef = this.dialogService.open({
      title: "Проверка на наличие ошибок",
      width: "96%",
      height: "90%",
      content: HierarchiStringsComponent,
      actions: actions,
      actionsLayout: "end"
    });

    const component = dialogRef.content.instance as HierarchiStringsComponent;
    component.hierarchiStrings = hierarchiStrings;
    component.params = params;
    component.exportExcelFileName = exportExcelFileName;

    return component;
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
