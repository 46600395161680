<kendo-splitter orientation="horizontal" style="height: 100%">
  <kendo-splitter-pane size="600px" min="300px" max="50%" >
    <app-subdivision-treeview2
      [dataSourceService]="subdivisionDataSourceService"
      [expandAll]="true"
      [checkableSettings]="subdivisionTreeViewChekableSettings"
      (checkedSubdivisions$)="onSubdivisionsChecked($event)"
  ></app-subdivision-treeview2>
  </kendo-splitter-pane>
  <kendo-splitter-pane>

    <!-- Единичное редактирование-->
    <app-approval-route-step
      [subdivisionIds]="checkedSubdivisionsKeys"
      [settings]="settings"
    ></app-approval-route-step>

  </kendo-splitter-pane>
</kendo-splitter>






