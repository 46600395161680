import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from "rxjs/internal/ReplaySubject";
import { Observable } from "rxjs/internal/Observable";
import { WebApi1Service } from "../web-api1.service";
import { HttpClient } from "@angular/common/http";
import { defer, of } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class Api1TimeSheetSettingsControllerService implements OnDestroy {

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _openCodivDialogSubdivisionIdsWithCash$: Array<number>;
  private _covidLogicCash$: CovidLogicEnum;

  constructor(private webApi1Service: WebApi1Service, private httpClient: HttpClient) { }


  /** Получить список идентификаторов подразделений, в которых при двойном клике по ячейке в графике откроется окно covid */
  public getOpenCovidDialogSubdivisionIds$(): Observable<Array<number>> {
    return this.httpClient.get<Array<number>>(
      this.webApi1Service.controllers.timeSheetSettings.actions.getOpenCovidDialogSubdivisionIds.toString());
  }

  /** Обвертка над методом getOpenCovidDialogSubdivisionIds$, Для кэширования результата на время работы */
  public getOpenCovidDialogSubdivisionIds$_cached$(): Observable<Array<number>> {
    return defer(() => {
      return this._openCodivDialogSubdivisionIdsWithCash$ === undefined
        ?
        this.getOpenCovidDialogSubdivisionIds$()
          .pipe(takeUntil(this.unsubscribe$),
            tap(value => this._openCodivDialogSubdivisionIdsWithCash$ = value))
        :
        of(this._openCodivDialogSubdivisionIdsWithCash$);
    })
  }

  /** Получить строку настроек, отвечающую за Алгоритм учета Covid */
  public getCovidLogic$(): Observable<CovidLogicEnum> {
    return this.httpClient.get<CovidLogicEnum>(
      this.webApi1Service.controllers.timeSheetSettings.actions.getCovidLogic.toString());
  }

  /** Обвертка над методом getCovidLogic$, Для кэширования результата на время работы */
  public getCovidLogic$_cached$(): Observable<CovidLogicEnum> {
    return defer(() => {
      return this._covidLogicCash$ === undefined
        ?
        this.getCovidLogic$()
          .pipe(
            takeUntil(this.unsubscribe$),
            tap(value => this._covidLogicCash$ = value)
          )
        :
        of(this._covidLogicCash$);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

export enum CovidLogicEnum {
  Logic1 = 1,
  Logic2 = 2
}
