import {
  FinancingSource,
  IFinancingSource
} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/FinancingSource";
import {GetGraphModelResult_Directories_FinancingSource} from "../../../../../Classes/GetGraphModelResult";

export class FinancingSourceVM implements Pick<IFinancingSource, 'id' | 'name' | 'shortName' >{
  constructor(
    public id: number,
    public name: string,
    public shortName: string
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_FinancingSource){
    return new FinancingSourceVM(source.id, source.name, source.shortName);
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_FinancingSource>){
    return source.map(x => FinancingSourceVM.Create(x))
  }

  public static Create2(source: FinancingSource){
    return new FinancingSourceVM(source.id, source.name, source.shortName);
  }
}
