<div #anchor
     [title]="disabled ? 'Временные интервалы в данный момент неактивны' : ''"
     class="button-group-container"
>
  <kendo-buttongroup  kendoContextMenuTargetContainer #target="kendoContextMenuTargetContainer"
                      [selection]="isRemoving ? 'multiple' : 'single'"
                      look="outline"
                      [disabled]="disabled"
  >
    <button *ngFor="let button of dataSource$ | async"
            kendoButton
            [ngClass]="{
              'group-button': true,
              'not-deleting-time-interval': !button.canRemove,
              'as-new-time-interval': button.asNew
            }"
            [kendoContextMenuTarget]='button'
            [title]="!button.canRemove ? 'Данный интервал времени является общим' : ''"
            [disabled]="disabled || (isRemoving && !button.canRemove)"
            [(selected)]="button.isSelected"
            [toggleable]="isRemoving"
            (click)="onButtonClick($event, button)"
    >
      <div class="time-interval-content">
        <span class="button-text">
          {{ button.startIntervalStr + '-' + button.endIntervalStr }}
        </span>
      </div>
    </button>
  </kendo-buttongroup>
</div>

<div style="display: flex; flex-wrap: wrap; align-content: flex-start; justify-content: flex-end; flex-grow: 1;" [title]= "disabled ? 'Временные интервалы в данный момент неактивны' : ''">
  <button kendoButton
          [ngStyle]="{
            padding: '0px',
            paddingLeft: '3px',
            paddingRight: '3px',
            background: '#bdffbd',
            color: '#3a3a3a',
            display: isRemoving ? 'none' : null,
            margin: '1px',
            height: '30px',
            width: '30px'
            }"
          [disabled]="isRemoving || disabled"
          title="Добавить временной интервал"
          (click)="onAdd($event)"
  >
    <span class="k-icon k-i-plus button-icon-span k-font-icon" style="margin: 0;"></span>
  </button>
  <button kendoButton
          [ngStyle]="{
            padding: '0px',
            paddingLeft: '3px',
            paddingRight: '3px',
            background: '#FCE4E4',
            color: '#3a3a3a',
            margin: '1px',
            height: '30px',
            width: '30px'
            }"
          [disabled]="isRemoving && totalSelected == 0 || disabled"
          title="Удалить несколько временных интервалов"
          (click)="remove($event)"
  >
    <span class="k-icon k-i-delete button-icon-span k-font-icon" style="margin: 0;"></span>
  </button>
  <button kendoButton #anch
          [ngStyle]="{
            padding: '0px',
            paddingLeft: '3px',
            paddingRight: '5px',
            color: '#3a3a3a',
            display: !isRemoving ? 'none' : null,
            margin: '1px',
            width: '30px',
            height: '30px'
            }"
          title="Отменить удаление временных интервалов"
          (click)="cancelRemove($event)"
  >
    <span class="k-icon k-i-cancel-outline button-icon-span k-font-icon"></span>
  </button>
</div>

<kendo-popup [anchor]="anchor" *ngIf="isRemoving" class="deleting-popup" [anchor]="anch">
  <div class="deleting-content">
    Выберите временные интервалы
  </div>
</kendo-popup>
<kendo-contextmenu  [target]="target"
                    [items]="contextMenuArray"
                    (select)="contextMenuSelect($event)"
                    (popupOpen)="contextMenuOpen($event)"
></kendo-contextmenu>

<app-add-time-interval  *ngIf="isAdding"
                        (cancel)="isAdding = false"
                        (save)="addedInterval($event)"
                        [subdivisionOwnerId]="subdivisionOwnerId"
>
</app-add-time-interval>
