import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DayDeviation} from "../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";

@Component({
  selector: 'app-custom-value',
  templateUrl: './custom-value.component.html',
  styleUrls: ['./custom-value.component.css']
})
export class CustomValueComponent implements OnInit {
  @Input() dayDeviation: DayDeviation;
  @Input() maxValue: number = 24;

  @Input() value: number = 0;
  @Output('valueChange') valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() ok$: EventEmitter<number> = new EventEmitter<number>();


  constructor() { }

  ngOnInit() {
    if(!this.dayDeviation) { throw new Error('@Input dayDeviation не передан')}
  }

  onCancel(){
    this.cancel$.emit();
  }

  onOk(){
    this.valueChange.emit(this.value);
    this.ok$.emit(this.value);
  }
}
