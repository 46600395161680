import {GetGraphModelResult_Directories_Employees} from "../../../../../Classes/GetGraphModelResult";
import {Employee} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";

export class EmployeeVm {
  constructor(
    public ownerId: number,
    public fullName: string,
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_Employees): EmployeeVm {
    return new EmployeeVm(source.ownerId, source.fullName)
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_Employees>): Array<EmployeeVm> {
    return source.map(item => EmployeeVm.Create(item));
  }

  public static Create2(source: Employee): EmployeeVm{
    return new EmployeeVm(source.ownerId, Employee.fullName(source))
  }
}
