import {IEntityId} from "../interfaces/IEntityId";
import {IEntityModify} from "../interfaces/IEntityModify";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IExcludeMilkLog extends IEntityId, IEntityModify{
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
}

@classBackend('ExcludeMilkLog', 'timesheet')
@className('ExcludeMilkLog')
/** Исключать молоко */
export class ExcludeMilkLog implements IExcludeMilkLog{
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public staffUnitId: number,
              public date: Date,) {
  }

  private static _fullComparer: ObjComparer<IExcludeMilkLog>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<IExcludeMilkLog>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        staffUnitId: true,
        date: ObjComparer.dateComparer,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IExcludeMilkLog, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if (!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }

}
