import {Injectable} from "@angular/core";
import {ListenerBase} from "../../../../../../src/app/services/signal-r/listeners/listener-base";
import {SignalRHub} from "../../../../../../src/app/services/signal-r/signalR-hub";

/** Интерфейс получаемых данных */
export interface IMonitoringListener_Data{
  /** Идентификатор графика/табеля */
  id: number;
  /** Подразделение */
  subdivisionId: number;
  /** Дата графика/табеля */
  date: Date;
}

/** Базовый класс */
export abstract class MonitoringListenerBase extends ListenerBase<IMonitoringListener_Data> {

}

/** Слушатель для мониторинга графиков */
@Injectable({
  providedIn: "root"
})
export class MonitoringGraphListener extends MonitoringListenerBase{
  readonly method: string = 'MonitoringGraph'

  constructor(signalRHub: SignalRHub) {
    super(signalRHub);
  }
}

/** Слушатель для мониторинга табелей */
@Injectable({
  providedIn: "root"
})
export class MonitoringTableListener extends MonitoringListenerBase{
  readonly method: string = 'MonitoringTable'

  constructor(signalRHub: SignalRHub) {
    super(signalRHub);
  }
}
