<div style="display: flex; flex-direction: column; flex-wrap: nowrap">
  <div style="flex: 1">
    <kendo-textarea [maxlength]="maxLength"
                    [rows]="rows"
                    [cols]="cols"
                    [resizable]="resizable"
                    [placeholder]="placeholder"
                    [(value)]="text"
    ></kendo-textarea>
  </div>

  <div style="display: flex; flex-wrap: nowrap"
       class="down"
  >
    <div style="flex: 1"></div>
    <button kendoButton
            (click)="cancel$.emit()"
    >
      Отмена
    </button>
    <button kendoButton
            primary="true"
            [disabled]="text.length < minLength"
            (click)="onOk()"
    >
      Продолжить
    </button>
  </div>
</div>



