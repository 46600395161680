<kendo-dialog title="Выбор штатной единицы"
              [height]="'90%'"
              [width]="'90%'"
              (close)="onCancel()"
              (keydown.escape)="onCancel()"
>
<ng-content select="[staffUnitDialog]"></ng-content>

  <app-staffunit-grid
    #staffUnitGrid
    [proxyEndDate] = "proxyEndDate"
    [proxyStartDate] = "proxyStartDate"
    (onceClick)="selectedStaffUnit = $event"
    (doubleClick)="selectedStaffUnit = $event; onOk()"
  >
  </app-staffunit-grid>

  <kendo-dialog-actions layout="normal">
    <button kendoButton
            (click)="onCancel()"
    >
      Отмена
    </button>
    <button kendoButton
            primary="primary"
            [disabled]="selectedStaffUnit == null"
            (click)="onOk()"
    >
      Выбрать
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
