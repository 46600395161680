import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {TracerServiceBase} from "../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../src/app/modules/trace/decorators/func.decorator";
import {ReplaySubject} from "rxjs";

@Component({
  selector: 'app-graph-table-workspace2',
  templateUrl: './graph-table-workspace2.component.html',
  styleUrl: './graph-table-workspace2.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('GraphTableWorkspace2Component')
/** Компонент обвертка для отображения Графика/Табеля */
export class GraphTableWorkspace2Component implements OnInit, OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  constructor(private readonly traceService: TracerServiceBase) {
  }


  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {

  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
