import { Component } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import { ReportDatesComponentSharedService } from '../../../../../../../src/app/components/report-dates/services/report-dates-component-shared.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { ReportDateValues } from '../../../../../../../src/app/components/report-dates/classes/report-date-values';
import { trace } from '../../../../../../../src/app/modules/trace/operators/trace';
import { exErrorHandler } from '../../../../../../../src/app/operators/ex-error-handler';
import { PatientMovementsGridComponentDataSourceService } from '../directory-grid/services/patient-movements-grid-component-data-source.service';

@Component({
  selector: 'app-directory',
  templateUrl: './patient-movements-directory.component.html',
  styleUrls: ['./patient-movements-directory.component.css'],
  providers: [
    ReportDatesComponentSharedService,
    PatientMovementsGridComponentDataSourceService
  ]
})
export class PatientMovementsDirectoryComponent {
  private streams$ = { unsubscribe: new ReplaySubject<any>(1) };

  constructor(private readonly reportDatesComponentSharedService: ReportDatesComponentSharedService,
              public readonly dataSourceService: PatientMovementsGridComponentDataSourceService,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase) {
  }

  ngOnInit(): void {
    // Подписка на обновление значений
    this.dataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          map(value => ({ ...ReportDateValues.toForDate(value), includeDeleted: false, includeOnlySent: false })),
          trace(this.tracerService),
          exErrorHandler(this.displayErrorService),
        ),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
