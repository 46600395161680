import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RedactionServiceGetResponse_Route } from '../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service';
import { xnameofPath } from '../../../../../../../../../src/app/functions/nameof';

@Component({
  selector: 'app-redaction-detail-grid2',
  templateUrl: './redaction-detail-grid2.component.html',
  styleUrl: './redaction-detail-grid2.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedactionDetailGrid2Component {
  public readonly data = input.required<RedactionServiceGetResponse_Route[]>();
  public readonly xRowPath = xnameofPath(RedactionServiceGetResponse_Route, '.', false);
}
