import {
  ChangeDetectionStrategy,
  Component,
  computed, effect,
  OnDestroy,
  OnInit,
  output,
  signal
} from '@angular/core';
import { ITimeInterval } from '../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval';
import {
  TracerServiceBase
} from "../../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Component({
  selector: 'app-add-time-interval2',
  templateUrl: './add-time-interval2.component.html',
  styleUrls: ['./add-time-interval2.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('AddTimeInterval2Component')
export class AddTimeInterval2Component implements OnInit, OnDestroy {
  /** Событие отмены добавления интервала */
  public readonly cancel = output<void>();

  /** Событие сохранения интервала времени */
  public readonly save = output<Pick<ITimeInterval, 'startInterval' | 'endInterval'>>();

  /** Источник данных для часов. Начало интервала */
  protected readonly startHours = signal(DropDownItem.GetMany(0, 23));
  /** Источник данных для минут. Начало интервала */
  protected readonly startMinutes = signal(DropDownItem.GetMany(0, 59));
  /** Выбранный час для начала интервала */
  protected readonly startSelectedHour = signal(this.startHours()[8]);
  /** Выбранные минуты для начала интервала */
  protected readonly startSelectedMinute = signal(this.startMinutes()[0]);

  /** Источник данных для часов. Окончание интервала */
  protected readonly endHours = signal(DropDownItem.GetMany(0, 24));
  private _endMinutes = DropDownItem.GetMany(0, 59);
  /** Источник данных для минут. Окончание интервала */
  protected readonly endMinutes = computed(() => {
    return this.endSelectedHour().id === 24 ? [this._endMinutes[0]] : this._endMinutes;
  });
  /** Выбранный час для окончания интервала */
  protected readonly endSelectedHour = signal(this.endHours()[17]);
  /** Выбранные минуты для окончания интервала */
  protected readonly endSelectedMinute = signal(this._endMinutes[0]);

  /** Временной интервал */
  protected timeInterval = computed<Pick<ITimeInterval, 'startInterval' | 'endInterval'>>(() => {
    return {
      startInterval: this.startSelectedHour().id * 60 + this.startSelectedMinute().id,
      endInterval: this.endSelectedHour().id * 60 + this.endSelectedMinute().id
    };
  })

  /** Валидационные ошибки */
  protected errors = computed(() => {
    if(this.timeInterval().startInterval > this.timeInterval().endInterval){
      return 'Начало интервала больше окончания интервала';
    }

    if(this.timeInterval().startInterval === this.timeInterval().endInterval){
      return 'Начало интервала НЕ может ровняться его окончанию';
    }

    return undefined;
  })

  constructor(private readonly traceService: TracerServiceBase) {
    //Если 24 час, то устанавливаем минуты в 00
    effect(() => {
      if(this.endSelectedHour().id === 24){
        this.endSelectedMinute.set(this._endMinutes[0]);
      }
    }, {allowSignalWrites: true})
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit() {
  }

  /** Обработка события Добавить */
  @traceFunc()
  protected onAddTimeInterval() {
    if(!!this.errors()){
      throw new Error('Трансляция события сохранения с НЕ валидными данными');
    }

    this.save.emit(this.timeInterval());
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy() {
  }
}

/** Класс элемента DropDown */
class DropDownItem {
  public readonly text: string;

  constructor(public readonly id: number) {
    this.text = id.toString();
    if (this.text.length < 2) {
      this.text = `0${this.text}`;
    }
  }

  /** Создать несколько */
  public static GetMany(startId: number, endId: number): Array<DropDownItem> {
    if (startId > endId) {
      throw new Error('start id > end id');
    }

    const returnArray: Array<DropDownItem> = new Array<DropDownItem>();
    for (; startId <= endId; startId++) {
      returnArray.push(new DropDownItem(startId));
    }

    return returnArray;
  }
}
