<div *ngIf="service.redaction" class="master">
  <app-redaction-button *ngIf="service.redaction"
                        [disabled]="service.status.status == 'edit' || disabled"
                        (clickByRoot$)="showRedactionSelect = true"
                        [type]="service.target"
                        [redaction]="{id: service.redaction.id, modifiedDate: service.redaction.date, isActualRedaction: service.redaction.isActual, version: service.version.number, isActualVersion: service.version.isActual}"
                        [hasClearCompareButton]="!!(service.compareRedactionId$ | async)"
                        (clickByClearCompare$)="service.compareRedactionId = null"
  >
  </app-redaction-button>

  <div *ngIf="service.data.actualRedactionId == service.redaction.id">
    Статус:
    <strong>
      <span *ngIf="service.status.status == 'notEdit'" class="actual">не редактируется</span>
      <span *ngIf="service.status.status == 'edit'">редактирует {{service.currentUserId == service.status.editUser?.id ? '(Вы) ' : ''}}{{service.status.editUser?.fio ? service.status.editUser.fio : 'НЕИЗВЕСТНО' }}</span>
      <span *ngIf="service.status.status == 'approving'">на согласовании</span>
      <span *ngIf="service.status.status == 'approved'">согласован</span>
    </strong>
  </div>

  <div *ngIf="service.status.status == 'edit' && service.currentUserId != service.status.editUser?.id"
       style="width: 200px"
  >
    <!--Для пустого пространства справа-->
  </div>

  <div class="button-panel">
    <button kendoButton
            *ngIf="service.target == 'graph'"
            [ngStyle]="{visibility: !disableSaveButton || !service.redaction.isActual ? 'hidden' : 'visible'}"
            (click)="onCheckBtnClick()"
    >
      <span class="k-icon k-i-check k-font-icon"
            style="color: #00a200; font-weight: bold">
      </span>
      Проверить
    </button>

    <kendo-dropdownbutton *ngIf="service.status.status != 'edit'"
                          icon="print"
                          [data]="printData$ | async"
                          [disabled]="disabled"
                          (click)="clickPrintBtn()"
                          [popupSettings]="{animate: false, align: 'right'}"
                          (itemClick)="onPrintMenuItemClick($event)"
                          KendoDropDownButtonExpanded
                          [unFocusAfterSelected]="true"
    >
      Печать
    </kendo-dropdownbutton>

    <button *ngIf="service.status.status == 'notEdit' && service.data.actualRedactionId == service.redaction.id"
            kendoButton
            icon="edit"
            primary="true"
            [disabled]="disabled"
            (click)="onStartEdit()"
    >
      Начать редактирование
    </button>

    <button *ngIf="service.status.status == 'notEdit' && service.data.actualRedactionId == service.redaction.id"
            kendoButton
            [disabled]="disabled"
            (click)="onToApproving()"
    >
      Отправить на согласование
      <span class="k-icon k-font-icon k-i-arrow-right" style="margin-left: 4px;"></span>
    </button>

    <button *ngIf="service.status.status == 'approving' && service.status.canFromApproving && service.data.actualRedactionId == service.redaction.id"
            kendoButton
            [disabled]="disabled"
            (click)="onFromApproving()"
    >
      Вернуть в работу
      <span class="k-icon k-i-arrow-left k-font-icon" style="margin-left: 4px;"></span>
    </button>

    <div *ngIf="service.status.status == 'edit' && service.currentUserId == service.status.editUser?.id && service.data.actualRedactionId == service.redaction.id">
      <button kendoButton
              icon="cancel"
              [disabled]="disabled"
              (click)="onStopEdit()"
      >
        Завершить редактирование
      </button>
      <button kendoButton
              icon="save"
              primary="true"
              [disabled]="disabled || disableSaveButton"
              (click)="onSave()"
              style="margin-left: 10px;"
      >
        Сохранить
      </button>
    </div>
  </div>

</div>

<kendo-dialog #dialogComponent
              *ngIf="showRedactionSelect"
              title="Список редакций"
              width="90%"
              maxHeight="90%"
              (keydown.escape)="dialogComponent.close.emit()"
              (close)="onRedactionChange({data: redactionGridComponent.data, selectedRedactionId: redactionGridComponent.initSelectedRowId})"
>
  <app-redaction-select #redactionGridComponent
                        [redactionFor]="service.target"
                        [graphOrTableId]="service.targetId"
                        [initSelectedRowId]="service.redaction.id"
                        (preview$)="onRedactionChange($event)"
                        (compare$)="onRedactionCompare($event)"
                        style="max-height: 100%; height: 100%; cursor: default;"
  ></app-redaction-select>


  <kendo-dialog-actions layout="start">
    <button kendoButton
            (click)="dialogComponent.close.emit()"
    >
      Отмена
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<div kendoDialogContainer></div>
