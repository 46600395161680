<kendo-dialog #customValueDialog
              title = "Установка времени"
              [height] = "170"
              [width] = "300"
              (close) = "onCancel()"
              (keydown.escape) = "onCancel()">

  <div id = "customValueDialogForm">
    <div style="margin-right: 10px">
      Часы {{dayDeviation.shortName}}:
    </div>
    <div>
      <kendo-numerictextbox class="short-numeric-box"
                            [(value)]="value"
                            [min]="0.0"
                            [max]="maxValue"
                            [autoCorrect]="true"
                            [step]="0.1"
                            format="0.##"
                            decimals="2"
      ></kendo-numerictextbox>
    </div>
  </div>

  <kendo-dialog-actions layout="normal">
    <button kendoButton
            (click)="customValueDialog.close.emit()"
    >
      Отмена
    </button>
    <button kendoButton
            [disabled]="value < 0.001"
            primary="primary"
            (click)="onOk()"
    >
      Сохранить
    </button>
  </kendo-dialog-actions>

</kendo-dialog>
