import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {IGraphTableBase} from "../../../../../classes/domain/POCOs/timesheet/GraphTableBase";
import {IRedactionBase} from "../../../../../classes/domain/POCOs/timesheet/RedactionBase";
import {IRouteBase} from "../../../../../classes/domain/POCOs/timesheet/RouteBase";
import {IRouteStatus} from "../../../../../classes/domain/POCOs/timesheet/RouteStatus";
import {map} from "rxjs/operators";
import {ArrayExpanded} from "../../../../../helpers/arrayHelper";
import {IEditStatusBase} from "../../../../../classes/domain/POCOs/timesheet/EditStatusBase";

export type RedactionBaseService_Type = 'graph' | 'table'

/** Базовый класс для работы с редакциями */
export abstract class RedactionBaseService{

  /** Тип данных. Для графика или табеля */
  public abstract get type(): RedactionBaseService_Type;

  protected constructor(
    private httpClient: HttpClient,
    private paths: {
      get: string,
      get2: string,
      get3: string,
      get4: string,
      copy: string,
      /** @deprecated */
      toApprovingObsolete: string,
      /** @deprecated */
      fromApprovingObsolete: string,
      /** @deprecated */
      startEditObsolete: string,
      /** @deprecated */
      updateLastActivityObsolete: string,
      /** @deprecated */
      stopEditObsolete: string,

      toApproving: string,
      fromApproving: string,
      startEdit: string,
      updateLastActivity: string,
      stopEdit: string
    }
  ) {  }

  /**
   * Получить редакции
   * @deprecated
   */
  public get$(graphOrTableId: number): Observable<IRedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse>(
      this.paths.get,
      {
        id: graphOrTableId
      }
    )
  }

  /**
   * Получить редакции
   * @param subdivisionOwnerId идентификатор подразделения
   * @param year год
   * @param month месяц
   * @deprecated
   */
  public get2$(subdivisionOwnerId: number, year: number, month: number): Observable<IRedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse>(
      this.paths.get2,
      {
        subdivisionOwnerId: subdivisionOwnerId,
        year: year,
        month: month
      }
    )
  }

  /**
   * Получить редакции по идентификатору Графика/Табеля
   */
  public get3$(graphOrTableId: number): Observable<RedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse2>(
      this.paths.get3,
      {
        id: graphOrTableId
      }
    ).pipe(
      map(value => {
        return RedactionServiceGetResponse.Create1(value, this.type);
      })
    )
  }

  /**
   * Получить редакции
   * @param subdivisionOwnerId идентификатор подразделения
   * @param year год
   * @param month месяц
   */
  public get4$(subdivisionOwnerId: number, year: number, month: number): Observable<RedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse2>(
      this.paths.get4,
      {
        subdivisionOwnerId: subdivisionOwnerId,
        year: year,
        month: month
      }
    ).pipe(
      map(value => {
        return RedactionServiceGetResponse.Create1(value, this.type);
      })
    )
  }

  /** Копировать конкретную редакцию */
  public copy$(redactionId: number): Observable<IRedactionServiceGetResponse>{
    return this.httpClient.post<IRedactionServiceGetResponse>(
      this.paths.copy,
      {
        redactionId: redactionId
      }
    );
  }

  /**
   * @deprecated
   * Отправить редакцию на согласование
   * @param redactionId идентификатор редакции
   * @param comment комментарий
   */
  public toApprovingObsolete$(redactionId: number, comment: string): Observable<IRedactionServiceToApprovingResponse>{
    return this.httpClient.post<IRedactionServiceToApprovingResponse>(
      this.paths.toApprovingObsolete,
      {
        redactionId: redactionId,
        comment: comment
      }
    );
  }

  /** Отправить редакцию на согласование */
  public toApproving$(redactionId: number, comment: string): Observable<void>{
    return this.httpClient.post<void>(
      this.paths.toApproving,
      {
        redactionId: redactionId,
        comment: comment
      }
    )
  }

  /**
   * @deprecated
   * Вернуть редакцию с согласования
   * @param redactionId идентификатор редакции
   */
  public fromApprovingObsolete$(redactionId: number): Observable<IRedactionServiceToApprovingResponse>{
    return this.httpClient.post<IRedactionServiceToApprovingResponse>(
      this.paths.fromApprovingObsolete,
      {
        redactionId: redactionId
      }
    )
  }

  /** Вернуть редакцию с согласования */
  public fromApproving$(redactionId: number): Observable<void>{
    return this.httpClient.post<void>(
      this.paths.fromApproving,
      {
        redactionId: redactionId,
      }
    )
  }

  /**
   * @deprecated
   * Начать редактировать редакцию
   * @param redactionId идентификатор редакции
   */
  public startEditObsolete$(redactionId: number): Observable<IRedactionServiceStartEditResponse>{
    return this.httpClient.post<IRedactionServiceStartEditResponse>(
      this.paths.startEditObsolete,
      {
        redactionId: redactionId
      }
    )
  }

  /** Начать редактирование редакции */
  public startEdit$(redactionId: number): Observable<IEditStatusBase>{
    return this.httpClient.post<IEditStatusBase>(
      this.paths.startEdit,
      {
        redactionId: redactionId,
      }
    )
  }

  /**
   * @deprecated
   * Обновить время последней активности
   * @param redactionId идентификатор редакции
   */
  public updateLastActivityObsolete$(redactionId: number): Observable<IRedactionServiceUpdateLastActivityResponse>{
    return this.httpClient.post<IRedactionServiceUpdateLastActivityResponse>(
      this.paths.updateLastActivityObsolete,
      {
        redactionId: redactionId
      }
    )
  }

  /** Обновить время последней активности */
  public updateLastActivity$(redactionId: number): Observable<IEditStatusBase>{
    return this.httpClient.post<IEditStatusBase>(
      this.paths.updateLastActivity,
      {
        redactionId: redactionId,
      }
    );
  }

  /**
   * @deprecated
   * Остановить редактирование
   * @param redactionId идентификатор редакции
   */
  public stopEditObsolete$(redactionId: number): Observable<IRedactionServiceToApprovingResponse>{
    return this.httpClient.post<IRedactionServiceToApprovingResponse>(
      this.paths.stopEditObsolete,
      {
        redactionId: redactionId
      }
    )
  }

  /** Остановить редактирование */
  public stopEdit$(redactionId: number): Observable<void>{
    return this.httpClient.post<void>(
      this.paths.stopEdit,
      {
        redactionId: redactionId
      }
    );
  }
}

/** @deprecated */
export interface IRedactionServiceGetResponse{
  graphTable: {id: number, month: Date, subdivisionOwnerId: number};
  users: Array<IRedactionServiceGetResponse_User>;
  redactions: Array<IRedactionServiceGetResponse_Redaction>;
  actualRedactionId: number;
  actualVersionId: number;
  /** Находится ли график/табель в работе. Если false то либо согласуется либо согласован */
  inWork: boolean;
  /** Идентификатор пользователя кто редактирует в данный момент */
  userEditId: number;
  /** Можно ли вернуть с согласования */
  canFromApproving: boolean;
  /** Согласован ли */
  isApproved: boolean;
  versions: Array<IRedactionServiceGetResponse_Version>;
}

interface IRedactionServiceGetResponse_Status{
  id: number;
  text: string;
}

interface IRedactionServiceGetResponse_Version{
  id: number;
  routeStatus: IRedactionServiceGetResponse_Status;
  displayStatus: IRedactionServiceGetResponse_Status;
}

interface IRedactionServiceGetResponse_User{
  id: number;
  fio: string
}

interface IRedactionServiceGetResponse_Route{
  id: number;
  date: Date;
  userId: number;
  routeStatus: IRedactionServiceGetResponse_Status;
  displayStatus: IRedactionServiceGetResponse_Status;
  comment: string;
}

interface IRedactionServiceGetResponse_Redaction{
  version: number;
  id: number;
  parentId: number;
  date: Date;
  userId: number;
  comment: string;
  routes: Array<IRedactionServiceGetResponse_Route>;
}

interface IRedactionServiceToApprovingResponse{
  /** Выполнено ли действие на сервере */
  isExecuted: boolean,
  data: IRedactionServiceGetResponse
}

interface IRedactionServiceStartEditResponse extends IRedactionServiceToApprovingResponse{
  updateInterval: number
}

interface IRedactionServiceUpdateLastActivityResponse extends IRedactionServiceToApprovingResponse{
  message: string
}





interface IRedactionServiceGetResponse2{
  /** График или Табель */
  graphTable: IGraphTableBase;
  /** Идентификатор актуальной редакции */
  actualRedactionId: number;
  /** Номер актуальной версии */
  actualVersionId: number;
  /** Находится ли график/табель в работе. Если false - то согласуется или согласован */
  inWork: boolean;
  /** Идентификатор пользователя кто редактирует в данный момент */
  userEditId: number;
  /** Можно ли вернуть с согласования */
  canFromApproving: boolean;
  /** Согласован ли график/табель */
  isApproved: boolean;
  /** Статус редактирования */
  editStatus: IEditStatusBase,

  /** Версии графика табеля */
  versions: IRedactionServiceGetResponse2_Version[];
  /** Редакции */
  redactions: IRedactionServiceGetResponse2_Redaction[];

  /** Статусы маршрутов для отображения */
  displayStatuses: IRedactionServiceGetResponse2_DisplayStatus[];

  /** Статусы маршрутов */
  routeStatuses: IRouteStatus[];

  /** Пользователи фигурирующие в данных */
  users: IRedactionServiceGetResponse_User[];
}

interface IRedactionServiceGetResponse2_Version {
  /** Номер версии */
  id: number,
  /** Идентификатор текущего маршрута */
  routeStatusId: number | undefined,
  /** Идентификатор статуса для отображения */
  displayStatusId: number,
  /** Актуальная ли версия */
  isActual: boolean,
}

interface IRedactionServiceGetResponse2_Redaction{
  redaction: IRedactionBase,
  routes: IRedactionServiceGetResponse2_Route[],
  versionId: number,
  canCopy: boolean,
  canEdit: boolean,
  canToApproving: boolean,
  canFromApproving: boolean,
  canApproving: boolean,
  canApproved: boolean,
  canToUnderRevision: boolean,
  isActual: boolean,
  canDecline: boolean,
  canCancelApproving: boolean,
}

interface IRedactionServiceGetResponse2_DisplayStatus{
  id: number,
  text: string,
}

interface IRedactionServiceGetResponse2_Route{
  route: IRouteBase,
  routeStatusId: number,
  displayStatusId: number,
}

/** Класс ответа сервера */
export abstract class RedactionServiceGetResponse implements Omit<IRedactionServiceGetResponse2, 'versions' | 'redactions'>{
  type: RedactionBaseService_Type;
  actualRedactionId: number;
  actualVersionId: number;
  canFromApproving: boolean;
  graphTable: IGraphTableBase;
  inWork: boolean;
  isApproved: boolean;

  userEditId: number;
  userEdit: IRedactionServiceGetResponse_User;

  editStatus: IEditStatusBase;

  versions: RedactionServiceGetResponse_Version[];
  redactions: RedactionServiceGetResponse_Redaction[];

  displayStatuses: IRedactionServiceGetResponse2_DisplayStatus[];
  routeStatuses: IRouteStatus[];
  users: IRedactionServiceGetResponse_User[];

  /** Создать */
  public static Create1(source: IRedactionServiceGetResponse2, type: RedactionBaseService_Type): RedactionServiceGetResponse{
    const userMap = new ArrayExpanded(source.users)
      .toMap(x => x.id);

    const routeStatusesMap = new ArrayExpanded(source.routeStatuses)
      .toMap(x => x.id);

    const displayStatusesMap = new ArrayExpanded(source.displayStatuses)
      .toMap(x => x.id);


    const versions = source.versions.map(x => {
      return RedactionServiceGetResponse_Version.Create1(x, undefined, routeStatusesMap, displayStatusesMap, undefined);
    });

    const redactions = source.redactions.map(x => {
      return RedactionServiceGetResponse_Redaction.Create1(
        x,
        x.routes.map(r => {
          return RedactionServiceGetResponse_Route.Create1(r, displayStatusesMap, routeStatusesMap, userMap)
        }),
        undefined,
        userMap);
    });

    const result: RedactionServiceGetResponse = {
      type: type,
      actualRedactionId: source.actualRedactionId,
      actualVersionId: source.actualVersionId,
      canFromApproving: source.canFromApproving,
      graphTable: source.graphTable,
      inWork: source.inWork,
      isApproved: source.isApproved,
      userEditId: source.userEditId,
      userEdit: userMap.get(source.userEditId),
      editStatus: source.editStatus,
      versions: versions,
      redactions: redactions,
      displayStatuses: source.displayStatuses,
      routeStatuses: source.routeStatuses,
      users: source.users
    };

    //Заполняем навигационные поля
    new ArrayExpanded(versions)
      .leftInnerJoinGroupedRight(
        redactions,
        x => x.id,
        x => x.versionId,
        (left, rights) => ({version: left, redactions: rights})
      ).array
      .forEach(group => {
        group.redactions.forEach(redaction => redaction.version = group.version); //Заполняем версии у редакций
        group.version.redactions = group.redactions;
        group.version.fullData = result;
      })

    return result;
  }
}

/** Обвертка для {@link IRouteBase} */
export abstract class RedactionServiceGetResponse_Route implements IRedactionServiceGetResponse2_Route{
  route: IRouteBase;

  displayStatusId: number;
  displayStatus: IRedactionServiceGetResponse2_DisplayStatus;

  routeStatusId: number;
  routeStatus: IRouteStatus;

  modifiedUser: IRedactionServiceGetResponse_User;
  approvals: IRedactionServiceGetResponse_User;

  /** Копирует объект и заполняет навигационные поля из переданных словарей */
  public static Create1(source: IRedactionServiceGetResponse2_Route, displayStatusesMap: Map<number, IRedactionServiceGetResponse2_DisplayStatus>, routeStatusesMap: Map<number, IRouteStatus>, usersMap: Map<number, IRedactionServiceGetResponse_User>){
    const instance: RedactionServiceGetResponse_Route = {
      displayStatusId: source.displayStatusId,
      displayStatus: displayStatusesMap.get(source.displayStatusId),
      routeStatusId: source.routeStatusId,
      routeStatus: routeStatusesMap.get(source.routeStatusId),
      modifiedUser: usersMap.get(source.route.modifiedUserId),
      approvals: usersMap.get(source.route.approvalsId),
      route: source.route,
    };

    return instance;
  }
}

/** Класс расширяет {@link IRedactionBase} */
export abstract class RedactionServiceGetResponse_Redaction implements Omit<IRedactionServiceGetResponse2_Redaction, 'routes'> {
  redaction: IRedactionBase;
  canApproved: boolean;
  canApproving: boolean;
  canCancelApproving: boolean;
  canCopy: boolean;
  canDecline: boolean;
  canEdit: boolean;
  canFromApproving: boolean;
  canToApproving: boolean;
  canToUnderRevision: boolean;
  isActual: boolean;

  versionId: number;
  version: RedactionServiceGetResponse_Version;

  routes: RedactionServiceGetResponse_Route[];

  modifiedUser: IRedactionServiceGetResponse_User;

  status: IRouteStatus;
  displayStatus: IRedactionServiceGetResponse2_DisplayStatus;

  /** Создает экземпляр из переданного источника и заполняет навигационные поля из переданных справочников */
  public static Create1(source: IRedactionServiceGetResponse2_Redaction, routes: RedactionServiceGetResponse_Route[], version: RedactionServiceGetResponse_Version, usersMap: Map<number, IRedactionServiceGetResponse_User>){
    const lastRoute = !routes || routes.length === 0 ? undefined : routes[0];

    const instance: RedactionServiceGetResponse_Redaction = {
      versionId: source.versionId,
      version: version,
      redaction: source.redaction,
      isActual: source.isActual,
      canApproved: source.canApproved,
      canApproving: source.canApproving,
      canCancelApproving: source.canCancelApproving,
      canCopy: source.canCopy,
      canDecline: source.canDecline,
      canFromApproving: source.canFromApproving,
      canEdit: source.canEdit,
      canToApproving: source.canToApproving,
      canToUnderRevision: source.canToUnderRevision,
      routes: routes,
      status:  lastRoute?.routeStatus,
      displayStatus: lastRoute?.displayStatus,
      modifiedUser: usersMap.get(source.redaction.modifiedUserId),
    };

    return instance;
  }
}

/** Класс версий редакций */
export abstract class RedactionServiceGetResponse_Version implements IRedactionServiceGetResponse2_Version{
  /** Полные данные */
  fullData: RedactionServiceGetResponse;

  id: number;
  isActual: boolean;

  routeStatusId: number;
  routeStatus: IRouteStatus;

  displayStatusId: number;
  displayStatus: IRedactionServiceGetResponse2_DisplayStatus;

  redactions: RedactionServiceGetResponse_Redaction[];

  /** Создает экземпляр из переданного источника и заполняет навигационные поля из переданных справочников */
  public static Create1(source: IRedactionServiceGetResponse2_Version, fullData: RedactionServiceGetResponse, routeStatusesMap: Map<number, IRouteStatus>, displayStatusesMap: Map<number, IRedactionServiceGetResponse2_DisplayStatus>, redactions: RedactionServiceGetResponse_Redaction[]){
    const instance: RedactionServiceGetResponse_Version = {
      fullData: fullData,
      id: source.id,
      isActual: source.isActual,
      routeStatusId: source.routeStatusId,
      routeStatus: routeStatusesMap.get(source.routeStatusId),
      displayStatusId: source.displayStatusId,
      displayStatus: displayStatusesMap.get(source.displayStatusId),
      redactions: redactions
    }

    return instance;
  }
}
