<div *ngIf="access?.graph || access?.table"
     style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; flex-wrap: nowrap">
  <kendo-buttongroup selection="single">
    <button *ngIf="access?.graph"
            kendoButton
            [toggleable]="true"
            [selected]="type == 1"
            icon="graph"
            (click)="onButtonGroupChange(1)"
    >
      График
    </button>
    <button *ngIf="access?.table"
            kendoButton
            [toggleable]="true"
            [selected]="type == 2"
            icon="table"
            (click)="onButtonGroupChange(2)"
    >
      Табель
    </button>
  </kendo-buttongroup>
  <kendo-datepicker
    [bottomView]="'year'"
    [topView]="'year'"
    [format]="'MM.yyyy'"
    [value]="date"
    [min]="minDate"
    [max]="maxDate"
    [readOnlyInput]="true"
    (valueChange)="onDateChange($event)"
  ></kendo-datepicker>
</div>
