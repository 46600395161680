import {Injectable} from "@angular/core";
import {RedactionBaseService, RedactionBaseService_Type} from "./redaction-base.service";
import { HttpClient } from "@angular/common/http";
import {WebApi1Service} from "../../web-api1.service";

@Injectable({
  providedIn: "root"
})
export class Api1RedactionGraphService extends RedactionBaseService{

  get type(): RedactionBaseService_Type {
    return 'graph';
  }

  constructor(httpClient: HttpClient, webApi1Service: WebApi1Service) {
    super(httpClient, {
      get: webApi1Service.controllers.redactionGraph.actions.get.toString(),
      get2: webApi1Service.controllers.redactionGraph.actions.get2.toString(),
      get3: webApi1Service.controllers.redactionGraph.actions.get3.toString(),
      get4: webApi1Service.controllers.redactionGraph.actions.get4.toString(),
      copy: webApi1Service.controllers.redactionGraph.actions.copy.toString(),
      toApprovingObsolete: webApi1Service.controllers.redactionGraph.actions.toApprovingObsolete.toString(),
      fromApprovingObsolete: webApi1Service.controllers.redactionGraph.actions.fromApprovingObsolete.toString(),
      startEditObsolete: webApi1Service.controllers.redactionGraph.actions.startEditObsolete.toString(),
      updateLastActivityObsolete: webApi1Service.controllers.redactionGraph.actions.updateLastActivityObsolete.toString(),
      stopEditObsolete: webApi1Service.controllers.redactionGraph.actions.stopEditObsolete.toString(),

      toApproving: webApi1Service.controllers.redactionGraph.actions.toApproving.toString(),
      fromApproving: webApi1Service.controllers.redactionGraph.actions.fromApproving.toString(),
      startEdit: webApi1Service.controllers.redactionGraph.actions.startEdit.toString(),
      updateLastActivity: webApi1Service.controllers.redactionGraph.actions.updateLastActivity.toString(),
      stopEdit: webApi1Service.controllers.redactionGraph.actions.stopEdit.toString(),
    });
  }
}
