import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WebApi1Service } from "../web-api1.service";
import { Observable } from "rxjs";
import { IPatientMovement, PatientMovement } from "src/app/classes/domain/POCOs/timesheet/PatientMovement";
import { IForDate } from '../../../../classes/for-date';
import { IPatientMovementsGridComponentDataSourceServiceParams } from '../../../../../../projects/timesheet/src/app/components/patient-movements/directory-grid/services/patient-movements-grid-component-data-source.service';

@Injectable({
  providedIn: "root"
})
export class Api1PatientMovementControllerService {

  constructor(private httpClient: HttpClient,
    private webApi1Service: WebApi1Service) { }

  /** Получить */
  public get$(startDate: Date, endDate: Date, ids: number[] = null, includeDeleted: boolean = false, includeOnlySent: boolean = false): Observable<PatientMovementsResponseDTO> {
    return this.httpClient
      .post<PatientMovementsResponseDTO>(this.webApi1Service.controllers.patientMovementGridControl.actions.get.toString(), { startDate, endDate, ids, includeDeleted, includeOnlySent })
  }

  /** Добавить */
  public add$(entity: IPatientMovement): Observable<PatientMovement> {
    return this.httpClient
      .post<PatientMovement>(this.webApi1Service.controllers.patientMovement.actions.add.toString(), entity)
  }

  /** Сохранить */
  public save$(entity: IPatientMovement): Observable<PatientMovement> {
    return this.httpClient
      .post<PatientMovement>(this.webApi1Service.controllers.patientMovement.actions.save.toString(), entity);
  }

  /** Отправить в СВОД */
  public send$(id: number, timestamp: []): Observable<PatientMovement> {
    return this.httpClient
      .post<PatientMovement>(this.webApi1Service.controllers.patientMovement.actions.send.toString(), { id, timestamp });
  }

  /** Удалить */
  public delete$(id: number): Observable<PatientMovement> {
    return this.httpClient
      .post<PatientMovement>(this.webApi1Service.controllers.patientMovement.actions.delete.toString(), { id })
  }

}

export interface PatientMovementsResponseDTO {
  currentSubdivisionId?: number, // Подразделение, в котором по основной ставке числится заполняющий пользователь 
  rows: PatientMovementRowResponseDTO[]
}

export interface PatientMovementRowResponseDTO {
  patientMovement: PatientMovement,
  subdivision: PatientMovementSubdivisionDTO,
  letalisMale: number,
  letalisFamale: number,
  military: number,
  fromNewSubjects: number
}

export interface PatientMovementSubdivisionDTO {
  id: number
  shortName: string
  longName: string
  code: string
}
