import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {Code} from "../../../../../../../../src/app/classes/domain/POCOs/timesheet_table/Code";
import {takeUntil} from "rxjs/operators";
import {TableRowModel} from "../classes/view-models/row-and-table-cell-view-model.class";
import {CodePanelItem} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-code-controller.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";

@Injectable()
@traceClass('TableCodePanelService')
export class TableCodePanelService implements OnDestroy{

  /** Событие выделения строки табеля */
  public selectedRow$: ReplaySubject<TableRowModel> =
    new ReplaySubject<TableRowModel>(1);

  /** Выбранный Code, который будет вставлен выбранному сотруднику */
  public insertedCode$: ReplaySubject<CodePanelItem> = new ReplaySubject<CodePanelItem>(1);

  /** Отключена ли панель */
  public disable: boolean = true;

  /** Стрим для отслеживания жизненного цикла сервиса */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>()
  }

  constructor(private readonly tracerService: TracerServiceBase) {
    this.selectedRow$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
    this.disable = !value || value.masterStaffUnit.isDuty
  });
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
