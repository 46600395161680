import {Injectable} from "@angular/core";
import {LoadingIndicatorService} from "../../../../../../../../../src/app/services/loading-indicator.service";
import {Observable} from "rxjs";
import {
  Api1TableControlControllerService,
  ISaveTableRequestTableCodeItem
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-table-control-controller.service";
import {GetTableModelResult} from "../../../../../Classes/GetTableModelResult";
import {SortDescriptor} from "@progress/kendo-data-query";
import {TableRowModel} from "../view-models/row-and-table-cell-view-model.class";
import {AppSettingsService} from "../../../../../../../../../src/app/services/app-settings.service";

@Injectable({
  providedIn: "root"
})
export class TableGridComponentService {
  constructor(private loadingIndicatorService: LoadingIndicatorService,
              private api1TableControlControllerService: Api1TableControlControllerService,
              private appSettingsService: AppSettingsService,
  ) {
  }

  public viewModel$(tableRedactionId: number): Observable<GetTableModelResult> {
    return this.loadingIndicatorService.addToObservable(
      'Загрузка данных табеля',
      this.api1TableControlControllerService.getTableRows$(tableRedactionId));
  }

  /** Получить данные для запроса сохранения табеля */
  public getDataForSaveTable(rows: Array<TableRowModel>)
    : { sourceData: Array<{ guid: number, tableRow: TableRowModel }>, tableCodes: Array<ISaveTableRequestTableCodeItem> } {
    const tempData = rows.map((x, index) => {
      return {
        guid: index + 1,
        tableRow: x,
        ownerId: x.ownerId
      }
    });

    return {
      sourceData: tempData.filter(x => !x.tableRow.deletedFlag),
      tableCodes: tempData.map(x => new class implements ISaveTableRequestTableCodeItem {
        guid = x.guid;
        sUOId = x.tableRow.staffUnit.ownerId;
        cId = x.tableRow.code.id;
        tCdV = x.tableRow.tableRowValue;
        tCdV2 = x.tableRow.code.supportValue2 ? x.tableRow.percent : null;
        oId = x.ownerId;
        dF = x.tableRow.deletedFlag;
      })
    }
  }


  /**
   * Получить первоначальные настройки сортировки графика
   */
  public getTableDefaultSortSettings(): Array<SortDescriptor> {
    const factory = (dir: 'asc' | 'desc', field: string): SortDescriptor => {
      return {
        dir: dir,
        field: field
      }
    }
    // TODO: ВЫНЕСТИ ПОЛУЧЕНИЕ ПОРЯДКА СОРТИРОВКИ НА СЛОЙ БАЗЫ
    if (this.appSettingsService.company.includes('ikb2_registry')){   // Порядок сортировки для ikb2
      return [
        factory('asc', 'employeeName'),
        factory('asc', 'occupationName'),
        factory('desc', 'rate'),
        factory('asc', 'isProxy'),
        factory('asc', 'IsEmployment')
      ];
    } else if(this.appSettingsService.company.includes('gvv2_registry')){   // Порядок сортировки для gvv2
      return [
        factory('asc', 'employeeName'),
        factory('asc', 'occupationName'),
        factory('desc', 'rate'),
        factory('asc', 'isProxy'),
        factory('asc', 'IsEmployment')
      ];
    } else {    // Порядок сортировки для прочих клиник
      return [
        factory('asc', 'employeeName'),
      ];
    }
  }
}
