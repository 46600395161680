import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IVichLog extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Идентификатор сотрудника */
  staffUnitId: number;
  /** Дата, за которую составлена данная запись */
  date: Date;
  /** Номер истории болезни пациента */
  diseaseNumber: string;
  /** ФИО пациента */
  patientFio: string;
  /** Вид обслуживания */
  typeServiceId: number | null;
  /** Вид исследования */
  typeResearchId: number | null;
  customTypeResearch: string; // Кастомный вид иследования
  /** Вида работ или название материала */
  typeWorkId: number | null;
  customTypeWork: string; // Кастомный вид работ или название материала
  /** Количество проведенных исследований в рамках данной записи */
  researchCount: number | null;
  /** № протокола исследований */
  researchProtocol: string;
  /** Вид обслуживания */
  subdivisionId: number | null;
  /** Время проведения */
  operatingTime: number;
  /** Идентификатор настроек ВИЧ журнала, в рамках которых была создана эта запись */
  descriptorId: number;
}

@classBackend('VichLog', 'timesheet')
@className('VichLog')
/** Модель строки ВИЧ журнала */
export class VichLog implements IVichLog {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public staffUnitId: number,
              public date: Date,
              public diseaseNumber: string,
              public patientFio: string,
              public typeServiceId: number | null,
              public typeResearchId: number | null,
              public customTypeResearch: string,
              public typeWorkId: number | null,
              public customTypeWork: string,
              public researchCount: number | null,
              public researchProtocol: string,
              public subdivisionId: number | null,
              public operatingTime: number,
              public descriptorId: number
  ) {
  }

  private static _fullComparer: ObjComparer<IVichLog>;
  /** Сравнение по всем полям */
  public static get fullComparer() {
    if(!this._fullComparer) {
      this._fullComparer = new ObjComparer<IVichLog>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        deletedFlag: true,
        staffUnitId: true,
        date: ObjComparer.dateComparer,
        diseaseNumber: true,
        patientFio: true,
        typeServiceId: true,
        typeResearchId: true,
        customTypeResearch: true,
        typeWorkId: true,
        customTypeWork: true,
        researchCount: true,
        researchProtocol: true,
        subdivisionId: true,
        operatingTime: true,
        descriptorId: true
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IVichLog, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer) {
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }

  /** Получить заполненный по умолчанию */
  public static GetEmpty(staffUnitId: number, date: Date): VichLog {
    return new VichLog(0, null, null, false, staffUnitId, date, '', '', 0, 0, null, 0, null, 0, '', 0, 1, 0);
  }
}

