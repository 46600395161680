import {Injectable} from "@angular/core";
import {AuthService} from "../services/auth.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import {Observable, of} from "rxjs";
import {AlertService} from "../../../services/alert.service";
import {ServerLoggerService} from "../../../services/loggers/server-logger.service";
import {switchMap} from "rxjs/operators";

/**
 * Доступ только если у пользователя есть необходимые функции
 */
@Injectable({
  providedIn: "root"
})
export class UserFuncGuard  {
  /**
   * Конструктор
   */
  constructor(private authService: AuthService,
              private alertService: AlertService,
              private serverLoggerService: ServerLoggerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userFuncGuardOptions: UserFuncGuardOption = route.data.userFuncGuardOptions;
    if(!userFuncGuardOptions){
      throw new Error('Установлен на route UserFuncGuard но не заданы его опции')
    }
    return this.authService.isAuth$.pipe(switchMap(value => {
      if(!value){
        return of(false);
      }

      const isCanActivate = userFuncGuardOptions.check(this.authService.user.Functions);
      if(!isCanActivate){
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'У вас нет доступа к ресурсу. Обратитесь к администратору'
        }).showAlert();
        this.serverLoggerService.error('Пользователь имеет доступ к юи но при этом ему запрещен туда доступ')
      }

      return of(isCanActivate);
    }))
  }
}

/**
 * Опции настройки UserFuncGuard
 */
export class UserFuncGuardOption {
  /**
   * Конструктор
   * @param logic Логика перебора массива items
   * @param items Массив
   */
  constructor(public logic: LogicEnum, public items: Array<UserFuncGuardOptionItem>) {
  }

  /**
   * Проверить
   * @param userFunction функции пользователя
   */
  public check(userFunction: Array<string>): boolean{
    for (let i = 0; i < this.items.length; i++){
      const isCheck = this.items[i].check(userFunction);
      if(isCheck && this.logic == LogicEnum.or){
        return true;
      }
      if(!isCheck && this.logic == LogicEnum.and){
        return false;
      }
    }

    return this.logic == LogicEnum.and ? true : this.items.length == 0;
  }
}

/**
 * Элемент настроек UserFuncGuard
 */
export class UserFuncGuardOptionItem {
  /**
   * Конструктор
   * @param logic Логика
   * @param functions Функции для поиска
   */
  constructor(public logic: LogicEnum, public functions: Array<string>) {
  }

  /**
   * Проверить
   * @param userFunction функции пользователя
   */
  public check(userFunction: Array<string>): boolean{
    for (let i = 0; i < this.functions.length; i++){
      const contains = userFunction.includes(this.functions[i]);
      if(contains && this.logic == LogicEnum.or){
        return true;
      }
      if(!contains && this.logic == LogicEnum.and){
        return false;
      }
    }

    return this.logic == LogicEnum.and ? true : this.functions.length == 0;
  }
}

/**
 * Тип логики поиска функций
 */
export enum LogicEnum {
  /**
   * Должны все функции находится в массиве
   */
  and= 1,
  /**
   * Любая из функций должна находится в массиве
   */
  or= 2
}
