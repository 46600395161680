import {Injectable, OnDestroy} from "@angular/core";
import {ComponentServiceBase} from "../../../../../../../src/app/services/abstracts/component-service-base";
import {EditMoonlighterComponentDataService} from "./edit-moonlighter-component-data.service";
import {EditMoonlighterComponentSignalRService} from "./edit-moonlighter-component-signalR.service";
import {
  BaseEmployee,
  EditMoonlighterComponentFormService,
  EditMoonlighterComponentFormService_InitFormParam
} from "./edit-moonlighter-component-form.service";
import {debounceTime, distinctUntilChanged, map, take, takeUntil} from "rxjs/operators";
import {AlertOptionExtension, AlertService} from "../../../../../../../src/app/services/alert.service";
import {combineLatest, iif, Observable, of, ReplaySubject} from "rxjs";
import * as moment from "moment";
import {FreeRateGridService, FreeRateGridService_RowItem} from "../free-rate-grid/free-rate-grid.service";
import {StaffUnit} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {
  AddCombinationFree$Param,
  AddMoonlighter$Param,
  Api1GraphControlControllerService,
  EditCombinationFree$Param,
  EditMoonlighter$Param
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {KendoNotificationService} from "../../../../../../../src/app/services/kendo-notification.service";
import {EditMoonlighterComponent_SaveEvent, IEditMoonlighterComponent} from "../i-edit-moonlighter-component";
import {Employee} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {
  SelectWorkingEmployeesDialogService
} from "../../../../../../../src/app/components/select-working-employees/dialog/select-working-employees-dialog.service";
import {StaffUnitTypeEnum} from "../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TracerServiceBase
} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {LoadingIndicatorService} from "../../../../../../../src/app/services/loading-indicator.service";
import {exErrorHandler} from "../../../../../../../src/app/operators/ex-error-handler";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";

@Injectable()
@traceClass('EditMoonlighterComponentService')
export class EditMoonlighterComponentService extends ComponentServiceBase<IEditMoonlighterComponent> implements OnDestroy {
  /** Внутренние стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Объект формы. Берется из компонента */
  private get formObj() {
    return this.component.formObj;
  }
  private set formObj(value) {
    this.component.formObj = value;
  }

  /** Настройки компонента */
  private get componentSettings() {
    return this.component.settings;
  }

  /** Диапазон месяца(дата первого и последнего дня) */
  private monthRange: { start: Date, end: Date };

  constructor(private readonly dataService: EditMoonlighterComponentDataService,
              private readonly signalRService: EditMoonlighterComponentSignalRService,
              private readonly formService: EditMoonlighterComponentFormService,
              private readonly freeRateGridService: FreeRateGridService,
              private readonly alertService: AlertService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly selectWorkingEmployeesDialogService: SelectWorkingEmployeesDialogService,
              private readonly graphControlService: Api1GraphControlControllerService,
              private readonly traceService: TracerServiceBase,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly displayErrorsService: DisplayErrorsService,
  ) {
    super();
  }

  /** Инициализация сервиса */
  public onInit() {
    this.setMonthRange(this.componentSettings.month);

    const alertError = (message: string, alertOptions: AlertOptionExtension) => {
      alertOptions
        .mod(x => {
          x.message = message;
          x.buttons[0].callBack = () => {
            this.component.cancel$.emit()
          }
        })
        .showAlert();
    };

    const typeIds: StaffUnitTypeEnum[] = [];

    switch (this.componentSettings.combinationType) {
      case "moonlighter":
        typeIds.push(StaffUnitTypeEnum.MoonlighterInner, StaffUnitTypeEnum.MoonlighterExternal);
        break;
      case "combination":
        typeIds.push(StaffUnitTypeEnum.CombinationInner, StaffUnitTypeEnum.CombinationExternal);
        break;
      case "uvor":
        typeIds.push(StaffUnitTypeEnum.Uvor)
        break;
      default: throw new Error('out of range')
    }

    this.loadingIndicatorService.addToObservable(
      'Получение данных',
      combineLatest([
        this.dataService.getEmployeeByOwnerId$(this.componentSettings.employeeOwnerId, this.monthRange.start, this.monthRange.end),
        this.dataService.getStaffUnitTypeByIds$(typeIds),
        this.freeRateGridService.init(this.componentSettings.month, this.componentSettings.subdivisionOwnerId, this.componentSettings.staffUnit?.ownerId),
        this.dataService.rateStep$(),
      ])
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        const baseEmployee = this.getEmployeeBase(value[0]);
        this.component.employeeTextBoxValue = this.getEmployeeTextBoxValue(baseEmployee)

        //Работа с 3 значением
        this.component.rateStep = value[3];
        //Работа с 1 значением
        this.component.typeEmployments = value[1]
          .map(x => {
            return {
              id: x.id,
              text: x.description
            }
          })

        //Остальные данные для инициализации формы
        const otherData = this.getFormDateForEdit();
        this.freeRateGridService.selectedRow = otherData?.freeInterval; //Устанавливаем выделенную строку необходимо для редактирования

        this.freeRateGridService.selected$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
          this.onIntervalChange(value);
        });
        this.formObj = this.formService.create(
          new EditMoonlighterComponentFormService_InitFormParam(
            baseEmployee,
            otherData?.freeInterval,
            otherData?.rate,
            otherData?.percent,
            otherData?.start,
            otherData?.end,
            this.componentSettings.type == 'add' ?
              this.component.typeEmployments[0] :
              this.component.typeEmployments.find(x => x.id == this.componentSettings.staffUnit.staffUnitTypeId),
            this.componentSettings.staffUnit?.comment ?? '',
            undefined,
            otherData?.milk ?? false
          )
        );

        this.onFormControlChange();

        if (otherData?.freeInterval) {
          this.onIntervalChange(otherData.freeInterval);
        }
      }, error: error => {
        this.component.cancel$.emit();
        alertError('Произошла ошибка, попробуйте еще раз', this.alertService.defaultAlertOption.error());
      }
    });
  }

  /** Подпись на события */
  public onFormControlChange() {
    this.formObj.controls.baseEmployee.valueChanges.pipe(takeUntil(this.streams$.unsubscribe)).subscribe((value: BaseEmployee) => {
      this.component.employeeTextBoxValue = this.getEmployeeTextBoxValue(value);
    });

    this.formObj.controls.freeInterval.valueChanges
      .pipe(takeUntil(this.streams$.unsubscribe))
      .subscribe((value: FreeRateGridService_RowItem) => {
      const isUsePercent = this.isUsePercent();

      this.component.rateMax = isUsePercent ? 1 : this.getRateMax(value?.rate);
      this.component.percentMax = isUsePercent ? this.getRateMax(value?.rate) * 100 : 0;
      this.component.minRange = this.getMinRangeDate(value?.start, this.monthRange.start);
      this.component.maxRange = this.getMaxRangeDate(value?.end, this.monthRange.end);

      if (!value) {
        this.formObj.controls.rate.setValue(null);
        this.formObj.controls.rate.disable();

        this.formObj.controls.start.setValue(null);
        this.formObj.controls.start.disable();

        this.formObj.controls.end.setValue(null);
        this.formObj.controls.end.disable();

        this.formObj.controls.percent.setValue(null);
        this.formObj.controls.percent.disable();
      } else {
        setTimeout(() => { //Делаем задержку для перерасчета мин/максимальных значений контролов
          const getStartValue = (): Date => {
            const dateAsNumber = Math.min(Math.max(+value.start, +(this.formObj.controls.start.value ?? value.start)), +value.end);
            return new Date(dateAsNumber);
          }

          const getEndValue = (): Date => {
            const dateAsNumber = Math.max(Math.min(+value.end, +(this.formObj.controls.end.value ?? value.end)), + value.start);
            return new Date(dateAsNumber);
          }

          this.formObj.controls.rate.setValue(Math.min(1, value.rate, this.formObj.controls.rate.value ?? 0.5))
          this.formObj.controls.rate.enable();

          if(isUsePercent){
            this.formObj.controls.percent.setValue(Math.min(100, value.rate * 100, this.formObj.controls.percent.value ?? 50));
            this.formObj.controls.percent.enable();
          }

          this.formObj.controls.start.setValue(getStartValue());
          this.formObj.controls.start.enable();

          this.formObj.controls.end.setValue(getEndValue());
          this.formObj.controls.end.enable();
        });
      }

      this.formObj.controls.start.markAsDirty();
      this.formObj.controls.end.markAsDirty();
      this.formObj.controls.rate.markAsDirty();
      this.formObj.controls.percent.markAsDirty();
      this.formObj.controls.freeInterval.markAsDirty();
    });

    this.formObj.form.valueChanges
      .pipe(map(x => {
        return { rate: x.rate, start: x.start, end: x.end,
          positionId: this.freeRateGridService.selectedRow?.positionOwnerId } }),
      distinctUntilChanged((prev, curr) => {
        return prev.rate == curr.rate && prev.start == curr.start && prev.end == curr.end;
      }), debounceTime(500)
        , takeUntil(this.streams$.unsubscribe))
      .subscribe(value => {
        this.recalculatePositionNorma(value, this.freeRateGridService.selectedRow?.positionOwnerId);
        this.setProxyDaysFormValue(value.start, value.end);
      });
  }


  /*/ Запросить пересчет количества рабочих часов для выбранных параметров должности (ставка, даты начала/окончания) */
  private recalculatePositionNorma(value: {rate: number, start: Date, end: Date}, positionId: number) {

    iif(
      () => !positionId,
      of(null),
      this.graphControlService.calculateNormaForPosition$(this.componentSettings.redactionId, positionId,
        value.rate, value.start, value.end)
    )
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(value => this.formObj.controls.proxyHours.setValue(value));

  }

  /** Установить значение поля формы "Количесво календарных дней" */
  @traceFunc()
  private setProxyDaysFormValue(start?: Date, end?: Date) {
    if (this.formObj.controls.start.value && this.formObj.controls.end.value) {
      const days = moment(end).diff(start, 'days') + 1;
      this.formObj.controls.proxyDays.setValue(days);
    }
  }

  /** Обработка выбора штатной единицы */
  public onProxySelected(value: BaseEmployee) {
    this.formObj.controls.baseEmployee.setValue(value, { emitEvent: true })
    this.formObj.controls.baseEmployee.markAsDirty();
  }

  /** Отрыть диалоговое окно выбора сотрудника */
  public openDialogSelectingEmployee() {
    const dialog = this.selectWorkingEmployeesDialogService.show(
      {
        employeesIds: this.componentSettings.employeeOwnerId ? [this.componentSettings.employeeOwnerId] : [],
        forDate: this.monthRange.end,
        startDate: this.monthRange.start,
        endDate: this.monthRange.end
      }
    )

    const subscribe = dialog.result$
      .pipe(takeUntil(this.streams$.unsubscribe))
      .subscribe(value => {
        subscribe.unsubscribe();

      if (value.type == "ok") {
        const selected = value.selectedItems[0];

        this.onProxySelected(new BaseEmployee(
          selected.id,
          selected.startDate,
          selected.endDate,
          selected.code,
          selected.fio
        ));
      }
    })
  }

  /** Сохранение добавления */
  public saveAdd() {
    if (this.componentSettings.type != 'add') { throw new Error('Для добавления необходим режим "add"'); }

    const selectedFreeInterval = this.formObj.controls.freeInterval.value as FreeRateGridService_RowItem;

    const addMoonlighterParam = new AddMoonlighter$Param(
      this.componentSettings.redactionId,
      this.formObj.controls.start.value,
      this.formObj.controls.end.value,
      this.formObj.controls.comment.value,
      (this.formObj.controls.baseEmployee.value as BaseEmployee)?.ownerId,
      selectedFreeInterval.positionOwnerId,
      selectedFreeInterval.financingSource.id,
      this.formObj.controls.typeEmployment.value.id,
      this.formObj.controls.rate.value,
      this.formObj.controls.milk.value
    )

    let addedStaffUnit$: Observable<StaffUnit>;
    let type: string;

    switch (this.componentSettings.combinationType) {
      case 'moonlighter':
        type = 'Совместительство';
        addedStaffUnit$ = this.dataService.addMoonlighter$(addMoonlighterParam);
        break;
      case 'combination':
        type = 'Совмещение';
        addedStaffUnit$ = this.dataService.addCombinationFree$(
          AddCombinationFree$Param.Create1(addMoonlighterParam, this.formObj.controls.percent.value)
        )
        break;
      case 'uvor':
        type = 'УВОР';
        addedStaffUnit$ = this.dataService.addUvorFree$(
          AddCombinationFree$Param.Create1(addMoonlighterParam, this.formObj.controls.percent.value)
        );
        break;
      default: throw Error("Передан невалидный тип совмещения");
    }

    addedStaffUnit$.pipe(take(1), exErrorHandler(this.displayErrorsService), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.kendoNotificationService.showSuccess({ content: `${!this.componentSettings.combinationType ? "Совместительство" : "Совмещение на свободную ставку"} назначено` });
        this.component.save$.emit(new EditMoonlighterComponent_SaveEvent(this.componentSettings.type, value));
      }
    })
  }

  /** Сохранение редактирования */
  public saveEdit() {
    if (this.componentSettings.type != 'edit') { throw new Error('Для добавления необходим режим "edit"'); }

    const selectedFreeInterval = this.formObj.controls.freeInterval.value as FreeRateGridService_RowItem;
    let editedStaffUnit$: Observable<StaffUnit>;

    const editMoonlighterParam = new EditMoonlighter$Param(
      this.componentSettings.redactionId,
      this.componentSettings.staffUnit.ownerId,
      this.formObj.controls.start.value,
      this.formObj.controls.end.value,
      this.formObj.controls.rate.value,
      this.formObj.controls.comment.value,
      selectedFreeInterval.positionOwnerId,
      selectedFreeInterval.financingSource.id,
      this.formObj.controls.typeEmployment.value.id,
      this.formObj.controls.milk.value,
      this.componentSettings.staffUnit.timestamp
    )

    switch (this.componentSettings.combinationType) {
      case 'moonlighter':
        editedStaffUnit$ = this.dataService.editMoonlighter$(editMoonlighterParam);
        break;
      case 'combination':
        editedStaffUnit$ = this.dataService.editCombinationFree$(
          EditCombinationFree$Param.Create1(editMoonlighterParam, this.formObj.controls.percent.value)
        )
        break;
      case 'uvor':
        editedStaffUnit$ = this.dataService.editUvorFree$(
          EditCombinationFree$Param.Create1(editMoonlighterParam, this.formObj.controls.percent.value));
        break;
      default: throw Error("Передан невалидный тип сотрудника");
    }

    editedStaffUnit$.pipe(take(1), exErrorHandler(this.displayErrorsService), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.kendoNotificationService.showSuccess({ content: 'Внесенные изменения сохранены' })
        this.component.save$.emit(new EditMoonlighterComponent_SaveEvent(this.componentSettings.type, value));
      }
    });
  }

  /** Использовать ли при расчетах процент вместо ставки */
  private isUsePercent(){
    return this.component.settings.combinationType === 'uvor' || this.component.settings.combinationType === 'combination';
  }

  private getEmployeeBase(employee: Employee): BaseEmployee {
    if (!employee) {
      return null;
    }

    return new BaseEmployee(
      employee.ownerId,
      employee.startDate,
      employee.endDate,
      employee.code,
      Employee.fullName(employee)
    );
  }

  /** Получить данные для вывода сотрудника */
  private getEmployeeTextBoxValue(baseEmployee: BaseEmployee): string {
    return !baseEmployee ? '' : `${baseEmployee.code}  ${baseEmployee.fio}`;
  }

  /** Устанавливает допустимый диаппазон. первый и последний день месяца */
  private setMonthRange(month: Date) {
    const monthAsMoment = moment(month).startOf('month');
    this.monthRange = { start: monthAsMoment.toDate(), end: monthAsMoment.endOf('month').startOf('day').toDate() };
  }

  /** Получить данные для формы */
  private getFormDateForEdit(): { freeInterval: FreeRateGridService_RowItem, rate: number, percent: number, start: Date, end: Date, milk: boolean } {
    if (this.componentSettings.type != 'edit') {
      return null;
    }

    const staffUnitStartAsNumber = this.componentSettings.staffUnit.start ? +this.componentSettings.staffUnit.start : Number.MIN_VALUE;
    const staffUnitEndAsNumber = this.componentSettings.staffUnit.end ? +this.componentSettings.staffUnit.end : Number.MAX_VALUE;

    const rate = this.isUsePercent() ? this.componentSettings.staffUnit.percent / 100 : this.componentSettings.staffUnit.rate;

    const suitableArr = this.freeRateGridService.dataSource
      .filter(item => item.financingSource.id == this.componentSettings.staffUnit.financingSourceId &&
        Math.round((item.rate - rate) * 100) / 100 >= 0 &&
        item.positionOwnerId == this.componentSettings.staffUnit.positionId &&
        +item.start <= staffUnitStartAsNumber && +item.end >= staffUnitEndAsNumber)
      .sort((a, b) => a.rate - b.rate);

    if (suitableArr.length == 0) {
      return null;
    }

    return {
      freeInterval: suitableArr[0],
      rate: this.componentSettings.staffUnit.rate,
      percent: this.componentSettings.staffUnit.percent,
      start: this.componentSettings.staffUnit.start,
      end: this.componentSettings.staffUnit.end,
      milk: this.componentSettings.staffUnit.milk
    }
  }

  /** Обработка изменения свободного интервала */
  private onIntervalChange(row: FreeRateGridService_RowItem) {
    this.formObj.controls.freeInterval.setValue(row);
  }

  /** Установка максимальное значение для контрола Количество ставок */
  public getRateMax(currentRate: number): number {
    return Math.min(1, currentRate ?? 1);
  }

  /** Получить минимальную дату для диаппазона */
  public getMinRangeDate(freeIntervalStart: Date, monthStart: Date) {
    const startFromControlFreeInterval = freeIntervalStart ?? monthStart;
    return new Date(Math.max(+startFromControlFreeInterval, +monthStart));
  }

  /** Получить максимальную дату для диаппазона */
  public getMaxRangeDate(freeIntervalEnd: Date, monthRangeEnd: Date) {
    const endFromControlFreeInterval = freeIntervalEnd ?? monthRangeEnd;
    return new Date(Math.min(+endFromControlFreeInterval, +monthRangeEnd));
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.freeRateGridService.ngOnDestroy();
  }
}

