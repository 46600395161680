<div style="display: flex; flex-direction: column; height: 100%">
  <div>
    <app-covid-journal-toolbar #toolbarComponent
                               class="k-toolbar"
                               (change$)="toolbarChange($event)"
                               [disabled]="toolbarDisabled"
    >
    </app-covid-journal-toolbar>
  </div>
  <div *ngIf="covidJournalToolbarComponentChangeEvent"
       style=" flex: 1; margin-top: 4px"
  >
    <div *ngIf="covidJournalToolbarComponentChangeEvent.values.day == null">
      Выберите день месяца!
    </div>

    <div *ngIf="covidJournalToolbarComponentChangeEvent.values.day != null"
         style="height: 100%"
    >
      <app-covid-register-grid [settings]="gridSettings"
                               style="height: 100%"
                               (change$)="onGridChange($event)"
                               (isEditOrAdd$)="onGridEditOrAdd($event)"
      >
      </app-covid-register-grid>
    </div>
  </div>
</div>
