<kendo-grid *ngIf="graphDataSourceService.source$ | async"
            style="max-height: 100% !important; min-width: 1500px"
            class="graph-grid"
            sortable="true"
            [sort]="graphDataSourceService.state.sort"
            (sortChange)="onSortChange($event)"
            (cellClick)="onCellClick(rowContextMenu, $event)"
            (keydown.delete)= "onDeleteKeyDown()"
            [rowClass]="rowClassCallback"
            appGraphRedactionComparer
            [dataSourceService]="graphDataSourceService"
            [(redactionIdForCompare)]="gridToolbarManagementService.compareRedactionId"
            appGraphCellSelecting
>

  <ng-template kendoGridToolbarTemplate>
    <div style="display: flex; flex-direction: column; width: 100%">
      <app-grid-toolbar-management *ngIf="settings.showToolbarManagementComponent"
                                   [disabled]="settings.readonly"
                                   [disableSaveButton]="!graphDataSourceService.hasChange"
                                   class="grid-toolbar-element"
      >
      </app-grid-toolbar-management>

      <div *ngIf="gridToolbarManagementService.isEditing$ | async"
           style="display: flex; flex-direction: row;"
           class="grid-toolbar-element"
      >
        <app-proxy-button-panel *ngIf="gridToolbarManagementService.isEditing$ | async"
                                [month]="gridToolbarManagementService.data.graphTable.month"
                                [subdivisionOwnerId]="settings.openFor.bySubdivisionOwnerId.subdivisionOwnerId"
                                style="flex-grow: 1"
        >
        </app-proxy-button-panel>

        <div *ngIf="gridToolbarManagementService.isEditing$ | async"
             class="button-panel"
             style="display: flex;"
        >
          <button *ngIf="graphEditService.hasLaunch"
                  kendoButton [disabled]="!(graphEditService.selected$ | async)?.graphDays"
                  (click)="graphEditService.onSubtractLunchBtnClick()"
                  imageUrl="../../../../../icons/lunch/lunch_green.png"
                  class="image-button"
          >
            Вычитать обед
          </button>
          <button *ngIf="graphEditService.hasLaunch"
                  kendoButton [disabled]="!(graphEditService.selected$ | async)?.graphDays"
                  (click)="graphEditService.onSetNotLunchBtnClick()"
                  imageUrl="../../../../../icons/lunch/lunch_red.png"
                  class="image-button"
          >
            Учитывать обед
          </button>
          <button *ngIf="graphEditService.isFlexDinner"
                  kendoButton
                  [disabled]="!(graphEditService.selected$ | async)?.graphDays"
                  (click)="onFlexDinnerBtnClick()"
                  [icon]="'gear'">
            Обед
          </button>

          <button kendoButton
                  [disabled]="!(graphEditService.selected$ | async)?.graphDays"
                  [icon]="'apply-format'"
                  title="Очистить выделенные ячейки"
                  class="panel-button"
                  (click)="graphEditService.clearCells()"
          >
            Очистить
          </button>

        </div>
      </div>

      <app-day-deviation-panel *ngIf="gridToolbarManagementService.isEditing$ | async"

      >
      </app-day-deviation-panel>

      <app-time-interval-panel *ngIf="gridToolbarManagementService.isEditing$ | async"
                               [subdivisionOwnerId]="settings.openFor.bySubdivisionOwnerId.subdivisionOwnerId"
                               [disabled]="timeIntervalPanelComponentService.disable"
                               class="grid-toolbar-element"
                               style="display: flex; justify-content: space-between"
      >
      </app-time-interval-panel>
    </div>
  </ng-template>

  <kendo-grid-column title=""
                     [width]="5"
                     class="no-padding"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="(dataItem.staffUnit.asObservable.stream$ | async)?.isExternal"
           class="icon-staff-unit-external"></div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title=""
                     [width]="30"
                     class="no-padding"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem>
      <img *ngIf="dataItem.imageUrl"
           kendoTooltip [tooltipTemplate]="tooltipTemplate"
           position="right"
           title=""
           class="staff-unit-img"
           (mouseover)="dataForTooltip = dataItem"
           [src]="dataItem.imageUrl"/>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Должность"
                     field="staffUnit.occupation.name"
                     sortable="true"
                     class="no-padding has-cell-wrap unselectable"
                     headerClass="graph-column-header"  >
    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div appCellSelecting [rowIndex]="rowIndex" [columnIndex]="columnIndex"
           [cellModel]="{
                     rowModel: dataItem,
                     cellModel: dataItem.staffUnit.occupation.name,
                     rowPropertyName: 'staffUnit.occupation.name'
                     }"
           class="content-center"
           style="min-height: 3em; padding-left: 2px"
      >
        <div appCutLongString
             [lineCount]="3"
             [title]= "(dataItem.staffUnit.asObservable.stream$ | async)?.occupation.name"
        >
          {{(dataItem.staffUnit.asObservable.stream$ | async)?.occupation.name}}
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="ФИО"
                     field="staffUnit.employee.fullName"
                     sortable="true"
                     class="no-padding has-cell-wrap unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div appCellSelecting [rowIndex]="rowIndex" [columnIndex]="columnIndex"
           [cellModel]="{
                     rowModel: dataItem,
                     cellModel: dataItem.staffUnit.employee.fullName,
                     rowPropertyName: 'staffUnit.employee.fullName'
                     }"
           class="content-center"
           style="padding-left: 2px;"
      >
        <div appCutLongString
             [lineCount]="3"
             [title] = "(dataItem.staffUnit.asObservable.stream$ | async)?.employee.fullName"
        >
          {{(dataItem.staffUnit.asObservable.stream$ | async)?.employee.fullName}}
        </div>

      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ставка"
                     field="staffUnit.rate"
                     sortable="true"
                     [width]="50"
                     class="no-padding has-cell-wrap unselectable"
                     headerClass="graph-column-header sort-icon-not-left-margin">

    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="calculateColumnHeaderTemplate;context: {$implicit: 'Ставка'}"></ng-container>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div appCellSelecting [rowIndex]="rowIndex" [columnIndex]="columnIndex"
           [cellModel]="{
                     rowModel: dataItem,
                     cellModel: dataItem.staffUnit.rate,
                     rowPropertyName: 'staffUnit.rate'
                     }"
           class="content-center"
           style="justify-content: center"
      >
        <ng-container *ngIf="dataItem.staffUnit.staffUnitType.id !== staffUnitTypeEnum.Duty">
          {{(dataItem.staffUnit.asObservable.stream$ | async)?.rate | kendoNumber: '0.##'}}
        </ng-container>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="42"
                     class="no-padding has-cell-wrap unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="calculateColumnHeaderTemplate;context: {$implicit: 'Норма'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div appCellSelecting [rowIndex]="rowIndex" [columnIndex]="columnIndex"
           [cellModel]="{
                     rowModel: dataItem,
                     cellModel: dataItem.norm,
                     rowPropertyName: 'norm'
                     }"
      >
        <ng-container *ngTemplateOutlet="calculatedCellTemplateSimple;context: {$implicit: dataItem.norm}"></ng-container>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="42"
                     class="no-padding has-cell-wrap unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="calculateColumnHeaderTemplate;context: {$implicit: 'Факт'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div appCellSelecting [rowIndex]="rowIndex" [columnIndex]="columnIndex"
           [cellModel]="{
                     rowModel: dataItem,
                     cellModel: dataItem.fact,
                     rowPropertyName: 'fact'
                     }"
      >
        <ng-container *ngIf="(dataItem.asObservable.stream$ | async) as stream">
          <ng-container *ngTemplateOutlet="calculatedCellTemplateSimple;context: {$implicit: stream.fact}"></ng-container>
        </ng-container>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="45"
                     class="no-padding has-cell-wrap unselectable"
                     headerClass="graph-column-header">
    <ng-template kendoGridHeaderTemplate>
      <ng-container *ngTemplateOutlet="calculateColumnHeaderTemplate;context: {$implicit: 'Дельта'}"></ng-container>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <div appCellSelecting [rowIndex]="rowIndex" [columnIndex]="columnIndex"
           [cellModel]="{
                     rowModel: dataItem,
                     cellModel: dataItem.delta,
                     rowPropertyName: 'delta'
                     }"
      >
        <ng-container *ngIf="(dataItem.asObservable.stream$ | async) as stream">
          <ng-container *ngTemplateOutlet="calculatedCellTemplate;context: {$implicit: stream.delta}"></ng-container>
        </ng-container>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let day of graphDataSourceService.days; let dayIndex = index"
                     [title]="dayIndex + 1"
                     class="has-cell-wrap no-padding unselectable"
                     headerClass="graph-column-header"
                     [width]="36"
                     [style]="{
                     background: day.type.id == dayTypeEnum.BusinessDay ? null :
                      day.type.id == dayTypeEnum.Weekend ? 'rgba(255, 255, 0, 0.15)' :
                        day.type.id == dayTypeEnum.PreHoliday ? 'rgba(0, 255, 63, 0.15)' :
                         day.type.id == dayTypeEnum.NonWorkingDay ? 'rgba(253, 233, 16, 0.25)' :
                         'rgba(255, 23, 0, 0.15)'
                     }"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
      <app-graph-day-cell [settings]="{staffUnit: dataItem.staffUnit.asObservable.stream$ | async, graphDay: dataItem.graphDays[dayIndex].asObservable.stream$ | async}"
                          appCellSelecting
                          [rowIndex]="rowIndex"
                          [columnIndex]="columnIndex"
                          [title]= "dataItem.graphDays[dayIndex].title$ | async"
                          [cellModel]="{
                             rowModel: dataItem,
                             cellModel: dataItem.graphDays[dayIndex],
                             rowPropertyName: 'graphDays:' + dayIndex}"
      >
      </app-graph-day-cell>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<ng-template #calculateColumnHeaderTemplate let-text>
  <div [title]="text" style="height: 100%; overflow: hidden; text-overflow: ellipsis">
    {{text}}
  </div>
</ng-template>

<ng-template #calculatedCellTemplateSimple let-value>
  <div class="calculated-cell-template">
    <ng-container *ngTemplateOutlet="calculatedCellTemplateProtected;context: {$implicit: value}"></ng-container>
  </div>
</ng-template>

<ng-template #calculatedCellTemplate let-value>
  <div class="calculated-cell-template"
       [ngStyle]="{'background-color': value && value < 0 ? '#4caf5085' : value > 0 ? '#ff000063' : null}"
  >
    <ng-container *ngTemplateOutlet="calculatedCellTemplateProtected;context: {$implicit: value}"></ng-container>
  </div>
</ng-template>

<ng-template #calculatedCellTemplateProtected let-value>
  <div *ngIf="value == null"
       class="k-icon k-i-loading k-font-icon"
       style="color: limegreen;">
  </div>
  <div *ngIf="value">{{value | kendoNumber: '0.##'}}</div>
  <div *ngIf="!value">{{value | kendoNumber: '-'}}</div>
</ng-template>

<ng-template #tooltipTemplate>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div style="text-align: center; font-size: 20px;">{{dataForTooltip.staffUnit.staffUnitType.description}}</div>
    <ng-container *ngTemplateOutlet="dataForTooltip.parent ? tooltipTemplate1 : tooltipTemplate2"></ng-container>
  </div>
</ng-template>

<ng-template #tooltipTemplate1>
  <div style="margin-top: 10px; width: fit-content; display: grid; grid-template-rows: auto auto auto auto; grid-template-columns: auto auto; grid-gap: 10px 20px;">
    <div>За сотрудника:</div>
    <div>{{dataForTooltip.parent.staffUnit.employee.fullName}}</div>
    <div>Дата начала:</div>
    <div>{{dataForTooltip.staffUnit.startDate | kendoDate:'dd.MM.yyy'}}</div>
    <div>Дата окончания:</div>
    <div>{{dataForTooltip.staffUnit.endDate | kendoDate:'dd.MM.yyy'}}</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">Размер выплаты:</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">{{dataForTooltip.staffUnit.percent}} %</div>
  </div>
</ng-template>

<ng-template #tooltipTemplate2>
  <div style="margin-top: 10px; width: fit-content; display: grid; grid-template-rows: auto auto auto; grid-template-columns: auto auto; grid-gap: 10px 20px;">
    <div>Дата начала:</div>
    <div>{{dataForTooltip.staffUnit.startDate | kendoDate:'dd.MM.yyy'}}</div>
    <div>Дата окончания:</div>
    <div>{{dataForTooltip.staffUnit.endDate | kendoDate:'dd.MM.yyy'}}</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">Размер выплаты:</div>
    <div *ngIf="dataForTooltip.staffUnit.percent">{{dataForTooltip.staffUnit.percent}} %</div>
  </div>
</ng-template>

<ng-template #flexDinnerTemplate>
  <div style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: nowrap;">
    <label for="flexDinnerMinutesInput"
           style="align-self: center; margin-right: 10px;"
    >Минуты:</label>
    <kendo-numerictextbox id = flexDinnerMinutesInput
                          min="0"
                          max="120"
                          [(value)]="flexDinnerValue"
                          autoCorrect="true"
                          [format]="'n0'"
                          style="flex-grow: 1;">
    </kendo-numerictextbox>
  </div>
</ng-template>

<kendo-contextmenu #rowContextMenu
                   [items]="graphGridRowContextService.items"
                   (select)="graphGridRowContextService.onSelect($event)"
>
</kendo-contextmenu>
