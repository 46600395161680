import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {GraphCellEditService} from "../../secvices/graph-cell-edit.service";
import {of, race, ReplaySubject, tap} from "rxjs";
import {delay, take, takeUntil} from "rxjs/operators";
import {
  Api1TableCodeControllerService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-table-code-controller.service";
import {AlertService} from "../../../../../../../../../src/app/services/alert.service";
import {EditMoonlighterService} from "../../../services/edit-moonlighter.service";
import {GridToolbarManagementService} from "../../../services/grid-toolbar-management.service";
import {
  EditProxyComponent_ParentStaffUnit,
  EditProxyComponent_SaveEvent,
  EditProxyComponent_Settings
} from "../../../../edit-proxy/edit-proxy.component";
import {EditProxyService} from "../../../services/edit-proxy.service";
import {LableAndTitle_Settings} from "../../../../../Classes/LableAndTitle_Settings";
import {EditDutyComponent_Settings, EditDutyComponent_StaffUnit} from "../../../../edit-duty/edit-duty.component";
import {EditDutyService} from "../../../services/edit-duty.service";
import {
  Api1StaffListSettingsControllerService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service";
import {EditMoonlighterComponent_Settings} from "../../../../edit-moonlighter/i-edit-moonlighter-component";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {trace} from "../../../../../../../../../src/app/modules/trace/operators/trace";
import {StaffUnitTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {DayDeviationEnum} from "../../../../../../../../../src/app/classes/domain/enums/day-deviation.enum";
import {
  Api1StaffUnitTypeControllerService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";

@Component({
  selector: 'app-proxy-button-panel',
  templateUrl: './proxy-button-panel.component.html',
  styleUrls: ['./proxy-button-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@traceClass('ProxyButtonPanelComponent')
export class ProxyButtonPanelComponent implements OnInit, OnDestroy {
  /** Месяц графика */
  @Input() month: Date = null;
  /** Подразделение */
  @Input() subdivisionOwnerId: number = null;

  /** Событие добавления Proxy */
  @Output() addedProxy$ = new EventEmitter<EditProxyComponent_SaveEvent>();

  /** Флаг принципа расчета совмещения */
  public isisComputedCombination: boolean = false;

  /** Использует ли данная клиника функционал "Дежурство" */
  public isUsedDuty: boolean = false;

  /** Использует ли данная клиника функционал "УВОР" */
  public isUsedUvor: boolean = false;

  /** Активна ли кнопка добавления proxy */
  public isProxyButtonDisabled = false;

  /** Активна ли кнопка добавления совмещения на занятую ставку */
  public isCombinationButtonDisabled = false;

  /** Активна ли кнопка добавления УВОР на занятую ставку */
  public isUvorButtonDisabled = false;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(public graphEditService: GraphCellEditService,
              private api1TableCodeControllerService: Api1TableCodeControllerService,
              private alertService: AlertService,
              private editMoonlighterService: EditMoonlighterService,
              private editProxyService: EditProxyService,
              private gridToolbarManagementService: GridToolbarManagementService,
              private editDutyService: EditDutyService,
              private api1SettingsControllerService: Api1StaffListSettingsControllerService,
              private api1StaffUnitTypeService: Api1StaffUnitTypeControllerService,
              private readonly tracerService: TracerServiceBase,
              public readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  @traceFunc()
  ngOnInit(): void {
    if (!this.month) {
      throw new Error('@Input month НЕ передан');
    }
    if (!this.subdivisionOwnerId) {
      throw new Error('@Input subdivisionOwnerId НЕ передан');
    }

    this.api1TableCodeControllerService.isComputedCombination_cached$()
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
        next: value => {
          this.isisComputedCombination = value;
          this.changeDetectorRef.detectChanges();
        }, error: error => {
          this.alertService.defaultAlertOption.information().mod(x => {
            x.message = 'При загрузке информации о типе расчета совмещения произошла ошибка'
          }).showAlert();
        }
      })

    this.api1StaffUnitTypeService.getByIds$([ StaffUnitTypeEnum.Duty, StaffUnitTypeEnum.Uvor])
      .pipe(trace(this.tracerService), takeUntil(this.streams$.unsubscribe),
        tap(x => {
          const usedStaffUnitTypesParameters = x.sort(sut => sut.id);
          this.isUsedDuty = !usedStaffUnitTypesParameters[0].deletedFlag;
          this.isUsedUvor = !usedStaffUnitTypesParameters[1].deletedFlag;
          this.changeDetectorRef.detectChanges();
        })).subscribe();

    //Управление кнопками назначений на занятую ставку (proxy, combination, uvor)
    const proxySupportedStaffUnitTypes = [StaffUnitTypeEnum.Basic, StaffUnitTypeEnum.MoonlighterInner,
      StaffUnitTypeEnum.MoonlighterExternal];
    const combinationSupportedStaffUnitTypes = [StaffUnitTypeEnum.Basic, StaffUnitTypeEnum.MoonlighterInner,
      StaffUnitTypeEnum.MoonlighterExternal];
    const uvorSupportedStaffUnitTypes = [StaffUnitTypeEnum.Basic, StaffUnitTypeEnum.MoonlighterInner,
      StaffUnitTypeEnum.MoonlighterExternal];

    this.graphEditService.selected$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const ioExistsInSelectedCells = value?.graphDays?.some(x => x?.dayDeviation?.id == DayDeviationEnum.ИО);

      this.isProxyButtonDisabled = !value?.row || !!value.row.staffUnit.parentId ||
        !proxySupportedStaffUnitTypes.some(x => x === value.row.staffUnit.staffUnitType.id) ||
        ioExistsInSelectedCells;

      this.isCombinationButtonDisabled = !value.row || !!value.row.staffUnit.parentId ||
        !combinationSupportedStaffUnitTypes.some(x => x === value.row.staffUnit.staffUnitType.id) ||
        ioExistsInSelectedCells;

      this.isUvorButtonDisabled = !value.row || !!value.row.staffUnit.parentId ||
        !uvorSupportedStaffUnitTypes.some(x => x === value.row.staffUnit.staffUnitType.id) ||
        ioExistsInSelectedCells;

      this.changeDetectorRef.detectChanges();
    });
  }

  /** Событие нажатия на добавление Proxy или Combination на занятую ставку*/
  @traceFunc()
  public onAddProxy(combinationType: 'proxy' | 'combination' | 'uvor') {
    this.graphEditService.selected$.pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const parentStaffUnit = value.row.staffUnit;
      if (parentStaffUnit.rate < 0.01) {
        this.alertService.defaultAlertOption.information().mod(x => {
          x.title = 'Предупреждение';
          x.message = "У замещаемого сотрудника ставка равна 0.<br>Назначение заместителя <strong>невозможно.</strong>"
        }).showAlert();
        return;
      }
      let lableAndTitleSettings: LableAndTitle_Settings;
      switch (combinationType) {
        case 'proxy':
          lableAndTitleSettings = new LableAndTitle_Settings("Добавить заместителя", "Замещаемый:", "Заместитель:", "Причина замещения:")
          break;
        case 'combination':
          lableAndTitleSettings = new LableAndTitle_Settings("Добавить совмещение на занятую ставку", "Совмещаемый:", "Совмещающий:", "Причина совмещения:")
          break;
        case "uvor":
          lableAndTitleSettings = new LableAndTitle_Settings("Добавить УВОР на занятую ставку", "Отсутствующий:", "Замещает:", "Причина отсутствия:");
          break;
        default: throw Error("Передан невалиный тип proxy/combination")
      }
      const graphDays = value.graphDays ?? value.row.graphDays.sort();
      this.editProxyService.open(new EditProxyComponent_Settings(
        'add',
        this.month,
        this.gridToolbarManagementService.redaction.id,
        this.subdivisionOwnerId,
        value.row?.staffUnit?.employee?.ownerId,
        null,
        new EditProxyComponent_ParentStaffUnit(parentStaffUnit.ownerId, parentStaffUnit.employee.fullName, parentStaffUnit.startDate, parentStaffUnit.endDate, parentStaffUnit.rate, parentStaffUnit.position.ownerId),
        lableAndTitleSettings,
        this.editProxyService.changeGraphDaysAndReturnDayDeviation(graphDays),
        graphDays[0].day.date,
        graphDays[graphDays.length - 1].day.date,
        combinationType,
        value.row.graphDays.filter(x => x.dayDeviation?.id == DayDeviationEnum.ИО).map(x => x.day.date)));
    });
  }

  /** Событие нажатия на добавление Moonlighter */
  @traceFunc()
  public onAddMoonlighter(combinationType: 'moonlighter' | 'combination' | 'uvor') {
    race( //Использую, так как если нет строк в графике, не бросается событие выделенных ячеек.
      of(null).pipe(delay(20)),
      this.graphEditService.selected$
    ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.editMoonlighterService.open(new EditMoonlighterComponent_Settings(
        'add',
        this.month,
        this.gridToolbarManagementService.redaction.id,
        this.subdivisionOwnerId,
        value?.row?.staffUnit?.employee?.ownerId,
        null,
        combinationType));
    })
  }

  /** Обработка события нажатия на кнопку "Добавить дежурство" */
  @traceFunc()
  public onAddDuty() {
    this.graphEditService.selected$.pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const graphDays = value.graphDays;
      const selectedPeriod = graphDays?.length > 0 ? { start: graphDays[0].day.date, end: graphDays[graphDays.length - 1].day.date } : { start: null, end: null };

      this.editDutyService.open(new EditDutyComponent_Settings(
        'add',
        this.month,
        this.gridToolbarManagementService.redaction.id,
        this.subdivisionOwnerId,
        !value.row ?
          null :
          new EditDutyComponent_StaffUnit(
            value.row.staffUnit.ownerId,
            value.row.staffUnit.employee.fullName,
            value.row.staffUnit.employee.ownerId,
            selectedPeriod.start,
            selectedPeriod.end,
            value.row.staffUnit.rate,
            value.row.staffUnit.position.ownerId,
            value.row.staffUnit.milk,
            '',
            [])
      ));
    });
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
