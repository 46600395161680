<kendo-dialog title="Выбор отклонения"
              [maxHeight]="'90%'"
              [width]="800"
              (close)="onCancel()"
              (keydown.escape)="onCancel()"
>
  <kendo-dialog-actions layout="normal">
    <button kendoButton
            (click)="onCancel()"
    >
      Отмена
    </button>
    <button kendoButton
            primary="primary"
            [disabled]="selectedDayDeviation == null"
            (click)="onOk()"
    >
      Выбрать
    </button>
  </kendo-dialog-actions>
  <app-day-deviation-grid (onceClick)="selectedDayDeviation = $event"
                          (doubleClick)="selectedDayDeviation = $event; onOk()"
  >
  </app-day-deviation-grid>
</kendo-dialog>
