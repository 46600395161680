import {GetGraphModelResult_Directories_StaffUnitType} from "../../../../../Classes/GetGraphModelResult";
import {
  IStaffUnitType,
  StaffUnitType
} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnitType";

export class StaffUnitTypeVM implements Pick<IStaffUnitType, 'id' | 'description'> {
  constructor(
    public id: number,
    public description: string,
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_StaffUnitType): StaffUnitTypeVM {
    return new StaffUnitTypeVM(source.id, source.description)
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_StaffUnitType>): Array<StaffUnitTypeVM> {
    return source.map(item => StaffUnitTypeVM.Create(item));
  }

  public static Create2(source: StaffUnitType): StaffUnitTypeVM{
    return new StaffUnitTypeVM(source.id, source.description);
  }
}
