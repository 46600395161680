<kendo-grid #tuberRegisterGrid
            [kendoGridBinding]="data$ | async"
            (add)="addRow($event)"
            (cancel)="cancelRow($event)"
            (save)="saveRow($event)"
            (edit)="editRow($event)"
            (remove)="removeRow($event)"
            KendoGridExpanded
            columnsPadding="2px 4px"
            style="height: 100%; min-height: 200px"
>

  <ng-template kendoGridToolbarTemplate>
    <div style="width: 100%; display: flex; justify-content: space-between">
      <div>
        <button *ngIf="!isReadOnly"
                kendoGridAddCommand
                primary="true"
                [disabled]="!!form"
        >
          <span class="k-icon k-i-plus k-font-icon"></span>
          Добавить
        </button>
      </div>
    </div>
  </ng-template>

  <kendo-grid-column [width]='60'>
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Тип</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem
                 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
      <span kendoTooltip
            [title]="dataItem.registerTypeName"
            position="bottom"
      >{{ dataItem.registerTypeName }}</span>
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <kendo-combobox #registerType
                      valueField="id"
                      textField="text"
                      style="width: 100%"
                      [data]="service.registerTypes"
                      [popupSettings]="{animate: false, width: 300}"
                      [formControl]="form.controls.registerType"
                      [disabled]="!form"
                      [value]="currentRegisterTypeId"
                      valuePrimitive="true"
                      [clearButton]="false"
                      [spellcheck]="false"
                      [allowCustom]="false"
                      (valueChange)="changeRegisterType($event, tuberRegisterGrid)">
      </kendo-combobox>
      <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: registerType.container.element.nativeElement.parentElement, control: form.controls.registerType, noWrap: true }"></ng-container>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column *ngIf="service.controlVisibilityParams.diseaseNumber"
                     [width]="60">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Номер ИБ</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.diseaseNumber }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.diseaseNumber.enabled; else unavailableBase">
        <input #diseaseNumber
              [autofocus]="true"
              kendoTextBox
              [formControl]="form.controls.diseaseNumber"
              [maxLength]="50"
        />
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: diseaseNumber, control: form.controls.diseaseNumber, noWrap: true }"></ng-container>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.patientFio"
                     [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">ФИО пациента</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.patientFio }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.patientFio.enabled; else unavailableBase">
        <input #patientFio
              kendoTextBox
              [formControl]="form.controls.patientFio"
              [maxLength]="100"
        />
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: patientFio, control: form.controls.patientFio, noWrap: true }"></ng-container>
      </ng-container>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.epidemNumber"
                     [width]="130">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Эпидемиологический №</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.epidemNumber }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.epidemNumber.enabled; else unavailableBase">
        <input  #epidemNumber
                kendoTextBox
                [formControl]="form.controls.epidemNumber"
                [maxLength]="200"
        />
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: epidemNumber, control: form.controls.epidemNumber, noWrap: true }"></ng-container>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.typeServiceId"
                     [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Вид обслуживания</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.typeService?.text }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.typeServiceId.enabled; else unavailableBase">
        <kendo-dropdownlist #typeServiceId
                            valueField="id"
                            textField="text"
                            [defaultItem]="{ id: null, text: 'Выберите...' }"
                            [valuePrimitive]="true"
                            [data]="typesServiceSource"
                            style="width: 100%"
                            [popupSettings]="{animate: false, width: 210}"
                            [formControl]="form.controls.typeServiceId"
        >
        </kendo-dropdownlist>
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: typeServiceId.container.element.nativeElement.parentElement, control: form.controls.typeServiceId, noWrap: true }"></ng-container>
      </ng-container>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.typeResearch"
                     [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Вид исследования</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.customTypeResearch ?? dataItem.typeResearch?.text }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.typeResearch.enabled; else unavailableBase">
        <kendo-combobox #typeResearch
                        valueField="id"
                        textField="text"
                        style="width: 100%"
                        [data]="typesResearchSource"
                        [allowCustom]="true"
                        [valueNormalizer]="valueNormalizer"
                        [popupSettings]="{animate: false, width: 300}"
                        [formControl]="form.controls.typeResearch"
                        [filterable]="true"
                        [kendoDropDownFilter]="filterSettings">
        </kendo-combobox>
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: typeResearch.container.element.nativeElement.parentElement, control: form.controls.typeResearch, noWrap: true }"></ng-container>
      </ng-container>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.typeWork"
                     [width]="150">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Название материала, вид работы</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.customTypeWork ?? dataItem.typeWork?.text }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.typeWork.enabled; else unavailableBase">
        <kendo-combobox #typeWork
                        valueField="id"
                        textField="text"
                        style="width: 100%"
                        [data]="typesTypeWorkSource"
                        [allowCustom]="true"
                        [valueNormalizer]="valueNormalizer"
                        [popupSettings]="{animate: false, width: 300}"
                        [formControl]="form.controls.typeWork"
                        [filterable]="true"
                        [kendoDropDownFilter]="filterSettings">
        </kendo-combobox>
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: typeWork.container.element.nativeElement.parentElement, control: form.controls.typeWork, noWrap: true }"></ng-container>
      </ng-container>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.researchProtocol"
                     [width]="130">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">№ протоколов исследований</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.researchProtocol }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.researchProtocol.enabled; else unavailableBase">
        <input  #researchProtocol
                kendoTextBox
                [formControl]="form.controls.researchProtocol"
                [maxLength]="200"
        />
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: researchProtocol, control: form.controls.researchProtocol, noWrap: true }"></ng-container>
      </ng-container>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="service.controlVisibilityParams.researchCount"
                     [width]="100">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Количество исследований</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.researchCount }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <ng-container *ngIf="form.controls.researchCount.enabled; else unavailableBase">
        <kendo-numerictextbox #researchCount
                              [min]="1"
                              [step]="1"
                              [autoCorrect]="true"
                              [decimals]="0"
                              format="0"
                              [formControl]="form.controls.researchCount"
                              style="width: 100%"
        >
        </kendo-numerictextbox>
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: researchCount.numericInput, control: form.controls.researchCount, noWrap: true }"></ng-container>
      </ng-container>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="100">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-column-stl">Длительность, мин.</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.log.operatingTime }}
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <div style="display: flex; align-items: center">
        <kendo-numerictextbox #operatingTime
                              [min]="1"
                              [step]="1"
                              [autoCorrect]="true"
                              [decimals]="0"
                              format="0"
                              required
                              [formControl]="form.controls.operatingTime"
        >
        </kendo-numerictextbox>
        <ng-container *ngTemplateOutlet="validationTooltip; context: { anchor: operatingTime.numericInput, control: form.controls.operatingTime, noWrap: true }"></ng-container>
        <strong style="margin-left: 15px"
                title="Доступно времени"
                [ngStyle]="{ color: form.controls.operatingTimeFree?.value < 0 ? 'red' : 'green' }"
        >
          {{ form.controls.operatingTimeFree?.value | kendoNumber:'0.##' }}
        </strong>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="!isReadOnly" title="" [width]="60">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand
              icon="edit"
              primary="true"
              title="Редактировать"
              [disabled]="!!form"
      ></button>
      <button kendoGridRemoveCommand
              icon="delete"
              title="Удалить"
              [disabled]="!!form"
      ></button>
      <button kendoGridSaveCommand
              primary="true"
              title="Сохранить"
              [disabled]="form?.invalid || form?.pristine"
      >
        <span class="k-icon k-i-save k-font-icon"></span>
      </button>
      <button kendoGridCancelCommand title="Отмена">
        <span class="k-icon k-i-cancel k-font-icon"></span>
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<ng-template #unavailableBase>
  <div>
    <div style="color: gray; font-style: italic; height: 100%; width: 100%;">Недоступно</div>
  </div>
</ng-template>

<ng-template #validationTooltip let-anchor="anchor" let-control="control" let-noWrap="noWrap">
  <kendo-popup *ngIf="control.invalid && control.enabled"
               [anchor]="anchor"
               popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg popup-correction-has-tooltip-class"
  >
    <div [ngStyle]="{ whiteSpace: noWrap ? 'nowrap' : 'none' }"
         [innerHTML]="control.errors?.message">
    </div>
  </kendo-popup>
</ng-template>
