import {Pipe, PipeTransform} from "@angular/core";
import {ArrayHelper} from "../../helpers/arrayHelper";

@Pipe({
  name: 'arraySum'
})
/** Пайп суммирует значение массива */
export class ArraySumPipe implements PipeTransform {
  transform(values: number[]): number {
    return ArrayHelper.sumBy(values, x => x);
  }
}

@Pipe({
  name: 'arraySumBy'
})
/** Пайп суммирует значения массива, полученные по переданной функции получения значения */
export class ArraySumByPipe implements PipeTransform {
  transform<T>(values: T[], by: (value: T) => number): number {
    return ArrayHelper.sumBy(values, by);
  }
}
