import {ChangeDetectionStrategy, Component, computed, input, OnInit} from '@angular/core';
import {
  DateInStaffUnitRangeType,
  IStaffUnit,
} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {ITimeInterval} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval";
import {IDayDeviation} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {IGraphDay} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphDay";
import {ICovidLog} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog";
import {ICovidLog2} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/CovidLog2";
import {IVichLog} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/VichLog";
import {ITuberLog} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/TuberLog";
import {IExcludeMilkLog} from "../../../../../../../../../../../../src/app/classes/domain/POCOs/timesheet/ExcludeMilkLog";


@Component({
  selector: 'app-graph-day-cell2',
  templateUrl: './graph-day-cell2.component.html',
  styleUrl: './graph-day-cell2.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphDayCell2Component implements OnInit {

  /** Тип дня по отношению к диапазону исполнения должности */
  public readonly type = input.required<DateInStaffUnitRangeType>();
  public readonly staffUnit = input.required<Pick<IStaffUnit, 'milk'>>();
  public readonly graphDay = input.required<Pick<IGraphDay, 'dayDeviationCustomValue' | 'substractLunchFlag' | 'flexDinner'>>();
  public readonly timeInterval = input<Pick<ITimeInterval, 'startInterval' | 'endInterval'>>();
  public readonly dayDeviation = input<Pick<IDayDeviation, 'name' | 'shortName' | 'color'>>();
  public readonly hasChange = input<boolean>();

  //Логи в ячейке
  public readonly excludeMilk = input<Partial<IExcludeMilkLog>>();
  public readonly covidLogs = input<Pick<ICovidLog, 'operatingTime'>[]>();
  public readonly covidLog2s = input<Partial<ICovidLog2>[]>();
  public readonly vichLogs = input<Pick<IVichLog, 'operatingTime'>[]>();
  public readonly tuberLogs = input<Pick<ITuberLog, 'operatingTime'>[]>();

  /** Отображать ли молоко */
  protected readonly milk = computed(() => {
    return this.staffUnit()?.milk && this.timeInterval() && !this.excludeMilk();
  })

  /** Функция получает время из лога */
  protected operationTimeGetter(item: Pick<ICovidLog, 'operatingTime'>): number{
    return item.operatingTime;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
  }
}
