import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebApi1Service } from '../web-api1.service';
import { Observable } from 'rxjs';
import { IPatientMovementAdditionallyInfo, PatientMovementAdditionallyInfo } from '../../../../classes/domain/POCOs/timesheet/PatientMovementAdditionallyInfo';
import { PatientMovementAdditionallyInfoType } from '../../../../classes/domain/POCOs/timesheet/PatientMovementAdditionallyInfoType';
import { ISubdivision } from '../../../../classes/domain/POCOs/stafflist/Subdivision';

@Injectable({
  providedIn: 'root',
})
export class Api1PatientMovementAdditionallyInfoControllerService {

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {
  }

  /** Получить */
  public get$(entity: GetPatientMovementAdditionallyInfosRequestDTO): Observable<PatientMovementAdditionallyInfosResponseDTO> {
    return this.httpClient
      .post<PatientMovementAdditionallyInfosResponseDTO>(
        this.webApi1Service.controllers.patientMovementAdditionallyInfoGridControl.actions.get.toString(),
        entity,
      );
  }

  /** Добавить */
  public add$(entity: AddPatientMovementAdditionallyInfoRequestDTO): Observable<PatientMovementAdditionallyInfo> {
    return this.httpClient
      .post<PatientMovementAdditionallyInfo>(this.webApi1Service.controllers.patientMovementAdditionallyInfo.actions.add.toString(), entity);
  }

  /** Сохранить */
  public save$(entity: SavePatientMovementAdditionallyInfoRequestDTO): Observable<[]> {
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.patientMovementAdditionallyInfo.actions.save.toString(), entity);
  }

  /** Удалить */
  public delete$(id: number): Observable<IPatientMovementAdditionallyInfo> {
    return this.httpClient
      .post<IPatientMovementAdditionallyInfo>(this.webApi1Service.controllers.patientMovementAdditionallyInfo.actions.delete.toString(), { id });
  }

}

export interface GetPatientMovementAdditionallyInfosRequestDTO {
  patientMovementId: number;
  typeId: number;
  includeDeleted: boolean;
}

export interface PatientMovementAdditionallyInfosResponseDTO {
  currentSubdivisionId?: number, // Подразделение, в котором по основной ставке числится заполняющий пользователь 
  rows: PatientMovementAdditionallyInfoRowResponseDTO[]
}

export interface PatientMovementAdditionallyInfoRowResponseDTO {
  additionallyInfo: PatientMovementAdditionallyInfo,
  subdivision: Pick<ISubdivision, 'id' | 'shortName' | 'longName' | 'code'>,
  type: PatientMovementAdditionallyInfoType
}

export type AddPatientMovementAdditionallyInfoRequestDTO = Pick<IPatientMovementAdditionallyInfo, 'patientMovementId' | 'comment' | 'subdivisionId' | 'typeId' | 'number' | 'fio' | 'gender' | 'age' | 'diagnosis'>;
export type SavePatientMovementAdditionallyInfoRequestDTO = Pick<IPatientMovementAdditionallyInfo, 'id' | 'timestamp' | 'comment' | 'subdivisionId' | 'number' | 'fio' | 'gender' | 'age' | 'diagnosis'>