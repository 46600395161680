import { IEntityId } from '../interfaces/IEntityId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IGraphDay extends IEntityId {
  /** Идентификатор записи в таблице, содержащей информацию о редакции и версии данных данной ячейки графика */
  redactionId: number;
  /** Идентификатор штатной единицы, к которой относится данная ячейка графика. FK на таблицу stafflist.StaffUnits */
  staffUnitId: number;
  /** Временной интервал, стоящий в данной ячейке (время работы в данный день). FK на таблицу timesheet_graph.TimeIntervals */
  timeIntervalId: number | null;
  /** Идентификатор отклонения от нормы (отпуска, прогулы, больничные и пр). FK на таблицу timsheet_graph.DayDeviations */
  dayDeviationId: number | null;
  /** Дополнительный параметр, отображаемый в ячейке графика, устанавливаемый в связке с некоторыми отклонениями от нормы (в ПК и др) */
  dayDeviationCustomValue: number | null;
  /** День месяца, к которому относится данная ячейка графика */
  date: Date;
  /**  Флаг, указывающий надо или нет вычитать из продолжительности рабочего дня время обеда */
  substractLunchFlag: boolean;
  /** Размер обеда, устанавливаемого вручную */
  flexDinner: number | null;
}

@classBackend('GraphDay', 'timesheet_graph')
@className('GraphDay')
/** Информация о ячейке графика конкретного сотрудника в конкретный день */
export class GraphDay implements IGraphDay {
  constructor(public id: number,
              public redactionId: number,
              public staffUnitId: number,
              public timeIntervalId: number | null,
              public dayDeviationId: number | null,
              public dayDeviationCustomValue: number | null,
              public date: Date,
              public substractLunchFlag: boolean,
              public flexDinner: number | null
  ) {
  }

  private static _fullComparer: ObjComparer<IGraphDay>;
  /** Сравнение по всем полям */
  public static get fullComparer() {
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer({
        id: true,
        redactionId: true,
        staffUnitId: true,
        timeIntervalId: true,
        dayDeviationId: true,
        dayDeviationCustomValue: true,
        date: ObjComparer.dateComparer,
        substractLunchFlag: true,
        flexDinner: (val1, val2) => val1 == val2
      })
    }

    return this._fullComparer;
  }

  /** Создать по умолчанию. Будут заполнены только переданные данные */
  public static CreateDefault(redactionId: number, staffUnitId: number, date: Date): GraphDay {
    return new GraphDay(
      0,
      redactionId,
      staffUnitId,
      undefined,
      undefined,
      undefined,
      date,
      false,
      undefined)
  }
}
