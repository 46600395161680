import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  OnInit,
  output,
  signal
} from '@angular/core';
import { ContextMenuPopupEvent, ContextMenuSelectEvent, MenuItem } from '@progress/kendo-angular-menu';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import {filter, ReplaySubject, skip, timer} from 'rxjs';
import { delay, map, take, takeUntil, tap } from 'rxjs/operators';
import { LoadingIndicatorService } from '../../../../../../../../../../src/app/services/loading-indicator.service';
import { KendoNotificationService } from '../../../../../../../../../../src/app/services/kendo-notification.service';
import {
  TimeIntervalPanel2ComponentDataSourceService,
  TimeIntervalPanel2ComponentDataSourceServiceBase
} from './services/time-interval-panel2-component-data-source.service';
import { ITimeInterval } from '../../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/TimeInterval';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AddTimeInterval2Component } from './add/add-time-interval2.component';
import {traceFunc} from "../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {exLoadingMessage} from "../../../../../../../../../../src/app/operators/ex-loading-message.operator";
import {readonlyInput} from "../../../../../../../../../../src/app/functions/signal.functions";

/** Время подсветки новых временных интервалов */
const HighlightingTime = 5000;

@Component({
  selector: 'app-time-interval-panel2',
  templateUrl: './time-interval-panel2.component.html',
  styleUrls: [
    './time-interval-panel2.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TimeIntervalPanel2ComponentDataSourceService],
})
@traceClass('TimeIntervalPanel2Component')
export class TimeIntervalPanel2Component implements OnInit, OnDestroy {
  /** Отключена ли панель */
  public disabled = input<boolean>(false);
  /** Сервис источник данных */
  public readonly dataSourceService = input.required<TimeIntervalPanel2ComponentDataSourceServiceBase<any>>();

  /** Событие клика по временному интервалу */
  public readonly timeIntervalClick = output<ITimeInterval>();

  /** Временные интервалы на удаление */
  protected readonly toRemove = signal<number[]>(undefined);
  /** Добавленные временные интервалы. Используется в подсветке */
  protected readonly asNew = signal<number[]>([]);

  /** Данные для контекстного меню */
  protected readonly contextMenuArray: Array<MenuItem> = [{ text: 'Удалить интервал времени' }];

  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(),
  };

  constructor(private readonly tracerService: TracerServiceBase,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly dialogService: DialogService) {

    readonlyInput(this.dataSourceService);
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit() {
    //Управление подсветкой новых.
    this.dataSourceService().dataSource.observe$()
      .pipe(
        skip(1),
        filter(d => d.some(s => s.state === 'added')),
        map(v => v.map(d => d.currentOrOrigin.id)),
        tap(v => this.asNew.set([...this.asNew(), ...v])),
        delay(HighlightingTime),
        tap(v => this.asNew.set([...this.asNew().filter(a => !v.includes(a))])),
        takeUntil(this.streams$.unsubscribe),
      ).subscribe();
  }

  /** Обработка события нажатия на buttonGroup */
  @traceFunc()
  protected onButtonClick(interval: ITimeInterval): void {
    if (!this.toRemove()) {
      this.timeIntervalClick.emit(interval);
      return;
    }

    this.toRemove.set(
      this.toRemove().includes(interval.id)
        ? this.toRemove().filter(v => v !== interval.id)
        : [...this.toRemove(), interval.id]);
  }

  /** Удалить несколько временных интервалов */
  @traceFunc()
  protected remove(): void {
    const toRemove = this.toRemove();

    if (!toRemove) { // Первый клик
      this.toRemove.set([]);
      return;
    }

    //Если второй клик
    this.toRemove.set(undefined);

    if(toRemove.length === 0){ //Если нет выделенных на удаление
      return;
    }

    this.dataSourceService().deleteTimeIntervalLinks$(toRemove)
      .pipe(
        take(1),
        exLoadingMessage(this.loadingIndicatorService, 'Удаление временных интервалов'),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe({
      next: () => {
        this.toRemove.set(undefined);
        this.kendoNotificationService.showSuccess({ content: 'Временные интервалы удалены' });
      },
      error: () => {
        this.toRemove.set(undefined);
        this.kendoNotificationService.showError({ content: 'НЕ получилось удалить временные интервалы' });
      }
    })
  }

  /** Отмена удаления временных интервалов */
  @traceFunc()
  protected cancelRemove(): void {
    this.toRemove.set(undefined);
  }

  /** Добавление временного интервала */
  @traceFunc()
  protected onAdd(): void {
    const ref = this.dialogService.open({
      title: 'Добавить интервал времени',
      content: AddTimeInterval2Component,
      width: '340px',
    });

    const instance = ref.content.instance as AddTimeInterval2Component;

    instance.save.subscribe(ti => {
      ref?.close();

      const existingInterval = this.dataSourceService().dataSource.data
        .find(x => x.startInterval === ti.startInterval && x.endInterval === ti.endInterval);

      if (existingInterval) {
        this.kendoNotificationService.showInfo({content: 'Данный временной интервал уже содержится в списке!'});
        this.asNew.set([...this.asNew(), existingInterval.id]);

        timer(HighlightingTime)
          .pipe(
            tap(() => this.asNew.set(this.asNew().filter(x => x !== existingInterval.id))),
            takeUntil(this.streams$.unsubscribe)
          )
          .subscribe()

        return;
      }

      this.dataSourceService().addTimeIntervalToSubdivision$(ti.startInterval, ti.endInterval)
        .pipe(
          take(1),
          takeUntil(this.streams$.unsubscribe)
        )
        .subscribe({
          next: value => {
            if (!value) {
              this.kendoNotificationService.showError({ content: 'НЕ удалось добавить временной интервал' });
              return;
            }

            this.kendoNotificationService.showSuccess({ content: 'Временной интервал добавлен' });
          }, error: () => {
            this.kendoNotificationService.showError({ content: 'НЕ удалось добавить временной интервал' });
          },
        });
    });

    instance.cancel.subscribe(() => ref?.close());
  }

  /** Событие контекста временного интервала */
  @traceFunc()
  protected contextMenuSelect(e: ContextMenuSelectEvent): void {
    const item: ITimeInterval = e.target.data;

    this.dataSourceService().deleteTimeIntervalLinks$([item.id])
      .pipe(
        take(1),
        exLoadingMessage(this.loadingIndicatorService, 'Удаление временного интервала'),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe({
        next: () => {
          this.kendoNotificationService.showSuccess({ content: 'Временной интервал удален' });
        },
        error: () => {
          this.kendoNotificationService.showError({ content: 'НЕ удалось удалить временной интервал' });
        },
      });
  }

  /** Обработка события предоткрытия окна */
  protected contextMenuOpen(e: ContextMenuPopupEvent): void {
    if (!!this.toRemove() || !e.target.data) {
      e.preventDefault();
    }
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
