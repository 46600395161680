<kendo-splitter *ngIf="form"
                [formGroup]="form"
                orientation="vertical"
                style="height: 100%; width: 100%; border: none;">
  <kendo-splitter-pane style="margin-bottom: 5px;">
    <div id="addOrEditDutyDialogForm">
      <div style="text-align: right;"
           class="vertical-text-center-align"
      >
        Сотрудник:
      </div>
      <div>
        <input kendoTextBox
               class="input-text"
               readonly="true"
               formControlName="employeeFullName"
        >
      </div>
      <div>
        <button *ngIf="disabledDutyEmployeeFild" class="input-text" (click)="openDutyStaffUnitChoiceWindow()">...</button>
      </div>

      <div style="text-align: right;"
           class="vertical-text-center-align"
      >
        Должность:
      </div>
      <div>
        <kendo-dropdownlist [data]="positionList"
                            textField="text"
                            valueField="id"
                            listHeight="200"
                            [disabled]="enabledDutyPositionButton"
                            [filterable] = "true"
                            [kendoDropDownFilter]="filterSettings"
                            formControlName="positionId"
                            style="width: 100%"
        >
        </kendo-dropdownlist>
      </div>
      <div></div>

      <div style="text-align: right"
           class="vertical-text-center-align"
      >
        Дата начала:
      </div>
      <div>
          <kendo-datepicker [readOnlyInput]="true"
                            [min]="minShownDate"
                            [max]="maxShownDate"
                            [disabledDates]="startDateValidator"
                            formControlName="dutyStartDate"
                            style="width: 100%"
          >
            <kendo-datepicker-messages today="Сегодня">
            </kendo-datepicker-messages>
          </kendo-datepicker>

      </div>
      <div></div>

      <div style="text-align: right"
           class="vertical-text-center-align"
      >
        Дата окончания:
      </div>
      <div>
        <kendo-datepicker
          [readOnlyInput]="true"
          [min]="minShownDate"
          [max]="maxShownDate"
          [disabledDates]="endDateValidator"
          formControlName="dutyEndDate"
          style="width: 100%"
        >
          <kendo-datepicker-messages
            today="Сегодня"
          >
          </kendo-datepicker-messages>
        </kendo-datepicker>
      </div>
      <div></div>

      <div style="text-align: right"
           class="vertical-text-center-align">
        Количество часов:
      </div>
        <kendo-numerictextbox
          class="input-text"
          readonly="true"
          [spinners]="false"
          formControlName="proxyHours">
        </kendo-numerictextbox>
      <div></div>

      <div style="text-align: right; line-height: 1.2em"
           class="vertical-text-center-align">
        Количество календарных дней:
      </div>

      <kendo-numerictextbox
        class="input-text"
        readonly="true"
        [spinners]="false"
        format="n0"
        formControlName="proxyDays">
      </kendo-numerictextbox>
      <div></div>

      <div class="vertical-text-center-align"
           style="line-height: 1.2; text-align: right;">
        Участвует в выплате компенсации за молоко:
      </div>
      <input kendoCheckBox
             style="align-self: center;"
             type="checkbox"
             [indeterminate]="false"
             formControlName="milk">
    </div>

    <div style="display: flex; width: 100%">
      <kendo-floatinglabel class="duty-comment"
                               text="Комментарий">
        <textarea kendoTextArea
                  maxlength="100"
                  formControlName="comment"
                  style="width: 100%; resize: none; overflow: auto;"
        ></textarea>
      </kendo-floatinglabel>
    </div>
  </kendo-splitter-pane>

  <kendo-splitter-pane [resizable]="false" style="height: auto; margin-top: 5px;">
    <div class="k-form-buttons"
         style="display: flex; justify-content: flex-end; width: 100%"
    >
      <button kendoButton
              (click)="onCancel()"
      >
        Отмена
      </button>
      <button kendoButton
              primary="true"
              (click)="onSave()"
              [disabled]="!form.valid"
      >
        Сохранить
      </button>
    </div>
  </kendo-splitter-pane>

</kendo-splitter>


<app-staffunit-list-dialog *ngIf="openStaffUnitDialog"
                           (cancel$)="onDutySelectingCancel()"
                           (select$)="onDutySelected($event)"
                           [proxyStartDate]="form.controls.dutyStartDate.value || firstDayOfCurrentMonth"
                           [proxyEndDate]="form.controls.dutyEndDate.value || lastDayOfCurrentMonth"
>
</app-staffunit-list-dialog>
