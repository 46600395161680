<div *ngIf="values"
     style="display: flex; flex-wrap: nowrap;"
>
  <div class="label-control"
  >
    <div>Год:</div>
    <kendo-dropdownlist style="width: auto"
                        [valueField]="'id'"
                        [textField]="'text'"
                        [data]="streams$.years | async"
                        [(value)]="values.year"
                        (valueChange)="onChange()"
                        [disabled]="disabled"
                        [listHeight]="400"
    >
    </kendo-dropdownlist>
  </div>
  <div style="margin-left: 20px"
       class="label-control"
  >
    <div>Месяц:</div>
    <kendo-dropdownlist style="width: 105px"
                        [valueField]="'id'"
                        [textField]="'text'"
                        [data]="streams$.months | async"
                        [(value)]="values.month"
                        (valueChange)="onChange()"
                        [disabled]="disabled"
                        [listHeight]="400"
    >
    </kendo-dropdownlist>
  </div>
  <div *ngIf="inlineDayPickerComponentSettings"
       style="margin-left: 20px;"
       class="label-control"
  >
    <div>День:</div>
    <app-inline-day-picker #daySelector
                           [settings]="inlineDayPickerComponentSettings"
                           (dayChange$)="onDayChange($event)"
                           [disabled]="disabled"
    >
    </app-inline-day-picker>
  </div>
</div>
