import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TableDismissedComponentService} from "../services/table-dismissed-component.service";
import {ITableDismisedComponent} from "../i-table-dismised-component";
import {DissmissedStaffUnitBlockItem} from "../services/table-dismissed-data.service";


@Component({
  selector: 'app-table-dismissed',
  templateUrl: './table-dismissed.component.html',
  styleUrls: ['./table-dismissed.component.css'],
  providers: [TableDismissedComponentService]
})
export class TableDismissedComponent implements ITableDismisedComponent, OnInit {
  /**
   * Событие отмены выбора
   */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Событие выбора
   */
  @Output() public select$: EventEmitter<void> = new EventEmitter<void>();

  public subdivisionId: number;
  public month: Date;
  public redactionId: number;

  dismissedStaffUnitList: Array<DissmissedStaffUnitBlockItem> = [];
  printedStaffUnitOwnerIdList: Array<number> = [];


  constructor(private readonly tableDismissedComponentService: TableDismissedComponentService) {
    tableDismissedComponentService.component = this;
  }

  ngOnInit(): void {
    this.tableDismissedComponentService.ngOnInit();
  }

  print() {
    this.tableDismissedComponentService.print(this.redactionId);
    this.select$.next();
  }

  onClickCancel() {
    this.cancel$.emit();
  }
}
