import {Injectable, OnDestroy} from "@angular/core";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  CustomStorageService, StorageLocationEnum,
  StorageOptions
} from "../../../../../../../../../src/app/services/storages/custom-storage.service";
import {DataSource} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";

/** Хранимый объект */
class StorageObj {
  /**
   * Конструктор
   * @param subdivisionOwnerId Идентификатор подразделения
   * @param yearId Год
   * @param monthId Месяц
   */
  constructor(public readonly subdivisionOwnerId: number,
              public readonly yearId: number,
              public readonly monthId: number) {
  }

  /** Копировать */
  public static Copy(storageObj: StorageObj){
    return !storageObj ? undefined : new StorageObj(storageObj.subdivisionOwnerId, storageObj.yearId, storageObj.monthId)
  }
}

/** Базовый сервис хранения данных в session/local storage */
@Injectable()
export abstract class GraphTableToolbarStorageServiceBase implements OnDestroy{
  /** Источник данных для значения */
  public abstract readonly valueDataSource: DataSource<StorageObj>;

  /** @inheritDoc */
  abstract ngOnDestroy(): void;
}

@Injectable()
@traceClass('GraphTableToolbarStorageService')
export class GraphTableToolbarStorageService extends GraphTableToolbarStorageServiceBase{
  /** @inheritDoc */
  public readonly valueDataSource: DataSource<StorageObj> = new DataSource<StorageObj>({copyValueFn: currentValue => StorageObj.Copy(currentValue)});

  /** Конструктор */
  constructor(private readonly customStorageService: CustomStorageService,
              private readonly traceService: TracerServiceBase) {
    super()

    const storageObj = this.customStorageService.get<StorageObj>(this.storageOptions);
    this.traceService.add2('Данные в хранилище value на момент инициализации', {obj: storageObj})

    this.valueDataSource.setData(storageObj);

    this.valueDataSource.change$.subscribe(value => {
      this.customStorageService.set(this.storageOptions, value);
      this.traceService.add2('Данные в хранилище value изменились', {obj: value});
    });
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy() {
    this.valueDataSource.onDestroy();
  }

  /** Ключ для localStorage */
  private readonly storageOptions: StorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'GraphTableToolbarStorageService',
    null,
    true,
    false
  );
}
