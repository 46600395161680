import {
  Directive,
  ElementRef,
  Input,
  Renderer2
} from '@angular/core';

const lineClamp = '-webkit-line-clamp';

/**
 * Директива ограничивает количество строк в элементе.<br>
 * Если количество строк больше заданного, то добавляет ... в конце.<br>
 * Добавляет в HTMLElement силь display: '-webkit-box', '-webkit-box-orient': 'vertical', overflow: 'hidden', text-overflow: ellipsis а обрезает при помощи {@link lineClamp}.<br>
 * Все происходит на уровне css.<br>
 */
@Directive({
  selector: '[appCutLongString]'
})
export class CutLongStringDirective {
  private _lineCount: number = undefined;
  /** Количество отображаемых строк */
  public get lineCount(){
    return this._lineCount;
  }
  @Input() public set lineCount(value){
    if(value === this._lineCount){
      return;
    }

    this._lineCount = value;
    if(value){
      this.renderer.setStyle(this.element.nativeElement, lineClamp, value);
    } else {
      this.renderer.removeStyle(this.element.nativeElement, lineClamp);
    }

  }

  constructor(private readonly element: ElementRef,
              private readonly renderer: Renderer2) {
    this.renderer.setStyle(this.element.nativeElement, 'display', '-webkit-box');
    this.renderer.setStyle(this.element.nativeElement, '-webkit-box-orient', 'vertical');
    this.renderer.setStyle(this.element.nativeElement, 'overflow', 'hidden');
    this.renderer.setStyle(this.element.nativeElement, 'text-overflow', 'ellipsis');
  }
}
