<div kendoDialogContainer></div>

<kendo-grid class="patient-movements-grid"
            [kendoGridBinding]="dataSourceService.dataSource.data$ | async"
            KendoGridExpanded
            columnsPadding="2px 4px"
            style="height: 100%; min-height: 200px"

            (add)="addRow($event)"
            (edit)="editRow($event)"
            (save)="saveRow($event)"
            (cancel)="closeRow($event.sender, $event.rowIndex)"
            (remove)="delete($event.dataItem)"

            (dbCellClick)="dbCellClick($event)"

            [sortable]="true"
            [sort]="[{ field: 'id', dir: 'desc' }]"

            [pageable]="true"
            [pageSize]="15"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand primary="true" [disabled]="!!form" *ngIf="!isSvod">
      <span class="k-icon k-i-plus k-font-icon"></span>
      Добавить
    </button>
    <button *ngIf="isSvod"
            kendoButton primary="true"
            (click)="onPrintClick()"
    >
      <span class="k-icon k-i-print k-font-icon"></span>
      Печать
    </button>
  </ng-template>

  <kendo-grid-column title="Дата" [field]="fieldsNames.date.toString()" headerClass="wrapped-column-header" [width]="125" [sticky]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.date | kendoDate : 'dd.MM.yyyy' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-datepicker [formControl]="form.controls.dataItem.controls.date"></kendo-datepicker>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Подразделение" [field]="fieldsNames.subdivision.longName.toString()" headerClass="wrapped-column-header" [width]="200" [sticky]="true">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-dropdownlist
                          [data]="subdivisionsData$ | async"

                          valueField="id"
                          textField="longName"
                          [valuePrimitive]="true"
                          [popupSettings]="{ width: 'auto' }"
                          [filterable]="true"

                          [readonly]="isSvod"

                          [formControl]="form.controls.dataItem.controls.subdivisionId"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Кол-во коек" [field]="fieldsNames.numberBeds.toString()" headerClass="wrapped-column-header" [width]="70">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.numberBeds">
      </kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column-group title="Состояло" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column title="ВСЕГО пациентов" headerClass="wrapped-column-header static-color-header patient-movements-grid-column-border-left" class="static-color-cell patient-movements-grid-column-border-left" [width]="95">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{ dataItem?.calculatedFields?.consistedTotal }}
      </ng-template>
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.consistedTotal.value }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedVich.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. пациенты с ВИЧ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedVich">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedCovid.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. пациенты с COVID</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedCovid">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedObservation.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. обсервация</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedObservation">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. взрослые с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedChildOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedChildOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedPregnantBefore22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность до 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedPregnantBefore22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedPregnantAfter22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность после 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedPregnantAfter22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedMen.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedMen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistedWomen.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistedWomen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Госпитализировано" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.receivedMen.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedMen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedWomen.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedWomen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedVich.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. пациенты с ВИЧ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedVich">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedCovid.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. пациенты с COVID</span>
      </ng-template>

      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedCovid">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedObservation.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. обсервация</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedObservation">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. взрослые с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedChildOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedChildOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedChildCommunityAcquiredPneumonia.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с внебольничной пневмонией</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedChildCommunityAcquiredPneumonia">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedPregnantBefore22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность до 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedPregnantBefore22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.receivedPregnantAfter22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность после 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.receivedPregnantAfter22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Перевод в отделение" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.transToDepartmentMen.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transToDepartmentMen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.transToDepartmentWomen.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transToDepartmentWomen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Перевод из отделения" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.transFromDepartmentMen.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transFromDepartmentMen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.transFromDepartmentWomen.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transFromDepartmentWomen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Выбыло" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.droppedOutMen.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.droppedOutMen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.droppedOutWomen.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.droppedOutWomen">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Letalis" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.letalisMale.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.letalisFamale.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Состоит" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column title="ВСЕГО пациентов" [field]="fieldsNames.calculatedFields.consistsTotal.toString()" headerClass="wrapped-column-header static-color-header patient-movements-grid-column-border-left" class="static-color-cell patient-movements-grid-column-border-left" [width]="95">
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.consistsTotal.value }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsVich.toString()" headerClass="wrapped-column-header" [width]="80">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. пациенты с ВИЧ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsVich">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsCovid.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. пациенты с COVID</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsCovid">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsObservation.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. обсервация</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsObservation">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. взрослые с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsChildOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsChildOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsChildCommunityAcquiredPneumonia.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с внебольничной пневмонией</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsChildCommunityAcquiredPneumonia">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsPregnantBefore22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность до 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsPregnantBefore22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsPregnantAfter22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность после 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsPregnantAfter22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsChild.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">дети</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsChild">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.consistsMoms.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мамы</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.consistsMoms">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.calculatedFields.consistsMen.toString()" headerClass="wrapped-column-header static-color-header" class="static-color-cell" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.consistsMen.value }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.calculatedFields.consistsWomen.toString()" headerClass="wrapped-column-header static-color-header" class="static-color-cell" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.consistsWomen.value }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Отказано" title="Свободные места">
    <kendo-grid-column [field]="fieldsNames.freeBedsMale.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">мужчин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.freeBedsMale">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.freeBedsFamale.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">женщин</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.freeBedsFamale">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.calculatedFields.freeBeds.toString()" headerClass="wrapped-column-header static-color-header patient-movements-grid-column-border-left" class="static-color-cell patient-movements-grid-column-border-left" title="Всего" [width]="100">
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.freeBeds.value }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.calculatedFields.freeBedsPercent.toString()" headerClass="wrapped-column-header static-color-header" class="static-color-cell" title="%" [width]="45">
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.freeBedsPercent.value | kendoNumber: "##.#" }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Отказано" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.refusedTotal.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Всего по больнице</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedTotal">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.refusedOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. взрослые с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.refusedChildOrvi.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с ОРВИ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedChildOrvi">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.refusedChildCommunityAcquiredPneumonia.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. дети с внебольничной пневмонией</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedChildCommunityAcquiredPneumonia">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.refusedTotalRoddom.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Всего РОДДОМ</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedTotalRoddom">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.refusedPregnantBefore22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность до 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedPregnantBefore22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.refusedPregnantAfter22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">в т.ч. беременность после 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.refusedPregnantAfter22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Каналы госпитализации (по госпитализированным пациентам) - по больнице" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.calculatedFields.hospitalizationHospitalTotal.toString()" headerClass="wrapped-column-header static-color-header patient-movements-grid-column-border-left" class="static-color-cell patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">ВСЕГО</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.hospitalizationHospitalTotal.value }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationHospital103.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">103</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationHospital103">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationHospitalFlow.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">САМОТЕК</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationHospitalFlow">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationHospitalPlan103.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">ПЛАН (103 поликлиника)</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationHospitalPlan103">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationHospitalPlan.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">ПЛАН (не экстренно)</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationHospitalPlan">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Каналы госпитализации (по госпитализированным пациентам) - родильный дом" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.calculatedFields.hospitalizationRoddomTotal.toString()" headerClass="wrapped-column-header static-color-header patient-movements-grid-column-border-left" class="static-color-cell patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">ВСЕГО</span>
      </ng-template>
      <ng-template kendoGridEditTemplate>
        {{ form.controls.calculatedFields.controls.hospitalizationRoddomTotal.value }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationRoddom103.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">103</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationRoddom103">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationRoddomFlow.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">САМОТЕК</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationRoddomFlow">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationRoddomPlan103.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">ПЛАН (103 поликлиника)</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationRoddomPlan103">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.hospitalizationRoddomPlan.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">ПЛАН (не экстренно)</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.hospitalizationRoddomPlan">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Сведения о транспортабельности" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.transOutpatient.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Амбулаторно</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transOutpatient">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.transSitting.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Транспортировка сидя</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transSitting">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.transLying.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Транспортировка лёжа</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transLying">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.transNontransportable.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Нетранспортабельный</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.transNontransportable">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Операции" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.operationsPlanned.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Плановые</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.operationsPlanned">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.operationsEmergency.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Экстренные</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.operationsEmergency">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Родильный дом" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.roddomBirths.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Количество родов</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.roddomBirths">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.roddomAbortions.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Количество абортов</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.roddomAbortions">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.roddomBefore22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Количество беременных до 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.roddomBefore22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.roddomAfter22Weeks.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Количество беременных после 22 недель</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.roddomAfter22Weeks">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.roddomInGynecology.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Количество больных в гинекологии</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.roddomInGynecology">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.roddomNeonatalResuscitation.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Реанимация новорожденных</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.roddomNeonatalResuscitation">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Кабинет клещевого энцефалита" headerClass="patient-movements-grid-column-border-left">
    <kendo-grid-column [field]="fieldsNames.miteApplied.toString()" headerClass="wrapped-column-header patient-movements-grid-column-border-left" class="patient-movements-grid-column-border-left" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Обратилось в кабинет</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.miteApplied">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.miteImmunoprophylaxis.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Проведено иммунопрофилактики</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.miteImmunoprophylaxis">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="fieldsNames.miteDeleted.toString()" headerClass="wrapped-column-header" [width]="60">
      <ng-template kendoGridHeaderTemplate>
        <span class="vertical-column-header-text">Удалено клещей</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-numerictextbox [spinners]="false" [min]="1" [step]="1" [autoCorrect]="true" [decimals]="0" format="0" [formControl]="form.controls.dataItem.controls.miteDeleted">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column headerClass="wrapped-column-header" [field]="fieldsNames.military.toString()" [width]="60">
    <ng-template kendoGridHeaderTemplate>
      <span class="vertical-column-header-text">Военнослужащие</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="wrapped-column-header" [field]="fieldsNames.fromNewSubjects.toString()" [width]="60">
    <ng-template kendoGridHeaderTemplate>
      <span class="vertical-column-header-text">Пациенты из новых субъектов РФ</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="wrapped-column-header" title="Действия" [width]="120" [sticky]="true">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand
              primary="true"
              title="Редактировать"
              [disabled]="!!form"
              *ngIf="!dataItem.sent || isSvod"
      >
        <span class="k-icon k-i-edit k-font-icon"></span>
      </button>
      <button kendoGridRemoveCommand
              title="Удалить"
              [disabled]="!!form"
              *ngIf="!dataItem.sent"
      >
        <span class="k-icon k-i-delete k-font-icon"></span>
      </button>
      <button kendoGridSaveCommand
              primary="true"
              title="Сохранить"
              [disabled]="form?.invalid || (form?.controls.dataItem.controls.id.value && form?.pristine)"
      >
        <span class="k-icon k-i-save k-font-icon"></span>
      </button>
      <button kendoGridCancelCommand title="Отмена">
        <span class="k-icon k-i-cancel k-font-icon"></span>
      </button>
      <button kendoButton
              primary="true"
              title="Отправить"
              [disabled]="!!form"
              (click)="send(dataItem)"
              *ngIf="!dataItem.sent && !isNew"
      >
        <span class="k-icon k-i-arrow-right k-font-icon"></span>
      </button>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
