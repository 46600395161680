import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { GenderEnum } from '../../enums/gender-enum';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IPatientMovementAdditionallyInfo extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityRowVersion {
  /** Родитель передвижение пациента (к чему и крепится эта таблица) */
  patientMovementId: number;
  /** Подразделенние заполняюшие */
  subdivisionId: number;
  /** Тип пациента */
  typeId: number;
  /** Номер ИБ */
  number: string;
  /** ФИО */
  fio: string;
  /** Пол */
  gender: GenderEnum;
  /** Возраст */
  age: number;
  /** Диагноз */
  diagnosis: string;
}

@classBackend('PatientMovementAdditionallyInfo', 'timesheet')
@className('PatientMovementAdditionallyInfo')
/** Дополнительная информация для раздела "Движение пациентов в учреждении" */
export class PatientMovementAdditionallyInfo implements IPatientMovementAdditionallyInfo {
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public timestamp: [],
              public patientMovementId: number,
              public subdivisionId: number,
              public typeId: number,
              public number: string,
              public fio: string,
              public gender: GenderEnum,
              public age: number,
              public diagnosis: string) {
  }
}
