import { IStaffUnit } from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { DayDeviation } from "src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";

export interface IDayDeviationPanelComponent {
  /** Модель для указания занчения отклонения */
  customValueModel: {
      iDayDeviationClickEvent: IDayDeviationClickEvent,
      maxValue: number,
  }

  /** Отключена ли панель */
  disabled: boolean
}

/** Интерфейс события выбора отклонения */
export interface IDayDeviationClickEvent {
  /** Отклонение */
  dayDeviation: DayDeviation,
  /** Значение */
  customValue: number,
  /** Очищать ли Временной интервал */
  isClearTimeInterval: boolean
}

/** Интерфейс выделения ячейки */
export interface ISelectedForPanelsEvent {
  /** Несколько строк выделено? */
  isMultipleRow: boolean;
  /** Выделенная строка */
  row: {
      /** Продолжительность работы */
      workDayHourDuration: number
  },
  /** Выделенные ячейки */
  readonly cells: {
    /** Временной интервал */
    readonly timeInterval: {
      /** Продолжительность */
      readonly duration: number
    }

    readonly staffUnit: Pick<IStaffUnit, 'executionDutiesFlag'>
  }[],
  /** Есть ли хоть одна ячейка относящаяся к строке типа Дежурство */
  readonly containsDutyRow: boolean,
  /** Есть ли хоть одна ячейка относящаяся к строке типа Совместительство */
  readonly containsCombinationRow: boolean,
  /** Есть ли хоть одна ячейка относящаяся к строке типа УВОР */
  readonly containsUvorRow: boolean,
  /** Возможно ли установить данному StaffUnit отклонение ИО */
  readonly canSetIo: boolean
}

/** Композиция класса DayDeviation для дополнительного поля isDisabled */
export class DayDeviationItem {
  constructor(public readonly dayDeviation: DayDeviation,
              public isDisabled: boolean = false) { }
}
