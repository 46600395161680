import {WorkModeVM} from "./work-mode-view-model.class";
import {GetGraphModelResult_Data_StaffUnit} from "../../../../../Classes/GetGraphModelResult";
import {StaffUnitTypeVM} from "./staff-unit-type-view-model.class";
import {OccupationVM} from "./occupation-view-model.class";
import {EmployeeVm} from "./employee-view-model.class";
import {Helper} from "../helpers/grid-helper.class";
import {PositionVM} from "./position-view-model.class";
import * as moment from "moment";
import {
  IStaffUnit,
  StaffUnit
} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {FinancingSourceVM} from "./financing-source-view-model";
import {GraphGridRowModel} from "./row-and-graph-day-view-model.class";
import {
  AsObservableObj,
  IAsObservable
} from "../../../../../../../../../src/app/classes/as-observable/as-observable-obj";

export class StaffUnitVM implements IAsObservable<StaffUnitVM>, Pick<IStaffUnit, 'ownerId' | 'parentId' | 'executionDutiesFlag' | 'startDate' | 'endDate' | 'rate' | 'percent' | 'milk' | 'comment' | 'timestamp'> {
  constructor(
    public readonly row: GraphGridRowModel,
    public readonly ownerId: number,
    public readonly parentId: number,
    public readonly executionDutiesFlag: boolean,
    public startDate: Date,
    public endDate: Date,
    public rate: number,
    public percent: number,
    public milk: boolean,
    public comment: string,
    public timestamp: [],
    public readonly employee: EmployeeVm,
    public readonly staffUnitType: StaffUnitTypeVM,
    position: PositionVM,
    occupation: OccupationVM,
    workMode: WorkModeVM,
    financingSource: FinancingSourceVM,
  ) {
    if(this.isProxy && (position || occupation || workMode || financingSource)){
      throw new Error('Если isProxy то position, occupation, workMode, financingSource должны быть null');
    }

    if(!this.isProxy){
      this._position = position;
      this._occupation = occupation;
      this._workMode = workMode;
      this._financingSource = financingSource;
    }

  }

  readonly asObservable: AsObservableObj<StaffUnitVM> = new AsObservableObj<StaffUnitVM>(() => this, true);

  private _position: PositionVM = null;
  public get position(): PositionVM{
    return this.isProxy ? this.row.parent.staffUnit.position : this._position;
  }
  public set position(value: PositionVM){
    if(this.isProxy) { throw new Error('НЕ допускается установка position у Proxy')}
    this._position = value;
  }

  private _occupation: OccupationVM = null;
  public get occupation(): OccupationVM{
    return this.isProxy ? this.row.parent.staffUnit.occupation : this._occupation;
  }
  public set occupation(value: OccupationVM){
    if(this.isProxy) { throw new Error('НЕ допускается установка occupation у Proxy')}
    this._occupation = value;
  }

  private _workMode: WorkModeVM = null;
  public get workMode(): WorkModeVM{
    return this.isProxy ? this.row.parent.staffUnit.workMode : this._workMode;
  }
  public set workMode(value: WorkModeVM){
    if(this.isProxy) { throw new Error('НЕ допускается установка workMode у Proxy')}
    this._workMode = value;
  }

  private _financingSource: FinancingSourceVM = null;
  public get financingSource(): FinancingSourceVM{
    return this.isProxy ? this.row.parent.staffUnit.financingSource : this._financingSource;
  }
  public set financingSource(value: FinancingSourceVM){
    if(this.isProxy) { throw new Error('НЕ допускается установка financingSource у Proxy')}
    this._financingSource = value;
  }

  /** Является ли внешним сотрудником */
  public get isExternal(): boolean{
    return Helper.isExternalStaffUnit(this.staffUnitType.id);
  }

  /** Является ли Proxy */
  public get isProxy(): boolean{
    return Helper.isProxy(this);
  }

  /** Является ли сотрудник совместителем */
  public get isEmployment(): boolean{
    return Helper.isEmployment(this);
  }

  /** Проверка, находится ли диаппазон работы в переданном диаппазоне */
  public isInRange(startRange: Date, endRange: Date): boolean{
    const sUStart = this.startDate ? +this.startDate : Number.MIN_SAFE_INTEGER;
    const sUEnd = this.endDate ? +this.endDate : Number.MAX_SAFE_INTEGER;
    const rStart = startRange ? +startRange : Number.MIN_SAFE_INTEGER;
    const rEnd = endRange ? +endRange : Number.MAX_SAFE_INTEGER;

    return sUStart >= rStart && sUEnd <= rEnd;
  }

  /** Проверка, находится ли диаппазон работы в переданном месяце */
  public isInMonth(month: Date): boolean{
    const monthAsMoment = moment(month).startOf('month');
    return this.isInRange(monthAsMoment.toDate(), monthAsMoment.endOf('month').toDate())
  }

  public static Create(
    row: GraphGridRowModel,
    source: GetGraphModelResult_Data_StaffUnit,
    sourceAll: Array<GetGraphModelResult_Data_StaffUnit>,
    staffUnitTypes: Array<StaffUnitTypeVM>,
    positions: Array<PositionVM>,
    workModes: Array<WorkModeVM>,
    occupations: Array<OccupationVM>,
    employees: Array<EmployeeVm>,
    financingSources: Array<FinancingSourceVM>
  ): StaffUnitVM {
    return (() => {
      const staffUnitType = staffUnitTypes.find(x => x.id == source.typeId);

      const position = source.parentId ? null : positions.find(x => x.ownerId == source.positionId);

      const sUVM = new StaffUnitVM(
        row,
        source.ownerId,
        source.parentId,
        source.executionDutiesFlag,
        source.startDate,
        source.endDate,
        source.rate,
        source.percent,
        source.milk,
        source.comment,
        source.timestamp,
        employees.find(x => x.ownerId == source.employeeId),
        staffUnitType,
        position,
        source.parentId ? null : occupations.find(x => x.ownerId == position.occupationId),
        source.parentId ? null : workModes.find(x => x.ownerId == position.workModeId),
        source.parentId ? null : financingSources.find(x => x.id == source.financingSourceId),
      );

      return sUVM;
    })();
  }

  public static Create2(row: GraphGridRowModel, source: StaffUnit, staffUnitType: StaffUnitTypeVM, position: PositionVM,
                        occupation: OccupationVM, employee: EmployeeVm, workMode: WorkModeVM,
                        financingSource: FinancingSourceVM, comment: string, timestamp: []): StaffUnitVM{
    return (() => {
      const sUVM = new StaffUnitVM(
        row,
        source.ownerId,
        source.parentId,
        source.executionDutiesFlag,
        source.startDate,
        source.endDate,
        source.rate,
        source.percent,
        source.milk,
        comment,
        timestamp,
        employee,
        staffUnitType,
        position,
        occupation,
        workMode,
        financingSource,
      );

      return sUVM;
    })();
  }
}
