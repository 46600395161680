<div [title]="disabled == true ? 'Отклонения в данный момент неактивны' : ''" class="button-group-container">
  <kendo-buttongroup [selection]="'single'" look="outline">
    <button *ngFor="let button of dayDeviations$ | async"
            kendoButton
            [disabled]="button.isDisabled"
            [ngStyle]="{
              margin: '1px',
              padding: '2px',
              paddingLeft: '6px',
              paddingRight: '6px',
              minWidth: '32px',
              background: button.dayDeviation.color
            }"
            (click)="onButtonClick(button.dayDeviation)"
    >
      <span class="button-text" [title]="button.dayDeviation.name">
        {{ button.dayDeviation.shortName }}
      </span>
    </button>
  </kendo-buttongroup>
</div>

<app-custom-value *ngIf="customValueModel"
                  [dayDeviation]="customValueModel.iDayDeviationClickEvent.dayDeviation"
                  [(value)]="customValueModel.iDayDeviationClickEvent.customValue"
                  (cancel$)="customValueModel = null"
                  [maxValue]="customValueModel.maxValue"
                  (ok$)="onCustomValueSet($event)"
>
</app-custom-value>
