<kendo-splitter orientation="vertical" style="height: 100%">
  <kendo-splitter-pane [resizable]="false" style="height: auto; margin-bottom: 3px">
    <app-graph-table-toolbar class="k-toolbar"></app-graph-table-toolbar>
  </kendo-splitter-pane>
  <kendo-splitter-pane style="margin-top: 3px">
    <ng-container *ngIf="outletDataSource.data$ | async as outletData">
      <router-outlet (activate)="onOutletActivate($event)">
      </router-outlet>
    </ng-container>
  </kendo-splitter-pane>
</kendo-splitter>

