import {GetGraphModelResult_Directories_Position} from "../../../../../Classes/GetGraphModelResult";
import {Position} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Position";

export class PositionVM {
  constructor(
    public ownerId: number,
    public occupationId: number,
    public workModeId: number,
  ) {
  }

  public static Create(source: GetGraphModelResult_Directories_Position): PositionVM {
    return new PositionVM(source.ownerId, source.occupationId, source.workModeId);
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_Position>): Array<PositionVM> {
    return source.map(item => PositionVM.Create(item));
  }

  public static Create2(source: Position): PositionVM{
    return new PositionVM(source.ownerId, source.occupationId, source.workModeId);
  }
}
