import {
  GetTableModelResult,
  GetTableModelResult_Data_CodeRowInfo,
  GetTableModelResult_Data_CodeRowInfo_TableCellModel,
  GetTableModelResult_Directories_Code,
  GetTableModelResult_Directories_Day,
  GetTableModelResult_Directories_DayType,
  GetTableModelResult_Directories_Employees,
  GetTableModelResult_Directories_Occupation,
  GetTableModelResult_Directories_Position,
  GetTableModelResult_Directories_StaffUnit,
  GetTableModelResult_Directories_StaffUnitType,
  GetTableModelResult_Directories_WorkMode
} from "../../../../../Classes/GetTableModelResult";
import {ExtensionObj} from "../../../../../../../../../src/app/helpers/extensionObj";
import {Helper} from "../../../graph-grid/classes/helpers/grid-helper.class";
import {Guid} from "guid-typescript";
import {CodePanelItem} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-code-controller.service";
import { IconsUrlHelper } from "src/app/helpers/icons-url.helper";
import {StaffUnitTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {IStaffUnitType} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import {DayTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/day-type-enum";

export class TableRowModel{
  public masterStaffUnit: StaffUnit;
  public imageUrl: string;
  public staffUnit: StaffUnit;
  public code: GetTableModelResult_Directories_Code;
  public tableCodeId: number;
  public cellModels: Array<TableCellModel>;
  public initialTableRowValue: number;
  public initialTableRowValue2: number;
  private _tableRowValue: number;
  public get tableRowValue(){return this._tableRowValue;}
  public set tableRowValue(value: number){this._tableRowValue = value || 0;};
  private _percent?: number;
  public get percent(){ return this.code.supportValue2 ? this._percent || 0 : this._percent; }
  public set percent(value: number){ this._percent = this.code.supportValue2 ? value || 0 : value; }
  public isFirstRow: boolean;
  public ownerId: Guid;
  public deletedFlag: boolean;

  public RowHasChange(): boolean{
    return (!this.code.isComputedFlag && Math.abs(this.initialTableRowValue - this.tableRowValue) > 0.000001)
    || (this.code.supportValue2 && Math.abs(this.initialTableRowValue2 - this.percent) > 0.000001)
  }

  public static CreateEmpty(staffUnit: StaffUnit, code: CodePanelItem, days: Array<Day>){

    return new ExtensionObj(new TableRowModel()).modResult( item => {
      item.masterStaffUnit = staffUnit;
      item.staffUnit = staffUnit;
      item.isFirstRow = false;
      item.tableRowValue = 0;
      item.initialTableRowValue = -1;
      item.initialTableRowValue2 = -1;
      item.code = code;
      item.imageUrl = null;
      item.ownerId = null;
      item.deletedFlag = false;
      item.cellModels = days.map(day => {
        const dayWorked = (!staffUnit.startDate || +day.date >= +staffUnit.startDate) &&
          (!staffUnit.endDate || +day.date <= +staffUnit.endDate);
        return TableCellModel.CreateEmpty(day, dayWorked);});
    });
  }

  public static Create(row: GetTableModelResult_Data_CodeRowInfo,
                       staffUnit: StaffUnit, staffUnits: Array<StaffUnit>,
                       codes: Array<GetTableModelResult_Directories_Code>,
                       days: Array<Day>, isFirstRow: boolean, firstRowValue: number) {
    return new ExtensionObj(new TableRowModel()).modResult(item => {
      let code = codes.find(c => c.id == row.codeId);
      let rowStaffunit = staffUnit != null && staffUnit.ownerId == row.staffUnitId ? staffUnit : staffUnits.find(s => s.ownerId == row.staffUnitId);

      item.masterStaffUnit = staffUnit;
      item.imageUrl = isFirstRow ? IconsUrlHelper.getstaffUnitImagePath(rowStaffunit.staffUnitType.id, !!rowStaffunit.parentId) : null;
      item.staffUnit = rowStaffunit;
      item.code = code;
      item.cellModels = row.tableCellModels != null ?
        days.map(day => {
        const cell = row.tableCellModels.find(c => +c.date == +day.date);
        const dayWorked = (!rowStaffunit.startDate || +day.date >= +rowStaffunit.startDate) &&
          (!rowStaffunit.endDate || +day.date <= +rowStaffunit.endDate);
        return cell ? TableCellModel.Create(cell, day, dayWorked) : TableCellModel.CreateEmpty(day, dayWorked);
        }) :
        days.map(day => {
          const dayWorked = (!rowStaffunit.startDate || +day.date >= +rowStaffunit.startDate) &&
            (!rowStaffunit.endDate || +day.date <= +rowStaffunit.endDate);
          return TableCellModel.CreateEmpty(day, dayWorked);});
      item.tableCodeId = row.tableCodeId;
      item.tableRowValue = code.isEqualBaseRowValue ? firstRowValue : row.tableRowValue;
      item.initialTableRowValue = row.tableRowValue;
      item.initialTableRowValue2 = row.percent;
      item.percent = row.percent;
      item.isFirstRow = isFirstRow;
      item.ownerId = row.ownerId;
      item.deletedFlag = false;
    });
  }

  public static CreateArray(source: GetTableModelResult): Array<TableRowModel> {
    let positions = Position.CreateArray(source.directories.positions);
    let occupations = Occupation.CreateArray(source.directories.occupations);
    let workModes = WorkMode.CreateArray(source.directories.workModes);
    let staffUnitTypes = StaffUnitType.CreateArray(source.directories.staffUnitTypes);
    let employees = Employee.CreateArray(source.directories.employees);
    let staffUnits = StaffUnit.CreateArray(source.directories.staffUnits,
      staffUnitTypes, positions, workModes, occupations, employees);
    const days = Day.CreateArray(source.directories.days, source.directories.dayTypes);
    let retList = new Array<TableRowModel>();

    source.datas.map(block => {
      let isFirst = true;
      let firstRowValue = 0;
      let blockStaffUnit = staffUnits.find(s => s.ownerId == block.masterStaffUnitId);

      block.codeRowInfos.sort(x => x.codeId);
      let blockRows = block.codeRowInfos.sort(x => x.codeId).map(row => {
        const element = this.Create(row, blockStaffUnit, staffUnits, source.directories.codes, days, isFirst, firstRowValue);
        if (isFirst){
          isFirst = false;
          firstRowValue = element._tableRowValue;
        }
        return element;
      });

      retList = retList.concat(blockRows
        .sort((x1, x2) => +x1.code.key >= +x2.code.key ? 1 : -1));
    });

    return retList;
  }

  /**
   * Применить изменения
   * Текущее состояние станет стартовым
   */
  public applyChange(){
    this.initialTableRowValue = this.tableRowValue;
    this.initialTableRowValue2 = this.percent;
  }
}

export class TableCellModel {
  public day: Day;
  public cellValue: string;
  public cellColor: string;
  public dayWorked: boolean;
  public tooltipItem: { dayDeviationName: string } =
    {dayDeviationName: ""};

  public static Create(cellModel: GetTableModelResult_Data_CodeRowInfo_TableCellModel, day: Day, dayWorked: boolean) {
    return new ExtensionObj(new TableCellModel()).modResult(item => {
      item.day = day;
      if (!cellModel) {
        item.cellValue = null;
      }
      else if(cellModel.stringValue != null){
        if(cellModel.customValue !== null){
          item.cellValue = cellModel.customValue.toLocaleString('ru-ru',{minimumFractionDigits: 1, maximumFractionDigits: 2} );
        }
        else {
          item.cellValue = cellModel.stringValue
        }
      }
      else item.cellValue = cellModel.numberValue?.toLocaleString('ru-ru',{minimumFractionDigits: 1, maximumFractionDigits: 2} );

      item.cellColor = cellModel?.color ;
      item.dayWorked = dayWorked;
      item.tooltipItem = cellModel.tooltipItem;
    });
  }

  static CreateEmpty(day: Day, dayWorked: boolean) {
    return new ExtensionObj(new TableCellModel()).modResult(item => {
      item.day = day;
      item.cellValue = null;
      item.cellColor = null;
      item.dayWorked = dayWorked;
    });
  }
}

export class Day {
  public date: Date;
  public name: string;
  public color: string;

  public static Create(source: GetTableModelResult_Directories_Day, dayTypes: Array<GetTableModelResult_Directories_DayType>) {
    return new ExtensionObj(new Day()).modResult(item => {
      const dayType = dayTypes.find(d => d.id == source.typeId);
      item.date = source.date;
      switch (dayType.id) {
        case DayTypeEnum.BusinessDay: item.color = null; break;
        case DayTypeEnum.Weekend: item.color = 'rgba(255, 255, 0, 0.15)'; break;
        case DayTypeEnum.PreHoliday: item.color = 'rgba(0, 255, 63, 0.15)'; break;
        case DayTypeEnum.NonWorkingDay: item.color = 'rgba(253, 233, 16, 0.25)'; break;
        default: item.color = 'rgba(255, 23, 0, 0.15)'; break;
      }

      item.name = dayType.name;
    });
  }

  public static CreateArray(days: Array<GetTableModelResult_Directories_Day>, dayTypes: Array<GetTableModelResult_Directories_DayType>) {
    return days.map(day => Day.Create(day, dayTypes));
  }
}

export class StaffUnit {
  ownerId: number;
  startDate: Date;
  endDate: Date;
  rate: number;
  parentId: number;
  percent: number;
  public isExternal: boolean;
  public isProxy: boolean;
  public isEmployment: boolean;
  public isDuty: boolean;
  public staffUnitType: StaffUnitType;
  public position: Position;
  public occupation: Occupation;
  public employee: Employee;
  public workMode: WorkMode;
  public parentEpmloyeeFullName: string;

  /** Является ли сотрудник заместителем */
  public static isProxy(staffUnit: StaffUnit): boolean {
    return !!staffUnit.parentId;
  }

  /** Является ли сотрудник совместителем */
  public static isEmployment(staffUnit: StaffUnit): boolean {
    return !!staffUnit.parentId &&
      (staffUnit.staffUnitType.id == StaffUnitTypeEnum.MoonlighterInner ||
        staffUnit.staffUnitType.id == StaffUnitTypeEnum.MoonlighterExternal)
  }

  /** Является данный сотрудник дежурным */
  public static isDuty(staffUnit: StaffUnit): boolean{
    return staffUnit.staffUnitType.id == StaffUnitTypeEnum.Duty;
  }

  public static Create(source: GetTableModelResult_Directories_StaffUnit,
                       sourceAll: Array<GetTableModelResult_Directories_StaffUnit>,
                       staffUnitTypes: Array<StaffUnitType>,
                       positions: Array<Position>,
                       workModes: Array<WorkMode>,
                       occupations: Array<Occupation>,
                       employees: Array<Employee>) : StaffUnit{
    const instance = new ExtensionObj(new StaffUnit()).modResult(item => {
      item.ownerId = source.ownerId;
      item.parentId = source.parentId;
      item.percent = source.percent;
      item.startDate = source.startDate;
      item.endDate = source.endDate;
      item.rate = source.rate;
      item.staffUnitType = staffUnitTypes.find(x => x.id == source.typeId);
      item.isExternal = Helper.isExternalStaffUnit(item.staffUnitType.id);
      item.isProxy = this.isProxy(item);
      item.isEmployment = this.isEmployment(item);
      item.isDuty = this.isDuty(item);
      let positionId: number;
      if(source.parentId){
        let parent = sourceAll.find(x => x.ownerId == source.parentId)
        while (parent.parentId){
          parent = sourceAll.find(x => x.ownerId == source.parentId)
        }
        positionId = parent.positionId;
        item.parentEpmloyeeFullName = employees.find(x => x.ownerId == parent.employeeId)?.fullName;
      }
      else {
        positionId = source.positionId;
      }

      item.position = positions.find(x => x.ownerId == positionId);
      item.workMode = workModes.find(x => x.ownerId == item.position.workModeId);
      item.occupation = occupations.find(x => x.ownerId == item.position.occupationId);

      item.employee = employees.find(x => x.ownerId == source.employeeId);
    });

    return instance;
  }

  public static CreateArray(source: Array<GetTableModelResult_Directories_StaffUnit>,
                            staffUnitTypes: Array<StaffUnitType>,
                            positions: Array<Position>,
                            workModes: Array<WorkMode>,
                            occupations: Array<Occupation>,
                            employees: Array<Employee>){
    return source.map(item => this.Create(item, source, staffUnitTypes, positions, workModes, occupations, employees));
  }
}

export class Position {
  ownerId: number;
  occupationId: number;
  workModeId: number;

  public static Create(source: GetTableModelResult_Directories_Position): Position {
    return new ExtensionObj(new Position()).modResult(item => {
      item.ownerId = source.ownerId;
      item.occupationId = source.occupationId;
      item.workModeId = source.workModeId;
    });
  }

  public static CreateArray(source: Array<GetTableModelResult_Directories_Position>): Array<Position> {
    return source.map(item => this.Create(item));
  }
}

export class Occupation {
  ownerId: number;
  name: string;

  public static Create(source: GetTableModelResult_Directories_Occupation): Occupation {
    return new ExtensionObj(new Occupation()).modResult(item => {
      item.ownerId = source.ownerId;
      item.name = source.name;
    });
  }

  public static CreateArray(source: Array<GetTableModelResult_Directories_Occupation>): Array<Occupation> {
    return source.map(item => this.Create(item));
  }
}

export class Employee {
  ownerId: number;
  fullName: string;

  public static Create(source: GetTableModelResult_Directories_Employees): Employee {
    return new ExtensionObj(new Employee()).modResult(item => {
      item.ownerId = source.ownerId;
      item.fullName = source.fullName;
    });
  }

  public static CreateArray(source: Array<GetTableModelResult_Directories_Employees>): Array<Employee> {
    return source.map(item => this.Create(item));
  }
}

export class WorkMode {
  ownerId: number;
  name: string;
  preHolidayHourDuration: number;
  workDayHourDuration: number;
  workModeTypeId: number;

  public static Create(source: GetTableModelResult_Directories_WorkMode): WorkMode {
    return new ExtensionObj(new WorkMode()).modResult(item => {
      item.ownerId = source.ownerId;
      item.name = source.name;
      item.preHolidayHourDuration = source.preHolidayHourDuration;
      item.workDayHourDuration = source.workdayHourDuration;
      item.workModeTypeId = source.workModeTypeId;
    });
  }

  public static CreateArray(source: Array<GetTableModelResult_Directories_WorkMode>): Array<WorkMode> {
    return source.map(item => this.Create(item));
  }
}

export class StaffUnitType implements Pick<IStaffUnitType, 'id' | 'description'>{
  id: number;
  description: string;

  public static Create(source: GetTableModelResult_Directories_StaffUnitType): StaffUnitType {
    return new ExtensionObj(new StaffUnitType()).modResult(item => {
      item.id = source.id;
      item.description = source.description;
    });
  }

  public static CreateArray(source: Array<GetTableModelResult_Directories_StaffUnitType>): Array<StaffUnitType> {
    return source.map(item => this.Create(item));
  }
}
