import {
  ArrayDataSourceSelection
} from "../../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {
  RedactionServiceGetResponse_Redaction
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/redactions/redaction-base.service";
import {RedactionGridDataSource} from "./redaction-grid-data-source.service";
import {DataSource} from "../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {pairwise, ReplaySubject, startWith, Subject, switchMap} from "rxjs";
import {filter, map, take, takeUntil} from "rxjs/operators";
import {ArrayHelper} from "../../../../../../../../../src/app/helpers/arrayHelper";

/** Тип параметров */
type GraphTableParamsType = {
  redaction: RedactionServiceGetResponse_Redaction,
  isRedactionIdChanged: boolean,
  useSignalR: boolean,
}

/**
 * Базовый класс выборщика для таблицы редакций.<br>
 * Данный выборщик расширяет {@link ArrayDataSourceSelection} полем {@link paramsDataSource}.<br>
 */
export abstract class RedactionGridDataSourceSelectionBase extends ArrayDataSourceSelection<RedactionServiceGetResponse_Redaction, number>{
  /** Стримы */
  protected streams2$ = {
    unsubscribe: new ReplaySubject<any>(1),
    autoFollowUpToActualChange: new Subject<boolean>(),
  }

  /** Источник данных для получения параметров для загрузки данных в источнике данных(График, Табель и т.д) */
  public abstract readonly paramsDataSource: DataSource<GraphTableParamsType| undefined>;

  private _autoFollowUpToActual: boolean = false;
  /**
   * Автоматически следование за актуальной редакцией.<br>
   * Если true - если появляется новая редакция и расстояние между ними отсутствует, будет переключать на актуальную.<br>
   */
  public get autoFollowUpToActual(){
    return this._autoFollowUpToActual;
  }
  public set autoFollowUpToActual(value: boolean){
    value = !!value;

    if(this._autoFollowUpToActual === value){
      return;
    }

    this.streams2$.autoFollowUpToActualChange.next(value);

    if(!value){ //Если НЕ надо следовать
      return;
    }

    this.selectedItems2.beforeChange$
      .pipe(
        filter(value => value.initiator === 'change' && !!value.currentData[0]),
        map(value => value.currentData[0]),
        filter(value => value.isActual),
        switchMap(before => {
          return this.selectedItems2.change$
            .pipe(
              map(afters => ({
                before: before,
                after: afters[0],
              })),
              take(1)
            )
        }),
        filter(value => {
          if(!value.after){
            return false;
          }

          if(value.before.redaction.id !== value.after.redaction.id){
            return false;
          }

          if(value.after.isActual){
            return false;
          }

          return true;
        }),
        map(() => ArrayHelper.single(this.dataSource.data, x => x.isActual)),

        takeUntil(this.streams2$.autoFollowUpToActualChange),
        takeUntil(this.streams2$.unsubscribe),
      ).subscribe(value => {
        this.setIds([value.redaction.id]);
    })
  }

  /** Конструктор */
  constructor(dataSource: RedactionGridDataSource) {
    super(dataSource);

    this.onInit();
  }

  /** Метод инициализации сервиса. Вызывается в конструкторе */
  protected onInit(): void{

  }

  /** @inheritDoc */
  public onDestroy() {
    super.onDestroy();

    this.paramsDataSource?.onDestroy();
    this.streams2$.unsubscribe.next(null);
    this.streams2$.unsubscribe.complete();
    this.streams2$.autoFollowUpToActualChange.complete();
  }
}

/** Выборщик для таблицы редакций */
export class RedactionGridDataSourceSelection extends RedactionGridDataSourceSelectionBase {
  /** @inheritDoc */
  public readonly paramsDataSource: DataSource<GraphTableParamsType | undefined> = new DataSource<GraphTableParamsType>();

  /** @inheritDoc */
  protected onInit() {
    super.onInit();

    //Трансляция в paramsDataSource
    this.selectedItems2.data$
      .pipe(
        startWith(undefined),
        map(value => !value ? new Array<RedactionServiceGetResponse_Redaction>() : value),
        pairwise(),
        map(value => ({prev: value[0][0], current: value[1][0]})),
      ).subscribe(value => {

      if(!value.current){ //Если выбранной редакции нет
        if(!!this.paramsDataSource.data){
          this.paramsDataSource.setData(undefined);
        }
        return;
      }

      this.paramsDataSource.setData({
        redaction: value.current,
        isRedactionIdChanged: value.prev?.redaction?.id !== value.current?.redaction.id,
        useSignalR: !!value.current.version.isActual && !value.current.version.routeStatus,
      });
    })
  }
}
