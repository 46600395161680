import {Injectable, OnDestroy} from "@angular/core";
import {LoadingIndicatorService} from "../../../../../../../../../src/app/services/loading-indicator.service";
import {AlertService} from "../../../../../../../../../src/app/services/alert.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {KendoNotificationService} from "../../../../../../../../../src/app/services/kendo-notification.service";
import {defer, EMPTY, NEVER, Observable, ReplaySubject, switchMap, tap, throwError} from "rxjs";
import {catchError, finalize, map, take, takeUntil} from "rxjs/operators";
import {exLoadingMessage} from "../../../../../../../../../src/app/operators/ex-loading-message.operator";
import {trace} from "../../../../../../../../../src/app/modules/trace/operators/trace";
import {
  TracerServiceBase
} from "../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {exErrorHandler} from "../../../../../../../../../src/app/operators/ex-error-handler";
import {
  DisplayErrorsService
} from "../../../../../../../../../src/app/components/display-errors/services/display-errors.service";
import {traceClass} from "../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TraceParamEnum
} from "../../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../../../src/app/modules/trace/decorators/param.decorator";
import {IStaffUnit, StaffUnit} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";
import {Employee, IEmployee} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee";
import {IPosition} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/Position";
import {IRedactionBase} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet/RedactionBase";
import {
  EditProxyComponent,
  EditProxyComponent_ParentStaffUnit,
  EditProxyComponent_ProxyStaffUnit,
  EditProxyComponent_Settings
} from "../../../../edit-proxy/edit-proxy.component";
import {IGraph} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/Graph";
import {LableAndTitle_Settings} from "../../../../../Classes/LableAndTitle_Settings";
import {IGraphDay} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/GraphDay";
import {DayDeviationEnum} from "../../../../../../../../../src/app/classes/domain/enums/day-deviation.enum";
import {StaffUnitTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {IDayDeviation} from "../../../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {DialogService} from "@progress/kendo-angular-dialog";
import {DateHelper} from "../../../../../../../../../src/app/helpers/dateHelper";
import {
  EditMoonlighterComponent_Settings,
  EditMoonlighterComponent_StaffUnit
} from "../../../../edit-moonlighter/i-edit-moonlighter-component";
import {IFinancingSource} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/FinancingSource";
import {EditMoonlighterComponent} from "../../../../edit-moonlighter/edit-moonlighter.component";
import {
  EditDutyComponent,
  EditDutyComponent_Settings,
  EditDutyComponent_StaffUnit
} from "../../../../edit-duty/edit-duty.component";

/** Тип дня графика */
type GraphDayType = (Pick<IGraphDay, 'date'> & {
  dayDeviation: IDayDeviation
});

/** Функция получения дней графика родительской строки */
type ParentGraphDayGetterType = () => GraphDayType[];

/** Тип события необходимости установки отклонения у родителя */
type OnParentSetDayDeviationType = {
  start: Date,
  end: Date,
  dayDeviation: IDayDeviation
}

/** Сервис редактирования строк графика */
@Injectable()
@traceClass('GraphRowEdit2Service')
export class GraphRowEdit2Service implements OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>()
  }

  /** Конструктор */
  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly alertService: AlertService,
              private readonly api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly dialogService: DialogService,

              private readonly tracerService: TracerServiceBase) {
  }

  /**
   * Удалить строку
   * @param redactionId идентификатор текущей редакции
   * @param staffUnitOwnerId идентификатор исполнения должности
   * @param timestamp
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public deleteRow$(@traceParam() redactionId: number, @traceParam() staffUnitOwnerId: number, timestamp: any): Observable<void>{
    return this.alertService.defaultAlertOption
      .confirmation()
      .mod(x => {
        x.message = "Вы уверены, что хотите удалить выбранную запись?";
      })
      .showAlert$({
        cancel: {text: 'Отмена', isCancel: true},
        ok: {text: 'Подтвердить', isPrimary: true},
      })
      .pipe(
        switchMap(() => {
          return this.api1GraphControlControllerService.deleteStaffUnit$(redactionId, staffUnitOwnerId, timestamp)
            .pipe(
              trace(this.tracerService),
              exLoadingMessage(this.loadingIndicatorService, 'Удаление исполнения должности'),
              tap(() => this.kendoNotificationService.showSuccess({content: 'Исполнение должности удалено'})),
              catchError(err => {
                this.kendoNotificationService.showError({content: 'Удаление исполнения должности завершилось ошибкой'});
                return throwError(() => err);
              }),
              exErrorHandler(this.displayErrorsService)
            )
        }),
        map(() => {})
      )
  }

  /**
   * Открыть диалоговое окно добавления proxy
   * @param type тип добавляемого proxy
   * @param redaction редакция в которой происходит добавление
   * @param graph график в котором происходит добавление
   * @param parent за какую штатную единицу будет работать сотрудник
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public openAddProxyDialog$(
    @traceParam() type: StaffUnitTypeEnum.MoonlighterInner | StaffUnitTypeEnum.CombinationInner | StaffUnitTypeEnum.Uvor,
    @traceParam() redaction: Pick<IRedactionBase, 'id'>,
    @traceParam() graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>,
    @traceParam() parent: {
      staffUnit: Pick<IStaffUnit, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'rate' | 'typeId' | 'parentId'>,
      position: Pick<IPosition, 'id'>,
      employee: Pick<IEmployee, 'ownerId' | 'lastName' | 'firstName' | 'patronymic'>,
      /** Выделенные ячейки */
      selectedGraphDays: GraphDayType[],
      /** Функция получения всех дней графика */
      allGraphDayGetter: ParentGraphDayGetterType,
    }): Observable<Observable<OnParentSetDayDeviationType>> {

    if (!parent) { throw new Error('Передача Замещаемого обязательна!'); }

    if (!StaffUnit.isSupportedProxy(parent.staffUnit.typeId, parent.staffUnit.parentId, type)){
      throw new Error('Замещаемый НЕ поддерживает назначение Замещающего');
    }

    const selectedDate = (() => {
      const dates = parent.selectedGraphDays.map(x => x.date);

      return {
        min: DateHelper.getMinDateFromDateArray(dates),
        max: DateHelper.getMaxDateFromDateArray(dates),
      }
    })();

    const settings = new EditProxyComponent_Settings(
      'add',
      graph.month,
      redaction.id,
      graph.subdivisionId,
      parent.employee.ownerId,
      undefined,
      new EditProxyComponent_ParentStaffUnit(
        parent.staffUnit.ownerId,
        Employee.fullName(parent.employee),
        parent.staffUnit.startDate,
        parent.staffUnit.endDate,
        parent.staffUnit.rate,
        parent.position.id
      ),
      getLabelAndTitleSettings(),
      this.everyDayDeviationOrUndefined(parent.selectedGraphDays),
      selectedDate.min,
      selectedDate.max,
      this.convertProxyTypeToDialogType(type),
      this.getExcludeDatesFromProxy(parent.allGraphDayGetter()),
    );

    return this._openProxyDialog$(
      settings,
      (start, end) => this.filterGraphDayByRange(parent.allGraphDayGetter(), start, end),
    )

    /** Получить настройки для title и labels */
    function getLabelAndTitleSettings(){
      switch (type) {
        case StaffUnitTypeEnum.MoonlighterInner:
          return new LableAndTitle_Settings('Добавить заместителя', 'Замещаемый:', 'Заместитель:', 'Причина замещения:')
        case StaffUnitTypeEnum.CombinationInner:
          return new LableAndTitle_Settings('Добавить совмещение на занятую ставку', 'Совмещаемый:', 'Совмещающий:', 'Причина совмещения:')
        case StaffUnitTypeEnum.Uvor:
          return new LableAndTitle_Settings('Добавить УВОР на занятую ставку', 'Отсутствующий:', 'Замещает:', 'Причина отсутствия:');
        default: throw Error('out of range')
      }
    }
  }

  /**
   * Отрыть окно редактирования Proxy
   * @param redaction текущая редакция
   * @param graph текущий график
   * @param proxy кого редактируем
   * @param parent за кого работает
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public openEditProxyDialog$(
    @traceParam() redaction: Pick<IRedactionBase, 'id'>,
    @traceParam() graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>,
    @traceParam() proxy: {
      staffUnit: Pick<IStaffUnit, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'timestamp' | 'milk' | 'parentId' | 'percent' | 'rate' | 'comment' | 'typeId'>,
      employee: Pick<IEmployee, 'lastName' | 'firstName' | 'patronymic'>
    },
    @traceParam() parent: {
      staffUnit: Pick<IStaffUnit, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'rate'>,
      position: Pick<IPosition, 'id'>,
      employee: Pick<IEmployee, 'ownerId' | 'lastName' | 'firstName' | 'patronymic'>,
      /** Получить все дни графика */
      allGraphDayGetter: ParentGraphDayGetterType,
    }): Observable<Observable<OnParentSetDayDeviationType>>{

    if(!proxy) { throw new Error('proxy is undefined') }
    if(!parent) { throw new Error('parent is undefined') }
    if(proxy.staffUnit.parentId !== parent.staffUnit.id) { throw new Error('proxy ссылается на другой parent') }

    const settings = new EditProxyComponent_Settings(
      'edit',
      graph.month,
      redaction.id,
      graph.subdivisionId,
      parent.employee.ownerId,
      new EditProxyComponent_ProxyStaffUnit(
        proxy.staffUnit.ownerId,
        Employee.fullName(proxy.employee),
        proxy.staffUnit.startDate,
        proxy.staffUnit.endDate,
        proxy.staffUnit.rate,
        proxy.staffUnit.typeId,
        proxy.staffUnit.milk,
        proxy.staffUnit.comment,
        proxy.staffUnit.timestamp,
        proxy.staffUnit.percent
      ),
      new EditProxyComponent_ParentStaffUnit(
        parent.staffUnit.ownerId,
        Employee.fullName(parent.employee),
        parent.staffUnit.startDate,
        parent.staffUnit.endDate,
        parent.staffUnit.rate,
        parent.position.id
      ),
      new LableAndTitle_Settings(
        "Редактировать заместителя",
        "Замещаемый:",
        "Заместитель:",
        `Причина замещения:`),
      this.everyDayDeviationOrUndefined(this.filterGraphDayByRange(parent.allGraphDayGetter(), proxy.staffUnit.startDate, proxy.staffUnit.endDate)),
      undefined, //проверил, при редактировании НЕ используется
      undefined, //проверил, при редактировании НЕ используется
      this.convertProxyTypeToDialogType(proxy.staffUnit.typeId),
      this.getExcludeDatesFromProxy(parent.allGraphDayGetter()),
    )

    return this._openProxyDialog$(
      settings,
      (start, end) => this.filterGraphDayByRange(parent.allGraphDayGetter(), start, end),
    );
  }

  /**
   * Открыть окно добавления исполнения должности на свободную ставку
   * @param type тип добавляемого исполнения должности
   * @param employeeOwnerId идентификатор сотрудника. Поддерживается передача undefined
   * @param redaction редакция в которой происходит добавление
   * @param graph график в котором происходит добавление
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public openAddNotProxyDialog$(
    @traceParam() type: StaffUnitTypeEnum.MoonlighterInner | StaffUnitTypeEnum.CombinationInner | StaffUnitTypeEnum.Uvor,
    @traceParam() employeeOwnerId: number | undefined,
    @traceParam() redaction: Pick<IRedactionBase, 'id'>,
    @traceParam() graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>): Observable<void>{

    const settings = new EditMoonlighterComponent_Settings(
      'add',
      graph.month,
      redaction.id,
      graph.subdivisionId,
      employeeOwnerId,
      undefined,
      this.convertNotProxyTypeToDialogType(type),
    );

    return this._openNotProxyDialog$(settings);
  }

  /**
   * Открыть окно редактирования НЕ proxy
   * @param redaction редакция в которой происходит редактирование
   * @param graph график в которой происходит редактирование
   * @param row строка которую редактируют
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public openEditNotProxyDialog$(
    @traceParam() redaction: Pick<IRedactionBase, 'id'>,
    @traceParam() graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>,
    @traceParam() row: {
      staffUnit: Pick<IStaffUnit, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'timestamp' | 'milk' | 'parentId' | 'percent' | 'rate' | 'comment' | 'typeId'>,
      employee: Pick<IEmployee, 'id'>,
      position: Pick<IPosition, 'id'>,
      financingSource: Pick<IFinancingSource, 'id'>,
    }): Observable<void> {

    if(row.staffUnit.parentId){ throw new Error('Редактирование Proxy через данный метод НЕ поддерживается'); }
    if(StaffUnit.isBasic(row.staffUnit.typeId)){ throw new Error('Редактирование Основной ставки НЕ поддерживается'); }
    if(StaffUnit.isDuty(row.staffUnit.typeId)){ throw new Error('Редактирование Дежурства НЕ поддерживается'); }

    const componentService = new EditMoonlighterComponent_Settings(
      'edit',
      graph.month,
      redaction.id,
      graph.subdivisionId,
      row.employee.id,
      new EditMoonlighterComponent_StaffUnit(
        row.staffUnit.ownerId,
        row.staffUnit.startDate,
        row.staffUnit.endDate,
        row.staffUnit.rate,
        row.financingSource.id,
        row.staffUnit.typeId,
        row.position.id,
        StaffUnit.isMoonlighter(row.staffUnit.typeId) && row.staffUnit.milk,
        row.staffUnit.comment,
        row.staffUnit.timestamp,
        row.staffUnit.percent),
      this.convertNotProxyTypeToDialogType(row.staffUnit.typeId)
    );

    return this._openNotProxyDialog$(componentService);
  }

  /**
   * Открыть диалоговое окно добавления Дежурство
   * @param redaction редакция в которой происходит добавление
   * @param graph график в котором происходит добавление
   * @param selectedRow выделенная строка
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public openAddDutyDialog$(
    @traceParam() redaction: Pick<IRedactionBase, 'id'>,
    @traceParam() graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>,
    @traceParam() selectedRow: {
      staffUnit: Pick<IStaffUnit, 'id' | 'ownerId' | 'rate' | 'milk'>,
      employee: Pick<IEmployee, 'id' | 'lastName' | 'firstName' | 'patronymic'>,
      position: Pick<IPosition, 'id'>,
      /** Выделенные ячейки */
      selectedGraphDays: Pick<GraphDayType, 'date'>[],
    } | undefined){

    const selectedDates = selectedRow?.selectedGraphDays?.map(x => x.date) ?? [];

    const settings = new EditDutyComponent_Settings(
      'add',
      graph.month,
      redaction.id,
      graph.subdivisionId,
      !selectedRow
        ? undefined
        : new EditDutyComponent_StaffUnit(
          selectedRow.staffUnit.ownerId,
          Employee.fullName(selectedRow.employee),
          selectedRow.employee.id,
          DateHelper.getMinDateFromDateArray(selectedDates),
          DateHelper.getMaxDateFromDateArray(selectedDates),
          selectedRow.staffUnit.rate,
          selectedRow.position.id,
          selectedRow.staffUnit.milk,
          '',
          []
        )
    );

    return this._openDutyDialog$(settings);
  }

  /**
   * Открыть диалог редактирования Дежурства
   * @param redaction редакция в которой происходит редактирование
   * @param graph график в котором происходит редактирование
   * @param row дежурство
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public openEditDutyDialog$(
    @traceParam() redaction: Pick<IRedactionBase, 'id'>,
    @traceParam() graph: Pick<IGraph, 'id' | 'subdivisionId' | 'month'>,
    @traceParam() row: {
      staffUnit: Pick<IStaffUnit, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'timestamp' | 'milk' | 'rate' | 'comment' | 'typeId'>,
      employee: Pick<IEmployee, 'id' | 'lastName' | 'firstName' | 'patronymic'>,
      position: Pick<IPosition, 'id'>
    }): Observable<void>{

    if(!StaffUnit.isDuty(row.staffUnit.typeId)){
      throw new Error('Передано исполнение должности НЕ Дежурство')
    }

    const settings = new EditDutyComponent_Settings(
      'edit',
      graph.month,
      redaction.id,
      graph.subdivisionId,
      new EditDutyComponent_StaffUnit(
        row.staffUnit.ownerId,
        Employee.fullName(row.employee),
        row.employee.id,
        row.staffUnit.startDate,
        row.staffUnit.endDate,
        row.staffUnit.rate,
        row.position.id,
        row.staffUnit.milk,
        row.staffUnit.comment,
        row.staffUnit.timestamp
      )
    );

    return this._openDutyDialog$(settings);
  }

  /** Конвертировать тип исполнения должности в тип диалогового окна */
  private convertProxyTypeToDialogType(staffUnitTypeId: number): 'proxy' | 'combination' | 'uvor'{
    switch (staffUnitTypeId) {
      case StaffUnitTypeEnum.MoonlighterInner:
        return 'proxy';
      case StaffUnitTypeEnum.CombinationInner:
        return 'combination';
      case StaffUnitTypeEnum.Uvor:
        return 'uvor';
      default: throw new Error('staffUnitTypeId out of range');
    }
  }

  /** Конвертировать тип исполнения должности в тип диалогового окна */
  private convertNotProxyTypeToDialogType(staffUnitTypeId: number): 'moonlighter' | 'combination' | 'uvor'{
    switch (staffUnitTypeId){
      case StaffUnitTypeEnum.MoonlighterInner:
        return 'moonlighter';
      case StaffUnitTypeEnum.CombinationInner:
        return 'combination';
      case StaffUnitTypeEnum.Uvor:
        return 'uvor';
      default: throw new Error('staffUnitTypeId out of range');
    }
  }

  /** Внутренний метод открывает диалог proxy */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private _openProxyDialog$(@traceParam() settings: EditProxyComponent_Settings,
                           getParentGraphDay: (start: Date, end: Date) => GraphDayType[]): Observable<Observable<OnParentSetDayDeviationType>>{
    const open$ = defer(() => {
      const dialogRef = this.dialogService
        .open({
          title: settings.lableAndTitle_Settings.title,
          content: EditProxyComponent,
          height: '580px',
          width: '500px'
        })

      const component = dialogRef.content.instance as EditProxyComponent;
      component.settings = settings;

      return component.save$
        .pipe(
          take(1),
          takeUntil(dialogRef.result),
          takeUntil(component.cancel$),
          finalize(() => dialogRef.close()),
        )
    })

    return open$
      .pipe(
        map(value => {
          return defer(() => {
            if (!value.deviation) {
              return EMPTY;
            }

            const parentDayDeviation = this.everyDayDeviationOrUndefined(getParentGraphDay(value.staffUnit.startDate, value.staffUnit.endDate));
            if (parentDayDeviation?.id === value.deviation?.id) {
              return EMPTY;
            }

            return openAlert$(this.alertService)
              .pipe(
                map(() => ({
                  start: value.staffUnit.startDate,
                  end: value.staffUnit.endDate,
                  dayDeviation: value.deviation
                }))
              );
          });
        })
      )

    /** Открыть алерт с подтверждением перезаписи отклонений */
    function openAlert$(alertService: AlertService){
      return alertService.defaultAlertOption.confirmation()
        .mod(x => {
          x.message = `<strong>При добавлении</strong> заместителя <strong>в выбранном интервале времени</strong> обнаружен один из следующих <strong>конфликтов:</strong>
•\tприсутствуют разные причины отклонений;
•\tприсутствуют временные интервалы;
•\tприсутствуют дни, в которых не проставлено отклонение.<br>
В случае нажатия кнопки <strong>"Подтвердить"</strong> у замещаемого в графике <strong>произойдет изменение причины отклонения</strong> на выбранный Вами в поле "Причина замещения/совмещения" (если поле было не заполнено, то данные останутся без изменения)<br>
В случае нажатия кнопки <strong>"Отмена"</strong> у замещаемого данные в графике <strong>останутся без изменений</strong>.`;
        })
        .showAlert$({
          cancel: {text: 'Отмена', isCancel: true},
          ok: {text: 'Подтвердить', isPrimary: true}
        })
    }
  }

  /** Внутренний метод открывает диалог НЕ proxy */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private _openNotProxyDialog$(@traceParam() settings: EditMoonlighterComponent_Settings): Observable<void>{
    const action = settings.type == 'add' ? 'Добавить' : 'Редактировать';
    const type = (() => {
      switch (settings.combinationType) {
        case 'moonlighter':
          return 'совместительство';
        case 'combination':
          return 'совмещение';
        case 'uvor':
          return 'УВОР';
        default:
          throw Error('Передан невалидный тип совмещения');
      }
    })();

    return defer(() => {
      const dialogRef = this.dialogService.open({
        title: `${action} ${type}`,
        content: EditMoonlighterComponent,
        height: '90%',
        width: '950px'
      });

      const component = dialogRef.content.instance as EditMoonlighterComponent;
      component.settings = settings;

      return component.save$
        .pipe(
          take(1),
          map(() => {}),
          finalize(() => dialogRef.close()),
          takeUntil(dialogRef.result),
          takeUntil(component.cancel$)
        )
    });
  }

  /** Внутренний метод открывает диалог Дежурства */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private _openDutyDialog$(@traceParam() settings: EditDutyComponent_Settings): Observable<void>{
    return defer(() => {
      const dialogRef = this.dialogService.open({
        title: `${settings.type == 'add' ? 'Добавление' : 'Редактирование'} дежурства`,
        content: EditDutyComponent,
        height: '500px',
        width: '500px'
      });
      const component = dialogRef.content.instance as EditDutyComponent;
      component.settings = settings;

      return component.save$
        .pipe(
          take(1),
          map(() => {}),
          finalize(() => dialogRef.close()),
          takeUntil(dialogRef.result),
          takeUntil(component.cancel$)
        )
    });
  }

  /** Получить отклонение если все одинаковы, иначе undefined */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private everyDayDeviationOrUndefined(graphDays: GraphDayType[]): IDayDeviation | undefined {
    let dayDeviation: IDayDeviation = undefined;

    for (let graphDay of graphDays) {
      if(!graphDay.dayDeviation){
        return undefined;
      }

      if(!dayDeviation){
        dayDeviation = graphDay.dayDeviation;
        continue;
      }

      if(dayDeviation.id !== graphDay.dayDeviation.id){
        return undefined;
      }
    }

    return dayDeviation;
  }

  /** Получить исключающие даты для pxoxy */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  private getExcludeDatesFromProxy(graphDays: GraphDayType[]): Date[] {
    return graphDays
      .filter(x => x.dayDeviation?.id === DayDeviationEnum.ИО)
      .map(x => x.date)
  }

  /** Фильтровать дни графика по переданному диапазону */
  private filterGraphDayByRange(graphDays: GraphDayType[], startRange: Date, endRange: Date): GraphDayType[]{
    return graphDays
      .filter(x => DateHelper.isExistInRange(x.date, startRange, endRange));
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
