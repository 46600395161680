import {GraphGridNormFactDataSource_Item} from "./graph-grid-nom-fact-data-source.classes";
import {ArrayDataSourceHasId} from "../../../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {ArrayExpanded} from "../../../../../../../../../../../src/app/helpers/arrayHelper";
import {
  TracerServiceBase
} from "../../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";

/** Источник данных для Норма/Факт/Дельта */
@traceClass('GraphGridNormFactDataSource')
export class GraphGridNormFactDataSource extends ArrayDataSourceHasId<GraphGridNormFactDataSource_Item, string>{
  /** Хранилище */
  private readonly _map = new Map<string, GraphGridNormFactDataSource_Item>;


  constructor(private readonly traceService: TracerServiceBase) {
    super(x => x.row.id.uid, undefined, GraphGridNormFactDataSource_Item.comparer.asPropertyCompareFunc(false));

    this.change$.subscribe(items => {
      this.setToMap(items);
    })
  }

  /** Получить */
  public get(staffUnitUId: string): GraphGridNormFactDataSource_Item {
    return this._map.get(staffUnitUId);
  }

  /** Удалить все элементы по списку идентификаторов исполнения должности(все виртуальные строки) */
  @traceFunc()
  public deleteByStaffUnitId(...staffUnitIds: number[]){
    const itemsForDelete = new ArrayExpanded(staffUnitIds)
      .distinct()
      .leftInnerJoinGroupedRight(
        this.data,
        x => x,
        x => x.row.id.staffUnitId,
        (l, r) => r
      ).flatMap(x => x)
      .array;

    this.deleteItems(true, ...itemsForDelete);
  }

  /** Установить данные в {@link _map} */
  private setToMap(items: GraphGridNormFactDataSource_Item[]) {
    this._map.clear();

    for (let item of items) {
      this._map.set(item.row.id.uid, item);
    }
  }


  /** @inheritdoc */
  @traceFunc()
  public onDestroy() {
    super.onDestroy();
    this._map.clear();
  }
}
