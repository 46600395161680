import {Observable, ReplaySubject, Subject} from "rxjs";

/** Интерфейс расширающий объект до возможности работать с ним как с Observable */
export interface IAsObservable<T extends object>{
  /** Как Observable */
  readonly asObservable: AsObservableObj<T>;
}

/**
 * Обвертка на объектом, позволяющая работать с объектом как со стримом.
 * Перед трансляцией стримов обворачивает объект в Proxy. Тем самым, на каждую трансляцию новый объект. Необходимо для angular detectedChange
 */
export class AsObservableObj<T extends object>{
  /** Стрим - объект изменен */
  public readonly change$: Observable<T> = new Subject<T>();

  /** Стрим - тоже что и this.change$ только ReplaySubject */
  public readonly stream$: Observable<T> = new ReplaySubject<T>(1);

  /**
   * Конструктор
   * @param getThis Получение this объекта
   * @param emitImmediately Транслировать ли сразу this
   */
  constructor(private readonly getThis: () => T, emitImmediately: boolean) {
    if(emitImmediately){
      this.onChange();
    }
  }

  /** Сообщить, что объект изменился */
  public onChange(){
    const value = new Proxy(this.getThis(), {});
    (this.change$ as Subject<T>).next(value);
    (this.stream$ as ReplaySubject<T>).next(value);
  }

  /** Завершить отслеживание */
  public complete(){
    (this.change$ as Subject<T>).complete();
    (this.stream$ as ReplaySubject<T>).complete();
  }
}
