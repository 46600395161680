<div class="container">
  <div class="interval-row">
    <div class="interval-part">
      <div>Начало интервала:</div>
      <div class="border">
        <div>
          <div>час.</div>
          <kendo-dropdownlist [data]="startHours()"
                              [(value)]="startSelectedHour"
                              valueField="id"
                              textField="text"
                              class="interval-drop-down-list"
          ></kendo-dropdownlist>
        </div>
        <div>
          <div>мин.</div>
          <kendo-dropdownlist [data]="startMinutes()"
                              [(value)]="startSelectedMinute"
                              valueField="id"
                              textField="text"
                              class="interval-drop-down-list"
          ></kendo-dropdownlist>
        </div>
      </div>
    </div>
    <div class="interval-part">
      <div>Окончание интервала:</div>
      <div class="border">

        <div>
          <div>час.</div>
          <kendo-dropdownlist [data]="endHours()"
                              [(value)]="endSelectedHour"
                              valueField="id"
                              textField="text"
                              class="interval-drop-down-list"
          ></kendo-dropdownlist>
        </div>
        <div>
          <div>мин.</div>
          <kendo-dropdownlist [data]="endMinutes()"
                              [(value)]="endSelectedMinute"
                              valueField="id"
                              textField="text"
                              class="interval-drop-down-list"
          ></kendo-dropdownlist>
        </div>
      </div>
    </div>
  </div>

  @if (!errors()){
    <div class="info-row">
      <div>Справочная информация:</div>
      <div class="border">
        <div>Длительность (всего): {{ timeInterval() | timeIntervalDuration }} час.</div>
        <div>Длительность (ночь):{{ timeInterval() | timeIntervalNightDuration }} час. </div>
      </div>
    </div>
  }

  <div class="button-row">
    <button kendoButton (click)="cancel.emit()">Отмена</button>
    <button kendoButton primary="true"
            (click)="onAddTimeInterval()"
            [disabled]="!!errors()">Добавить
    </button>
    @if (!!errors()){
      <span class="range-error">{{errors()}}</span>
    }
  </div>
</div>
