/** Перечисление отклонений */
export enum DayDeviationEnum {
  /** Служебная командировка */
  К = 1,

  /** Ежегодный основной и дополнительный оплачиваемый отпуск */
  О = 2,

  /** Дополнительный отпуск в связи с обучением с сохранением среднего заработка */
  У = 3,

  /** Временная нетрудоспособность или нетрудоспособность по беременности и родам */
  Б = 4,

  /** Отпуск по уходу за ребенком */
  ОР = 5,

  /** Выходные и нерабочие праздничные дни */
  В = 6,

  /** Неявки с разрешения администрации */
  А = 7,

  /** Неявки по невыясненным причинам (до выяснения обстоятельств) */
  НН = 8,

  /** Прогулы */
  П = 9,

  /** Время простоя по вине работодателя */
  РП = 10,

  /** Время простоя по вине работника */
  ВП = 11,

  /** Время простоя по причинам, не зависящим от работодателя и работника */
  НП = 12,

  /** Исполнение обязанностей на период отсутствия работника */
  ИО = 13,

  /** Отгул */
  ОГ = 14,

  /** Повышение квалификации с отрывом от работы */
  ПК = 15,

  /** Неявки с разрешения администрации для прохождения медицинского осмотра */
  МО = 16,

  /** Отстранение от работы (недопущение к работе) по причинам, предусмотренным законодательством, без начисления заработной платы */
  НБ = 17,

  /** Отстранение от работы (недопущение к работе) с оплатой (пособием) в соответствии с законодательством */
  НО = 18,

  /** Донорский день */
  Д = 19,

  /** Невыходы на время исполнения государственных или общественных обязанностей согласно законодательству */
  Г = 20,

  /** Диспансерные осмотры */
  ДС = 21,

  /** Дополнительные выходные дни (оплачиваемые) */
  ОВ = 22,

  /** Дополнительные выходные дни (без сохранения заработной платы) */
  НВ = 23,

  /** Нерабочий оплачиваемый день */
  НОД = 24,

  /** День голосования */
  ДГ = 25,

  /** Время работы с новой коронавирусной инфекции или подозрением на новую коронавирусную инфекцию */
  CV = 26,

  /** Продолжительность работы в выходные и нерабочие праздничные дни */
  РВ = 27,

  /** Продолжительность работы в выходные и нерабочие праздничные дни - предоставление дополнительного оплачиваемого дня отдыха */
  РВО = 28,

  /** Продолжительность серхурочной работы */
  С = 29,

  /** Дополнительные выходные дни лицам, осуществяющим уход за детьми-инвалидами */
  И = 30,

  /** Дополнительный выходной день за вакцинацию */
  ДВ = 31,

  /** Мобилизация */
  МБ = 32,

  /** Отпуск без сохранения заработной платы в случаях, предусмотренных законодательством (новый код, рассчет как у отпуска)*/
  ОЗ = 33,

  /** Отпуск по беременности и родам (отпуск в связи с усыновлением новорожденного ребенка) */
  Р = 34,

  /** Отпуск без сохранения заработной платы, предоставленный работнику по разрешению работодателя */
  ДО = 35,

  /** Время вынужденного прогула в случае признания увольнения, перевода на другую работу или отстранения от работы незаконными с восстановлением на прежней работе */
  ПВ = 36,

  /** Неоплачиваемый ежегодный дополнительный отпуск*/
  ДБ = 37,
}
