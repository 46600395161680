<kendo-grid [data]="dismissedStaffUnitList"
            kendoGridSelectBy="baseStaffunitOwnerId"
            [(selectedKeys)]="printedStaffUnitOwnerIdList"
            style="flex: 1; max-height: calc(100% - 50px)"
            scrollable="scrollable"
>

  <kendo-grid-checkbox-column [width]="45" >
  </kendo-grid-checkbox-column>

    <kendo-grid-column field="employeeName"
                       title="ФИО">
    </kendo-grid-column>

    <kendo-grid-column title="Дата увольнения"
                       [width]="150"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.dismissedDate | kendoDate :'dd.MM.yyyy'}}</span>
      </ng-template>
    </kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <section>
      <div style="border-bottom: 1px solid gray"
           *ngFor="let staffUnit of dataItem.staffUnitInfos">
        <p style="margin-block-start: 8px; margin-block-end: 8px;"><strong>Тип: </strong> {{ staffUnit.staffUnitType }} </p>
        <p style="margin-block-start: 8px; margin-block-end: 8px;"><strong>Должность: </strong> <span style="margin-left: 5px">{{ staffUnit.occupationName }}</span> ( <span style="margin-left: 5px">{{staffUnit.startDate | kendoDate :'dd.MM.yyyy'}}</span> - <span style="margin-left: 5px">{{staffUnit.endDate | kendoDate :'dd.MM.yyyy'}}</span> )</p>
      </div>
    </section>
  </ng-template>

</kendo-grid>

<div style="display: flex; justify-content: end;">
  <button class="button" kendoButton type="button" primary="true" [disabled]="!printedStaffUnitOwnerIdList.length" (click)="print()">Печать</button>
  <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
</div>
