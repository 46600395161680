import {Injectable, OnDestroy, Output} from "@angular/core";
import {DialogService} from "@progress/kendo-angular-dialog";
import {EditMoonlighterComponent} from "../../edit-moonlighter/edit-moonlighter.component";
import {race, ReplaySubject, Subject} from "rxjs";
import {delay, take, takeUntil} from "rxjs/operators";
import {
  EditMoonlighterComponent_SaveEvent,
  EditMoonlighterComponent_Settings
} from "../../edit-moonlighter/i-edit-moonlighter-component";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {TraceParamEnum} from "../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../src/app/modules/trace/decorators/param.decorator";

@Injectable()
@traceClass('EditMoonlighterService')
export class EditMoonlighterService implements OnDestroy{
  /** Событие добавления Moonlighter */
  @Output() save$: Subject<EditMoonlighterComponent_SaveEvent> = new Subject<EditMoonlighterComponent_SaveEvent>();

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private dialogService: DialogService,
              private tracerService: TracerServiceBase) {
  }

  /** Открыть диалог */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public open(@traceParam({maxLength: 1000}) settings: EditMoonlighterComponent_Settings){
    const action = settings.type == 'add' ? 'Добавить' : 'Редактировать';
    let type : string;
    switch (settings.combinationType) {
      case 'moonlighter':
        type = 'совместительство';
        break;
      case 'combination':
        type = 'совмещение';
        break;
      case 'uvor':
        type = 'УВОР';
        break;
      default:
        throw Error('Передан невалидный тип совмещения');
    }
    const dialogRef = this.dialogService.open({
      title: `${action} ${type}`,
      content: EditMoonlighterComponent,
      height: '90%',
      width: '950px'
    });

    const editMoonlighterComponent = dialogRef.content.instance as EditMoonlighterComponent;

    editMoonlighterComponent.settings = settings;

    const unsubscribeRice = race(this.streams$.unsubscribe, editMoonlighterComponent.cancel$, editMoonlighterComponent.save$)
      .pipe(delay(1));
    editMoonlighterComponent.cancel$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      dialogRef.close();
    })

    editMoonlighterComponent.save$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      dialogRef.close();
      this.save$.next(value);
    })

  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
