import { Injectable, OnDestroy } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { EditEvent, GridComponent, RemoveEvent, SaveEvent } from "@progress/kendo-angular-grid";
import { map, Observable, ReplaySubject, take, takeUntil, startWith } from "rxjs";
import { ArrayDataSourceIEntityId } from "src/app/classes/array-data-sources/data-source";
import { VichLog } from "src/app/classes/domain/POCOs/timesheet/VichLog";
import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { AlertService } from "src/app/services/alert.service";
import { IVichRegisterGridComponent, IVichRegisterGridComponentChangeEvent, IVichRegisterGridComponentForm, IVichRegisterGridComponentFormType, IVichRegisterGridComponentFormValue, VichRegisterComponentGridDataItem } from "../i-vich-register-grid.component";
import { VichRegisterGridComponentDataService } from "./vich-register-grid-component-data.service";
import {ArrayHelper} from "../../../../helpers/arrayHelper";
import {registerFieldEnum} from "../../../../services/webApi/webApi1/controllers/api1-tuber-controller.service";

@Injectable()
export class VichRegisterGridComponentService extends ComponentServiceBase<IVichRegisterGridComponent> implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  private hasChange = false


  private directoryData = new ArrayDataSourceIEntityId<VichRegisterComponentGridDataItem>();

  public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => ({ id: null, text } as IDropDownItem)));

  /** Параметры видимости столбцов журнала (используется в *ngIf столбцов) */
  public controlVisibilityParams: {
    diseaseNumber: boolean,
    patientFio: boolean,
    typeServiceId: boolean,
    typeResearch: boolean,
    typeWork: boolean,
    researchCount: boolean,
    researchProtocol: boolean,
    subdivisionId: boolean,
  };

  /** Используется для назначения строкового читаемого представления типа ВИЧ журнала */
  public registerDescriptorIdTypeNamesMap: Map<number, string> = new Map<number, string>;

  /** Массив используемых данной клиникой типов журналов (Лаборатория, Стационар, ПАО, Параклиника и т.п.) */
  public registerTypes: { id: number, text: string }[];

  constructor(private readonly dataService: VichRegisterGridComponentDataService, private readonly alertService: AlertService) {
    super();
  }

  public onInit(): void {
    this.component.data$ = this.directoryData.data$

    this.dataService
      .getCombineLatestData$([this.component.settings.staffUnitOwnerId], this.component.settings.date)
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe(this.getCombineLatestDataSubscribe.bind(this));

    this.registerTypes = this.component.settings.vichRegisterSettingsArrObj.settingsSets
      .map(x => { return {id: x.registerTypeId, text: x.registerTypeName}});

    ArrayHelper.flatMap(this.component.settings.vichRegisterSettingsArrObj.settingsSets
      .map(x => x.registerTypeSettingsArr.map(y => ({id: y.setId, name: x.registerTypeName}))))
      .forEach(x => this.registerDescriptorIdTypeNamesMap.set(x.id, x.name));

    this.controlVisibilityParams = {
      diseaseNumber: this.getControlVisibility("diseaseNumber"),
      patientFio: this.getControlVisibility("patientFio"),
      typeServiceId: this.getControlVisibility("typeServiceId"),
      typeResearch: this.getControlVisibility("typeResearch"),
      typeWork: this.getControlVisibility("typeWork"),
      researchCount: this.getControlVisibility("researchCount"),
      researchProtocol: this.getControlVisibility("researchProtocol"),
      subdivisionId: this.getControlVisibility("subdivisionId")
    };
  }

  private getCombineLatestDataSubscribe([typesResearchSource, typesServiceSource, typesTypeWorkSource, logs, subdivisions, canAddEditRemove]) {
    this.component.typesResearchSource = typesResearchSource;
    this.component.typesServiceSource = typesServiceSource;
    this.component.filteredTypeWorks = typesTypeWorkSource;
    this.component.typesTypeWorkSource = typesTypeWorkSource;
    this.component.subdivisionsSource = subdivisions;
    this.component.isReadOnly = !canAddEditRemove;

    let gridData = logs.map(log => this.vichLogToRegisterComponentGridDataItem(log))

    this.directoryData.setData(gridData)
  }

  /** Получить параметр disabled для контрола по его наименованию */
  private getControlVisibility(fieldName: IVichRegisterGridComponentFormType){
    return !this.component.settings.vichRegisterSettingsArrObj?.missedFieldIds?.some(x => registerFieldEnum[x] == fieldName);
  }

  private vichLogToRegisterComponentGridDataItem(log: VichLog) {
    return new VichRegisterComponentGridDataItem(
      log.id,
      log.descriptorId,
      this.registerDescriptorIdTypeNamesMap.get(log.descriptorId),
      log,
      this.component.subdivisionsSource.find(s => s.ownerId === log.subdivisionId),
      this.component.typesServiceSource.find(t => t.id === log.typeServiceId),
      this.component.typesResearchSource.find(t => t.id === log.typeResearchId) ?? null,
      this.component.typesTypeWorkSource.find(t => t.id === log.typeWorkId) ?? null
    )
  }

  private createFormGroup(
    id: number,
    staffUnitId: number,
    diseaseNumber: string,
    patientFio: string,
    typeServiceId: number,
    typeResearch: IDropDownItem,
    typeWork: IDropDownItem,
    researchCount: number,
    researchProtocol: string,
    subdivisionId: number,
    operatingTime: number,
    versionSet: RegisterTypeVersionSettings,
    registerTypeId: number,
    actionType: 'add' | 'edit'
  ) {
    const requiredValidator = (control: AbstractControl<any, any>) =>
      !control.value ? { 'message': 'Поле должно быть заполнено' } : null;

    const thisVersionRegisterFieldMap = new Map<string, VichRegisterFieldSet>();

    versionSet?.registerFieldSet
      .forEach(fieldSet => thisVersionRegisterFieldMap.set(fieldSet.fieldName, fieldSet));

    const controls = {
      id: new FormControl<number>(id),
      staffUnitId: new FormControl<number>(staffUnitId),
      descriptorId: new FormControl<number>({
        value: versionSet?.setId,
        disabled: actionType === 'edit'
      }, requiredValidator),
      registerType: new FormControl<number>({
        value: registerTypeId,
        disabled: actionType === 'edit'
      }, requiredValidator),
      diseaseNumber: this.createAndInitializeControl(diseaseNumber, "diseaseNumber", requiredValidator, thisVersionRegisterFieldMap),
      patientFio: this.createAndInitializeControl(patientFio, "patientFio", requiredValidator, thisVersionRegisterFieldMap),
      typeServiceId: this.createAndInitializeControl(typeServiceId, "typeServiceId", requiredValidator, thisVersionRegisterFieldMap),
      typeResearch: this.createAndInitializeControl(typeResearch, "typeResearch", requiredValidator, thisVersionRegisterFieldMap),
      typeWork: this.createAndInitializeControl(typeWork, "typeWork", requiredValidator, thisVersionRegisterFieldMap),
      researchCount: this.createAndInitializeControl(researchCount, "researchCount", requiredValidator, thisVersionRegisterFieldMap),
      researchProtocol: this.createAndInitializeControl(researchProtocol, "researchProtocol", requiredValidator, thisVersionRegisterFieldMap),
      subdivisionId: this.createAndInitializeControl(subdivisionId, "subdivisionId", requiredValidator, thisVersionRegisterFieldMap),
      operatingTime: new FormControl<number>(operatingTime),
      operatingTimeFree: new FormControl<number>(0)
    };

    controls.operatingTime.setValidators(control => {
      const busyAndIntervalData = this.getBusyAndIntervalData(controls.staffUnitId.value, controls.id.value);
      const lengthFree = busyAndIntervalData.timeIntervalLength - busyAndIntervalData.totalBusy - control.value;

      controls.operatingTimeFree.setValue(lengthFree, { onlySelf: true });

      if (control.value && lengthFree < 0) {
        return { 'message': 'Превышает рабочее время' }
      } else if (!control.value) {
        return { 'message': 'Поле<br>должно быть заполнено' }
      } else {
        return null
      }
    })

    this.component.form = new FormGroup<IVichRegisterGridComponentForm>(controls);
  }

  /** Создать контрол и инициализировать его в соответствии с параметрами поля */
  private createAndInitializeControl(
    fieldValue: any,
    fieldName: IVichRegisterGridComponentFormType,
    validatorFunc: (ctrl: AbstractControl<any, any>) => { message: string },
    thisVersionRegisterFieldMap: Map<string, VichRegisterFieldSet>) {
    const field = thisVersionRegisterFieldMap.get(fieldName);

    return new FormControl({value: fieldValue, disabled: !field},
      {validators: this.getValidatorIfFieldHasTrueIsRequiredParam(validatorFunc, field)});
  }

  /** Получить кастомный валидатор required, если поле является обязательным к заполнению */
  private getValidatorIfFieldHasTrueIsRequiredParam(validatorFuncs:(ctrl: AbstractControl<any, any>) => {message: string}, field: VichRegisterFieldSet){
    return  field && field.isRequiredField ? validatorFuncs : undefined;
  }

  public addRow(registerTypeId: number, gridComponent: GridComponent): void {
    if (!this.checkCanAddRow(null)) return;

    const curSet = this.component.settings.vichRegisterSettingsArrObj.settingsSets
      .find(x => x.registerTypeId === registerTypeId)?.registerTypeSettingsArr
      .find(typeVersionSet => typeVersionSet.isCurrentVersion);

    this.createFormGroup(
      0,
      this.component.settings.staffUnitOwnerId,
      null, null, null, null,
      null, null, null, null, null,
      curSet,
      registerTypeId,
      'add'
    )

    gridComponent.addRow(this.component.form);
  }

  public editRow(e: EditEvent) {
    const item: VichRegisterComponentGridDataItem = e.dataItem;

    let curSet: RegisterTypeVersionSettings;
    let curTypeId: number;
    this.component.settings.vichRegisterSettingsArrObj
      .settingsSets.forEach(x => x.registerTypeSettingsArr.forEach(j => {
        if(j.setId === item.descriptorId) {
          curSet = j;
          curTypeId = x.registerTypeId;
        }
      })
    );
    if (!this.checkCanAddRow(item.id)) return;

    this.component.currentRegisterTypeId = curTypeId;

    this.createFormGroup(
      item.log.id,
      item.log.staffUnitId,
      item.log.diseaseNumber,
      item.log.patientFio,
      item.log.typeServiceId,
      {
        id: item.log.typeResearchId ?? null,
        text:
          item.log.typeResearchId
            ? this.component.typesResearchSource.find(t => t.id === item.log.typeResearchId).text
            : item.log.customTypeResearch
      },
      {
        id: item.log.typeWorkId ?? null,
        text:
          item.log.typeWorkId
            ? this.component.typesTypeWorkSource.find(t => t.id === item.log.typeWorkId).text
            : item.log.customTypeWork
      },
      item.log.researchCount,
      item.log.researchProtocol,
      item.log.subdivisionId,
      item.log.operatingTime,
      curSet,
      curTypeId,
      'edit'
    );

    e.sender.editRow(e.rowIndex, this.component.form);
  }

  public saveRow(e: SaveEvent) {
    const value = this.component.form.value

    // Если новая строка
    if (e.isNew) this.saveNewRow(value, e.sender, e.rowIndex)
    // Если редактирование
    else this.saveExistRow(value, e.sender, e.rowIndex)
  }

  public removeRow(e: RemoveEvent) {
    const id = e.dataItem.id;
    this.alertService.defaultAlertOption.question().mod(x => {
      x.title = 'Подтверждение';
      x.message = 'Удалить случай работы с ВИЧ?';
      x.buttons[1].text = 'Удалить'
      x.buttons[1].callBack = () =>
        this.dataService.removeLog$(id)
          .pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
            next: () => {
              this.hasChange = true
              this.directoryData.deleteItemByIds(true, id)
            },
            error: () => this.alertService.defaultAlertOption.warning().mod(x => {
              x.message = 'При удалении записи о случае работы с ВИЧ произошла ошибка!<br>Рекомендуем перезагрузить страницу'
            }).showAlert()
          })
    }).showAlert();
  }

  /** Закрыть редактируемую строку */
  public closeRow(grid: GridComponent, rowIndex: number) {
    grid.closeRow(rowIndex);
    this.component.form = null;
  }

  private saveNewRow(
    {
      staffUnitId, descriptorId, diseaseNumber, patientFio, typeServiceId, typeResearch,
      typeWork, researchCount, researchProtocol, subdivisionId, operatingTime
    }: IVichRegisterGridComponentFormValue,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.dataService.addLog$({
      staffUnitId,
      descriptorId,
      date: this.component.settings.date,
      diseaseNumber,
      patientFio,
      typeServiceId: typeServiceId,
      typeResearchId: typeResearch?.id ?? null,
      customTypeResearch: typeResearch?.id ? null : typeResearch?.text,
      typeWorkId: typeWork?.id ?? null,
      customTypeWork: typeWork?.id ? null : typeWork?.text,
      researchCount,
      researchProtocol,
      subdivisionId,
      operatingTime
    }).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.hasChange = true
        this.directoryData.addItems(true, this.vichLogToRegisterComponentGridDataItem(value));
        this.closeRow(sender, rowIndex)
      }, error: () => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении новой записи произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  private saveExistRow(
    {
      id, staffUnitId, diseaseNumber, patientFio, typeServiceId, typeResearch,
      typeWork, researchCount, researchProtocol, subdivisionId, operatingTime
    }: IVichRegisterGridComponentFormValue,
    sender: GridComponent,
    rowIndex: number
  ) {
    this.dataService.editLog$({
      id,
      staffUnitId,
      diseaseNumber,
      patientFio,
      typeServiceId: typeServiceId,
      typeResearchId: typeResearch?.id ?? null,
      customTypeResearch: typeResearch?.id ? null : typeResearch?.text,
      typeWorkId: typeWork?.id ?? null,
      customTypeWork: typeWork?.id ? null : typeWork?.text,
      researchCount,
      researchProtocol,
      subdivisionId,
      operatingTime
    }).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: value => {
        this.hasChange = true
        this.directoryData.updateItems2(true, this.vichLogToRegisterComponentGridDataItem(value))
        this.closeRow(sender, rowIndex)
      }, error: () => {
        this.alertService.defaultAlertOption.warning().mod(x => {
          x.message = 'При сохранении изменений произошла ошибка!<br>Рекомендуем перезагрузить страницу'
        }).showAlert();
      }
    })
  }

  /** Получить данные о занятом времени и длительности временного интервала **/
  private getBusyAndIntervalData(staffUnitOwnerId: number, notIncludeVichLogId: number): { totalBusy: number, timeIntervalLength: number } {
    const timeInterval = this.component.settings.timeInterval;

    return {
      totalBusy: this.directoryData.data
        .filter(x => x.log.staffUnitId == staffUnitOwnerId)
        .filter(x => x.log.id != notIncludeVichLogId)
        .reduce((previousValue, currentValue) => previousValue + currentValue.log.operatingTime, 0),
      timeIntervalLength: timeInterval.duration * 60
    }
  }

  /** Проверка возможности добавления нового случая работы с ВИЧ */
  private checkCanAddRow(notIncludeCovidLogId: number): boolean {
    const data = this.directoryData.data

    const can = data.length == 0 ?
      true :
      data
        .map(x => this.getBusyAndIntervalData(x.log.staffUnitId, notIncludeCovidLogId))
        .some(x => x.timeIntervalLength - x.totalBusy > 0)

    if (!can) {
      this.alertService.defaultAlertOption.information().mod(x => {
        x.message = 'Вы не можете добавить новую запись<br>Все рабочее время уже занято'
      }).showAlert();
    }

    return can;
  }

  public getChangeEvent(): IVichRegisterGridComponentChangeEvent {
    return {
      changed: this.hasChange,
      busyTime: this.directoryData.data.reduce((previousValue, currentValue) =>
        previousValue + currentValue.log.operatingTime, 0)
    }
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/**  */
export type VichRegisterFieldName = keyof Pick<IVichRegisterGridComponentForm,
  'diseaseNumber' | 'patientFio' | 'typeServiceId' | 'typeResearch' |
  'researchCount' | 'typeWork' | 'researchProtocol' | 'subdivisionId'>;

/** Набор настроек ВИЧ журналов определенной версии */
export type VichRegisterTypeSettingsSet = {
  registerTypeId: vichRegisterTypeEnum,
  registerTypeName: string,
  registerTypeSettingsArr: RegisterTypeVersionSettings[]
}

/** Набор полей определенной версии и типа ВИЧ журнала */
export type RegisterTypeVersionSettings = {
  setId: number,
  settingsVersion: number,
  isCurrentVersion: boolean,
  registerFieldSet: VichRegisterFieldSet[]
};

/** Модель одного поля ВИЧ журнала */
export type VichRegisterFieldSet = {
  fieldName: VichRegisterFieldName,
  isRequiredField: boolean
};

/** Идентификатор типа ВИЧ журнала */
export enum vichRegisterTypeEnum{
  hospital = 1,
  paraclinic = 2,
  disinfection = 3,
  disinfectionOomo = 4,
  pao = 5,
  laboratory = 6,
  cleaners = 7,
}
