import {ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, Self} from "@angular/core";
import {GraphGridRowModel} from "../classes/view-models/row-and-graph-day-view-model.class";
import {GraphDataSourceService} from "../secvices/graph-data-source.service";
import {GraphNormFactCalculatorService} from "../secvices/graph-norm-fact-calculator.service";
import {
  Api1GraphControlControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-graph-control-controller.service";
import {LoadingIndicatorService} from "../../../../../../../../src/app/services/loading-indicator.service";
import {AppSettingsService} from "../../../../../../../../src/app/services/app-settings.service";
import {CellSelectingDirectiveService} from "./cell-selecting.directive.service";
import {
  TracerServiceBase
} from "../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {GridComponent} from "@progress/kendo-angular-grid";
import {defer, of, ReplaySubject, Subject, switchMap} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ArrayHelper} from "../../../../../../../../src/app/helpers/arrayHelper";
import {
  ObjectComparer,
} from "../../../../../../../../src/app/classes/object-comparers/object-comparer";
import {GraphDayCellService} from "../components/graph-day-cell/services/graph-day-cell.service";
import {process} from "@progress/kendo-data-query";
import {traceFunc} from "../../../../../../../../src/app/modules/trace/decorators/func.decorator";


/** Директива сравнивает две редакции графика. Добавляет строки показывающие разность между редакциями */
@Directive({
  selector: '[appGraphRedactionComparer]',
  providers: [GraphNormFactCalculatorService]
})
@traceClass('GraphRedactionComparerDirective')
export class GraphRedactionComparerDirective implements OnInit, OnDestroy{

  private _dataSourceService: GraphDataSourceService = null;
  /** Источник данных для графика, построенный по целевой редакции(что сравнивать) */
  @Input() set dataSourceService(value: GraphDataSourceService){
    if(this._dataSourceService){
      throw new Error('Повторная установка НЕ допускается');
    }

    this._dataSourceService = value;
    this._dataSourceService.source$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.redactionIdForCompare = null;
      this.streams$.calculate.next();
    })
  }

  private _redactionIdForCompare: number = null;
  /** Идентификатор редакции для сравнения */
  @Input() set redactionIdForCompare(value: number){
    if(this._redactionIdForCompare == value){
      return;
    }
    this._redactionIdForCompare = value;
    this.streams$.loadDataForCompare.next(value);
    this.redactionIdForCompareChange.emit(value);
  }

  /** Событие изменения редакции */
  @Output() redactionIdForCompareChange = new EventEmitter<number>();

  /** Сервис получения данных строк для сравнения(с чем сравнивать) */
  private readonly _compareDataSourceService: GraphDataSourceService;

  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    /** Стрим сообщает что нужно пересчитать источник для Графика */
    calculate: new Subject<void>(),
    /** Стрим сообщает что нужно сменить данные для сравнения(источник) */
    loadDataForCompare: new Subject<number>()
  }

  constructor(@Self()  graphNormFactCalculatorService: GraphNormFactCalculatorService,
              api1GraphControlControllerService: Api1GraphControlControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              appSettingsService: AppSettingsService,
              cellSelectingDirectiveService: CellSelectingDirectiveService,
              private readonly tracerService: TracerServiceBase,
              private readonly gridComponent: GridComponent,
              private readonly graphDayCellService: GraphDayCellService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
    /** Создаем источник данных для источника сравнения */
    this._compareDataSourceService = new GraphDataSourceService(graphNormFactCalculatorService,
      api1GraphControlControllerService, this.loadingIndicatorService, appSettingsService, cellSelectingDirectiveService, tracerService.copy())

    /** Подписываемся на необходимость перестроения источника данных Графика */
    this.streams$.calculate.pipe(debounceTime(50), takeUntil(this.streams$.unsubscribe)).subscribe(() => {
      const dataSource = this.generateDataSource(
        this._dataSourceService.source,
        this._redactionIdForCompare ? this._compareDataSourceService : null
      );

      this.gridComponent.data = process(dataSource, this._dataSourceService.state);
      this.changeDetectorRef.markForCheck();
    })

    /** Подписываемся на изменение  */
    this.streams$.loadDataForCompare.pipe(
      debounceTime(50),
      switchMap(redactionId => {
        return !!redactionId ?
          this._compareDataSourceService.onInit(redactionId, 'Загрузка данных для сравнения') :
          of(null)
      }),
      takeUntil(this.streams$.unsubscribe)).subscribe(value => {
        this.streams$.calculate.next();
    });
  }

  @traceFunc()
  ngOnInit(): void {
  }

  /** Сформировать источник данных */
  private generateDataSource(dataSource: GraphGridRowModel[], compareDataSourceService: GraphDataSourceService): GraphGridRowModel[]{
    if(!dataSource){ // выходим если нет данных
      return null;
    }

    if(!compareDataSourceService?.source || compareDataSourceService.source.length == 0){ //выходим если не с чем сравнивать
      return dataSource;
    }

    dataSource.forEach(x => this.clearStateInRow(x)) //Очищаем предыдущее состояние сравнения источника Графика
    compareDataSourceService.source.forEach(x => this.clearStateInRow(x)); //Очищаем предыдущее состояние сравнения источника для сравнения

    const rows: GraphGridRowModel[] = [];

    ArrayHelper.leftOuterJoinGroupedRight(
      dataSource,
      compareDataSourceService.source,
        x => x.staffUnit.ownerId,
        x => x.staffUnit.ownerId,
      (left, rights) => { return {left: left, right: !rights || rights.length == 0 ? null : rights[0]}}
    ).forEach(x => {
      if(!x.left){ //если в текущей редакции строка удалена
        x.right.compareState = "deleted";
        rows.push(x.right);
        return;
      }

      if(!x.right){ //если в текущей редакции строка добавлена
        x.left.compareState = 'added';
        rows.push(x.left)
        return;
      }

      const comparer = new ObjectComparer(x.left, x.right)
      comparer.registryProp('staffUnit', (sU1, sU2) => {
        const sUComparer = new ObjectComparer(sU1, sU2)
          .registryProp('rate')
          .registryProp('percent')

        return sUComparer.isEquals;
      }).registryProp('graphDays', (arr1, arr2) => {
        let allGraphDayEquals = true;

        for (let i = 0; i < arr1.length; i++) {
          //Сравниваем работал/не работал в этот день
          const isEmpty1 = this.graphDayCellService.isEmpty(x.left.staffUnit.startDate, x.left.staffUnit.endDate, arr1[i].day.date);
          const isEmpty2 = this.graphDayCellService.isEmpty(x.right.staffUnit.startDate, x.right.staffUnit.endDate, arr2[i].day.date);

          if(isEmpty1 != isEmpty2){ //Если изменился диаппазон staffUnit
            allGraphDayEquals = false;
            continue;
          }

          if(isEmpty1 && isEmpty2){ //Если НЕ работал, то предотвращаем последующее сравнение. Так как у нас грузится информация и в те дни где не работал и может ложно говорить что ячейки не равны
            continue;
          }

          //Сравниваем ячейки
          const cellComparer = new ObjectComparer(arr1[i], arr2[i]);
          cellComparer.registryProp('timeInterval', (tI1, tI2) => tI1?.id === tI2?.id)
            .registryProp('dayDeviation', (dD1, dD2) => dD1?.id === dD2?.id)
            .registryProp('customValue')
            .registryProp('subtractLunch')
            .registryProp('flexDinner')

          if(!cellComparer.isEquals){
            arr2[i].compareState = "modified";
            allGraphDayEquals = false;
          }
        }

        return allGraphDayEquals;
      });

      rows.push(x.left);

      if(!comparer.isEquals2){
        x.right.compareState = "modified"
        rows.push(x.right)
      }
    });

    return rows;
  }


  /** Очищаем состояние сравнения в строке Графика  */
  private clearStateInRow(row: GraphGridRowModel){
    row.compareState = null;
    row.graphDays.forEach(x => x.compareState = null)
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this._compareDataSourceService?.ngOnDestroy();
  }
}
