import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddEvent, CancelEvent, EditEvent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { CovidLog2 } from 'src/app/classes/domain/POCOs/timesheet/CovidLog2';
import { Covid2RegisterComponentSettings, ICovid2RegisterGridComponent, ICovid2RegisterGridComponentChangeEvent, ICovid2RegisterGridComponentForm } from './i-covid2-register-grid.component';
import { Covid2RegisterGridComponentDataService } from './services/covid2-register-grid-component-data.service';
import { Covid2RegisterGridComponentService } from './services/covid2-register-grid-component.service';

@Component({
  selector: 'app-covid2-register-grid',
  templateUrl: './covid2-register-grid.component.html',
  styleUrls: ['./covid2-register-grid.component.css'],
  providers: [
    Covid2RegisterGridComponentService,
    Covid2RegisterGridComponentDataService
  ]
})
export class Covid2RegisterGridComponent implements ICovid2RegisterGridComponent, OnInit {
  @Input() public settings: Covid2RegisterComponentSettings

  public isReadOnly: boolean

  public data$: Observable<CovidLog2[]>

  public form: FormGroup<ICovid2RegisterGridComponentForm>

  constructor(private readonly service: Covid2RegisterGridComponentService) {
    service.component = this
  }

  ngOnInit(): void {
    this.service.onInit()
  }

  public addRow(e: AddEvent) {
    this.service.addRow(e)
  }

  public editRow(e: EditEvent) {
    this.service.editRow(e)
  }

  public cancelRow(e: CancelEvent) {
    this.service.closeRow(e.sender, e.rowIndex)
  }

  public saveRow(e: SaveEvent) {
    this.service.saveRow(e)
  }

  public removeRow(e: RemoveEvent) {
    this.service.removeRow(e)
  }

  public getChangeEvent(): ICovid2RegisterGridComponentChangeEvent {
    return this.service.getChangeEvent()
  }
}