import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { CovidLog2 } from "src/app/classes/domain/POCOs/timesheet/CovidLog2";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1Covid2ControllerService, IAddCovidLog2Request, IEditCovidLog2Request } from "src/app/services/webApi/webApi1/controllers/api1-covid2-controller.service";

@Injectable()
export class Covid2RegisterGridComponentDataService {
  constructor(private readonly api1Covid2ControllerService: Api1Covid2ControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService) { }

  private getLogs$(staffUnitOwnerIds: number[], date: Date): Observable<CovidLog2[]> {
    return this.api1Covid2ControllerService.getCovidLogs$(staffUnitOwnerIds, date)
  }

  private getCanAddEditRemove$(date: Date): Observable<boolean> {
    return this.api1Covid2ControllerService.canAddEditRemove$(date)
  }

  public getCombineLatestData$(staffUnitOwnerIds: number[], date: Date): Observable<[CovidLog2[], boolean]> {
    return combineLatest(
      [
        this.loadingIndicatorService.addToObservable(
          'Загрузка случаем работы с Covid пациентами',
          this.getLogs$(staffUnitOwnerIds, date)
        ),
        this.loadingIndicatorService.addToObservable(
          'Загрузка общей информации',
          this.getCanAddEditRemove$(date)
        )]
    )
  }

  public addLog$(model: IAddCovidLog2Request): Observable<CovidLog2> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление случая работы с Covid',
      this.api1Covid2ControllerService.addCovidLog$(model)
    );
  }

  public editLog$(model: IEditCovidLog2Request): Observable<CovidLog2> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение изменений случая работы с Covid',
      this.api1Covid2ControllerService.editCovidLog$(model)
    );
  }

  public removeLog$(id: number): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      'Удаление записи о случае работы с Covid',
      this.api1Covid2ControllerService.removeCovidLog$(id)
    );
  }
}