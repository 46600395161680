<kendo-grid [data]="gridView"
            [selectable]="{enabled: true, mode: 'single'}"
            kendoGridSelectBy="id"
            [(selectedKeys)]="rowSelected"
            KendoGridExpanded
            (dbCellClick)="onDbCellClick($event)"
            style="max-height: 100%;"
>
  <kendo-grid-column title="Редакция"
                     field="date"
                     format="HH:mm dd.MM.yyyy"
                     [width]="150"
  >

  </kendo-grid-column>
  <kendo-grid-column title="Исполнитель"
                     field="modifiedFio"
                     [width]="300"
  >

  </kendo-grid-column>
  <kendo-grid-column title="Комментарий"
                     field="comment"
  >

  </kendo-grid-column>

  <kendo-grid-column title="Статус"
                     [width]="200"
                     field="status"
  >
  </kendo-grid-column>

  <kendo-grid-column title="Версия"
                     hidden="hidden"
                     field="version"
  >
    <ng-template kendoGridGroupHeaderTemplate let-value="value" let-index="index">
      <span *ngIf="redactionFor == 'table'">{{(value > 0 ? 'Корректирующий' : 'Первичный')}} № {{value}}</span>
      <span *ngIf="redactionFor == 'graph'">Версия {{value}}</span>
      <span *ngIf="data.versions[index].routeStatus"
            style="margin-left: 50px"
      >
        {{data.versions[index].routeStatus?.text}}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column [width]="actionColumnState.columnWidth" title="Действие">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton primary="true"
              [icon]="'preview'"
              title="Просмотр"
              (click)="onPreview(dataItem)"
      >
      </button>
      <button kendoButton
              *ngIf="!data.userEditId && dataItem.version == data.actualVersionId && dataItem.id != data.actualRedactionId && data.inWork"
              primary="true"
              [icon]="'copy'"
              title="Использовать для работы"
              (click)="onCopy(dataItem)"
      >
      </button>
      <button kendoButton
              *ngIf="actionColumnState.hasCompareButton && dataItem.id < initSelectedRowId"
              primary="false"
              [icon]="'equal'"
              title="Сравнить"
              (click)="onCompare(dataItem)"
      >
      </button>
    </ng-template>
  </kendo-grid-command-column>

  <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="detailTemplateShowIf"
  >
    <div style="display: flex; width: 100%; justify-content: center">
      <app-redaction-detail-grid *ngIf="dataItem.status"
                                 [data]="dataItem.routes"
                                 style="width: 90%"
      >
      </app-redaction-detail-grid>
    </div>
  </ng-template>
</kendo-grid>
