import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'arraySort'
})
/** Пайп сортирует массив */
export class ArraySortPipe implements PipeTransform {
  transform<T>(values: T[], compareFn?: Parameters<typeof values['sort']>[0]): T[] {
    return values?.sort(compareFn);
  }
}
