import {Injectable, OnDestroy, Output} from '@angular/core';
import {race, ReplaySubject, Subject} from "rxjs";
import {DialogService} from "@progress/kendo-angular-dialog";
import {delay, take, takeUntil} from "rxjs/operators";
import {
  EditProxyComponent,
  EditProxyComponent_SaveEvent,
  EditProxyComponent_Settings
} from "../../edit-proxy/edit-proxy.component";
import {DayDeviation} from "../../../../../../../src/app/classes/domain/POCOs/timesheet_graph/DayDeviation";
import {GraphDayViewModel} from "../graph-grid/classes/view-models/row-and-graph-day-view-model.class";
import {traceClass} from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {TraceParamEnum} from "../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../src/app/modules/trace/decorators/param.decorator";

@Injectable()
@traceClass('EditProxyService')
export class EditProxyService implements OnDestroy {
  /** Событие добавления/редактирования Proxy */
  @Output() save$: Subject<EditProxyComponent_SaveEvent> = new Subject<EditProxyComponent_SaveEvent>();

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private dialogService: DialogService,
              private readonly tracerService: TracerServiceBase) { }

  /** Открыть диалог */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public open(@traceParam({maxLength: 1000}) settings: EditProxyComponent_Settings){
    const dialogRef = this.dialogService.open({
      title: settings.lableAndTitle_Settings.title,
      content: EditProxyComponent,
      height: '580px',
      width: '500px'
    });
    const editProxyComponent = dialogRef.content.instance as EditProxyComponent;

    editProxyComponent.settings = settings;

    const unsubscribeRice = race(this.streams$.unsubscribe, editProxyComponent.cancel$, editProxyComponent.save$)
      .pipe(delay(1));
    editProxyComponent.cancel$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      dialogRef.close();
    });

    editProxyComponent.save$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      dialogRef.close();
      this.save$.next(value);
    });
  }

  /** Проверить переданный диапазон GraphDays на однородность причины замещения (во всех выделенных клетках один dayDeviation) и вернуть валидный DayDeviation */
  @traceFunc()
  public changeGraphDaysAndReturnDayDeviation(graphDays: Array<GraphDayViewModel>): DayDeviation{
    //определяем правильность выбора причины замещения при выделении ячеек графика (должен быть только один DayDeviation во всех ячейках, иначе передаем undefined)
    let ddChosen: boolean = graphDays?.every(x => x.dayDeviation) && // все graphDay имеют dayDeviation
      this.onlyOneDayDeviationInGraphDays(graphDays);  //проверяем все ли graphDay содержат один и тот же dayDeviation по их daydeviation.id
    if (ddChosen) {
      const dd = graphDays[0].dayDeviation;
      return new DayDeviation(dd.id, false, dd.name,
        dd.color, null, dd.isIncludeInCalculate, dd.hasCustomValue, dd.deviationWithInterval, dd.shortName);
    }
  }

  @traceFunc()
  /** Проверка все ли graphDays содержат один и тот же dayDeviation*/
  private onlyOneDayDeviationInGraphDays(graphDays: GraphDayViewModel[]): boolean{
    return graphDays.map(gd => gd.dayDeviation) // выбираем dayDeviation
      .reduce((prev, cur, index, arr) => //проверяем все ли graphDay содержат один и тот же dayDeviation по их daydeviation.id
        prev && (cur.id == arr[0].id), //callback fn
        true);  //initialValue
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
