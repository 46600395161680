import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ComponentServiceBase } from "../../../../../../../src/app/services/abstracts/component-service-base";
import { ITableDismisedComponent } from "../i-table-dismised-component";
import { TableDismissedDataService } from "./table-dismissed-data.service";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import {
  Api1PrintReportControllerService
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  DisplayErrorsService
} from "../../../../../../../src/app/components/display-errors/services/display-errors.service";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";

@Injectable()
export class TableDismissedComponentService extends ComponentServiceBase<ITableDismisedComponent> implements OnInit, OnDestroy {

  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly tableDismissedDataService: TableDismissedDataService,
    private readonly reportService: Api1PrintReportControllerService,
    private readonly displayErrorsService: DisplayErrorsService,
    private readonly loadingIndicatorService: LoadingIndicatorService) {
    super();
  }



  ngOnInit(): void {
    this.loadingIndicatorService.addToObservable('Загрузка списка уволенных',
      this.tableDismissedDataService.getData$(this.component.subdivisionId, this.component.month, this.component.redactionId)
        .pipe(take(1), takeUntil(this.unsubscribe$)))
      .subscribe({
        next: value => this.component.dismissedStaffUnitList = value,
        error: error => {
          this.component.cancel$.emit();
          this.displayErrorsService.showSimpleError(
            "Не получилось загрузить информацию об уволенных сотрудниках.\r\nПопробуйте еще раз.");
        }
      });
  }

  print(redactionId: number) {
    this.component.printedStaffUnitOwnerIdList;
    let checkedStaffUnitIdList = this.component.dismissedStaffUnitList
      .filter(x => this.component.printedStaffUnitOwnerIdList
        .some(el => x.baseStaffunitOwnerId == el))
      .map(x => x.staffUnitInfos.map(y => y.ownerId));
    let dismissedStaffUnitIds = [].concat(...checkedStaffUnitIdList);

    this.reportService.printTable$(redactionId, 'table_dismissed', null, null, dismissedStaffUnitIds)
      .subscribe(resp => {
        if (resp.size === 0) {
          this.displayErrorsService.showSimpleError(
            'Не удалось сформировать табель на уволенных. Попробуйте еще раз.');
          return;
        }
        FileSaver.saveAs(resp, `Табель_на_уволенных_${moment().format('DD_MM_yyyy')}.xlsx`);
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
