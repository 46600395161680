import { GetRowsResponse_Directories_Subdivision } from "src/app/services/webApi/webApi1/controllers/api1-covid-controller.service";
import {Day} from "../../../../../../timesheet/src/app/components/graph-table-workspace/graph-grid/classes/view-models/day-view-model.class";
import {EmployeeGraphGridRow} from "./employee-graph-grid-row";

export class EmployeeGraphGridViewModel {
  constructor(public days: Array<Day>,
              public rows: Array<EmployeeGraphGridRow>,
              public subdivisions: Array<GetRowsResponse_Directories_Subdivision>) {
  }
}
