<div class="button-group-container"
     [title]="(disabled$ | async) ? 'Панель неактивна из-за отсутствия выделенных или доступных для добавления кодов строк табеля' : ''">
  <kendo-buttongroup
        [selection]="'single'"
        look="outline"
        [disabled]="disabled$ | async"
  >
    <button
          *ngFor="let code of source$ | async"
          kendoButton
          [ngStyle]="{
          margin: '1px',
          padding: '2px',
          paddingLeft: '6px',
          paddingRight: '6px'
          }"
          (click)="onButtonClick(code)"
    >
    <span class="button-text" [title]="code.name">
      {{ code.key }}
    </span>
    </button>
  </kendo-buttongroup>
</div>
