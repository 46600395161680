import {GetGraphModelResult_Directories_WorkMode} from "../../../../../Classes/GetGraphModelResult";
import {WorkMode} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkMode";

export class WorkModeVM {
  constructor(
    public ownerId: number,
    public name: string,
    public preHolidayHourDuration: number,
    public workDayHourDuration: number,
    public workModeTypeId: number,
  ) {
  }


  public static Create(source: GetGraphModelResult_Directories_WorkMode): WorkModeVM {
    return new WorkModeVM(source.ownerId, source.name, source.preHolidayHourDuration, source.workdayHourDuration, source.workModeTypeId);
  }

  public static CreateArray(source: Array<GetGraphModelResult_Directories_WorkMode>): Array<WorkModeVM> {
    return source.map(item => WorkModeVM.Create(item));
  }

  public static Create2(source: WorkMode): WorkModeVM{
    return new WorkModeVM(source.ownerId, source.name, source.preHolidayHourDuration, source.workdayHourDuration, source.workModeTypeId);
  }
}
