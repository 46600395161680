import {
  GraphGrid_EditablePartGraphDayCell,
  GraphGrid_GraphDayCell
} from "./graph-grid-graph-day-cell-data-source.classes";
import {ArrayExpanded, ArrayHelper} from "../../../../../../../../../../../src/app/helpers/arrayHelper";
import {Injectable} from "@angular/core";
import {DateMap} from "../../../../../../../../../../../src/app/classes/maps/date-maps/date-map";
import {ArrayDataSourceHasId} from "../../../../../../../../../../../src/app/classes/array-data-sources/data-source";
import {
  ArrayDataSourceSelection
} from "../../../../../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";
import {
  TracerServiceBase
} from "../../../../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../../../../../../../../../src/app/modules/trace/decorators/class.decorator";
import {traceFunc} from "../../../../../../../../../../../src/app/modules/trace/decorators/func.decorator";
import {
  TraceParamEnum
} from "../../../../../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface";
import {traceParam} from "../../../../../../../../../../../src/app/modules/trace/decorators/param.decorator";

/** Тип - результат редактирования ячейки */
type EditCellResultType = {
  current: GraphGrid_GraphDayCell,
  newValue: GraphGrid_GraphDayCell
}

/** Источник данных для ячеек дней графика. */
@Injectable()
@traceClass('GraphGrid_GraphDayCellDataSourceService')
export class GraphGrid_GraphDayCellDataSourceService extends ArrayDataSourceHasId<GraphGrid_GraphDayCell, string> {
  /** Хранилище ячеек */
  private readonly _map = new Map<number, DateMap<GraphGrid_GraphDayCell>>();

  /** Выборщик содержащий измененные ячейки */
  public readonly changedCellsSelection = new ArrayDataSourceSelection<GraphGrid_GraphDayCell, string>(this);

  constructor(private readonly traceService: TracerServiceBase) {
    super(x => x.id, undefined, GraphGrid_GraphDayCell.fullComparer.asPropertyCompareFunc(false));

    this.change$.subscribe(cells => {
      this.setToMap(cells);
      this.setToChangedSelection(cells);
    });
  }

  /**
   * Получить.<br>
   * @return ячейка или undefined если отсутствует
   */
  public get(staffUnitId: number, date: Date): GraphGrid_GraphDayCell {
    return this._map.get(staffUnitId)?.get(date);
  }

  /**
   * Редактировать ячейки.
   * @param cells ячейки для модификации
   * @param mod функция модификации. Используй готовые функции {@link GraphGrid_EditablePartGraphDayCell.setDayDeviation}, {@link GraphGrid_EditablePartGraphDayCell.setTimeInterval} и др.
   * @param updateOnlyChanged Если true, обновит в источнике данных только те ячейки, которые были изменены функцией {@link mod}
   * @return только измененные ячейки. Даже если {@link updateOnlyChanged} === false
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public edit(@traceParam() cells: GraphGrid_GraphDayCell[],
              mod: Parameters<typeof GraphGrid_GraphDayCell.CopyWithCurrentEditPart>[1],
              @traceParam() updateOnlyChanged: boolean){

    const copies = (cells ?? [])
      .map<EditCellResultType>(x => ({
        current: x,
        newValue: GraphGrid_GraphDayCell.CopyWithCurrentEditPart(x, mod),
      }))

    const changed = copies
      .filter(x => !GraphGrid_EditablePartGraphDayCell.fullComparer.compare(x.current.graphDayCurrent, x.newValue.graphDayCurrent));

    this.updateItems(true, ...(updateOnlyChanged ? changed : copies));

    return changed;
  }

  /**
   * Очистить ячейки
   * @param cells ячейки подлежащие очищению
   * @param updateOnlyChanged Если true, обновит в источнике данных только те ячейки, которые были изменены при очистке
   * @return только ячейки, которые были НЕ чисты. Даже если {@link updateOnlyChanged} === false
   */
  @traceFunc()
  public clear(cells: GraphGrid_GraphDayCell[], updateOnlyChanged: boolean): EditCellResultType[]{
    const copies = (cells ?? [])
      .map<EditCellResultType>(cell => {
        return {
          current: cell,
          newValue: GraphGrid_GraphDayCell
            .Copy(
              cell,
              x => {
                x.graphDayCurrent = GraphGrid_EditablePartGraphDayCell.CreateDefault()
              }
            )
        };
      });

    const changed = copies
      .filter(x => !GraphGrid_EditablePartGraphDayCell.fullComparer.compare(x.current.graphDayCurrent, x.newValue.graphDayCurrent));

    this.updateItems(true, ...(updateOnlyChanged ? changed : copies));

    return changed;
  }

  /**
   * Получить измененные ячейки
   * @param staffUnitIds фильтр по идентификаторам исполнения должности. {@link undefined} вернет все измененные ячейки
   */
  @traceFunc()
  public getChangedCells(staffUnitIds: number[] = undefined){
    let cells = this.changedCellsSelection
      .selectedItems2.data ?? [];

    if(staffUnitIds){
      cells = new ArrayExpanded(staffUnitIds)
        .distinct()
        .rightInnerJointElements(cells, x => x, x => x.staffUnitId)
        .array;
    }

    return cells;
  }

  /**
   * Отменить изменения в измененных ячейках
   * @param staffUnitIds
   */
  @traceFunc()
  public cancelChangedCells(staffUnitIds: number[] = undefined){
    const cells = this.getChangedCells(staffUnitIds);
    if(!cells.length){
      return [];
    }

    const copies = cells
      .map<EditCellResultType>(x => {
        return {
          current: x,
          newValue: GraphGrid_GraphDayCell
            .Copy(x, copy => {
              copy.graphDayCurrent = GraphGrid_EditablePartGraphDayCell.Copy(copy.graphDayOrigin);
            })
        }
      });

    this.updateItems(true, ...copies);

    return copies;
  }

  /** @inheritdoc */
  @traceFunc()
  public onDestroy() {
    super.onDestroy();
    this._map.clear();
    this.changedCellsSelection.onDestroy();
  }

  /** Установить данные в {@link _map} */
  private setToMap(cells: GraphGrid_GraphDayCell[]){
    this._map.clear(); //Очищаем перед установкой новых значений

    const grouped = new ArrayExpanded(cells)
      .groupBy(
        x => x.staffUnitId,
        (key, items) => {
          return {
            staffUnitId: key,
            map: new ArrayExpanded(items)
              .groupBy(x => +x.day.date)
              .toDateMap(x => x.key, x => ArrayHelper.singleOrDefault(x.values)),
          }
        })
      .array;

    for (let group of grouped) {
      this._map.set(group.staffUnitId, group.map);
    }
  }

  /** Установить значения в {@link changedCellsSelection} */
  private setToChangedSelection(cells: GraphGrid_GraphDayCell[]){
    const changedIds = cells
      .filter(x => x.isChanged)
      .map(x => x.id);

    const needSet = !ArrayHelper.equals2(
      this.changedCellsSelection.selectedIds.data,
      changedIds,
      (id1, id2) => id1 === id2
    )

    if(needSet){
      this.changedCellsSelection.setIds(changedIds);
    }
  }
}
