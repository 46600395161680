import { Injectable, OnDestroy } from "@angular/core";
import {
  Api1RateControllerService,
  Api1RateControllerServiceResponse
} from "../../../../../../../src/app/services/webApi/webApi1/controllers/api1-rate-controller.service";
import { LoadingIndicatorService } from "../../../../../../../src/app/services/loading-indicator.service";
import {Observable, ReplaySubject, Subject} from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AlertService } from "../../../../../../../src/app/services/alert.service";
import { DataResult, process, State } from "@progress/kendo-data-query";

@Injectable()
export class FreeRateGridService implements OnDestroy {
  /** Событие выделения строки */
  public selected$ = new ReplaySubject<FreeRateGridService_RowItem>(1);
  /** Ответ сервера */
  public response: Api1RateControllerServiceResponse = null;
  /** Источник данных для таблицы */
  public dataSource: Array<FreeRateGridService_RowItem> = null;
  /** Выделенная строка в данный момент */
  public selectedRow: FreeRateGridService_RowItem = null;

  /** Стрим {@link gridView} */
  public readonly gridView$: Observable<DataResult> = new ReplaySubject<DataResult>(1);

  private _gridView: DataResult = null;
  /** DataSource для таблицы */
  get gridView(): DataResult {
    return this._gridView;
  }
  private set gridView(value: DataResult) {
    this._gridView = value;
    (this.gridView$ as Subject<DataResult>).next(value);
  }


  /** Состояние таблицы */
  public state: State = {
    sort: [
      { field: 'rate', dir: "asc" },
      { field: 'start', dir: "asc" },
      { field: 'end', dir: "asc" },
      { field: 'financingSource.shortName', dir: "asc" },
    ],
    group: [
      { field: 'groupField', dir: "asc" },
    ]
  }

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private loadingIndicatorService: LoadingIndicatorService,
    private alertService: AlertService,
    private api1RateControllerService: Api1RateControllerService) {
  }

  /**
   * Инициализировать сервис
   * @param month месяц в который добавляется
   * @param subdivisionOwnerId подразделение
   * @param editStaffUnitOwnerId идентификатор штатной единицы(передавать если запись только редактируется)
   */
  public init(month: Date, subdivisionOwnerId: number, editStaffUnitOwnerId: number | null): Observable<void> {
    this.ngOnDestroy();
    this.streams$.unsubscribe = new ReplaySubject<any>(1);
    this.response = null;
    this.dataSource = null;
    this.gridView = null;

    return new Observable<void>(subscriber => {

      this.loadingIndicatorService.addToObservable(
        'Получение свободных ставок',
        this.api1RateControllerService.freeRateToAddEditMoonlighter(month, subdivisionOwnerId, editStaffUnitOwnerId)
      ).pipe(take(1), takeUntil(this.streams$.unsubscribe)).subscribe({
        next: value => {
          this.response = value;
          this.dataSource = this.convert(this.response);
          this.gridView = process(this.dataSource, this.state);
          subscriber.next();
          subscriber.complete();
        }, error: error => {
          this.alertService.defaultAlertOption.warning().mod(x => {
            x.message = 'Произошла ошибка! Попробуйте еще раз'
          }).showAlert();
        }
      });
    })
  }

  /** Конвертировать ответ сервера в источник данных для таблицы */
  public convert(response: Api1RateControllerServiceResponse): Array<FreeRateGridService_RowItem> {
    return response.items.map(item => {
      const financingSource = response.financingSources.find(x => x.id == item.financingSourceId);
      const occupation = response.occupations.find(x => x.id == item.occupationId);
      const workMode = response.workModes.find(x => x.id == item.workModeId);

      return new FreeRateGridService_RowItem(item.start, item.end, item.rate, item.positionOwnerId,
        occupation ? occupation : {id: item.occupationId, name: 'НЕИЗВЕСТНО'},
        workMode ? workMode : {id: item.workModeId, name: 'НЕИЗВЕСТНО'},
        financingSource ? financingSource : {id: item.financingSourceId, shortName: 'НЕИЗВЕСТЕН'});
    })
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Модель строки таблицы */
export class FreeRateGridService_RowItem {
  public readonly groupField: string;

  constructor(
    public readonly start: Date,
    public readonly end: Date,
    public readonly rate: number,
    public readonly positionOwnerId: number,

    public readonly occupation: Api1RateControllerServiceResponse['occupations'][number],
    public readonly workMode: Api1RateControllerServiceResponse['workModes'][number],
    public readonly financingSource: Api1RateControllerServiceResponse['financingSources'][number],
  ) {
    this.groupField = `${this.occupation.name}      ${this.workMode.name}`
  }
}
