import { Injectable, OnDestroy } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {Employee, IEmployee} from "src/app/classes/domain/POCOs/stafflist/Employee";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1SelectWorkingEmployeesControlControllerService, EmployeeResponseItem } from "src/app/services/webApi/webApi1/controllers/api1-select-working-employees-control-controller.service";

@Injectable()
export class SelectWorkingEmployeesComponentDataService implements OnDestroy {
  constructor(private loadingIndicatorService: LoadingIndicatorService, private api1SelectWorkingEmployeesControlControllerService: Api1SelectWorkingEmployeesControlControllerService){

  }

  /** Получение данных работающих сотрудников */
  public getWorkingEmployees$(forDate?: Date, startDate?: Date, endDate?: Date): Observable<SelectWorkingEmployeesComponentGridItem[]> {
    return this.loadingIndicatorService.addToObservable('Загрузка сотрудников',
    this.api1SelectWorkingEmployeesControlControllerService.getForDate$(forDate, startDate, endDate).pipe(map(m=>
      m.map(emplyee=> SelectWorkingEmployeesComponentGridItem.fromEmployeeResponseItem(emplyee)))));
  }

  ngOnDestroy(): void {
  }
}

/** Основной объект для отображения в гриде */
export class SelectWorkingEmployeesComponentGridItem implements Pick<IEmployee, 'id' | 'startDate' | 'endDate' | 'lastName' | 'firstName' | 'patronymic' | 'code'> {
  constructor(
    public id: number,
    public startDate: Date,
    public endDate: Date,
    public lastName: string,
    public firstName: string,
    public patronymic: string,
    public fio: string,
    public code: string,
    public subdivisions: SelectWorkingEmployeesComponentGridItem_Subdivision[],
    public subdivisionsString: string,
    public occupations: SelectWorkingEmployeesComponentGridItem_Occupation[],
    public occupationsString: string
  ){}
  public static fromEmployeeResponseItem(employeeResponseItem: EmployeeResponseItem){
    const subdivisions = employeeResponseItem.subdivisions.map(s=> new SelectWorkingEmployeesComponentGridItem_Subdivision(s.id, s.longName));
    const occupations = employeeResponseItem.occupations.map(s=> new SelectWorkingEmployeesComponentGridItem_Occupation(s.id, s.name));

    return new SelectWorkingEmployeesComponentGridItem(
      employeeResponseItem.id,
      employeeResponseItem.startDate,
      employeeResponseItem.endDate,
      employeeResponseItem.lastName,
      employeeResponseItem.firstName,
      employeeResponseItem.patronymic,
      Employee.fullName(employeeResponseItem),
      employeeResponseItem.code,
      subdivisions,
      subdivisions.map(s=> s.longName).join(", "),
      occupations,
      occupations.map(s=> s.name).join(", ")
    );
  }
}

export class SelectWorkingEmployeesComponentGridItem_Subdivision{
constructor(
    public id: number,
    public longName: string
  ){}
}


export class SelectWorkingEmployeesComponentGridItem_Occupation{
  constructor(
      public id: number,
      public name: string
    ){}
  }
